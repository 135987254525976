import {
  collection,
  limit,
  orderBy,
  query,
  onSnapshot,
} from 'firebase/firestore';
import { FirestoreDB } from '../utils/firebase/firebase';
import { useQuery } from '@tanstack/react-query';

const fetchPrivacy = () => {
  return new Promise((resolve, reject) => {
    const privacyQuery = query(
      collection(FirestoreDB, 'privacy_policies'),
      orderBy('modified_date', 'desc'),
      limit(1)
    );

    const unsubscribe = onSnapshot(
      privacyQuery,
      (querySnapshot) => {
        const latestPrivacyDoc = querySnapshot.docs[0]?.data().policy || '';
        const decodedPrivacyText = atob(latestPrivacyDoc);
        resolve(decodedPrivacyText);
      },
      (error) => reject(error)
    );

    return () => unsubscribe();
  });
};

export const usePrivacy = () => {
  return useQuery({
    queryKey: ['privacy'],
    queryFn: fetchPrivacy,
    staleTime: 1000 * 60 * 30,
    cacheTime: 1000 * 60 * 60,
  });
};
