import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  Box,
  Typography,
  Grid,
  Avatar,
  Switch,
  Divider,
  tooltipClasses,
  styled,
  Tooltip,
  CircularProgress,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import MainLayout from '../../hoc/MainLayout';
import './AddGoal.css';
import useStudentsForOneTeacher from '../../hooks/useStudentsForOneTeacher';
import AuthContext from '../../contexts/AuthContext';
import usePatientsForDoctor from '../../hooks/usePatientsForDoctor';
import { ACM } from '../../constants/UserRoles';
import { doc, setDoc, Timestamp } from 'firebase/firestore';
import { FirestoreDB } from '../../utils/firebase/firebase';
import NewGoalDialog from '../../components/NewGoalDialog';
import useAllStudentGoalsData from '../../hooks/useAllStudentGoalsData';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import InfoIcon from '@mui/icons-material/Info';
import {
  BEHAVIOR_TYPE_CATEGORIES,
  CATEGORY_TOOLTIPS,
  SKILL_ACQUISITION_TYPES,
} from '../../constants/GoalsConstants';
import {
  fetchingTeachersForStudents,
  updateGoalDataCollection,
} from '../../api';
import useFetchPreRegisteredUserForTeacher from '../../hooks/useFetchPreRegisteredUserForTeacher';
const ITEM_HEIGHT = 70;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AddGoalDetails = () => {
  const { user, typeOfUser } = useContext(AuthContext);
  const [dataEntryDialogOpen, setDataEntryDialogOpen] = useState(false);
  const location = useLocation();
  const goalData = location.state?.goalData || {};
  const goals = location.state?.goals || {};
  console.log('goalData: ', goalData, 'goals, ', goals);
  const [formState, setFormState] = useState({
    selectedStudent: '',
    selectedPatient: '',
    studentName: '',
    patientName: '',
    studentID: '',
    patientID: '',
    goalTitle: '',
    goalCategory: [],
    typeOfGoal: '',
    behaviorType: '',
    skillType: '',
    skillName: '',
    skillDefinition: '',
    skillNaturalCue: '',
    parentTrainingSkillType: '',
    parentTrainingSkillName: '',
    parentTrainingDefinition: '',
    parentTrainingNaturalCue: '',
    behavior: '',
    definition: '',
    onset: '',
    offset: '',
    goalMeasurement: '',
    measurementUnit: '',
    target: '',
    measurement: '',
    multipleDataPoints: false,
    dataPointAction: '',
    multipleTimesToMeetGoal: '',
    numberOfSessions: '',
    consecutive: false,
    sessionsRequired: '',
    startdate: '',
    byDate: '',
    baseline: '',
    notes: '',
    hasSubgoal: false,
    goalType: '',
    editAccess: [],
    viewAccess: [],
    generateGraphs: false,
    enableNotifications: false,
    progress_declining: false,
    progress_100_more_than_sessions: false,
    progress_no_data: false,
    progress_goal_stagnation: false,
    progress_on_subobjective: false,
    generateReports: false,
    shareWithTeam: false,
    dataType: '',
    definePlus: '',
    defineMinus: '',
    minutes: '',
    trailsMin: 0,
    trailsMax: 0,
    isPromptingApplicable: false,
    promptingType: '',
    pMinus: '',
    pPlus: '',
    I: 0,
    VP: 0,
    IVP: 0,
    DVP: 0,
    PVP: 0,
    GP: 0,
    PP: 0,
    antecedent: '',
    targetBehavior: '',
    criteria: [],
    subgoals: [], // Added for subgoals
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const {
    data: preRegisteredUsers = [],
    // isLoading: isLoading,
    error: errorStudentData,
    refetch,
  } = useFetchPreRegisteredUserForTeacher(user?.uid);

  const {
    data: fetchStudentsForOneTeacher = [],
    isLoading: isTeachersLoading,
    error: teachersError,
    refetch: refetchTeachers,
  } = useStudentsForOneTeacher(user?.uid, typeOfUser);

  const {
    data: patientData = [],
    isLoading,
    error: errorPatientData,
  } = usePatientsForDoctor(user?.uid);

  const enrolledStudents = useMemo(
    () => fetchStudentsForOneTeacher || [],
    [fetchStudentsForOneTeacher]
  );

  const combinedStudents = useMemo(() => {
    const enrolledStudentNames = enrolledStudents.map((student) => ({
      id: student.id,
      name: student.name,
    }));

    const preRegisteredUserNames = preRegisteredUsers.map((user) => ({
      id: user.id, // assuming preRegisteredUsers have an 'id' field
      name: user.name,
    }));

    // Combine both arrays
    return [...enrolledStudentNames, ...preRegisteredUserNames];
  }, [enrolledStudents, preRegisteredUsers]);

  const {
    data: studentGoalsData = [],
    isLoading: isStudentGoalsLoading,
    error: studentGoalsError,
    refetch: refetchStudentGoals,
  } = useAllStudentGoalsData(user?.uid, typeOfUser);

  const truncateName = (name, limit = 10) => {
    const capitalized = capitalizeWords(name);
    if (capitalized?.length > limit) {
      return `${capitalized.slice(0, limit)}...`;
    }
    return capitalized;
  };

  const capitalizeWords = (name) => {
    return name
      ?.split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  const addSubgoal = () => {
    setFormState((prev) => {
      // Get the current number of subgoals
      const subgoalCount = prev.subgoals.length;

      return {
        ...prev,
        subgoals: [
          ...prev.subgoals,
          {
            target: '',
            measurement: '',
            criteria: prev.criteria.map((criterion) => ({ ...criterion })),
            isPromptingApplicable: false,
            promptingType: '',
            pMinus: 0,
            pPlus: 0,
            I: 0,
            VP: 0,
            IVP: 0,
            DVP: 0,
            PVP: 0,
            GP: 0,
            PP: 0,
            multipleTimesToMeetGoal: 'No',
            numberOfSessions: '',
            consecutive: 'No',
            isCurrentGoal: subgoalCount === 0,
          },
        ],
      };
    });
  };

  const handleSubgoalCriterionChange = (
    subgoalIndex,
    criterionIndex,
    field,
    value
  ) => {
    setFormState((prev) => {
      const updatedSubgoals = [...prev.subgoals];
      if (!updatedSubgoals[subgoalIndex]) {
        return prev;
      }

      if (!updatedSubgoals[subgoalIndex].criteria) {
        updatedSubgoals[subgoalIndex].criteria = [];
      }

      const updatedCriteria = [...updatedSubgoals[subgoalIndex].criteria];
      if (!updatedCriteria[criterionIndex]) {
        updatedCriteria[criterionIndex] = {
          measurementType: '',
          target: '',
          measurement: '',
          variable: '',
        };
      }

      updatedCriteria[criterionIndex] = {
        ...updatedCriteria[criterionIndex],
        [field]: value,
      };

      updatedSubgoals[subgoalIndex] = {
        ...updatedSubgoals[subgoalIndex],
        criteria: updatedCriteria,
      };

      return {
        ...prev,
        subgoals: updatedSubgoals,
      };
    });
  };
  const removeSubgoal = (indexToRemove) => {
    setFormState((prev) => {
      const updatedSubgoals = prev.subgoals.filter(
        (_, index) => index !== indexToRemove
      );

      if (
        prev.subgoals[indexToRemove]?.isCurrentGoal &&
        updatedSubgoals.length > 0
      ) {
        updatedSubgoals[0].isCurrentGoal = true;
      }

      return {
        ...prev,
        subgoals: updatedSubgoals,
      };
    });
  };

  const addCriterion = () => {
    setFormState((prev) => ({
      ...prev,
      criteria: [
        ...prev.criteria,
        { measurementType: '', target: '', measurement: '', variable: '' },
      ],
    }));
  };

  const handleCriterionChange = (index, field, value) => {
    setFormState((prev) => {
      const updatedCriteria = [...prev.criteria];
      updatedCriteria[index] = {
        ...updatedCriteria[index],
        [field]: value,
      };
      return {
        ...prev,
        criteria: updatedCriteria,
      };
    });
  };

  const removeCriterion = (indexToRemove) => {
    setFormState((prev) => ({
      ...prev,
      criteria: prev.criteria.filter((_, index) => index !== indexToRemove),
    }));
  };
  const selectedStudentGoals = useMemo(() => {
    if (!formState.studentID && !formState.patientID) return [];

    return studentGoalsData?.filter(
      (goal) =>
        goal.id === formState.studentID || goal.id === formState.patientID
    );
  }, [formState.studentID, formState.patientID, studentGoalsData]);

  const existingGoalNumbers =
    ACM[typeOfUser]?.isDoctor || ACM[typeOfUser]?.isTeacher
      ? selectedStudentGoals?.flatMap((goalData) =>
          goalData.dataCollection === 'No data available'
            ? 0
            : goalData?.dataCollection?.map(
                (item) => Number(item?.info?.GoalDetails?.GoalNumber) || 0
              )
        )
      : studentGoalsData?.map(
          (goal) => Number(goal?.studentData?.GoalDetails?.GoalNumber) || 0
        ) || [];

  const goalNumber =
    goalData && goalData.GoalDetails?.GoalNumber
      ? goalData.GoalDetails.GoalNumber
      : ACM[typeOfUser]?.isDoctor || ACM[typeOfUser]?.isTeacher
      ? existingGoalNumbers && existingGoalNumbers.length === 0
        ? 1
        : Math.max(...(existingGoalNumbers || [0])) + 1
      : existingGoalNumbers && existingGoalNumbers.length === 0
      ? 1
      : Math.max(...(existingGoalNumbers || [0])) + 1;

  const goalTitle =
    selectedStudentGoals?.flatMap((data) =>
      Array.isArray(data?.dataCollection)
        ? data.dataCollection.map(
            (collection) => collection?.info?.GoalDetails?.GoalTitle || ''
          )
        : []
    ) || [];

  const handleChange = (event) => {
    if (event === null) {
      // Handle clearing the date
      setFormState((prev) => ({
        ...prev,
        startdate: '',
        byDate: '', // Store as empty string when cleared
      }));
      return;
    }
    if (event instanceof Date) {
      // Adjust for timezone offset to ensure the correct date is stored
      const localDate = new Date(
        event.getTime() - event.getTimezoneOffset() * 60000
      );
      const formattedDate = localDate.toISOString().split('T')[0];

      setFormState((prev) => ({
        ...prev,
        startdate: formattedDate,
        byDate: formattedDate, // Store as 'YYYY-MM-DD'
      }));
      return;
    }

    const { name, value, type, checked } = event.target;

    if (name === 'selectedStudent') {
      const selectedStudent = combinedStudents?.find(
        (student) => student.name === value
      );

      setFormState((prev) => ({
        ...prev,
        selectedStudent: value,
        studentName: value,
        studentID: selectedStudent ? selectedStudent.id : '',
      }));
    } else if (name === 'selectedPatient') {
      const selectedPatient = patientData.find(
        (patient) => patient.patientId === value
      );

      setFormState((prev) => ({
        ...prev,
        selectedPatient: value,
        patientName: selectedPatient
          ? selectedPatient.patientInfo.fullname
          : '',
        patientID: selectedPatient ? selectedPatient.patientId : '',
      }));
    } else if (name === 'goalCategory') {
      setFormState((prev) => {
        const updatedCategories = checked
          ? [...prev.goalCategory, value]
          : prev.goalCategory.filter((category) => category !== value);

        return {
          ...prev,
          goalCategory: updatedCategories,
        };
      });
    } else if (name === 'editAccess') {
      setFormState((prev) => {
        const updatedEditAccess = checked
          ? [...prev.editAccess, value]
          : prev.editAccess.filter((edit) => edit !== value);
        return {
          ...prev,
          editAccess: updatedEditAccess,
        };
      });
    } else if (name === 'viewAccess') {
      setFormState((prev) => {
        const updatedViewAccess = checked
          ? [...prev.viewAccess, value]
          : prev.viewAccess.filter((view) => view !== value);
        return {
          ...prev,
          viewAccess: updatedViewAccess,
        };
      });
    } else if (name === 'goalMeasurement') {
      // Handle goalMeasurement change
      setFormState((prev) => ({
        ...prev,
        goalMeasurement: value,
        dataType:
          value === 'Frequency'
            ? 'times'
            : value === 'Duration'
            ? 'min'
            : value === 'Percentage'
            ? '%'
            : value === '+/-'
            ? '+/-'
            : value === '%'
            ? '+/-'
            : value === 'times'
            ? 'times'
            : value === 'minutes'
            ? 'min'
            : value === 'others'
            ? ''
            : '',
      }));
    } else if (name === 'dataType') {
      setFormState((prev) => ({
        ...prev,
        dataType: value === 'others' ? '' : value,
      }));
    } else if (name === 'hasSubgoal') {
      const hasSubgoal = value === 'Yes';

      const initialSubgoals = hasSubgoal
        ? [
            {
              target: '',
              measurement: '',
              criteria: formState.criteria,
              isPromptingApplicable: false,
              promptingType: '',
              pMinus: 0,
              pPlus: 0,
              I: 0,
              VP: 0,
              IVP: 0,
              DVP: 0,
              PVP: 0,
              GP: 0,
              PP: 0,
              multipleTimesToMeetGoal: 'No',
              numberOfSessions: '',
              consecutive: 'No',
              isCurrentGoal: true,
            },
          ]
        : [];

      setFormState((prev) => ({
        ...prev,
        hasSubgoal,
        subgoals: initialSubgoals,
      }));
    } else {
      setFormState((prev) => ({
        ...prev,
        [name]: type === 'checkbox' || type === 'switch' ? checked : value,
      }));
    }
  };

  const showToastMessage = () => {
    toast.success('Goal added successfully', {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  };

  const createGoalParams = () => {
    return {
      StudentName:
        formState.studentName || formState.patientName || user.fullname || '',
      StudentID: formState.studentID || formState.patientID || user.uid || '',
      GoalDetails: {
        GoalTitle: formState.goalTitle || '',
        GoalNumber: goalNumber || goalData.GoalDetails?.GoalNumber || '',
        GoalCategory: formState.goalCategory || [],
        TypeOfGoal: {
          ...(formState.typeOfGoal === 'Behavior'
            ? {
                Type: formState.typeOfGoal || '',
                BehaviorType: formState.behaviorType || '',
                BehaviorName: formState.behavior || '',
                Definition: formState.definition || '',
                Onset: formState.onset || '',
                Offset: formState.offset || '',
              }
            : formState.typeOfGoal === 'Parent Training'
            ? {
                Type: formState.typeOfGoal || '',
                ParentTrainingSkillType:
                  formState.parentTrainingSkillType || '',
                ParentTrainingSkillName:
                  formState.parentTrainingSkillName || '',
                ParentTrainingDefinition:
                  formState.parentTrainingDefinition || '',
                ParentTrainingNaturalCue:
                  formState.parentTrainingNaturalCue || '',
              }
            : {
                Type: formState.typeOfGoal || '',
                SkillType: formState.skillType || '',
                SkillName: formState.skillName || '',
                SkillDefinition: formState.skillDefinition || '',
                SkillNaturalCue: formState.skillNaturalCue || '',
              }),
        },
      },
      DataCollection: {
        antecedent: { Antecedent: formState.antecedent || '' },
        targetBehavior: { TargetBehavior: formState.targetBehavior || '' },
        measurementType: {
          ...(formState.goalMeasurement === 'Trials'
            ? {
                Type: formState.goalMeasurement || '',
                DataType: formState.dataType || '',
                measurementUnit: formState.measurementUnit || '',
                DefinePlus: formState.definePlus || '',
                DefineMinus: formState.defineMinus || '',
                Target: formState.target || '',
                TargetDuration: formState.measurement || '',
                TrailsValue: {
                  TrailsMin: formState.trailsMin || 0,
                  TrailsMax: formState.trailsMax || 0,
                },
                Criteria: formState.criteria.map((criterion) => ({
                  MeasurementType: criterion.measurementType || '',
                  Target: criterion.target || '',
                  TargetDuration: criterion.measurement || '',
                  Variable: criterion.variable || '',
                })),
              }
            : {
                Type: formState.goalMeasurement || '',
                Target: formState.target || '',
                TargetDuration: formState.measurement || '',
                measurementUnit: formState.measurementUnit || '',
                Criteria: formState.criteria.map((criterion) => ({
                  MeasurementType: criterion.measurementType || '',
                  Target: criterion.target || '',
                  TargetDuration: criterion.measurement || '',
                  Variable: criterion.variable || '',
                })),
              }),
        },
        PromptingApplicable: {
          ...(formState.isPromptingApplicable === true &&
          formState.promptingType === 'P+ & P-'
            ? {
                PromptingApplicable: formState.isPromptingApplicable || '',
                PromptingType: formState.promptingType || '',
                pMinus: formState.pMinus || 0,
                pPlus: formState.pPlus || 0,
              }
            : formState.isPromptingApplicable === true &&
              formState.promptingType === 'IVP, DVP, PVP, GP, PP'
            ? {
                PromptingApplicable: formState.isPromptingApplicable || '',
                PromptingType: formState.promptingType || '',
                I: formState.I || 0,
                VP: formState.VP || 0,
                IVP: formState.IVP || 0,
                DVP: formState.DVP || 0,
                PVP: formState.PVP || 0,
                GP: formState.GP || 0,
                PP: formState.PP || 0,
              }
            : {
                PromptingApplicable: formState.isPromptingApplicable || '',
              }),
        },
        MultipleDataPointsPerSession: {
          MultipleDataPointsPerSession: formState.multipleDataPoints || '',
          DataPointPerSession: formState.dataPointAction || 'NA',
        },
        MultipleTimesToMeetGoal: {
          MultipleTimesToMeetGoal: formState.multipleTimesToMeetGoal || '',
          NumberOfSessions: formState.numberOfSessions || 'NA',
          ConsecutiveSessions: formState.consecutive || 'NA',
        },
        StartDate: formState.startdate || '',
        ByDate: formState.byDate || '',
        Baseline: formState.baseline || '',
        Notes: formState.notes || '',
      },
      HasSubgoal: {
        HasSubgoal: formState.hasSubgoal ? 'Yes' : 'No',
        LinkedGoal: formState.goalType || 'NA',
        Subgoals: formState.subgoals.map((subgoal) => ({
          Target: subgoal.target || '',
          Measurement: subgoal.measurement || '',
          Criteria: subgoal.criteria || [],
          PromptingApplicable: {
            ...(subgoal.isPromptingApplicable === true &&
            subgoal.promptingType === 'P+ & P-'
              ? {
                  PromptingApplicable: subgoal.isPromptingApplicable
                    ? 'Yes'
                    : 'No',
                  PromptingType: subgoal.promptingType || '',
                  pMinus: subgoal.pMinus || 0,
                  pPlus: subgoal.pPlus || 0,
                }
              : subgoal.isPromptingApplicable === true &&
                subgoal.promptingType === 'IVP, DVP, PVP, GP, PP'
              ? {
                  PromptingApplicable: subgoal.isPromptingApplicable
                    ? 'Yes'
                    : 'No',
                  PromptingType: subgoal.promptingType || '',
                  I: subgoal.I || 0,
                  VP: subgoal.VP || 0,
                  IVP: subgoal.IVP || 0,
                  DVP: subgoal.DVP || 0,
                  PVP: subgoal.PVP || 0,
                  GP: subgoal.GP || 0,
                  PP: subgoal.PP || 0,
                }
              : {
                  PromptingApplicable: subgoal.isPromptingApplicable
                    ? 'Yes'
                    : 'No',
                }),
          },
          MultipleTimesToMeetGoal: {
            MultipleTimesToMeetGoal: subgoal.multipleTimesToMeetGoal || 'No',
            NumberOfSessions: subgoal.numberOfSessions || 'NA',
            ConsecutiveSessions: subgoal.consecutive || 'NA',
          },
          CurrentGoal: subgoal.isCurrentGoal ? 'Yes' : 'No',
        })),
      },
      Generate: {
        Graphs: formState.generateGraphs || 'false',
        Reports: formState.generateReports || 'false',
      },
      ShareWithTeam: {
        IsSharingEnabled: formState.shareWithTeam || 'false',
        EditAccess: formState.editAccess || 'false',
        ViewOnlyAccess: formState.viewAccess || 'false',
      },
      Notifications: {
        EnableNotifications: formState.enableNotifications || false,
        ProgressDecling: formState.progress_declining || false,
        Progress100MoreThanSessions:
          formState.progress_100_more_than_sessions || false,
        ProgressNoData: formState.progress_no_data || false,
        ProgressGoalStagnation: formState.progress_goal_stagnation || false,
        ProgressOnSubobjective: formState.progress_on_subobjective || false,
      },
    };
  };

  const updateGoalsDataCollectionFile = async (id, goalId) => {
    try {
      const params = createGoalParams();
      await updateGoalDataCollection(
        user?.uid,
        typeOfUser,
        id,
        goalId,
        btoa(JSON.stringify(params))
      );
      console.log('Update Params', params);
      showToastMessage('Goal updated successfully');
      handleClose();
    } catch (error) {
      console.error('Error updating goal:', error);
      showToastMessage(error.message);
    }
  };

  const addGoalsDataCollectionFile = async (id) => {
    console.log('id: ', id);
    try {
      const goalsArray = Array.isArray(goals) ? goals : [goals];

      const matchedGoals = Array.isArray(
        selectedStudentGoals[0]?.dataCollection
      )
        ? selectedStudentGoals[0]?.dataCollection.map((data) => ({
            ...data,
            exists: goalsArray?.some((goal) => goal?.goalId === data?.id)
              ? 'Existing Data'
              : 'New Data',
          }))
        : []; // Default to an empty array if dataCollection is not an array

      const existedGoals = matchedGoals?.filter(
        (goal) => goal.exists === 'Existing Data'
      );

      if (existedGoals?.length > 0) {
        await updateGoalsDataCollectionFile(id, existedGoals[0].id);
        return;
      }

      // If no existing goals, proceed to create a new one
      const goalID = uuidv4();
      const params = createGoalParams();

      console.log('Creating new goal:', { goalID, params });
      console.log('Add on params', params);

      // Check if the id exists in preRegisteredUsers
      const isPreRegisteredUser = preRegisteredUsers.some(
        (preUSer) => preUSer.id === id
      );

      console.log('isPreRegisteredUser', isPreRegisteredUser);

      // Determine the collection based on whether the user is pre-registered or not
      const collection = isPreRegisteredUser
        ? 'preRegisteredUsers'
        : ACM[typeOfUser]?.isDoctor || ACM[typeOfUser]?.isPatient
        ? 'patientsList'
        : 'studentsList';

      console.log('collection', collection);

      // Save the goal in the appropriate collection
      await setDoc(doc(FirestoreDB, collection, id, 'dataCollection', goalID), {
        info: btoa(JSON.stringify(params)),
        createdAt: Timestamp.now(),
        isEncrypted: false,
      });

      handleClose();
      showToastMessage();
    } catch (error) {
      console.error('Error message:', error);
      showToastMessage(error.message);
    }
  };

  const addGoalDetailsSaveHandler = async () => {
    const toastOptions = {
      position: toast.POSITION.BOTTOM_RIGHT,
    };

    if (!formState.goalTitle) {
      toast.error('Please enter a goal title.', toastOptions);
      return;
    }
    if (!formState.antecedent) {
      toast.error('Please enter an antecedent.', toastOptions);
      return;
    }
    if (!formState.targetBehavior) {
      toast.error('Please enter a targetBehavior.', toastOptions);
      return;
    }
    if (!formState.multipleTimesToMeetGoal) {
      toast.error(
        'Please specify whether the target needs to be achieved multiple times to meet the goal.',
        toastOptions
      );
      return;
    }
    if (formState.multipleTimesToMeetGoal === 'Yes') {
      if (!formState.numberOfSessions) {
        toast.error('Please enter number of sessions.', toastOptions);
        return;
      }
      if (!formState.consecutive) {
        toast.error(
          'Please enter if sessions have to be consecutive.',
          toastOptions
        );
        return;
      }
    }
    if (formState.goalCategory.length === 0) {
      toast.error('Please select at least one goal category.', toastOptions);
      return;
    }
    if (!formState.typeOfGoal) {
      toast.error('Please select a type of goal.', toastOptions);
      return;
    }
    if (!formState.goalMeasurement) {
      toast.error('Please select how the goal is measured.', toastOptions);
      return;
    }
    if (!formState.target) {
      toast.error('Please select a target value.', toastOptions);
      return;
    }
    if (!formState.measurement) {
      toast.error(
        'Please enter a valid target value for the goal measurement.',
        toastOptions
      );
      return;
    }
    if (!formState.byDate) {
      toast.error('Please select a By Date.', toastOptions);
      return;
    }

    setLoading(true); // Start loading
    try {
      if (ACM[typeOfUser]?.isDoctor || ACM[typeOfUser]?.isTeacher) {
        await addGoalsDataCollectionFile(
          ACM[typeOfUser]?.isDoctor ? formState.patientID : formState.studentID
        );
      } else {
        await addGoalsDataCollectionFile(user.uid);
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false); // Stop loading after operation completes
    }
  };

  const handleClose = () => {
    setFormState({
      selectedStudent: '',
      selectedPatient: '',
      studentName: '',
      studentID: '',
      goalTitle: '',
      goalNumber: '',
      goalCategory: [],
      typeOfGoal: '',
      behaviorType: '',
      skillType: '',
      skillName: '',
      skillDefinition: '',
      skillNaturalCue: '',
      parentTrainingSkillType: '',
      parentTrainingSkillName: '',
      parentTrainingDefinition: '',
      parentTrainingNaturalCue: '',
      behavior: '',
      definition: '',
      onset: '',
      offset: '',
      goalMeasurement: '',
      target: 0,
      measurement: '',
      multipleDataPoints: false,
      dataPointAction: '',
      multipleTimesToMeetGoal: '',
      numberOfSessions: '',
      consecutive: false,
      sessionsRequired: '',
      startdate: '',
      byDate: '',
      antecedent: '',
      targetBehavior: '',
      baseline: '',
      notes: '',
      hasSubgoal: false,
      goalType: '',

      editAccess: [],
      viewAccess: [],
      generateGraphs: false,
      generateReports: false,
      shareWithTeam: false,
      enableNotifications: false,
      progress_declining: false,
      progress_100_more_than_sessions: false,
      progress_no_data: false,
      progress_goal_stagnation: false,
      progress_on_subobjective: false,
      dataType: '',
      definePlus: '',
      defineMinus: '',
      minutes: '',
      trailsMin: 0,
      trailsMax: 0,
      isPromptingApplicable: false,
      promptingType: '',
      pMinus: '',
      pPlus: '',
      I: 0,
      VP: 0,
      IVP: 0,
      DVP: 0,
      PVP: 0,
      GP: 0,
      PP: 0,
      criteria: [],
      subgoals: [], // Added for subgoals
    });
  };

  const [roleNames, setRoleNames] = useState([]);

  const fetchTeachersForStudentData = useCallback(async () => {
    if (!formState.studentID || !ACM[typeOfUser]?.isTeacher) return;

    console.log('selected students: ', formState.studentID);

    try {
      const fetchedData = await fetchingTeachersForStudents(
        formState.studentID
      );
      console.log('fetched data::', fetchedData);
      // Map through the fetched data and extract subRoleName
      const roles = [
        ...new Set(
          fetchedData?.map((item) => item.info?.subRoleName).filter(Boolean)
        ),
      ];

      console.log('roles:', roles);
      setRoleNames(roles);
    } catch (err) {
      console.log('Error fetching students:', err);
    }
  }, [formState.studentID, typeOfUser]);

  console.log('roleNames', roleNames);

  useEffect(() => {
    fetchTeachersForStudentData();
  }, [fetchTeachersForStudentData]);

  useEffect(() => {
    if (
      (goalData && Object.keys(goalData).length > 0) ||
      (goals && Object.keys(goalData).length > 0)
    ) {
      console.log('Updating formState with goalData:', goalData);

      setFormState((prevState) => ({
        ...prevState,
        ...(ACM[typeOfUser]?.isDoctor
          ? {
              selectedPatient: goalData.StudentID || '',
              patientName: goalData.StudentName || '',
              patientID: goalData.StudentID || '',
            }
          : {
              selectedStudent: goalData.StudentName || '',
              studentName: goalData.StudentName || '',
              studentID: goalData.StudentID || '',
            }),

        goalTitle: goalData.GoalDetails?.GoalTitle || '',
        goalNumber: goalData.GoalDetails?.GoalNumber || '',
        goalCategory: goalData.GoalDetails?.GoalCategory || [],
        typeOfGoal: goalData.GoalDetails?.TypeOfGoal?.Type || '',
        behaviorType: goalData.GoalDetails?.TypeOfGoal?.BehaviorType || '',
        behavior: goalData.GoalDetails?.TypeOfGoal?.BehaviorName || '',
        definition: goalData.GoalDetails?.TypeOfGoal?.Definition || '',
        onset: goalData.GoalDetails?.TypeOfGoal?.Onset || '',
        offset: goalData.GoalDetails?.TypeOfGoal?.Offset || '',
        antecedent: goalData?.DataCollection?.antecedent?.Antecedent || '',
        targetBehavior:
          goalData?.DataCollection?.targetBehavior?.TargetBehavior || '',
        skillType: goalData.GoalDetails?.TypeOfGoal?.SkillType || '',
        skillName: goalData.GoalDetails?.TypeOfGoal?.SkillName || '',
        skillDefinition:
          goalData.GoalDetails?.TypeOfGoal?.SkillDefinition || '',
        skillNaturalCue:
          goalData.GoalDetails?.TypeOfGoal?.SkillNaturalCue || '',
        parentTrainingSkillType:
          goalData.GoalDetails?.TypeOfGoal?.ParentTrainingSkillType ||
          goalData.GoalDetails?.TypeOfGoal?.ParentSkillType ||
          '',
        parentTrainingSkillName:
          goalData.GoalDetails?.TypeOfGoal?.ParentTrainingSkillName || '',
        parentTrainingDefinition:
          goalData.GoalDetails?.TypeOfGoal?.ParentTrainingDefinition || '',
        parentTrainingNaturalCue:
          goalData.GoalDetails?.TypeOfGoal?.ParentTrainingNaturalCue || '',
        dataType: goalData.DataCollection?.measurementType?.DataType || '',
        goalMeasurement: goalData.DataCollection?.measurementType?.Type || '',
        measurementUnit:
          goalData.DataCollection?.measurementType?.measurementUnit || '',
        target: goalData.DataCollection?.measurementType?.Target || '',
        measurement:
          goalData.DataCollection?.measurementType?.TargetDuration || '',
        defineMinus:
          goalData.DataCollection?.measurementType?.DefineMinus || '',
        definePlus: goalData.DataCollection?.measurementType?.DefinePlus || '',
        isPromptingApplicable:
          goalData.DataCollection?.PromptingApplicable?.PromptingApplicable ===
          true,
        promptingType:
          goalData.DataCollection?.PromptingApplicable?.PromptingType || '',
        pMinus: goalData.DataCollection?.PromptingApplicable?.pMinus || '',
        pPlus: goalData.DataCollection?.PromptingApplicable?.pPlus || '',
        I: goalData.DataCollection?.PromptingApplicable?.I || 0,
        VP: goalData.DataCollection?.PromptingApplicable?.VP || 0,
        IVP: goalData.DataCollection?.PromptingApplicable?.IVP || 0,
        DVP: goalData.DataCollection?.PromptingApplicable?.DVP || 0,
        PVP: goalData.DataCollection?.PromptingApplicable?.PVP || 0,
        GP: goalData.DataCollection?.PromptingApplicable?.GP || 0,
        PP: goalData.DataCollection?.PromptingApplicable?.PP || 0,
        measurementUnit:
          goalData.DataCollection?.measurementType?.measurementUnit || '',
        trailsMin:
          goalData.DataCollection?.measurementType?.TrailsValue?.TrailsMin ||
          '',
        trailsMax:
          goalData.DataCollection?.measurementType?.TrailsValue?.TrailsMax ||
          '',
        multipleDataPoints:
          goalData.DataCollection?.MultipleDataPointsPerSession
            ?.MultipleDataPointsPerSession || false,
        dataPointAction:
          goalData.DataCollection?.MultipleDataPointsPerSession
            ?.DataPointPerSession || '',
        multipleTimesToMeetGoal:
          goalData.DataCollection?.MultipleTimesToMeetGoal
            ?.MultipleTimesToMeetGoal || false,
        numberOfSessions:
          goalData.DataCollection?.MultipleTimesToMeetGoal?.NumberOfSessions ||
          '',
        consecutive:
          goalData.DataCollection?.MultipleTimesToMeetGoal
            ?.ConsecutiveSessions || false,
        byDate: goalData.DataCollection?.ByDate || '',
        startdate: goalData.DataCollection?.StartDate || '',
        baseline: goalData.DataCollection?.Baseline || '',
        notes: goalData.DataCollection?.Notes || '',
        hasSubgoal: goalData.HasSubgoal?.HasSubgoal === 'Yes',
        subgoals:
          goalData.HasSubgoal?.HasSubgoal === 'Yes'
            ? goalData.HasSubgoal.Subgoals.map((subgoal) => ({
                target: subgoal.Target || '',
                measurement: subgoal.Measurement || '',
                criteria: Array.isArray(subgoal.Criteria)
                  ? subgoal.Criteria.map((criterion) => ({
                      measurementType: criterion.measurementType || '',
                      target: criterion.target || '',
                      measurement: criterion.measurement || '',
                      variable: criterion.variable || '',
                    }))
                  : [],
                isPromptingApplicable:
                  subgoal.PromptingApplicable?.PromptingApplicable === 'Yes',
                promptingType: subgoal.PromptingApplicable?.PromptingType || '',
                pMinus: subgoal.PromptingApplicable?.pMinus || '',
                pPlus: subgoal.PromptingApplicable?.pPlus || '',
                I: subgoal.PromptingApplicable?.I || 0,
                VP: subgoal.PromptingApplicable?.VP || 0,
                IVP: subgoal.PromptingApplicable?.IVP || 0,
                DVP: subgoal.PromptingApplicable?.DVP || 0,
                PVP: subgoal.PromptingApplicable?.PVP || 0,
                GP: subgoal.PromptingApplicable?.GP || 0,
                PP: subgoal.PromptingApplicable?.PP || 0,
                multipleTimesToMeetGoal:
                  subgoal.MultipleTimesToMeetGoal?.MultipleTimesToMeetGoal ||
                  'No',
                numberOfSessions:
                  subgoal.MultipleTimesToMeetGoal?.NumberOfSessions || '',
                consecutive:
                  subgoal.MultipleTimesToMeetGoal?.ConsecutiveSessions || 'No',
                isCurrentGoal: subgoal.CurrentGoal === 'Yes',
              }))
            : [],
        generateGraphs: goalData.Generate?.Graphs === true,
        generateReports: goalData.Generate?.Reports === true,
        shareWithTeam: goalData.ShareWithTeam?.IsSharingEnabled === true,
        editAccess: goalData.ShareWithTeam?.EditAccess || [],
        viewAccess: goalData.ShareWithTeam?.ViewOnlyAccess || [],
        antecedent: goalData?.DataCollection?.antecedent?.Antecedent,
        targetBehavior:
          goalData?.DataCollection?.targetBehavior?.TargetBehavior || '',
        criteria: Array.isArray(
          goalData.DataCollection?.measurementType?.Criteria
        )
          ? goalData.DataCollection?.measurementType?.Criteria.map(
              (criterion) => ({
                measurementType: criterion.MeasurementType || '',
                target: criterion.Target || '',
                measurement: criterion.TargetDuration || '',
                variable: criterion.Variable || '',
              })
            )
          : [],
        enableNotifications:
          goalData?.Notifications?.EnableNotifications || false,
        progress_declining: goalData?.Notifications?.ProgressDecling || false,
        progress_100_more_than_sessions:
          goalData?.Notifications?.Progress100MoreThanSessions || false,
        progress_no_data: goalData?.Notifications?.ProgressNoData || false,
        progress_goal_stagnation:
          goalData?.Notifications?.ProgressGoalStagnation || false,
        progress_on_subobjective:
          goalData?.Notifications?.ProgressOnSubobjective || false,
      }));
    }
  }, [goalData]);

  const handleDataEntry = () => {
    setDataEntryDialogOpen(true);
  };

  const getRandomPastelColor = (name) => {
    const hash = [...name].reduce((acc, char) => acc + char.charCodeAt(0), 0);
    const hue = hash % 360;
    return `hsl(${hue}, 70%, 80%)`;
  };

  return (
    <MainLayout>
      <Box className='add-goal-details' p={2}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={3} px={3}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Typography
                  variant='h5'
                  className='heading'
                  sx={{ textAlign: 'center', py: 3, mt: 10 }}
                >
                  New Goal Details
                </Typography>
                <Button
                  fullWidth
                  variant='contained'
                  onClick={handleDataEntry}
                  sx={{
                    backgroundColor: 'var(--clr-mindly-purple)',
                    '&:hover': {
                      backgroundColor: 'var(--clr-mindly-purple-hover)',
                    },
                    fontWeight: 'bold',
                    textTransform: 'capitalize',
                  }}
                >
                  Data Entry
                </Button>
                <Button
                  fullWidth
                  variant='contained'
                  onClick={() => navigate('/allgoals')}
                  sx={{
                    backgroundColor: 'var(--clr-mindly-purple)',
                    '&:hover': {
                      backgroundColor: 'var(--clr-mindly-purple-hover)',
                    },
                    fontWeight: 'bold',
                    textTransform: 'capitalize',
                  }}
                >
                  Goals
                </Button>
                <Button
                  fullWidth
                  variant='contained'
                  sx={{
                    backgroundColor: 'var(--clr-mindly-purple)',
                    '&:hover': {
                      backgroundColor: 'var(--clr-mindly-purple-hover)',
                    },
                    fontWeight: 'bold',
                    textTransform: 'capitalize',
                  }}
                >
                  Statistics
                </Button>
                <Button
                  fullWidth
                  variant='contained'
                  sx={{
                    backgroundColor: 'var(--clr-mindly-purple)',
                    '&:hover': {
                      backgroundColor: 'var(--clr-mindly-purple-hover)',
                    },
                    fontWeight: 'bold',
                    textTransform: 'capitalize',
                  }}
                >
                  Reports
                </Button>
                <Button
                  fullWidth
                  variant='contained'
                  onClick={() => navigate('/pendinggoals')}
                  sx={{
                    backgroundColor: 'var(--clr-mindly-purple)',
                    '&:hover': {
                      backgroundColor: 'var(--clr-mindly-purple-hover)',
                    },
                    fontWeight: 'bold',
                    textTransform: 'capitalize',
                  }}
                >
                  Pending Goals
                </Button>
              </Box>
            </Grid>
            <Grid item xs={9}>
              <Box sx={{ maxHeight: `calc(100vh - 140px)`, overflowY: 'auto' }}>
                <Box
                  display='flex'
                  alignItems='center'
                  gap={1}
                  sx={{ flexWrap: 'wrap' }}
                >
                  {ACM[typeOfUser]?.isDoctor && (
                    <>
                      <InputLabel
                        id='demo-simple-select-label'
                        sx={{ color: 'var(--clr-mindly-black)' }}
                      >
                        Select Patient
                      </InputLabel>
                      <FormControl sx={{ minWidth: 200 }}>
                        {console.log(
                          'Matching Patient:',
                          patientData?.find(
                            (patient) =>
                              patient.patientInfo.fullname ===
                              formState.selectedPatient
                          )
                        )}
                        <Select
                          MenuProps={MenuProps}
                          labelId='demo-simple-select-label'
                          id='demo-simple-select'
                          value={formState.selectedPatient}
                          inputProps={{ 'aria-label': 'Without label' }}
                          name='selectedPatient'
                          disabled={!!location.state?.goalData}
                          onChange={handleChange}
                          sx={{
                            height: '32px',
                            borderRadius: '8px',
                            backgroundColor: 'var(--clr-mindly-white)',
                            boxShadow: '0px 0px 10px rgba(0,0,0,0.1);',
                            // border: "1px solid #9192ef;",
                          }}
                        >
                          <MenuItem value='All'>All Patient</MenuItem>
                          {patientData?.map((patient) => {
                            return (
                              <MenuItem
                                key={patient.patientId}
                                value={patient?.patientId}
                              >
                                {patient?.patientInfo?.parentName
                                  ? `${truncateName(
                                      patient.patientInfo.fullname
                                    )} (${truncateName(
                                      patient.patientInfo.parentName
                                    )})`
                                  : `${truncateName(
                                      patient.patientInfo.fullname
                                    )}`}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </>
                  )}
                  {ACM[typeOfUser]?.isTeacher && (
                    <>
                      <InputLabel
                        id='demo-simple-select-label'
                        sx={{ color: 'var(--clr-mindly-black)' }}
                      >
                        Select Student
                      </InputLabel>
                      <FormControl sx={{ minWidth: 200 }}>
                        <Select
                          labelId='demo-simple-select-label'
                          id='demo-simple-select'
                          value={formState.selectedStudent}
                          // label='Select Student'
                          inputProps={{ 'aria-label': 'Without label' }}
                          name='selectedStudent'
                          disabled={!!location.state?.goalData}
                          onChange={handleChange}
                          sx={{
                            height: '32px',
                            borderRadius: '8px',
                            backgroundColor: 'var(--clr-mindly-white)',
                            boxShadow: '0px 0px 10px rgba(0,0,0,0.1);',
                            // border: "1px solid #9192ef;",
                          }}
                        >
                          <MenuItem value='All'>All Students</MenuItem>
                          {combinedStudents.map((student) => (
                            <MenuItem
                              key={student.studentId}
                              value={student.name}
                            >
                              {truncateName(student?.name)}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </>
                  )}
                </Box>
                <Box className='student-info-box' gap={2}>
                  {ACM[typeOfUser]?.isDoctor && (
                    <>
                      {formState.patientID ? (
                        (() => {
                          const patient = patientData.find(
                            (patient) =>
                              patient?.patientId === formState.patientID
                          );

                          const photoURL = patient?.photoURL;
                          const patientName =
                            patient?.patientInfo?.fullname || 'P';
                          console.log();

                          return (
                            <Avatar
                              style={{
                                border: '2px solid #9192ef',
                                borderRadius: '50%',
                                boxShadow: '0px 0px 10px rgba(0,0,0,0.1)',
                                backgroundColor: photoURL
                                  ? 'transparent'
                                  : getRandomPastelColor(patientName),
                                color: 'black',
                                fontSize: '70px',
                              }}
                              src={photoURL || undefined}
                            >
                              {!photoURL && patientName[0].toUpperCase()}
                            </Avatar>
                          );
                        })()
                      ) : (
                        <Avatar
                          style={{
                            boxShadow: '0px 0px 10px rgba(0,0,0,0.1)',
                            backgroundColor: '#d3d3d3',
                          }}
                        />
                      )}
                    </>
                  )}
                  {ACM[typeOfUser]?.isTeacher && (
                    <>
                      {formState.studentID ? (
                        (() => {
                          const student = combinedStudents?.find(
                            (student) => student.id === formState.studentID
                          );
                          const photoURL = student?.photoURL;
                          const studentName = student?.name || 'S';
                          return (
                            <Avatar
                              style={{
                                border: '2px solid #9192ef',
                                borderRadius: '50%',
                                boxShadow: '0px 0px 10px rgba(0,0,0,0.1)',
                                backgroundColor: photoURL
                                  ? 'transparent'
                                  : getRandomPastelColor(studentName),
                                color: 'black',
                                fontSize: '70px',
                              }}
                              src={photoURL || undefined}
                            >
                              {!photoURL && studentName[0].toUpperCase()}
                            </Avatar>
                          );
                        })()
                      ) : (
                        <Avatar
                          style={{
                            boxShadow: '0px 0px 10px rgba(0,0,0,0.1)',
                            backgroundColor: '#d3d3d3',
                          }}
                        />
                      )}
                    </>
                  )}
                  {ACM[typeOfUser]?.isStudent || ACM[typeOfUser]?.isPatient ? (
                    <>
                      <Button
                        variant='outlined'
                        // fullWidth
                        disabled
                        sx={{
                          justifyContent: 'flex-start',
                          textTransform: 'none',
                          backgroundColor:
                            'var(--clr-mindly-purple) !important',
                          color: 'var(--clr-mindly-white) !important',
                          padding: '8px 14px',
                          fontWeight: 'bold',
                          minWidth: '200px',
                          opacity: 1,
                          '&.Mui-disabled': {
                            backgroundColor:
                              'var(--clr-mindly-purple) !important',
                            color: 'var(--clr-mindly-white) !important',
                            opacity: 1,
                          },
                        }}
                      >
                        {user?.fullname?.length > 20
                          ? `${user.fullname.slice(0, 20)}...`
                          : user?.fullname || 'User Name'}
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        variant='outlined'
                        // fullWidth
                        disabled
                        sx={{
                          justifyContent: 'flex-start',
                          textTransform: 'none',
                          backgroundColor:
                            'var(--clr-mindly-purple) !important',
                          color: 'var(--clr-mindly-white) !important',
                          padding: '8px 14px',
                          fontWeight: 'bold',
                          minWidth: '200px',
                          opacity: 1,
                          '&.Mui-disabled': {
                            backgroundColor:
                              'var(--clr-mindly-purple) !important',
                            color: 'var(--clr-mindly-white) !important',
                            opacity: 1,
                          },
                        }}
                      >
                        {ACM[typeOfUser]?.isTeacher
                          ? formState.studentName?.length > 20
                            ? `${formState.studentName.slice(0, 20)}...`
                            : formState.studentName || 'Student Name'
                          : formState.patientName?.length > 20
                          ? `${formState.patientName.slice(0, 20)}...`
                          : formState.patientName || 'Patient Name'}
                      </Button>
                    </>
                  )}
                </Box>
                <Box className='goal-box'>
                  <Box className='goal-box-flex'>
                    <Typography
                      className='goal-box-typography'
                      gutterBottom
                      variant='body2'
                      sx={{ fontWeight: 600 }}
                    >
                      Goal Title
                    </Typography>
                    <TextField
                      inputProps={{ 'aria-label': 'Without label' }}
                      placeholder='Ex: Screaming'
                      fullWidth
                      value={formState.goalTitle}
                      className='goal-textfield'
                      name='goalTitle'
                      onChange={handleChange}
                      InputProps={{
                        style: {
                          height: '40px',
                          borderRadius: '8px',
                        },
                      }}
                      sx={{
                        '& .MuiOutlinedInput-notchedOutline': {
                          border: 'none',
                        },
                      }}
                    />
                  </Box>
                  <Box className='goal-box-flex'>
                    <Typography
                      className='goal-box-typography'
                      gutterBottom
                      variant='body2'
                      sx={{ fontWeight: 600 }}
                    >
                      Goal Number
                    </Typography>
                    <TextField
                      inputProps={{ 'aria-label': 'Without label' }}
                      type='number'
                      fullWidth
                      value={goalNumber}
                      className='goal-textfield'
                      name='goalNumber'
                      InputProps={{
                        readOnly: true,
                        style: {
                          height: '40px',
                          borderRadius: '8px',
                          backgroundColor: 'white',
                        },
                      }}
                      sx={{
                        '& .MuiOutlinedInput-notchedOutline': {
                          border: 'none',
                        },
                        width: '15%',
                        backgroundColor: 'white',
                        borderRadius: '8px',
                      }}
                    />
                  </Box>
                </Box>
                <Typography
                  className='goal-box-typography'
                  gutterBottom
                  variant='body2'
                  sx={{ fontWeight: 600 }}
                >
                  Goal Category
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    mb: 2,
                    fontFamily: 'Lato',
                  }}
                >
                  {[
                    'ABA',
                    'Speech',
                    'OT',
                    'Classroom',
                    'Vision',
                    'PT',
                    'APE',
                    'Mental Health',
                    'Residential',
                    'Other',
                  ].map((category) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formState.goalCategory.includes(category)}
                          onChange={handleChange}
                          name='goalCategory'
                          value={category}
                        />
                      }
                      label={
                        CATEGORY_TOOLTIPS[category] ? (
                          <Box display='flex' alignItems='center'>
                            {category}
                            <Tooltip title={CATEGORY_TOOLTIPS[category]}>
                              <InfoIcon
                                sx={{
                                  fontSize: 'small',
                                  ml: 0.5,
                                  verticalAlign: 'middle',
                                }}
                              />
                            </Tooltip>
                          </Box>
                        ) : (
                          category
                        )
                      }
                      key={category}
                      sx={{ mr: 2 }}
                    />
                  ))}
                </Box>
                <Typography
                  className='goal-box-typography'
                  variant='body2'
                  sx={{ fontWeight: 600 }}
                >
                  Type of Goal
                </Typography>
                <RadioGroup
                  row
                  name='typeOfGoal'
                  value={formState.typeOfGoal}
                  onChange={handleChange}
                  sx={{ mb: 2 }}
                >
                  <FormControlLabel
                    value='Behavior'
                    control={<Radio />}
                    label='Behavior'
                  />
                  <FormControlLabel
                    value='Skill Acquisition'
                    control={<Radio />}
                    label='Skill Acquisition'
                  />
                  <FormControlLabel
                    value='Parent Training'
                    control={<Radio />}
                    label='Parent Training'
                  />
                </RadioGroup>
                {formState.typeOfGoal === 'Behavior' && (
                  <>
                    <Box
                      className='select-student-box'
                      display='flex'
                      alignItems='center'
                      gap={1}
                      sx={{ flexWrap: 'wrap' }}
                    >
                      {/* <Typography
                        className="select-student-typography"
                        gutterBottom
                      >
                        Behavior Type
                      </Typography> */}
                      <InputLabel
                        id='demo-simple-select-label'
                        sx={{ color: 'var(--clr-mindly-black)' }}
                      >
                        Behavior Type
                      </InputLabel>
                      <FormControl sx={{ minWidth: 200 }}>
                        <Select
                          labelId='demo-simple-select-label'
                          id='demo-simple-select'
                          inputProps={{ 'aria-label': 'Without label' }}
                          name='behaviorType'
                          value={formState.behaviorType}
                          onChange={handleChange}
                          sx={{
                            height: '40px',
                            borderRadius: '8px',
                            backgroundColor: 'var(--clr-mindly-white)',
                            boxShadow: '0px 0px 10px rgba(0,0,0,0.1);',
                            // border: "1px solid #9192ef;",
                          }}
                        >
                          {BEHAVIOR_TYPE_CATEGORIES.map((type) => (
                            <MenuItem key={type} value={type}>
                              {type}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                    <Typography
                      className='goal-box-typography'
                      gutterBottom
                      variant='body2'
                      sx={{ fontWeight: 600 }}
                    >
                      Behavior
                    </Typography>
                    <TextField
                      inputProps={{ 'aria-label': 'Without label' }}
                      placeholder='Enter title'
                      value={formState.behavior}
                      className='goal-textfield'
                      name='behavior'
                      onChange={handleChange}
                      sx={{
                        width: '50%',
                        '& .MuiOutlinedInput-notchedOutline': {
                          border: 'none',
                        },
                      }}
                      InputProps={{
                        style: {
                          height: '40px',
                          borderRadius: '10px',
                        },
                      }}
                    />
                    <Typography
                      className='goal-box-typography'
                      gutterBottom
                      variant='body2'
                      sx={{ fontWeight: 600 }}
                    >
                      Definition
                    </Typography>
                    <TextField
                      inputProps={{ 'aria-label': 'Without label' }}
                      placeholder="Provider's name"
                      fullWidth
                      value={formState.definition}
                      className='goal-textfield'
                      name='definition'
                      onChange={handleChange}
                      InputProps={{
                        style: {
                          height: '40px',
                          borderRadius: '8px',
                        },
                      }}
                      sx={{
                        '& .MuiOutlinedInput-notchedOutline': {
                          border: 'none',
                        },
                      }}
                    />
                    <Grid container spacing={2} sx={{ mb: 2 }}>
                      <Grid item xs={6}>
                        <Typography
                          className='goal-box-typography'
                          gutterBottom
                          variant='body2'
                          sx={{ fontWeight: 600 }}
                        >
                          Onset
                        </Typography>
                        <TextField
                          inputProps={{ 'aria-label': 'Without label' }}
                          placeholder='Enter progress notes here'
                          fullWidth
                          value={formState.onset}
                          className='goal-textfield'
                          name='onset'
                          onChange={handleChange}
                          InputProps={{
                            style: {
                              height: '40px',
                              borderRadius: '8px',
                            },
                          }}
                          sx={{
                            '& .MuiOutlinedInput-notchedOutline': {
                              border: 'none',
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          className='goal-box-typography'
                          gutterBottom
                          variant='body2'
                          sx={{ fontWeight: 600 }}
                        >
                          Offset
                        </Typography>
                        <TextField
                          inputProps={{ 'aria-label': 'Without label' }}
                          placeholder='Enter progress notes here'
                          fullWidth
                          value={formState.offset}
                          className='goal-textfield'
                          name='offset'
                          onChange={handleChange}
                          InputProps={{
                            style: {
                              height: '40px',
                              borderRadius: '8px',
                            },
                          }}
                          sx={{
                            '& .MuiOutlinedInput-notchedOutline': {
                              border: 'none',
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </>
                )}
                {formState.typeOfGoal === 'Skill Acquisition' && (
                  <>
                    <Box
                      className='select-student-box'
                      display='flex'
                      alignItems='center'
                      gap={1}
                      sx={{ flexWrap: 'wrap' }}
                    >
                      <InputLabel
                        id='demo-simple-select-label'
                        sx={{ color: 'var(--clr-mindly-black)' }}
                      >
                        Skill Type
                      </InputLabel>
                      <FormControl sx={{ minWidth: 200 }}>
                        <Select
                          labelId='demo-simple-select-label'
                          id='demo-simple-select'
                          inputProps={{ 'aria-label': 'Without label' }}
                          value={formState.skillType}
                          name='skillType'
                          onChange={handleChange}
                          sx={{
                            height: '40px',
                            borderRadius: '8px',
                            backgroundColor: 'var(--clr-mindly-white)',
                            boxShadow: '0px 0px 10px rgba(0,0,0,0.1);',
                          }}
                        >
                          {SKILL_ACQUISITION_TYPES.map((type) => (
                            <MenuItem key={type} value={type}>
                              {type}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                    <Typography
                      className='goal-box-typography'
                      gutterBottom
                      variant='body2'
                      sx={{ fontWeight: 600 }}
                    >
                      Skill Name
                    </Typography>
                    <TextField
                      inputProps={{ 'aria-label': 'Without label' }}
                      placeholder='Skill Name'
                      value={formState.skillName}
                      className='goal-textfield'
                      name='skillName'
                      onChange={handleChange}
                      InputProps={{
                        style: {
                          height: '40px',
                          borderRadius: '8px',
                        },
                      }}
                      sx={{
                        width: '50%',
                        '& .MuiOutlinedInput-notchedOutline': {
                          border: 'none',
                        },
                      }}
                    />
                    <Typography
                      className='goal-box-typography'
                      gutterBottom
                      variant='body2'
                      sx={{ fontWeight: 600 }}
                    >
                      Definition
                    </Typography>
                    <TextField
                      inputProps={{ 'aria-label': 'Without label' }}
                      placeholder='Definition'
                      fullWidth
                      value={formState.skillDefinition}
                      className='goal-textfield'
                      name='skillDefinition'
                      onChange={handleChange}
                      InputProps={{
                        style: {
                          height: '40px',
                          borderRadius: '8px',
                        },
                      }}
                      sx={{
                        '& .MuiOutlinedInput-notchedOutline': {
                          border: 'none',
                        },
                      }}
                    />
                    <Typography
                      className='goal-box-typography'
                      gutterBottom
                      variant='body2'
                      sx={{ fontWeight: 600 }}
                    >
                      Natural Cue
                    </Typography>
                    <TextField
                      inputProps={{ 'aria-label': 'Without label' }}
                      placeholder='Natural Cue'
                      value={formState.skillNaturalCue}
                      className='goal-textfield'
                      name='skillNaturalCue'
                      onChange={handleChange}
                      InputProps={{
                        style: {
                          height: '40px',
                          borderRadius: '8px',
                        },
                      }}
                      sx={{
                        width: '50%',
                        '& .MuiOutlinedInput-notchedOutline': {
                          border: 'none',
                        },
                      }}
                    />
                  </>
                )}

                {formState.typeOfGoal === 'Parent Training' && (
                  <>
                    <Box
                      className='select-student-box'
                      display='flex'
                      alignItems='center'
                      gap={1}
                      sx={{ flexWrap: 'wrap' }}
                    >
                      <InputLabel
                        id='demo-simple-select-label'
                        sx={{ color: 'var(--clr-mindly-black)' }}
                      >
                        Skill Type
                      </InputLabel>
                      <FormControl sx={{ minWidth: 200 }}>
                        <Select
                          labelId='demo-simple-select-label'
                          id='demo-simple-select'
                          inputProps={{ 'aria-label': 'Without label' }}
                          value={formState.parentTrainingSkillType}
                          name='parentTrainingSkillType'
                          onChange={handleChange}
                          sx={{
                            height: '40px',
                            borderRadius: '8px',
                            backgroundColor: 'var(--clr-mindly-white)',
                            boxShadow: '0px 0px 10px rgba(0,0,0,0.1);',
                          }}
                        >
                          {SKILL_ACQUISITION_TYPES.map((type) => (
                            <MenuItem key={type} value={type}>
                              {type}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                    <Typography
                      className='goal-box-typography'
                      gutterBottom
                      variant='body2'
                      sx={{ fontWeight: 600 }}
                    >
                      Skill Name
                    </Typography>
                    <TextField
                      inputProps={{ 'aria-label': 'Without label' }}
                      placeholder='Skill Name'
                      value={formState.parentTrainingSkillName}
                      className='goal-textfield'
                      name='parentTrainingSkillName'
                      onChange={handleChange}
                      InputProps={{
                        style: {
                          height: '40px',
                          borderRadius: '8px',
                        },
                      }}
                      sx={{
                        width: '50%',
                        '& .MuiOutlinedInput-notchedOutline': {
                          border: 'none',
                        },
                      }}
                    />
                    <Typography
                      className='goal-box-typography'
                      gutterBottom
                      variant='body2'
                      sx={{ fontWeight: 600 }}
                    >
                      Definition
                    </Typography>
                    <TextField
                      inputProps={{ 'aria-label': 'Without label' }}
                      placeholder='Definition'
                      fullWidth
                      value={formState.parentTrainingDefinition}
                      className='goal-textfield'
                      name='parentTrainingDefinition'
                      onChange={handleChange}
                      InputProps={{
                        style: {
                          height: '40px',
                          borderRadius: '8px',
                        },
                      }}
                      sx={{
                        '& .MuiOutlinedInput-notchedOutline': {
                          border: 'none',
                        },
                      }}
                    />
                    <Typography
                      className='goal-box-typography'
                      gutterBottom
                      variant='body2'
                      sx={{ fontWeight: 600 }}
                    >
                      Natural Cue
                    </Typography>
                    <TextField
                      inputProps={{ 'aria-label': 'Without label' }}
                      placeholder='Natural Cue'
                      value={formState.parentTrainingNaturalCue}
                      className='goal-textfield'
                      name='parentTrainingNaturalCue'
                      onChange={handleChange}
                      InputProps={{
                        style: {
                          height: '40px',
                          borderRadius: '8px',
                        },
                      }}
                      sx={{
                        width: '50%',
                        '& .MuiOutlinedInput-notchedOutline': {
                          border: 'none',
                        },
                      }}
                    />
                  </>
                )}

                <Divider
                  sx={{ my: 2, borderBottomWidth: 2, background: 'black' }}
                />

                <FormControl component='fieldset' fullWidth sx={{ p: 1 }}>
                  <Typography variant='h5' className='heading' gutterBottom>
                    Data Collection
                  </Typography>
                  <Typography
                    className='goal-box-typography'
                    gutterBottom
                    variant='body2'
                    sx={{ fontWeight: 600 }}
                  >
                    Antecedent
                  </Typography>
                  <TextField
                    inputProps={{ 'aria-label': 'Without label' }}
                    placeholder='Antecedent'
                    fullWidth
                    value={formState.antecedent}
                    className='goal-textfield'
                    name='antecedent'
                    onChange={handleChange}
                    InputProps={{
                      style: {
                        height: '40px',
                        borderRadius: '8px',
                      },
                    }}
                    sx={{
                      '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                      },
                    }}
                  />
                  <Typography
                    className='goal-box-typography'
                    gutterBottom
                    variant='body2'
                    sx={{ fontWeight: 600 }}
                  >
                    Target Behavior
                  </Typography>
                  <TextField
                    inputProps={{ 'aria-label': 'Without label' }}
                    placeholder='Target Behavior'
                    fullWidth
                    value={formState.targetBehavior}
                    className='goal-textfield'
                    name='targetBehavior'
                    onChange={handleChange}
                    InputProps={{
                      style: {
                        height: '40px',
                        borderRadius: '8px',
                      },
                    }}
                    sx={{
                      '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                      },
                    }}
                  />
                  <Typography className='goal-box-typography' gutterBottom>
                    How is this goal measured?
                  </Typography>
                  <RadioGroup
                    row
                    name='goalMeasurement'
                    value={formState.goalMeasurement}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      className='goal-box-typography'
                      value='Frequency'
                      control={<Radio />}
                      label='Frequency'
                    />
                    <FormControlLabel
                      className='goal-box-typography'
                      value='Duration'
                      control={<Radio />}
                      label='Duration'
                    />
                    <FormControlLabel
                      className='goal-box-typography'
                      value='Percentage'
                      control={<Radio />}
                      label='Percentage'
                    />
                    <FormControlLabel
                      className='goal-box-typography'
                      value='Trials'
                      control={<Radio />}
                      label='Trials'
                    />
                  </RadioGroup>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 2,
                      alignItems: 'center',
                    }}
                  >
                    {formState.goalMeasurement === 'Trials' ? (
                      <>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                            width: '100%',
                          }}
                        >
                          <Box>
                            <Typography
                              className='goal-box-typography'
                              gutterBottom
                            >
                              What is the data type?
                            </Typography>
                            <RadioGroup
                              row
                              name='dataType'
                              value={formState.dataType || 'others'}
                              onChange={handleChange}
                            >
                              <FormControlLabel
                                className='goal-box-typography'
                                value='+/-'
                                control={<Radio />}
                                label='+/-'
                              />
                              <FormControlLabel
                                className='goal-box-typography'
                                value='%'
                                control={<Radio />}
                                label='%'
                              />
                              <FormControlLabel
                                className='goal-box-typography'
                                value='times'
                                control={<Radio />}
                                label='times'
                              />
                              <FormControlLabel
                                className='goal-box-typography'
                                value='minutes'
                                control={<Radio />}
                                label='minutes'
                              />
                              <FormControlLabel
                                className='goal-box-typography'
                                value='others'
                                control={<Radio />}
                                label='other'
                              />

                              {!['+/-', '%', 'times', 'minutes'].includes(
                                formState.dataType
                              ) &&
                                formState.dataType !== '+' &&
                                formState.dataType !== '-' && (
                                  <TextField
                                    type='text'
                                    placeholder='Enter unit'
                                    className='goal-textfield'
                                    name='dataType'
                                    value={formState.dataType}
                                    onChange={handleChange}
                                    sx={{
                                      width: '20%',
                                      '& .MuiOutlinedInput-notchedOutline': {
                                        border: 'none',
                                      },
                                    }}
                                    InputProps={{
                                      style: {
                                        height: '40px',
                                        borderRadius: '8px',
                                      },
                                    }}
                                  />
                                )}
                            </RadioGroup>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              gap: 2,
                              width: '100%',
                            }}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                              }}
                            >
                              <Typography className='goal-box-typography'>
                                Target:
                              </Typography>
                              <FormControl sx={{ minWidth: 120 }}>
                                <Select
                                  value={formState.target}
                                  name='target'
                                  onChange={handleChange}
                                  sx={{
                                    height: '40px',
                                    borderRadius: '8px',
                                    backgroundColor: 'var(--clr-mindly-white)',
                                    boxShadow: '0px 0px 10px rgba(0,0,0,0.1)',
                                    width: '100%',
                                  }}
                                >
                                  <MenuItem value='Atmost'>At most</MenuItem>
                                  <MenuItem value='Atleast'>At least</MenuItem>
                                </Select>
                              </FormControl>
                            </Box>

                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                              }}
                            >
                              <TextField
                                type='number'
                                value={formState.measurement}
                                name='measurement'
                                onChange={(e) => {
                                  const value = e.target.value;
                                  if (value >= 0) {
                                    handleChange(e);
                                  }
                                }}
                                sx={{
                                  height: '40px',
                                  borderRadius: '8px',
                                  backgroundColor: 'var(--clr-mindly-white)',
                                  boxShadow: '0px 0px 10px rgba(0,0,0,0.1)',
                                  maxWidth: 150,
                                  minWidth: 100,
                                }}
                              />
                              <Typography className='goal-box-typography'>
                                {formState.goalMeasurement === 'Trials'
                                  ? formState.dataType === 'others'
                                    ? 'others'
                                    : formState.dataType
                                  : formState.goalMeasurement}
                              </Typography>
                              <Box
                                sx={{
                                  display: 'flex',
                                  gap: 1,
                                  alignItems: 'center',
                                }}
                              >
                                <Typography className='goal-box-typography'>
                                  in
                                </Typography>
                                <TextField
                                  type='text'
                                  inputProps={{
                                    'aria-label': 'Without label',
                                    inputMode: 'numeric',
                                  }}
                                  fullWidth
                                  value={formState.trailsMin}
                                  onChange={handleChange}
                                  className='goal-textfield'
                                  name='trailsMin'
                                  InputProps={{
                                    readOnly: false,
                                    style: {
                                      height: '40px',
                                      borderRadius: '8px',
                                      backgroundColor: 'white',
                                    },
                                  }}
                                  sx={{
                                    '& .MuiOutlinedInput-notchedOutline': {
                                      border: 'none',
                                    },
                                    width: '10%',
                                    backgroundColor: 'white',
                                    borderRadius: '8px',
                                  }}
                                />
                                <Typography className='goal-box-typography'>
                                  out of
                                </Typography>
                                <TextField
                                  inputProps={{
                                    'aria-label': 'Without label',
                                    inputMode: 'numeric',
                                  }}
                                  type='text'
                                  fullWidth
                                  value={formState.trailsMax}
                                  onChange={handleChange}
                                  className='goal-textfield'
                                  name='trailsMax'
                                  InputProps={{
                                    readOnly: false,
                                    style: {
                                      height: '40px',
                                      borderRadius: '8px',
                                      backgroundColor: 'white',
                                    },
                                  }}
                                  sx={{
                                    '& .MuiOutlinedInput-notchedOutline': {
                                      border: 'none',
                                    },
                                    width: '10%',
                                    backgroundColor: 'white',
                                    borderRadius: '8px',
                                  }}
                                />
                                <Typography className='goal-box-typography'>
                                  trials
                                </Typography>
                              </Box>
                            </Box>
                            <Button
                              onClick={addCriterion}
                              sx={{
                                padding: '10px 3%',
                                color: 'black',
                                whiteSpace: 'nowrap',
                                minWidth: '120px',
                                maxWidth: '100%',
                                textAlign: 'center',
                                fontSize: '12px',
                                textTransform: 'none',
                                '&:hover': {
                                  borderColor: 'black',
                                  backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                },
                              }}
                            >
                              + Add Criteria
                            </Button>
                          </Box>

                          <Box sx={{ width: '100%' }}>
                            {formState.criteria.map((criterion, index) => (
                              <Box>
                                <Typography
                                  className='goal-box-typography'
                                  gutterBottom
                                >
                                  How is this criteria measured? (optional)
                                </Typography>
                                <RadioGroup
                                  row
                                  name='goalMeasurement'
                                  value={criterion.measurementType}
                                  onChange={(e) =>
                                    handleCriterionChange(
                                      index,
                                      'measurementType',
                                      e.target.value
                                    )
                                  }
                                >
                                  <FormControlLabel
                                    className='goal-box-typography'
                                    value='Frequency'
                                    control={<Radio />}
                                    label='Frequency'
                                  />
                                  <FormControlLabel
                                    className='goal-box-typography'
                                    value='Duration'
                                    control={<Radio />}
                                    label='Duration'
                                  />
                                  <FormControlLabel
                                    className='goal-box-typography'
                                    value='Percentage'
                                    control={<Radio />}
                                    label='Percentage'
                                  />
                                </RadioGroup>
                                <Box
                                  key={index}
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    gap: 2,
                                    marginTop: 1,
                                    padding: '4px 0',
                                  }}
                                >
                                  <Button
                                    onClick={() => removeCriterion(index)}
                                    variant='outlined'
                                    sx={{
                                      minWidth: '40px',
                                      width: '40px',
                                      height: '40px',
                                      p: 0,
                                      borderRadius: '50%',
                                      borderColor: 'var(--clr-mindly-purple)',
                                      color: 'var(--clr-mindly-purple)',
                                    }}
                                  >
                                    <Typography sx={{ fontSize: 40 }}>
                                      -
                                    </Typography>
                                  </Button>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      gap: 1,
                                    }}
                                  >
                                    <Typography className='goal-box-typography'>
                                      Target:
                                    </Typography>
                                    <FormControl
                                      sx={{ flex: 1, minWidth: 150 }}
                                    >
                                      <Select
                                        value={criterion.target}
                                        onChange={(e) =>
                                          handleCriterionChange(
                                            index,
                                            'target',
                                            e.target.value
                                          )
                                        }
                                        sx={{
                                          height: '40px',
                                          borderRadius: '8px',
                                          backgroundColor:
                                            'var(--clr-mindly-white)',
                                          boxShadow:
                                            '0px 0px 10px rgba(0,0,0,0.1)',
                                          width: '100%',
                                        }}
                                      >
                                        <MenuItem value='Atmost'>
                                          At most
                                        </MenuItem>
                                        <MenuItem value='Atleast'>
                                          At least
                                        </MenuItem>
                                      </Select>
                                    </FormControl>
                                  </Box>

                                  <Box
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      gap: 1,
                                    }}
                                  >
                                    <TextField
                                      type='number'
                                      value={criterion.measurement}
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        if (value >= 0) {
                                          handleCriterionChange(
                                            index,
                                            'measurement',
                                            value
                                          );
                                        }
                                      }}
                                      sx={{
                                        height: '40px',
                                        borderRadius: '8px',
                                        backgroundColor:
                                          'var(--clr-mindly-white)',
                                        boxShadow:
                                          '0px 0px 10px rgba(0,0,0,0.1)',
                                        maxWidth: 80,
                                      }}
                                    />
                                    <Typography className='goal-box-typography'>
                                      {criterion.measurementType ===
                                        'Frequency' && 'times of'}
                                      {criterion.measurementType ===
                                        'Duration' && 'mins of'}
                                      {criterion.measurementType ===
                                        'Percentage' && '% of'}
                                    </Typography>
                                    <FormControl sx={{ flex: 1 }}>
                                      <TextField
                                        placeholder='Variable name'
                                        value={criterion.variable}
                                        onChange={(e) =>
                                          handleCriterionChange(
                                            index,
                                            'variable',
                                            e.target.value
                                          )
                                        }
                                        sx={{
                                          height: '40px',
                                          borderRadius: '8px',
                                          backgroundColor:
                                            'var(--clr-mindly-white)',
                                          boxShadow:
                                            '0px 0px 10px rgba(0,0,0,0.1)',
                                          width: '100%',
                                        }}
                                      />
                                    </FormControl>
                                  </Box>
                                </Box>
                              </Box>
                            ))}
                          </Box>
                        </Box>
                      </>
                    ) : (
                      <>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                            width: '100%',
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              gap: 2,
                              width: '100%',
                            }}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                              }}
                            >
                              <Typography className='goal-box-typography'>
                                Target:
                              </Typography>
                              <FormControl sx={{ minWidth: 150 }}>
                                <Select
                                  value={formState.target}
                                  name='target'
                                  onChange={handleChange}
                                  sx={{
                                    height: '40px',
                                    borderRadius: '8px',
                                    backgroundColor: 'var(--clr-mindly-white)',
                                    boxShadow: '0px 0px 10px rgba(0,0,0,0.1)',
                                    width: '100%',
                                  }}
                                >
                                  <MenuItem value='Atmost'>At most</MenuItem>
                                  <MenuItem value='Atleast'>At least</MenuItem>
                                </Select>
                              </FormControl>
                            </Box>

                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                              }}
                            >
                              <TextField
                                type='number'
                                value={formState.measurement}
                                name='measurement'
                                onChange={(e) => {
                                  const value = e.target.value;
                                  if (value >= 0) {
                                    handleChange(e);
                                  }
                                }}
                                sx={{
                                  height: '40px',
                                  borderRadius: '8px',
                                  backgroundColor: 'var(--clr-mindly-white)',
                                  boxShadow: '0px 0px 10px rgba(0,0,0,0.1)',
                                  maxWidth: 80,
                                }}
                              />
                              <Typography className='goal-box-typography'>
                                {formState.goalMeasurement === 'Frequency' &&
                                  'times'}
                                {formState.goalMeasurement === 'Duration' &&
                                  'mins'}
                                {formState.goalMeasurement === 'Percentage' &&
                                  '%'}
                              </Typography>
                            </Box>
                            <Box
                              sx={{ display: 'flex', justifyContent: 'center' }}
                            >
                              <Button
                                onClick={addCriterion}
                                sx={{
                                  padding: '10px 3%',
                                  color: 'black',
                                  whiteSpace: 'nowrap',
                                  minWidth: '120px',
                                  maxWidth: '100%',
                                  textAlign: 'center',
                                  fontSize: '12px',
                                  textTransform: 'none',
                                  '&:hover': {
                                    borderColor: 'black',
                                    backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                  },
                                }}
                              >
                                + Add Criteria
                              </Button>
                            </Box>
                          </Box>
                          <Box sx={{ width: '100%' }}>
                            {formState.criteria.map((criterion, index) => (
                              <Box>
                                <Typography
                                  className='goal-box-typography'
                                  gutterBottom
                                >
                                  How is this criteria measured? (optional)
                                </Typography>
                                <RadioGroup
                                  row
                                  name='goalMeasurement'
                                  value={criterion.measurementType}
                                  onChange={(e) =>
                                    handleCriterionChange(
                                      index,
                                      'measurementType',
                                      e.target.value
                                    )
                                  }
                                >
                                  <FormControlLabel
                                    className='goal-box-typography'
                                    value='Frequency'
                                    control={<Radio />}
                                    label='Frequency'
                                  />
                                  <FormControlLabel
                                    className='goal-box-typography'
                                    value='Duration'
                                    control={<Radio />}
                                    label='Duration'
                                  />
                                  <FormControlLabel
                                    className='goal-box-typography'
                                    value='Percentage'
                                    control={<Radio />}
                                    label='Percentage'
                                  />
                                </RadioGroup>
                                <Box
                                  key={index}
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    gap: 2,
                                    marginTop: 1,
                                    padding: '4px 0',
                                  }}
                                >
                                  <Button
                                    onClick={() => removeCriterion(index)}
                                    variant='outlined'
                                    sx={{
                                      minWidth: '40px',
                                      width: '40px',
                                      height: '40px',
                                      p: 0,
                                      borderRadius: '50%',
                                      borderColor: 'var(--clr-mindly-purple)',
                                      color: 'var(--clr-mindly-purple)',
                                    }}
                                  >
                                    <Typography sx={{ fontSize: 40 }}>
                                      -
                                    </Typography>
                                  </Button>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      gap: 1,
                                    }}
                                  >
                                    <Typography className='goal-box-typography'>
                                      Target:
                                    </Typography>
                                    <FormControl
                                      sx={{ flex: 1, minWidth: 150 }}
                                    >
                                      <Select
                                        value={criterion.target}
                                        onChange={(e) =>
                                          handleCriterionChange(
                                            index,
                                            'target',
                                            e.target.value
                                          )
                                        }
                                        sx={{
                                          height: '40px',
                                          borderRadius: '8px',
                                          backgroundColor:
                                            'var(--clr-mindly-white)',
                                          boxShadow:
                                            '0px 0px 10px rgba(0,0,0,0.1)',
                                          width: '100%',
                                        }}
                                      >
                                        <MenuItem value='Atmost'>
                                          At most
                                        </MenuItem>
                                        <MenuItem value='Atleast'>
                                          At least
                                        </MenuItem>
                                      </Select>
                                    </FormControl>

                                    <TextField
                                      type='number'
                                      value={criterion.measurement}
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        if (value >= 0) {
                                          handleCriterionChange(
                                            index,
                                            'measurement',
                                            value
                                          );
                                        }
                                      }}
                                      sx={{
                                        height: '40px',
                                        borderRadius: '8px',
                                        backgroundColor:
                                          'var(--clr-mindly-white)',
                                        boxShadow:
                                          '0px 0px 10px rgba(0,0,0,0.1)',
                                        maxWidth: 80,
                                      }}
                                    />

                                    <Typography className='goal-box-typography'>
                                      {criterion.measurementType ===
                                        'Frequency' && 'times of'}
                                      {criterion.measurementType ===
                                        'Duration' && 'mins of'}
                                      {criterion.measurementType ===
                                        'Percentage' && '% of'}
                                    </Typography>

                                    <FormControl sx={{ flex: 1 }}>
                                      <TextField
                                        placeholder='Variable name'
                                        value={criterion.variable}
                                        onChange={(e) =>
                                          handleCriterionChange(
                                            index,
                                            'variable',
                                            e.target.value
                                          )
                                        }
                                        sx={{
                                          height: '40px',
                                          borderRadius: '8px',
                                          backgroundColor:
                                            'var(--clr-mindly-white)',
                                          boxShadow:
                                            '0px 0px 10px rgba(0,0,0,0.1)',
                                          width: '100%',
                                        }}
                                      />
                                    </FormControl>
                                  </Box>
                                </Box>
                              </Box>
                            ))}
                          </Box>
                        </Box>
                      </>
                    )}
                  </Box>

                  {/* Prompting Section */}
                  <Typography className='goal-box-typography' sx={{ mt: 2 }}>
                    Is Prompting Applicable?
                  </Typography>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={formState.isPromptingApplicable}
                        onChange={() =>
                          setFormState({
                            ...formState,
                            isPromptingApplicable:
                              !formState.isPromptingApplicable,
                          })
                        }
                      />
                    }
                    label={formState.isPromptingApplicable ? 'Yes' : 'No'}
                  />

                  {formState.isPromptingApplicable && (
                    <>
                      <Typography
                        className='goal-box-typography'
                        sx={{ mt: 2 }}
                      >
                        Which Type:
                      </Typography>
                      <RadioGroup
                        name='promptingType'
                        value={formState.promptingType}
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          className='goal-box-typography'
                          value='P+ & P-'
                          control={<Radio />}
                          label='P+ & P-'
                        />

                        {/* Conditional Fields for Prompting Type */}
                        {formState.promptingType === 'P+ & P-' && (
                          <Box
                            sx={{
                              display: 'flex',
                              gap: 2,
                              alignItems: 'center',
                              // flexDirection: 'column',
                              mt: 1,
                            }}
                          >
                            <Typography className='goal-box-typography'>
                              How many allowed per trail?
                            </Typography>
                            <Typography className='goal-box-typography'>
                              P-
                            </Typography>
                            <TextField
                              inputProps={{
                                'aria-label': 'Without label',
                              }}
                              type='number'
                              fullWidth
                              value={formState.pMinus}
                              onChange={handleChange}
                              className='goal-textfield'
                              name='pMinus'
                              InputProps={{
                                readOnly: false,
                                style: {
                                  height: '40px',
                                  borderRadius: '8px',
                                  backgroundColor: 'white',
                                },
                              }}
                              sx={{
                                '& .MuiOutlinedInput-notchedOutline': {
                                  border: 'none',
                                },
                                width: '10%',
                                backgroundColor: 'white',
                                borderRadius: '8px',
                              }}
                            />

                            <Typography className='goal-box-typography'>
                              P+
                            </Typography>
                            <TextField
                              inputProps={{
                                'aria-label': 'Without label',
                              }}
                              type='number'
                              fullWidth
                              value={formState.pPlus}
                              onChange={handleChange}
                              className='goal-textfield'
                              name='pPlus'
                              InputProps={{
                                readOnly: false,
                                style: {
                                  height: '40px',
                                  borderRadius: '8px',
                                  backgroundColor: 'white',
                                },
                              }}
                              sx={{
                                '& .MuiOutlinedInput-notchedOutline': {
                                  border: 'none',
                                },
                                width: '10%',
                                backgroundColor: 'white',
                                borderRadius: '8px',
                              }}
                            />
                          </Box>
                        )}

                        <FormControlLabel
                          className='goal-box-typography'
                          value='IVP, DVP, PVP, GP, PP'
                          control={<Radio />}
                          label='I, VP (IVP, DVP, PVP), GP, PP'
                        />
                      </RadioGroup>

                      {formState.promptingType === 'IVP, DVP, PVP, GP, PP' && (
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 1,
                            mt: 1,
                          }}
                        >
                          <Box sx={{ display: 'flex', gap: 5 }}>
                            <Typography className='goal-box-typography'>
                              I
                            </Typography>
                            <TextField
                              inputProps={{
                                'aria-label': 'Without label',
                              }}
                              type='number'
                              fullWidth
                              value={formState.I}
                              onChange={handleChange}
                              className='goal-textfield'
                              name='I'
                              InputProps={{
                                readOnly: false,
                                style: {
                                  height: '40px',
                                  borderRadius: '8px',
                                  backgroundColor: 'white',
                                },
                              }}
                              sx={{
                                '& .MuiOutlinedInput-notchedOutline': {
                                  border: 'none',
                                },
                                width: '10%',
                                backgroundColor: 'white',
                                borderRadius: '8px',
                              }}
                            />

                            <Typography className='goal-box-typography'>
                              VP
                            </Typography>
                            <TextField
                              inputProps={{
                                'aria-label': 'Without label',
                              }}
                              type='number'
                              fullWidth
                              value={formState.VP}
                              onChange={handleChange}
                              className='goal-textfield'
                              name='VP'
                              InputProps={{
                                readOnly: false,
                                style: {
                                  height: '40px',
                                  borderRadius: '8px',
                                  backgroundColor: 'white',
                                },
                              }}
                              sx={{
                                '& .MuiOutlinedInput-notchedOutline': {
                                  border: 'none',
                                },
                                width: '10%',
                                backgroundColor: 'white',
                                borderRadius: '8px',
                              }}
                            />
                          </Box>
                          <Box sx={{ display: 'flex', gap: 4 }}>
                            <Typography className='goal-box-typography'>
                              IVP
                            </Typography>
                            <TextField
                              inputProps={{
                                'aria-label': 'Without label',
                              }}
                              type='number'
                              fullWidth
                              value={formState.IVP}
                              onChange={handleChange}
                              className='goal-textfield'
                              name='IVP'
                              InputProps={{
                                readOnly: false,
                                style: {
                                  height: '40px',
                                  borderRadius: '8px',
                                  backgroundColor: 'white',
                                },
                              }}
                              sx={{
                                '& .MuiOutlinedInput-notchedOutline': {
                                  border: 'none',
                                },
                                width: '10%',
                                backgroundColor: 'white',
                                borderRadius: '8px',
                              }}
                            />
                            <Typography className='goal-box-typography'>
                              DVP
                            </Typography>
                            <TextField
                              inputProps={{
                                'aria-label': 'Without label',
                              }}
                              type='number'
                              fullWidth
                              value={formState.DVP}
                              onChange={handleChange}
                              className='goal-textfield'
                              name='DVP'
                              InputProps={{
                                readOnly: false,
                                style: {
                                  height: '40px',
                                  borderRadius: '8px',
                                  backgroundColor: 'white',
                                },
                              }}
                              sx={{
                                '& .MuiOutlinedInput-notchedOutline': {
                                  border: 'none',
                                },
                                width: '10%',
                                backgroundColor: 'white',
                                borderRadius: '8px',
                              }}
                            />
                            <Typography className='goal-box-typography'>
                              PVP
                            </Typography>
                            <TextField
                              inputProps={{
                                'aria-label': 'Without label',
                              }}
                              type='number'
                              fullWidth
                              value={formState.PVP}
                              onChange={handleChange}
                              className='goal-textfield'
                              name='PVP'
                              InputProps={{
                                readOnly: false,
                                style: {
                                  height: '40px',
                                  borderRadius: '8px',
                                  backgroundColor: 'white',
                                },
                              }}
                              sx={{
                                '& .MuiOutlinedInput-notchedOutline': {
                                  border: 'none',
                                },
                                width: '10%',
                                backgroundColor: 'white',
                                borderRadius: '8px',
                              }}
                            />
                          </Box>
                          <Box sx={{ display: 'flex', gap: 2 }}>
                            <Typography className='goal-box-typography'>
                              GP
                            </Typography>
                            <TextField
                              inputProps={{
                                'aria-label': 'Without label',
                              }}
                              type='number'
                              fullWidth
                              value={formState.GP}
                              onChange={handleChange}
                              className='goal-textfield'
                              name='GP'
                              InputProps={{
                                readOnly: false,
                                style: {
                                  height: '40px',
                                  borderRadius: '8px',
                                  backgroundColor: 'white',
                                },
                              }}
                              sx={{
                                '& .MuiOutlinedInput-notchedOutline': {
                                  border: 'none',
                                },
                                width: '10%',
                                backgroundColor: 'white',
                                borderRadius: '8px',
                              }}
                            />

                            <Typography className='goal-box-typography'>
                              PP
                            </Typography>

                            <TextField
                              inputProps={{
                                'aria-label': 'Without label',
                              }}
                              type='number'
                              fullWidth
                              value={formState.PP}
                              onChange={handleChange}
                              className='goal-textfield'
                              name='PP'
                              InputProps={{
                                readOnly: false,
                                style: {
                                  height: '40px',
                                  borderRadius: '8px',
                                  backgroundColor: 'white',
                                },
                              }}
                              sx={{
                                '& .MuiOutlinedInput-notchedOutline': {
                                  border: 'none',
                                },
                                width: '10%',
                                backgroundColor: 'white',
                                borderRadius: '8px',
                              }}
                            />
                          </Box>
                        </Box>
                      )}
                    </>
                  )}

                  <FormControl component='fieldset' margin='normal'>
                    <Typography className='goal-box-typography' gutterBottom>
                      Multiple data points per session?
                    </Typography>
                    <RadioGroup
                      row
                      name='multipleDataPoints'
                      value={formState.multipleDataPoints}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        className='goal-box-typography'
                        value='Yes'
                        control={<Radio />}
                        label='Yes'
                      />
                      <FormControlLabel
                        className='goal-box-typography'
                        value='No'
                        control={<Radio />}
                        label='No'
                      />
                    </RadioGroup>
                  </FormControl>

                  {formState.multipleDataPoints === 'Yes' && (
                    <FormControl
                      component='fieldset'
                      margin='normal'
                      sx={{ ml: 4 }}
                    >
                      <Typography className='goal-box-typography' gutterBottom>
                        What do we do with multiple data points per sessions?
                      </Typography>
                      <RadioGroup
                        row
                        name='dataPointAction'
                        value={formState.dataPointAction}
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          className='goal-box-typography'
                          value='Average'
                          control={<Radio />}
                          label='Average'
                        />
                        <FormControlLabel
                          className='goal-box-typography'
                          value='Add'
                          control={<Radio />}
                          label='Add'
                        />
                        <FormControlLabel
                          className='goal-box-typography'
                          value='Take Highest'
                          control={<Radio />}
                          label='Take Highest'
                        />
                        <FormControlLabel
                          className='goal-box-typography'
                          value='Take Lowest'
                          control={<Radio />}
                          label='Take Lowest'
                        />
                      </RadioGroup>
                    </FormControl>
                  )}

                  <FormControl component='fieldset' margin='normal'>
                    <Typography className='goal-box-typography' gutterBottom>
                      Does target have to be achieved multiple times to meet
                      goals?
                    </Typography>
                    <RadioGroup
                      row
                      name='multipleTimesToMeetGoal'
                      value={formState.multipleTimesToMeetGoal}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        className='goal-box-typography'
                        value='Yes'
                        control={<Radio />}
                        label='Yes'
                      />
                      <FormControlLabel
                        className='goal-box-typography'
                        value='No'
                        control={<Radio />}
                        label='No'
                      />
                    </RadioGroup>
                  </FormControl>

                  {formState.multipleTimesToMeetGoal === 'Yes' && (
                    <Box className='select-student-box' gap={2} sx={{ ml: 4 }}>
                      <Typography className='goal-box-typography' gutterBottom>
                        How many sessions?
                      </Typography>
                      <FormControl sx={{ maxWidth: 80 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <TextField
                            type='number'
                            value={formState.numberOfSessions}
                            name='numberOfSessions'
                            onChange={(e) => {
                              const value = e.target.value;
                              if (value >= 0) {
                                handleChange(e);
                              }
                            }}
                            sx={{
                              height: '40px',
                              borderRadius: '8px',
                              backgroundColor: 'var(--clr-mindly-white)',
                              boxShadow: '0px 0px 10px rgba(0,0,0,0.1);',
                            }}
                          />
                        </Box>
                      </FormControl>
                    </Box>
                  )}

                  {formState.multipleTimesToMeetGoal === 'Yes' && (
                    <Box className='select-student-box' gap={2} sx={{ ml: 4 }}>
                      <Typography className='goal-box-typography' gutterBottom>
                        Does it have to be consecutive
                      </Typography>
                      <RadioGroup
                        row
                        name='consecutive'
                        value={formState.consecutive}
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          className='goal-box-typography'
                          value='Yes'
                          control={<Radio />}
                          label='Yes'
                        />
                        <FormControlLabel
                          className='goal-box-typography'
                          value='No'
                          control={<Radio />}
                          label='No'
                        />
                      </RadioGroup>
                    </Box>
                  )}

                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 2,
                      marginBottom: 1,
                    }}
                  >
                    <Typography className='goal-box-typography'>
                      Start Date:
                    </Typography>

                    <TextField
                      type='date'
                      value={formState.startdate}
                      name='startdate'
                      onChange={handleChange}
                      sx={{
                        height: '40px',
                        width: '30%',
                        borderRadius: '8px',
                        backgroundColor: 'var(--clr-mindly-white)',
                        boxShadow: '0px 0px 10px rgba(0,0,0,0.1);',
                      }}
                    />
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Typography className='goal-box-typography'>
                      End Date:
                    </Typography>

                    <TextField
                      type='date'
                      value={formState.byDate}
                      name='byDate'
                      onChange={handleChange}
                      sx={{
                        height: '40px',
                        width: '30%',
                        borderRadius: '8px',
                        backgroundColor: 'var(--clr-mindly-white)',
                        boxShadow: '0px 0px 10px rgba(0,0,0,0.1);',
                      }}
                    />
                  </Box>

                  <Typography
                    className='goal-box-typography'
                    gutterBottom
                    variant='body2'
                    sx={{ fontWeight: 600 }}
                  >
                    Baseline
                  </Typography>
                  <TextField
                    inputProps={{
                      'aria-label': 'Without label',
                      placeholder: 'Baseline',
                    }}
                    name='baseline'
                    value={formState.baseline}
                    onChange={handleChange}
                    fullWidth
                    className='goal-textfield'
                    InputProps={{
                      style: {
                        height: '40px',
                        borderRadius: '8px',
                      },
                    }}
                    sx={{
                      '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                      },
                    }}
                  />

                  <Typography
                    className='goal-box-typography'
                    gutterBottom
                    variant='body2'
                    sx={{ fontWeight: 600 }}
                  >
                    Notes
                  </Typography>
                  <TextField
                    inputProps={{ 'aria-label': 'Without label' }}
                    placeholder='Notes'
                    value={formState.notes}
                    name='notes'
                    onChange={handleChange}
                    className='goal-textfield'
                    // multiline
                    // rows={3}
                    sx={{
                      '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                      },
                    }}
                    InputProps={{
                      style: {
                        height: '40px',
                        borderRadius: '8px',
                      },
                    }}
                  />

                  {/* Subgoal Section */}
                  <FormControl component='fieldset' margin='normal'>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <Typography className='goal-box-typography'>
                        Are there any subgoals?
                      </Typography>

                      <RadioGroup
                        row
                        name='hasSubgoal'
                        value={formState.hasSubgoal ? 'Yes' : 'No'}
                        onChange={(e) => {
                          setFormState((prev) => ({
                            ...prev,
                            hasSubgoal: e.target.value === 'Yes',

                            subgoals:
                              e.target.value === 'Yes'
                                ? [
                                    {
                                      target: 'Atleast',
                                      measurement: '',
                                      criteria: formState.criteria || [],
                                      isPromptingApplicable: false,
                                      multipleTimesToMeetGoal: 'No',
                                      numberOfSessions: '',
                                      consecutive: 'No',
                                      isCurrentGoal: true,
                                    },
                                  ]
                                : [],
                          }));
                        }}
                      >
                        <FormControlLabel
                          className='goal-box-typography'
                          value='Yes'
                          control={<Radio />}
                          label='Yes'
                        />
                        <FormControlLabel
                          className='goal-box-typography'
                          value='No'
                          control={<Radio />}
                          label='No'
                        />
                      </RadioGroup>
                    </Box>

                    {formState?.hasSubgoal && (
                      <>
                        <Divider
                          sx={{ my: 2, borderBottomWidth: 2, bgcolor: 'black' }}
                        />
                        <Box sx={{ mt: 2, mb: 3 }}>
                          <Typography
                            variant='h6'
                            sx={{ fontWeight: 'bold', mb: 2 }}
                          >
                            Subgoal #1
                          </Typography>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              gap: 2,
                              width: '100%',
                              mb: 2,
                            }}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                              }}
                            >
                              <Typography className='goal-box-typography'>
                                Target:
                              </Typography>
                              <FormControl sx={{ minWidth: 150 }}>
                                <Select
                                  value={
                                    formState.subgoals?.[0]?.target || 'Atleast'
                                  }
                                  name='subgoalTarget'
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    setFormState((prev) => {
                                      const updatedSubgoals = [
                                        ...prev.subgoals,
                                      ];
                                      if (updatedSubgoals[0]) {
                                        updatedSubgoals[0] = {
                                          ...updatedSubgoals[0],
                                          target: value,
                                        };
                                      }
                                      return {
                                        ...prev,
                                        subgoals: updatedSubgoals,
                                      };
                                    });
                                  }}
                                  sx={{
                                    height: '40px',
                                    borderRadius: '8px',
                                    backgroundColor: 'var(--clr-mindly-white)',
                                    boxShadow: '0px 0px 10px rgba(0,0,0,0.1)',
                                    width: '100%',
                                  }}
                                >
                                  <MenuItem value='Atmost'>At most</MenuItem>
                                  <MenuItem value='Atleast'>At least</MenuItem>
                                </Select>
                              </FormControl>
                            </Box>
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                              }}
                            >
                              <TextField
                                type='number'
                                value={formState.subgoals[0].measurement || ''}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  if (value >= 0) {
                                    setFormState((prev) => {
                                      const updatedSubgoals = [
                                        ...prev.subgoals,
                                      ];
                                      if (updatedSubgoals[0]) {
                                        updatedSubgoals[0] = {
                                          ...updatedSubgoals[0],
                                          measurement: value,
                                        };
                                      }
                                      return {
                                        ...prev,
                                        subgoals: updatedSubgoals,
                                      };
                                    });
                                  }
                                }}
                                sx={{
                                  height: '40px',
                                  borderRadius: '8px',
                                  backgroundColor: 'var(--clr-mindly-white)',
                                  boxShadow: '0px 0px 10px rgba(0,0,0,0.1)',
                                  maxWidth: 80,
                                }}
                              />

                              <Typography className='goal-box-typography'>
                                {formState.goalMeasurement === 'Frequency' &&
                                  'times'}
                                {formState.goalMeasurement === 'Duration' &&
                                  'min'}
                                {formState.goalMeasurement === 'Percentage' &&
                                  '%'}
                                {formState.goalMeasurement === 'Trials' &&
                                  (formState.dataType === 'others'
                                    ? 'others'
                                    : formState.dataType)}
                              </Typography>

                              {formState.goalMeasurement === 'Trials' && (
                                <Typography>
                                  {' '}
                                  in {formState?.trailsMin} out of{' '}
                                  {formState?.trailsMax} trials
                                </Typography>
                              )}
                            </Box>
                          </Box>
                          <Box sx={{ mt: 2 }}>
                            {formState.subgoals[0]?.criteria?.map(
                              (criterion, criterionIndex) => (
                                <Box
                                  key={criterionIndex}
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 2,
                                    marginTop: 1,
                                    padding: '4px 0',
                                  }}
                                >
                                  <Typography className='goal-box-typography'>
                                    Target:
                                  </Typography>

                                  <FormControl sx={{ minWidth: 150 }}>
                                    <Select
                                      value={criterion.target || 'Atleast'}
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        setFormState((prev) => {
                                          const updatedSubgoals = [
                                            ...prev.subgoals,
                                          ];
                                          if (!updatedSubgoals[0]) return prev;

                                          const updatedCriteria = [
                                            ...(updatedSubgoals[0].criteria ||
                                              []),
                                          ];
                                          if (
                                            !updatedCriteria[criterionIndex]
                                          ) {
                                            updatedCriteria[criterionIndex] = {
                                              measurementType: '',
                                              target: '',
                                              measurement: '',
                                              variable: '',
                                            };
                                          }

                                          updatedCriteria[criterionIndex] = {
                                            ...updatedCriteria[criterionIndex],
                                            target: value,
                                          };

                                          updatedSubgoals[0] = {
                                            ...updatedSubgoals[0],
                                            criteria: updatedCriteria,
                                          };

                                          return {
                                            ...prev,
                                            subgoals: updatedSubgoals,
                                          };
                                        });
                                      }}
                                      sx={{
                                        height: '40px',
                                        borderRadius: '8px',
                                        backgroundColor:
                                          'var(--clr-mindly-white)',
                                        boxShadow:
                                          '0px 0px 10px rgba(0,0,0,0.1)',
                                        width: '100%',
                                      }}
                                    >
                                      <MenuItem value='Atmost'>
                                        At most
                                      </MenuItem>
                                      <MenuItem value='Atleast'>
                                        At least
                                      </MenuItem>
                                    </Select>
                                  </FormControl>

                                  <TextField
                                    type='number'
                                    value={criterion.measurement || ''}
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      if (value >= 0) {
                                        setFormState((prev) => {
                                          const updatedSubgoals = [
                                            ...prev.subgoals,
                                          ];
                                          if (!updatedSubgoals[0]) return prev;

                                          const updatedCriteria = [
                                            ...(updatedSubgoals[0].criteria ||
                                              []),
                                          ];
                                          if (
                                            !updatedCriteria[criterionIndex]
                                          ) {
                                            updatedCriteria[criterionIndex] = {
                                              measurementType: '',
                                              target: '',
                                              measurement: '',
                                              variable: '',
                                            };
                                          }

                                          updatedCriteria[criterionIndex] = {
                                            ...updatedCriteria[criterionIndex],
                                            measurement: value,
                                          };

                                          updatedSubgoals[0] = {
                                            ...updatedSubgoals[0],
                                            criteria: updatedCriteria,
                                          };

                                          return {
                                            ...prev,
                                            subgoals: updatedSubgoals,
                                          };
                                        });
                                      }
                                    }}
                                    sx={{
                                      height: '40px',
                                      borderRadius: '8px',
                                      backgroundColor:
                                        'var(--clr-mindly-white)',
                                      boxShadow: '0px 0px 10px rgba(0,0,0,0.1)',
                                      maxWidth: 70,
                                      flexShrink: 0,
                                    }}
                                  />

                                  <Typography className='goal-box-typography'>
                                    {criterion.measurementType ===
                                      'Frequency' && 'times of'}
                                    {criterion.measurementType === 'Duration' &&
                                      'mins of'}
                                    {criterion.measurementType ===
                                      'Percentage' && '% of'}
                                  </Typography>

                                  <Typography>{criterion.variable}</Typography>
                                </Box>
                              )
                            )}
                          </Box>

                          <Box>
                            <Typography
                              className='goal-box-typography'
                              sx={{ mt: 2 }}
                            >
                              Is Prompting Applicable for this subgoal?
                            </Typography>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={
                                    formState.subgoals?.[0]
                                      ?.isPromptingApplicable || false
                                  }
                                  onChange={() =>
                                    setFormState((prev) => ({
                                      ...prev,
                                      subgoals: [
                                        {
                                          ...(prev.subgoals?.[0] || {}),
                                          isPromptingApplicable: !(
                                            prev.subgoals?.[0]
                                              ?.isPromptingApplicable || false
                                          ),
                                        },
                                        ...(prev.subgoals?.slice(1) || []),
                                      ],
                                    }))
                                  }
                                />
                              }
                              label={
                                formState.subgoals?.[0]?.isPromptingApplicable
                                  ? 'Yes'
                                  : 'No'
                              }
                            />
                            {formState.subgoals?.[0]?.isPromptingApplicable && (
                              <>
                                <Typography
                                  className='goal-box-typography'
                                  sx={{ mt: 2 }}
                                >
                                  Which Type:
                                </Typography>
                                <RadioGroup
                                  name='promptingType'
                                  value={
                                    formState.subgoals?.[0]?.promptingType || ''
                                  }
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    setFormState((prev) => ({
                                      ...prev,
                                      subgoals: [
                                        {
                                          ...(prev.subgoals?.[0] || {}),
                                          promptingType: value,
                                        },
                                        ...(prev.subgoals?.slice(1) || []),
                                      ],
                                    }));
                                  }}
                                >
                                  <FormControlLabel
                                    className='goal-box-typography'
                                    value='P+ & P-'
                                    control={<Radio />}
                                    label='P+ & P-'
                                  />
                                  {formState.subgoals?.[0]?.promptingType ===
                                    'P+ & P-' && (
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        gap: 2,
                                        alignItems: 'center',
                                        mt: 1,
                                      }}
                                    >
                                      <Typography className='goal-box-typography'>
                                        How many allowed per trail?
                                      </Typography>
                                      <Typography className='goal-box-typography'>
                                        P-
                                      </Typography>
                                      <TextField
                                        inputProps={{
                                          'aria-label': 'Without label',
                                        }}
                                        type='number'
                                        fullWidth
                                        value={
                                          formState.subgoals?.[0]?.pMinus || ''
                                        }
                                        onChange={(e) => {
                                          const value = e.target.value;
                                          setFormState((prev) => ({
                                            ...prev,
                                            subgoals: [
                                              {
                                                ...(prev.subgoals?.[0] || {}),
                                                pMinus: value,
                                              },
                                              ...(prev.subgoals?.slice(1) ||
                                                []),
                                            ],
                                          }));
                                        }}
                                        className='goal-textfield'
                                        sx={{
                                          width: '10%',
                                          backgroundColor: 'white',
                                          borderRadius: '8px',
                                        }}
                                      />

                                      <Typography className='goal-box-typography'>
                                        P+
                                      </Typography>
                                      <TextField
                                        inputProps={{
                                          'aria-label': 'Without label',
                                        }}
                                        type='number'
                                        fullWidth
                                        value={
                                          formState.subgoals?.[0]?.pPlus || ''
                                        }
                                        onChange={(e) => {
                                          const value = e.target.value;
                                          setFormState((prev) => ({
                                            ...prev,
                                            subgoals: [
                                              {
                                                ...(prev.subgoals?.[0] || {}),
                                                pPlus: value,
                                              },
                                              ...(prev.subgoals?.slice(1) ||
                                                []),
                                            ],
                                          }));
                                        }}
                                        className='goal-textfield'
                                        sx={{
                                          width: '10%',
                                          backgroundColor: 'white',
                                          borderRadius: '8px',
                                        }}
                                      />
                                    </Box>
                                  )}
                                  <FormControlLabel
                                    className='goal-box-typography'
                                    value='IVP, DVP, PVP, GP, PP'
                                    control={<Radio />}
                                    label='I, VP (IVP, DVP, PVP), GP, PP'
                                  />
                                </RadioGroup>
                                {formState.subgoals?.[0]?.promptingType ===
                                  'IVP, DVP, PVP, GP, PP' && (
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      gap: 1,
                                      mt: 1,
                                    }}
                                  >
                                    <Box sx={{ display: 'flex', gap: 5 }}>
                                      <Typography className='goal-box-typography'>
                                        I
                                      </Typography>
                                      <TextField
                                        inputProps={{
                                          'aria-label': 'Without label',
                                        }}
                                        type='number'
                                        fullWidth
                                        value={formState.subgoals?.[0]?.I || ''}
                                        onChange={(e) => {
                                          const value = e.target.value;
                                          setFormState((prev) => ({
                                            ...prev,
                                            subgoals: [
                                              {
                                                ...(prev.subgoals?.[0] || {}),
                                                I: value,
                                              },
                                              ...(prev.subgoals?.slice(1) ||
                                                []),
                                            ],
                                          }));
                                        }}
                                        className='goal-textfield'
                                        sx={{
                                          width: '10%',
                                          backgroundColor: 'white',
                                          borderRadius: '8px',
                                        }}
                                      />

                                      <Typography className='goal-box-typography'>
                                        VP
                                      </Typography>
                                      <TextField
                                        inputProps={{
                                          'aria-label': 'Without label',
                                        }}
                                        type='number'
                                        fullWidth
                                        value={
                                          formState.subgoals?.[0]?.VP || ''
                                        }
                                        onChange={(e) => {
                                          const value = e.target.value;
                                          setFormState((prev) => ({
                                            ...prev,
                                            subgoals: [
                                              {
                                                ...(prev.subgoals?.[0] || {}),
                                                VP: value,
                                              },
                                              ...(prev.subgoals?.slice(1) ||
                                                []),
                                            ],
                                          }));
                                        }}
                                        className='goal-textfield'
                                        sx={{
                                          width: '10%',
                                          backgroundColor: 'white',
                                          borderRadius: '8px',
                                        }}
                                      />
                                    </Box>

                                    <Box sx={{ display: 'flex', gap: 4 }}>
                                      <Typography className='goal-box-typography'>
                                        IVP
                                      </Typography>
                                      <TextField
                                        inputProps={{
                                          'aria-label': 'Without label',
                                        }}
                                        type='number'
                                        fullWidth
                                        value={
                                          formState.subgoals?.[0]?.IVP || ''
                                        }
                                        onChange={(e) => {
                                          const value = e.target.value;
                                          setFormState((prev) => ({
                                            ...prev,
                                            subgoals: [
                                              {
                                                ...(prev.subgoals?.[0] || {}),
                                                IVP: value,
                                              },
                                              ...(prev.subgoals?.slice(1) ||
                                                []),
                                            ],
                                          }));
                                        }}
                                        className='goal-textfield'
                                        sx={{
                                          width: '10%',
                                          backgroundColor: 'white',
                                          borderRadius: '8px',
                                        }}
                                      />

                                      <Typography className='goal-box-typography'>
                                        DVP
                                      </Typography>
                                      <TextField
                                        inputProps={{
                                          'aria-label': 'Without label',
                                        }}
                                        type='number'
                                        fullWidth
                                        value={
                                          formState.subgoals?.[0]?.DVP || ''
                                        }
                                        onChange={(e) => {
                                          const value = e.target.value;
                                          setFormState((prev) => ({
                                            ...prev,
                                            subgoals: [
                                              {
                                                ...(prev.subgoals?.[0] || {}),
                                                DVP: value,
                                              },
                                              ...(prev.subgoals?.slice(1) ||
                                                []),
                                            ],
                                          }));
                                        }}
                                        className='goal-textfield'
                                        sx={{
                                          width: '10%',
                                          backgroundColor: 'white',
                                          borderRadius: '8px',
                                        }}
                                      />

                                      <Typography className='goal-box-typography'>
                                        PVP
                                      </Typography>
                                      <TextField
                                        inputProps={{
                                          'aria-label': 'Without label',
                                        }}
                                        type='number'
                                        fullWidth
                                        value={
                                          formState.subgoals?.[0]?.PVP || ''
                                        }
                                        onChange={(e) => {
                                          const value = e.target.value;
                                          setFormState((prev) => ({
                                            ...prev,
                                            subgoals: [
                                              {
                                                ...(prev.subgoals?.[0] || {}),
                                                PVP: value,
                                              },
                                              ...(prev.subgoals?.slice(1) ||
                                                []),
                                            ],
                                          }));
                                        }}
                                        className='goal-textfield'
                                        sx={{
                                          width: '10%',
                                          backgroundColor: 'white',
                                          borderRadius: '8px',
                                        }}
                                      />
                                    </Box>

                                    <Box sx={{ display: 'flex', gap: 2 }}>
                                      <Typography className='goal-box-typography'>
                                        GP
                                      </Typography>
                                      <TextField
                                        inputProps={{
                                          'aria-label': 'Without label',
                                        }}
                                        type='number'
                                        fullWidth
                                        value={
                                          formState.subgoals?.[0]?.GP || ''
                                        }
                                        onChange={(e) => {
                                          const value = e.target.value;
                                          setFormState((prev) => ({
                                            ...prev,
                                            subgoals: [
                                              {
                                                ...(prev.subgoals?.[0] || {}),
                                                GP: value,
                                              },
                                              ...(prev.subgoals?.slice(1) ||
                                                []),
                                            ],
                                          }));
                                        }}
                                        className='goal-textfield'
                                        sx={{
                                          width: '10%',
                                          backgroundColor: 'white',
                                          borderRadius: '8px',
                                        }}
                                      />

                                      <Typography className='goal-box-typography'>
                                        PP
                                      </Typography>
                                      <TextField
                                        inputProps={{
                                          'aria-label': 'Without label',
                                        }}
                                        type='number'
                                        fullWidth
                                        value={
                                          formState.subgoals?.[0]?.PP || ''
                                        }
                                        onChange={(e) => {
                                          const value = e.target.value;
                                          setFormState((prev) => ({
                                            ...prev,
                                            subgoals: [
                                              {
                                                ...(prev.subgoals?.[0] || {}),
                                                PP: value,
                                              },
                                              ...(prev.subgoals?.slice(1) ||
                                                []),
                                            ],
                                          }));
                                        }}
                                        className='goal-textfield'
                                        sx={{
                                          width: '10%',
                                          backgroundColor: 'white',
                                          borderRadius: '8px',
                                        }}
                                      />
                                    </Box>
                                  </Box>
                                )}
                              </>
                            )}
                          </Box>
                          <FormControl component='fieldset' margin='normal'>
                            <Typography
                              className='goal-box-typography'
                              gutterBottom
                            >
                              Does target have to be achieved multiple times to
                              meet this subgoal?
                            </Typography>
                            <RadioGroup
                              row
                              name='subgoalMultipleTimesToMeetGoal'
                              value={
                                formState.subgoals?.[0]
                                  ?.multipleTimesToMeetGoal || 'No'
                              }
                              onChange={(e) => {
                                const value = e.target.value;
                                setFormState((prev) => ({
                                  ...prev,
                                  subgoals: [
                                    {
                                      ...(prev.subgoals?.[0] || {}),
                                      multipleTimesToMeetGoal: value,
                                    },
                                    ...(prev.subgoals?.slice(1) || []),
                                  ],
                                }));
                              }}
                            >
                              <FormControlLabel
                                className='goal-box-typography'
                                value='Yes'
                                control={<Radio />}
                                label='Yes'
                              />
                              <FormControlLabel
                                className='goal-box-typography'
                                value='No'
                                control={<Radio />}
                                label='No'
                              />
                            </RadioGroup>
                          </FormControl>
                          {formState.subgoals?.[0]?.multipleTimesToMeetGoal ===
                            'Yes' && (
                            <Box
                              className='select-student-box'
                              gap={2}
                              sx={{ ml: 4 }}
                            >
                              <Typography
                                className='goal-box-typography'
                                gutterBottom
                              >
                                How many sessions?
                              </Typography>
                              <FormControl sx={{ maxWidth: 80 }}>
                                <Box
                                  sx={{ display: 'flex', alignItems: 'center' }}
                                >
                                  <TextField
                                    type='number'
                                    value={
                                      formState.subgoals?.[0]
                                        ?.numberOfSessions || ''
                                    }
                                    name='subgoalNumberOfSessions'
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      if (value >= 0) {
                                        setFormState((prev) => ({
                                          ...prev,
                                          subgoals: [
                                            {
                                              ...(prev.subgoals?.[0] || {}),
                                              numberOfSessions: value,
                                            },
                                            ...(prev.subgoals?.slice(1) || []),
                                          ],
                                        }));
                                      }
                                    }}
                                    sx={{
                                      height: '40px',
                                      borderRadius: '8px',
                                      backgroundColor:
                                        'var(--clr-mindly-white)',
                                      boxShadow:
                                        '0px 0px 10px rgba(0,0,0,0.1);',
                                    }}
                                  />
                                </Box>
                              </FormControl>
                            </Box>
                          )}
                          {formState.subgoals?.[0]?.multipleTimesToMeetGoal ===
                            'Yes' && (
                            <Box
                              className='select-student-box'
                              gap={2}
                              sx={{ ml: 4 }}
                            >
                              <Typography
                                className='goal-box-typography'
                                gutterBottom
                              >
                                Does it have to be consecutive?
                              </Typography>
                              <RadioGroup
                                row
                                name='subgoalConsecutive'
                                value={
                                  formState.subgoals?.[0]?.consecutive || 'No'
                                }
                                onChange={(e) => {
                                  const value = e.target.value;
                                  setFormState((prev) => ({
                                    ...prev,
                                    subgoals: [
                                      {
                                        ...(prev.subgoals?.[0] || {}),
                                        consecutive: value,
                                      },
                                      ...(prev.subgoals?.slice(1) || []),
                                    ],
                                  }));
                                }}
                              >
                                <FormControlLabel
                                  className='goal-box-typography'
                                  value='Yes'
                                  control={<Radio />}
                                  label='Yes'
                                />
                                <FormControlLabel
                                  className='goal-box-typography'
                                  value='No'
                                  control={<Radio />}
                                  label='No'
                                />
                              </RadioGroup>
                            </Box>
                          )}

                          <Box sx={{ mt: 3 }}>
                            <Typography
                              className='goal-box-typography'
                              gutterBottom
                            >
                              Is this the current goal?
                            </Typography>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={
                                    formState.subgoals?.[0]?.isCurrentGoal ||
                                    false
                                  }
                                  onChange={() => {
                                    setFormState((prev) => {
                                      const updatedSubgoals = prev.subgoals.map(
                                        (sg, idx) => ({
                                          ...sg,
                                          isCurrentGoal:
                                            idx === 0
                                              ? !sg.isCurrentGoal
                                              : false,
                                        })
                                      );

                                      return {
                                        ...prev,
                                        subgoals: updatedSubgoals,
                                      };
                                    });
                                  }}
                                />
                              }
                              label={
                                formState.subgoals?.[0]?.isCurrentGoal
                                  ? 'Yes'
                                  : 'No'
                              }
                            />
                            <Typography
                              variant='caption'
                              sx={{
                                display: 'block',
                                mt: 1,
                                color: 'text.secondary',
                              }}
                            >
                              When set to Yes, all other subgoals will be
                              automatically set to No
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'right',
                              mt: 1,
                            }}
                          >
                            {formState?.subgoals?.length === 1 && (
                              <Button
                                onClick={addSubgoal}
                                sx={{
                                  padding: '10px 20px',
                                  color: 'black',
                                  textTransform: 'none',
                                  '&:hover': {
                                    backgroundColor: 'rgba(145, 146, 239, 0.1)',
                                  },
                                }}
                              >
                                + Add Another Subgoal
                              </Button>
                            )}
                          </Box>
                        </Box>
                        {formState.subgoals.slice(1).map((subgoal, index) => (
                          <Box key={index + 1}>
                            <Divider
                              sx={{
                                my: 2,
                                borderBottomWidth: 2,
                                bgcolor: 'black',
                              }}
                            />

                            <Box sx={{ mt: 2, mb: 3 }}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                  mb: 2,
                                }}
                              >
                                <Typography
                                  variant='h6'
                                  sx={{ fontWeight: 'bold' }}
                                >
                                  Subgoal #{index + 2}
                                </Typography>

                                <Button
                                  onClick={() => removeSubgoal(index + 1)}
                                  variant='outlined'
                                  sx={{
                                    minWidth: '40px',
                                    width: '40px',
                                    height: '40px',
                                    p: 0,
                                    borderRadius: '50%',
                                    borderColor: 'var(--clr-mindly-purple)',
                                    color: 'var(--clr-mindly-purple)',
                                  }}
                                >
                                  <Typography sx={{ fontSize: 40 }}>
                                    -
                                  </Typography>
                                </Button>
                              </Box>

                              {/* Target fields */}
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                  gap: 2,
                                  width: '100%',
                                  mb: 2,
                                }}
                              >
                                <Box
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 1,
                                  }}
                                >
                                  <Typography className='goal-box-typography'>
                                    Target:
                                  </Typography>
                                  <FormControl sx={{ minWidth: 150 }}>
                                    <Select
                                      value={subgoal.target || 'Atleast'}
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        setFormState((prev) => {
                                          const updatedSubgoals = [
                                            ...prev.subgoals,
                                          ];
                                          updatedSubgoals[index + 1] = {
                                            ...updatedSubgoals[index + 1],
                                            target: value,
                                          };
                                          return {
                                            ...prev,
                                            subgoals: updatedSubgoals,
                                          };
                                        });
                                      }}
                                      sx={{
                                        height: '40px',
                                        borderRadius: '8px',
                                        backgroundColor:
                                          'var(--clr-mindly-white)',
                                        boxShadow:
                                          '0px 0px 10px rgba(0,0,0,0.1)',
                                        width: '100%',
                                      }}
                                    >
                                      <MenuItem value='Atmost'>
                                        At most
                                      </MenuItem>
                                      <MenuItem value='Atleast'>
                                        At least
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Box>

                                <Box
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 1,
                                  }}
                                >
                                  <TextField
                                    type='number'
                                    value={subgoal.measurement || ''}
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      if (value >= 0) {
                                        setFormState((prev) => {
                                          const updatedSubgoals = [
                                            ...prev.subgoals,
                                          ];
                                          updatedSubgoals[index + 1] = {
                                            ...updatedSubgoals[index + 1],
                                            measurement: value,
                                          };
                                          return {
                                            ...prev,
                                            subgoals: updatedSubgoals,
                                          };
                                        });
                                      }
                                    }}
                                    sx={{
                                      height: '40px',
                                      borderRadius: '8px',
                                      backgroundColor:
                                        'var(--clr-mindly-white)',
                                      boxShadow: '0px 0px 10px rgba(0,0,0,0.1)',
                                      maxWidth: 80,
                                    }}
                                  />
                                  <Typography className='goal-box-typography'>
                                    {formState.goalMeasurement ===
                                      'Frequency' && 'times'}
                                    {formState.goalMeasurement === 'Duration' &&
                                      'min'}
                                    {formState.goalMeasurement ===
                                      'Percentage' && '%'}
                                    {formState.goalMeasurement === 'Trials' &&
                                      (formState.dataType === 'others'
                                        ? 'others'
                                        : formState.dataType)}
                                  </Typography>

                                  {formState.goalMeasurement === 'Trials' && (
                                    <Typography>
                                      {' '}
                                      in {formState?.trailsMin} out of{' '}
                                      {formState?.trailsMax} trials
                                    </Typography>
                                  )}
                                </Box>
                              </Box>
                              <Box sx={{ mt: 2 }}>
                                {formState.subgoals[index + 1]?.criteria?.map(
                                  (criterion, criterionIndex) => (
                                    <Box
                                      key={criterionIndex}
                                      sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 2,
                                        marginTop: 1,
                                        padding: '4px 0',
                                      }}
                                    >
                                      <Typography className='goal-box-typography'>
                                        Target:
                                      </Typography>

                                      <FormControl sx={{ minWidth: 150 }}>
                                        <Select
                                          value={criterion.target || 'Atleast'}
                                          onChange={(e) =>
                                            handleSubgoalCriterionChange(
                                              index + 1,
                                              criterionIndex,
                                              'target',
                                              e.target.value
                                            )
                                          }
                                          sx={{
                                            height: '40px',
                                            borderRadius: '8px',
                                            backgroundColor:
                                              'var(--clr-mindly-white)',
                                            boxShadow:
                                              '0px 0px 10px rgba(0,0,0,0.1)',
                                            width: '100%',
                                          }}
                                        >
                                          <MenuItem value='Atmost'>
                                            At most
                                          </MenuItem>
                                          <MenuItem value='Atleast'>
                                            At least
                                          </MenuItem>
                                        </Select>
                                      </FormControl>

                                      <TextField
                                        type='number'
                                        value={criterion.measurement || ''}
                                        onChange={(e) => {
                                          const value = e.target.value;
                                          if (value >= 0) {
                                            handleSubgoalCriterionChange(
                                              index + 1,
                                              criterionIndex,
                                              'measurement',
                                              value
                                            );
                                          }
                                        }}
                                        sx={{
                                          height: '40px',
                                          borderRadius: '8px',
                                          backgroundColor:
                                            'var(--clr-mindly-white)',
                                          boxShadow:
                                            '0px 0px 10px rgba(0,0,0,0.1)',
                                          maxWidth: 70,
                                          flexShrink: 0,
                                        }}
                                      />
                                      <Typography className='goal-box-typography'>
                                        {criterion.measurementType ===
                                          'Frequency' && 'times of'}
                                        {criterion.measurementType ===
                                          'Duration' && 'mins of'}
                                        {criterion.measurementType ===
                                          'Percentage' && '% of'}
                                      </Typography>

                                      <Typography>
                                        {criterion.variable}
                                      </Typography>
                                    </Box>
                                  )
                                )}
                              </Box>

                              <Box>
                                <Typography
                                  className='goal-box-typography'
                                  sx={{ mt: 2 }}
                                >
                                  Is Prompting Applicable for this subgoal?
                                </Typography>
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={
                                        subgoal.isPromptingApplicable || false
                                      }
                                      onChange={() => {
                                        setFormState((prev) => {
                                          const updatedSubgoals = [
                                            ...prev.subgoals,
                                          ];
                                          updatedSubgoals[index + 1] = {
                                            ...updatedSubgoals[index + 1],
                                            isPromptingApplicable:
                                              !updatedSubgoals[index + 1]
                                                .isPromptingApplicable,
                                          };
                                          return {
                                            ...prev,
                                            subgoals: updatedSubgoals,
                                          };
                                        });
                                      }}
                                    />
                                  }
                                  label={
                                    subgoal.isPromptingApplicable ? 'Yes' : 'No'
                                  }
                                />

                                {subgoal.isPromptingApplicable && (
                                  <>
                                    <Typography
                                      className='goal-box-typography'
                                      sx={{ mt: 2 }}
                                    >
                                      Which Type:
                                    </Typography>
                                    <RadioGroup
                                      value={subgoal.promptingType || ''}
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        setFormState((prev) => {
                                          const updatedSubgoals = [
                                            ...prev.subgoals,
                                          ];
                                          updatedSubgoals[index + 1] = {
                                            ...updatedSubgoals[index + 1],
                                            promptingType: value,
                                          };
                                          return {
                                            ...prev,
                                            subgoals: updatedSubgoals,
                                          };
                                        });
                                      }}
                                    >
                                      <FormControlLabel
                                        className='goal-box-typography'
                                        value='P+ & P-'
                                        control={<Radio />}
                                        label='P+ & P-'
                                      />

                                      {subgoal.promptingType === 'P+ & P-' && (
                                        <Box
                                          sx={{
                                            display: 'flex',
                                            gap: 2,
                                            alignItems: 'center',
                                            mt: 1,
                                          }}
                                        >
                                          <Typography className='goal-box-typography'>
                                            How many allowed per trail?
                                          </Typography>
                                          <Typography className='goal-box-typography'>
                                            P-
                                          </Typography>
                                          <TextField
                                            inputProps={{
                                              'aria-label': 'Without label',
                                            }}
                                            type='number'
                                            fullWidth
                                            value={subgoal.pMinus || ''}
                                            onChange={(e) => {
                                              const value = e.target.value;
                                              setFormState((prev) => {
                                                const updatedSubgoals = [
                                                  ...prev.subgoals,
                                                ];
                                                updatedSubgoals[index + 1] = {
                                                  ...updatedSubgoals[index + 1],
                                                  pMinus: value,
                                                };
                                                return {
                                                  ...prev,
                                                  subgoals: updatedSubgoals,
                                                };
                                              });
                                            }}
                                            className='goal-textfield'
                                            sx={{
                                              width: '10%',
                                              backgroundColor: 'white',
                                              borderRadius: '8px',
                                            }}
                                          />

                                          <Typography className='goal-box-typography'>
                                            P+
                                          </Typography>
                                          <TextField
                                            inputProps={{
                                              'aria-label': 'Without label',
                                            }}
                                            type='number'
                                            fullWidth
                                            value={subgoal.pPlus || ''}
                                            onChange={(e) => {
                                              const value = e.target.value;
                                              setFormState((prev) => {
                                                const updatedSubgoals = [
                                                  ...prev.subgoals,
                                                ];
                                                updatedSubgoals[index + 1] = {
                                                  ...updatedSubgoals[index + 1],
                                                  pPlus: value,
                                                };
                                                return {
                                                  ...prev,
                                                  subgoals: updatedSubgoals,
                                                };
                                              });
                                            }}
                                            className='goal-textfield'
                                            sx={{
                                              width: '10%',
                                              backgroundColor: 'white',
                                              borderRadius: '8px',
                                            }}
                                          />
                                        </Box>
                                      )}

                                      <FormControlLabel
                                        className='goal-box-typography'
                                        value='IVP, DVP, PVP, GP, PP'
                                        control={<Radio />}
                                        label='I, VP (IVP, DVP, PVP), GP, PP'
                                      />
                                    </RadioGroup>

                                    {subgoal.promptingType ===
                                      'IVP, DVP, PVP, GP, PP' && (
                                      <Box
                                        sx={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                          gap: 1,
                                          mt: 1,
                                        }}
                                      >
                                        <Box sx={{ display: 'flex', gap: 5 }}>
                                          <Typography className='goal-box-typography'>
                                            I
                                          </Typography>
                                          <TextField
                                            inputProps={{
                                              'aria-label': 'Without label',
                                            }}
                                            type='number'
                                            fullWidth
                                            value={subgoal.I || ''}
                                            onChange={(e) => {
                                              const value = e.target.value;
                                              setFormState((prev) => {
                                                const updatedSubgoals = [
                                                  ...prev.subgoals,
                                                ];
                                                updatedSubgoals[index + 1] = {
                                                  ...updatedSubgoals[index + 1],
                                                  I: value,
                                                };
                                                return {
                                                  ...prev,
                                                  subgoals: updatedSubgoals,
                                                };
                                              });
                                            }}
                                            className='goal-textfield'
                                            sx={{
                                              width: '10%',
                                              backgroundColor: 'white',
                                              borderRadius: '8px',
                                            }}
                                          />

                                          <Typography className='goal-box-typography'>
                                            VP
                                          </Typography>
                                          <TextField
                                            inputProps={{
                                              'aria-label': 'Without label',
                                            }}
                                            type='number'
                                            fullWidth
                                            value={subgoal.VP || ''}
                                            onChange={(e) => {
                                              const value = e.target.value;
                                              setFormState((prev) => {
                                                const updatedSubgoals = [
                                                  ...prev.subgoals,
                                                ];
                                                updatedSubgoals[index + 1] = {
                                                  ...updatedSubgoals[index + 1],
                                                  VP: value,
                                                };
                                                return {
                                                  ...prev,
                                                  subgoals: updatedSubgoals,
                                                };
                                              });
                                            }}
                                            className='goal-textfield'
                                            sx={{
                                              width: '10%',
                                              backgroundColor: 'white',
                                              borderRadius: '8px',
                                            }}
                                          />
                                        </Box>

                                        <Box sx={{ display: 'flex', gap: 4 }}>
                                          <Typography className='goal-box-typography'>
                                            IVP
                                          </Typography>
                                          <TextField
                                            inputProps={{
                                              'aria-label': 'Without label',
                                            }}
                                            type='number'
                                            fullWidth
                                            value={subgoal.IVP || ''}
                                            onChange={(e) => {
                                              const value = e.target.value;
                                              setFormState((prev) => {
                                                const updatedSubgoals = [
                                                  ...prev.subgoals,
                                                ];
                                                updatedSubgoals[index + 1] = {
                                                  ...updatedSubgoals[index + 1],
                                                  IVP: value,
                                                };
                                                return {
                                                  ...prev,
                                                  subgoals: updatedSubgoals,
                                                };
                                              });
                                            }}
                                            className='goal-textfield'
                                            sx={{
                                              width: '10%',
                                              backgroundColor: 'white',
                                              borderRadius: '8px',
                                            }}
                                          />

                                          <Typography className='goal-box-typography'>
                                            DVP
                                          </Typography>
                                          <TextField
                                            inputProps={{
                                              'aria-label': 'Without label',
                                            }}
                                            type='number'
                                            fullWidth
                                            value={subgoal.DVP || ''}
                                            onChange={(e) => {
                                              const value = e.target.value;
                                              setFormState((prev) => {
                                                const updatedSubgoals = [
                                                  ...prev.subgoals,
                                                ];
                                                updatedSubgoals[index + 1] = {
                                                  ...updatedSubgoals[index + 1],
                                                  DVP: value,
                                                };
                                                return {
                                                  ...prev,
                                                  subgoals: updatedSubgoals,
                                                };
                                              });
                                            }}
                                            className='goal-textfield'
                                            sx={{
                                              width: '10%',
                                              backgroundColor: 'white',
                                              borderRadius: '8px',
                                            }}
                                          />

                                          <Typography className='goal-box-typography'>
                                            PVP
                                          </Typography>
                                          <TextField
                                            inputProps={{
                                              'aria-label': 'Without label',
                                            }}
                                            type='number'
                                            fullWidth
                                            value={subgoal.PVP || ''}
                                            onChange={(e) => {
                                              const value = e.target.value;
                                              setFormState((prev) => {
                                                const updatedSubgoals = [
                                                  ...prev.subgoals,
                                                ];
                                                updatedSubgoals[index + 1] = {
                                                  ...updatedSubgoals[index + 1],
                                                  PVP: value,
                                                };
                                                return {
                                                  ...prev,
                                                  subgoals: updatedSubgoals,
                                                };
                                              });
                                            }}
                                            className='goal-textfield'
                                            sx={{
                                              width: '10%',
                                              backgroundColor: 'white',
                                              borderRadius: '8px',
                                            }}
                                          />
                                        </Box>

                                        <Box sx={{ display: 'flex', gap: 2 }}>
                                          <Typography className='goal-box-typography'>
                                            GP
                                          </Typography>
                                          <TextField
                                            inputProps={{
                                              'aria-label': 'Without label',
                                            }}
                                            type='number'
                                            fullWidth
                                            value={subgoal.GP || ''}
                                            onChange={(e) => {
                                              const value = e.target.value;
                                              setFormState((prev) => {
                                                const updatedSubgoals = [
                                                  ...prev.subgoals,
                                                ];
                                                updatedSubgoals[index + 1] = {
                                                  ...updatedSubgoals[index + 1],
                                                  GP: value,
                                                };
                                                return {
                                                  ...prev,
                                                  subgoals: updatedSubgoals,
                                                };
                                              });
                                            }}
                                            className='goal-textfield'
                                            sx={{
                                              width: '10%',
                                              backgroundColor: 'white',
                                              borderRadius: '8px',
                                            }}
                                          />

                                          <Typography className='goal-box-typography'>
                                            PP
                                          </Typography>
                                          <TextField
                                            inputProps={{
                                              'aria-label': 'Without label',
                                            }}
                                            type='number'
                                            fullWidth
                                            value={subgoal.PP || ''}
                                            onChange={(e) => {
                                              const value = e.target.value;
                                              setFormState((prev) => {
                                                const updatedSubgoals = [
                                                  ...prev.subgoals,
                                                ];
                                                updatedSubgoals[index + 1] = {
                                                  ...updatedSubgoals[index + 1],
                                                  PP: value,
                                                };
                                                return {
                                                  ...prev,
                                                  subgoals: updatedSubgoals,
                                                };
                                              });
                                            }}
                                            className='goal-textfield'
                                            sx={{
                                              width: '10%',
                                              backgroundColor: 'white',
                                              borderRadius: '8px',
                                            }}
                                          />
                                        </Box>
                                      </Box>
                                    )}
                                  </>
                                )}
                              </Box>
                              <FormControl component='fieldset' margin='normal'>
                                <Typography
                                  className='goal-box-typography'
                                  gutterBottom
                                >
                                  Does target have to be achieved multiple times
                                  to meet this subgoal?
                                </Typography>
                                <RadioGroup
                                  row
                                  value={
                                    subgoal.multipleTimesToMeetGoal || 'No'
                                  }
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    setFormState((prev) => {
                                      const updatedSubgoals = [
                                        ...prev.subgoals,
                                      ];
                                      updatedSubgoals[index + 1] = {
                                        ...updatedSubgoals[index + 1],
                                        multipleTimesToMeetGoal: value,
                                      };
                                      return {
                                        ...prev,
                                        subgoals: updatedSubgoals,
                                      };
                                    });
                                  }}
                                >
                                  <FormControlLabel
                                    className='goal-box-typography'
                                    value='Yes'
                                    control={<Radio />}
                                    label='Yes'
                                  />
                                  <FormControlLabel
                                    className='goal-box-typography'
                                    value='No'
                                    control={<Radio />}
                                    label='No'
                                  />
                                </RadioGroup>
                              </FormControl>

                              {subgoal.multipleTimesToMeetGoal === 'Yes' && (
                                <Box
                                  className='select-student-box'
                                  gap={2}
                                  sx={{ ml: 4 }}
                                >
                                  <Typography
                                    className='goal-box-typography'
                                    gutterBottom
                                  >
                                    How many sessions?
                                  </Typography>
                                  <FormControl sx={{ maxWidth: 80 }}>
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <TextField
                                        type='number'
                                        value={subgoal.numberOfSessions || ''}
                                        onChange={(e) => {
                                          const value = e.target.value;
                                          if (value >= 0) {
                                            setFormState((prev) => {
                                              const updatedSubgoals = [
                                                ...prev.subgoals,
                                              ];
                                              updatedSubgoals[index + 1] = {
                                                ...updatedSubgoals[index + 1],
                                                numberOfSessions: value,
                                              };
                                              return {
                                                ...prev,
                                                subgoals: updatedSubgoals,
                                              };
                                            });
                                          }
                                        }}
                                        sx={{
                                          height: '40px',
                                          borderRadius: '8px',
                                          backgroundColor:
                                            'var(--clr-mindly-white)',
                                          boxShadow:
                                            '0px 0px 10px rgba(0,0,0,0.1);',
                                        }}
                                      />
                                    </Box>
                                  </FormControl>
                                </Box>
                              )}

                              {subgoal.multipleTimesToMeetGoal === 'Yes' && (
                                <Box
                                  className='select-student-box'
                                  gap={2}
                                  sx={{ ml: 4 }}
                                >
                                  <Typography
                                    className='goal-box-typography'
                                    gutterBottom
                                  >
                                    Does it have to be consecutive?
                                  </Typography>
                                  <RadioGroup
                                    row
                                    value={subgoal.consecutive || 'No'}
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      setFormState((prev) => {
                                        const updatedSubgoals = [
                                          ...prev.subgoals,
                                        ];
                                        updatedSubgoals[index + 1] = {
                                          ...updatedSubgoals[index + 1],
                                          consecutive: value,
                                        };
                                        return {
                                          ...prev,
                                          subgoals: updatedSubgoals,
                                        };
                                      });
                                    }}
                                  >
                                    <FormControlLabel
                                      className='goal-box-typography'
                                      value='Yes'
                                      control={<Radio />}
                                      label='Yes'
                                    />
                                    <FormControlLabel
                                      className='goal-box-typography'
                                      value='No'
                                      control={<Radio />}
                                      label='No'
                                    />
                                  </RadioGroup>
                                </Box>
                              )}

                              <Box sx={{ mt: 3 }}>
                                <Typography
                                  className='goal-box-typography'
                                  gutterBottom
                                >
                                  Is this the current goal?
                                </Typography>
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={subgoal.isCurrentGoal || false}
                                      onChange={() => {
                                        setFormState((prev) => {
                                          const updatedSubgoals =
                                            prev.subgoals.map((sg, idx) => ({
                                              ...sg,
                                              isCurrentGoal:
                                                idx === index + 1
                                                  ? !sg.isCurrentGoal
                                                  : false,
                                            }));

                                          return {
                                            ...prev,
                                            subgoals: updatedSubgoals,
                                          };
                                        });
                                      }}
                                    />
                                  }
                                  label={subgoal.isCurrentGoal ? 'Yes' : 'No'}
                                />
                                <Typography
                                  variant='caption'
                                  sx={{
                                    display: 'block',
                                    mt: 1,
                                    color: 'text.secondary',
                                  }}
                                >
                                  When set to Yes, all other subgoals will be
                                  automatically set to No
                                </Typography>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'right',
                                    mt: 1,
                                  }}
                                >
                                  {formState?.subgoals?.length - 2 ===
                                    index && (
                                    <Button
                                      onClick={addSubgoal}
                                      sx={{
                                        padding: '10px 20px',
                                        color: 'black',
                                        textTransform: 'none',
                                        '&:hover': {
                                          backgroundColor:
                                            'rgba(145, 146, 239, 0.1)',
                                        },
                                      }}
                                    >
                                      + Add Another Subgoal
                                    </Button>
                                  )}
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        ))}
                      </>
                    )}
                  </FormControl>
                  <Box>
                    <Divider
                      sx={{ my: 2, borderBottomWidth: 3, bgcolor: 'black' }}
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={formState.enableNotifications}
                          onChange={handleChange}
                          name='enableNotifications'
                        />
                      }
                      label='Enable Notifications'
                      labelPlacement='start'
                      className='goal-box-typography'
                      sx={{
                        width: '100%',
                        justifyContent: 'space-between',
                        m: 0,
                      }}
                    />

                    {formState.enableNotifications && (
                      <Box>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={formState.progress_declining}
                              onChange={handleChange}
                              name='progress_declining'
                            />
                          }
                          label='If progress is declining for more than 3 sessions'
                          labelPlacement='start'
                          className='goal-box-typography'
                          sx={{
                            width: '100%',
                            justifyContent: 'space-between',
                            m: 0,
                          }}
                        />
                        <FormControlLabel
                          control={
                            <Switch
                              checked={
                                formState.progress_100_more_than_sessions
                              }
                              onChange={handleChange}
                              name='progress_100_more_than_sessions'
                            />
                          }
                          label='If progress is 100% or more for more than 3 sessions'
                          labelPlacement='start'
                          className='goal-box-typography'
                          sx={{
                            width: '100%',
                            justifyContent: 'space-between',
                            m: 0,
                          }}
                        />
                        <FormControlLabel
                          control={
                            <Switch
                              checked={formState.progress_no_data}
                              onChange={handleChange}
                              name='progress_no_data'
                            />
                          }
                          label='If there is no data entry for more than a week. '
                          labelPlacement='start'
                          className='goal-box-typography'
                          sx={{
                            width: '100%',
                            justifyContent: 'space-between',
                            m: 0,
                          }}
                        />
                        <FormControlLabel
                          control={
                            <Switch
                              checked={formState.progress_goal_stagnation}
                              onChange={handleChange}
                              name='progress_goal_stagnation'
                            />
                          }
                          label='If there is goal stagnantion for longer than 2 weeks '
                          labelPlacement='start'
                          className='goal-box-typography'
                          sx={{
                            width: '100%',
                            justifyContent: 'space-between',
                            m: 0,
                          }}
                        />
                        <FormControlLabel
                          control={
                            <Switch
                              checked={formState.progress_on_subobjective}
                              onChange={handleChange}
                              name='progress_on_subobjective'
                            />
                          }
                          label='One on sub-objective for longer than a month. '
                          labelPlacement='start'
                          className='goal-box-typography'
                          sx={{
                            width: '100%',
                            justifyContent: 'space-between',
                            m: 0,
                          }}
                        />
                      </Box>
                    )}
                  </Box>

                  <Divider
                    sx={{ my: 2, borderBottomWidth: 3, bgcolor: 'black' }}
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={formState.generateGraphs}
                        onChange={handleChange}
                        name='generateGraphs'
                      />
                    }
                    label='Generate graphs'
                    labelPlacement='start'
                    className='goal-box-typography'
                    sx={{
                      width: '100%',
                      justifyContent: 'space-between',
                      m: 0,
                    }}
                  />

                  <Divider
                    sx={{ my: 2, borderBottomWidth: 3, bgcolor: 'black' }}
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={formState.generateReports}
                        onChange={handleChange}
                        name='generateReports'
                      />
                    }
                    className='goal-box-typography'
                    labelPlacement='start'
                    label='Generate reports'
                    sx={{
                      width: '100%',
                      justifyContent: 'space-between',
                      m: 0,
                    }}
                  />
                  {(ACM[typeOfUser]?.isDoctor ||
                    ACM[typeOfUser]?.isTeacher) && (
                    <>
                      <Divider
                        sx={{ my: 2, borderBottomWidth: 3, bgcolor: 'black' }}
                      />
                      <FormControlLabel
                        control={
                          <Switch
                            checked={formState.shareWithTeam}
                            onChange={handleChange}
                            name='shareWithTeam'
                          />
                        }
                        label='Share with team'
                        labelPlacement='start'
                        className='goal-box-typography'
                        sx={{
                          width: '100%',
                          justifyContent: 'space-between',
                          m: 0,
                        }}
                      />
                    </>
                  )}

                  {formState.shareWithTeam && (
                    <Box sx={{ mt: 2 }}>
                      <Typography
                        variant='subtitle2'
                        sx={{ fontWeight: 'bold', mt: 1 }}
                        className='goal-box-typography'
                      >
                        Edit & View Access:
                      </Typography>

                      <Box
                        sx={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          mb: 2,
                          fontFamily: 'Lato',
                        }}
                      >
                        {roleNames.map((edit) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={formState.editAccess.includes(edit)}
                                onChange={handleChange}
                                name='editAccess'
                                value={edit}
                              />
                            }
                            label={edit}
                            key={edit}
                            sx={{ mr: 2 }}
                          />
                        ))}
                      </Box>
                      <Typography
                        variant='subtitle2'
                        sx={{ fontWeight: 'bold', mt: 1 }}
                        className='goal-box-typography'
                      >
                        View Only Access:
                      </Typography>
                      <Box
                        sx={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          mb: 2,
                          fontFamily: 'Lato',
                        }}
                      >
                        {roleNames.map((view) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={formState.viewAccess.includes(view)}
                                onChange={handleChange}
                                name='viewAccess'
                                value={view}
                              />
                            }
                            label={view}
                            key={view}
                            sx={{ mr: 2 }}
                          />
                        ))}
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={formState.viewAccess.includes(
                                'Parent/Guardian'
                              )}
                              onChange={handleChange}
                              name='viewAccess'
                              value='Parent/Guardian'
                            />
                          }
                          label='Parent/Guardian'
                        />
                      </Box>
                    </Box>
                  )}

                  <Divider
                    sx={{ my: 2, borderBottomWidth: 3, bgcolor: 'black' }}
                  />
                  <Box
                    className='fullWidth flexBox flexCenter flexWrap'
                    gap={2}
                    mt={3}
                  >
                    <Button
                      variant='outlined'
                      onClick={handleClose}
                      sx={{
                        width: '20%',
                        fontFamily: 'Lato',
                        textTransform: 'capitalize',
                        color: 'var(--clr-theme-purple)',
                        borderColor: 'var(--clr-theme-purple)',
                        fontWeight: 'bold',
                      }}
                      // disabled={isLoading}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant='extended'
                      onClick={addGoalDetailsSaveHandler}
                      sx={{
                        width: '20%',
                        // alignSelf: "end",
                        backgroundColor: loading
                          ? '#B0B0B0'
                          : 'var(--clr-mindly-purple)',
                        color: 'var(--clr-theme-white)',
                        '&:hover': {
                          color: 'var(--clr-theme-white) ',
                          backgroundColor: 'var(--clr-mindly-purple-hover)',
                        },
                        '&.Mui-disabled': {
                          backgroundColor: '#B0B0B0', // Gray when disabled
                          color: '#FFFFFF',
                        },
                        alignSelf: 'end',
                        fontWeight: 'bold',
                        textTransform: 'capitalize',
                      }}
                      disabled={loading}
                    >
                      {loading ? (
                        <CircularProgress size={24} sx={{ color: 'white' }} />
                      ) : (
                        'Save Changes'
                      )}
                      {/* <SendRounded sx={{ ml: 1 }} fontSize="small" /> */}
                    </Button>
                    {/* {isLoading && <Progress />} */}
                  </Box>
                </FormControl>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      {dataEntryDialogOpen && (
        <NewGoalDialog
          open={dataEntryDialogOpen}
          onClose={() => setDataEntryDialogOpen(false)}
        />
      )}
    </MainLayout>
  );
};

export default AddGoalDetails;
