import React, { useContext, useEffect, useState, useMemo } from 'react';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Select,
  MenuItem,
  FormControl,
  Box,
  Card,
  CardContent,
  CardHeader,
  Stack,
  Grid,
  Menu,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  DialogContentText,
} from '@mui/material';

import AuthContext from '../../../contexts/AuthContext';
import { ACMContext } from '../../../contexts/ACMProvider';
import MainLayout from '../../../hoc/MainLayout';
import { More, ShowChart } from '@mui/icons-material';
import './goals.css';
import useStudentsForOneTeacher from '../../../hooks/useStudentsForOneTeacher';
import useAllStudentGoalsData from '../../../hooks/useAllStudentGoalsData';
import NewGoalDialog from '../../../components/NewGoalDialog';
import { useNavigate } from 'react-router-dom';
import PerformanceMetricsChart from '../../../components/PerformanceMetricsChart';
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  updateDoc,
} from 'firebase/firestore';
import { FirestoreDB } from '../../../utils/firebase/firebase';
import { ACM } from '../../../constants/UserRoles';

const ViewAllGoals = () => {
  // const { ACM } = useContext(ACMContext);
  const { user, typeOfUser } = useContext(AuthContext);

  const {
    data: fetchStudentsForOneTeacher = [],
    isLoading: isTeachersLoading,
    error: teachersError,
    refetch: refetchTeachers,
  } = useStudentsForOneTeacher(user?.uid, typeOfUser);

  const {
    data: studentsGoalData = [],
    isLoading: isGoalsLoading,
    error: goalsError,
    refetch: goalsRefetch,
  } = useAllStudentGoalsData(user?.uid, typeOfUser);

  // console.log('studentsGoalData::', studentsGoalData);

  const enrolledStudents = useMemo(
    () => fetchStudentsForOneTeacher || [],
    [fetchStudentsForOneTeacher]
  );

  const [selectedStudent, setSelectedStudent] = useState('All Students');
  const [filteredGoals, setFilteredGoals] = useState([]);
  const [selectedGoal, setSelectedGoal] = useState('All Goals');
  const [displayedGoals, setDisplayedGoals] = useState([]);
  const [chartDatas, setChartDatas] = useState([]);
  const [allGoalsForStudent, setAllGoalsForStudent] = useState([]); // To keep all goals intact
  const [goalCategories, setGoalCategories] = useState([]); // To keep all goals intact
  const [goalProgress, setGoalProgress] = useState('All Progress');
  const [dataEntryDialogOpen, setDataEntryDialogOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [goalToDelete, setGoalToDelete] = useState(null);

  const open = Boolean(anchorEl);

  const navigate = useNavigate();

  const [isArchiveDialogOpen, setIsArchiveDialogOpen] = useState(false); // State for archive/unarchive confirmation popup
  const [archiveAction, setArchiveAction] = useState(null); // Store whether to archive or unarchive

  const handleOpenArchiveDialog = (action) => {
    setArchiveAction(action);
    setIsArchiveDialogOpen(true);
  };

  const handleCloseArchiveDialog = () => {
    setIsArchiveDialogOpen(false);
  };

  const handleConfirmArchiveAction = () => {
    if (selectedIndex !== null) {
      if (archiveAction === 'archive') {
        handleArchive(displayedGoals[selectedIndex], selectedIndex);
      } else {
        handleUnarchive(displayedGoals[selectedIndex], selectedIndex);
      }
    }
    handleCloseArchiveDialog();
    handleClose(); // Close menu after action
  };

  const handleAddGoal = () => {
    navigate('/add-goal');
  };

  const handleDataEntry = () => {
    setDataEntryDialogOpen(true);
  };

  const handleClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setSelectedIndex(index); // Store the correct index
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedIndex(null);
  };

  const getFormattedDate = (daysAgo) => {
    const date = new Date();
    date.setDate(date.getDate() - daysAgo); // Subtract daysAgo from today
    const day = String(date.getDate()).padStart(2, '0'); // Ensure two digits
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const year = date.getFullYear();

    return `${month}/${day}/${year}`;
  };

  useEffect(() => {
    handleGoaProgress({ target: { value: 'All Progress' } });
  }, [studentsGoalData]);

  const calculateProgress = (goal, specificDate = null) => {
    let goalData;
    let dataCollection;
    let studentName;

    if (ACM[typeOfUser]?.isDoctor || ACM[typeOfUser]?.isTeacher) {
      goalData = Array.isArray(goal?.goalData) ? goal.goalData : [];
      dataCollection = goal?.info?.DataCollection;
      studentName = goal?.info?.StudentName;
    } else {
      goalData = Array.isArray(goal?.studentData?.goalData)
        ? goal?.studentData?.goalData
        : [];
      dataCollection = goal?.studentData?.DataCollection;
      studentName = goal?.studentData?.StudentName;
    }

    if (!dataCollection) return 'No Data';

    const target = dataCollection.measurementType.Target;
    const targetDuration = Number.parseFloat(
      dataCollection.measurementType.TargetDuration || 0
    );
    const typeOfOperation =
      dataCollection.MultipleDataPointsPerSession?.DataPointPerSession ||
      'Average';
    const isTrials = dataCollection.measurementType.Type === 'Trials';
    const trialsMin = Number.parseInt(
      dataCollection.measurementType?.TrailsValue?.TrailsMin || 0,
      10
    );
    const trialsMax = Number.parseInt(
      dataCollection.measurementType?.TrailsValue?.TrailsMax || 0,
      10
    );

    if (specificDate) {
      const specificDateString = specificDate.toDateString();
      goalData = goalData.filter((entry) => {
        if (!entry.createdAt?._seconds) return false;
        const entryDate = new Date(entry.createdAt._seconds * 1000);
        return entryDate.toDateString() === specificDateString;
      });

      if (goalData.length === 0) return 'NA';
    }

    const dateGroupedData = {};

    if (!isTrials) {
      goalData?.forEach((dataEntry) => {
        const createdAtDate =
          dataEntry.createdAt && dataEntry.createdAt._seconds
            ? new Date(dataEntry.createdAt._seconds * 1000)
            : new Date();
        const dateKey = createdAtDate.toDateString();

        const values = [];

        if (Array.isArray(dataEntry.info?.data_points)) {
          dataEntry.info.data_points.forEach((point) => {
            values.push(Number.parseFloat(point.goal_value || 0));
          });
        }

        if (!dateGroupedData[dateKey]) {
          dateGroupedData[dateKey] = [];
        }

        dateGroupedData[dateKey].push(...values);
      });

      const dateValueMap = {};

      Object.keys(dateGroupedData).forEach((dateKey) => {
        const values = dateGroupedData[dateKey];
        let dailyTotal = 0;

        switch (typeOfOperation) {
          case 'Average':
            dailyTotal =
              values.length > 0
                ? values.reduce((a, b) => a + b, 0) / values.length
                : 0;
            break;
          case 'Add':
            dailyTotal = values.reduce((a, b) => a + b, 0);
            break;
          case 'Take Highest':
            dailyTotal = values.length > 0 ? Math.max(...values) : 0;
            break;
          case 'Take Lowest':
            dailyTotal = values.length > 0 ? Math.min(...values) : 0;
            break;
          default:
            break;
        }

        dateValueMap[dateKey] = dailyTotal;
      });

      let latestDate = null;
      let latestValue = null;

      if (specificDate) {
        const specificDateString = specificDate.toDateString();
        if (dateValueMap[specificDateString] !== undefined) {
          latestDate = specificDateString;
          latestValue = dateValueMap[specificDateString];
        }
      } else {
        Object.keys(dateValueMap)
          .sort((a, b) => new Date(b) - new Date(a))
          .forEach((dateKey) => {
            if (!latestDate) {
              latestDate = dateKey;
              latestValue = dateValueMap[dateKey];
            }
          });
      }

      let lastAvailableProgress = 'No Data';

      if (latestDate && latestValue !== null) {
        let progress;
        if (target === 'At least' || target === 'Atleast') {
          progress = (latestValue / targetDuration) * 100;
        } else if (target === 'At most' || target === 'Atmost') {
          if (latestValue === 0) {
            progress = 1 - (1 - targetDuration) * 100;
            return progress ? progress.toFixed(2) + '+%' : 'No Data';
          } else {
            progress = (1 - (latestValue - targetDuration) / latestValue) * 100;
          }
        }
        lastAvailableProgress = progress
          ? progress.toFixed(2) + '%'
          : 'No Data';
      }

      return lastAvailableProgress;
    } else {
      const trialsResult = {};

      goalData?.forEach((dataEntry) => {
        const createdAtDate =
          dataEntry.createdAt && dataEntry.createdAt._seconds
            ? new Date(dataEntry.createdAt._seconds * 1000)
            : new Date();
        const dateKey = createdAtDate.toDateString();

        if (!trialsResult[dateKey]) {
          trialsResult[dateKey] = [];
        }

        dataEntry.info?.data_points?.forEach((point) => {
          if (Array.isArray(point.trialsNumber)) {
            trialsResult[dateKey].push(point.trialsNumber);
          }
        });
      });

      let latestDate;

      if (specificDate) {
        latestDate = specificDate.toDateString();

        if (
          !trialsResult[latestDate] ||
          trialsResult[latestDate].length === 0
        ) {
          return 'NA';
        }
      } else {
        latestDate = Object.keys(trialsResult).sort(
          (a, b) => new Date(b) - new Date(a)
        )[0];
      }

      const trialsArray = latestDate ? trialsResult[latestDate] : [];
      if (trialsArray.length === 0) return 'No Data';

      let filteredTrials = [];

      switch (typeOfOperation) {
        case 'Take Highest':
          filteredTrials = trialsArray
            .flat()
            .sort((a, b) => b - a)
            .slice(0, trialsMax);
          break;
        case 'Take Lowest':
          filteredTrials = trialsArray
            .flat()
            .sort((a, b) => a - b)
            .slice(0, trialsMax);
          break;
        case 'Average':
          const maxLength = Math.max(
            ...trialsArray.map((arr) => arr.length),
            0
          );

          filteredTrials = Array.from({ length: maxLength }, (_, i) => {
            let sum = 0,
              count = 0;
            trialsArray.forEach((trials) => {
              if (trials[i] !== undefined) {
                sum += trials[i];
                count++;
              }
            });
            return count > 0 ? sum / count : 0;
          });
          break;
        case 'Add':
          const maxIndex = Math.max(...trialsArray.map((arr) => arr.length), 0);

          filteredTrials = Array.from({ length: maxIndex }, (_, i) => {
            return trialsArray.reduce(
              (sum, trials) => sum + (trials[i] || 0),
              0
            );
          });
          break;
        default:
          filteredTrials = trialsArray.flat();
      }

      const successCount = filteredTrials.filter((value) =>
        target === 'At least' || target === 'Atleast'
          ? value >= targetDuration
          : value <= targetDuration
      ).length;

      if (filteredTrials.length === 0 || trialsMin === 0) return 'No Data';

      const successRate = ((successCount / trialsMin) * 100).toFixed(2);
      return successRate + '%';
    }
  };

  useEffect(() => {
    handleGoaProgress({ target: { value: 'All Progress' } });
  }, [studentsGoalData]);

  useEffect(() => {
    if (ACM[typeOfUser]?.isDoctor || ACM[typeOfUser]?.isTeacher) {
      if (!studentsGoalData || studentsGoalData.length === 0) {
        console.log('No goals');
        return;
      }

      const allGoals = studentsGoalData
        .flatMap((student) => {
          if (!Array.isArray(student.dataCollection)) return [];

          return student?.dataCollection
            .sort((a, b) => {
              const timeA =
                (a.createdAt?._seconds || 0) * 1e9 +
                (a.createdAt?._nanoseconds || 0);
              const timeB =
                (b.createdAt?._seconds || 0) * 1e9 +
                (b.createdAt?._nanoseconds || 0);
              return timeB - timeA;
            })
            .flatMap((collection) => {
              const dataCollectionData = collection?.info || {};
              const goalId = collection.id || '';
              const isArchived = collection.isArchived || false;

              if (!collection.info?.GoalDetails) return [];

              const yesterday = new Date();
              yesterday.setDate(yesterday.getDate() - 1);

              const dayBeforeYesterday = new Date();
              dayBeforeYesterday.setDate(dayBeforeYesterday.getDate() - 2);

              const progress = calculateProgress(collection);
              const step1 = calculateProgress(collection, yesterday);
              const step2 = calculateProgress(collection, dayBeforeYesterday);

              return {
                ...collection.info.GoalDetails,
                studentName: student.fullname,
                studentId: collection.info.StudentID,
                step1,
                step2,
                progress,
                dataCollection: dataCollectionData,
                goalId: goalId,
                isArchived: isArchived,
              };
            });
        })
        .filter(Boolean);

      const uniqueCategories = [
        ...new Set(allGoals.flatMap((goal) => goal.GoalCategory)),
      ];

      setAllGoalsForStudent(allGoals);
      setFilteredGoals(allGoals);
      setDisplayedGoals(allGoals);
      setSelectedGoal('All Goals');
      setGoalCategories(uniqueCategories);
    } else {
      if (!studentsGoalData || studentsGoalData.length === 0) {
        console.log('No goals');
        return;
      }

      const allGoals = studentsGoalData
        .flatMap((student) => {
          if (!student || !student.studentData) return [];

          const studentData = student?.studentData;
          if (!studentData || !studentData.DataCollection) return [];

          const yesterday = new Date();
          yesterday.setDate(yesterday.getDate() - 1);

          const dayBeforeYesterday = new Date();
          dayBeforeYesterday.setDate(dayBeforeYesterday.getDate() - 2);

          const progress = calculateProgress(student);
          const step1 = calculateProgress(student, yesterday);
          const step2 = calculateProgress(student, dayBeforeYesterday);

          return {
            ...studentData.GoalDetails,
            studentName: studentData.StudentName,
            studentId: studentData.StudentID,
            step1,
            step2,
            progress,
            dataCollection: studentData,
            goalId: student.docId,
            isArchived: studentData.isArchived,
          };
        })
        .filter(Boolean);

      const uniqueCategories = [
        ...new Set(allGoals.flatMap((goal) => goal.GoalCategory)),
      ];

      setAllGoalsForStudent(allGoals);
      setFilteredGoals(allGoals);
      setDisplayedGoals(allGoals);
      setSelectedGoal('All Goals');
      setGoalCategories(uniqueCategories);
    }
  }, [studentsGoalData]);

  const handleEdit = (goal, index) => {
    navigate('/add-goal', {
      state: { goalData: goal?.dataCollection, goals: goal },
    });
    handleClose();
  };

  const handleSelectChange = (e) => {
    const selectedValue = e.target.value;

    const processGoals = (studentData) => {
      if (!Array.isArray(studentData?.dataCollection)) return []; // ✅ Ensure dataCollection is an array

      return studentData.dataCollection.flatMap((collection) => {
        if (!collection.info?.GoalDetails) return []; // ✅ Ensure GoalDetails exist
        const isArchived = collection.isArchived || false;
        const dataCollectionData = collection?.info || {};

        const goalCreation = parseFloat(
          collection.info.DataCollection?.measurementType?.TargetDuration || 0
        );

        const targetType =
          collection.info.DataCollection?.measurementType?.Target;

        const measurementType =
          collection.info.DataCollection?.measurementType?.Type;

        const trialsMin =
          parseInt(
            collection.info.DataCollection?.measurementType?.TrailsValue
              ?.TrailsMin,
            10
          ) || 0;

        if (!['Atmost', 'Atleast'].includes(targetType)) return [];

        const goalData = Array.isArray(collection.goalData)
          ? collection.goalData
          : [];

        let totalGoalValue = 0;
        let step1 = 'NA',
          step2 = 'NA';
        let dateCountMap = {},
          dateValueMap = {};
        let trialsPerDay = {};

        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        const yesterdayKey = yesterday.toDateString();
        const dayBeforeYesterday = new Date();
        dayBeforeYesterday.setDate(dayBeforeYesterday.getDate() - 2);
        const dayBeforeYesterdayKey = dayBeforeYesterday.toDateString();

        if (measurementType === 'Trials') {
          goalData.forEach((dataEntry) => {
            if (!dataEntry.createdAt?._seconds) return;

            const createdAtDate = new Date(dataEntry.createdAt._seconds * 1000);
            const dateKey = createdAtDate.toDateString();

            let mergedTrials = [];
            if (Array.isArray(dataEntry.info?.data_points)) {
              dataEntry.info.data_points.forEach((point) => {
                if (Array.isArray(point.trialsNumber)) {
                  mergedTrials = [...mergedTrials, ...point.trialsNumber];
                }
              });
            }

            // ✅ Calculate Successful Trials
            const successfulTrials =
              targetType === 'Atmost'
                ? mergedTrials.filter((trial) => trial <= goalCreation).length
                : mergedTrials.filter((trial) => trial >= goalCreation).length;

            if (!trialsPerDay[dateKey]) {
              trialsPerDay[dateKey] = { successful: 0, total: 0 };
            }

            trialsPerDay[dateKey].successful += successfulTrials;
            trialsPerDay[dateKey].total += mergedTrials.length;
          });

          // ✅ Find the latest available past date for progress
          let allDates = Object.keys(trialsPerDay)
            .map((date) => new Date(date))
            .filter((date) => date < new Date())
            .sort((a, b) => b - a);

          let latestDate =
            allDates.length > 0 ? allDates[0].toDateString() : null;

          let progress =
            latestDate && trialsMin
              ? ((trialsPerDay[latestDate]?.successful || 0) / trialsMin) * 100
              : 'NA';

          if (progress !== 'NA') progress = progress.toFixed(2) + '%';

          // ✅ Set step1 (yesterday) and step2 (day before yesterday)
          step1 =
            trialsPerDay[yesterdayKey]?.total > 0 && trialsMin
              ? ((trialsPerDay[yesterdayKey]?.successful || 0) / trialsMin) *
                100
              : 'NA';

          step2 =
            trialsPerDay[dayBeforeYesterdayKey]?.total > 0 && trialsMin
              ? ((trialsPerDay[dayBeforeYesterdayKey]?.successful || 0) /
                  trialsMin) *
                100
              : 'NA';

          if (step1 !== 'NA') step1 = step1.toFixed(2) + '%';
          if (step2 !== 'NA') step2 = step2.toFixed(2) + '%';

          return {
            ...collection.info.GoalDetails,
            dataCollection: dataCollectionData,
            studentName: studentData.fullname,
            step1,
            step2,
            progress,
            isArchived: isArchived,
          };
        }

        // 📌 Process goal values and categorize by date
        goalData.forEach((dataEntry) => {
          const createdAtDate = new Date(dataEntry.createdAt._seconds * 1000);

          let dailyTotal = 0;
          if (Array.isArray(dataEntry.info?.data_points)) {
            dataEntry.info.data_points.forEach((point) => {
              dailyTotal += parseFloat(point.goal_value || 0);
            });
          }

          totalGoalValue += dailyTotal;

          const dateKey = createdAtDate.toDateString();
          dateValueMap[dateKey] = (dateValueMap[dateKey] || 0) + dailyTotal;
          dateCountMap[dateKey] = (dateCountMap[dateKey] || 0) + 1;
        });

        // 📌 Calculate step1 and step2 percentages
        const step1Value =
          dateValueMap[yesterday.toDateString()] /
          (dateCountMap[yesterday.toDateString()] || 1);
        const step2Value =
          dateValueMap[dayBeforeYesterday.toDateString()] /
          (dateCountMap[dayBeforeYesterday.toDateString()] || 1);

        if (targetType === 'Atmost') {
          if (step1Value > 0) {
            step1 =
              ((1 - (step1Value - goalCreation) / step1Value) * 100).toFixed(
                2
              ) + '%';
          }
          if (step2Value > 0) {
            step2 =
              ((1 - (step2Value - goalCreation) / step2Value) * 100).toFixed(
                2
              ) + '%';
          }
        } else if (targetType === 'Atleast') {
          if (step1Value > 0) {
            step1 = ((step1Value / goalCreation) * 100).toFixed(2) + '%';
          }
          if (step2Value > 0) {
            step2 = ((step2Value / goalCreation) * 100).toFixed(2) + '%';
          }
        }

        let lastAvailableProgress = 'NA';

        Object.keys(dateValueMap)
          .sort((a, b) => new Date(b) - new Date(a)) // Sort dates in descending order
          .find((dateKey) => {
            let count = dateCountMap[dateKey];
            if (!count || dateValueMap[dateKey] === 0) return false;

            let value = dateValueMap[dateKey] / count;

            if (targetType === 'Atmost') {
              lastAvailableProgress =
                (
                  (1 - (value - goalCreation) / Math.max(value, 1)) *
                  100
                ).toFixed(2) + '%';
            } else if (targetType === 'Atleast') {
              lastAvailableProgress =
                ((value / goalCreation) * 100).toFixed(2) + '%';
            }

            return true; // Stops at the first valid date
          });

        return {
          ...collection.info.GoalDetails,
          dataCollection: dataCollectionData,
          studentName: studentData.fullname,
          step1,
          step2,
          progress: lastAvailableProgress,
          isArchived: isArchived,
        };
      });
    };

    let updatedGoals = [];

    if (selectedValue === 'All Students') {
      updatedGoals = studentsGoalData.flatMap((student) =>
        processGoals(student)
      );
    } else {
      const selectedStudentData = studentsGoalData.find(
        (student) => student?.id === selectedValue
      );
      if (selectedStudentData) {
        updatedGoals = processGoals(selectedStudentData);
      }
    }

    // 🔄 Update states
    setFilteredGoals(updatedGoals);
    setDisplayedGoals(updatedGoals);
    setAllGoalsForStudent(updatedGoals);
    setSelectedGoal('All Goals');
    setSelectedStudent(selectedValue);
    setChartDatas([]);
  };

  // const handleGoalChange = (e) => {
  //   const selectedValue = e.target.value;
  //   setSelectedGoal(selectedValue);

  //   if (selectedValue === 'All Goals') {
  //     setDisplayedGoals(filteredGoals.filter((goal) => !goal.isArchived));
  //   } else if (selectedValue === 'Archived Goals') {
  //     const archivedGoals = allGoalsForStudent.filter((goal) => goal.isArchived === true);
  //     setDisplayedGoals(archivedGoals);
  //   } else {
  //     const filtered = filteredGoals.filter(
  //       (goal) =>
  //         goal.GoalCategory.includes(selectedValue) && !goal.isArchived
  //     );
  //     setDisplayedGoals(filtered);
  //   }
  // };

  const handleGoalChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedGoal(selectedValue);

    if (selectedValue === 'All Goals') {
      const activeGoals = filteredGoals.filter((goal) => !goal.isArchived);
      setDisplayedGoals(activeGoals);
    } else if (selectedValue === 'Archived Goals') {
      const archivedGoals = allGoalsForStudent.filter(
        (goal) => goal.isArchived === true
      );
      console.log('Archived Goals:', archivedGoals);
      setDisplayedGoals(archivedGoals);
    } else {
      const filtered = filteredGoals.filter(
        (goal) => goal.GoalCategory.includes(selectedValue) && !goal.isArchived
      );

      setDisplayedGoals(filtered);
    }
  };

  // useEffect(() => {
  //   if (!selectedGoal || selectedGoal === 'All Goals') {
  //     setDisplayedGoals(filteredGoals.filter((goal) => !goal.isArchived)); // Show all goals
  //   } else {
  //     // Filter goals that include the selected category
  //     const filteredByCategory = filteredGoals.filter(
  //       (goal) =>
  //         goal.GoalCategory.includes(selectedGoal) && !goal.isArchived
  //     );

  //     setDisplayedGoals(filteredByCategory);
  //   }
  // }, [selectedGoal, filteredGoals]);

  useEffect(() => {
    if (selectedGoal === 'All Goals') {
      const activeGoals = filteredGoals.filter((goal) => !goal.isArchived);

      setDisplayedGoals(activeGoals);
    } else if (selectedGoal === 'Archived Goals') {
      const archivedGoals = filteredGoals.filter((goal) => goal.isArchived);
      setDisplayedGoals(archivedGoals);
    } else {
      const filtered = filteredGoals.filter(
        (goal) => goal.GoalCategory.includes(selectedGoal) && !goal.isArchived
      );
      setDisplayedGoals(filtered);
    }
  }, [selectedGoal, filteredGoals]);

  const handleGoaProgress = (e) => {
    const selectedValue = e?.target?.value || 'All Progress';
    setGoalProgress(selectedValue);

    if (!studentsGoalData || !Array.isArray(studentsGoalData)) {
      console.warn('studentsGoalData is undefined or not an array.');
      return;
    }

    if (ACM[typeOfUser]?.isDoctor || ACM[typeOfUser]?.isTeacher) {
      let filteredGoal = [];
      let dateCountMap = {};
      let dateValueMap = {};
      let processedChartData = [];

      if (selectedValue === 'All Progress') {
        filteredGoal = studentsGoalData.flatMap((student) => {
          return Array.isArray(student.dataCollection)
            ? student.dataCollection
                .filter((goal) => {
                  const isSharingEnabled =
                    goal.info.ShareWithTeam?.IsSharingEnabled;
                  const hasAccess =
                    goal.info.ShareWithTeam?.EditAccess?.includes(
                      user?.subRoleName
                    ) ||
                    goal.info.ShareWithTeam?.ViewOnlyAccess?.includes(
                      user?.subRoleName
                    );
                  return isSharingEnabled && hasAccess;
                })
                .map((goal) => ({
                  ...goal,
                  studentName: goal?.info?.StudentName,
                }))
            : studentsGoalData.flatMap((student) =>
                Array.isArray(student.dataCollection)
                  ? student.dataCollection.map((goal) => ({
                      ...goal,
                      studentName: goal?.info?.StudentName,
                    }))
                  : []
              );
        });

        let trialsPerDay = {}; // Stores trials data per date
        let dateProgressSum = {}; // Stores summed progress values
        let dateProgressCount = {};
        let progress;

        if (filteredGoal.length > 0) {
          // filteredGoal.forEach((goal) => {
          //   const goalCreation = parseFloat(
          //     goal?.info?.DataCollection?.measurementType?.TargetDuration || 0
          //   );

          //   const trialsMin =
          //     goal?.info?.DataCollection?.measurementType?.TrailsValue
          //       .TrailsMin || 0;
          //   console.log('trialsMin', trialsMin, goal?.info?.StudentName);

          //   const goalData = Array.isArray(goal?.goalData) ? goal.goalData : [];

          //   goalData.forEach((dataEntry) => {
          //     if (!dataEntry?.createdAt?._seconds) return; // Skip invalid data

          //     const createdAtDate = new Date(
          //       dataEntry.createdAt._seconds * 1000
          //     );
          //     const dateKey = createdAtDate.toLocaleDateString('en-GB', {
          //       day: '2-digit',
          //       month: '2-digit',
          //       year: '2-digit',
          //     });

          //     let dailyTotal = 0;
          //     if (Array.isArray(dataEntry.info?.data_points)) {
          //       dataEntry.info.data_points.forEach((point) => {
          //         dailyTotal += parseFloat(point.goal_value || 0);
          //       });
          //     }

          //     dateValueMap[dateKey] = (dateValueMap[dateKey] || 0) + dailyTotal;
          //     dateCountMap[dateKey] = (dateCountMap[dateKey] || 0) + 1;
          //   });
          // });

          // let dateProgressSum = {};
          // let dateProgressCount = {};

          // Object.keys(dateValueMap).forEach((dateKey) => {
          //   const avgValue =
          //     dateValueMap[dateKey] / (dateCountMap[dateKey] || 1); // Prevent division by 0
          //   let calculatedValue = 0;

          //   filteredGoal.forEach((goal) => {
          //     const goalCreation = parseFloat(
          //       goal?.info?.DataCollection?.measurementType?.TargetDuration || 1
          //     ); // Ensure a valid denominator

          //     const targetType =
          //       goal?.info?.DataCollection?.measurementType?.Target ||
          //       'Atleast';

          //     if (targetType === 'Atleast') {
          //       calculatedValue = goalCreation
          //         ? ((avgValue / goalCreation) * 100).toFixed(2)
          //         : 0;
          //     } else if (targetType === 'Atmost') {
          //       calculatedValue = goalCreation
          //         ? (
          //             (1 - (avgValue - goalCreation) / Math.max(avgValue, 1)) *
          //             100
          //           ).toFixed(2)
          //         : 0;
          //     }

          //     // Ensure calculatedValue is a valid number
          //     calculatedValue = isFinite(parseFloat(calculatedValue))
          //       ? parseFloat(calculatedValue)
          //       : 0;

          //     dateProgressSum[dateKey] =
          //       (dateProgressSum[dateKey] || 0) + calculatedValue;
          //     dateProgressCount[dateKey] =
          //       (dateProgressCount[dateKey] || 0) + 1;
          //   });
          // });

          // Object.keys(dateProgressSum).forEach((date) => {
          //   const finalProgress =
          //     dateProgressSum[date] / dateProgressCount[date] || 1;

          //   processedChartData.push({
          //     goalTitle: date,
          //     progress: isNaN(finalProgress)
          //       ? 0
          //       : parseFloat(finalProgress.toFixed(2)),
          //   });
          // });

          filteredGoal.forEach((goal) => {
            const goalCreation = parseFloat(
              goal?.info?.DataCollection?.measurementType?.TargetDuration || 0
            );

            const measurementType =
              goal?.info?.DataCollection?.measurementType?.Type;
            const targetType =
              goal?.info?.DataCollection?.measurementType?.Target;

            const trialsMin =
              parseInt(
                goal?.info?.DataCollection?.measurementType?.TrialsValue
                  ?.TrialsMin,
                10
              ) || 0;

            const goalData = Array.isArray(goal?.goalData) ? goal.goalData : [];

            goalData.forEach((dataEntry) => {
              if (!dataEntry?.createdAt?._seconds) return;

              const createdAtDate = new Date(
                dataEntry.createdAt._seconds * 1000
              );
              const dateKey = createdAtDate.toLocaleDateString('en-GB', {
                day: '2-digit',
                month: '2-digit',
                year: '2-digit',
              });

              if (measurementType === 'Trials') {
                // Trials logic: Merge multiple `trialsNumber` arrays per date
                let mergedTrials = [];
                if (Array.isArray(dataEntry.info?.data_points)) {
                  dataEntry.info.data_points.forEach((point) => {
                    if (Array.isArray(point.trialsNumber)) {
                      mergedTrials = [...mergedTrials, ...point.trialsNumber];
                    }
                  });
                }

                const successfulTrials =
                  targetType === 'Atmost'
                    ? mergedTrials.filter((trial) => trial <= goalCreation)
                        .length
                    : mergedTrials.filter((trial) => trial >= goalCreation)
                        .length;

                if (!trialsPerDay[dateKey]) {
                  trialsPerDay[dateKey] = { successful: 0, total: 0 };
                }

                trialsPerDay[dateKey].successful += successfulTrials;
                trialsPerDay[dateKey].total += mergedTrials.length;
              } else {
                // Existing logic for Atmost & Atleast
                let dailyTotal = 0;
                if (Array.isArray(dataEntry.info?.data_points)) {
                  dataEntry.info.data_points.forEach((point) => {
                    dailyTotal += parseFloat(point.goal_value || 0);
                  });
                }

                dateValueMap[dateKey] =
                  (dateValueMap[dateKey] || 0) + dailyTotal;
                dateCountMap[dateKey] = (dateCountMap[dateKey] || 0) + 1;
              }
            });
          });

          // Process and store progress for each date (Atmost & Atleast)
          Object.keys(dateValueMap).forEach((dateKey) => {
            const avgValue = dateValueMap[dateKey] / dateCountMap[dateKey] || 1;
            let calculatedValue = 0;

            filteredGoal.forEach((goal) => {
              const goalCreation = parseFloat(
                goal?.info?.DataCollection?.measurementType?.TargetDuration || 0
              );

              const targetType =
                goal?.info?.DataCollection?.measurementType?.Target ||
                'Atleast';

              if (targetType === 'Atleast') {
                calculatedValue = ((avgValue / goalCreation) * 100).toFixed(2);
              } else if (targetType === 'Atmost') {
                calculatedValue = (
                  (1 - (avgValue - goalCreation) / Math.max(avgValue, 1)) *
                  100
                ).toFixed(2);
              }

              // Sum progress per date for averaging
              dateProgressSum[dateKey] =
                (dateProgressSum[dateKey] || 0) + parseFloat(calculatedValue);
              dateProgressCount[dateKey] =
                (dateProgressCount[dateKey] || 0) + 1;
            });
          });

          // Process trials data for each date
          Object.keys(trialsPerDay).forEach((dateKey) => {
            const successful = trialsPerDay[dateKey]?.successful || 0;
            filteredGoal.forEach((goal) => {
              const trialsMin =
                parseInt(
                  goal?.info?.DataCollection?.measurementType?.TrailsValue
                    ?.TrailsMin,
                  10
                ) || 0;

              progress = trialsMin
                ? ((successful / trialsMin) * 100).toFixed(2)
                : 'NA';
            });

            // Sum trials-based progress per date for averaging
            if (progress !== 'NA') {
              dateProgressSum[dateKey] =
                (dateProgressSum[dateKey] || 0) + parseFloat(progress);
              dateProgressCount[dateKey] =
                (dateProgressCount[dateKey] || 0) + 1;
            }
          });

          // Final averaging for each date
          Object.keys(dateProgressSum).forEach((date) => {
            const finalProgress =
              dateProgressSum[date] / dateProgressCount[date] || 1;

            processedChartData.push({
              goalTitle: date,
              progress: isNaN(finalProgress)
                ? 0
                : parseFloat(finalProgress.toFixed(2)),
            });
          });
        } else {
          console.warn('No matching goal data found.');
        }
      } else {
        const [goalTitle, studentName] = selectedValue.split('__');

        // filteredGoal = studentsGoalData.flatMap((student) =>
        //   Array.isArray(student.dataCollection)
        //     ? student.dataCollection
        //         .filter(
        //           (data) =>
        //             data?.info?.GoalDetails?.GoalTitle === goalTitle &&
        //             data?.info?.StudentName === studentName
        //         )
        //          .filter((goal) => {
        //           const isSharingEnabled =
        //             goal.info.ShareWithTeam?.IsSharingEnabled;
        //           const hasAccess =
        //             goal.info.ShareWithTeam?.EditAccess?.includes(
        //               user?.subRoleName
        //             ) ||
        //             goal.info.ShareWithTeam?.ViewOnlyAccess?.includes(
        //               user?.subRoleName
        //             );
        //           return isSharingEnabled && hasAccess;
        //         })
        //         .map((goal) => ({
        //           ...goal,
        //           studentName: studentName,
        //         }))
        //     :Array.isArray(student.dataCollection) ?
        //      student?.dataCollection
        //     .filter(
        //       (data) =>
        //         data?.info?.GoalDetails?.GoalTitle === goalTitle &&
        //         data?.info?.StudentName === studentName
        //     )
        //     .map((goal) => ({
        //       ...goal,
        //       studentName: studentName,
        //     })) : []
        // );

        filteredGoal = studentsGoalData.flatMap((student) =>
          Array.isArray(student.dataCollection)
            ? student.dataCollection
                .filter(
                  (data) =>
                    data?.info?.GoalDetails?.GoalTitle === goalTitle &&
                    data?.info?.StudentName === studentName
                )
                .map((goal) => ({
                  ...goal,
                  studentName: studentName,
                }))
            : []
        );

        //else

        // if (filteredGoal.length > 0) {
        //   filteredGoal.forEach((goal) => {
        //     const goalCreation = parseFloat(
        //       goal?.info?.DataCollection?.measurementType?.TargetDuration || 0
        //     );

        //     const measurementType =
        //       goal?.info?.DataCollection?.measurementType?.Type;

        //     const trialsMin =
        //       goal?.info?.DataCollection?.measurementType?.TrailsValue
        //         ?.TrailsMin || 0;

        //     console.log('measurementType', measurementType);
        //     console.log('trialsMin', trialsMin);

        //     if (measurementType === 'Trials') {
        //     } else {
        //     }

        //     const goalData = Array.isArray(goal?.goalData) ? goal.goalData : [];

        //     if (measurementType === 'Trials') {
        //     } else {
        //     }

        //     goalData.forEach((dataEntry) => {
        //       if (!dataEntry?.createdAt?._seconds) return; // Skip invalid data

        //       const createdAtDate = new Date(
        //         dataEntry.createdAt._seconds * 1000
        //       );
        //       const dateKey = createdAtDate.toLocaleDateString('en-GB', {
        //         day: '2-digit',
        //         month: '2-digit',
        //         year: '2-digit',
        //       });

        //       let dailyTotal = 0;
        //       if (Array.isArray(dataEntry.info?.data_points)) {
        //         dataEntry.info.data_points.forEach((point) => {
        //           dailyTotal += parseFloat(point.goal_value || 0);
        //         });
        //       }

        //       dateValueMap[dateKey] = (dateValueMap[dateKey] || 0) + dailyTotal;
        //       dateCountMap[dateKey] = (dateCountMap[dateKey] || 0) + 1;
        //     });
        //   });

        //   Object.keys(dateValueMap).forEach((dateKey) => {
        //     const avgValue = dateValueMap[dateKey] / dateCountMap[dateKey];
        //     let calculatedValue = 0;

        //     filteredGoal?.forEach((goal) => {
        //       const goalCreation = parseFloat(
        //         goal?.info?.DataCollection?.measurementType?.TargetDuration || 0
        //       );

        //       const targetType =
        //         goal?.info?.DataCollection?.measurementType?.Target ||
        //         'Atleast';

        //       if (targetType === 'Atleast') {
        //         calculatedValue = ((avgValue / goalCreation) * 100).toFixed(2);
        //       } else if (targetType === 'Atmost') {
        //         calculatedValue = (
        //           (1 - (avgValue - goalCreation) / Math.max(avgValue, 1)) *
        //           100
        //         ).toFixed(2);
        //       }

        //       processedChartData.push({
        //         goalTitle: dateKey,
        //         progress: parseFloat(calculatedValue),
        //         studentName: goal?.studentName,
        //         goalName: goal?.info?.GoalDetails?.GoalTitle,
        //       });
        //     });
        //   });
        // } else {
        //   console.warn('No matching goal data found.');
        // }

        if (filteredGoal.length > 0) {
          let dateValueMap = {}; // Stores total goal values per date
          let dateCountMap = {}; // Counts occurrences per date
          let trialsPerDay = {}; // Stores trials data per date

          filteredGoal.forEach((goal) => {
            const goalCreation = parseFloat(
              goal?.info?.DataCollection?.measurementType?.TargetDuration || 0
            );

            const measurementType =
              goal?.info?.DataCollection?.measurementType?.Type;
            const targetType =
              goal?.info?.DataCollection?.measurementType?.Target;

            const trialsMin =
              parseInt(
                goal?.info?.DataCollection?.measurementType?.TrailsValue
                  ?.TrailsMin,
                10
              ) || 0;

            const goalData = Array.isArray(goal?.goalData) ? goal.goalData : [];

            goalData.forEach((dataEntry) => {
              if (!dataEntry?.createdAt?._seconds) return;

              const createdAtDate = new Date(
                dataEntry.createdAt._seconds * 1000
              );
              const dateKey = createdAtDate.toLocaleDateString('en-GB', {
                day: '2-digit',
                month: '2-digit',
                year: '2-digit',
              });

              if (measurementType === 'Trials') {
                // Trials logic: Merge multiple `trialsNumber` arrays per date
                let mergedTrials = [];
                if (Array.isArray(dataEntry.info?.data_points)) {
                  dataEntry.info.data_points.forEach((point) => {
                    if (Array.isArray(point.trialsNumber)) {
                      mergedTrials = [...mergedTrials, ...point.trialsNumber];
                    }
                  });
                }

                const successfulTrials =
                  targetType === 'Atmost'
                    ? mergedTrials.filter((trial) => trial <= goalCreation)
                        .length
                    : mergedTrials.filter((trial) => trial >= goalCreation)
                        .length;

                if (!trialsPerDay[dateKey]) {
                  trialsPerDay[dateKey] = { successful: 0, total: 0 };
                }

                trialsPerDay[dateKey].successful += successfulTrials;
                trialsPerDay[dateKey].total += mergedTrials.length;
              } else {
                // Existing logic for Atmost & Atleast
                let dailyTotal = 0;
                if (Array.isArray(dataEntry.info?.data_points)) {
                  dataEntry.info.data_points.forEach((point) => {
                    dailyTotal += parseFloat(point.goal_value || 0);
                  });
                }

                dateValueMap[dateKey] =
                  (dateValueMap[dateKey] || 0) + dailyTotal;
                dateCountMap[dateKey] = (dateCountMap[dateKey] || 0) + 1;
              }
            });
          });

          // Process and store progress for each date (Atmost & Atleast)
          Object.keys(dateValueMap).forEach((dateKey) => {
            const avgValue = dateValueMap[dateKey] / dateCountMap[dateKey];
            let calculatedValue = 0;

            filteredGoal.forEach((goal) => {
              const goalCreation = parseFloat(
                goal?.info?.DataCollection?.measurementType?.TargetDuration || 0
              );

              const targetType =
                goal?.info?.DataCollection?.measurementType?.Target ||
                'Atleast';

              if (targetType === 'Atleast') {
                calculatedValue = ((avgValue / goalCreation) * 100).toFixed(2);
              } else if (targetType === 'Atmost') {
                calculatedValue = (
                  (1 - (avgValue - goalCreation) / Math.max(avgValue, 1)) *
                  100
                ).toFixed(2);
              }

              processedChartData.push({
                goalTitle: dateKey,
                progress: parseFloat(calculatedValue),
                studentName: goal?.studentName,
                goalName: goal?.info?.GoalDetails?.GoalTitle,
              });
            });
          });

          // Process trials data for each date
          Object.keys(trialsPerDay).forEach((dateKey) => {
            const successful = trialsPerDay[dateKey]?.successful || 0;

            filteredGoal?.forEach((goal) => {
              const trialsMin =
                parseInt(
                  goal?.info?.DataCollection?.measurementType?.TrailsValue
                    ?.TrailsMin,
                  10
                ) || 0;

              let progress = trialsMin
                ? ((successful / trialsMin) * 100).toFixed(2)
                : 'NA';

              processedChartData.push({
                goalTitle: dateKey,
                progress: parseFloat(progress),
                studentName: goal?.studentName,
                goalName: goal?.info?.GoalDetails?.GoalTitle,
              });
            });
          });
        } else {
          console.warn('No matching goal data found.');
        }
      }

      setChartDatas(processedChartData);
    } else {
      let filteredGoal = [];
      let dateCountMap = {};
      let dateValueMap = {};
      let processedChartData = [];

      if (selectedValue === 'All Progress') {
        filteredGoal = studentsGoalData.flatMap((student) => {
          return {
            info: student?.studentData,
            id: student?.docId,
            studentName: student?.studentData?.StudentName,
            createdAt: student?.studentData?.createdAt,
            isEncrypted: student?.studentData?.isEncrypted,
            goalData: student?.studentData?.goalData,
          };
        });

        let trialsPerDay = {}; // Stores trials data per date
        let dateProgressSum = {}; // Stores summed progress values
        let dateProgressCount = {};
        let progress;

        if (filteredGoal.length > 0) {
          // filteredGoal.forEach((goal) => {
          //   const goalCreation = parseFloat(
          //     goal?.info?.DataCollection?.measurementType?.TargetDuration || 0
          //   );

          //   const goalData = Array.isArray(goal?.goalData) ? goal.goalData : [];

          //   goalData.forEach((dataEntry) => {
          //     if (!dataEntry?.createdAt?._seconds) return; // Skip invalid data

          //     const createdAtDate = new Date(
          //       dataEntry.createdAt._seconds * 1000
          //     );
          //     const dateKey = createdAtDate.toLocaleDateString('en-GB', {
          //       day: '2-digit',
          //       month: '2-digit',
          //       year: '2-digit',
          //     });

          //     let dailyTotal = 0;
          //     if (Array.isArray(dataEntry.info?.data_points)) {
          //       dataEntry.info.data_points.forEach((point) => {
          //         dailyTotal += parseFloat(point.goal_value || 0);
          //       });
          //     }

          //     dateValueMap[dateKey] = (dateValueMap[dateKey] || 0) + dailyTotal;
          //     dateCountMap[dateKey] = (dateCountMap[dateKey] || 0) + 1;
          //   });
          // });

          // let dateProgressSum = {};
          // let dateProgressCount = {};

          // Object.keys(dateValueMap).forEach((dateKey) => {
          //   const avgValue =
          //     dateValueMap[dateKey] / (dateCountMap[dateKey] || 1); // Prevent division by 0
          //   let calculatedValue = 0;

          //   filteredGoal.forEach((goal) => {
          //     const goalCreation = parseFloat(
          //       goal?.info?.DataCollection?.measurementType?.TargetDuration || 1
          //     ); // Ensure a valid denominator

          //     const targetType =
          //       goal?.info?.DataCollection?.measurementType?.Target ||
          //       'Atleast';

          //     if (targetType === 'Atleast') {
          //       calculatedValue = goalCreation
          //         ? ((avgValue / goalCreation) * 100).toFixed(2)
          //         : 0;
          //     } else if (targetType === 'Atmost') {
          //       calculatedValue = goalCreation
          //         ? (
          //             (1 - (avgValue - goalCreation) / Math.max(avgValue, 1)) *
          //             100
          //           ).toFixed(2)
          //         : 0;
          //     }

          //     // Ensure calculatedValue is a valid number
          //     calculatedValue = isFinite(parseFloat(calculatedValue))
          //       ? parseFloat(calculatedValue)
          //       : 0;

          //     dateProgressSum[dateKey] =
          //       (dateProgressSum[dateKey] || 0) + calculatedValue;
          //     dateProgressCount[dateKey] =
          //       (dateProgressCount[dateKey] || 0) + 1;
          //   });
          // });

          // Object.keys(dateProgressSum).forEach((date) => {
          //   const finalProgress =
          //     dateProgressSum[date] / dateProgressCount[date] || 1;

          //   processedChartData.push({
          //     goalTitle: date,
          //     progress: isNaN(finalProgress)
          //       ? 0
          //       : parseFloat(finalProgress.toFixed(2)),
          //   });
          // });

          filteredGoal.forEach((goal) => {
            const goalCreation = parseFloat(
              goal?.info?.DataCollection?.measurementType?.TargetDuration || 0
            );

            const measurementType =
              goal?.info?.DataCollection?.measurementType?.Type;
            const targetType =
              goal?.info?.DataCollection?.measurementType?.Target;

            const trialsMin =
              parseInt(
                goal?.info?.DataCollection?.measurementType?.TrialsValue
                  ?.TrialsMin,
                10
              ) || 0;

            const goalData = Array.isArray(goal?.goalData) ? goal.goalData : [];

            goalData.forEach((dataEntry) => {
              if (!dataEntry?.createdAt?._seconds) return;

              const createdAtDate = new Date(
                dataEntry.createdAt._seconds * 1000
              );
              const dateKey = createdAtDate.toLocaleDateString('en-GB', {
                day: '2-digit',
                month: '2-digit',
                year: '2-digit',
              });

              if (measurementType === 'Trials') {
                // ✅ Trials logic: Merge multiple `trialsNumber` arrays per date
                let mergedTrials = [];
                if (Array.isArray(dataEntry.info?.data_points)) {
                  dataEntry.info.data_points.forEach((point) => {
                    if (Array.isArray(point.trialsNumber)) {
                      mergedTrials = [...mergedTrials, ...point.trialsNumber];
                    }
                  });
                }

                const successfulTrials =
                  targetType === 'Atmost'
                    ? mergedTrials.filter((trial) => trial <= goalCreation)
                        .length
                    : mergedTrials.filter((trial) => trial >= goalCreation)
                        .length;

                if (!trialsPerDay[dateKey]) {
                  trialsPerDay[dateKey] = { successful: 0, total: 0 };
                }

                trialsPerDay[dateKey].successful += successfulTrials;
                trialsPerDay[dateKey].total += mergedTrials.length;
              } else {
                // ✅ Existing logic for Atmost & Atleast
                let dailyTotal = 0;
                if (Array.isArray(dataEntry.info?.data_points)) {
                  dataEntry.info.data_points.forEach((point) => {
                    dailyTotal += parseFloat(point.goal_value || 0);
                  });
                }

                dateValueMap[dateKey] =
                  (dateValueMap[dateKey] || 0) + dailyTotal;
                dateCountMap[dateKey] = (dateCountMap[dateKey] || 0) + 1;
              }
            });
          });

          // ✅ Process and store progress for each date (Atmost & Atleast)
          Object.keys(dateValueMap).forEach((dateKey) => {
            const avgValue = dateValueMap[dateKey] / dateCountMap[dateKey] || 1;
            let calculatedValue = 0;
            // console.log('datekey: ', dateKey);

            // console.log('date value map: ', dateValueMap);

            filteredGoal?.forEach((goal) => {
              const goalCreation = parseFloat(
                goal?.info?.DataCollection?.measurementType?.TargetDuration || 0
              );

              const targetType =
                goal?.info?.DataCollection?.measurementType?.Target ||
                'Atleast';

              if (targetType === 'Atleast') {
                calculatedValue = ((avgValue / goalCreation) * 100).toFixed(2);
              } else if (targetType === 'Atmost') {
                calculatedValue = (
                  (1 - (avgValue - goalCreation) / Math.max(avgValue, 1)) *
                  100
                ).toFixed(2);
              }

              // Sum progress per date for averaging
              dateProgressSum[dateKey] =
                (dateProgressSum[dateKey] || 0) + parseFloat(calculatedValue);
              dateProgressCount[dateKey] =
                (dateProgressCount[dateKey] || 0) + 1;
            });
          });

          // ✅ Process trials data for each date
          Object.keys(trialsPerDay).forEach((dateKey) => {
            const successful = trialsPerDay[dateKey]?.successful || 0;

            filteredGoal?.forEach((goal) => {
              const trialsMin =
                parseInt(
                  goal?.info?.DataCollection?.measurementType?.TrailsValue
                    ?.TrailsMin,
                  10
                ) || 0;

              progress = trialsMin
                ? ((successful / trialsMin) * 100).toFixed(2)
                : 'NA';
            });

            // Sum trials-based progress per date for averaging
            if (progress !== 'NA') {
              dateProgressSum[dateKey] =
                (dateProgressSum[dateKey] || 0) + parseFloat(progress);
              dateProgressCount[dateKey] =
                (dateProgressCount[dateKey] || 0) + 1;
            }
          });

          // ✅ Final averaging for each date
          Object.keys(dateProgressSum).forEach((date) => {
            const finalProgress =
              dateProgressSum[date] / dateProgressCount[date] || 1;

            processedChartData.push({
              goalTitle: date,
              progress: isNaN(finalProgress)
                ? 0
                : parseFloat(finalProgress.toFixed(2)),
            });
          });
        } else {
          console.warn('No matching goal data found.');
        }
      } else {
        const [goalTitle, studentName] = selectedValue.split('__');

        filteredGoal = studentsGoalData.flatMap((student) => {
          const studentInfo = student?.studentData;

          // Ensure student data and goal details exist
          if (!studentInfo || !studentInfo.GoalDetails) return [];

          // Filter based on selected goalTitle and studentName
          if (
            studentInfo.GoalDetails.GoalTitle === goalTitle &&
            studentInfo.StudentName === studentName
          ) {
            return {
              id: student?.docId,
              studentName: studentInfo?.StudentName,
              createdAt: studentInfo?.createdAt,
              isEncrypted: studentInfo?.isEncrypted,
              goalData: studentInfo?.goalData,
              info: studentInfo, // Complete student data if needed
            };
          }

          return [];
        });

        // if (filteredGoal.length > 0) {
        //   filteredGoal.forEach((goal) => {
        //     const goalCreation = parseFloat(
        //       goal?.info?.DataCollection?.measurementType?.TargetDuration || 0
        //     );

        //     const goalData = Array.isArray(goal?.goalData) ? goal.goalData : [];

        //     goalData.forEach((dataEntry) => {
        //       if (!dataEntry?.createdAt?._seconds) return; // Skip invalid data

        //       const createdAtDate = new Date(
        //         dataEntry.createdAt._seconds * 1000
        //       );
        //       const dateKey = createdAtDate.toLocaleDateString('en-GB', {
        //         day: '2-digit',
        //         month: '2-digit',
        //         year: '2-digit',
        //       });

        //       let dailyTotal = 0;
        //       if (Array.isArray(dataEntry.info?.data_points)) {
        //         dataEntry.info.data_points.forEach((point) => {
        //           dailyTotal += parseFloat(point.goal_value || 0);
        //         });
        //       }

        //       dateValueMap[dateKey] = (dateValueMap[dateKey] || 0) + dailyTotal;
        //       dateCountMap[dateKey] = (dateCountMap[dateKey] || 0) + 1;
        //     });
        //   });

        //   Object.keys(dateValueMap).forEach((dateKey) => {
        //     const avgValue = dateValueMap[dateKey] / dateCountMap[dateKey];
        //     let calculatedValue = 0;

        //     filteredGoal?.forEach((goal) => {
        //       const goalCreation = parseFloat(
        //         goal?.info?.DataCollection?.measurementType?.TargetDuration || 0
        //       );

        //       const targetType =
        //         goal?.info?.DataCollection?.measurementType?.Target ||
        //         'Atleast';

        //       if (targetType === 'Atleast') {
        //         calculatedValue = ((avgValue / goalCreation) * 100).toFixed(2);
        //       } else if (targetType === 'Atmost') {
        //         calculatedValue = (
        //           (1 - (avgValue - goalCreation) / Math.max(avgValue, 1)) *
        //           100
        //         ).toFixed(2);
        //       }

        //       processedChartData.push({
        //         goalTitle: dateKey,
        //         progress: parseFloat(calculatedValue),
        //         studentName: goal?.studentName,
        //         goalName: goal?.info?.GoalDetails?.GoalTitle,
        //       });

        //       // setChartDatas(processedChartData);
        //     });
        //   });
        // } else {
        //   console.warn('No matching goal data found.');
        // }

        if (filteredGoal.length > 0) {
          let dateValueMap = {}; // Stores total goal values per date
          let dateCountMap = {}; // Counts occurrences per date
          let trialsPerDay = {}; // Stores trials data per date

          filteredGoal.forEach((goal) => {
            const goalCreation = parseFloat(
              goal?.info?.DataCollection?.measurementType?.TargetDuration || 0
            );

            const measurementType =
              goal?.info?.DataCollection?.measurementType?.Type;
            const targetType =
              goal?.info?.DataCollection?.measurementType?.Target;

            const trialsMin =
              parseInt(
                goal?.info?.DataCollection?.measurementType?.TrailsValue
                  ?.TrailsMin,
                10
              ) || 0;

            console.log('trialsMin', trialsMin);

            const goalData = Array.isArray(goal?.goalData) ? goal.goalData : [];

            goalData.forEach((dataEntry) => {
              if (!dataEntry?.createdAt?._seconds) return;

              const createdAtDate = new Date(
                dataEntry.createdAt._seconds * 1000
              );
              const dateKey = createdAtDate.toLocaleDateString('en-GB', {
                day: '2-digit',
                month: '2-digit',
                year: '2-digit',
              });

              if (measurementType === 'Trials') {
                // Trials logic: Merge multiple `trialsNumber` arrays per date
                let mergedTrials = [];
                if (Array.isArray(dataEntry.info?.data_points)) {
                  dataEntry.info.data_points.forEach((point) => {
                    if (Array.isArray(point.trialsNumber)) {
                      mergedTrials = [...mergedTrials, ...point.trialsNumber];
                    }
                  });
                }

                const successfulTrials =
                  targetType === 'Atmost'
                    ? mergedTrials.filter((trial) => trial <= goalCreation)
                        .length
                    : mergedTrials.filter((trial) => trial >= goalCreation)
                        .length;

                if (!trialsPerDay[dateKey]) {
                  trialsPerDay[dateKey] = { successful: 0, total: 0 };
                }

                trialsPerDay[dateKey].successful += successfulTrials;
                trialsPerDay[dateKey].total += mergedTrials.length;
              } else {
                //Existing logic for Atmost & Atleast
                let dailyTotal = 0;
                if (Array.isArray(dataEntry.info?.data_points)) {
                  dataEntry.info.data_points.forEach((point) => {
                    dailyTotal += parseFloat(point.goal_value || 0);
                  });
                }

                dateValueMap[dateKey] =
                  (dateValueMap[dateKey] || 0) + dailyTotal;
                dateCountMap[dateKey] = (dateCountMap[dateKey] || 0) + 1;
              }
            });
          });

          //Process and store progress for each date
          Object.keys(dateValueMap).forEach((dateKey) => {
            const avgValue = dateValueMap[dateKey] / dateCountMap[dateKey];
            let calculatedValue = 0;

            filteredGoal?.forEach((goal) => {
              const goalCreation = parseFloat(
                goal?.info?.DataCollection?.measurementType?.TargetDuration || 0
              );

              const targetType =
                goal?.info?.DataCollection?.measurementType?.Target ||
                'Atleast';

              if (targetType === 'Atleast') {
                calculatedValue = ((avgValue / goalCreation) * 100).toFixed(2);
              } else if (targetType === 'Atmost') {
                calculatedValue = (
                  (1 - (avgValue - goalCreation) / Math.max(avgValue, 1)) *
                  100
                ).toFixed(2);
              }

              processedChartData.push({
                goalTitle: dateKey,
                progress: parseFloat(calculatedValue),
                studentName: goal?.studentName,
                goalName: goal?.info?.GoalDetails?.GoalTitle,
              });
            });
          });

          // ✅ Process trials data for each date
          Object.keys(trialsPerDay).forEach((dateKey) => {
            const successful = trialsPerDay[dateKey]?.successful || 0;

            filteredGoal?.forEach((goal) => {
              const trialsMin =
                parseInt(
                  goal?.info?.DataCollection?.measurementType?.TrailsValue
                    ?.TrailsMin,
                  10
                ) || 0;

              let progress = trialsMin
                ? ((successful / trialsMin) * 100).toFixed(2)
                : 'NA';

              processedChartData.push({
                goalTitle: dateKey,
                progress: parseFloat(progress),
                studentName: goal?.studentName,
                goalName: goal?.info?.GoalDetails?.GoalTitle,
              });
            });
          });
        } else {
          console.warn('No matching goal data found.');
        }
      }

      setChartDatas(processedChartData);
    }
  };

  const chartData = filteredGoals.map((goal) => {
    // Extract numerical value from 'progress' and handle NaN cases
    const progressValue = parseFloat(goal?.progress?.replace('%', ''));
    return {
      goalTitle: goal?.GoalTitle,
      progress: isNaN(progressValue) ? 0 : progressValue, // Default to 0 if NaN
      studentName: goal?.studentName,
    };
  });

  const filterOutArchivedGoals = (goals) => {
    // Ensure goals is defined before filtering
    return Array.isArray(goals)
      ? goals.filter((goal) => goal.isArchived !== true)
      : [];
  };

  // const totakActiveGoals = studentsGoalData
  // ?.map((student) => {
  //   if (Array.isArray(student.dataCollection)) {
  //     // Filtered goals based on isArchived and role-based access
  //     const filteredGoals = student.dataCollection.filter((goal) => {
  //       const isGoalArchived =
  //         selectedGoal === 'Archived Goals' ? goal.isArchived : !goal.isArchived;
  //       const isSharingEnabled = goal.info.ShareWithTeam?.IsSharingEnabled;
  //       const hasAccess =
  //         goal.info.ShareWithTeam?.EditAccess?.includes(user?.subRoleName) ||
  //         goal.info.ShareWithTeam?.ViewOnlyAccess?.includes(user?.subRoleName);
  //       return isGoalArchived && isSharingEnabled && hasAccess;
  //     });

  //     console.log('filtered goals****', filteredGoals)

  //     // Non-filtered (normal) goals: No filtering, count all goals
  //     const normalGoals = student.dataCollection.filter((goal) => selectedGoal === 'Archived Goals'? goal.isArchived : !goal.isArchived);

  //     console.log('normal goals', normalGoals)
  //     const totalGoals =( filteredGoals.length + normalGoals.length)
  //     console.log('Totallllllllllll', totalGoals)

  //     return filteredGoals.length + normalGoals.length;
  //   } else {
  //     return 0; // If no dataCollection or it's not an array, return 0
  //   }
  // })
  // .reduce((sum, count) => sum + count, 0);

  const totakActiveGoals = studentsGoalData
    ?.map((student) => {
      if (Array.isArray(student.dataCollection)) {
        // Filtered goals based on isArchived and role-based access
        const filteredGoals = student.dataCollection.filter((goal) => {
          const isGoalArchived =
            selectedGoal === 'Archived Goals'
              ? goal.isArchived
              : !goal.isArchived;
          const isSharingEnabled = goal.info.ShareWithTeam?.IsSharingEnabled;
          const hasAccess =
            goal.info.ShareWithTeam?.EditAccess?.includes(user?.subRoleName) ||
            goal.info.ShareWithTeam?.ViewOnlyAccess?.includes(
              user?.subRoleName
            );
          return isGoalArchived && isSharingEnabled && hasAccess;
        });

        // Non-filtered (normal) goals: Goals that are not archived and don't have role-based filtering applied
        const normalGoals = student.dataCollection.filter((goal) => {
          const isGoalArchived =
            selectedGoal === 'Archived Goals'
              ? goal.isArchived
              : !goal.isArchived;
          const isSharingEnabled = goal.info.ShareWithTeam?.IsSharingEnabled;
          return isGoalArchived && isSharingEnabled;
        });

        // Filter out the goals that were already counted as filtered goals (to avoid duplicates)
        const normalGoalsCount = normalGoals?.filter((goal) => {
          const isGoalArchived =
            selectedGoal === 'Archived Goals'
              ? goal.isArchived
              : !goal.isArchived;
          const isSharingEnabled = goal.info.ShareWithTeam?.IsSharingEnabled;
          const hasAccess =
            goal.info.ShareWithTeam?.EditAccess?.includes(user?.subRoleName) ||
            goal.info.ShareWithTeam?.ViewOnlyAccess?.includes(
              user?.subRoleName
            );
          return !(isGoalArchived && isSharingEnabled && hasAccess); // Ensure it's not in filtered
        });

        // Return the count of filtered and non-overlapping normal goals
        return filteredGoals?.length + normalGoalsCount?.length;
      } else {
        return 0;
      }
    })
    .reduce((sum, count) => sum + count, 0);

  const totalStudents = studentsGoalData?.length;

  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000); // Convert seconds to milliseconds
    return date.toLocaleDateString('en-GB').replace(/\//g, '/'); // Format as DD/MM/YY
  };

  // const totalGoalDataCount = studentsGoalData
  //   ?.flatMap((student) =>
  //     Array.isArray(student?.dataCollection)
  //       ? student.dataCollection.filter((goal) =>
  //           selectedGoal === 'Archived Goals'
  //             ? goal.isArchived
  //             : !goal.isArchived
  //         )
  //       : []
  //   )
  //   .map((data) => (Array.isArray(data.goalData) ? data.goalData.length : 0))
  //   .reduce((sum, count) => sum + count, 0);

  const goalDataCount = studentsGoalData
    ?.flatMap((student) =>
      Array.isArray(student?.dataCollection)
        ? student.dataCollection.filter((goal) => {
            const isGoalArchived =
              selectedGoal === 'Archived Goals'
                ? goal.isArchived
                : !goal.isArchived;
            const isSharingEnabled =
              goal?.info?.ShareWithTeam?.IsSharingEnabled;
            const hasAccess =
              goal?.info?.ShareWithTeam?.EditAccess?.includes(
                user?.subRoleName
              ) ||
              goal?.info?.ShareWithTeam?.ViewOnlyAccess?.includes(
                user?.subRoleName
              );
            return isGoalArchived && isSharingEnabled && hasAccess;
          })
        : []
    )
    .map((data) => (Array.isArray(data.goalData) ? data.goalData.length : 0))
    .reduce((sum, count) => sum + count, 0);

  const goalDataCountWithoutRoles = studentsGoalData
    ?.flatMap((student) =>
      Array.isArray(student?.dataCollection)
        ? student.dataCollection.filter((goal) => {
            const isGoalArchived =
              selectedGoal === 'Archived Goals'
                ? goal.isArchived
                : !goal.isArchived;

            return isGoalArchived;
          })
        : []
    )
    .map((data) => (Array.isArray(data.goalData) ? data.goalData.length : 0))
    .reduce((sum, count) => sum + count, 0);

  const totalGoalDataCount = goalDataCount + goalDataCountWithoutRoles;

  const totalGoalDataCountForPatientAndStudent = (studentsGoalData || [])
  .flatMap((student) => {
    console.log('student', student); 
    return student?.studentData?.goalData || []; // Make sure to return an empty array if `goalData` is missing
  })
  .map((data) => (Array.isArray(data?.info?.data_points) ? data?.info?.data_points.length : 0)) // Count data points for each goalData
  .reduce((sum, count) => sum + count, 0); // Sum up the total counts

  // const totalGoalCountPatientandStudent = studentsGoalData?.map((student) => {
  //   return student.docId;
  // });

  const totalGoalCountPatientandStudent = studentsGoalData?.length || 0;

  // const totalGoalDataCount = studentsGoalData
  //   ?.flatMap((student) => filterOutArchivedGoals(student.dataCollection) || []) // Filter archived goals
  //   .map((data) => (Array.isArray(data.goalData) ? data.goalData.length : 0))
  //   .reduce((sum, count) => sum + count, 0);

  // const totalGoalDataCountForPatientAndStudent = studentsGoalData
  //   ?.flatMap((student) => filterOutArchivedGoals(student.studentData) || []) // Filter archived goals
  //   .map((data) => (Array.isArray(data?.goalData) ? data?.goalData.length : 0))
  //   .reduce((sum, count) => sum + count, 0);

  // const frequencyCount =
  //   studentsGoalData
  //     ?.flatMap((student) =>
  //       filterOutArchivedGoals(student.dataCollection || [])
  //     ) // Filter archived goals
  //     .filter(
  //       (data) =>
  //         data.info?.DataCollection?.measurementType?.Type === 'Frequency'
  //     ).length || 0;

  const frequencyCount =
    studentsGoalData
      ?.flatMap((student) =>
        filterOutArchivedGoals(student.dataCollection || [])
      ) // Filter archived goals
      .filter((data) => {
        const isSharingEnabled = data?.info?.ShareWithTeam?.IsSharingEnabled;
        const hasAccess =
          data?.info?.ShareWithTeam?.EditAccess?.includes(user?.subRoleName) ||
          data?.info?.ShareWithTeam?.ViewOnlyAccess?.includes(
            user?.subRoleName
          );

        const isFrequencyType =
          data.info?.DataCollection?.measurementType?.Type === 'Frequency';

        return isSharingEnabled && hasAccess && isFrequencyType;
      }).length || 0;

  const frequencyCountForStudentAndPatient =
    studentsGoalData
      ?.flatMap((student) => student.studentData || []) // Filter archived goals
      .filter((data) => {
        return data?.DataCollection?.measurementType?.Type === 'Frequency';
      }).length || 0;

  const trialsCountForStudentAndPatient =
    studentsGoalData
      ?.flatMap((student) => student.studentData || []) // Filter archived goals
      .filter((data) => {
        return data?.DataCollection?.measurementType?.Type === 'Trials';
      }).length || 0;

  const duartionCountForStudentAndPatient =
    studentsGoalData
      ?.flatMap((student) => student.studentData || []) // Filter archived goals
      .filter((data) => {
        return data?.DataCollection?.measurementType?.Type === 'Duration';
      }).length || 0;

  const percentageCountForStudentAndPatient =
    studentsGoalData
      ?.flatMap((student) => student.studentData || []) // Filter archived goals
      .filter((data) => {
        return data?.DataCollection?.measurementType?.Type === 'Percentage';
      }).length || 0;

  // const durationCount =
  //   studentsGoalData
  //     ?.flatMap((student) =>
  //       filterOutArchivedGoals(student.dataCollection || [])
  //     ) // Filter archived goals
  //     .filter(
  //       (data) =>
  //         data.info?.DataCollection?.measurementType?.Type === 'Duration'
  //     ).length || 0;

  const durationCount =
    studentsGoalData
      ?.flatMap((student) =>
        filterOutArchivedGoals(student.dataCollection || [])
      ) // Filter archived goals
      .filter((data) => {
        const isSharingEnabled = data?.info?.ShareWithTeam?.IsSharingEnabled;
        const hasAccess =
          data?.info?.ShareWithTeam?.EditAccess?.includes(user?.subRoleName) ||
          data?.info?.ShareWithTeam?.ViewOnlyAccess?.includes(
            user?.subRoleName
          );

        const isDurationType =
          data.info?.DataCollection?.measurementType?.Type === 'Duration';

        return isSharingEnabled && hasAccess && isDurationType;
      }).length || 0;

  const durationForWithOutRoleBase =
    studentsGoalData
      ?.flatMap((student) =>
        filterOutArchivedGoals(student.dataCollection || [])
      ) // Filter archived goals
      .filter((data) => {
        const isDurationType =
          data.info?.DataCollection?.measurementType?.Type === 'Duration';

        return isDurationType;
      }).length || 0;

  const totalCountOfDuration = durationCount + durationForWithOutRoleBase;

  // const percentageCount =
  //   studentsGoalData
  //     ?.flatMap((student) =>
  //       filterOutArchivedGoals(student.dataCollection || [])
  //     ) // Filter archived goals
  //     .filter(
  //       (data) =>
  //         data.info?.DataCollection?.measurementType?.Type === 'Percentage'
  //     ).length || 0;

  const percentageCount =
    studentsGoalData
      ?.flatMap((student) =>
        filterOutArchivedGoals(student.dataCollection || [])
      ) // Filter archived goals
      .filter((data) => {
        const isSharingEnabled = data?.info?.ShareWithTeam?.IsSharingEnabled;
        const hasAccess =
          data?.info?.ShareWithTeam?.EditAccess?.includes(user?.subRoleName) ||
          data?.info?.ShareWithTeam?.ViewOnlyAccess?.includes(
            user?.subRoleName
          );

        const isPercentageType =
          data.info?.DataCollection?.measurementType?.Type === 'Percentage';
        return isSharingEnabled && hasAccess && isPercentageType;
      }).length || 0;

  const percentageCountWithoutRoleBase =
    studentsGoalData
      ?.flatMap((student) =>
        filterOutArchivedGoals(student.dataCollection || [])
      ) // Filter archived goals
      .filter((data) => {
        const isPercentageType =
          data.info?.DataCollection?.measurementType?.Type === 'Percentage';
        return isPercentageType;
      }).length || 0;

  const totalPercentageCount = percentageCount + percentageCountWithoutRoleBase;

  // const trailsCount =
  //   studentsGoalData
  //     ?.flatMap((student) =>
  //       filterOutArchivedGoals(student.dataCollection || [])
  //     )
  //     .filter(
  //       (data) => data.info?.DataCollection?.measurementType?.Type === 'Trials'
  //     ).length || 0;

  const trailsCount =
    studentsGoalData
      ?.flatMap((student) =>
        filterOutArchivedGoals(student.dataCollection || [])
      ) // Filter archived goals
      .filter((data) => {
        const isSharingEnabled = data?.info?.ShareWithTeam?.IsSharingEnabled;
        const hasAccess =
          data?.info?.ShareWithTeam?.EditAccess?.includes(user?.subRoleName) ||
          data?.info?.ShareWithTeam?.ViewOnlyAccess?.includes(
            user?.subRoleName
          );

        const isTrialsType =
          data.info?.DataCollection?.measurementType?.Type === 'Trials';

        return isSharingEnabled && hasAccess && isTrialsType;
      }).length || 0;

  const trailsCountWithoutRoleBase =
    studentsGoalData
      ?.flatMap((student) =>
        filterOutArchivedGoals(student.dataCollection || [])
      ) // Filter archived goals
      .filter((data) => {
        const isTrialsType =
          data.info?.DataCollection?.measurementType?.Type === 'Trials';

        return isTrialsType;
      }).length || 0;

  const totalTrailsCount = trailsCount + trailsCountWithoutRoleBase;

  // const totalTargetDuration = studentsGoalData
  //   ?.flatMap((student) => filterOutArchivedGoals(student.dataCollection || []))
  //   .filter((data) => {
  //     data.info?.DataCollection?.measurementType?.Type === 'Duration';
  //     console.log('data', data);
  //   }) // Filter where Type is "Duration"
  //   .reduce(
  //     (sum, data) =>
  //       sum +
  //       Number(data.info.DataCollection.measurementType.TargetDuration || 0),
  //     0
  //   ); // Sum TargetDuration values

  // const totalTargetDuration = studentsGoalData
  //   ?.flatMap((student) => filterOutArchivedGoals(student.dataCollection || []))
  //   .filter((data) => {
  //     const isDurationType =
  //       data.info?.DataCollection?.measurementType?.Type === 'Duration';

  //     // Non-role-based filtering: Is the sharing enabled and duration type?
  //     return isDurationType;
  //   })
  //   .reduce(
  //     (sum, data) =>
  //       sum +
  //       Number(data.info.DataCollection.measurementType.TargetDuration || 0),
  //     0
  //   );

  const totalTargetDuration = studentsGoalData
    ?.map((student) => {
      if (Array.isArray(student.dataCollection)) {
        // Filtered goals based on isArchived and role-based access
        const filteredGoals = student.dataCollection.filter((goal) => {
          const isGoalArchived =
            selectedGoal === 'Archived Goals'
              ? goal.isArchived
              : !goal.isArchived;
          const isSharingEnabled = goal.info.ShareWithTeam?.IsSharingEnabled;
          const hasAccess =
            goal.info.ShareWithTeam?.EditAccess?.includes(user?.subRoleName) ||
            goal.info.ShareWithTeam?.ViewOnlyAccess?.includes(
              user?.subRoleName
            );

          return isGoalArchived && isSharingEnabled && hasAccess;
        });

        // Non-filtered (normal) goals: Goals that are not archived and don't have role-based filtering applied
        const normalGoals = student.dataCollection.filter((goal) => {
          const isGoalArchived =
            selectedGoal === 'Archived Goals'
              ? goal.isArchived
              : !goal.isArchived;
          const isSharingEnabled = goal.info.ShareWithTeam?.IsSharingEnabled;
          const isTrialsType =
            goal.info?.DataCollection?.measurementType?.Type === 'Trials';
          return isGoalArchived && isSharingEnabled && isTrialsType;
        });

        // Filter out the goals that were already counted as filtered goals (to avoid duplicates)
        const normalGoalsCount = normalGoals?.filter((goal) => {
          const isGoalArchived =
            selectedGoal === 'Archived Goals'
              ? goal.isArchived
              : !goal.isArchived;
          const isSharingEnabled = goal.info.ShareWithTeam?.IsSharingEnabled;
          const hasAccess =
            goal.info.ShareWithTeam?.EditAccess?.includes(user?.subRoleName) ||
            goal.info.ShareWithTeam?.ViewOnlyAccess?.includes(
              user?.subRoleName
            );
          const isTrialsType =
            goal.info?.DataCollection?.measurementType?.Type === 'Trials';
          return !(
            isGoalArchived &&
            isSharingEnabled &&
            hasAccess &&
            isTrialsType
          ); // Ensure it's not in filtered
        });

        // Return the count of filtered and non-overlapping normal goals
        return filteredGoals?.length + normalGoalsCount?.length;
      } else {
        return 0;
      }
    })
    .reduce((sum, count) => sum + count, 0);

  // // With role-based filtering
  // const totalTargetDurationWithRole = studentsGoalData
  //   ?.flatMap((student) => filterOutArchivedGoals(student.dataCollection || []))
  //   .filter((data) => {
  //     const isSharingEnabled = data?.info?.ShareWithTeam?.IsSharingEnabled;
  //     const hasAccess =
  //       data?.info?.ShareWithTeam?.EditAccess?.includes(user?.subRoleName) ||
  //       data?.info?.ShareWithTeam?.ViewOnlyAccess?.includes(user?.subRoleName);

  //     const isDurationType =
  //       data.info?.DataCollection?.measurementType?.Type === 'Duration';

  //     // Role-based filtering: Sharing enabled, has access, and duration type
  //     return isSharingEnabled && hasAccess && isDurationType;
  //   })
  //   .reduce(
  //     (sum, data) =>
  //       sum +
  //       Number(data.info.DataCollection.measurementType.TargetDuration || 0),
  //     0
  //   );

  // const totalCombinedTargetDuration =
  //   totalTargetDuration + totalTargetDurationWithRole;

  // const frequencyCountWithRole =
  //   studentsGoalData
  //     ?.flatMap((student) =>
  //       filterOutArchivedGoals(student.dataCollection || [])
  //     ) // Filter archived goals
  //     .filter((data) => {
  //       const isSharingEnabled = data?.info?.ShareWithTeam?.IsSharingEnabled;
  //       const hasAccess =
  //         data?.info?.ShareWithTeam?.EditAccess?.includes(user?.subRoleName) ||
  //         data?.info?.ShareWithTeam?.ViewOnlyAccess?.includes(
  //           user?.subRoleName
  //         );

  //       const isFrequencyType =
  //         data.info?.DataCollection?.measurementType?.Type === 'Frequency';

  //       // Role-based filtering: Sharing enabled, has access, and frequency type
  //       return isSharingEnabled && hasAccess && isFrequencyType;
  //     }).length || 0;

  // const frequencyCountWithoutRole =
  //   studentsGoalData
  //     ?.flatMap((student) =>
  //       filterOutArchivedGoals(student.dataCollection || [])
  //     ) // Filter archived goals
  //     .filter((data) => {
  //       const isFrequencyType =
  //         data.info?.DataCollection?.measurementType?.Type === 'Frequency';

  //       // Non-role-based filtering: Sharing enabled and frequency type
  //       return isFrequencyType;
  //     }).length || 0;

  // const totalFrequencyCount =
  //   frequencyCountWithRole + frequencyCountWithoutRole;

  const totalFrequencyCount = studentsGoalData
    ?.map((student) => {
      if (Array.isArray(student.dataCollection)) {
        // Filtered goals based on isArchived and role-based access
        const filteredGoals = student.dataCollection.filter((goal) => {
          const isGoalArchived =
            selectedGoal === 'Archived Goals'
              ? goal.isArchived
              : !goal.isArchived;
          const isSharingEnabled = goal.info.ShareWithTeam?.IsSharingEnabled;
          const hasAccess =
            goal.info.ShareWithTeam?.EditAccess?.includes(user?.subRoleName) ||
            goal.info.ShareWithTeam?.ViewOnlyAccess?.includes(
              user?.subRoleName
            );

          return isGoalArchived && isSharingEnabled && hasAccess;
        });

        // Non-filtered (normal) goals: Goals that are not archived and don't have role-based filtering applied
        const normalGoals = student.dataCollection.filter((goal) => {
          const isGoalArchived =
            selectedGoal === 'Archived Goals'
              ? goal.isArchived
              : !goal.isArchived;
          const isSharingEnabled = goal.info.ShareWithTeam?.IsSharingEnabled;
          const isFrequencyType =
            goal.info?.DataCollection?.measurementType?.Type === 'Frequency';
          return isGoalArchived && isSharingEnabled && isFrequencyType;
        });

        // Filter out the goals that were already counted as filtered goals (to avoid duplicates)
        const normalGoalsCount = normalGoals?.filter((goal) => {
          const isGoalArchived =
            selectedGoal === 'Archived Goals'
              ? goal.isArchived
              : !goal.isArchived;
          const isSharingEnabled = goal.info.ShareWithTeam?.IsSharingEnabled;
          const hasAccess =
            goal.info.ShareWithTeam?.EditAccess?.includes(user?.subRoleName) ||
            goal.info.ShareWithTeam?.ViewOnlyAccess?.includes(
              user?.subRoleName
            );
          const isFrequencyType =
            goal.info?.DataCollection?.measurementType?.Type === 'Frequency';
          return !(
            isGoalArchived &&
            isSharingEnabled &&
            hasAccess &&
            isFrequencyType
          ); // Ensure it's not in filtered
        });

        console.log('normalGoalsCount', normalGoalsCount);

        // Return the count of filtered and non-overlapping normal goals
        return filteredGoals?.length + normalGoalsCount?.length;
      } else {
        return 0;
      }
    })
    .reduce((sum, count) => sum + count, 0);

  const totalTargetDurationForPatientAndStudent = studentsGoalData
    ?.flatMap((student) => student?.studentData || [])
    .filter(
      (data) => data?.DataCollection?.measurementType?.Type === 'Duration'
    )
    .reduce((acc, data) => {
      const targetDuration =
        data?.DataCollection?.measurementType?.TargetDuration;
      return acc + (targetDuration ? parseFloat(targetDuration) : 0);
    }, 0);

  // const studentsWithoutGoalData = studentsGoalData
  //   ?.map((student) =>
  //     Array.isArray(student.dataCollection) &&
  //     filterOutArchivedGoals(student.dataCollection).filter(
  //       (data) => data.goalData === 'No data available'
  //     ).length > 0
  //       ? 1
  //       : 0
  //   )
  //   .reduce((sum, count) => sum + count, 0);

  const studentsWithoutGoalData = studentsGoalData
    ?.map((student) =>
      Array.isArray(student.dataCollection) &&
      filterOutArchivedGoals(student.dataCollection).filter((data) => {
        const isSharingEnabled = data?.info?.ShareWithTeam?.IsSharingEnabled;
        const hasAccess =
          data?.info?.ShareWithTeam?.EditAccess?.includes(user?.subRoleName) ||
          data?.info?.ShareWithTeam?.ViewOnlyAccess?.includes(
            user?.subRoleName
          );

        const isNoDataAvailable = data.goalData === 'No data available';

        return isSharingEnabled && hasAccess && isNoDataAvailable;
      }).length > 0
        ? 1
        : 0
    )
    .reduce((sum, count) => sum + count, 0);

  const totalProgress = chartDatas
    ?.map((data) => data.progress) // Extract progress values
    .reduce((sum, progress) => sum + progress, 0); // Sum all progress values

  const averageProgress = Math.round(totalProgress / chartDatas.length);

  const goalDataByDateForStudentAndPatient = studentsGoalData
    ?.flatMap((student) => (student.studentData || [])) // Filter archived goals
    .flatMap((data) => (Array.isArray(data.goalData) ? data.goalData : []))
    .reduce((acc, goal) => {
      if (goal.createdAt?._seconds) {
        const date = formatDate(goal.createdAt._seconds);
        acc[date] = (acc[date] || 0) + 1; // Count goalData per date
      }
      return acc;
    }, {});

  const totalEntriesPS = Object.values(
    goalDataByDateForStudentAndPatient || {}
  ).reduce((sum, count) => sum + count, 0);

  const totalDaysPS = Object.keys(
    goalDataByDateForStudentAndPatient || {}
  ).length;
  const averageEntriesPerDayPS = Math.round(
    totalDaysPS > 0 ? totalEntriesPS / totalDaysPS : 0
  );

  const goalDataByDate = studentsGoalData
    ?.flatMap((student) => filterOutArchivedGoals(student.dataCollection || [])) // Filter archived goals
    .flatMap((data) => (Array.isArray(data.goalData) ? data.goalData : []))
    .reduce((acc, goal) => {
      if (goal.createdAt?._seconds) {
        const date = formatDate(goal.createdAt._seconds);
        acc[date] = (acc[date] || 0) + 1; // Count goalData per date
      }
      return acc;
    }, {});

  const totalEntries = Object.values(goalDataByDate || {}).reduce(
    (sum, count) => sum + count,
    0
  );
  const totalDays = Object.keys(goalDataByDate || {}).length;
  const averageEntriesPerDay = Math.round(
    totalDays > 0 ? totalEntries / totalDays : 0
  );

  const datas = [
    {
      title: 'Total Data Entries',
      description: `${
        totalGoalDataCount || totalGoalDataCountForPatientAndStudent
      } entries`,
    },
    { title: 'Overall Progress', description: `${averageProgress}% Progress` },
    {
      title: 'Data Collection rate',
      description: `${
        averageEntriesPerDay || averageEntriesPerDayPS
      } entries/day`,
    },
    {
      title: 'Total Data Entries',
      description: `${
        totalGoalDataCount || totalGoalDataCountForPatientAndStudent
      } entries`,
    },
  ];

  const goals = [
    {
      title: 'Total Number of Active Goals',
      subtitle: 'The total number of goals currently being tracked.',
      count: totakActiveGoals || totalGoalCountPatientandStudent,
    },
    {
      title: 'Total Number of Minutes Logged',
      subtitle: 'The total number of minutes logged for all goals.',
      count: totalTargetDuration || totalTargetDurationForPatientAndStudent,
    },
    ...(ACM[typeOfUser]?.isDoctor || ACM[typeOfUser]?.isTeacher
      ? [
          {
            title: `Total Number of ${
              ACM[typeOfUser]?.isDoctor ? 'Patients' : 'Students'
            }`,
            subtitle: 'The total number of students being tracked.',
            count: totalStudents,
          },
          {
            title: `Total Number of ${
              ACM[typeOfUser]?.isDoctor ? 'Patients' : 'Students'
            } with No Data Logged in a Week`,
            subtitle:
              'The number of students who have not logged any data in the past week.',
            count: studentsWithoutGoalData,
          },
        ]
      : []), // Only include these two items if the user is BOTH a Doctor and a Teacher
    {
      title: 'Total Number of Frequency Goals',
      subtitle: 'The total number of frequency goals currently being tracked.',
      count: totalFrequencyCount || frequencyCountForStudentAndPatient,
    },
    {
      title: 'Total Number of Duration Goals',
      subtitle: 'The total number of duration goals currently being tracked.',
      count: totalCountOfDuration || duartionCountForStudentAndPatient,
    },
    {
      title: 'Total Number of Percentage Goals',
      subtitle: 'The total number of percentage goals currently being tracked.',
      count: totalPercentageCount || percentageCountForStudentAndPatient,
    },
    {
      title: 'Total Number of Trials Goals',
      subtitle: 'The total number of trials goals currently being tracked.',
      count: totalTrailsCount || trialsCountForStudentAndPatient,
    },
  ];

  const formattedChartData = [...chartDatas]
    .map((data) => ({
      ...data,
      progress: parseFloat(data.progress.toFixed(2)), // ✅ Round to 2 decimal places
    }))
    .sort((a, b) => {
      // ✅ Convert DD/MM/YY format to Date object for correct sorting
      const [dayA, monthA, yearA] = a.goalTitle.split('/').map(Number);
      const [dayB, monthB, yearB] = b.goalTitle.split('/').map(Number);

      const dateA = new Date(`20${yearA}`, monthA - 1, dayA);
      const dateB = new Date(`20${yearB}`, monthB - 1, dayB);

      return dateA - dateB; // Sort in ascending order
    });

  const handleDelete = async (goal, index) => {
    const userID = goal.studentId;
    const goalID = goal.goalId;
    const collectionPath =
      ACM[typeOfUser]?.isDoctor || ACM[typeOfUser]?.isPatient
        ? 'patientsList'
        : 'studentsList';

    try {
      const goalDataCollectionRef = collection(
        FirestoreDB,
        collectionPath,
        userID,
        'dataCollection',
        goalID,
        'goalData'
      );

      const goalDataDocs = await getDocs(goalDataCollectionRef);
      const deletePromises = goalDataDocs.docs.map((docItem) =>
        deleteDoc(docItem.ref)
      );

      await Promise.all(deletePromises); // Wait for all related goalData to be deleted

      // Delete the main goal document
      await deleteDoc(
        doc(FirestoreDB, collectionPath, userID, 'dataCollection', goalID)
      );

      // Update UI by removing the deleted goal
      const updatedGoals = displayedGoals.filter((_, i) => i !== index);
      setDisplayedGoals(updatedGoals);

      console.log('Goal and related data deleted successfully');
    } catch (error) {
      console.error('Error deleting goal and related data:', error);
    }
    setOpenDialog(false);
    setGoalToDelete(null);
  };

  const confirmDeleteGoal = (goal, index) => {
    setGoalToDelete({ goal, index });
    setOpenDialog(true);
  };

  const handleArchive = async (goal, index) => {
    const collectionPath =
      ACM[typeOfUser]?.isDoctor || ACM[typeOfUser]?.isPatient
        ? 'patientsList'
        : 'studentsList';

    const goalDocRef = doc(
      FirestoreDB,
      collectionPath,
      goal.studentId,
      'dataCollection',
      goal.goalId
    );

    updateDoc(goalDocRef, { isArchived: true })
      .then(() => {
        // Update local UI state
        const updatedGoals = displayedGoals.filter(
          (goal, idx) => idx !== index
        );
        setDisplayedGoals(updatedGoals);
        console.log('Goal archived successfully');
      })
      .catch((error) => {
        console.error('Error archiving goal:', error);
      });
    await goalsRefetch();
  };

  const handleUnarchive = async (goal) => {
    const collectionPath =
      ACM[typeOfUser]?.isDoctor || ACM[typeOfUser]?.isPatient
        ? 'patientsList'
        : 'studentsList';

    const goalDocRef = doc(
      FirestoreDB,
      collectionPath,
      goal.studentId,
      'dataCollection',
      goal.goalId
    );

    updateDoc(goalDocRef, { isArchived: false })
      .then(() => {
        // Update local UI state
        const updatedGoals = displayedGoals.map((g) =>
          g.goalId === goal.goalId ? { ...g, isArchived: false } : g
        );
        setDisplayedGoals(updatedGoals);
        console.log('Goal unarchived successfully');
      })
      .catch((error) => {
        console.error('Error unarchiving goal:', error);
      });

    await goalsRefetch();
  };

  // const visibleGoals = displayedGoals.filter(
  //   (goal) =>
  //     (selectedGoal === 'All Goals' && goal.isArchived !== true) ||
  //     (selectedGoal === 'Archived Goals' && goal.isArchived === true) ||
  //     (selectedGoal !== 'All Goals' &&
  //       selectedGoal !== 'Archived Goals' &&
  //       !goal.isArchived)
  // );

  // const visibleGoals = displayedGoals.filter((goal) => {
  //   if (selectedGoal === 'All Goals') {
  //     return goal.isArchived !== true;
  //   } else if (selectedGoal === 'Archived Goals') {
  //     return goal.isArchived === true;
  //   } else {
  //     return goal.isArchived === false && goal.GoalCategory.includes(selectedGoal);
  //   }
  // });

  const visibleGoals = displayedGoals.filter((goal) => {
    if (selectedGoal === 'All Goals') {
      return !goal.isArchived;
    } else if (selectedGoal === 'Archived Goals') {
      return goal.isArchived;
    } else {
      return !goal.isArchived && goal.GoalCategory.includes(selectedGoal);
    }
  });

  const renderGoalRow = (goal, index) => {
    return (
      <TableRow key={index}>
        <TableCell
          align='left'
          className='mindly-table-cell'
          sx={{ borderBottom: '1px solid darkGray' }}
        >
          <Typography variant='body2' fontFamily='Lato'>
            {goal?.GoalTitle}
          </Typography>
        </TableCell>
        <TableCell
          align='left'
          className='mindly-table-cell'
          sx={{ borderBottom: '1px solid darkGray' }}
        >
          <Typography variant='body2' fontFamily='Lato'>
            {goal?.studentName}
          </Typography>
        </TableCell>
        <TableCell
          align='left'
          className='mindly-table-cell'
          sx={{ borderBottom: '1px solid darkGray' }}
        >
          <Typography variant='body2' fontFamily='Lato'>
            {goal?.step2}
          </Typography>
        </TableCell>
        <TableCell
          align='left'
          className='mindly-table-cell'
          sx={{ borderBottom: '1px solid darkGray' }}
        >
          <Typography variant='body2' fontFamily='Lato'>
            {goal?.step1}
          </Typography>
        </TableCell>
        <TableCell
          align='center'
          className='mindly-table-cell'
          sx={{ borderBottom: '1px solid darkGray' }}
        >
          <Typography
            variant='body2'
            fontFamily='Lato'
            fontWeight={700}
            backgroundColor='var(--clr-mindly-background)'
            sx={{ borderRadius: 10, p: 1 }}
          >
            {goal?.progress}
          </Typography>
        </TableCell>
        <TableCell
          align='right'
          className='mindly-table-cell'
          sx={{ borderBottom: '1px solid darkGray' }}
        >
          <More onClick={(event) => handleClick(event, index)} />
          <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
            <MenuItem
              onClick={() => {
                const hasAccessToView = displayedGoals[
                  selectedIndex
                ]?.dataCollection?.ShareWithTeam?.ViewOnlyAccess?.includes(
                  user?.subRoleName
                );
                if (hasAccessToView) {
                  navigate('/goalDetails', {
                    state: {
                      goalData: displayedGoals[selectedIndex]?.dataCollection,
                      goals: displayedGoals[selectedIndex],
                    },
                  });
                } else {
                  navigate('/goalDetails', {
                    state: {
                      goalData: displayedGoals[selectedIndex]?.dataCollection,
                      goals: displayedGoals[selectedIndex],
                    },
                  });
                }
              }}
              disabled={
                displayedGoals[selectedIndex]?.dataCollection?.ShareWithTeam
                  ?.IsSharingEnabled === 'true'
                  ? !displayedGoals[
                      selectedIndex
                    ]?.dataCollection?.ShareWithTeam?.ViewOnlyAccess?.includes(
                      user?.subRoleName
                    )
                  : false
              }
            >
              View
            </MenuItem>
            <MenuItem
              onClick={() => {
                if (
                  selectedIndex !== null &&
                  displayedGoals[
                    selectedIndex
                  ]?.dataCollection?.ShareWithTeam?.EditAccess?.includes(
                    user?.subRoleName
                  )
                ) {
                  handleEdit(displayedGoals[selectedIndex], selectedIndex);
                } else {
                  handleEdit(displayedGoals[selectedIndex], selectedIndex);
                }
                handleClose();
              }}
              disabled={
                displayedGoals[selectedIndex]?.dataCollection?.ShareWithTeam
                  ?.IsSharingEnabled === 'true'
                  ? !displayedGoals[
                      selectedIndex
                    ]?.dataCollection?.ShareWithTeam?.EditAccess?.includes(
                      user?.subRoleName
                    )
                  : false
              }
            >
              Edit
            </MenuItem>
            {selectedIndex !== null &&
            !displayedGoals[selectedIndex].isArchived ? (
              <MenuItem onClick={() => handleOpenArchiveDialog('archive')}>
                Archive
              </MenuItem>
            ) : (
              <MenuItem onClick={() => handleOpenArchiveDialog('unarchive')}>
                Unarchive
              </MenuItem>
            )}
            <MenuItem
              onClick={() => {
                confirmDeleteGoal(displayedGoals[selectedIndex], selectedIndex);
                handleClose();
              }}
              sx={{ color: 'red' }}
            >
              Delete
            </MenuItem>
          </Menu>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <MainLayout>
      <Box className='goalDashboard fullWidth' sx={{ flexGrow: 1 }}>
        <Grid container spacing={1} sx={{ height: 'calc(100vh - 100px)' }}>
          <Grid xs={12} sm={12} md={8} pl={2} pr={2}>
            <Box className='fullWidth'>
              <Typography
                variant='h6'
                gutterBottom
                fontFamily={'Lato'}
                fontWeight={700}
              >
                Goals Dashboard
              </Typography>
              <Box
                display='flex'
                gap={1}
                flexDirection={{ xs: 'column', sm: 'column', md: 'row' }}
                width={{ xs: '100%', sm: '60%', md: '100%' }}
                justifyContent={'space-between'}
              >
                <Box
                  display='flex'
                  gap={2}
                  flexDirection={{ xs: 'column', sm: 'row' }}
                  // flexGrow={1}
                  alignItems={'center'}
                >
                  {(ACM[typeOfUser]?.isDoctor ||
                    ACM[typeOfUser]?.isTeacher) && (
                    <>
                      <Typography
                        sx={{ minWidth: '105px' }}
                        fontFamily={'Lato'}
                      >
                        {ACM[typeOfUser]?.isDoctor
                          ? 'Select Patient'
                          : 'Select Student'}
                      </Typography>
                      <FormControl
                        sx={{
                          minWidth: { xs: '100%', sm: '200px', md: '200px' },
                        }}
                      >
                        {/* <InputLabel id="demo-simple-select-helper-label">
                  Select Student
                </InputLabel> */}
                        <Select
                          labelId='demo-simple-select-helper-label'
                          id='demo-simple-select-helper'
                          value={selectedStudent}
                          // label="Select Student"
                          onChange={handleSelectChange}
                          sx={{
                            border: '1px solid var(--clr-mindly-purple)',
                            height: '40px',
                            borderRadius: '10px',
                            boxShadow:
                              '0px 0px 0px 0px var(--clr-mindly-purple)',
                          }}
                          MenuProps={{
                            PaperProps: {
                              sx: {
                                maxHeight: 200,
                                '& .MuiMenuItem-root': {
                                  '&:hover': {
                                    backgroundColor: 'var(--clr-mindly-purple)', // Custom hover color
                                    color: '#fff', // Optional: Change text color on hover
                                  },
                                },
                              },
                            },
                          }}
                        >
                          <MenuItem value='All Students'>
                            <span>
                              {ACM[typeOfUser]?.isDoctor
                                ? 'All Patient'
                                : 'All Student'}
                            </span>
                          </MenuItem>
                          {studentsGoalData?.map((student) => (
                            <MenuItem key={student.id} value={student.id}>
                              {student.fullname}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </>
                  )}

                  <FormControl
                    sx={{ minWidth: { xs: '100%', sm: '200px', md: '200px' } }}
                  >
                    <Select
                      // label="Goal Category"
                      value={selectedGoal}
                      onChange={handleGoalChange} // Your goal selection handler
                      sx={{
                        border: '1px solid var(--clr-mindly-purple)',
                        height: '40px',
                        borderRadius: '10px',
                        boxShadow: '0px 0px 0px 0px var(--clr-mindly-purple)',
                      }}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            '& .MuiMenuItem-root': {
                              '&:hover': {
                                backgroundColor: 'var(--clr-mindly-purple)', // Custom hover color
                                color: '#fff', // Optional: Change text color on hover
                              },
                            },
                          },
                        },
                      }}
                    >
                      <MenuItem value='All Goals'>
                        <span>All Goals</span>
                      </MenuItem>
                      <MenuItem value='Archived Goals'>
                        <span>Archived Goals</span>
                      </MenuItem>
                      {goalCategories.length > 0 ? (
                        goalCategories.map((category, index) => (
                          <MenuItem key={index} value={category}>
                            {category}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem disabled>No goals available</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Box>
                <Box
                  className='fullWidth'
                  display='flex'
                  gap={2}
                  flexDirection={{ xs: 'column', sm: 'row' }}
                  alignItems={'center'}
                  justifyContent={'flex-end'}
                >
                  <Button
                    variant='contained'
                    sx={{
                      width: { xs: '100%', sm: '100%', md: '150px' },
                      fontFamily: 'Lato',
                    }}
                    onClick={handleDataEntry}
                  >
                    Enter Data
                  </Button>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid xs={12} sm={12} md={8} sx={{ height: '42vh' }} p={1}>
            <Box
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
              }}
            >
              <Box sx={{ height: '90%' }}>
                <TableContainer
                  className='fullHeight'
                  sx={{
                    scrollbarWidth: 'none',
                    borderRadius: 3,
                    border: '1px solid darkgray',
                    bgcolor: 'var(--clr-mindly-background)',
                  }}
                >
                  <Table stickyHeader minHeight={400}>
                    <TableHead
                      sx={{
                        backgroundColor:
                          'var(--clr-mindly-background)!important',
                        borderRadius: 12,
                      }}
                    >
                      <TableRow sx={{ borderRadius: 12 }}>
                        <TableCell align='left' className='mindly-table-cell'>
                          <Typography
                            variant='body2'
                            fontFamily={'Lato'}
                            fontWeight={700}
                          >
                            Goal Title
                          </Typography>
                        </TableCell>
                        <TableCell align='left' className='mindly-table-cell'>
                          <Typography
                            variant='body2'
                            fontFamily={'Lato'}
                            fontWeight={700}
                          >
                            {ACM[typeOfUser]?.isDoctor
                              ? 'Patient Name'
                              : 'Student Name'}
                          </Typography>
                        </TableCell>
                        <TableCell align='left' className='mindly-table-cell'>
                          <Typography
                            variant='body2'
                            fontFamily={'Lato'}
                            fontWeight={700}
                          >
                            {getFormattedDate(2)}
                          </Typography>
                        </TableCell>
                        <TableCell align='left' className='mindly-table-cell'>
                          <Typography
                            variant='body2'
                            fontFamily={'Lato'}
                            fontWeight={700}
                          >
                            {getFormattedDate(1)}
                          </Typography>
                        </TableCell>
                        <TableCell align='center' className='mindly-table-cell'>
                          <Typography
                            variant='body2'
                            fontFamily={'Lato'}
                            fontWeight={700}
                          >
                            Progress
                          </Typography>
                        </TableCell>

                        <TableCell align='right' className='mindly-table-cell'>
                          {''}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody
                      sx={{ backgroundColor: 'var(--clr-mindly-light-gray)' }}
                    >
                      {visibleGoals.length > 0 ? (
                        visibleGoals.map((goal, index) => {
                          if (
                            ACM[typeOfUser]?.isTeacher ||
                            ACM[typeOfUser]?.isDoctor
                          ) {
                            const shareWithTeam =
                              goal?.dataCollection?.ShareWithTeam;

                            if (
                              shareWithTeam?.IsSharingEnabled &&
                              (shareWithTeam?.EditAccess?.includes(
                                user?.subRoleName
                              ) ||
                                shareWithTeam?.ViewOnlyAccess?.includes(
                                  user?.subRoleName
                                ))
                            ) {
                              return renderGoalRow(goal, index);
                            } else {
                              return renderGoalRow(goal, index);
                            }
                            // If sharing settings don't meet criteria, don't render
                            return null;
                          } else {
                            // For non-teacher/doctor users
                            return renderGoalRow(goal, index);
                          }
                        })
                      ) : (
                        <TableRow>
                          <TableCell colSpan={6}>No goals available</TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              <Box
                className='flexBox'
                sx={{
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  gap: 5,
                }}
              >
                <Button
                  onClick={handleAddGoal}
                  variant='contained'
                  sx={{
                    backgroundColor: 'var(--clr-mindly-purple)',
                    '&:hover': {
                      background: 'var(--clr-mindly-purple-hover)',
                    },
                    color: 'var(--clr-theme-white)',
                    fontFamily: 'Lato',
                    textTransform: 'capitalize',
                    width: { xs: '8rem', sm: '8rem', md: '8rem' },
                    alignSelf: 'flex-end',
                  }}
                >
                  Add New Goal
                </Button>

                <Button
                  variant='contained'
                  sx={{
                    width: { xs: '7rem', sm: '7rem', md: '7rem' },
                    alignSelf: 'flex-end',
                  }}
                  onClick={() => navigate('/allgoals')}
                >
                  View All
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid xs={12} sm={12} md={4} sx={{ height: '42vh' }} p={1}>
            <Box
              className='flexBox flexColumn'
              gap={2}
              bgcolor={'var(--clr-mindly-background)'}
              p={2}
              sx={{ height: '100%', overflowY: 'auto' }}
              borderRadius={3}
            >
              <Box className='flexBox flexRow' sx={{ alignItems: 'center' }}>
                <Typography
                  variant='h6'
                  gutterBottom
                  fontFamily={'Lato'}
                  flexGrow={1}
                >
                  Summary Metrics
                </Typography>

                <Button variant='contained'>View All Metrics</Button>
              </Box>
              <Box
                className='flexBox flexColumn'
                gap={1}
                justifyContent='center'
              >
                {goals.map((item, index) => (
                  <Card
                    sx={{ borderRadius: 5, border: '1px solid darkGray' }}
                    key={index}
                  >
                    <CardHeader
                      sx={{ padding: '10px 16px' }}
                      avatar={
                        <ShowChart
                          sx={{
                            backgroundColor: 'var(--clr-mindly-purple)',
                            fontSize: '3rem',
                            p: 1,
                            color: 'white',
                            borderRadius: 1,
                          }}
                        />
                      }
                      action={
                        <Typography
                          sx={{ padding: '20px' }}
                          bgcolor={'var(--clr-theme-purple-20)'}
                          borderRadius={20}
                          fontSize={20}
                        >
                          {item.count}
                        </Typography>
                      }
                      title={
                        <Typography
                          variant={'body1'}
                          fontFamily={'Lato'}
                          fontWeight={700}
                        >
                          {item.title}
                        </Typography>
                      }
                      subheader={
                        <Typography variant='body2' fontFamily={'Lato'}>
                          {item.subtitle}
                        </Typography>
                      }
                    />
                  </Card>
                ))}
              </Box>
            </Box>
          </Grid>
          <Grid xs={12} sm={12} md={8} sx={{ height: '42vh' }} p={2}>
            <Box sx={{ height: '100%' }}>
              <PerformanceMetricsChart
                goalProgress={goalProgress}
                handleGoalProgress={handleGoaProgress}
                filteredGoals={filteredGoals}
                formattedChartData={formattedChartData}
                chartDatas={chartDatas}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4} sx={{ height: '40vh', p: 2 }}>
            <Box
              className='fullWidth data'
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Grid container spacing={2}>
                {datas.map((item, index) => (
                  <Grid item xs={12} sm={6} md={6} lg={6} key={index}>
                    <Card
                      sx={{
                        borderRadius: 3,
                        border: '1px solid darkGray',
                        backgroundColor: 'var(--clr-mindly-gray)',
                        width: {
                          xs: '100%',
                          sm: '100%',
                          md: '100%',
                          lg: '100%',
                          xl: '100%',
                        },
                        height: {
                          xs: '120px',
                          sm: '120px',
                          md: '120px',
                          lg: '120px',
                          xl: '120px',
                        },
                        mt: 2,
                        p: 1, // Reduced padding for a smaller card
                      }}
                    >
                      <CardContent>
                        <Typography
                          component='div'
                          color={'var(--clr-mindly-black)'}
                          fontFamily={'Lato'}
                          textAlign={'left'}
                          fontSize={{
                            xs: '0.7rem',
                            sm: '0.8rem',
                            md: '1rem',
                            lg: '0.8rem',
                            xl: '1rem',
                          }}
                          fontWeight={500}
                        >
                          {item.title}
                        </Typography>
                        <Typography
                          textAlign={'left'}
                          fontWeight={700}
                          fontFamily={'Lato'}
                          color={'var(--clr-mindly-black)'}
                          fontSize={{
                            xs: '1rem',
                            sm: '1.2rem',
                            md: '1.4rem',
                            lg: '1.2rem',
                            xl: '1.6rem',
                          }}
                        >
                          {item.description}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid>
        </Grid>

        <Dialog
          sx={{ zIndex: 9999 }}
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogContent>
            <Typography
              sx={{
                color: 'var(--clr-theme-gray)',
                fontFamily: 'Karla',
                fontWeight: 700,
                textAlign: 'center',
              }}
            >
              Are you sure you want to delete this goal and all related data?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              variant='outlined'
              onClick={() => setOpenDialog(false)}
              color='primary'
              sx={{
                color: 'var(--clr-theme-purple)',
                border: '1px solid var(--clr-theme-purple)',
              }}
            >
              Cancel
            </Button>
            <Button
              variant='contained'
              onClick={() => {
                if (goalToDelete) {
                  handleDelete(goalToDelete.goal, goalToDelete.index);
                }
              }}
              color='error'
              sx={{ backgroundColor: 'var(--clr-theme-purple)' }}
              autoFocus
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        {dataEntryDialogOpen && (
          <NewGoalDialog
            open={dataEntryDialogOpen}
            onClose={() => setDataEntryDialogOpen(false)}
          />
        )}
      </Box>

      <Dialog open={isArchiveDialogOpen} onClose={handleCloseArchiveDialog}>
        <DialogTitle fontWeight={700} fontFamily={'Lato'}>
          Confirm Action
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to {archiveAction} this goal and all related
            data?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseArchiveDialog}
            color='primary'
            sx={{
              textTransform: 'capitalize',
              color: 'var(--clr-theme-purple)',
              border: '1px solid var(--clr-theme-purple)',
              width: '20%',
            }}
          >
            Cancel
          </Button>
          <Button
            variant='outlined'
            onClick={handleConfirmArchiveAction}
            autoFocus
            sx={{
              textTransform: 'capitalize',
              backgroundColor: 'var(--clr-theme-purple)!important',
              color: '#fff',
              border: '1px solid var(--clr-mindly-purple-hover)',
              width: '20%',
            }}
          >
            {archiveAction === 'archive' ? 'Archive' : 'Unarchive'}
          </Button>
        </DialogActions>
      </Dialog>
    </MainLayout>
  );
};

export default ViewAllGoals;
