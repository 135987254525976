import { Typography, Grid, Box } from '@mui/material';

const GoalData = ({ goalData }) => {
  const categoriesFormat = (arr) => {
    if (!arr || !Array.isArray(arr)) return '';
    return arr.join(', ');
  };

  return (
    <Box sx={{ position: 'relative', padding: 2 }}>
      <Box
        sx={{
          position: 'absolute',
          top: 16,
          right: 16,
          backgroundColor: '#9291ef',
          padding: '8px 16px',
          borderRadius: 1,
          zIndex: 1,
        }}
      >
        <Typography sx={{ color: 'white', fontWeight: 500, fontSize: '1rem' }}>
          {goalData?.StudentName}
        </Typography>
      </Box>

      <Grid container spacing={3} sx={{ paddingRight: '120px' }}>
        <Grid item xs={12} sm={4}>
          <Typography
            variant='subtitle2'
            color='textSecondary'
            sx={{ display: 'block', mb: 0.5, fontSize: '0.9rem' }}
          >
            Category:
          </Typography>
          <Typography variant='body1' sx={{ fontSize: '1rem' }}>
            {categoriesFormat(goalData?.GoalDetails?.GoalCategory)}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Typography
            variant='subtitle2'
            color='textSecondary'
            sx={{ display: 'block', mb: 0.5, fontSize: '0.9rem' }}
          >
            Goal Type:
          </Typography>
          <Typography variant='body1' sx={{ fontSize: '1rem' }}>
            {goalData?.GoalDetails?.TypeOfGoal?.Type}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Typography
            variant='subtitle2'
            color='textSecondary'
            sx={{ display: 'block', mb: 0.5, fontSize: '0.9rem' }}
          >
            {goalData?.GoalDetails?.TypeOfGoal?.Type === 'Behavior'
              ? 'Behavior Type:'
              : 'Skill Type:'}
          </Typography>
          <Typography variant='body1' sx={{ fontSize: '1rem' }}>
            {goalData?.GoalDetails?.TypeOfGoal?.Type === 'Behavior'
              ? goalData?.GoalDetails?.TypeOfGoal?.BehaviorType
              : goalData?.GoalDetails?.TypeOfGoal?.SkillType}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Box sx={{ mb: 2, minHeight: '80px' }}>
            <Typography
              variant='subtitle2'
              color='textSecondary'
              sx={{ display: 'block', mb: 0.5, fontSize: '0.9rem' }}
            >
              {goalData?.GoalDetails?.TypeOfGoal?.Type === 'Behavior'
                ? 'Behavior:'
                : 'Skill:'}
            </Typography>
            <Typography variant='body1' sx={{ fontSize: '1rem' }}>
              {goalData?.GoalDetails?.TypeOfGoal?.Type === 'Behavior'
                ? goalData?.GoalDetails?.TypeOfGoal?.BehaviorName
                : goalData?.GoalDetails?.TypeOfGoal?.SkillName}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Box sx={{ mb: 2, minHeight: '80px' }}>
            <Typography
              variant='subtitle2'
              color='textSecondary'
              sx={{ display: 'block', mb: 0.5, fontSize: '0.9rem' }}
            >
              Definition:
            </Typography>
            <Typography variant='body1' sx={{ fontSize: '1rem' }}>
              {goalData?.GoalDetails?.TypeOfGoal?.Type === 'Behavior'
                ? goalData?.GoalDetails?.TypeOfGoal?.Definition
                : goalData?.GoalDetails?.TypeOfGoal?.SkillDefinition}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4}></Grid>

        {goalData?.GoalDetails?.TypeOfGoal?.Type === 'Behavior' ? (
          <>
            <Grid item xs={12} sm={4}>
              <Box sx={{ mb: 2, minHeight: '80px' }}>
                <Typography
                  variant='subtitle2'
                  color='textSecondary'
                  sx={{ display: 'block', mb: 0.5, fontSize: '0.9rem' }}
                >
                  Onset:
                </Typography>
                <Typography variant='body1' sx={{ fontSize: '1rem' }}>
                  {goalData?.GoalDetails?.TypeOfGoal?.Onset || 'Not specified'}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Box sx={{ mb: 2, minHeight: '80px' }}>
                <Typography
                  variant='subtitle2'
                  color='textSecondary'
                  sx={{ display: 'block', mb: 0.5, fontSize: '0.9rem' }}
                >
                  Offset:
                </Typography>
                <Typography variant='body1' sx={{ fontSize: '1rem' }}>
                  {goalData?.GoalDetails?.TypeOfGoal?.Offset || 'Not specified'}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}></Grid>
          </>
        ) : (
          <>
            <Grid item xs={12} sm={4}>
              <Box sx={{ mb: 2, minHeight: '80px' }}>
                <Typography
                  variant='subtitle2'
                  color='textSecondary'
                  sx={{ display: 'block', mb: 0.5, fontSize: '0.9rem' }}
                >
                  Skill Natural Cue:
                </Typography>
                <Typography variant='body1' sx={{ fontSize: '1rem' }}>
                  {goalData?.GoalDetails?.TypeOfGoal?.SkillNaturalCue ||
                    'Not specified'}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box sx={{ mb: 2, minHeight: '80px' }}>
                {/* Empty grid item to maintain 2-column layout */}
              </Box>
            </Grid>
          </>
        )}

        {/* Bottom section - always 3 columns for consistency */}
        <Grid item xs={12} sm={4}>
          <Box sx={{ mb: 2, minHeight: '80px' }}>
            <Typography
              variant='subtitle2'
              color='textSecondary'
              sx={{ display: 'block', mb: 0.5, fontSize: '0.9rem' }}
            >
              Goal:
            </Typography>
            <Typography variant='body1' sx={{ fontSize: '1rem' }}>
              {goalData?.DataCollection?.measurementType?.Target}{' '}
              {goalData?.DataCollection?.measurementType?.TargetDuration}{' '}
              {goalData?.DataCollection?.measurementType?.measurementUnit}
              {goalData?.DataCollection?.measurementType?.Type === 'Trials' &&
                ` in ${goalData?.DataCollection?.measurementType?.TrailsValue?.TrailsMin} 
                    out of ${goalData?.DataCollection?.measurementType?.TrailsValue?.TrailsMax} trials`}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Box sx={{ mb: 2, minHeight: '80px' }}>
            <Typography
              variant='subtitle2'
              color='textSecondary'
              sx={{ display: 'block', mb: 0.5, fontSize: '0.9rem' }}
            >
              Due Date:
            </Typography>
            <Typography variant='body1' sx={{ fontSize: '1rem' }}>
              {goalData?.DataCollection?.ByDate
                ? new Date(
                    goalData?.DataCollection?.ByDate
                  ).toLocaleDateString()
                : 'Not specified'}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Box sx={{ mb: 2, minHeight: '80px' }}>
            <Typography
              variant='subtitle2'
              color='textSecondary'
              sx={{ display: 'block', mb: 0.5, fontSize: '0.9rem' }}
            >
              Baseline:
            </Typography>
            <Typography variant='body1' sx={{ fontSize: '1rem' }}>
              {goalData?.DataCollection?.Baseline || 'Not specified'}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={12}>
          <Box sx={{ mb: 2 }}>
            <Typography
              variant='subtitle2'
              color='textSecondary'
              sx={{ display: 'block', mb: 0.5, fontSize: '0.9rem' }}
            >
              Notes:
            </Typography>
            <Typography variant='body1' sx={{ fontSize: '1rem' }}>
              {goalData?.DataCollection?.Notes || 'No notes'}
            </Typography>
          </Box>
        </Grid>

        {goalData?.Subgoal?.IsSubgoal === 'Yes' && (
          <Grid item xs={12}>
            <Box sx={{ mb: 2 }}>
              <Typography
                variant='subtitle2'
                color='textSecondary'
                sx={{ display: 'block', mb: 0.5, fontSize: '0.9rem' }}
              >
                Subgoal:
              </Typography>
              <Typography variant='body1' sx={{ fontSize: '1rem' }}>
                {goalData?.Subgoal?.LinkedGoal || 'Not linked'}
              </Typography>
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default GoalData;
