import React, { useContext } from "react";
import {
  Box,
  Drawer,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Avatar,
  Fab,
  Dialog,
  DialogContent,
  Button,
  Tooltip,
  tooltipClasses,
} from "@mui/material";

import Header from "../../components/Header";
import LeftPanel from "../../components/LeftPanel";
import AuthContext from "../../contexts/AuthContext";
import { UIContext } from "../../contexts/UIProvider";
import { styled } from "@mui/material/styles";
import "./MainLayout.css";
import VideoUpload from "../../components/VideoUpload";
import { Close, CloseRounded, Event } from "@mui/icons-material";
import QuickNotesModel from "../../components/QuickNotesModel";
import Reminders from "../../components/Reminders";
import EmailVerificationNote from "../../pages/EmailVerificationNote";
import FallbackScreen from "../../components/FallbackScreen";

import { ACMContext } from "../../contexts/ACMProvider";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontWeight: "bold",
    padding: "10px",
  },
}));

const MainLayout = ({ children, onclick }) => {
  const {
    user,
    setUser,
    typeOfUser,
    openRightPanel,
    setOpenRightPanel,
    panelContent,
    isNotificationPanel,
  } = useContext(AuthContext);
  const {
    bottomDrawer,
    setBottomDrawer,
    isDrawerOpen,
    setIsDrawerOpen,
    isDrawerExpanded,
    setIsDrawerExpanded,
    drawerVariant,
  } = useContext(UIContext);

  const { ACM } = useContext(ACMContext);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (!user) {
    return <FallbackScreen />;
  }
  const specificDate = new Date(Date.UTC(2024, 9, 20, 5, 34, 12));
  const formattedDate = specificDate.toUTCString();

  return (
    <div className="MainLayout">
      {Boolean(
        user?.emailVerified || user?.metadata?.creationTime < formattedDate
      ) ? (
        <>
          <LeftPanel
            userType={typeOfUser}
            drawerVariant={drawerVariant}
            isDrawerOpen={isDrawerOpen}
            setIsDrawerOpen={setIsDrawerOpen}
            isDrawerExpanded={isDrawerExpanded}
            setIsDrawerExpanded={setIsDrawerExpanded}
          />
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              overflow: "hidden",
              marginLeft: isDrawerExpanded ? "280px" : "70px",
              transition: "margin-left 0.2s ease",
            }}
          >
            <Header />
            <Box
              sx={{
                p: 2,
                flex: 1,
                overflowY: "auto",
                height: "calc(100vh - 64px)",
              }}
            >
              {children}
            </Box>
          </Box>
          {/* {typeOfUser !== "ADMIN" && ( */}
          {!ACM[typeOfUser]?.isAdmin && !ACM[typeOfUser]?.isSchoolAdmin && (
            <VideoUpload user={user} typeOfUser={typeOfUser} />
          )}
          <QuickNotesModel user={user} typeOfUser={typeOfUser} />
          {/* {typeOfUser === 'DOCTOR' && ( */}
          {/* {ACM[typeOfUser]?.notificationServices && (
            <BootstrapTooltip title="Set Reminders" placement="left">
              <Fab
                size="large"
                sx={{
                  position: "absolute",
                  bottom: "15%",
                  right: "2%",
                  backgroundColor: "var(--clr-theme-purple)!important",
                }}
                color="secondary"
                aria-label="edit"
                onClick={handleClickOpen}
              >
                <Event />
              </Fab>
            </BootstrapTooltip>
          )} */}
          <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            maxWidth="lg"
            className="mindly-reminders"
          >
            <AppBar
              sx={{
                position: "relative",
                backgroundColor: "var(--clr-mindly-background)",
                boxShadow: "none",
              }}
            >
              <Toolbar>
                <Typography
                  sx={{
                    flex: 1,
                    color: "black",
                    fontSize: "1.5rem",
                    fontFamily: "Lato",
                    textAlign: "center",
                  }}
                  variant="h6"
                  component="div"
                >
                  Reminders 
                </Typography>
                <Button autoFocus color="inherit" onClick={handleClose}>
                  <Close sx={{ color: "black" }} />
                </Button>
              </Toolbar>
            </AppBar>
            <DialogContent sx={{ width: "42vw", backgroundColor:'var(--clr-mindly-background)'}}>
              <Reminders />
            </DialogContent>
          </Dialog>
          <Drawer
            sx={{ zIndex: 1300 }}
            anchor="bottom"
            open={bottomDrawer.show}
            transitionDuration={250}
            PaperProps={{
              sx: {
                width: "100%",
                height: "100%",
                alignItems: "center",
              },
            }}
          >
            <AppBar sx={{ position: "sticky" }}>
              <Toolbar
                sx={{
                  color: "white",
                  backgroundColor: "var(--clr-theme-purple)",
                }}
              >
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => {
                    setBottomDrawer({
                      ...bottomDrawer,
                      patientName: "",
                      title: "",
                      show: false,
                      content: null,
                      patientPhoto: null,
                    });
                  }}
                  aria-label="close"
                >
                  <Close />
                </IconButton>
                <Typography
                  sx={{ ml: 2, flex: 1 }}
                  variant="h6"
                  component="div"
                >
                  {bottomDrawer?.title || "No Title"}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <Avatar
                    src={bottomDrawer?.patientPhoto || null}
                    variant="square"
                  />
                  <Typography>
                    {bottomDrawer?.patientName
                      ? `${bottomDrawer?.patientName
                          .split(/\s+/)
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() +
                              word.slice(1).toLowerCase()
                          )
                          .join(" ")}`
                      : "" || "-"}
                  </Typography>
                </Box>
              </Toolbar>
            </AppBar>
            <div className="fullHeight fullWidth" style={{ padding: "10px" }}>
              {bottomDrawer?.content}
            </div>
          </Drawer>
          <Drawer
            sx={{ zIndex: 1350 }}
            anchor="right"
            open={openRightPanel}
            transitionDuration={250}
            PaperProps={{
              sx: {
                width: `${isNotificationPanel ? "30%" : "60%"}`,
                display: "flex",
                padding: 1,
                borderTopLeftRadius: 10,
                borderBottomLeftRadius: 10,
              },
            }}
          >
            <Box sx={{ alignItems: "end", textAlign: "end" }}>
              <IconButton
                size="large"
                color="info"
                onClick={() => {
                  setOpenRightPanel(false);
                }}
              >
                <CloseRounded />
              </IconButton>
            </Box>
            <Box className="fullWidth fullHeight" sx={{ overflowY: "auto" }}>
              {panelContent}
            </Box>
          </Drawer>
        </>
      ) : (
        <Box>
          <EmailVerificationNote setUser={setUser} />
        </Box>
      )}
    </div>
  );
};

export default MainLayout;
