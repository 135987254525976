import { useContext, useMemo, useState } from 'react';
import {
  Button,
  Select,
  MenuItem,
  FormControl,
  Box,
  Typography,
  Avatar,
  Divider,
  Paper,
} from '@mui/material';
import { ACMContext } from '../../contexts/ACMProvider';
import useStudentsForOneTeacher from '../../hooks/useStudentsForOneTeacher';
import AuthContext from '../../contexts/AuthContext';
import usePatientsForDoctor from '../../hooks/usePatientsForDoctor';
import { useNavigate } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import useFetchPreRegisteredUserForTeacher from '../../hooks/useFetchPreRegisteredUserForTeacher';
import MainLayout from '../../hoc/MainLayout';
import goalsFromDocuments from './mockFromDocuments';
import goalsFromOtherUsers from './mockFromOtherUsers';

const PendingGoals = () => {
  const { ACM } = useContext(ACMContext);
  const { user, typeOfUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [documentsExpanded, setDocumentsExpanded] = useState(true);
  const [otherUsersExpanded, setOtherUsersExpanded] = useState(true);
  const [selectedStudent, setSelectedStudent] = useState('all');

  const {
    data: preRegisteredUsers = [],
    // isLoading: isLoading,
    error: errorStudentData,
    refetch,
  } = useFetchPreRegisteredUserForTeacher(user?.uid);

  const {
    data: fetchStudentsForOneTeacher = [],
    isLoading: isTeachersLoading,
    error: teachersError,
    refetch: refetchTeachers,
  } = useStudentsForOneTeacher(user?.uid, typeOfUser);

  const {
    data: patientData = [],
    isLoading,
    error: errorPatientData,
  } = usePatientsForDoctor(user?.uid);

  const enrolledStudents = useMemo(
    () => fetchStudentsForOneTeacher || [],
    [fetchStudentsForOneTeacher]
  );

  const combinedStudents = useMemo(() => {
    const enrolledStudentNames = enrolledStudents.map((student) => ({
      id: student.id,
      name: student.name,
    }));

    const preRegisteredUserNames = preRegisteredUsers.map((user) => ({
      id: user.id,
      name: user.name,
    }));

    return [...enrolledStudentNames, ...preRegisteredUserNames];
  }, [enrolledStudents, preRegisteredUsers]);

  const handleBack = () => {
    navigate(-1);
  };

  const handleViewGoal = (goalId) => {
    console.log(`Viewing goal with ID: ${goalId}`);
  };

  const getRandomPastelColor = (name) => {
    const hash = [...name].reduce((acc, char) => acc + char.charCodeAt(0), 0);
    const hue = hash % 360;
    return `hsl(${hue}, 70%, 80%)`;
  };
  console.log('patientData', patientData);

  return (
    <MainLayout>
      <Box sx={{ mb: 4 }}>
        <Typography variant='h4' component='h1' fontWeight='bold' gutterBottom>
          Pending Goals
        </Typography>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 4,
          }}
        >
          <Typography variant='body1' color='text.secondary'>
            These goals need to be approved by you to be added to the system.
          </Typography>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
            }}
          >
            {ACM[typeOfUser]?.isTeacher ? (
              <Typography variant='body2'>Select Student</Typography>
            ) : (
              <Typography variant='body2'>Select Patient</Typography>
            )}
            <FormControl size='small' sx={{ minWidth: 180 }}>
              <Select
                value={selectedStudent}
                onChange={(e) => setSelectedStudent(e.target.value)}
                displayEmpty
                sx={{ bgcolor: 'white' }}
              >
                {ACM[typeOfUser]?.isTeacher && (
                  <>
                    <MenuItem value='all'>All Students</MenuItem>
                    {combinedStudents.map((student) => (
                      <MenuItem
                        key={student.id}
                        value={student.id}
                        onClick={() => setSelectedStudent(student.id)}
                      >
                        {student.name}
                      </MenuItem>
                    ))}
                  </>
                )}
                {ACM[typeOfUser]?.isDoctor && (
                  <>
                    <MenuItem value='all'>All Patients</MenuItem>
                    {patientData.map((patient) => (
                      <MenuItem
                        key={patient.patientId}
                        value={patient.patientId}
                        onClick={() => setSelectedStudent(patient.patientId)}
                      >
                        {patient?.patientInfo?.fullname ||
                          patient?.patientInfo?.name}
                      </MenuItem>
                    ))}
                  </>
                )}
              </Select>
            </FormControl>
            <Button
              variant='contained'
              onClick={handleBack}
              sx={{
                bgcolor: '#9291ef',
                '&:hover': { bgcolor: '#818cf8' },
                textTransform: 'none',
                minWidth: 100,
              }}
            >
              Back
            </Button>
          </Box>
        </Box>
      </Box>

      <Paper
        sx={{ mb: 3, overflow: 'hidden', borderRadius: 1 }}
        elevation={0}
        variant='outlined'
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            p: 2,
            bgcolor: '#e9eaff',
            borderBottom: documentsExpanded ? '1px solid #e2e8f0' : 'none',
            cursor: 'pointer',
          }}
          onClick={() => setDocumentsExpanded(!documentsExpanded)}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant='body1' component='span' sx={{ mr: 1 }}>
              ≡
            </Typography>
            <Typography variant='subtitle1' fontWeight='medium'>
              From Documents
            </Typography>
          </Box>
          {documentsExpanded ? (
            <KeyboardArrowUpIcon />
          ) : (
            <KeyboardArrowDownIcon />
          )}
        </Box>

        {documentsExpanded && (
          <Box>
            {goalsFromDocuments.map((goal, index) => (
              <Box key={goal.id}>
                <Box sx={{ p: 3, display: 'flex' }}>
                  <Box
                    sx={{
                      mr: 3,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <Avatar
                      src={goal.avatar || undefined}
                      alt={goal.studentName}
                      sx={{
                        width: 64,
                        height: 64,
                        bgcolor: goal.avatar
                          ? 'transparent'
                          : getRandomPastelColor(goal.StudentName),
                        fontSize: 24,
                        color: 'black',
                      }}
                    >
                      {!goal.avatar && goal.StudentName
                        ? goal.StudentName[0].toUpperCase()
                        : null}
                    </Avatar>
                    <Typography variant='body2' sx={{ mt: 1 }}>
                      {goal.StudentName}
                    </Typography>
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <Typography variant='h6' fontWeight='medium'>
                      {goal.GoalDetails?.GoalTitle}
                    </Typography>
                    <Typography
                      variant='body2'
                      color='text.secondary'
                      sx={{ mb: 1 }}
                    >
                      {goal.DataCollection.measurementType.Target}{' '}
                      {goal.DataCollection.measurementType.TargetDuration}{' '}
                      {goal.DataCollection.measurementType.measurementUnit} by{' '}
                      {goal.DataCollection.ByDate}
                    </Typography>
                    <Typography variant='body2' color='text.secondary'>
                      From: file_name
                    </Typography>
                  </Box>
                  <Box>
                    <Button
                      variant='contained'
                      onClick={() => handleViewGoal(goal.id)}
                      sx={{
                        bgcolor: '#9291ef',
                        '&:hover': { bgcolor: '#818cf8' },
                        textTransform: 'none',
                      }}
                    >
                      View
                    </Button>
                  </Box>
                </Box>
                {index < goalsFromDocuments.length - 1 && <Divider />}
              </Box>
            ))}
          </Box>
        )}
      </Paper>

      {/* From Other Users Section */}
      <Paper
        sx={{ mb: 3, overflow: 'hidden', borderRadius: 1 }}
        elevation={0}
        variant='outlined'
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            p: 2,
            bgcolor: '#e9eaff',
            borderBottom: otherUsersExpanded ? '1px solid #e2e8f0' : 'none',
            cursor: 'pointer',
          }}
          onClick={() => setOtherUsersExpanded(!otherUsersExpanded)}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant='body1' component='span' sx={{ mr: 1 }}>
              ≡
            </Typography>
            <Typography variant='subtitle1' fontWeight='medium'>
              From Other Users
            </Typography>
          </Box>
          {otherUsersExpanded ? (
            <KeyboardArrowUpIcon />
          ) : (
            <KeyboardArrowDownIcon />
          )}
        </Box>

        {otherUsersExpanded && (
          <Box>
            {goalsFromOtherUsers.map((goal, index) => (
              <Box key={goal.id}>
                <Box sx={{ p: 3, display: 'flex' }}>
                  <Box
                    sx={{
                      mr: 3,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <Avatar
                      src={goal.avatar || undefined}
                      alt={goal.studentName}
                      sx={{
                        width: 64,
                        height: 64,
                        bgcolor: goal.avatar
                          ? 'transparent'
                          : getRandomPastelColor(goal.StudentName),
                        fontSize: 24,
                        color: 'black',
                      }}
                    >
                      {!goal.avatar && goal.StudentName
                        ? goal.StudentName[0].toUpperCase()
                        : null}
                    </Avatar>
                    <Typography variant='body2' sx={{ mt: 1 }}>
                      {goal.StudentName}
                    </Typography>
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <Typography variant='h6' fontWeight='medium'>
                      {goal.GoalDetails?.GoalTitle}
                    </Typography>
                    <Typography
                      variant='body2'
                      color='text.secondary'
                      sx={{ mb: 1 }}
                    >
                      {goal.DataCollection.measurementType.Target}{' '}
                      {goal.DataCollection.measurementType.TargetDuration}{' '}
                      {goal.DataCollection.measurementType.measurementUnit} by{' '}
                      {goal.DataCollection.ByDate}
                    </Typography>
                    <Typography variant='body2' color='text.secondary'>
                      Created by: creator_name
                    </Typography>
                  </Box>
                  <Box>
                    <Button
                      variant='contained'
                      onClick={() => handleViewGoal(goal.id)}
                      sx={{
                        bgcolor: '#9291ef',
                        '&:hover': { bgcolor: '#818cf8' },
                        textTransform: 'none',
                      }}
                    >
                      View
                    </Button>
                  </Box>
                </Box>
                {index < goalsFromOtherUsers.length - 1 && <Divider />}
              </Box>
            ))}
          </Box>
        )}
      </Paper>
    </MainLayout>
  );
};

export default PendingGoals;
