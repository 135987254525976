import { collection, onSnapshot } from 'firebase/firestore';
import { FirestoreDB } from '../utils/firebase/firebase';
import { useQuery } from '@tanstack/react-query';

const fetchBehaviors = () => {
  return new Promise((resolve, reject) => {
    const behaviorRef = collection(FirestoreDB, 'behaviour');

    const unsubscribe = onSnapshot(
      behaviorRef,
      (querySnapshot) => {
        const behaviorNames = querySnapshot.docs.map((doc) => doc.id);
        resolve(behaviorNames);
      },
      (error) => reject(error)
    );

    return () => unsubscribe();
  });
};

export const useBehaviors = () => {
  return useQuery({
    queryKey: ['behaviors'],
    queryFn: fetchBehaviors,
    staleTime: 1000 * 60 * 5,
    cacheTime: 1000 * 60 * 10,
  });
};
