import React from 'react';
import { fetchAllPreRegisterUser } from '../api';
import { useQuery } from '@tanstack/react-query';

const useFetchAllPreRegisterUser = (userId, typeOfUser) => {
  return useQuery({
    queryKey: ['getPreRegisteredUserForTeacher', userId, typeOfUser],
    queryFn: async () => {
      // Fetching data using the API function
      const fetchedAllPreRegisterUserData = await fetchAllPreRegisterUser(
        userId,
        typeOfUser
      );
      console.log(
        'fetchedAllPreRegisterUserData',
        fetchedAllPreRegisterUserData
      );

      return (
        fetchedAllPreRegisterUserData?.preRegisterUsers?.map((student) => ({
          id: student.id,
          teacherId: student.docData.teacherID || student.teacherID || '',
          teacherName: student.docData.teacherName || '',
          name: student.docData.fullname || '',
          email: student.docData.email,
          parentName: student.docData.parentName,
          dob: student.docData.dob || '',
          gender: student.docData.gender || '',
          grade: student.docData.grade || '',
          schoolId: student.docData.schoolId || '',
          schoolName: student.docData.schoolName || '',
          contactNo: student.docData.contactNo || '',
          photoURL: student.docData?.photoURL || null,
          status: student.docData.status || '',
          typeOfUser: student.docData.typeOfUser || '',
        })) || []
      );
    },
    staleTime: 5000,
    cacheTime: 60000,
    onError: (error) => {
      console.error('Error fetching pre-registered users:', error);
    },
  });
};

export default useFetchAllPreRegisterUser;
