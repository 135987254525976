const goalsFromOtherUsers = [
    {
      "StudentName": "Liam",
      "StudentID": "5DEF12345XYZ",
      "GoalDetails": {
        "GoalTitle": "Task Completion",
        "GoalNumber": 6,
        "GoalCategory": ["ABA"],
        "TypeOfGoal": {
          "Type": "Behavior",
          "BehaviorType": "Skill Acquisition",
          "BehaviorName": "Completing Assigned Tasks",
          "Definition": "Finishing given tasks within the allotted time without assistance",
          "Onset": "Task is assigned",
          "Offset": "Task is completed or time runs out"
        }
      },
      "DataCollection": {
        "measurementType": {
          "Type": "Percentage",
          "Target": "At least",
          "TargetDuration": "85",
          "measurementUnit": "% tasks completed independently"
        },
        "MultipleDataPointsPerSession": {
          "MultipleDataPointsPerSession": "Yes",
          "DataPointPerSession": "Each Task"
        },
        "MultipleTimesToMeetGoal": {
          "MultipleTimesToMeetGoal": "Yes",
          "NumberOfSessions": "3",
          "ConsecutiveSessions": "Yes"
        },
        "ByDate": "2025-05-30",
        "Baseline": "Currently completing 50% of tasks independently",
        "Notes": "Increase focus and self-management skills"
      },
      "Subgoal": {
        "IsSubgoal": "No",
        "LinkedGoal": "NA"
      },
      "Generate": {
        "Graphs": true,
        "Reports": "true"
      },
      "ShareWithTeam": {
        "IsSharingEnabled": true,
        "EditAccess": ["Teacher"],
        "ViewOnlyAccess": ["Parent/Guardian"]
      }
    },
    {
      "StudentName": "Olivia",
      "StudentID": "6GHI67890LMN",
      "GoalDetails": {
        "GoalTitle": "Expressing Needs",
        "GoalNumber": 9,
        "GoalCategory": ["Communication"],
        "TypeOfGoal": {
          "Type": "Behavior",
          "BehaviorType": "Skill Acquisition",
          "BehaviorName": "Requesting Help",
          "Definition": "Verbally or non-verbally requesting assistance when needed",
          "Onset": "Student encounters difficulty",
          "Offset": "Student successfully asks for help"
        }
      },
      "DataCollection": {
        "measurementType": {
          "Type": "Frequency",
          "Target": "At least",
          "TargetDuration": "4",
          "measurementUnit": "times per session"
        },
        "MultipleDataPointsPerSession": {
          "MultipleDataPointsPerSession": "No",
          "DataPointPerSession": "Total Count"
        },
        "MultipleTimesToMeetGoal": {
          "MultipleTimesToMeetGoal": "Yes",
          "NumberOfSessions": "4",
          "ConsecutiveSessions": "Yes"
        },
        "ByDate": "2025-06-20",
        "Baseline": "Currently requesting help only once per session",
        "Notes": "Encourage self-advocacy skills"
      },
      "Subgoal": {
        "IsSubgoal": "Yes",
        "LinkedGoal": "Improving Communication Skills"
      },
      "Generate": {
        "Graphs": false,
        "Reports": "true"
      },
      "ShareWithTeam": {
        "IsSharingEnabled": true,
        "EditAccess": ["Speech Therapist"],
        "ViewOnlyAccess": ["Parent/Guardian"]
      }
    }
  ];
  
  export default goalsFromOtherUsers;
  