import { httpsCallable } from 'firebase/functions';
import { CloudFunctions } from '../utils/firebase/firebase';

export const validateAuthCodeAndGetDoctor = async (authCode, uid) => {
  try {
    const validateAuthCode = httpsCallable(CloudFunctions, 'validateAuthCode');

    const response = await validateAuthCode({
      authCode,
      uid,
    });

    if (response.data.success) {
      return response.data.data;
    } else {
      return response;
    }
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const patientLogin = async (uid) => {
  try {
    const validatePatientLogin = httpsCallable(
      CloudFunctions,
      'validatePatientLogin'
    );

    const response = await validatePatientLogin({
      uid,
    });
    if (response?.data?.status) {
      return response?.data?.data;
    } else {
      return null;
    }
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const doctorLogin = async (uid) => {
  try {
    const validateDoctorLogin = httpsCallable(
      CloudFunctions,
      'validateDoctorLogin'
    );

    const response = await validateDoctorLogin({
      uid,
    });
    if (response.data.status) {
      return response.data.data;
    } else {
      return null;
    }
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const updatePatientProfileDetails = async (details, uid) => {
  try {
    const updatePatientProfile = httpsCallable(
      CloudFunctions,
      'updatePatientProfile'
    );

    const response = await updatePatientProfile({
      details,
      uid,
    });
    if (response.data.status) {
      return response.data.message;
    } else {
      return null;
    }
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const editDoctorFeedbackTreatments = async (
  uid,
  treatments,
  patientId,
  videoId
) => {
  try {
    const updateDoctorFeedbackTreatments = httpsCallable(
      CloudFunctions,
      'updateDoctorFeedbackTreatments'
    );

    const response = await updateDoctorFeedbackTreatments({
      uid,
      treatments,
      patientId,
      videoId,
    });
    if (response.data.status) {
      return response.data.message;
    } else {
      return null;
    }
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const editDoctorFeedbackBehaviours = async (
  uid,
  behaviours,
  patientId,
  videoId
) => {
  try {
    const updateDoctorFeedbackBehaviours = httpsCallable(
      CloudFunctions,
      'updateDoctorFeedbackBehaviours'
    );

    const response = await updateDoctorFeedbackBehaviours({
      uid,
      behaviours,
      patientId,
      videoId,
    });
    if (response.data.status) {
      return response.data.message;
    } else {
      return null;
    }
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const updateDoctorProfileDetails = async (details, uid) => {
  try {
    const updateDoctorProfile = httpsCallable(
      CloudFunctions,
      'updateDoctorProfile'
    );

    const response = await updateDoctorProfile({
      details,
      uid,
    });
    if (response.data.status) {
      return response.data.message;
    } else {
      return null;
    }
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const updateAdminProfileDetails = async (details, uid) => {
  try {
    const updateAdminProfile = httpsCallable(
      CloudFunctions,
      'updateAdminProfile'
    );

    const response = await updateAdminProfile({
      details,
      uid,
    });
    if (response.data.status) {
      return response.data.message;
    } else {
      return null;
    }
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const getPatientVideos = async (uid, category) => {
  try {
    const getMyVideos = httpsCallable(CloudFunctions, 'getMyVideos');

    const response = await getMyVideos({
      uid,
      category,
    });
    if (response.data.status) {
      return response.data.videos;
    } else {
      return null;
    }
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const getAllPatientVideos = async (uid) => {
  try {
    const getAllPatientVideos = httpsCallable(
      CloudFunctions,
      'getAllPatientVideos'
    );

    const response = await getAllPatientVideos({
      uid,
    });
    if (response.data.status) {
      return response.data.videos;
    } else {
      return null;
    }
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const fetchVideoDetails = async (uid, videoId) => {
  try {
    const getVideoDetailsForPatient = httpsCallable(
      CloudFunctions,
      'getVideoDetailsForPatient'
    );

    const response = await getVideoDetailsForPatient({
      uid,
      videoId,
    });
    if (response.data.status) {
      return response.data.data;
    } else {
      return null;
    }
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const getMyPatientsDetails = async (uid) => {
  try {
    const getMyPatients = httpsCallable(CloudFunctions, 'getMyPatients');

    const response = await getMyPatients({
      uid,
    });
    if (response.data.status) {
      return response.data.data;
    } else {
      return null;
    }
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const fetchPatientTreatments = async (uid, typeOfUser) => {
  try {
    const getPatientTreatments = httpsCallable(
      CloudFunctions,
      'getPatientTreatments'
    );

    const response = await getPatientTreatments({
      uid,
      typeOfUser,
    });
    if (response.data.status) {
      return response.data.treatments;
    } else {
      return null;
    }
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const UpdatePatientNotesToVault = async (notes, uid, videoId) => {
  try {
    const updatePatientFeedbackNotes = httpsCallable(
      CloudFunctions,
      'updatePatientFeedbackNotes'
    );

    const response = await updatePatientFeedbackNotes({
      notes,
      uid,
      videoId,
    });
    if (response.data.status) {
      return response.data.message;
    } else {
      return null;
    }
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const UpdatedDoctorFeedbackNotesToVault = async (
  notes,
  uid,
  patientId,
  videoId
) => {
  try {
    const updateDoctorFeedbackNotes = httpsCallable(
      CloudFunctions,
      'updateDoctorFeedbackNotes'
    );

    const response = await updateDoctorFeedbackNotes({
      notes,
      uid,
      patientId,
      videoId,
    });
    if (response.data.status) {
      return response.data.message;
    } else {
      return null;
    }
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const getAllVideosFeedbackData = async (uid) => {
  try {
    const getAllDocNotesForPatient = httpsCallable(
      CloudFunctions,
      'getAllDocNotesForPatient'
    );

    const response = await getAllDocNotesForPatient({
      uid,
    });
    if (response.data.status) {
      return response.data.data;
    } else {
      return null;
    }
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const fetchProcessedVideoDetails = async (uid, videoId) => {
  try {
    const getProcessedVideoInfo = httpsCallable(
      CloudFunctions,
      'getProcessedVideoInfo'
    );

    const response = await getProcessedVideoInfo({
      uid,
      videoId,
    });
    if (response.data.status) {
      return response.data.data;
    } else {
      return null;
    }
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const fetchUserVideoActivityLogs = async (uid, videoId) => {
  try {
    const getUserVideoActivityLogs = httpsCallable(
      CloudFunctions,
      'getUserVideoActivityLogs'
    );

    const response = await getUserVideoActivityLogs({
      uid,
      videoId,
    });
    // console.log("Fetching video activity logs", response);
    if (response.data.status) {
      return response.data.data;
    } else {
      return null;
    }
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const fetchUserNotificationMessages = async (uid, videoId) => {
  try {
    const getUserNotificationMessages = httpsCallable(
      CloudFunctions,
      'getUserNotificationMessages'
    );

    const response = await getUserNotificationMessages({
      uid,
    });
    if (response.data.status) {
      return response.data.data;
    } else {
      return null;
    }
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const deleteUserAccountInfo = async (uid, typeOfUser) => {
  try {
    const deleteUserAccount = httpsCallable(
      CloudFunctions,
      'deleteUserAccount'
    );

    const response = await deleteUserAccount({
      uid,
      typeOfUser,
    });
    if (response.data.status) {
      return response.data.message;
    } else {
      return null;
    }
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const updatePatientTreatmentDetails = async (
  uid,
  patientId,
  treatmentId,
  treatment
) => {
  try {
    const updatePatientTreatmentInfo = httpsCallable(
      CloudFunctions,
      'updatePatientTreatmentInfo'
    );

    const response = await updatePatientTreatmentInfo({
      uid,
      patientId,
      treatmentId,
      treatment,
    });
    if (response.data.status) {
      return response.data.message;
    } else {
      return null;
    }
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const updateStudentTreatmentDetails = async (
  uid,
  studentId,
  treatmentId,
  treatment
) => {
  try {
    const updateStudentTreatmentInfo = httpsCallable(
      CloudFunctions,
      'updateStudentTreatmentInfo'
    );
    const response = await updateStudentTreatmentInfo({
      uid,
      studentId,
      treatmentId,
      treatment,
    });
    if (response.data.status) {
      return response.data.message;
    } else {
      return null;
    }
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const fetchDoctorAndPatientMessages = async (
  uid,
  doctorId,
  patientId
) => {
  try {
    const getDoctorAndPatientMessages = httpsCallable(
      CloudFunctions,
      'getDoctorAndPatientMessages'
    );

    const response = await getDoctorAndPatientMessages({
      uid,
      doctorId,
      patientId,
    });
    if (response?.data?.status) {
      return response?.data?.data;
    } else {
      return null;
    }
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const addPatientByDoctor = async (uid, patientEmail) => {
  try {
    const addExistingPatientByDoctor = httpsCallable(
      CloudFunctions,
      'addExistingPatientByDoctor'
    );

    const response = await addExistingPatientByDoctor({
      uid,
      patientEmail,
    });
    console.log('response: ', response);

    if (response.data.status) {
      return response.data;
    } else {
      return response;
    }
  } catch (e) {
    console.log(e);
    throw e;
  }
};
export const fetchAllAttachments = async (uid) => {
  try {
    const getAllAttachmentsData = httpsCallable(
      CloudFunctions,
      'getAllAttachmentsData'
    );

    const response = await getAllAttachmentsData({
      uid,
    });
    console.log('response: ', response);

    if (response.data.status) {
      return response.data;
    } else {
      return response;
    }
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const fetchAllAttachmentsByUser = async (uid, typeOfUser) => {
  try {
    const getAllAttachmentsByUser = httpsCallable(
      CloudFunctions,
      'getAllAttachmentsByUser'
    );

    const response = await getAllAttachmentsByUser({
      uid,
      typeOfUser,
    });

    if (response?.data?.status) {
      return response?.data;
    } else {
      return response;
    }
  } catch (e) {
    console.log(e);
    throw e;
  }
};
export const fetchGroupMessages = async (uid, groupID) => {
  try {
    const getGroupMessages = httpsCallable(CloudFunctions, 'getGroupMessages');

    const response = await getGroupMessages({
      uid,
      groupID,
    });
    console.log(' at api : ', response);
    if (response.data.status) {
      return response.data.data;
    } else {
      return response;
    }
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const adminLogin = async (uid) => {
  try {
    const validateAdminLogin = httpsCallable(
      CloudFunctions,
      'validateAdminLogin'
    );
    const response = await validateAdminLogin({ uid });
    if (response.data.status) {
      return response.data.data;
    } else {
      console.warn('Admin validation failed:', response.data.message);
      return null;
    }
  } catch (error) {
    console.error('Error during admin login:', error);
    throw error;
  }
};

export const fetchAllStudents = async (uid, typeOfUser) => {
  try {
    const getAllStudents = httpsCallable(CloudFunctions, 'allStudents');
    const response = await getAllStudents({
      uid,
      typeOfUser,
    });
    if (response.data.status) {
      return response.data;
    } else {
      return response;
    }
  } catch (e) {
    console.log('Error in fetchAllStudents API: ', e);
    throw e;
  }
};

export const updateStudentProfileDetails = async (details, uid) => {
  try {
    const updateStudentProfile = httpsCallable(
      CloudFunctions,
      'updateStudentProfile'
    );

    const response = await updateStudentProfile({
      details,
      uid,
    });
    if (response.data.status) {
      return response.data.message;
    } else {
      return null;
    }
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const fetchAllTeachers = async (uid, typeOfUser) => {
  try {
    const getAllTeachers = httpsCallable(CloudFunctions, 'allTeachers');
    const response = await getAllTeachers({
      uid,
      typeOfUser,
    });

    if (response.data.status) {
      return response.data;
    } else {
      return response;
    }
  } catch (e) {
    console.log('Error in fetchAllTeachers API: ', e);
    throw e;
  }
};

export const fetchAllSchoolAdmins = async (uid, typeOfUser) => {
  try {
    const allSchoolAdmins = httpsCallable(CloudFunctions, 'allSchoolAdmins');
    const response = await allSchoolAdmins({
      uid,
      typeOfUser,
    });

    if (response.data.status) {
      return response.data;
    } else {
      return response;
    }
  } catch (e) {
    console.log('Error in fetchAllSchoolAdmin API: ', e);
    throw e; // Rethrow the error for further handling
  }
};

export const updateSchoolAdmintProfileDetails = async (details, uid) => {
  try {
    const updateSchoolAdminProfile = httpsCallable(
      CloudFunctions,
      'updateSchoolAdminProfile'
    );

    const response = await updateSchoolAdminProfile({
      details,
      uid,
    });
    if (response.data.status) {
      return response.data.message;
    } else {
      return null;
    }
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const studentLogin = async (uid) => {
  try {
    const validateStudentLogin = httpsCallable(
      CloudFunctions,
      'validateStudentLogin'
    );
    const response = await validateStudentLogin({ uid });
    if (response.data.status) {
      return response.data.data;
    } else {
      console.warn('Student validation failed:', response.data.message);
      return null;
    }
  } catch (error) {
    console.error('Error during student login:', error);
    throw error;
  }
};

export const teacherLogin = async (uid) => {
  try {
    const validateTeacherLogin = httpsCallable(
      CloudFunctions,
      'validateTeacherLogin'
    );
    const response = await validateTeacherLogin({ uid });
    if (response.data.status) {
      return response.data.data;
    } else {
      console.warn('Teacher validation failed:', response.data.message);
      return null;
    }
  } catch (error) {
    console.error('Error during student login:', error);
    throw error;
  }
};

export const updateTeachertProfileDetails = async (details, uid) => {
  try {
    const updateTeacherProfile = httpsCallable(
      CloudFunctions,
      'updateTeacherProfile'
    );

    const response = await updateTeacherProfile({
      details,
      uid,
    });
    if (response.data.status) {
      return response.data.message;
    } else {
      return null;
    }
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const schoolAdminLogin = async (uid) => {
  try {
    const validateSchoolAdminLogin = httpsCallable(
      CloudFunctions,
      'validateSchoolAdminLogin'
    );
    const response = await validateSchoolAdminLogin({ uid });
    if (response.data.status) {
      return response.data.data;
    } else {
      console.warn(
        'School ddmin login validation failed:',
        response.data.message
      );
      return null;
    }
  } catch (error) {
    console.error('Error during school admin login login:', error);
    throw error;
  }
};

export const fetchingTeachersAndStudentsListForSchoolAdmin = async (uid) => {
  try {
    const fetchTeachersAndStudents = httpsCallable(
      CloudFunctions,
      'fetchingTeachersAndStudentsListForSchoolAdmin'
    );
    const response = await fetchTeachersAndStudents({ uid });

    // Handle the response
    if (response.data.status) {
      return response.data.data;
    } else {
      console.warn(
        'Failed to fetch teachers and students list:',
        response.data.message
      );
      return null;
    }
  } catch (error) {
    console.error('Error during fetching teachers and students list:', error);
    throw error;
  }
};
export const fetchingTeachersForStudents = async (uid) => {
  try {
    const fetchingTeachersListForStudents = httpsCallable(
      CloudFunctions,
      'fetchingTeachersListForStudents'
    );
    const response = await fetchingTeachersListForStudents({ uid });

    // Handle the response
    if (response.data.status) {
      return response.data.data;
    } else {
      console.log('Failed to fetch students list:', response.data.message);
      return null;
    }
  } catch (error) {
    console.error('Error during fetching students list:', error);
    throw error;
  }
};
export const organizationAdminLogin = async (uid) => {
  try {
    const validateOrganisationAdminLogin = httpsCallable(
      CloudFunctions,
      'validateOrganisationAdminLogin'
    );
    const response = await validateOrganisationAdminLogin({ uid });
    if (response.data.status) {
      return response.data.data;
    } else {
      console.warn(
        'School ddmin login validation failed:',
        response.data.message
      );
      return null;
    }
  } catch (error) {
    console.error('Error during school admin login login:', error);
    throw error;
  }
};

export const fetchingStudentsForTeachers = async (uid) => {
  try {
    const fetchingStudentsListForTeachers = httpsCallable(
      CloudFunctions,
      'fetchingStudentsListForTeachers'
    );
    const response = await fetchingStudentsListForTeachers({ uid });

    // Handle the response
    if (response.data.status) {
      return response.data.data;
    } else {
      console.log('Failed to fetch students list:', response.data.message);
      return null;
    }
  } catch (error) {
    console.error('Error during fetching students list:', error);
    throw error;
  }
};

export const fetchAllOrganizationAdmins = async (uid, typeOfUser) => {
  try {
    const allOrganizationAdmins = httpsCallable(
      CloudFunctions,
      'allOrganizationAdmins'
    );
    const response = await allOrganizationAdmins({
      uid,
      typeOfUser,
    });

    if (response.data.status) {
      return response.data;
    } else {
      return response;
    }
  } catch (e) {
    console.log('Error in fetchAllSchoolAdmin API: ', e);
    throw e;
  }
};

export const updateOrganizationAdminProfileDetails = async (details, uid) => {
  try {
    const updateOranizationAdminProfile = httpsCallable(
      CloudFunctions,
      'updateOranizationAdminProfile'
    );

    const response = await updateOranizationAdminProfile({
      details,
      uid,
    });
    if (response.data.status) {
      return response.data.message;
    } else {
      return null;
    }
  } catch (e) {
    console.log(e);
    throw e;
  }
};
export const getSchoolAdminsAndSchoolsForOrgAdmin = async (uid) => {
  try {
    const fetchingSchoolAdminsListForOrganizationAdmin = httpsCallable(
      CloudFunctions,
      'fetchingSchoolAdminsListForOrganizationAdmin'
    );
    const response = await fetchingSchoolAdminsListForOrganizationAdmin({
      uid,
    });

    // Handle the response
    if (response.data.status) {
      return response.data.data;
    } else {
      console.log('Failed to fetch students list:', response.data.message);
      return null;
    }
  } catch (error) {
    console.error('Error during fetching students list:', error);
    throw error;
  }
};

export const fetchStudentData = async (uid, typeOfUser) => {
  try {
    const getStudentData = httpsCallable(CloudFunctions, 'getStudentData');

    const response = await getStudentData({ uid, typeOfUser });

    if (response.data?.status) {
      return response.data.studentData;
    } else {
      console.warn(
        'fetchStudentData: API returned false status',
        response.data.message
      );
      return null;
    }
  } catch (error) {
    console.error('fetchStudentData: Error fetching student data', error);
    return null;
  }
};

export const editStudentGoalEntry = async (
  uid,
  typeOfUser,
  studentId,
  dataCollectionId,
  goalId,
  infoValue
) => {
  try {
    const updateStudentGoalEntry = httpsCallable(
      CloudFunctions,
      'updateStudentGoalEntry'
    );
    const response = await updateStudentGoalEntry({
      uid,
      typeOfUser,
      studentId,
      dataCollectionId,
      goalId,
      infoValue,
    });
    if (response?.data?.status) {
      return response?.data?.message;
    } else {
      return null;
    }
  } catch (e) {
    console.error('Error in editStudentGoalEntry:', e);
    throw e;
  }
};

export const updateGoalDataCollection = async (
  uid,
  typeOfUser,
  studentId,
  dataCollectionId,
  info
) => {
  try {
    const updateDatacollection = httpsCallable(
      CloudFunctions,
      'updateDatacollectionGoalInfo'
    );

    const response = await updateDatacollection({
      uid,
      typeOfUser,
      studentId,
      dataCollectionId,
      info,
    });

    if (response?.data?.status) {
      return response?.data?.message;
    } else {
      return null;
    }
  } catch (e) {
    console.error('Error in updateGoalDataCollection:', e);
    throw e;
  }
};

export const getPreRegisteredUserForTeacher = async (uid) => {
  try {
    const fetchPreRegisteredUsersForTeachers = httpsCallable(
      CloudFunctions,
      'fetchPreRegisteredUsersForTeachers'
    );
    const response = await fetchPreRegisteredUsersForTeachers({ uid });

    if (response.data.status) {
      return response.data.data;
    } else {
      console.log(
        'Failed to fetch Pre-Registered Users For Teachers list:',
        response.data.message
      );
      return null;
    }
  } catch (error) {
    console.error(
      'Error during fetching Pre-Registered Users For Teachers list:',
      error
    );
    throw error;
  }
};

export const fetchPreRegisteredUsers = async (email) => {
  try {
    const fetchPreRegisteredUsersCallable = httpsCallable(
      CloudFunctions,
      'fetchPreRegisteredUsers'
    );
    const response = await fetchPreRegisteredUsersCallable({ email });
    if (response?.data?.status) {
      return response?.data?.user;
    } else {
      return null;
    }
  } catch (e) {
    console.error('Error in fetchPreRegisteredUsers:', e);
    throw e;
  }
};

export const movePreRegisteredUserToRealUser = async (oldUid, newUid) => {
  try {
    const migrateUser = httpsCallable(
      CloudFunctions,
      'movePreRegisteredUserToRealUser'
    );

    const response = await migrateUser({ oldUid, newUid });

    if (response?.data?.status) {
      return response?.data?.message;
    } else {
      return null;
    }
  } catch (e) {
    console.error('Error in movePreRegisteredUserToRealUser:', e);
    throw e;
  }
};

export const updatePreRegisterUserProfile = async (details, uid) => {
  try {
    const preRegisteredUserProfile = httpsCallable(
      CloudFunctions,
      'updatePreRegisterUserProfile'
    );

    const response = await preRegisteredUserProfile({ details, uid });

    if (response?.data?.status) {
      return response?.data?.message;
    } else {
      return null;
    }
  } catch (e) {
    console.error('Error in update Pre-register user profile:', e);
    throw e;
  }
};

export const fetchAllPreRegisterUser = async (uid, typeOfUser) => {
  try {
    const getAllPreRegisterUser = httpsCallable(
      CloudFunctions,
      'allPreRegisterUser'
    );
    const response = await getAllPreRegisterUser({
      uid,
      typeOfUser,
    });
    if (response.data.status) {
      return response.data;
    } else {
      return response;
    }
  } catch (e) {
    console.log('Error in fetch All Pre-Register User API: ', e);
    throw e;
  }
};
