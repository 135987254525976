import { useQuery } from '@tanstack/react-query';
import { fetchProcessedVideoDetails } from '../api';

const useProcessedVideoDetails = (uid, videoId) => {
  return useQuery({
    queryKey: ['fetchProcessedVideoDetails', uid, videoId],
    queryFn: () => fetchProcessedVideoDetails(uid, videoId),
    enabled: !!uid || !!videoId,
    staleTime: 60000,
    cacheTime: 300000,
  });
};

export default useProcessedVideoDetails;
