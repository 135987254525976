import React, { useContext, useEffect, useRef, useState } from 'react';
import MainLayout from '../../hoc/MainLayout';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  IconButton,
  Avatar,
  Box,
  TextField,
  InputAdornment,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Button,
  CardHeader,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tabs,
  Tab,
  Tooltip,
  tooltipClasses,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  ListItem,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import {
  Search,
  Groups,
  Chat,
  Groups2,
  ExpandMore,
  Circle,
  Edit,
} from '@mui/icons-material';
import './Messaging.css'; // Import the external CSS file
import AuthContext from '../../contexts/AuthContext';
import usePatientsForDoctor from '../../hooks/usePatientsForDoctor';
import { UIContext } from '../../contexts/UIProvider';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FirestoreDB } from '../../utils/firebase/firebase';
import {
  addDoc,
  collection,
  doc,
  getDocs,
  limit,
  onSnapshot,
  query,
  where,
  writeBatch,
} from 'firebase/firestore';
import UnreadMessageCounts from './UnreadCount';
import { orderBy } from 'lodash';
import Conversations from './Conversation';
import Progress from '../../components/Progress';
import { styled } from '@mui/material/styles';
import { ACMContext } from '../../contexts/ACMProvider';
import useStudentsForOneTeacher from '../../hooks/useStudentsForOneTeacher';
import { fetchingTeachersForStudents } from '../../api';
import { convertLength } from '@mui/material/styles/cssUtils';
import useTeachersForOneStudent from '../../hooks/useTeachersForOneStudent';
// import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import CustomButton from '../../components/CustomButton';

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontWeight: 'bold',
    padding: '10px',
  },
}));

const Message = () => {
  const { ACM } = useContext(ACMContext);
  const countsObjRef = useRef({}); // Using ref to store unread counts

  const [messages, setMessages] = useState([]);
  const { user, typeOfUser } = useContext(AuthContext);

  const { selectedPatientId, selectPatient, setIsNewMessage } =
    useContext(UIContext);
  const [currentPatient, setCurrentPatient] = useState(null);
  const [currentStudent, setCurrentStudent] = useState(null);
  const [selectedChat, setSelectedChat] = useState(null);
  const [currentDoctor, setCurrentDoctor] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [unreadCounts, setUnreadCounts] = useState([]);
  const [unreadCounts2, setUnreadCounts2] = useState([]);
  const [unreadCountsForGroup, setUnreadCountsForGroup] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedPatients, setSelectedPatients] = useState([]);
  const [groupName, setGroupName] = useState('');
  const [groups, setGroups] = useState([]);
  const [chats, setChats] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [currentTeacher, setCurrentTeacher] = useState(null);
  const [fetchedTeachers, setFetchedTeachers] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [selectedTeacher, setSelectedTeacher] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [recentChatsExpanded, setRecentChatsExpanded] = useState(true);
  const [groupChatsExpanded, setGroupChatsExpanded] = useState(true);
  const [activeChatType, setActiveChatType] = useState('recent');
  const [open, setOpen] = useState(false);
  const [popupData, setPopupData] = useState({
    label: '',
    open: false,
    data: [],
  });
  // const [showCard, setShowCard] = useState(false);
  // const [tabIndex, setTabIndex] = useState(0);
  const [popupOpen, setPopupOpen] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();
  const scroll = useRef(null);
  const locationPatientId = location?.state?.patientId;
  const locationDoctorId = location?.state?.doctorId;
  const locationStudentId = location?.state?.studentId;
  const locationTeacherId = location?.state?.teacherId;

  const {
    data: patientData = [],
    isLoading: isLoading,
    error: errorPatientData,
  } = usePatientsForDoctor(user?.uid);

  const {
    data: studentData = [],
    isLoading: isStudentDataLoading,
    error: errorStudentData,
  } = useStudentsForOneTeacher(user?.uid, typeOfUser);

  const {
    data: teacherData = [],
    isLoading: isTeacherDataLoading,
    error: errorTeacherData,
  } = useTeachersForOneStudent(user?.uid, typeOfUser);

  const fetchGroups = () => {
    if (!user?.uid) {
      // If user?.uid is undefined, show loading message and return a no-op function
      setLoading(true);
      return () => {};
    }

    const groupCollection = collection(FirestoreDB, 'groupChats');
    const q = query(
      groupCollection,
      where('members', 'array-contains', user?.uid)
    );

    const unsubscribe = onSnapshot(q, (groupSnapshot) => {
      const groupList = groupSnapshot.docs.map((doc) => ({
        groupId: doc.id,
        ...doc.data(),
      }));
      setGroups(groupList);
      setLoading(false);
    });

    return unsubscribe; // Return the unsubscribe function
  };

  useEffect(() => {
    const unsubscribe = fetchGroups();
    return () => unsubscribe(); // Clean up the listener when the component unmounts
  }, [user?.uid]);

  const handleClickDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setErrorMessage('');
    resetStates();
  };

  // Open Popup
  const handleOpen = () => {
    let data =
      typeOfUser === 'DOCTOR'
        ? sortedFilteredPatients?.map((item) => {
            return {
              label: item.patientInfo.fullname,
              id: item.patientId,
              ...item,
            };
          })
        : typeOfUser === 'PATIENT'
        ? sortedFilteredDoctors?.map((item) => {
            return {
              label: item.fullname,
              id: item.doctorId,
              ...item,
            };
          })
        : typeOfUser === 'TEACHER'
        ? sortedFilteredStudnet?.map((item) => {
            return {
              label: item.name,
              id: item.id,
              ...item,
            };
          })
        : typeOfUser === 'STUDENT'
        ? sortedFilteredTeacher?.map((item) => {
            return {
              label: item.name,
              id: item.id,
              ...item,
            };
          })
        : [];
    setPopupData({
      open: true,
      data: data,
      label: userTypes[typeOfUser],
    });
  };

  // Close Popup
  const handleClose = () => setOpen(false);
  const handlePatientCheckboxChange = (member) => {
    // member = { id, name, photoURL, typeOfUser: "DOCTOR" || "PATIENT" };
    const isChecked = selectedPatients.find((item) => item?.id === member?.id);
    if (isChecked) {
      setSelectedPatients(
        selectedPatients.filter((item) => item?.id !== member?.id)
      );
    } else {
      setSelectedPatients([...selectedPatients, member]);
    }
    setErrorMessage('');
  };

  const createGroup = async (creator = '', members = []) => {
    try {
      const groupRef = collection(FirestoreDB, 'groupChats');
      const res = await addDoc(groupRef, {
        createdBy: creator?.id,
        members: [...members.map((member) => member.id), creator?.id],
        groupName: groupName,
        createdAt: new Date(),
      });
      const batch = writeBatch(FirestoreDB);
      [...members, creator].forEach((member) => {
        const memberRef = doc(
          FirestoreDB,
          'groupChats',
          res.id,
          'members',
          member.id
        );
        batch.set(memberRef, member);
      });
      await batch.commit();
      toast.success('Group created successfully', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } catch (error) {
      console.error('Error creating group:', error);
    }
  };

  const handleCreateGroup = () => {
    if (!groupName) {
      setErrorMessage('Group name is required');
      return;
    }
    if (selectedPatients?.length === 0) {
      setErrorMessage('At least one patient must be selected');
      return;
    }
    createGroup(
      {
        id: user.uid,
        name: user?.fullname,
        parentName: user?.parentName || '',
        photoURL: user?.photoURL,
        typeOfUser,
      },
      selectedPatients
    );
    handleDialogClose();
    resetStates();
  };

  const resetStates = () => {
    setSelectedPatients([]);
    setGroupName('');
  };

  const handleGroupNameChange = (e) => {
    setGroupName(e.target.value);
    if (errorMessage && e.target.value) {
      setErrorMessage(''); // Clear the error message when a valid group name is entered
    }
  };

  const changeReadStatusToDB = async (uid) => {
    // Validate uid
    if (!uid) {
      console.error('Invalid UID:', uid);
      return;
    }

    const messageRef = collection(
      FirestoreDB,
      'notifications',
      user?.uid,
      'messages'
    );
    const sourceTypes = {
      DOCTOR: 'patientMessage',
      PATIENT: 'doctorMessage',
      TEACHER: 'studentMessage',
      STUDENT: 'teacherMessage',
    };

    // Validate typeOfUser and get source type
    const sourceType = sourceTypes[typeOfUser];
    if (!sourceType) {
      console.error('Invalid typeOfUser:', typeOfUser);
      return;
    }

    try {
      const querySnapshot = await getDocs(
        query(
          messageRef,
          orderBy('createdDate', 'desc'),
          where('userId', '==', uid),
          where('notificationSource', '==', sourceType)
        )
      );

      if (querySnapshot.empty) {
        console.log('No unread messages found for UID:', uid);
        return;
      }

      const batch = writeBatch(FirestoreDB);
      querySnapshot.forEach((doc) => {
        batch.update(doc.ref, { readStatus: true });
      });

      await batch.commit();
      console.log('Read status updated successfully for user:', uid);
    } catch (error) {
      console.error('Error updating read status:', error);
    }
  };

  const changeReadStatusForGroup = async (groupId) => {
    try {
      const snapshot = await getDocs(
        query(
          collection(FirestoreDB, 'groupChats', groupId, 'groupMessages'),
          where('readStatus', 'array-contains', {
            id: user?.uid,
            status: false,
          }),
          where('notificationSource', '==', 'groupMessage')
        )
      );

      const batch = writeBatch(FirestoreDB);
      snapshot.forEach((doc) => {
        const messageData = doc.data();
        const updatedReadStatus = messageData.readStatus.map((statusObj) => {
          if (statusObj.id === user?.uid) {
            return { ...statusObj, status: true }; // Mark as read
          }
          return statusObj;
        });
        batch.update(doc.ref, { readStatus: updatedReadStatus });
      });

      await batch.commit();
    } catch (error) {
      console.error('Error marking messages as read:', error);
    }
  };

  const showPatientConversation = async (patient) => {
    if (!patient?.patientId) {
      console.error('Invalid patient object:', patient);
      return;
    }
    setCurrentPatient(patient);
    setSelectedChat(patient);
    await changeReadStatusToDB(patient?.patientId);
    setUnreadCounts((prevCounts) => [
      ...prevCounts.filter((count) => count?.patientId !== patient?.patientId),
      { patientId: patient?.patientId, count: 0 },
    ]);
  };

  const showStudentConversation = async (student) => {
    setCurrentStudent(student);
    setSelectedChat(student);
    await changeReadStatusToDB(student?.id);
    // setUnreadCounts((prevCounts) => [
    //   ...prevCounts.filter((count) => count.studentId !== student?.id),
    //   { patientId: student.id, count: 0 },
    // ]);
  };

  const showDoctorConversation = async (doctor) => {
    setCurrentDoctor(doctor);
    setSelectedChat(doctor);
    await changeReadStatusToDB(doctor?.doctorId);
    // setUnreadCounts((prevCounts) => [
    //   ...prevCounts.filter((count) => count.doctorId !== doctor?.doctorId),
    //   { doctorId: doctor?.doctorId, count: 0 },
    // ]);
  };
  const showTeacherConversation = async (teacher) => {
    setCurrentTeacher(teacher);
    setSelectedChat(teacher);
    // try {
    //   await changeReadStatusToDB(teacher?.id);
    // } catch (error) {
    //   console.error('Error updating read status:', error);
    // }
    await changeReadStatusToDB(teacher?.id);
    // setUnreadCounts((prevCounts) => [
    //   ...prevCounts.filter((count) => count.teacherId !== teacher?.id),
    //   // { doctorId: teacher?.id, count: 0 },
    //   { teacherId: teacher?.id, count: 0 },
    // ]);
  };

  const showGroupConversation = async (group) => {
    setSelectedChat(group); // Set selected chat
    await changeReadStatusForGroup(group.groupId); // Automatically mark messages as read
    setUnreadCountsForGroup((prevCounts) =>
      prevCounts.map((countObj) =>
        countObj.groupId === group.groupId
          ? { ...countObj, count: 0 }
          : countObj
      )
    );

    setIsNewMessage((prevCounts) => {
      if (!prevCounts || !Array.isArray(prevCounts)) {
        return false; // No new messages if prevCounts is undefined or not an array
      }
      const updatedCounts = prevCounts.map((countObj) =>
        countObj.groupId === group.groupId
          ? { ...countObj, count: 0 }
          : countObj
      );
      return updatedCounts.some((countObj) => countObj.count > 0); // Check if any groups still have unread messages
    });
  };
  const goToPatient = (id) => {
    selectPatient(id);

    navigate(`/patient/${id}`, {
      state: {
        patientData: {
          ...patientData?.find((patient) => patient?.patientId === id)
            ?.patientInfo,
          id,
        },
      },
    });
  };

  const goToStudent = (id) => {
    selectPatient(id);
    navigate(`/student/${id}`, {
      state: {
        studentData: {
          ...studentData?.find((student) => student?.id === id),
        },
      },
    });
  };

  useEffect(() => {
    const unsubscribes = [];
    const fetchGroupUnreadCounts = () => {
      try {
        if (groups) {
          for (const group of groups) {
            const readStatusQuery = { id: user?.uid, status: false };
            const unsubscribe = onSnapshot(
              query(
                collection(
                  FirestoreDB,
                  'groupChats',
                  group?.groupId,
                  'groupMessages'
                ),
                where('readStatus', 'array-contains', readStatusQuery),
                where('notificationSource', '==', 'groupMessage')
              ),
              (snapshot) => {
                const count = snapshot.size;
                countsObjRef.current[group.groupId] = count;

                const counts = Object.entries(countsObjRef.current).map(
                  ([id, count]) => ({
                    groupId: id,
                    count,
                  })
                );

                const hasNewMessages = counts.some((item) => item.count > 0);
                setIsNewMessage(hasNewMessages);
                setUnreadCountsForGroup(counts); // Update state after each snapshot

                if (selectedChat?.groupId === group?.groupId && count > 0) {
                  markGroupMessagesAsRead(group?.groupId);
                }
              }
            );

            unsubscribes.push(unsubscribe);
          }
        }
      } catch (error) {
        console.error('Error fetching unread message counts:', error);
      }
    };

    // Call markGroupMessagesAsRead when a new chat is selected/opened
    const markGroupMessagesAsRead = async (groupId) => {
      try {
        if (!groupId) return;

        const readStatusQuery = { id: user?.uid, status: false };
        const unreadMessagesQuery = query(
          collection(FirestoreDB, 'groupChats', groupId, 'groupMessages'),
          where('readStatus', 'array-contains', readStatusQuery)
        );

        const snapshot = await getDocs(unreadMessagesQuery);

        if (!snapshot.empty) {
          const batch = writeBatch(FirestoreDB);
          snapshot.forEach((docSnapshot) => {
            const messageData = docSnapshot.data();
            const updatedReadStatus = messageData.readStatus.map(
              (statusObj) => {
                if (statusObj.id === user?.uid) {
                  return { ...statusObj, status: true }; // Mark messages as read
                }
                return statusObj;
              }
            );

            batch.update(docSnapshot.ref, { readStatus: updatedReadStatus });
          });

          await batch.commit();

          // Reset unread count for the group immediately after marking as read
          countsObjRef.current[groupId] = 0;
          setUnreadCountsForGroup((prevCounts) =>
            prevCounts.map((countObj) =>
              countObj.groupId === groupId
                ? { ...countObj, count: 0 }
                : countObj
            )
          );
        }
      } catch (error) {
        console.error('Error marking group messages as read:', error);
      }
    };

    // Automatically fetch counts on groups change
    fetchGroupUnreadCounts();

    // Automatically mark selected chat as read
    if (selectedChat) {
      const groupId = selectedChat.groupId; // Ensure you get the groupId from selectedChat
      markGroupMessagesAsRead(groupId); // Pass the groupId to mark messages as read
    }

    // Cleanup subscriptions on component unmount
    return () => {
      unsubscribes.forEach((unsubscribe) => unsubscribe());
    };
  }, [groups, selectedChat, user?.uid]);

  useEffect(() => {
    const fetchUnreadCounts = () => {
      try {
        if (
          !sortedFilteredStudnet?.length &&
          !sortedFilteredDoctors?.length &&
          !sortedFilteredPatients?.length &&
          !sortedFilteredTeacher?.length
        )
          return;

        const unsubscribes = [];

        // for (const chat of chats) {
        // const readStatusQuery = { id: user?.uid, status: false };
        const sourceTypes = {
          DOCTOR: 'patientMessage',
          PATIENT: 'doctorMessage',
          TEACHER: 'studentMessage',
          STUDENT: 'teacherMessage',
        };
        const unsubscribe = onSnapshot(
          query(
            collection(FirestoreDB, 'notifications', user?.uid, 'messages'),
            where('readStatus', '==', false),
            where('notificationSource', '==', sourceTypes[typeOfUser]) // Ensure only direct messages
          ),
          (snapshot) => {
            const count = snapshot.size;
            // countsObjRef.current[chat.chatId] = count;

            const tempCounts = {};

            snapshot.forEach((item) => {
              const { userId } = item.data();
              tempCounts[userId] = (tempCounts[userId] || 0) + 1;
            });

            // // Convert counts object to array
            // const countsArray = Object.entries(countsObjRef.current).map(
            //   ([id, count]) => ({ chatId: id, count })
            // );

            // const hasNewMessages = ;
            setIsNewMessage(Object.keys(tempCounts) > 0);
            setUnreadCounts(tempCounts); // Update state with new counts

            // Count only chats with unread messages
            // const unreadChatCount = countsArray.filter(
            //   (item) => item.count > 0
            // )?.length;
            // setIsNewMessage(unreadChatCount); // Update the unread chat count state
          }
        );

        // unsubscribes.push(unsubscribe);
        // }

        // Cleanup function to remove listeners when component unmounts
        return () => unsubscribe();
      } catch (error) {
        console.error('Error fetching unread message counts:', error);
      }
    };
    // const markMessagesAsRead = async (chatId) => {
    //   try {
    //     if (!chatId) return;

    //     const readStatusQuery = { id: user?.uid, status: false };
    //     const unreadMessagesQuery = query(
    //       collection(FirestoreDB, 'notifications', user?.uid, 'messages'),
    //       where('readStatus', 'array-contains', readStatusQuery),
    //       where('notificationSource', '==', 'studentMessage') // Ensure only direct messages
    //     );

    //     const snapshot = await getDocs(unreadMessagesQuery);

    //     if (!snapshot.empty) {
    //       const batch = writeBatch(FirestoreDB);
    //       snapshot.forEach((docSnapshot) => {
    //         const messageData = docSnapshot.data();
    //         const updatedReadStatus = messageData.readStatus.map(
    //           (statusObj) => {
    //             if (statusObj.id === user?.uid) {
    //               return { ...statusObj, status: true }; // Mark messages as read
    //             }
    //             return statusObj;
    //           }
    //         );

    //         batch.update(docSnapshot.ref, { readStatus: updatedReadStatus });
    //       });

    //       await batch.commit();

    //       // Reset unread count for the group immediately after marking as read
    //       countsObjRef.current[chatId] = 0;
    //       setUnreadCounts((prevCounts) =>
    //         prevCounts.map((countObj) =>
    //           countObj.chatId === chatId ? { ...countObj, count: 0 } : countObj
    //         )
    //       );
    //     }
    //   } catch (error) {
    //     console.error('Error marking group messages as read:', error);
    //   }
    // };

    // Automatically fetch counts on groups change
    // fetchUnreadCounts();

    // Automatically mark selected chat as read
    // if (selectedChat) {
    //   const chatId = selectedChat.chatId; // Ensure you get the groupId from selectedChat
    //   markMessagesAsRead(chatId); // Pass the groupId to mark messages as read
    // }

    // Cleanup subscriptions on component unmount
    // return () => unsubscribes.forEach((unsub) => unsub());
  }, [chats, selectedChat, user?.uid]);

  useEffect(() => {
    if (currentPatient || currentDoctor || currentStudent || currentTeacher) {
      // user && fetchMessages();
    }

    if (
      ACM[typeOfUser]?.isDoctor &&
      patientData?.length > 0 &&
      !currentPatient
    ) {
      const patientInfo = locationPatientId
        ? patientData?.find(
            (patient) => patient?.patientId === locationPatientId
          )
        : patientData[0];
      setSelectedChat(patientInfo);
      setSelectedPatient(patientInfo);
      showPatientConversation(patientInfo); // Choose the first patient by default
    } else if (ACM[typeOfUser]?.isPatient && !currentDoctor) {
      const doctorInfo = locationDoctorId
        ? user?.doctorDetails?.find(
            (doctor) => doctor?.doctorId === locationDoctorId
          )
        : user?.doctorDetails[0];
      setSelectedChat(doctorInfo); // Choose the first doctor by default
      setSelectedDoctor(doctorInfo);
      showDoctorConversation(doctorInfo);
    } else if (
      ACM[typeOfUser]?.isStudent &&
      teacherData?.length > 0 &&
      !currentTeacher
    ) {
      const teacherInfo = locationTeacherId
        ? teacherData?.find((teacher) => teacher?.id === locationTeacherId)
        : teacherData[0];
      setSelectedChat(teacherInfo);
      setSelectedTeacher(teacherInfo);
      showTeacherConversation(teacherInfo);
    } else if (ACM[typeOfUser]?.isTeacher && !currentStudent) {
      const studentInfo = locationStudentId
        ? studentData?.find((student) => student?.id === locationStudentId)
        : studentData[0];
      setSelectedChat(studentInfo);
      setSelectedStudent(studentInfo);
      showStudentConversation(studentInfo);
    }
    //  else if (groups?.length > 0) {
    //     setSelectedGroup(groups[0]);
    //     showGroupConversation(groups[0]);
    //   }
  }, [
    user,
    currentPatient,
    patientData,
    currentDoctor,
    locationPatientId,
    locationDoctorId,
    typeOfUser,

    currentStudent,
    studentData,
    currentTeacher,
    locationTeacherId,
    locationStudentId,
  ]);

  const filteredPatients = patientData?.filter((patient) =>
    patient?.patientInfo?.fullname
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
  );

  const patientsWithUnread = filteredPatients?.filter((item) => {
    // First, filter patients with unread counts
    return unreadCounts.some(
      (countItem) =>
        countItem.patientId === item.patientId && countItem.count > 0
    );
  });

  const filteredStudents = studentData?.filter((student) =>
    student?.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const patientsWithoutUnread = filteredPatients?.filter((item) => {
    // Then, filter patients without unread counts
    return !unreadCounts.some(
      (countItem) =>
        countItem.patientId === item.patientId && countItem.count > 0
    );
  });

  const studentWithUnread = filteredStudents?.filter((item) => {
    // First, filter patients with unread counts
    return unreadCounts.some(
      (countItem) => countItem.id === item.id && countItem.count > 0
    );
  });

  const studnetWithoutUnread = filteredStudents?.filter((item) => {
    // Then, filter patients without unread counts
    return !unreadCounts.some(
      (countItem) => countItem.id === item.id && countItem.count > 0
    );
  });

  const sortedFilteredPatients = patientsWithUnread?.concat(
    patientsWithoutUnread
  );

  const sortedFilteredStudnet = studentWithUnread?.concat(studnetWithoutUnread);
  const filteredDoctors = user?.doctorDetails?.filter((doctor) =>
    doctor?.fullname?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // First, filter doctors with unread countssetUnreadCountssetUnreadCounts
  const doctorsWithUnread = filteredDoctors?.filter((doc) => {
    return unreadCounts.some(
      (countItem) => countItem.doctorId === doc.doctorId && countItem.count > 0
    );
  });

  // Then, filter doctors without unread counts
  const doctorsWithoutUnread = filteredDoctors?.filter((doc) => {
    return !unreadCounts.some(
      (countItem) => countItem.doctorId === doc.doctorId && countItem.count > 0
    );
  });

  // Concatenate the two filtered arrays, with doctors having unread counts first
  const sortedFilteredDoctors = doctorsWithUnread?.concat(doctorsWithoutUnread);

  const filteredTeachers = teacherData?.filter((teacher) =>
    teacher?.name.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const teacherWithUnread = filteredTeachers?.filter((item) => {
    // First, filter patients with unread counts
    return unreadCounts.some(
      (countItem) => countItem.id === item.id && countItem.count > 0
    );
  });
  const teacherWithoutUnread = filteredTeachers?.filter((item) => {
    // Then, filter patients without unread counts
    return !unreadCounts.some(
      (countItem) => countItem.id === item.patientId && countItem.count > 0
    );
  });
  const sortedFilteredTeacher = teacherWithUnread?.concat(teacherWithoutUnread);

  useEffect(() => {
    if (scroll.current) {
      scroll.current.scrollTop = scroll.current.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    const itemStr = localStorage.getItem('session');
    if (!itemStr) {
      navigate('/login');
      return;
    }

    const item = JSON.parse(itemStr);
    const now = new Date();

    if (now.getTime() > item.expiry) {
      localStorage.removeItem('session');
      navigate('/login');
    }
  }, [navigate]);

  if (loading) {
    return <Progress />;
  }

  // const chatWithUnreadCount = unreadCounts.filter(
  //   (item) => item.count > 0
  // )?.length;
  // const groupChatWithUnreadCount = unreadCountsForGroup.filter(
  //   (item) => item.count > 0
  // )?.length;

  const capitalizeWords = (name) => {
    return name
      ?.split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  const truncateName = (name, limit = 10) => {
    const capitalized = capitalizeWords(name);
    if (capitalized?.length > limit) {
      return `${capitalized.slice(0, limit)}...`;
    }
    return capitalized;
  };

  const userTypes = {
    DOCTOR: 'Select Patient',
    PATIENT: ' Select Doctor',
    TEACHER: 'Select Student',
    STUDENT: 'Select Teacher',
  };

  const NewChatPopup = ({ label, data = [], onChange }) => {   
    return (
      <FormControl fullWidth sx={{pt:'2',width:'98%',justifyContent:'center',}}>
        <InputLabel sx={{ mt: 0.6 }}>{label}</InputLabel>
        <Select
          label={label}
          labelId='select-label'
          onChange={onChange}
          onClose={handleClose}
        >
          {data?.map((item) => (
            <MenuItem key={item.patientId} value={item}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const handleOnChatChange = (e) => {
    const { id, label, ...rest } = e.target.value;

    if (typeOfUser === 'DOCTOR') {
      setSelectedPatient(rest);
      showPatientConversation(rest);
    } else if (typeOfUser === 'PATIENT') {
      setSelectedDoctor(rest);
      showDoctorConversation(rest);
    } else if (typeOfUser === 'TEACHER') {
      setSelectedStudent({ ...rest, id });
      showStudentConversation({ ...rest, id });
    } else if (typeOfUser === 'STUDENT') {
      setSelectedTeacher({ ...rest, id });
      showTeacherConversation({ ...rest, id });
    }
    setPopupData((prev) => ({ ...prev, open: false }));
  };

  const handleChatOpen = () => {
    handleOpen();
    setPopupOpen(true);
  };

  const handleTabChange = (event, newIndex) => setTabIndex(newIndex);

  return (
    <MainLayout>
      <UnreadMessageCounts
        unreadCounts={unreadCounts}
        setUnreadCounts={setUnreadCounts}
        user={user}
        typeOfUser={typeOfUser}
        patientData={patientData}
        studentData={studentData}
        teacherData={teacherData}
        selectedChat={selectedChat}
        setUnreadCounts2={setUnreadCounts2}
      />

      <Box className='fullWidth fullHeight messaging'>
        <Grid
          container
          // spacing={0}
          sx={{
            margin: 0,
            padding: 0,
            width: '100%',
          }}
          className='message-container'
        >
          <Grid item xs={12} sm={12} md={3} lg={2} xl={2}>
            <Card
              className='message-left-panel'
              sx={{
                height: { xs: 'auto', md: 'calc(100vh - 100px)' }, // Adjust height calculation if needed
                backgroundColor: '#E9EAFF',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start', // Align items to the start
                borderRadius: '0px !important',
                padding: '8px',
                boxShadow: 'none',
              }}
            >
              <Typography
                sx={{
                  height: 'auto',
                  fontFamily: 'Lato',
                  fontSize: { xs: '0.9rem', sm: '1rem', md: '1.1rem' },
                  padding: '7px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                Your Messages
              </Typography>

              <Box
                sx={{
                  flexGrow: 1,
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  height: { xs: 'auto', md: 'calc(100vh - 300px)' },
                }}
              >
                <Accordion
                  className='accordion'
                  expanded={recentChatsExpanded}
                  onChange={() => setRecentChatsExpanded(!recentChatsExpanded)}
                  sx={{
                    width: '100%',
                    height: { xs: 'auto', md: '300px' },
                    overflowY: 'auto',
                    // height: recentChatsExpanded ? '300px' : 'auto',
                    // overflowY: recentChatsExpanded ? 'auto' : 'hidden',
                    backgroundColor: 'var(--clr-mindly-background)',
                    border: 'none',
                    boxShadow: 'none',
                    margin: '0 !important',
                    '&:before': {
                      display: 'none',
                    },
                    '&:not(:last-child)': {
                      borderBottom: 'none',
                    },
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    sx={{
                      borderTop: '2px solid lightGrey',
                      height: '40px!important',
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: '10px',
                      position: 'sticky',
                      top: 0,
                      backgroundColor: 'inherit',
                      zIndex: 1,
                      '&.MuiAccordionSummary-root': {
                        minHeight: '40px !important',
                      },
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        width: '100%',
                      }}
                    >
                      <Circle
                        sx={{
                          color: 'grey',
                          width: 8,
                          height: 8,
                          p: '1',
                          my: 1,
                        }}
                      />
                      <Typography
                        sx={{ fontFamily: 'Lato', fontWeight: 'bold', ml: 1 }}
                      >
                        Recent Chats
                      </Typography>
                    </Box>
                  </AccordionSummary>
                  <Divider sx={{ border: '1px solid lightgray' }} />
                  <AccordionDetails
                    sx={{
                      // overflowY: 'auto',
                      // maxHeight: 'calc(100vh - 80px)', // Adjust this value as needed
                      maxHeight: { xs: '300px', md: '500px' }, // Adjust this value as needed
                    }}
                  >
                    <>
                      {/* <Box>
                        <TextField
                          placeholder={
                            // typeOfUser === "DOCTOR"
                            ACM[typeOfUser]?.isDoctor 
                              ? 'Search patient'
                              : ACM[typeOfUser]?.isTeacher
                              ? 'Search student'
                              : ACM[typeOfUser]?.isStudent
                              ? 'Search Teacher'
                              : 'Search healthcare provider'
                          }
                          fullWidth
                          sx={{ borderRadius: '10px' }}
                          value={searchQuery}
                          onChange={(e) => {
                            setSearchQuery(e.target.value);
                            setErrorMessage('');
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment>
                                <IconButton aria-label='Search'>
                                  <Search />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box> */}
                      <List
                        component='div'
                        disablePadding
                        sx={{
                          overflowY: 'auto',
                          flexGrow: 1,
                          // mt: 1,
                        }}
                      >
                        {ACM[typeOfUser]?.isTeacher
                          ? // Logic for teachers
                            sortedFilteredStudnet?.map((student, i) => (
                              <ListItemButton
                                sx={{
                                  height: 35,
                                  borderRadius:
                                    selectedStudent?.id === student?.id
                                      ? '30px'
                                      : 'none',
                                  backgroundColor:
                                    selectedStudent?.id === student?.id
                                      ? 'var(--clr-theme-purple)!important'
                                      : 'none',
                                  color:
                                    selectedStudent?.id === student?.id
                                      ? 'white'
                                      : 'inherit',
                                  transition: 'all 0.3s ease',
                                }}
                                key={student.id}
                                onClick={() => {
                                  setActiveChatType('recent');
                                  setSelectedStudent(student);
                                  showStudentConversation(student);
                                  setSelectedGroup(null);
                                }}
                              >
                                <ListItemText
                                  primary={
                                    <Typography
                                      sx={{
                                        fontFamily: 'Lato',
                                        fontWeight:
                                          unreadCounts2[student?.id] > 0
                                            ? 'bold'
                                            : 'normal',
                                      }}
                                    >
                                      {student?.name}
                                    </Typography>
                                  }
                                />
                                {unreadCounts2[student?.id] && (
                                  <Chip
                                    label={
                                      unreadCounts2[student?.id] === 1
                                        ? 'New'
                                        : unreadCounts2[student?.id]
                                    }
                                    sx={{
                                      backgroundColor:
                                        'var(--clr-theme-purple)',
                                      color: 'var(--clr-theme-white)',
                                    }}
                                  />
                                )}
                              </ListItemButton>
                            ))
                          : ACM[typeOfUser]?.isDoctor
                          ? // Logic for doctors
                            sortedFilteredPatients?.map((patient, i) => (
                              <ListItemButton
                                sx={{
                                  height: 35,
                                  borderRadius:
                                    selectedPatient === patient
                                      ? '30px'
                                      : 'none',
                                  backgroundColor:
                                    selectedPatient === patient
                                      ? 'var(--clr-theme-purple)!important'
                                      : 'none',
                                  color:
                                    selectedPatient === patient
                                      ? 'white'
                                      : 'inherit',
                                }}
                                key={i}
                                onClick={() => {
                                  setActiveChatType('recent');
                                  setSelectedPatient(patient);
                                  showPatientConversation(patient);
                                  setSelectedGroup(null);
                                }}
                              >
                                <ListItemText
                                  primary={
                                    <Typography
                                      sx={{
                                        fontFamily: 'Lato',
                                        fontWeight:
                                          unreadCounts2[patient?.patientId] > 0
                                            ? 'bold'
                                            : 'none',
                                        fontSize: {
                                          xs: '0.8rem',
                                          sm: '0.9rem',
                                          md: '1rem',
                                        },
                                      }}
                                    >
                                      {`${patient.patientInfo.fullname} `}
                                    </Typography>
                                  }
                                  sx={{
                                    fontWeight:
                                      unreadCounts2[patient?.patientId] > 0
                                        ? 'bold'
                                        : 'none',
                                  }}
                                />

                                {unreadCounts2[patient?.patientId] && (
                                  <Chip
                                    label={
                                      unreadCounts2[patient?.patientId] === 1
                                        ? 'New'
                                        : unreadCounts2[patient?.patientId]
                                    }
                                    sx={{
                                      backgroundColor:
                                        'var(--clr-theme-purple)',
                                      color: 'var(--clr-theme-white)',
                                    }}
                                  />
                                )}
                              </ListItemButton>
                            ))
                          : ACM[typeOfUser]?.isStudent
                          ? sortedFilteredTeacher?.map((teacher, i) => (
                              <ListItemButton
                                sx={{
                                  height: 35,
                                  borderRadius:
                                    selectedTeacher === teacher
                                      ? '30px'
                                      : 'none',
                                  backgroundColor:
                                    selectedTeacher === teacher
                                      ? 'var(--clr-theme-purple)!important'
                                      : 'none',
                                  color:
                                    selectedTeacher === teacher
                                      ? 'white'
                                      : 'inherit',
                                  transition: 'all 0.3s ease', // Smooth transition effect
                                }}
                                key={i}
                                onClick={() => {
                                  setActiveChatType('recent');
                                  setSelectedTeacher(teacher);
                                  showTeacherConversation(teacher);
                                  setSelectedGroup(null);
                                }}
                              >
                                {/* <ListItemIcon>
                                {teacher.icon ||
                                  (teacher?.photoURL && (
                                    <Avatar
                                      alt={`Profile picture of ${teacher?.name}`}
                                      src={teacher?.photoURL}
                                    />
                                  )) || <Avatar />}
                              </ListItemIcon> */}

                                <ListItemText
                                  // primary={truncateName(doctor?.fullname)}
                                  primary={
                                    <Typography
                                      sx={{
                                        fontFamily: 'Lato',
                                        fontWeight:
                                          unreadCounts2[teacher?.id] > 0
                                            ? 'bold'
                                            : 'none',
                                      }}
                                    >
                                      {truncateName(teacher?.name)
                                        .split(' ')
                                        .map(
                                          (word) =>
                                            word.charAt(0).toUpperCase() +
                                            word.slice(1).toLowerCase()
                                        )
                                        .join(' ')}
                                    </Typography>
                                  }
                                />

                                {unreadCounts2[teacher?.id] && (
                                  <Chip
                                    label={
                                      unreadCounts2[teacher?.id] === 1
                                        ? 'New'
                                        : unreadCounts2[teacher?.id]
                                    }
                                    // color='secondary'
                                    sx={{
                                      backgroundColor:
                                        'var(--clr-theme-purple)',
                                      color: 'var(--clr-theme-white)',
                                    }}
                                  />
                                )}
                              </ListItemButton>
                            ))
                          : // Logic for patients
                            sortedFilteredDoctors?.map((doctor, i) => (
                              <ListItemButton
                                sx={{
                                  // borderBottom: '1px solid lightgray',
                                  height: 35,
                                  borderRadius:
                                    selectedDoctor === doctor ? '30px' : 'none',
                                  backgroundColor:
                                    selectedDoctor === doctor
                                      ? 'var(--clr-theme-purple)!important'
                                      : 'none',
                                  color:
                                    selectedDoctor === doctor
                                      ? 'white'
                                      : 'inherit',
                                  transition: 'all 0.3s ease', // Smooth transition effect
                                }}
                                key={i}
                                onClick={() => {
                                  setActiveChatType('recent');
                                  setSelectedDoctor(doctor);
                                  showDoctorConversation(doctor);
                                  setSelectedGroup(null);
                                }}
                              >
                                {/* <ListItemIcon>
                                {doctor.icon ||
                                  (doctor?.photoURL && (
                                    <Avatar
                                      alt={`Profile picture of ${doctor?.fullname}`}
                                      src={doctor?.photoURL}
                                    />
                                  )) || <Avatar />}
                              </ListItemIcon> */}
                                <ListItemText
                                  // primary={truncateName(doctor?.fullname)}
                                  primary={
                                    <Typography
                                      sx={{
                                        fontFamily: 'Lato',
                                        fontWeight:
                                          unreadCounts2[doctor?.doctorId] > 0
                                            ? 'bold'
                                            : 'none',
                                      }}
                                    >
                                      {truncateName(doctor?.fullname)
                                        .split(' ')
                                        .map(
                                          (word) =>
                                            word.charAt(0).toUpperCase() +
                                            word.slice(1).toLowerCase()
                                        )
                                        .join(' ')}
                                    </Typography>
                                  }
                                />

                                {unreadCounts2[doctor?.doctorId] && (
                                  <Chip
                                    label={
                                      unreadCounts2[doctor?.doctorId] === 1
                                        ? 'New'
                                        : unreadCounts2[doctor?.doctorId]
                                    }
                                    // color='secondary'
                                    sx={{
                                      backgroundColor:
                                        'var(--clr-theme-purple)',
                                      color: 'var(--clr-theme-white)',
                                    }}
                                  />
                                )}
                              </ListItemButton>
                            ))}
                      </List>
                    </>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  className='accordion-clr'
                  expanded={groupChatsExpanded}
                  onChange={() => setGroupChatsExpanded(!groupChatsExpanded)}
                  sx={{
                    width: '100%',
                    height: { xs: 'auto', md: '300px' },
                    overflowY: 'auto',
                    // height: recentChatsExpanded ? '300px' : 'auto',
                    // overflowY: recentChatsExpanded ? 'auto' : 'hidden',
                    backgroundColor: 'var(--clr-mindly-background)',
                    border: 'none',
                    boxShadow: 'none',
                    margin: '0 !important',
                    '&:before': {
                      display: 'none',
                    },
                    '&:not(:last-child)': {
                      borderBottom: 'none',
                    },
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    sx={{
                      borderTop: '2px solid lightGrey',
                      height: '40px!important',
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: '10px',
                      position: 'sticky',
                      top: 0,
                      backgroundColor: 'inherit',
                      zIndex: 1,
                      '&.MuiAccordionSummary-root': {
                        minHeight: '40px !important',
                      },
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'cener',
                        justifyContent: 'flex-start',
                        width: '100%',
                      }}
                    >
                      <Circle
                        sx={{
                          color: 'grey',
                          width: 8,
                          height: 8,
                          p: '1',
                          my: 1,
                        }}
                      />
                      <Typography
                        sx={{ fontFamily: 'Lato', fontWeight: 'bold', ml: 1 }}
                      >
                        Group Chats
                      </Typography>
                    </Box>
                  </AccordionSummary>
                  <Divider sx={{ border: '1px solid #7f8787', mb: 0.5 }} />
                  <AccordionDetails>
                    <>
                      <List>
                        {/* <Button
                        variant='contained'
                        fullWidth
                        sx={{
                          backgroundColor: 'var(--clr-theme-purple)!important',
                          color: 'var(--clr-theme-white)',
                          textTransform: 'capitalize',
                          mb: 1,
                          // border: "1px solid var(--clr-theme-purple)",
                        }}
                        startIcon={<Groups />}
                        onClick={handleClickDialogOpen}
                      >
                        Create Group
                      </Button> */}
                        {groups?.map((group) => {
                          const countItem = unreadCountsForGroup?.find(
                            (item) => item.groupId === group.groupId
                          );
                          const hasUnreadMessages = countItem?.count > 0;

                          return (
                            <ListItemButton
                              sx={{
                                height: 35,
                                borderRadius:
                                  selectedGroup === group ? '30px' : 'none',
                                backgroundColor:
                                  selectedGroup === group
                                    ? 'var(--clr-theme-purple)!important'
                                    : 'none',
                                color:
                                  selectedGroup === group ? 'white' : 'inherit',
                              }}
                              key={group.groupId}
                              onClick={() => {
                                setActiveChatType('group');
                                setSelectedGroup(group);
                                showGroupConversation(group);
                                setSelectedStudent(null);
                                setSelectedTeacher(null);
                                setSelectedDoctor(null);
                                setSelectedPatient(null);
                              }}
                            >
                              <ListItemText
                                primary={
                                  <Typography
                                    sx={{
                                      fontWeight: hasUnreadMessages
                                        ? 'bold'
                                        : 'none',
                                    }}
                                  >
                                    {group.groupName
                                      ? group.groupName
                                          .split(' ')
                                          .map(
                                            (word) =>
                                              word.charAt(0).toUpperCase() +
                                              word.slice(1).toLowerCase()
                                          )
                                          .join(' ')
                                      : ''}
                                  </Typography>
                                }
                              />

                              {unreadCountsForGroup &&
                                unreadCountsForGroup.map((countItem) => {
                                  if (countItem.groupId === group.groupId) {
                                    return (
                                      countItem.count > 0 && (
                                        <Chip
                                          key={countItem.groupId}
                                          label={
                                            countItem.count === 1
                                              ? 'New'
                                              : countItem.count
                                          }
                                          sx={{
                                            backgroundColor:
                                              'var(--clr-theme-purple)',
                                            color: 'var(--clr-theme-white)',
                                          }}
                                        />
                                      )
                                    );
                                  }
                                  return null;
                                })}
                            </ListItemButton>
                          );
                        })}
                      </List>

                      <Dialog
                        open={openDialog}
                        onClose={handleDialogClose}
                        maxWidth='lg'
                      >
                        <DialogTitle>
                          <TextField
                            fullWidth
                            label='Enter group name'
                            variant='outlined'
                            value={groupName}
                            onChange={handleGroupNameChange}
                            // sx={{ mb: 2 }}
                            error={!!errorMessage}
                            helperText={errorMessage}
                          />
                        </DialogTitle>
                        <DialogContent sx={{ width: '42vw' }}>
                          {ACM[typeOfUser]?.isTeacher
                            ? // Logic for teachers
                              studentData?.map((item, i) => (
                                <ListItemButton
                                  sx={{
                                    borderBottom: '1px solid lightgray',
                                    height: 75,
                                  }}
                                  key={i}
                                  className={
                                    selectedPatientId === item.id
                                      ? 'selected'
                                      : ''
                                  }
                                >
                                  <ListItemIcon>
                                    {item.icon ||
                                      (item?.photoURL && (
                                        <Avatar
                                          alt={`Profile picture of ${item?.name}`}
                                          src={item?.photoURL}
                                        />
                                      )) || <Avatar />}
                                  </ListItemIcon>
                                  <ListItemText
                                    primary={
                                      <BootstrapTooltip
                                        title={
                                          item?.parentName
                                            ? `${item.name} (${item.parentName})`
                                            : item.name
                                        }
                                      >
                                        <span style={{ cursor: 'pointer' }}>
                                          {' '}
                                          {/* Add a span to wrap the text */}
                                          {item?.parentName
                                            ? `${truncateName(
                                                item.name
                                              )} (${truncateName(
                                                item.parentName
                                              )})`
                                            : truncateName(item.name)}
                                        </span>
                                      </BootstrapTooltip>
                                    }
                                  />
                                  <Button
                                    onClick={() =>
                                      handlePatientCheckboxChange({
                                        id: item.id,
                                        name: item.name,
                                        parentName: item.parentName || '',
                                        photoURL: item?.photoURL,
                                        typeOfUser: 'STUDENT',
                                        // typeOfUser: ACM[typeOfUser]?.isPatient,
                                      })
                                    }
                                    variant={
                                      selectedPatients.find(
                                        (member) => item.id === member?.id
                                      )
                                        ? 'contained'
                                        : 'outlined'
                                    }
                                    sx={{
                                      bgcolor: selectedPatients.find(
                                        (member) => item.id === member?.id
                                      )
                                        ? 'green'
                                        : 'transparent',
                                      color: selectedPatients.find(
                                        (member) => item.id === member?.id
                                      )
                                        ? 'white'
                                        : 'primary.main',
                                      '&:hover': {
                                        bgcolor: selectedPatients.find(
                                          (member) => item.id === member?.id
                                        )
                                          ? 'green'
                                          : 'transparent',
                                        // Set color when hovered (optional)
                                        color: selectedPatients.find(
                                          (member) => item.id === member?.id
                                        )
                                          ? 'white'
                                          : 'primary.main',
                                      },
                                    }}
                                  >
                                    {selectedPatients.find(
                                      (member) => item.id === member?.id
                                    )
                                      ? 'Add'
                                      : 'Add'}
                                  </Button>
                                </ListItemButton>
                              ))
                            : ACM[typeOfUser]?.isDoctor
                            ? // Logic for doctors
                              patientData?.map((item, i) => (
                                <ListItemButton
                                  sx={{
                                    borderBottom: '1px solid lightgray',
                                    height: 40,
                                  }}
                                  key={i}
                                  className={
                                    selectedPatientId === item.patientId
                                      ? 'selected'
                                      : ''
                                  }
                                >
                                  <ListItemIcon>
                                    {item.icon ||
                                      (item?.patientInfo?.photoURL && (
                                        <Avatar
                                          alt={`Profile picture of ${item?.patientInfo?.fullname}`}
                                          src={item?.patientInfo?.photoURL}
                                        />
                                      )) || <Avatar />}
                                  </ListItemIcon>
                                  <ListItemText
                                    primary={
                                      <BootstrapTooltip
                                        title={
                                          item?.patientInfo?.parentName
                                            ? `${item.patientInfo.fullname} (${item.patientInfo.parentName})`
                                            : item.patientInfo.fullname
                                        }
                                      >
                                        <span style={{ cursor: 'pointer' }}>
                                          {' '}
                                          {/* Add a span to wrap the text */}
                                          {item?.patientInfo?.parentName
                                            ? `${truncateName(
                                                item.patientInfo.fullname
                                              )} (${truncateName(
                                                item.patientInfo.parentName
                                              )})`
                                            : truncateName(
                                                item.patientInfo.fullname
                                              )}
                                        </span>
                                      </BootstrapTooltip>
                                    }
                                  />
                                  <Button
                                    onClick={() =>
                                      handlePatientCheckboxChange({
                                        id: item.patientId,
                                        name: item.patientInfo?.fullname,
                                        parentName:
                                          item.patientInfo?.parentName || '',
                                        photoURL: item.patientInfo?.photoURL,
                                        typeOfUser: 'PATIENT',
                                      })
                                    }
                                    variant={
                                      selectedPatients.find(
                                        (member) =>
                                          item.patientId === member?.id
                                      )
                                        ? 'contained'
                                        : 'outlined'
                                    }
                                    sx={{
                                      bgcolor: selectedPatients.find(
                                        (member) =>
                                          item.patientId === member?.id
                                      )
                                        ? 'green'
                                        : 'transparent',
                                      color: selectedPatients.find(
                                        (member) =>
                                          item.patientId === member?.id
                                      )
                                        ? 'white'
                                        : 'primary.main',
                                      '&:hover': {
                                        bgcolor: selectedPatients.find(
                                          (member) =>
                                            item.patientId === member?.id
                                        )
                                          ? 'green'
                                          : 'transparent',
                                        // Set color when hovered (optional)
                                        color: selectedPatients.find(
                                          (member) =>
                                            item.patientId === member?.id
                                        )
                                          ? 'white'
                                          : 'primary.main',
                                      },
                                    }}
                                  >
                                    {selectedPatients.find(
                                      (member) => item.patientId === member?.id
                                    )
                                      ? 'Add'
                                      : 'Add'}
                                  </Button>
                                </ListItemButton>
                              ))
                            : ACM[typeOfUser]?.isStudent
                            ? // Logic for students

                              sortedFilteredTeacher?.map((doc, i) => {
                                return (
                                  <ListItemButton
                                    sx={{
                                      borderBottom: '1px solid lightgray',
                                      height: 75,
                                    }}
                                    key={doc?.id}
                                  >
                                    <ListItemIcon>
                                      {doc.icon ||
                                        (doc?.photoURL && (
                                          <Avatar
                                            alt={`Profile picture of ${doc?.name}`}
                                            src={doc?.photoURL}
                                          />
                                        )) || <Avatar />}
                                    </ListItemIcon>
                                    <ListItemText
                                      primary={
                                        doc?.name
                                          ? `${doc?.name
                                              ?.split(/\s+/)
                                              .map(
                                                (word) =>
                                                  word.charAt(0).toUpperCase() +
                                                  word.slice(1).toLowerCase()
                                              )
                                              .join(' ')}`
                                          : ''
                                      }
                                    />
                                    <Button
                                      onClick={() => {
                                        handlePatientCheckboxChange({
                                          id: doc.id,
                                          name: doc?.name,
                                          photoURL: doc?.photoURL,
                                          typeOfUser: 'TEACHER',
                                        });
                                      }}
                                      variant={
                                        selectedPatients.find(
                                          (member) => doc.id === member?.id
                                        )
                                          ? 'contained'
                                          : 'outlined'
                                      }
                                      sx={{
                                        bgcolor: selectedPatients.find(
                                          (member) => doc.id === member?.id
                                        )
                                          ? 'green'
                                          : 'transparent',
                                        color: selectedPatients.find(
                                          (member) => doc.id === member?.id
                                        )
                                          ? 'white'
                                          : 'primary.main',
                                        '&:hover': {
                                          bgcolor: selectedPatients.find(
                                            (member) => doc.id === member?.id
                                          )
                                            ? 'green'
                                            : 'transparent',
                                          color: selectedPatients.find(
                                            (member) => doc.id === member?.id
                                          )
                                            ? 'white'
                                            : 'primary.main',
                                        },
                                      }}
                                    >
                                      {selectedPatients.find(
                                        (member) => doc.id === member?.id
                                      )
                                        ? 'Add'
                                        : 'Add'}
                                    </Button>
                                  </ListItemButton>
                                );
                              })
                            : // Logic for patients
                              sortedFilteredDoctors?.map((doc, i) => {
                                return (
                                  <ListItemButton
                                    sx={{
                                      borderBottom: '1px solid lightgray',
                                      height: 75,
                                    }}
                                    key={doc?.doctorId}
                                  >
                                    <ListItemIcon>
                                      {doc.icon ||
                                        (doc?.photoURL && (
                                          <Avatar
                                            alt={`Profile picture of ${doc?.fullname}`}
                                            src={doc?.photoURL}
                                          />
                                        )) || <Avatar />}
                                    </ListItemIcon>
                                    <ListItemText
                                      primary={
                                        doc?.fullname
                                          ? `${doc?.fullname
                                              .split(/\s+/)
                                              .map(
                                                (word) =>
                                                  word.charAt(0).toUpperCase() +
                                                  word.slice(1).toLowerCase()
                                              )
                                              .join(' ')}`
                                          : ''
                                      }
                                    />
                                    <Button
                                      onClick={() => {
                                        handlePatientCheckboxChange({
                                          id: doc.doctorId,
                                          name: doc?.fullname,
                                          photoURL: doc?.photoURL,
                                          typeOfUser: 'DOCTOR',
                                        });
                                      }}
                                      variant={
                                        selectedPatients.find(
                                          (member) =>
                                            doc.doctorId === member?.id
                                        )
                                          ? 'contained'
                                          : 'outlined'
                                      }
                                      sx={{
                                        bgcolor: selectedPatients.find(
                                          (member) =>
                                            doc.doctorId === member?.id
                                        )
                                          ? 'green'
                                          : 'transparent',
                                        color: selectedPatients.find(
                                          (member) =>
                                            doc.doctorId === member?.id
                                        )
                                          ? 'white'
                                          : 'primary.main',
                                        '&:hover': {
                                          bgcolor: selectedPatients.find(
                                            (member) =>
                                              doc.patientId === member?.id
                                          )
                                            ? 'green'
                                            : 'transparent',
                                          // Set color when hovered (optional)
                                          color: selectedPatients.find(
                                            (member) =>
                                              doc.patientId === member?.id
                                          )
                                            ? 'white'
                                            : 'primary.main',
                                        },
                                      }}
                                    >
                                      {selectedPatients.find(
                                        (member) => doc.doctorId === member?.id
                                      )
                                        ? 'Add'
                                        : 'Add'}
                                    </Button>
                                  </ListItemButton>
                                );
                              })}
                        </DialogContent>
                        <DialogActions>
                          <Button
                            onClick={handleDialogClose}
                            sx={{
                              color: 'var(--clr-theme-purple)!important',
                              textTransform: 'capitalize',
                              fontFamily: 'Lato',
                              fontSize: '1rem',
                            }}
                          >
                            Cancel
                          </Button>

                          <Button
                            onClick={handleCreateGroup}
                            variant='contained'
                            color='primary'
                            sx={{
                              backgroundColor:
                                'var(--clr-theme-purple)!important',
                              textTransform: 'capitalize',
                              fontFamily: 'Lato',
                              fontSize: '1rem',
                            }}
                          >
                            Create Group
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </>
                  </AccordionDetails>
                </Accordion>
                <Divider sx={{ border: '1px solid #7f8787', mb: 0.5 }} />
              </Box>
              <Box
                sx={{
                  width: '100%',
                  textAlign: 'center',
                  paddingBottom: '10px',
                }}
              >
                <Button
                  fullWidth
                  variant='contained'
                  startIcon={<Edit />}
                  sx={{
                    backgroundColor: 'var(--clr-theme-purple)!important',
                    width: { xs: '90%', sm: '75%' },
                    fontFamily: 'Lato',
                    fontSize: { xs: '0.8rem', sm: '0.9rem' },
                    alignItems: 'center',
                    justifyContent: 'center',
                    textTransform: 'capitalize',
                    fontWeight: '600',
                    margin: '0 auto',
                    whiteSpace: 'nowrap',
                  }}
                  onClick={handleChatOpen}
                >
                  New Message
                </Button>
                <Dialog
                  open={popupData.open}
                  onClose={handleClose}
                  fullWidth                 
                >
                  <Tabs value={tabIndex} onChange={handleTabChange} centered>
                    <Tab label='New Chat' />
                    <Tab label='New Group Chat' />
                  </Tabs>                 
                  {tabIndex === 0 && (
                    <Box sx={{padding:3}}>
                    <NewChatPopup
                      label={popupData.label}
                      data={popupData.data}
                      onChange={handleOnChatChange}
                    />
                    </Box>
                  )}

                  {tabIndex === 1 && (
                    <Box sx={{ padding: 3, textAlign: 'center' }}>
                      <Typography variant='h6'>
                        Create a New Group Chat
                      </Typography>
                      <Button
                        variant='contained'
                        sx={{
                          marginTop: 2,
                          backgroundColor: 'var(--clr-theme-purple)',
                        }}
                        onClick={handleClickDialogOpen}
                      >
                        Create Group
                      </Button>
                    </Box>
                  )}
                </Dialog>
              </Box>
              {/* </CardContent> */}
            </Card>
          </Grid>

          <Grid item xs={12} sm={12} md={9} lg={10} xl={10}>
            <Box
              className='message-right-panel'
              sx={{
                borderRadius: '0px !important',
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: 'white',
                overflow: 'hidden',
                // padding: { xs: '8px', sm: '12px', md: '16px' },  // Adjusts padding per screen size
              }}
            >
              {ACM[typeOfUser]?.isTeacher || ACM[typeOfUser]?.isStudent ? (
                <Box sx={{ height: '100%' }}>
                  <Conversations
                    onChatNameUpdate={(newName) =>
                      setSelectedChat({ ...selectedChat, groupName: newName })
                    }
                    unreadCounts={unreadCounts}
                    selectedChat={selectedChat}
                    unreadCountsForGroup={unreadCountsForGroup}
                    key={selectedChat?.id || selectedChat?.groupId || ''}
                    chatId={selectedChat?.id || selectedChat?.groupId || ''}
                    chatPhotoURL={selectedChat?.photoURL || null}
                    chatName={
                      selectedChat?.parentName
                        ? `${selectedChat?.name} (${selectedChat?.parentName})`
                        : selectedChat?.name || selectedChat?.groupName
                    }
                    isGroupChat={Boolean(selectedChat?.groupName)}
                    chatUserInfo={selectedChat}
                    navigateProfile={goToStudent}
                  />
                </Box>
              ) : (
                <Box sx={{ height: '100%' }}>
                  <Conversations
                    onChatNameUpdate={(newName) =>
                      setSelectedChat({ ...selectedChat, groupName: newName })
                    }
                    unreadCounts={unreadCounts}
                    selectedChat={selectedChat}
                    unreadCountsForGroup={unreadCountsForGroup}
                    key={
                      selectedChat?.patientId ||
                      selectedChat?.groupId ||
                      selectedChat?.doctorId
                    }
                    chatId={
                      selectedChat?.patientId ||
                      selectedChat?.groupId ||
                      selectedChat?.doctorId
                    }
                    chatPhotoURL={selectedChat?.patientInfo?.photoURL}
                    chatName={
                      selectedChat?.patientInfo?.parentName
                        ? `${selectedChat?.patientInfo.fullname
                            .trim()
                            .split(/\s+/)
                            .map(
                              (word) =>
                                word.charAt(0).toUpperCase() +
                                word.slice(1).toLowerCase()
                            )} `
                        : selectedChat?.patientInfo?.fullname ||
                          selectedChat?.groupName ||
                          selectedChat?.fullname
                    }
                    isGroupChat={selectedChat?.groupName ? true : false}
                    chatUserInfo={selectedChat}
                    navigateProfile={goToPatient}
                  />
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </MainLayout>
  );
};

export default Message;
