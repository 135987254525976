import GoogleConfig from './config.json';

export function initClient(gapi, callback) {
  gapi.load("client:auth2", () => {
    try {
      gapi.client
        .init({
          apiKey: GoogleConfig.apiKey,
          clientId: GoogleConfig.clientId,
          discoveryDocs: GoogleConfig.discoveryDocs,
          scope: GoogleConfig.scope,
          // ux_mode: "redirect",
        })
        .then(
          function () {
            if (typeof callback === "function") {
              callback(true);
            }
          },
          function (error) {
            console.log(error);
          }
        );
    } catch (error) {
      console.log(error);
    }
  });
}

export const checkSignInStatus = async (gapi) => {
  try {
    let status = await gapi.auth2.getAuthInstance().isSignedIn.get();
    return status;
  } catch (error) {
    console.log(error);
  }
};

export const signInToGoogle = async (gapi) => {
  try {
    var auth2 = gapi.auth2.getAuthInstance();
    let googleuser = await auth2.signIn({ prompt: "consent" });
    if (googleuser) {
      return true;
    }
  } catch (error) {
    console.log(error);
  }
};

export const signOutFromGoogle = (gapi) => {
  try {
    var auth2 = gapi.auth2.getAuthInstance();
    auth2.signOut().then(function () {
      auth2.disconnect();
    });
    return true;
  } catch (error) {
    console.log(error);
  }
};

export const getSignedInUserEmail = async (gapi) => {
  try {
    let status = await checkSignInStatus(gapi);
    if (status) {
      var auth2 = gapi.auth2.getAuthInstance();
      var profile = auth2.currentUser.get().getBasicProfile();
      return profile.getEmail();
    } else {
      return null;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getCalendarEvents = async (gapi, callback) => {
  try {
    gapi.client.load("calendar", "v3", () => {
      var request = gapi.client.calendar.events.list({
        calendarId: "primary",
        timeMin: new Date().toISOString(),
        showDeleted: false,
        singleEvents: true,
        maxResults: 50,
        orderBy: "startTime",
      });

      request.execute(function (event) {
        callback(event.result);
      });
    });
  } catch (error) {
    console.log(error);
  }
};
