import React, { useContext, useEffect, useState, useCallback } from "react";
import { Navigate, useNavigate } from "react-router-dom";

import AuthContext from "../contexts/AuthContext";
import DataProvider from "../contexts/DataProvider";

const PrivateRoute = ({ children }) => {
  const { isUserAuthenticated, logout } = useContext(AuthContext);
  const [initialNavigation, setInitialNavigation] = useState(false);
  const navigate = useNavigate();

  const validateSession = useCallback(() => {
    const itemStr = localStorage.getItem("session");
    if (!itemStr) {
      return false;
    }
    const item = JSON.parse(itemStr);
    const now = new Date();
    if (now.getTime() > item.expiry) {
      localStorage.removeItem("session");
      logout(); // Clear session if expired
      return false;
    }
    return true;
  }, [logout]);

  useEffect(() => {
    if (!initialNavigation) {
      if (
        !isUserAuthenticated &&
        !validateSession()
      ) {
        // If the user is authenticated and the path is "/", redirect to homepage
        navigate("/main");
      }
      setInitialNavigation(true);
    }
  }, [initialNavigation, navigate, isUserAuthenticated, validateSession]);

  return isUserAuthenticated || validateSession() ? (
    <>
      <DataProvider>{children}</DataProvider>
    </>
  ) : (
    <>
      <Navigate to={"/main"} />
    </>
  );
};

export default PrivateRoute;
