import { useQuery } from '@tanstack/react-query';
import { fetchStudentData } from '../api';

const useAllStudentGoalsData = (uid, typeOfUser) => {
  return useQuery({
    queryKey: ['fetchStudentData', uid, typeOfUser],
    queryFn: async () => {
      if (!uid || !typeOfUser) return null;

      const fetchedData = await fetchStudentData(uid, typeOfUser);
      return fetchedData || null;
    },
    staleTime: 60000, // 1 min
    cacheTime: 300000, // 5 min
  });
};

export default useAllStudentGoalsData;