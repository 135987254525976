import { Delete, Edit } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  Chip,
  FormControl,
  IconButton,
  Switch,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Calendar from 'react-calendar';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import CustomButton from '../CustomButton';
import { deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { FirestoreDB } from '../../utils/firebase/firebase';
import Progress from '../Progress';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import dayjs from 'dayjs';

const ITEM_HEIGHT = 82;
const ITEM_PADDING_TOP = 8;

var currentDate = new Date();

var formattedDateTime = formatTime(currentDate);
function pad(n) {
  return n < 10 ? '0' + n : n;
}

function formatTime(date) {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = hours % 12 || 12; // Convert 0 to 12 for AM/PM format
  return `${pad(formattedHours)}:${pad(minutes)} ${ampm}`;
}

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const Allreminders = ({
  allReminders,
  selectedPatient,
  patientData,
  fetchReminders,
  loading,
}) => {
  const [textReminder, setTextReminder] = useState('');
  const [filteredReminders, setFilteredReminders] = useState(
    selectedPatient
      ? allReminders.filter(
          (reminder) => reminder.id === selectedPatient
        )
      : allReminders
  );
  const [editOpen, setEditOpen] = useState(false);
  const [selectedReminder, setSelectedReminder] = useState('');

  const [currentPatientId, setCurrentPatientId] = useState('');

  const ChangeHandler = () => {
    setEditOpen(true);
  };

  const handleSelectedReminder = (reminderInfo, patientId) => {
    setSelectedReminder(reminderInfo, patientId);
    setCurrentPatientId(patientId);
  };

  const deleteReminderInFirebase = async (reminderId, patientId) => {
    try {
      await deleteDoc(
        doc(FirestoreDB, 'notifications', patientId, 'reminders', reminderId)
      );
      await fetchReminders();
      console.log('delete successful');
    } catch (error) {
      console.log('Error deleting document:', error);
    }
  };

  useEffect(() => {
    setFilteredReminders(
      selectedPatient
        ? allReminders?.filter(
            // (reminder) => reminder.id === (selectedPatient || currentPatientId)
            (reminder) => reminder.id === (selectedPatient || currentPatientId)
          )
        : allReminders
    );
  }, [allReminders]);

  const updateReminderActiveHandler = async (
    reminderId,
    patientId,
    checked
  ) => {
    await updateDoc(
      doc(FirestoreDB, 'notifications', patientId, 'reminders', reminderId),
      {
        active: checked,
      }
    );
    return true;
  };

  const handleActiveReminderChange = (reminderId, patientId, checked) => {
    const tempReminders = [...filteredReminders];
    const patientReminders = tempReminders.find(
      (patientData) => patientData?.id === patientId
    );
    const reminder = patientReminders?.reminders?.find(
      (reminder) => reminder.id === reminderId
    );
    reminder['active'] = checked;
    updateReminderActiveHandler(reminderId, patientId, checked);
    setFilteredReminders(tempReminders);
    // console.log(" at active change : ", tempReminders);
  };
  // console.log("filter reminders", filteredReminders);

  const areRemindersEmpty = filteredReminders?.every(
    (reminder) => reminder?.reminders?.length === 0
  );
  if (loading) {
    return (
      <div className='flexBox flexColumn flexCenter' style={{ height: '50vh' }}>
        <div className='loader' />
      </div>
    );
  }

  return (
    <div className='fullWidth fullHeight flexBox flexColumn' style={{ gap: 5 }}>
      {!editOpen &&
        (areRemindersEmpty ? (
          <div
            className='flexBox flexColumn flexCenter'
            style={{ height: '50vh' }}
          >
            <Typography variant='h6' sx={{ fontFamily: 'Lato' }}>
              No reminders are available at the moment.
            </Typography>
          </div>
        ) : (
          filteredReminders?.map(
            (reminder) =>
              reminder.reminders.length > 0 &&
              reminder.reminders.map((data) => (
                <Box
                  key={`${reminder.patientId}-${data.id}`} // Ensure unique key
                  className='flexBox'
                  sx={{
                    alignItems: 'center',
                    border: '1px solid gray',
                    p: 2,
                    borderRadius: 3,
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                  }}
                >
                  <Box
                    className='flexBox flexColumn flexWrap'
                    sx={{
                      gap: 2,
                      width: '70%',
                    }}
                  >
                    <Box
                      className='flexBox flexColumn flexWrap'
                      sx={{ alignItems: 'start', gap: 3 }}
                    >
                      <Box className='flexBox' gap={2} alignItems={'center'}>
                        <Avatar src={reminder.patientPhotoURL} />
                        <Typography
                          variant='h6'
                          sx={{
                            fontFamily: 'Lato',
                            textTransform: 'capitalize',
                          }}
                        >
                          {reminder.name}
                        </Typography>
                      </Box>
                      {data?.message && (
                        <Typography
                          sx={{
                            fontFamily: 'Lato',
                            fontWeight: 'bold',
                            backgroundColor: '#9291f0',
                            color: '#fff',
                            p: 2,
                            borderRadius: 3,
                          }}
                        >
                          {data.message}
                        </Typography>
                      )}
                    </Box>
                    <Box className='flexBox flexWrap' sx={{ gap: 2 }}>
                      <>
                        {data?.dates.map((item, index) => (
                          <Chip
                            key={`date-${index}`}
                            label={
                              typeof item === 'object' ? item.toString() : item
                            }
                            color='primary'
                            variant='outlined'
                          />
                        ))}
                        {data?.times.map((item, index) => (
                          <Chip
                            key={`time-${index}`}
                            label={
                              typeof item === 'object' ? item.toString() : item
                            }
                            color='success'
                            variant='outlined'
                          />
                        ))}
                      </>
                    </Box>
                  </Box>
                  <Box className='flexBox' gap={1} alignSelf={'center'}>
                    <LightTooltip title='Edit'>
                      <IconButton
                        onClick={() => {
                          ChangeHandler();
                          handleSelectedReminder(data, reminder?.id);
                        }}
                      >
                        <Edit color='primary' />
                      </IconButton>
                    </LightTooltip>
                    <LightTooltip title='Delete'>
                      <IconButton
                        onClick={() => {
                          deleteReminderInFirebase(
                            data?.id,
                            reminder.patientId
                          );
                          handleSelectedReminder(data, reminder.patientId);
                        }}
                      >
                        <Delete color='error' />
                      </IconButton>
                    </LightTooltip>
                    <LightTooltip title={data.active ? 'Active' : 'Inactive'}>
                      <Switch
                        color='success'
                        checked={data.active}
                        onChange={(e) => {
                          console.log(' at checked', e.target.checked);
                          handleActiveReminderChange(
                            data?.id,
                            reminder.patientId,
                            e.target.checked
                          );
                        }}
                      />
                    </LightTooltip>
                  </Box>
                </Box>
              ))
          )
        ))}

      {editOpen && (
        <EditReminder
          reminderInfo={selectedReminder}
          setEditOpen={setEditOpen}
          fetchReminders={fetchReminders}
          patientData={patientData?.find(
            (patient) => patient?.id === (selectedPatient || currentPatientId)
          )}
        />
      )}
    </div>
  );
};

export default Allreminders;

const EditReminder = ({
  patientData,
  reminderInfo,
  setEditOpen,
  fetchReminders,
}) => {
  const [reminderData, setReminderData] = useState(reminderInfo);
  const [loading, setLoading] = useState(false);
  const [textReminder, setTextReminder] = useState('');

  const handleChangeReminder = (event) => {
    const { name, value } = event.target;
    console.log(' name , value ', event, name, value);
    setReminderData({ ...reminderData, [name]: value });
  };

  const handleTextChangeHandler = (e) => {
    setTextReminder(e.target.value);
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 to month because months are zero-indexed
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const handleDateChange = (date) => {
    const formattedDate = formatDate(date);
    const index = reminderData?.dates?.indexOf(formattedDate);
    if (index === -1) {
      setReminderData({
        ...reminderData,
        dates: [...reminderData?.dates, formattedDate],
      });
    } else {
      const updatedDates = reminderData?.dates?.filter(
        (d) => d !== formattedDate
      );
      setReminderData({
        ...reminderData,
        dates: updatedDates,
      });
    }
    console.log(reminderData?.dates, 'reminderData');
  };
  const isSameDay = (date1, date2) => {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  };
  const addTimePicker = () => {
    const currentTime = new Date();
    const formattedTime = formatTime(currentTime);
    setReminderData({
      ...reminderData,
      times: [...reminderData?.times, formattedTime],
    });
  };

  const deleteTimePicker = (indexToDelete) => {
    const time = [...reminderData?.times];
    const updatedTimes = time.filter((_, index) => index !== indexToDelete);
    setReminderData({ ...reminderData, times: updatedTimes });
  };

  const updateTime = (index, newTime) => {
    const formattedNewTime = formatTime(new Date(newTime));
    const updatedTimes = [...reminderData?.times];
    updatedTimes[index] = formattedNewTime;
    setReminderData({ ...reminderData, times: updatedTimes });
  };

  const formatTime = (date) => {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12; // Convert 0 to 12 for AM/PM format
    return `${formattedHours}:${pad(minutes)} ${ampm}`;
  };

  function pad(n) {
    return n < 10 ? '0' + n : n;
  }

  const updateReminderHandler = async () => {
    try {
      setLoading(true);
      const reminderDocRef = doc(
        FirestoreDB,
        'notifications',
        patientData?.id,
        'reminders',
        reminderData?.id
      );

      // Update the document with the new reminderData
      await updateDoc(reminderDocRef, {
        ...reminderData,
      });

      // Close the edit mode
      setEditOpen(false);
      await fetchReminders();

      // Log success message
      console.log('Reminder updated successfully');
    } catch (error) {
      // Log and handle errors
      console.error('Error updating reminder:', error);
      // Optionally, you can display an error message to the user
      // setError("Failed to update reminder. Please try again later.");
    } finally {
      // Set loading state to false after update operation completes (success or error)
      setLoading(false);
    }
  };

  const deleteDatePicker = (indexToDelete) => {
    const updatedDates = reminderData?.dates.filter(
      (_, index) => index !== indexToDelete
    );
    setReminderData({ ...reminderData, dates: updatedDates });
  };

  return (
    <>
      <Box
        className='fullWidth fullHeight flexBox flexColumn Reminders'
        gap={2}
        sx={{
          border: '1px solid',
          p: 3,
          backgroundColor: '#e4e4e4',
          borderRadius: 1,
        }}
      >
        {loading && <Progress />}
        <>
          <Box>
            <Typography variant='h6' gutterBottom>
              Create Reminder
            </Typography>
            <TextField
              value={reminderData?.message}
              name='message'
              onChange={handleChangeReminder}
              fullWidth
              sx={{ backgroundColor: '#fff' }}
            />
          </Box>

          <Box>
            <FormControl fullWidth>
              <Typography variant='h6' gutterBottom>
                Select Patient
              </Typography>
              <TextField value={patientData?.fullname || patientData?.name} />
            </FormControl>
          </Box>
          <Box>
            <Typography gutterBottom>Select Date/Time</Typography>
            <Calendar
              value={reminderData?.dates}
              onChange={handleDateChange}
              tileClassName={({ date }) =>
                reminderData?.dates.some((selectedDate) =>
                  isSameDay(new Date(selectedDate), date)
                )
                  ? 'highlight'
                  : null
              }
            />
            <div>
              Selected Dates:
              <ul>
                {reminderData?.dates?.map((date, index) => (
                  <li key={index}>
                    {date}
                    <IconButton onClick={() => deleteDatePicker(index)}>
                      <DeleteIcon color='error' />
                    </IconButton>
                  </li>
                ))}
              </ul>
            </div>
          </Box>
          <Box>
            <Typography gutterBottom>Select Time</Typography>
            {reminderData?.times?.map((time, index) => (
              <Box key={index} mb={1}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    value={dayjs(time, 'HH:mm A')}
                    onChange={(val) => updateTime(index, val)}
                    renderInput={(params) => (
                      <TextField
                        value={time}
                        onChange={handleTextChangeHandler}
                        {...params}
                        InputLabelProps={{ style: { color: 'gray' } }}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor: 'gray', // Change border color to gray
                            },
                            '& .MuiInputLabel-root': {
                              color: 'gray', // Change label color to gray
                            },
                            '& .MuiOutlinedInput-input': {
                              color: 'gray', // Change input text color to gray
                            },
                          },
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>

                <IconButton onClick={() => deleteTimePicker(index)}>
                  <Delete color='error' />
                </IconButton>
              </Box>
            ))}

            <Button
              variant='contained'
              onClick={addTimePicker}
              sx={{ mt: -1 }}
              color='secondary'
            >
              Add
            </Button>
          </Box>
        </>
      </Box>
      <Box className='flexBox flexColumn' sx={{ width: '100%', gap: 2, mt: 2 }}>
        <CustomButton autoFocus ChangeHandler={updateReminderHandler}>
          Set Reminders
        </CustomButton>
      </Box>
    </>
  );
};
