import React, { useContext, useEffect, useState, useRef } from 'react';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Paper,
  TextField,
  Typography,
  Card,
  CardContent,
  CardHeader,
  Fab,
} from '@mui/material';

import UpdateTreatments from '../../UpdateTreatments';
import Treatment from './Treatment';
import Behaviour from './Behaviour';
import Goals from './Goals';
import {
  FirestoreDB,
  LogAnalyticsEvent,
} from '../../../utils/firebase/firebase';
import { UIContext } from '../../../contexts/UIProvider';
import AuthContext from '../../../contexts/AuthContext';

import CustomButton from '../../CustomButton';
import { ACM } from '../../../constants/UserRoles';

import './VideoFeedback.css';

import { ToastContainer, toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import {
  BEHAVIOUR_TEMPLATE,
  TREATMENT_TEMPLATE,
  GOALS_TEMPLATE,
} from '../../../constants/AppConstants';
import { Add } from '@mui/icons-material';
import { doc, setDoc, Timestamp } from 'firebase/firestore';

import useOnePatientData from '../../../hooks/useOnePatientData';
import SymptomRating from './Rating';
import { ANALYTICS_KEYS } from '../../../constants/AnalyticKeys';
import useAllPatientsVideos from '../../../hooks/useAllPatientVideos';
import useOnePatientVideos from '../../../hooks/useOnePatientVideos';
import { useSymptoms } from '../../../hooks/useSymptoms';
import { useBehaviors } from '../../../hooks/useBehaviors';

const VideoFeedback = ({ patientID, videoDetails }) => {
  const { setOpenRightPanel, setPanelContent, user, typeOfUser } =
    useContext(AuthContext);
  const {
    data: currentPatientData = [],
    isLoading: isLoading,
    error: errorPatientData,
  } = useOnePatientData(patientID);

  const [treatments, setTreatments] = useState([]);
  const [behaviours, setBehaviours] = useState([]);
  const [goals, setGoals] = useState([]);
  const [notes, setNotes] = useState('');
  const [Private, setPrivate] = useState(true);
  const [medAndTherapies, setMedTherapies] = useState({
    treatment_name: [],
    meds: [],
    therapies: [],
  });
  const { bottomDrawer, setBottomDrawer } = useContext(UIContext);
  const [selectedBehaviorCount, setSelectedBehaviorCount] = useState(0);
  const initialTreatmentID = useRef(uuidv4());
  const initialBehaviourID = useRef(uuidv4());

  const {
    data: allPatientsAllVideos,
    isLoading: loadingfetchAllPatientsAllVideos,
    error: errorDoctor,
    refetch: refetchAllPatientsVideos,
  } = useAllPatientsVideos(user?.uid);

  const {
    data: currentPatientVideos,
    isLoading: loadingfetchOnePatientAllVideos,
    error: errorPatient,
    refetch: refetchOnePatientVideos,
  } = useOnePatientVideos(user?.uid);

  const UpdateTreatmentHandler = () => {
    setPanelContent(<UpdateTreatments patientID={patientID} />);
    setOpenRightPanel(true);
  };

  const { data: symptoms } = useSymptoms();
  const { data: behavioursName } = useBehaviors();

  useEffect(() => {
    const medsAndTherapies = transformPatientData(currentPatientData?.data);
    setMedTherapies(medsAndTherapies);
  }, [currentPatientData]);

  function transformPatientData(patientData) {
    const result = {
      meds: [],
      therapies: [],
      treatment_name: [],
    };

    patientData?.forEach((patient) => {
      if (patient.meds && patient.meds.length > 0) {
        patient.meds.forEach((med) => {
          result.meds.push({
            tradeName: med.tradeName || '',
            dosage: med.dosage || 0,
            usage: med.usage || '',
            drug_name: med.drug_name || '',
            range: med.range || '',
            treats: med.treats || '',
            sideEffects: med.sideEffects || '',
          });
        });
      }

      if (patient.therapies && patient.therapies.length > 0) {
        patient.therapies.forEach((therapy) => {
          result.therapies.push({
            costPerHours: therapy.costPerHours || '',
            therapy_name: therapy.therapy_name || '',
            sessionsPerWeek: therapy.sessionsPerWeek || '',
            hoursPerSession: therapy.hoursPerSession || '',
          });
        });
      }

      if (patient.treatment_name) {
        result.treatment_name.push(patient.treatment_name);
      }
    });

    return result;
  }

  const updateTreatments = (dataList, type, treatmentID) => {
    const myTreatments = [...treatments];
    if (type === 'Therapy') {
      myTreatments.find((x) => x.id === treatmentID).therapies = dataList;
    } else if (type === 'Meds') {
      myTreatments.find((x) => x.id === treatmentID).meds = dataList;
    } else if (type === 'Symptom') {
      myTreatments.find((x) => x.id === treatmentID).symptom = dataList;
    } else if (type === 'Diagnosis') {
      myTreatments.find((x) => x.id === treatmentID).diagnosis = dataList;
    } else if (type === 'Behaviour') {
      myTreatments.find((x) => x.id === treatmentID).behaviour = dataList;
    } else {
      myTreatments.find((x) => x.id === treatmentID).rating = dataList;
    }

    setTreatments(myTreatments);
  };

  const updateBehaviours = (dataList, type, behaviourID) => {
    const myBehaviours = [...behaviours];

    if (type === 'Behaviour') {
      myBehaviours.find((x) => x.id === behaviourID).behaviour = dataList;
    } else if (type === 'behaviourFrequencyCount') {
      myBehaviours.find((x) => x.id === behaviourID).behaviourFrequencyCount =
        dataList;
    } else if (type === 'Antecedents') {
      myBehaviours.find((x) => x.id === behaviourID).antecedents = dataList;
    } else if (type === 'Consequences') {
      myBehaviours.find((x) => x.id === behaviourID).consequences = dataList;
    } else if (type === 'rating') {
      console.log('190 HERE', behaviourID);
      myBehaviours.find((x) => x.id === behaviourID).behavior_rating = dataList;
    }

    setBehaviours(myBehaviours);
  };

  const updateGoals = (dataList, type, behaviourID) => {
    const myGoals = [...goals];
    console.log(dataList, 'dataList');
    if (type === 'behaviour') {
      myGoals.find((x) => x.id === behaviourID).behaviour = dataList;
    } else if (type === 'GoalsDescription') {
      myGoals.find((x) => x.id === behaviourID).GoalsDescription = dataList;
    } else if (type === 'BaselineDescription') {
      myGoals.find((x) => x.id === behaviourID).BaselineDescription = dataList;
    }
    setGoals(myGoals);
  };

  console.log(behaviours, 'updated behaviours');
  console.log(goals, 'upadted goals');

  const handleAddTreatment = () => {
    setTreatments([...treatments, { ...TREATMENT_TEMPLATE, id: uuidv4() }]);
    console.log('210', treatments);
  };

  const handleAddBehaviour = () => {
    setBehaviours([...behaviours, { ...BEHAVIOUR_TEMPLATE, id: uuidv4() }]);
  };

  const deleteTreatment = (treatmentID) => {
    setTreatments([...treatments.filter((x) => x.id !== treatmentID)]);
    console.log('219', treatments);
  };

  const deleteBehaviour = (behaviourID) => {
    setBehaviours([...behaviours.filter((x) => x.id !== behaviourID)]);
  };

  useEffect(() => {
    setTreatments([{ ...initial_treatment, id: initialTreatmentID.current }]);
    setBehaviours([{ ...initial_behavior, id: initialBehaviourID.current }]);
    setGoals([{ ...GOALS_TEMPLATE, id: uuidv4() }]);
  }, [patientID]);

  const addNewSymptomToSymptomsCollection = async (otherSymptom) => {
    if (!otherSymptom) return;

    try {
      const symptomDocRef = doc(FirestoreDB, 'symptoms', otherSymptom);
      await setDoc(symptomDocRef, {}, { merge: true });
    } catch (error) {
      console.error(
        "Error adding new symptom to the 'symptoms' collection:",
        error
      );
    }
  };

  const addNewBehaviourToBehaviourCollection = async (otherBehaviour) => {
    if (!otherBehaviour) return;

    try {
      const behaviourDocRef = doc(FirestoreDB, 'behaviour', otherBehaviour);
      await setDoc(behaviourDocRef, {}, { merge: true });
    } catch (error) {
      console.error('Error adding new behaviour:', error);
    }
  };

  const UpdateTreatmentstoVideo = async () => {
    if (validatingFeedback()) {
      try {
        for (let i = 0; i < treatments.length; i++) {
          const temp_symptom = treatments?.[i]?.symptom;
          const temp_behaviour = behaviours?.[i]?.behaviour;

          if (temp_symptom)
            await addNewSymptomToSymptomsCollection(temp_symptom); //if other symptom add to collection
          if (temp_behaviour)
            await addNewBehaviourToBehaviourCollection(temp_behaviour); //if other behaviour add to collection

          const result = await setDoc(
            doc(
              FirestoreDB,
              'videoLogs',
              patientID,
              'docNotes',
              videoDetails?.videoId
            ),
            {
              treatments: btoa(JSON.stringify(treatments)),
              behaviours: btoa(JSON.stringify(behaviours)),
              time: Timestamp.now(),
              notes: btoa(notes),
              Private: Private,
              userName: user?.fullname,
              userType: typeOfUser,
            }
          );
          setBottomDrawer({ ...bottomDrawer, show: false });
        }
        LogAnalyticsEvent(ANALYTICS_KEYS.FEEDBACK.VIDEOFEEDBACK, {
          eventName: 'Video Review',
          message: 'Feedback updated successfully',
          videoID: videoDetails?.videoId,
        });

        if (ACM[typeOfUser]?.isDoctor) refetchAllPatientsVideos();
        else refetchOnePatientVideos();
        toast.success('Feedback updated successfully', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } catch (error) {
        console.log(error, 'error while updating feedback', toast.POSITION);
        toast.error(error.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    }
  };

  const validatingFeedback = () => {
    for (let i = 0; i < treatments.length; i++) {
      if (
        treatments[i]?.meds.length === 0 &&
        treatments[i]?.therapies.length === 0
      ) {
        toast.error(
          'Every treatment has at least one medication or therapy, along with a rating.',
          {
            position: toast.POSITION.BOTTOM_RIGHT,
          }
        );
        return false;
      }
    }
    return true;
  };

  const frequencyHandler = (e) => {
    const enteredValue = e.target.value;
    const numericValue = parseInt(enteredValue);

    // Check if the entered value is a valid number
    if (!isNaN(numericValue)) {
      const updatedValue = Math.max(0, Math.min(numericValue, 50));
      setSelectedBehaviorCount(updatedValue);
      updateBehaviours(updatedValue, 'behaviourFrequencyCount', behaviours.id);
    } else {
      // Handle the case where the entered value is not a valid number
      // For example, clear the input or show an error message
      setSelectedBehaviorCount(0);
    }
  };

  const incrementCount = () => {
    const incrementCount = selectedBehaviorCount + 1;
    const updatedCount = Math.min(incrementCount, 50);
    setSelectedBehaviorCount(updatedCount);
    updateBehaviours(updatedCount, 'behaviourFrequencyCount', behaviours.id);
  };

  const decrementCount = () => {
    const newCount = selectedBehaviorCount - 1;
    const updatedCount = Math.max(newCount, 0);
    setSelectedBehaviorCount(updatedCount);
    updateBehaviours(updatedCount, 'behaviourFrequencyCount', behaviours.id);
  };

  const initialBehaviourincrementCount = () => {
    const incrementCount = selectedBehaviorCount + 1;
    const updatedCount = Math.min(incrementCount, 50);
    setSelectedBehaviorCount(updatedCount);
    updateBehaviours(
      updatedCount,
      'behaviourFrequencyCount',
      initialBehaviourID.current
    );
  };

  const initialBehaviourdecrementCount = () => {
    const newCount = selectedBehaviorCount - 1;
    const updatedCount = Math.max(newCount, 0);
    setSelectedBehaviorCount(updatedCount);
    updateBehaviours(
      updatedCount,
      'behaviourFrequencyCount',
      initialBehaviourID.current
    );
  };

  const RatingHandler = (value, type, id, funCall) => {
    console.log('ID 364', id);
    if (funCall === 'treatment') updateTreatments(value, type, id);
    else if (funCall === 'behaviour') updateBehaviours(value, type, id);
  };

  //Behaviors

  const behaviorsdetails = videoDetails?.patientTreatmentInfo?.behaviors || [];
  const behaviorNames = behaviorsdetails
    .map((behavior) => behavior.behaviorName)
    .join(', ');
  const antecedentFormatted = behaviorsdetails
    .map((behavior) => behavior.antecedent)
    .join(', ');
  const consequenceFormatted = behaviorsdetails
    .map((behavior) => behavior.consequence)
    .join(', ');

  const initial_behavior = {
    behaviour: behaviorNames,
    behaviourFrequencyCount: 0,
    behavior_rating: 0,
    antecedents: antecedentFormatted,
    consequences: consequenceFormatted,
  };

  //Treatments
  const {
    meds = [],
    diagnosis = '',
    behaviors = [],
    therapies = [],
  } = videoDetails?.patientTreatmentInfo || {};
  console.log(
    'videoDetails.patientTreatmentInfo',
    videoDetails.patientTreatmentInfo
  );

  const formattedMeds = Array.isArray(meds) ? meds.join(', ') : meds;
  const formattedDiagnosis = Array.isArray(diagnosis)
    ? diagnosis.join(', ')
    : diagnosis;

  const behaviorNamesTreat = behaviors
    .map((behavior) => behavior.behaviorName)
    .join(', ');

  const initial_treatment = {
    therapies: Array.isArray(therapies) ? therapies : [therapies],
    meds: Array.isArray(meds) ? meds : [meds],
    rating: 0,
    symptom: behaviorNames || '',
    diagnosis: diagnosis || '',
  };

  console.log('treatments 404', treatments);
  return (
    <Box sx={{ p: 1 }} className='videoFeedbackPaper'>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
        <Card
          className='treatmentBox'
          sx={{ border: '1px solid lightgray', p: 1 }}
        >
          <Typography variant='h6'>Treatment Information</Typography>

          <Box
            sx={{
              backgroundColor: '#E5E5FA',
              borderRadius: '20px',
              padding: '10px',
            }}
          >
            <Typography sx={{ fontWeight: 'bold', marginBottom: '8px' }}>
              Diagnosis
            </Typography>
            <Typography variant='body1' sx={{ color: '#333' }}>
              {formattedDiagnosis}
            </Typography>
          </Box>
          <Box
            sx={{
              backgroundColor: '#E5E5FA',
              borderRadius: '20px',
              padding: '10px',
              marginTop: '10px',
            }}
          >
            <Typography sx={{ fontWeight: 'bold', marginBottom: '8px' }}>
              Meds
            </Typography>
            <Typography variant='body1' sx={{ color: '#333' }}>
              {formattedMeds}
            </Typography>
          </Box>

          <Box
            sx={{
              backgroundColor: '#E5E5FA',
              borderRadius: '20px',
              padding: '10px',
              marginTop: '10px',
            }}
          >
            <Typography sx={{ fontWeight: 'bold', marginBottom: '8px' }}>
              Intervention
            </Typography>
            <Typography variant='body1' sx={{ color: '#333' }}>
              Autofilled from crisis
            </Typography>
          </Box>

          <Box
            sx={{
              backgroundColor: '#E5E5FA',
              borderRadius: '20px',
              padding: '10px',
              marginTop: '10px',
            }}
          >
            <Typography sx={{ fontWeight: 'bold', marginBottom: '8px' }}>
              Behaviors
            </Typography>
            <Typography variant='body1' sx={{ color: '#333' }}>
              {' '}
              {behaviorNamesTreat}
            </Typography>
          </Box>
          <Box>
            {ACM[typeOfUser]?.videoFeedbackAddNewTreatment && (
              <SymptomRating
                id={initialTreatmentID.current}
                RatingHandler={RatingHandler}
                funCall='treatment'
              />
            )}
          </Box>
        </Card>

        <Card
          className='treatmentBox'
          sx={{ border: '1px solid lightgray', p: 1 }}
        >
          <Box
            className='flexBox flexColumn fullHeight fullWidth'
            sx={{ gap: 2, justifyContent: 'center' }}
          >
            <CardHeader subheader={'Behavior Information'} />

            {behaviorsdetails.length > 0 ? (
              <>
                <Typography variant='body1' sx={{ marginBottom: 1 }}>
                  <Typography sx={{ fontWeight: 'bold', marginBottom: '8px' }}>
                    Behavior
                  </Typography>
                  {behaviorNames}
                  {ACM[typeOfUser]?.videoFeedbackAddNewTreatment && (
                    <>
                      <Typography
                        sx={{
                          fontWeight: 'bold',
                          marginBottom: '8px',
                          marginTop: '10px',
                        }}
                      >
                        Severity Rating
                      </Typography>

                      <SymptomRating
                        id={initialBehaviourID.current}
                        RatingHandler={RatingHandler}
                        funCall='behaviour'
                      />
                    </>
                  )}
                  {ACM[typeOfUser]?.videoFeedbackAddNewTreatment && (
                    <Box
                      className='flexBox flexCenter'
                      sx={{ justifyContent: 'flex-start', mt: 2 }}
                    >
                      <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                        {/* Selected Behavior:{" "}
    {isCustomBehaviour ? "Custom Behavior" : behaviour?.behaviour} */}
                        Frequency count
                      </Typography>
                      <Box
                        className='flexBox flexCenter'
                        sx={{ gap: 4, ml: 10 }}
                      >
                        <Fab
                          onClick={initialBehaviourdecrementCount}
                          disabled={selectedBehaviorCount === 0}
                          size='small'
                          sx={{
                            backgroundColor:
                              selectedBehaviorCount === 0
                                ? 'gray'
                                : 'var(--clr-theme-purple)!important',
                            fontSize: '2rem',
                            color: 'var(--clr-theme-white)',
                          }}
                        >
                          -
                        </Fab>
                        {/* <Typography>{selectedBehaviorCount}</Typography> */}
                        <TextField
                          type='tel'
                          inputProps={{
                            pattern: '[0-50]*', // Allows only numeric input
                            inputMode: 'numeric', // Specifies a numeric keyboard
                          }}
                          sx={{ width: '55px' }}
                          value={selectedBehaviorCount}
                          onChange={frequencyHandler}
                        />

                        <Fab
                          onClick={initialBehaviourincrementCount}
                          size='small'
                          sx={{
                            backgroundColor:
                              'var(--clr-theme-purple) !important',
                            fontSize: '1.5rem',
                            color: 'var(--clr-theme-white)',
                          }}
                        >
                          +
                        </Fab>
                      </Box>
                    </Box>
                  )}
                </Typography>
                <Typography sx={{ fontWeight: 'bold' }}>ANTECEDENT</Typography>
                {antecedentFormatted}
                <Typography sx={{ fontWeight: 'bold' }}>
                  {' '}
                  CONSEQUENCE
                </Typography>
                {consequenceFormatted}
              </>
            ) : (
              <Typography variant='body1' sx={{ color: '#777' }}>
                No behaviors available.
              </Typography>
            )}
          </Box>
        </Card>

        {ACM[typeOfUser]?.videoFeedbackAddNewTreatment && (
          <>
            {treatments
              ?.filter(
                (treatment) => treatment.id !== initialTreatmentID.current
              )
              .map((treatment, i) => {
                return (
                  <Treatment
                    key={i}
                    index={i}
                    treatment={treatment}
                    updateTreatments={updateTreatments}
                    symptoms={symptoms}
                    deleteTreatment={deleteTreatment}
                    medAndTherapies={medAndTherapies}
                    isLoading={isLoading}
                    behaviours={behaviours}
                    videoDetails={videoDetails}
                  />
                );
              })}

            {behaviours
              ?.filter(
                (behaviour) => behaviour.id !== initialBehaviourID.current
              )
              .map((behaviour, i) => {
                return (
                  <Behaviour
                    key={i}
                    index={i}
                    behaviour={behaviour}
                    updateBehaviours={updateBehaviours}
                    symptoms={symptoms}
                    deleteBehaviour={deleteBehaviour}
                    medAndTherapies={medAndTherapies}
                    isLoading={isLoading}
                    behavioursName={behavioursName}
                    videoDetails={videoDetails}
                  />
                );
              })}

            {/* {goals?.map((behaviour, i) => {
          return (
            <Goals
              key={i}
              index={i}
              behaviour={behaviour}
              updateGoals={updateGoals}
              // symptoms={symptoms}
              // deleteBehaviour={deleteBehaviour}
              // medAndTherapies={medAndTherapies}
              // isLoading={isLoading}
              behavioursName={behavioursName}
            />
          );
        })} */}
            <TextField
              label='Video feedback notes'
              multiline
              rows={12}
              value={notes}
              placeholder='Notes for patient'
              onChange={(e) => {
                setNotes(e.target.value);
              }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={Private}
                  onChange={(e) => {
                    setPrivate(e.target.checked);
                  }}
                />
              }
              label='Private'
            />
            <Box
              className='flexBox flexWrap'
              sx={{
                justifyContent: 'space-between',
                minWidth: '250px',
                gap: 1,
              }}
            >
              <Box sx={{ width: '45%' }}>
                <CustomButton
                  ChangeHandler={handleAddTreatment}
                  variant='extended'
                  color='info'
                  size={'large'}
                >
                  <Add />
                  Add new treatment
                </CustomButton>
              </Box>
              <Box sx={{ width: '45%' }}>
                <CustomButton
                  color='secondary'
                  variant='extended'
                  ChangeHandler={handleAddBehaviour}
                >
                  <Add /> Add new behavior
                </CustomButton>
              </Box>
            </Box>

            <ToastContainer autoClose={true} />
            <CustomButton
              ChangeHandler={UpdateTreatmentHandler}
              variant='extended'
              color='success'
            >
              Update treatment process
            </CustomButton>
            <CustomButton
              ChangeHandler={UpdateTreatmentstoVideo}
              variant='extended'
              color='info'
              size={'large'}
            >
              Submit
            </CustomButton>
          </>
        )}
      </div>
    </Box>
  );
};
export default VideoFeedback;
