import { fetchGroupMessages } from '../api';
import { useQuery } from '@tanstack/react-query';

const useGroupMessages = (id, ChatId) => {
  return useQuery({
    queryKey: ['groupMessages', id, ChatId],
    queryFn: () => fetchGroupMessages(id, ChatId),
    enabled: !id && !!ChatId,
    staleTime: 60000,
    cacheTime: 300000,
  });
};

export default useGroupMessages;
