export const SKILL_ACQUISITION_TYPES = [
  'Communication Skills',
  'Social Skills',
  'Daily Living Skills',
  'Academic Skills',
  'Fine Motor Skils',
  'Gross Motor Skills',
  'Sensory Processing Skills',
  'Cognitive Skills',
  'Play & Leisure Skills',
  'Self-Advocacy Skills',
  'Vocational Skills',
  'Other Skills',
];

export const BEHAVIOR_TYPE_CATEGORIES = [
  'Self-Regulation',
  'Adaptive Behavior',
  'Reduction of Challenging Behaviors',
  'Impulse Control',
  'Social-Emotional Behavior',
  'Other Behavior',
];

export const CATEGORY_TOOLTIPS = {
  ABA: 'Applied Behavioral Analysis',
  OT: 'Occupational Therapy',
  PT: 'Physical Therapy',
  APE: 'Adaptive Physical Education',
};