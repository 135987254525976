import React, { useContext, useEffect, useState } from 'react';
import MainLayout from '../../hoc/MainLayout';
import {
  TextField,
  Checkbox,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  FormControlLabel,
  Typography,
  Grid,
  Tooltip,
  Dialog,
  Skeleton,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  MenuItem,
  Switch,
} from '@mui/material';
import AuthContext from '../../contexts/AuthContext';
import { Edit, Delete } from '@mui/icons-material';
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  onSnapshot,
  updateDoc,
} from 'firebase/firestore';
import { FirestoreDB } from '../../utils/firebase/firebase';
import { updateTeachertProfileDetails } from '../../api';
import useTeachersData from '../../hooks/useTeachersData';
import { toast, ToastContainer } from 'react-toastify';
import useSchoolsData from '../../hooks/useSchoolsData';
const TeachersPage = () => {
  const { user, typeOfUser, updateGlobalUser } = useContext(AuthContext);

  const [teachers, setTeachers] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [teacherIndexDelete, setTeacherIndexDelete] = useState(0);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    addStudent: false,
    specializations: [],
    qualifications: '',
    school: '',
    schoolId: '',
    class: '',
    status: true,
  });
  const [grade, setGrade] = useState('');
  const [schoolType, setSchoolType] = useState('');
  const [subRoleName, setSubRoleName] = useState('');
  const [editIndex, setEditIndex] = useState(null);
  const [selectedTeacherId, setSelectedTeacherId] = useState(null);
  const {
    data: schools = [],
    isLoading: isSchoolsLoading,
    error: schoolsError,
    refetch: refetchSchools,
  } = useSchoolsData();

  const {
    data: fetchedTeachers = [],
    isLoading: isTeachersLoading,
    error: teachersError,
    refetch: refetchTeachers,
  } = useTeachersData(user?.uid, typeOfUser);
  const [openDialog, setOpenDialog] = useState(false);
  const [subRoles, setSubRoles] = useState([]);

  const fetchSubRoles = (setSubRoles) => {
    const accessPermissionRef = collection(FirestoreDB, 'accessPermission');

    const rolesName = onSnapshot(
      accessPermissionRef,
      (querySnapshot) => {
        const roles = querySnapshot.docs.map((doc) => doc.id);
        setSubRoles(roles);
      },
      (error) => console.error('Error fetching subRoles:', error)
    );

    return rolesName;
  };

  useEffect(() => {
    const getSubRoles = fetchSubRoles(setSubRoles);
    return () => getSubRoles();
  }, []);

  console.log('subRoles', subRoles);

  const specializationOptions = [
    'Autism Spectrum Disorder',
    'Learning Disabilities',
    'Behavioral Support',
    'Speech Therapy',
    'Occupational Therapy',
    'Physical Therapy',
  ];

  const grades = [
    { id: '1', name: 'Pre-School' },
    { id: '2', name: 'Kinder garden' },
    { id: '3', name: '1-12 grade' },
    { id: '4', name: 'k-2' },
  ];
  const schoolTypes = [
    { id: '1', name: 'Elementary School' },
    { id: '2', name: 'Middle School' },
    { id: '3', name: 'High School' },
  ];

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === 'checkbox') {
      if (name === 'addStudent') {
        // Handle the 'addStudent' checkbox
        setFormData((prev) => ({
          ...prev,
          addStudent: checked,
        }));
      } else {
        setFormData((prev) => ({
          ...prev,
          specializations: checked
            ? [...prev.specializations, value]
            : prev.specializations.filter((spec) => spec !== value),
        }));
      }
    } else if (name === 'school') {
      const selectedSchool = schools.find((school) => school.name === value);
      setFormData((prev) => ({
        ...prev,
        school: value,
        schoolId: selectedSchool?.id || '',
      }));
    } else if (name === 'status') {
      setFormData((prev) => ({ ...prev, status: checked }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleStatusChange = async (teacherId, isActive) => {
    try {
      const teacherDocRef = doc(FirestoreDB, 'teachersList', teacherId);
      await updateDoc(teacherDocRef, {
        status: isActive ? 'active' : 'inactive',
      });

      console.log(`Status updated for teacher ID: ${teacherId}`);

      setTeachers((prevTeachers) =>
        prevTeachers.map((teacher) =>
          teacher.id === teacherId
            ? { ...teacher, status: isActive ? 'active' : 'inactive' }
            : teacher
        )
      );
      await refetchTeachers();
      console.log(`Status updated for student ID: ${teacherId}`);
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const selectedSchool = schools.find(
      (school) => school.schoolName === formData.school
    );

    if (editIndex !== null) {
      // Edit existing teacher
      // const teacherUid = fetchedTeachers[editIndex]?.id;
      const teacherUid = selectedTeacherId;

      try {
        const updatedTeacher = {
          fullname: formData.name,
          schoolName: formData.school,
          schoolId: selectedSchool?.schoolId || selectedSchool?.id || '',
          email: formData.email,
          grade: grade,
          schoolType: schoolType,
          addStudent: formData.addStudent,
          specializations: formData.specializations,
          qualifications: formData.qualifications,
          timestamp: new Date(),
          subRoleName: subRoleName,
        };

        const result = await updateTeachertProfileDetails(
          btoa(JSON.stringify(updatedTeacher)),
          teacherUid
        );

        if (result) {
          updateGlobalUser({ ...user, ...formData });
          console.log('Teacher profile updated successfully via API');
          setOpenDialog(false);
          resetStates();
          await refetchTeachers();
        } else {
          console.error('API returned an error or no result');
        }
      } catch (error) {
        console.error('Error updating teacher profile:', error);
      }
    } else {
      // Add new teacher
      const params = {
        fromEmail: user?.email,
        inviteType: 'TEACHER',
        message: '',
        fullname: formData.name,
        schoolName: formData.school,
        addStudent: formData.addStudent,
        schoolId: selectedSchool?.schoolId || selectedSchool?.id || '',
        timestamp: new Date(),
        toEmail: formData.email,
        schoolType: schoolType || 'NA',
        subRoleName: subRoleName || 'NA',
      };

      try {
        const docRef = await addDoc(
          collection(FirestoreDB, 'invitations'),
          params
        );
        console.log('Teacher document written with ID: ', docRef.id);
      } catch (e) {
        console.error('Error adding teacher document: ', e);
      }
    }

    // setOpenDialog(false);
    if (editIndex === null)
      toast.success('Teacher added successfully.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    else
      toast.success('Teacher updated successfully.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    resetStates();
  };

  const resetStates = () => {
    setFormData({
      name: '',
      email: '',
      specializations: [],
      qualifications: '',
      school: '',
      schoolId: '',
      class: '',
      status: true,
    });
    setGrade('');
    setSchoolType('');
    setSubRoleName('');
  };

  const handleEdit = (index) => {
    // const teacherToEdit = fetchedTeachers[index];
    const teacherToEdit = teachers?.find((teacher) => teacher.id === index);
    setSelectedTeacherId(index);

    setFormData({
      name: teacherToEdit.name || '',
      email: teacherToEdit.email || '',
      addStudent: teacherToEdit.addStudent || false,
      specializations: teacherToEdit.specializations || [],
      qualifications: teacherToEdit.qualifications || '',
      school: teacherToEdit.schoolName || '',
      schoolId: teacherToEdit.schoolId || '',
      class: teacherToEdit.class || '',
    });
    setGrade(teacherToEdit.grade || '');
    setSchoolType(teacherToEdit.schoolType || '');
    setSubRoleName(teacherToEdit.subRole || '');
    setEditIndex(index);
    setOpenDialog(true);
  };

  console.log('setSelectedTeacherId', selectedTeacherId);

  const handleDelete = async (index) => {
    try {
      // const teacherId = fetchedTeachers[index]?.id;
      const teacherId = teachers.find((teacher) => teacher.id === index);
      if (!teacherId) {
        throw new Error('Teacher ID not found.');
      }

      const teacherDocRef = doc(FirestoreDB, 'teachersList', teacherId);

      await deleteDoc(teacherDocRef);

      console.log(`Teacher with ID ${teacherId} deleted successfully.`);

      await refetchTeachers();
      toast.success('Teacher deleted successfully.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } catch (error) {
      console.error(`Error deleting teacher with index ${index}:`, error);
    }
  };

  useEffect(() => {
    if (fetchedTeachers?.length > 0) {
      setTeachers(fetchedTeachers);
    }
  }, [fetchedTeachers]);



  const isFormValid =
    formData.name &&
    formData.email &&
    formData.qualifications &&
    formData.school;

  const isAddForm = !formData.id;

  const SkeletonRow = () => {
    return (
      <TableRow>
        <TableCell>
          <Skeleton variant='text' width={150} />
        </TableCell>

        <TableCell>
          <Skeleton variant='text' width={200} />
        </TableCell>

        <TableCell>
          <Skeleton variant='text' width={150} />
        </TableCell>

        <TableCell>
          <Skeleton
            variant='rectangular'
            width={50}
            height={25}
            style={{ marginRight: '10px' }}
          />
        </TableCell>
        <TableCell>
          <Skeleton
            variant='rectangular'
            width={50}
            height={25}
            style={{ marginRight: '10px' }}
          />
        </TableCell>
      </TableRow>
    );
  };

  return (
    <MainLayout>
      <Box sx={{ p: 2 }}>
        <Typography variant='h4' gutterBottom>
          Special Education Teachers
        </Typography>

        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: '20px',
          }}
        >
          <Button
            variant='contained'
            color='primary'
            onClick={() => setOpenDialog(true)}
          >
            + Add Teacher
          </Button>
        </div>

        <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
          <DialogTitle>
            {editIndex !== null ? 'Edit Teacher' : 'Add Teacher'}
          </DialogTitle>
          <form onSubmit={handleSubmit}>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label='Name'
                    name='name'
                    value={formData.name}
                    onChange={handleInputChange}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label='Email'
                    name='email'
                    value={formData.email}
                    onChange={handleInputChange}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    select
                    label='School'
                    name='school'
                    value={formData.school}
                    onChange={handleInputChange}
                    fullWidth
                    required
                  >
                    {schools.map((school) => (
                      <MenuItem key={school.id} value={school.schoolName}>
                        {school.schoolName}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                {/* <Grid item xs={12} sm={6}>
                  <TextField
                    label='School ID'
                    name='schoolId'
                    value={formData.schoolId}
                    fullWidth
                    disabled
                  />
                </Grid> */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    select
                    label='Grade Level'
                    fullWidth
                    variant='outlined'
                    value={grade}
                    onChange={(e) => setGrade(e.target.value)}
                  >
                    {grades.map((grade) => (
                      <MenuItem key={grade.id} value={grade.name}>
                        {grade.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    select
                    label='School Type'
                    fullWidth
                    variant='outlined'
                    value={schoolType}
                    onChange={(e) => setSchoolType(e.target.value)}
                  >
                    {schoolTypes.map((type) => (
                      <MenuItem key={type.id} value={type.name}>
                        {type.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    select
                    label='Sub Role'
                    fullWidth
                    variant='outlined'
                    value={subRoleName}
                    onChange={(e) => setSubRoleName(e.target.value)}
                  >
                    {subRoles.map((type) => (
                      <MenuItem key={type} value={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                {/* <Grid item xs={12} sm={6}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={formData.status}
                        onChange={handleInputChange}
                        name='status'
                        color='primary'
                        disabled={isAddForm}
                      />
                    }
                    label={formData.status ? 'Active' : 'Inactive'}
                  />
                </Grid> */}
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name='addStudent'
                        checked={formData.addStudent}
                        onChange={handleInputChange}
                      />
                    }
                    label='Add Student'
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='subtitle1'>Specializations</Typography>
                  {specializationOptions.map((spec) => (
                    <FormControlLabel
                      key={spec}
                      control={
                        <Checkbox
                          name='specializations'
                          value={spec}
                          checked={formData.specializations.includes(spec)}
                          onChange={handleInputChange}
                        />
                      }
                      label={spec}
                    />
                  ))}
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label='Qualifications'
                    name='qualifications'
                    value={formData.qualifications}
                    onChange={handleInputChange}
                    fullWidth
                    multiline
                    rows={4}
                    required
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setFormData({
                    name: '',
                    email: '',
                    specializations: [],
                    qualifications: '',
                    school: '',
                    schoolId: '',
                    class: '',
                    status: true,
                  });
                  setEditIndex(null);
                  setOpenDialog(false);
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={handleSubmit}
                variant='contained'
                color='primary'
                disabled={!isFormValid}
              >
                {editIndex !== null ? 'Update Teacher' : 'Add Teacher'}
              </Button>
            </DialogActions>
          </form>
        </Dialog>

        <TableContainer component={Paper} style={{ marginTop: '20px' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: 'bold' }}>Name</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>Email</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>
                  School Name
                </TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>Status</TableCell>
                {/* <TableCell>Class</TableCell> */}
                {/* <TableCell>Status</TableCell> */}
                {/* <TableCell>Specializations</TableCell> */}
                <TableCell style={{ fontWeight: 'bold' }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {teachers.length > 0 ? (
                teachers.map((teacher, index) => (
                  <TableRow key={index}>
                    <TableCell>{teacher.name}</TableCell>
                    <TableCell>{teacher.email}</TableCell>
                    <TableCell>{teacher.schoolName}</TableCell>
                    <TableCell>
                      <Switch
                        checked={teacher.status === 'active'}
                        onChange={(e) =>
                          handleStatusChange(teacher.id, e.target.checked)
                        }
                        name='status'
                        color='primary'
                      />
                    </TableCell>
                    {/* <TableCell>{grade}</TableCell> */}
                    {/* <TableCell>
                      {teacher.status ? 'Active' : 'Inactive'}
                    </TableCell> */}
                    {/* <TableCell>{teacher.specializations.join(', ')}</TableCell> */}
                    <TableCell>
                      <Tooltip title='Edit Teacher Information'>
                        <Button onClick={() => handleEdit(teacher?.id)}>
                          <Edit sx={{ color: 'black' }} />
                        </Button>
                      </Tooltip>
                      <Tooltip title='Delete Teacher'>
                        <Button
                          onClick={() => {
                            setDeleteModal(true);
                            setTeacherIndexDelete(teacher.id);
                          }}
                        >
                          <Delete sx={{ color: 'red' }} />
                        </Button>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))
              ) : isTeachersLoading || isSchoolsLoading ? (
                [...Array(5)].map((_, index) => <SkeletonRow key={index} />)
              ) : (
                <TableRow>
                  <TableCell colSpan={7} align='center'>
                    No teachers yet. Add your first teacher above.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Dialog open={deleteModal} onClose={() => setTeacherIndexDelete(0)}>
        <DialogContent>
          <Typography
            sx={{
              color: 'var(--clr-theme-gray)',
              fontFamily: 'Karla',
              fontWeight: 700,
              textAlign: 'center',
            }}
          >
            Are you sure you want to delete this teacher?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant='contained'
            color='success'
            onClick={() => setDeleteModal(false)}
            sx={{
              backgroundColor: '#D3D3D3',
              textTransform: 'capitalize',
              fontFamily: 'Lato',
              fontWeight: 400,
              fontSize: '1.2rem',
              width: '100%',
            }}
          >
            No
          </Button>
          <Button
            variant='contained'
            color='success'
            onClick={() => {
              handleDelete(teacherIndexDelete);
              setDeleteModal(false);
            }}
            sx={{
              backgroundColor: '#9291f0',
              textTransform: 'capitalize',
              fontFamily: 'Lato',
              fontWeight: 400,
              fontSize: '1.2rem',
              width: '100%',
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer />
    </MainLayout>
  );
};

export default TeachersPage;
