import { useState } from 'react';
import {
  Box,
  Typography,
  Button,
  Modal,
  Chip,
  Card,
  CardContent,
  Tooltip,
} from '@mui/material';
import {
  Event,
  Timeline,
  Edit as EditIcon,
  DataUsage,
} from '@mui/icons-material';
import DataEntryModal from '../../components/GoalData';

const DataPoints = ({
  data,
  measurementType,
  goals,
  studentDataPoints,
  measurementUnit,
  setviewGoalEdit,
}) => {
  const selectedGoalDataPoints = [];
  if (Array.isArray(data)) {
    data.forEach((dataEntry) => {
      const createdAtDate = new Date(
        (dataEntry.createdAt?._seconds || dataEntry.createdAt?.seconds) * 1000
      ).toLocaleDateString('en-CA');

      selectedGoalDataPoints.push({
        date: createdAtDate,
        dataPoints: dataEntry,
        goalCollectionId: goals?.goalId,
      });
    });
  }

  const [selectedDataPoint, setSelectedDataPoint] = useState(null);
  const dataPointPerSession =
    goals?.dataCollection.DataCollection.MultipleDataPointsPerSession
      .MultipleDataPointsPerSession;
  const [open, setOpen] = useState(false);

  const handleEdit = (dataPoint) => {
    setOpen(true);
    setSelectedDataPoint({ ...dataPoint, dataPointPerSession });
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedDataPoint(null);
  };

  if (!Array.isArray(data) || data.length === 0) {
    return (
      <Card
        sx={{ p: 4, textAlign: 'center', bgcolor: '#f9f9f9', borderRadius: 2 }}
      >
        <DataUsage sx={{ fontSize: 48, color: '#9291ef', mb: 2 }} />
        <Typography variant='h6' color='textSecondary'>
          No data points available
        </Typography>
      </Card>
    );
  }

  const formatDate = (seconds) => {
    if (!seconds) return 'N/A';
    return new Date(seconds * 1000).toISOString().split('T')[0];
  };

  return (
    <Box sx={{ mt: 2 }}>
      {data?.map((point, index) => {
        const dataPoints = point?.info?.data_points || [];
        const formattedDate = formatDate(point.createdAt?._seconds);

        return (
          <Card
            key={point.id || `point-${index}`}
            elevation={2}
            sx={{
              mb: 3,
              borderRadius: 2,
              overflow: 'visible',
              transition: 'transform 0.2s, box-shadow 0.2s',
              '&:hover': {
                transform: 'translateY(-2px)',
                boxShadow: '0 8px 16px rgba(146, 145, 239, 0.2)',
              },
            }}
          >
            <CardContent sx={{ p: 0 }}>
              <Box
                sx={{
                  p: 2,
                  bgcolor: '#f5f5f7',
                  borderBottom: '1px solid #e0e0e0',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Chip
                  icon={<Event />}
                  label={`Date: ${formattedDate}`}
                  variant='outlined'
                  sx={{
                    fontWeight: 'medium',
                    bgcolor: 'white',
                    border: '1px solid #e0e0e0',
                  }}
                />
                <Tooltip title='Edit Data Point'>
                  <Button
                    variant='contained'
                    size='small'
                    startIcon={<EditIcon />}
                    onClick={() => handleEdit(point)}
                    sx={{
                      bgcolor: '#9291ef',
                      '&:hover': {
                        bgcolor: '#7a79d1',
                      },
                      borderRadius: '20px',
                      px: 2,
                    }}
                  >
                    Edit
                  </Button>
                </Tooltip>
              </Box>

              <Box sx={{ p: 2 }}>
                {measurementType !== 'Trials' ? (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                    {dataPoints.map((dataPoint, dpIndex) => (
                      <Box
                        key={dataPoint.id || `dp-${dpIndex}`}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          p: 1.5,
                          borderRadius: 1,
                          bgcolor: '#f9f9fc',
                          border: '1px solid #e8e8f0',
                        }}
                      >
                        <Timeline
                          sx={{ mr: 1, color: '#9291ef', fontSize: 18 }}
                        />
                        <Typography
                          variant='body2'
                          color='textSecondary'
                          sx={{ mr: 1 }}
                        >
                          Goal Value:
                        </Typography>
                        <Typography
                          variant='body1'
                          sx={{ fontWeight: 'medium' }}
                        >
                          {dataPoint.goal_value !== undefined
                            ? dataPoint.goal_value
                            : 'N/A'}{' '}
                          {measurementUnit}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                ) : (
                  <Box>
                    {dataPoints.map((dataPoint, dpIndex) => (
                      <Box
                        key={dataPoint.id || `dp-${dpIndex}`}
                        sx={{
                          p: 1.5,
                          borderRadius: 1,
                          bgcolor: '#f9f9fc',
                          border: '1px solid #e8e8f0',
                        }}
                      >
                        <Box
                          sx={{ display: 'flex', alignItems: 'center', mb: 1 }}
                        >
                          <Timeline
                            sx={{ mr: 1, color: '#9291ef', fontSize: 18 }}
                          />
                          <Typography variant='body2' color='textSecondary'>
                            Trial Results:
                          </Typography>
                        </Box>

                        {dataPoint.trialsNumber &&
                        Array.isArray(dataPoint.trialsNumber) &&
                        dataPoint.trialsNumber.length > 0 ? (
                          <Box
                            sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}
                          >
                            {dataPoint.trialsNumber.map((val, trialIndex) => (
                              <Chip
                                key={trialIndex}
                                label={`Trial #${
                                  trialIndex + 1
                                }: ${val} ${measurementUnit}`}
                                size='small'
                                sx={{
                                  bgcolor: 'white',
                                  border: '1px dashed #9291ef',
                                  fontWeight: 'medium',
                                }}
                              />
                            ))}
                          </Box>
                        ) : (
                          <Typography variant='body2' color='text.secondary'>
                            No trial data available
                          </Typography>
                        )}
                      </Box>
                    ))}
                  </Box>
                )}
              </Box>
            </CardContent>
          </Card>
        );
      })}

      {/* Modal for Edit Data */}
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 600,
            maxHeight: '80vh',
            bgcolor: 'white',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            overflowY: 'auto',
          }}
        >
          {selectedDataPoint && (
            <DataEntryModal
              open={open}
              onClose={handleClose}
              goalTitle={goals?.GoalTitle}
              studentDataPoints={studentDataPoints}
              criteria={
                studentDataPoints?.[0]?.dataPoints?.info?.data_points[0]
                  ?.criteria
              }
              selectedDate={formatDate(selectedDataPoint.createdAt?._seconds)}
              dataPointPerSession={selectedDataPoint.dataPointPerSession}
              studentId={goals?.studentId}
            />
          )}
        </Box>
      </Modal>
    </Box>
  );
};

export default DataPoints;
