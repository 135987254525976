import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Box,
  Button,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Grid,
  Stack,
  Paper,
  Snackbar,
  Alert,
  IconButton,
} from '@mui/material';

const reports = [
  {
    id: 1,
    title: 'Report summary for each document',
    generated: '5 minutes ago',
  },
  {
    id: 2,
    title: 'Download Document & Summary',
    generated: '5 minutes ago',
  },
  {
    id: 3,
    title: 'Report summary for each document',
    generated: '5 minutes ago',
  },
  {
    id: 4,
    title: 'Download Document & Summary',
    generated: '5 minutes ago',
  },
];

export default function Reports() {
  return (
    <Paper
      elevation={0}
      sx={{
        p: 3,
        bgcolor: '#f3f4f6',
        borderRadius: 2,
      }}
    >
      <Typography variant='h6' fontWeight='bold' gutterBottom>
        Reports
      </Typography>
      <Stack spacing={2} sx={{ mb: 3 }}>
        {reports.map((report) => (
          <Paper
            key={report.id}
            elevation={0}
            sx={{
              p: 2,
              bgcolor: 'white',
              border: '1px solid #e5e7eb',
              borderRadius: 2,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
              }}
            >
              <Box>
                <Typography variant='subtitle1' fontWeight='medium'>
                  {report.title}
                </Typography>
                <Typography
                  variant='body2'
                  color='text.secondary'
                  sx={{ mt: 0.5 }}
                >
                  Generated {report.generated}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex' }}>
                <IconButton size='small'>
                  <NotificationsIcon fontSize='small' />
                </IconButton>
                <IconButton size='small'>
                  <MoreVertIcon fontSize='small' />
                </IconButton>
              </Box>
            </Box>
          </Paper>
        ))}
      </Stack>
      <Button
        variant='outlined'
        fullWidth
        sx={{
          bgcolor: 'white',
          textTransform: 'none',
          color: 'text.primary',
          border: '1px solid #e5e7eb',
          '&:hover': { bgcolor: '#f9fafb', border: '1px solid #d1d5db' },
        }}
      >
        Generate Report
      </Button>
    </Paper>
  );
}
