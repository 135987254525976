import React, { useEffect, useState, useContext } from 'react';
import {
  Add,
  Cancel,
  Check,
  Close,
  Delete,
  Edit,
  Medication,
  Psychology,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  NativeSelect,
  TextField,
  Typography,
} from '@mui/material';
import { deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { FirestoreDB } from '../../utils/firebase/firebase';
import './Treatment.css';
import EditTreatment from './EditTreatment';
import { ACMContext } from '../../contexts/ACMProvider';
import { useSymptoms } from '../../hooks/useSymptoms';

const Treatments = ({
  treatment,
  updateTreatments,
  index,
  deleteTreatment,
  patientID,
  studentID,
  setIsLoading,
  getTreatments,
  treatmentsFromDB,
  changeDoctorApproveStatus,
  typeOfUser,
}) => {
  const { ACM } = useContext(ACMContext);
  const [openDialog, setOpenDialog] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenDialog = (item) => {
    setItemToDelete(item);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setItemToDelete(null);
  };

  const handleDeleteItem = () => {
    if (itemToDelete) {
      deleteTreatment(itemToDelete.id);
      deleteTreatmentInFirebase(itemToDelete.id);
      handleCloseDialog();
    }
  };
  const targetCollection =
    ACM[typeOfUser]?.isTeacher || ACM[typeOfUser]?.isStudent
      ? 'studentsList'
      : 'patientsList';

  const deleteTreatmentInFirebase = async (id) => {
    await deleteDoc(
      doc(
        FirestoreDB,
        targetCollection,
        targetCollection === 'studentsList' ? studentID : patientID,
        'treatments',
        id
      )
    );
  };
  const doctorApprovedPatientTreatment = async (id) => {
    const treatmentref = doc(
      FirestoreDB,
      targetCollection,
      targetCollection === 'studentsList' ? studentID : patientID,
      'treatments',
      id
    );

    await updateDoc(treatmentref, { isDoctorApproved: true });
    changeDoctorApproveStatus(id);
  };

  return (
    <div style={{ padding: 5 }}>
      <Card
        className='treatmentBox'
        sx={{ border: '1px solid var(--clr-theme-purple-50)', mt: 1 }}
      >
        <CardHeader
          title={
            <Box className='flexBox flexColumn' gap={1}>
              {ACM[typeOfUser]?.isDoctor && !treatment?.isDoctorApproved && (
                <Box className='flexBox' sx={{ alignItems: 'center' }}>
                  <Typography
                    color={'darkblue'}
                    variant='subtitle1'
                    fontFamily={'Lato'}
                  >
                    The patient has added a new diagnosis. Please review and
                    approve it.
                  </Typography>
                  <IconButton
                    onClick={() =>
                      doctorApprovedPatientTreatment(treatment?.id)
                    }
                  >
                    <Check color='success' />
                  </IconButton>
                </Box>
              )}

              {treatmentsFromDB?.find(
                (each) => each?.treatment_name === treatment?.treatment_name
              ) ? (
                <Typography
                  variant='h5'
                  sx={{ fontFamily: 'Lato', textTransform: 'capitalize' }}
                >
                  {treatment?.treatment_name}
                </Typography>
              ) : (
                <TextField
                  sx={{ background: '#FFFFFF' }}
                  fullWidth
                  label='Diagnosis'
                  value={treatment?.treatment_name}
                  inputProps={{ style: { color: 'black' } }}
                  onChange={(e) => {
                    updateTreatments(
                      e.target.value,
                      'treatment',
                      treatment?.id
                    );
                  }}
                />
              )}
            </Box>
          }
          sx={{
            backgroundColor: 'var(--clr-theme-purple-20)',
          }}
          action={
            <div className='flexBox  flexCenter'>
              <IconButton
                color='error'
                onClick={() => handleOpenDialog(treatment)}
              >
                <Cancel />
              </IconButton>
              <IconButton color='default' onClick={handleClickOpen}>
                <Edit />
              </IconButton>
              <Dialog
                open={open}
                onClose={handleClose}
                sx={{ zIndex: 9999 }}
                maxWidth='lg'
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
              >
                <DialogTitle
                  id='new-fields-dialog-title'
                  sx={{
                    fontFamily: 'Lato',
                    backgroundColor: 'var(--clr-theme-purple)',
                    color: 'var(--clr-theme-white)',
                  }}
                >
                  Edit diagnosis, meds and therapy
                </DialogTitle>
                <IconButton
                  aria-label='close'
                  onClick={handleClose}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: 'var(--clr-theme-white)',
                  }}
                >
                  <Close />
                </IconButton>
                <CardContent sx={{ p: 5 }}>
                  <EditTreatment
                    treatment={treatment}
                    patientID={patientID}
                    studentID={studentID}
                    handleCloseDialog={handleClose}
                    setIsLoading={setIsLoading}
                    getTreatments={getTreatments}
                  />
                </CardContent>
              </Dialog>
            </div>
          }
          subheader={'Diagnosis #' + (index + 1)}
        />
        <CardContent>
          <DynamicList
            label={'Meds'}
            list={[...treatment?.meds]}
            treatmentID={treatment?.id}
            icon={<Medication />}
            updateTreatments={updateTreatments}
            treatmentsFromDB={treatmentsFromDB}
          />
          <DynamicList
            label={'Therapy'}
            list={[...treatment?.therapies]}
            treatmentID={treatment?.id}
            icon={<Psychology />}
            updateTreatments={updateTreatments}
            treatmentsFromDB={treatmentsFromDB}
          />
        </CardContent>
        <CardActions sx={{ color: 'var(--clr-theme-grayDark)' }}>
          <Box className='flexBox' gap={1}>
            <Typography
              fontFamily={'Lato'}
              sx={{ textTransform: 'capitalize' }}
            >
              Added by:
            </Typography>
            <Typography
              fontFamily={'Lato'}
              sx={{ textTransform: 'capitalize', color: 'green' }}
            >
              {treatment?.userType === 'DOCTOR'
                ? 'Healthcare Professional'
                : treatment?.userType
                    ?.split(/\s+/)
                    .map(
                      (word) =>
                        word.charAt(0).toUpperCase() +
                        word.slice(1).toLowerCase()
                    )
                    .join(' ')}{' '}
              {/* <VerifiedUser /> */}
            </Typography>
          </Box>
          <Box className='flexBox' gap={1}>
            <Typography fontFamily={'Lato'}>Name:</Typography>
            <Typography
              fontFamily={'Lato'}
              sx={{ textTransform: 'capitalize', color: 'green' }}
            >
              {treatment?.userName}
            </Typography>
          </Box>
        </CardActions>
      </Card>
      <Dialog
        sx={{ zIndex: 9999 }}
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title' fontFamily={'Lato'}>
          Are you sure you want to delete this treatment?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description' fontFamily={'Lato'}>
            This treatment will be deleted permanentely. You can't undo this
            action.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant='outlined'
            onClick={handleCloseDialog}
            color='primary'
            sx={{
              color: 'var(--clr-theme-purple)',
              border: '1px solid var(--clr-theme-purple)',
            }}
          >
            Cancel
          </Button>
          <Button
            variant='contained'
            onClick={handleDeleteItem}
            color='error'
            sx={{ backgroundColor: 'var(--clr-theme-purple)' }}
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export const DynamicList = ({
  label,
  list,
  icon,
  updateTreatments,
  treatmentID,
  treatment,
  treatmentsFromDB,
}) => {
  const [med, setMed] = useState({
    drug_name: '',
    dosage: '',
    tradeName: '',
    usage: '',
    range: '',
    sideEffects: '',
    treats: '',
  });
  const [therapy, setTherapy] = useState({
    costPerHours: '',
    hoursPerSession: '',
    sessionsPerWeek: '',
    therapy_name: '',
  });
  const [myList, setMyList] = useState(list);
  const [isCustomSymptom, setIsCustomSymptom] = useState(false);

  const initialSymptom = (treatment && treatment.symptom) || '';
  const [selectedSymptom, setSelectedSymptom] = useState(initialSymptom);

  const existedtreatment = treatmentsFromDB?.find(
    (treatment) => treatment.id === treatmentID
  );

  const { data: symptoms } = useSymptoms();

  const changeHandler = (e, label, field) => {
    var uniqMedId = 'Med' + new Date().getTime();
    var uniqTherapyId = 'Therapy' + new Date().getTime();
    if (label === 'Therapy') {
      if (field === 'name') {
        setTherapy({
          ...therapy,
          therapy_name: e.target.value,
          id: uniqTherapyId,
        });
      } else if (field === 'cost') {
        setTherapy({ ...therapy, costPerHours: e.target.value });
      } else if (field === 'hoursPerSession') {
        setTherapy({ ...therapy, hoursPerSession: e.target.value });
      } else if (field === 'sessionsPerWeek') {
        setTherapy({ ...therapy, sessionsPerWeek: e.target.value });
      }
    } else {
      // setMed({ ...med, [e.target.name]: e.target.value });
      if (field === 'name') {
        setMed({ ...med, drug_name: e.target.value, id: uniqMedId });
        updateTreatments([...myList, med], label, treatmentID);
      } else if (field === 'dosage') {
        setMed({ ...med, dosage: parseFloat(e.target.value) });
      } else if (field === 'TradeName') {
        setMed({ ...med, tradeName: e.target.value });
      } else if (field === 'Usage') {
        setMed({ ...med, usage: e.target.value });
      } else if (field === 'SideEffects') {
        setMed({ ...med, sideEffects: e.target.value });
      } else if (field === 'Treats') {
        setMed({ ...med, treats: e.target.value });
      } else if (field === 'range') {
        setMed({ ...med, range: e.target.value });
      } else if (field === 'Symptom') {
        setMed({ ...med, symptom: e.target.value });
      }
    }
  };

  const addListItem = (label, id) => {
    if (label === 'Therapy') {
      setMyList([...myList, therapy]);
      setTherapy({
        ...therapy,
        costPerHours: '',
        hoursPerSession: '',
        sessionsPerWeek: '',
        therapy_name: '',
      });
      updateTreatments([...myList, therapy], label, id);
    } else {
      if (label === 'Meds') {
        setMyList([...myList, med]);
        setMed({
          ...med,
          drug_name: '',
          dosage: '',
          tradeName: '',
          usage: '',
          range: '',
          sideEffects: '',
          treats: '',
        });
        updateTreatments([...myList, med], label, id);
      }
    }
  };

  const removeItem = (item) => {
    setMyList([...myList.filter((x) => x !== item)]);
    updateTreatments([...myList.filter((x) => x !== item)], label, treatmentID);
  };

  useEffect(() => {
    if (label === 'Meds') {
      updateTreatments([...myList, med], label, treatmentID);
    }
    if (label === 'Therapy') {
      updateTreatments([...myList, therapy], label, treatmentID);
    }
  }, [med, therapy]);

  const symptomHandler = (e) => {
    const selectedValue = e.target.value;
    setSelectedSymptom(selectedValue);

    if (selectedValue !== 'other') {
      changeHandler(e, 'Symptom', 'Symptom');
      setIsCustomSymptom(false);
    } else {
      setIsCustomSymptom(true);
    }
  };

  return (
    <Box className='Treatment'>
      <List
        subheader={label}
        sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}
      >
        {myList?.map((item, idx) => {
          return (
            <ListItem
              sx={{
                color: 'var(--clr-theme-gray)',
                background: 'var(--clr-theme-purple-20)',
                borderRadius: '5px',
              }}
              key={idx}
              secondaryAction={
                <IconButton
                  edge='end'
                  aria-label='delete'
                  onClick={() => removeItem(item)}
                  color='error'
                >
                  <Delete />
                </IconButton>
              }
            >
              <ListItemIcon sx={{ color: 'var(--clr-success)' }}>
                {icon}
              </ListItemIcon>
              <ListItemText>
                {label === 'Therapy' ? (
                  // `Therapy name: ${item?.therapy_name}`
                  <div>
                    <strong>Therapy name:</strong> {item?.therapy_name}
                  </div>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: 25,
                    }}
                  >
                    {item?.drug_name && (
                      <div>
                        <strong>Drug name:</strong> {item.drug_name}
                      </div>
                    )}
                    {item?.dosage && (
                      <div>
                        <strong>Dosage:</strong> {item.dosage} mg
                      </div>
                    )}
                    {item?.usage && (
                      <div>
                        <strong>Usage:</strong> {item.usage} times
                      </div>
                    )}
                    {item?.range && (
                      <div>
                        <strong>Range:</strong> {item.range}
                      </div>
                    )}
                    {item?.tradeName && (
                      <div>
                        <strong>Trade Name:</strong> {item.tradeName}
                      </div>
                    )}
                    {item?.tradeName && (
                      <div>
                        <strong>Symptom:</strong> {item.symptom}
                      </div>
                    )}
                  </div>
                )}
              </ListItemText>
            </ListItem>
          );
        })}
      </List>
      {!existedtreatment && (
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: 1,
            alignItems: 'center',
          }}
        >
          {label === 'Therapy' ? (
            <>
              <TextField
                sx={{ width: '40%' }}
                label='Therapy name'
                value={therapy?.therapy_name}
                inputProps={{ style: { color: 'black' } }}
                onChange={(e) => changeHandler(e, label, 'name')}
              />
              <TextField
                sx={{ width: '40%' }}
                label='Cost per hours'
                value={therapy?.costPerHours}
                disabled={therapy?.therapy_name.length === 0}
                onChange={(e) => changeHandler(e, label, 'cost')}
              />
              <TextField
                sx={{ width: '40%' }}
                label='Hours per session'
                value={therapy?.hoursPerSession}
                disabled={therapy?.therapy_name.length === 0}
                onChange={(e) => changeHandler(e, label, 'hoursPerSession')}
              />
              <TextField
                sx={{ width: '40%' }}
                label='Sessions per week'
                value={therapy?.sessionsPerWeek}
                disabled={therapy?.therapy_name.length === 0}
                onChange={(e) => changeHandler(e, label, 'sessionsPerWeek')}
              />
            </>
          ) : (
            <>
              <TextField
                sx={{ width: '40%' }}
                label='Drug name'
                name='drug_name'
                value={med?.drug_name}
                onChange={(e) => changeHandler(e, label, 'name')}
              />
              <TextField
                sx={{ width: '40%' }}
                label='Trade name'
                name='tradeName'
                value={med?.tradeName}
                disabled={med?.drug_name.length === 0}
                onChange={(e) => changeHandler(e, label, 'TradeName')}
              />
              <TextField
                sx={{ width: '40%' }}
                type='number'
                name='dosage'
                label='Dosage'
                value={med?.dosage}
                disabled={med?.drug_name.length === 0}
                onChange={(e) => changeHandler(e, label, 'dosage')}
                InputProps={{
                  inputProps: {
                    max: 100,
                    min: 0,
                    step: 0.1,
                  },
                  endAdornment: (
                    <InputAdornment position='end'>mg</InputAdornment>
                  ),
                }}
              />
              {/* <Typography gutterBottom sx={{ color: "grey" }}>
                Usage:
              </Typography> */}
              <TextField
                name='usage'
                // sx={{ ml: 1, width: "53%" }}
                sx={{ width: '40%' }}
                type='number'
                label='Usage'
                value={med?.usage}
                disabled={med?.drug_name.length === 0}
                onChange={(e) => changeHandler(e, label, 'Usage')}
                InputProps={{
                  inputProps: {
                    max: 100,
                    min: 0,
                    step: 1,
                  },
                  endAdornment: (
                    <InputAdornment position='end'>times</InputAdornment>
                  ),
                }}
              />

              <FormControl>
                <NativeSelect
                  // defaultValue={"Per Day"}
                  sx={{
                    p: 1,
                    borderColor: 'transparent',
                    borderRadius: 2,
                    backgroundColor: 'var(--clr-theme-purple-50)',
                  }}
                  autoFocus={false}
                  name='range'
                  value={med?.range}
                  onChange={(e) => changeHandler(e, label, 'range')}
                  disabled={med?.drug_name.length === 0}
                >
                  <option value='Per Day'>per day</option>
                  <option value='Per Week'>per week</option>
                  <option value='Per Month'>per month</option>
                </NativeSelect>
              </FormControl>

              <TextField
                sx={{ width: '40%' }}
                label='Side effects'
                name='sideEffects'
                value={med?.sideEffects}
                disabled={med?.drug_name.length === 0}
                onChange={(e) => changeHandler(e, label, 'SideEffects')}
              />
              <FormControl sx={{ width: '40%' }}>
                <NativeSelect
                  fullWidth
                  sx={{
                    p: 1,
                    border: '1px solid #ccc',
                    borderRadius: '15px',
                    backgroundColor: 'var(--clr-theme-white)',
                    display: 'flex',
                  }}
                  value={isCustomSymptom ? 'other' : selectedSymptom}
                  onChange={symptomHandler}
                  inputProps={{
                    name: 'symptom',
                    id: 'symptom-native-select',
                  }}
                >
                  <option value='' disabled>
                    Select symptom
                  </option>
                  {symptoms?.length > 0 &&
                    symptoms?.map((item, i) => (
                      <option key={i} value={item}>
                        {item}
                      </option>
                    ))}
                  <option value='other'>Other</option>
                </NativeSelect>
              </FormControl>
              {isCustomSymptom ? (
                <TextField
                  sx={{ width: '40%' }}
                  label='Add symptom'
                  onChange={(e) => {
                    changeHandler(e, label, 'Symptom');
                  }}
                />
              ) : null}
            </>
          )}
          <IconButton onClick={() => addListItem(label, treatmentID)}>
            <Add></Add>
          </IconButton>
        </Box>
      )}
    </Box>
  );
};
export default Treatments;
