import React, { useContext, useEffect, useState, useMemo } from 'react';
import {
  Box,
  TextField,
  MenuItem,
  Typography,
  Chip,
  InputLabel,
  FormControl,
  Select,
  Grid,
  Radio,
} from '@mui/material';
import { scaleOrdinal } from 'd3-scale';
import { schemeCategory10 } from 'd3-scale-chromatic';
import {
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  LineChart,
  Line,
  Label,
  Legend,
} from 'recharts';
import AuthContext from '../../contexts/AuthContext';
import MainLayout from '../../hoc/MainLayout';
import {
  ALL_MONTHS,
  getDateByPastMonths,
  getPastMonthNames,
} from '../../utils/helper';
import './Statistics.css';
import usePatientsForDoctor from '../../hooks/usePatientsForDoctor';
import { getPatientVideos } from '../../api';
import Progress from '../../components/Progress';
import { LogAnalyticsEvent } from '../../utils/firebase/firebase';
import { ANALYTICS_KEYS } from '../../constants/AnalyticKeys';
import { ACMContext } from '../../contexts/ACMProvider';
import useAllPatientsVideos from '../../hooks/useAllPatientVideos';
import useOnePatientVideos from '../../hooks/useOnePatientVideos';
import useStudentsForOneTeacher from '../../hooks/useStudentsForOneTeacher';
import useGetAllVideoFeedbackData from '../../hooks/useGetAllVideoFeedbackData';

const DURATION_MONTHS = 6;
const colors = scaleOrdinal(schemeCategory10).range();

const getPath = (x, y, width, height) => {
  return `M${x},${y + height}C${x + width / 3},${y + height} ${x + width / 2},${
    y + height / 3
  }
    ${x + width / 2}, ${y}
    C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + height} ${
    x + width
  }, ${y + height}
    Z`;
};

const TriangleBar = (props) => {
  const { fill, x, y, width, height } = props;
  return <path d={getPath(x, y, width, height)} stroke='none' fill={fill} />;
};

const Statistics = () => {
  const { ACM } = useContext(ACMContext);
  const { user, typeOfUser } = useContext(AuthContext);
  const {
    data: patients = [],
    isLoading: isPatientDataLoading,
    error: errorPatientData,
  } = usePatientsForDoctor(user?.uid);

  const {
    data: students = [],
    isLoading: isStudentDataLoading,
    error: errorStudentData,
  } = useStudentsForOneTeacher(user?.uid, typeOfUser);

  const [patientData, setPatientData] = useState([]);
  const [studentData, setStudentData] = useState([]);
  const [data, setData] = useState([]);
  const [symptomData, setSymptomData] = useState([]);
  const [showSymptomsDropdown, setShowSymptomsDropdown] = useState(false);
  const [showBehaviorsDropdown, setShowBehaviorsDropdown] = useState(false);
  const [selectedPatientId, setSelectedPatientId] = useState('');
  const [selectedStudentId, setSelectedStudentId] = useState('');
  const [selectedGraphType, setSelectedGraphType] = useState(
    ACM[typeOfUser]?.isPatient || ACM[typeOfUser]?.isStudent
      ? 'videoUploads'
      : 'rating'
  );
  const [selectedDuration, setSelectedDuration] = useState('6months');
  const [symptomsList, setSymptomsList] = useState('');
  const [selectedSymptoms, setSelectedSymptoms] = useState([]);
  const [totalVideos, setTotalVideos] = useState(0);
  const [behaviorsList, setBehaviorsList] = useState([]);
  const [selectedBehaviors, setSelectedBehaviors] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const currentMonths = getPastMonthNames(DURATION_MONTHS);

  useEffect(() => {
    if (patients) {
      const dataArray = patients?.map((each) => ({
        ...each?.patientInfo,
        id: each?.patientId,
      }));
      setPatientData(dataArray);
      setSelectedPatientId(dataArray[0]?.id);
    }
    if (students) {
      const dataArray = students?.map((each) => ({
        id: each.id,
        name: each.name,
        email: each.email,
        parentName: each.parentName,
        schoolName: each.schoolName,
        photoURL: each.photoURL,
      }));
      setStudentData(dataArray);
      setSelectedStudentId(dataArray[0]?.id);
    }
  }, [patients, students]);

  const totalVideosUploadedByPatients = async () => {
    let videosCount = 0;
    const promises = [];

    try {
      // Assuming your data structure is stored in some variable, replace it with your actual data variable
      const patientsData = allPatientsAllVideos?.data;

      patientsData.forEach((patient) => {
        const videos = patient.videos || [];
        videosCount += videos.length;
      });

      await Promise.all(promises);

      setTotalVideos(videosCount);
    } catch (error) {
      console.error('Error counting videos:', error);
    }
  };

  const PatientsDropdown = ({
    patientData,
    studentData,
    selectedStudentId,
    selectedPatientId,
    onPatientChange,
  }) => {
    const handlePatientChange = (newPatientId) => {
      resetMenuSelections();
      onPatientChange(newPatientId);
      LogAnalyticsEvent('doctor_statsview_per_patient', {
        patientId: newPatientId,
      });
    };

    const capitalizeWords = (name) => {
      return name
        ?.split(' ')
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join(' ');
    };

    const truncateName = (name, limit = 10) => {
      const capitalized = capitalizeWords(name);
      if (capitalized?.length > limit) {
        return `${capitalized.slice(0, limit)}...`;
      }
      return capitalized;
    };
    return (
      <TextField
        select
        label={ACM[typeOfUser]?.isTeacher ? 'Select Student' : 'Select Patient'}
        value={
          ACM[typeOfUser]?.isTeacher ? selectedStudentId : selectedPatientId
        }
        // onChange={(event) => onPatientChange(event.target.value)}
        onChange={(event) => handlePatientChange(event.target.value)}
        sx={{ mb: 1, width: 160 }}
      >
        {ACM[typeOfUser]?.isTeacher
          ? studentData?.map((student) => (
              <MenuItem key={student.id} value={student.id}>
                {student?.parentName
                  ? `${truncateName(student.name)} (${truncateName(
                      student.parentName
                    )})`
                  : truncateName(student.name)}
              </MenuItem>
            ))
          : patientData?.map((patient) => (
              <MenuItem key={patient.id} value={patient.id}>
                {patient?.parentName
                  ? `${truncateName(patient.fullname)} (${truncateName(
                      patient.parentName
                    )})`
                  : truncateName(patient.fullname)}
              </MenuItem>
            ))}
      </TextField>
    );
  };

  const resetMenuSelections = () => {
    setSelectedGraphType('rating');
    setSelectedDuration('6months');
    setSelectedSymptoms([]);
    setShowSymptomsDropdown(false);
    setSelectedBehaviors([]);
    setShowBehaviorsDropdown(false);
  };

  const TimeFilter = ({ selectedDuration, onDurationChange }) => {
    return (
      <TextField
        select
        label='Select Time Duration'
        value={selectedDuration}
        onChange={(event) => onDurationChange(event.target.value)}
        sx={{ width: 250 }}
        size='small'
      >
        {(selectedGraphType === 'rating' ||
          selectedGraphType === 'symptom' ||
          selectedGraphType === 'videoUploads') && (
          <MenuItem value='6months'>6 Months</MenuItem>
        )}
        <MenuItem value='1month'>1 Month</MenuItem>
        <MenuItem value='1week'>1 Week</MenuItem>
      </TextField>
    );
  };

  const GraphTypeFilter = ({ selectedGraphType, onGraphTypeChange }) => {
    const { ACM } = useContext(ACMContext);
    return (
      <TextField
        select
        label='Select Graph Type'
        value={selectedGraphType}
        onChange={(event) => onGraphTypeChange(event.target.value)}
        sx={{ width: 250 }}
        size='small'
      >
        {/* {typeOfUser === "DOCTOR" && <MenuItem value="rating">Rating</MenuItem>}
        {typeOfUser === "DOCTOR" && (
          <MenuItem value="symptom">Symptom</MenuItem>
        )}
        {typeOfUser === "PATIENT" && (
          <MenuItem value="videoUploads">Average Videos</MenuItem>
        )}
        {typeOfUser === "DOCTOR" && selectedGraphType !== "videoUploads" && (
          <MenuItem value="frequency">Frequency</MenuItem>
        )} */}
        {(ACM[typeOfUser]?.isDoctor || ACM[typeOfUser]?.isTeacher) && (
          <MenuItem value='rating'>Rating</MenuItem>
        )}
        {(ACM[typeOfUser]?.isDoctor || ACM[typeOfUser]?.isTeacher) && (
          <MenuItem value='symptom'>Symptom</MenuItem>
        )}
        {(ACM[typeOfUser]?.isPatient || ACM[typeOfUser]?.isStudent) && (
          <MenuItem value='videoUploads'>Average Videos</MenuItem>
        )}
        {(ACM[typeOfUser]?.isDoctor || ACM[typeOfUser]?.isTeacher) &&
          selectedGraphType !== 'videoUploads' && (
            <MenuItem value='frequency'>Frequency</MenuItem>
          )}
      </TextField>
    );
  };

  const SymptomsFilter = ({ selectedSymptoms, handleSymptomChange }) => {
    return (
      <FormControl sx={{ width: 250 }} size='small'>
        <InputLabel>Select Symptoms</InputLabel>
        <Select
          label='Select Symptoms'
          MenuProps={{
            PaperProps: { style: { maxHeight: 300 }, autoWidth: false },
          }}
          multiple
          value={selectedSymptoms}
          onChange={handleSymptomChange}
          renderValue={(selected) => (
            <div>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </div>
          )}
        >
          {symptomsList &&
            symptomsList?.map((symptom) => (
              <MenuItem key={symptom} value={symptom}>
                <Radio
                  checked={selectedSymptoms.includes(symptom)}
                  color='primary'
                />
                {symptom}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    );
  };
  const BehavioursFilter = React.memo(
    ({ selectedBehaviors, handleSelectChange, behaviorsList }) => (
      <FormControl sx={{ width: 250 }} size='small'>
        <InputLabel>Select Behaviors</InputLabel>
        <Select
          label='Select Behaviors'
          MenuProps={{
            PaperProps: { style: { maxHeight: 300 }, autoWidth: false },
          }}
          multiple
          value={selectedBehaviors}
          onChange={handleSelectChange}
          renderValue={(selected) => (
            <div>
              {selected?.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </div>
          )}
        >
          {behaviorsList?.map((behavior) => (
            <MenuItem key={behavior} value={behavior}>
              <Radio
                checked={selectedBehaviors?.includes(behavior)}
                color='primary'
              />
              {behavior}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  );

  const ratingStats = ANALYTICS_KEYS.STATISTICS.PATIENTSTATSFORRATING;
  const symptomStats = ANALYTICS_KEYS.STATISTICS.PATIENTSTATSFORSYMPTOM;
  const frequencyStats = ANALYTICS_KEYS.STATISTICS.PATIENTSTATSFORFREQUENCY;

  const { data: patientVideoFeedback, isLoading: patientVideoFeedbackLoading } =
    useGetAllVideoFeedbackData(
      ACM[typeOfUser]?.Teacher || ACM[typeOfUser]?.isDoctor
        ? selectedPatientId || selectedStudentId
        : user?.uid
    );

  const getStatisticsByDate = async (id, duration) => {
    try {
      setIsLoading(true);
      const feedbackData = patientVideoFeedback;

      if (feedbackData) {
        const ratingArray = [];
        const symptomsArray = [];
        const symptomsListArr = [];
        const symptomsMap = new Map();

        feedbackData.forEach((item) => {
          const docId = item.docId;
          const time = item.docData.time?._seconds || 0;
          const date = new Date(time * 1000).toLocaleDateString() || '-';

          if (item.docData.treatments) {
            item.docData.treatments.forEach((treatment) => {
              const currentTimeStamp = new Date(time * 1000);
              const timeDifference = Date.now() - currentTimeStamp.getTime();

              if (timeDifference <= duration) {
                ratingArray.push({
                  videoID: `${docId} V${ratingArray.length}`,
                  date: currentTimeStamp.toLocaleDateString(),
                  rating: treatment.rating,
                });

                const symptom = treatment.symptom;
                if (selectedSymptoms.includes(symptom)) {
                  symptomsArray.push({
                    videoID: `${docId} V${symptomsArray.length}`,
                    date: currentTimeStamp.toLocaleDateString(),
                    symptom: symptom,
                    rating: treatment.rating,
                  });
                }

                symptomsListArr.push(symptom);
              }
            });
          }
        });

        const result = [];
        ratingArray.reduce(function (res, value) {
          if (!res[value.date]) {
            res[value.date] = {
              date: value.date,
              rating: 0,
            };
            result.push(res[value.date]);
          }
          res[value.date].rating +=
            value.rating /
            ratingArray.filter((x) => x.date === value.date).length;
          return res;
        }, {});

        symptomsArray.forEach((entry) => {
          const existingSymptom = symptomsMap.get(entry.date);

          if (existingSymptom) {
            existingSymptom[entry.symptom] = entry.rating;
          } else {
            symptomsMap.set(entry.date, { [entry.symptom]: entry.rating });
          }
        });

        const aggregatedSymptomData = Array.from(symptomsMap).map(
          ([date, symptoms]) => ({
            date: date,
            ...symptoms,
          })
        );

        setSymptomData(aggregatedSymptomData);

        const cleanedSymptomsListArr = symptomsListArr
          .filter(
            (item) =>
              (typeof item === 'string' && item) ||
              (Array.isArray(item) && item.length > 0)
          )
          .flatMap((item) =>
            typeof item === 'string' ? item : item.map((obj) => obj.behaviour)
          );

        setSymptomsList([...new Set(cleanedSymptomsListArr)]);
        setData(result);
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching statistics by date:', error);
    }
  };

  useEffect(() => {
    const durationInMillis = getDurationInMillis(selectedDuration);

    if (selectedGraphType === 'rating') {
      LogAnalyticsEvent(ratingStats, {
        duration: selectedDuration,
        typeOfUser: typeOfUser,
        selectedPatientId: selectedPatientId || user?.uid,
        // selectedStudentId: selectedStudentId || user?.uid,
      });
    } else if (selectedGraphType === 'symptom') {
      LogAnalyticsEvent(symptomStats, {
        duration: selectedDuration,
        typeOfUser: typeOfUser,
        selectedPatientId: selectedPatientId || user?.uid,
        // selectedStudentId: selectedStudentId || user?.uid,
      });
    } else if (selectedGraphType === 'frequency') {
      LogAnalyticsEvent(frequencyStats, {
        duration: selectedDuration,
        typeOfUser: typeOfUser,
        selectedPatientId: selectedPatientId || user?.uid,
        // selectedStudentId: selectedStudentId || user?.uid,
      });
    }
    // if (typeOfUser === "DOCTOR") {
    if (ACM[typeOfUser]?.isDoctor || ACM[typeOfUser]?.isTeacher) {
      if (
        (selectedPatientId || selectedStudentId) &&
        (selectedDuration === '1week' || selectedDuration === '1month')
      ) {
        // Filter data by selected symptoms
        const filteredData = symptomData.filter((entry) =>
          selectedSymptoms.includes(entry.symptom)
        );
        getStatisticsByDate(
          selectedPatientId || selectedStudentId,
          durationInMillis,
          filteredData
        );
      } else if (
        (selectedPatientId || selectedStudentId) &&
        selectedDuration === '6months'
      ) {
        // Filter data by selected symptoms
        const filteredData = symptomData.filter((entry) =>
          selectedSymptoms.includes(entry.symptom)
        );
        get6monthStatistics(
          selectedPatientId || selectedStudentId,
          filteredData
        );
      }
    } else if (ACM[typeOfUser]?.isPatient) {
      if (selectedDuration === '1week' || selectedDuration === '1month') {
        // Filter data by selected symptoms
        const filteredData = symptomData.filter((entry) =>
          selectedSymptoms.includes(entry.symptom)
        );
        getStatisticsByDate(user?.uid, durationInMillis, filteredData);
      } else if (selectedDuration === '6months') {
        // Filter data by selected symptoms
        const filteredData = symptomData.filter((entry) =>
          selectedSymptoms.includes(entry.symptom)
        );
        get6monthStatistics(user?.uid, filteredData);
      }
    }
  }, [
    typeOfUser,
    selectedPatientId,
    selectedStudentId,
    selectedDuration,
    user,
    selectedSymptoms,
  ]);

  const {
    data: allPatientsAllVideos,
    isLoading: loadingfetchAllPatientsAllVideos,
    error: errorDoctor,
  } = useAllPatientsVideos(user?.uid);

  useEffect(() => {
    if (ACM[typeOfUser]?.isDoctor) {
      totalVideosUploadedByPatients();
    }
  }, [typeOfUser]);

  const handleTimeFilterChange = (selectedDuration) => {
    setSelectedDuration(selectedDuration);
  };

  const handleGraphTypeChange = (selectedGraphType) => {
    setSelectedGraphType(selectedGraphType);
    if (selectedGraphType === 'symptom') {
      setShowSymptomsDropdown(true);
      setSelectedBehaviors([]);
    } else {
      setShowSymptomsDropdown(false);
      setSelectedSymptoms([]);
    }
    if (selectedGraphType === 'frequency') {
      setShowBehaviorsDropdown(true);
      setSelectedSymptoms([]);
    } else {
      setShowBehaviorsDropdown(false);
      setSelectedBehaviors([]);
    }
  };

  const handleSelectChange = (event) => {
    if (selectedGraphType === 'symptom') {
      setSelectedSymptoms(event.target.value);
    }
    if (selectedGraphType === 'frequency') {
      setSelectedBehaviors(event.target.value);
    }
  };

  const get6monthStatistics = async (id) => {
    try {
      setIsLoading(true);
      const feedbackData = patientVideoFeedback;
      if (feedbackData) {
        const ratingArray = [];
        const symptomsArray = [];
        const symptomsListArr = [];
        const symptomsMap = new Map();

        const currentDate = new Date();
        const pastSixMonths = [];
        for (let i = 0; i < 6; i++) {
          const date = new Date(currentDate);
          date.setMonth(currentDate.getMonth() - i);
          pastSixMonths.push(ALL_MONTHS[date.getMonth()]);
        }
        pastSixMonths.reverse();

        feedbackData.forEach((item) => {
          const docId = item.docId;
          const time = item.docData.time?._seconds || 0;
          const currentTimeStamp = new Date(time * 1000);
          const timeDifference = Date.now() - currentTimeStamp.getTime();

          if (timeDifference <= getDateByPastMonths(6)) {
            if (item.docData.treatments) {
              item.docData.treatments.forEach((treatment) => {
                ratingArray.push({
                  videoID: `${docId} V${ratingArray.length}`,
                  month: ALL_MONTHS[currentTimeStamp.getMonth()],
                  rating: treatment.rating,
                });

                const symptom = treatment.symptom;
                if (selectedSymptoms.includes(symptom)) {
                  symptomsArray.push({
                    videoID: `${docId} V${symptomsArray.length}`,
                    month: ALL_MONTHS[currentTimeStamp.getMonth()],
                    symptom: symptom,
                    rating: treatment.rating,
                  });
                }
                symptomsListArr.push(symptom);
              });
            }
          }
        });

        const resultData = ratingArray.reduce((res, value) => {
          //  average rating per month
          if (!res[value.month]) {
            res[value.month] = {
              month: value.month,
              rating: 0,
              count: 0,
            };
          }
          res[value.month].rating += value.rating;
          res[value.month].count += 1;
          return res;
        }, {});

        const newData = currentMonths.map((month) => {
          const monthData = resultData[month];
          const averageRating = monthData
            ? monthData.rating / monthData.count
            : 0;
          return {
            month: month,
            average_rating: Math.round(averageRating || 0),
          };
        });

        const isAllZero = newData.every((item) => item.average_rating === 0);
        const finalData = isAllZero ? null : newData;

        symptomsArray.forEach((entry) => {
          const existingSymptom = symptomsMap.get(entry.month) || {};
          if (!existingSymptom[entry.symptom]) {
            existingSymptom[entry.symptom] = {
              rating: 0,
              count: 0,
            };
          }
          existingSymptom[entry.symptom].rating += entry.rating;
          existingSymptom[entry.symptom].count += 1;
          symptomsMap.set(entry.month, existingSymptom);
        });

        // Aggregate symptoms data and showing missing months with null
        const aggregatedSymptomData = pastSixMonths.map((month) => {
          const symptoms = symptomsMap.get(month) || {};
          const symptomsWithAverages = {};
          selectedSymptoms.forEach((symptom) => {
            const symptomData = symptoms[symptom];
            const averageRating = symptomData
              ? symptomData.rating / symptomData.count
              : null;
            symptomsWithAverages[symptom] = averageRating;
          });
          return {
            month: month,
            ...symptomsWithAverages,
          };
        });

        setSymptomData(aggregatedSymptomData);
        const cleanedSymptomsListArr = symptomsListArr
          .filter(
            (item) =>
              (typeof item === 'string' && item) ||
              (Array.isArray(item) && item.length > 0)
          )
          .flatMap((item) =>
            typeof item === 'string' ? item : item.map((obj) => obj.behaviour)
          );

        setSymptomsList([...new Set(cleanedSymptomsListArr)]);

        setData(finalData);
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching 6-month statistics:', error);
      setIsLoading(false);
    }
  };

  const getDurationInMillis = (duration) => {
    switch (duration) {
      case '1week':
        return 7 * 24 * 60 * 60 * 1000;
      case '1month':
        return 30 * 24 * 60 * 60 * 1000;
      case '6months':
        return 6 * 30 * 24 * 60 * 60 * 1000;
      default:
        return 6 * 30 * 24 * 60 * 60 * 1000;
    }
  };

  useEffect(() => {
    setSelectedSymptoms([]);
  }, [selectedDuration]);

  if (isLoading || isPatientDataLoading || loadingfetchAllPatientsAllVideos)
    return <Progress size={'2rem'} />;

  return (
    <MainLayout>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            {ACM[typeOfUser]?.statisticsScreenPatientList && (
              <PatientsDropdown
                patientData={patientData}
                studentData={studentData}
                selectedPatientId={selectedPatientId}
                selectedStudentId={selectedStudentId}
                onPatientChange={(newPatientId) => {
                  ACM[typeOfUser]?.isTeacher
                    ? setSelectedStudentId(newPatientId)
                    : setSelectedPatientId(newPatientId);
                  resetMenuSelections();
                }}
              />
            )}
            <Box className='chartContainer'>
              <div className='alignFilters'>
                <GraphTypeFilter
                  selectedGraphType={selectedGraphType}
                  onGraphTypeChange={handleGraphTypeChange}
                  typeOfUser={typeOfUser}
                />
                <TimeFilter
                  selectedDuration={selectedDuration}
                  onDurationChange={handleTimeFilterChange}
                />
                {showSymptomsDropdown && (
                  <SymptomsFilter
                    selectedSymptoms={selectedSymptoms}
                    handleSymptomChange={handleSelectChange}
                    symptomsList={symptomsList}
                  />
                )}
                {showBehaviorsDropdown && (
                  <BehavioursFilter
                    selectedBehaviors={selectedBehaviors}
                    handleSelectChange={handleSelectChange}
                    behaviorsList={behaviorsList}
                  />
                )}
              </div>
              <div className='adjustChart'>
                {selectedGraphType === 'rating' && (
                  <RatingLineChart
                    data={data}
                    selectedDuration={selectedDuration}
                    isLoading={isLoading}
                  />
                )}
                {selectedGraphType === 'frequency' && showBehaviorsDropdown && (
                  <BehaviourStats
                    selectedPatientId={selectedPatientId}
                    selectedStudentId={selectedStudentId}
                    getDurationInMillis={getDurationInMillis}
                    selectedDuration={selectedDuration}
                    user={user}
                    typeOfUser={typeOfUser}
                    behaviorsList={behaviorsList}
                    setBehaviorsList={setBehaviorsList}
                    selectedBehaviors={selectedBehaviors}
                  />
                )}
                {selectedGraphType === 'symptom' && showSymptomsDropdown && (
                  <SymptomRatingLineChart
                    data={symptomData}
                    selectedSymptoms={selectedSymptoms}
                    selectedDuration={selectedDuration}
                  />
                )}
                {selectedGraphType === 'videoUploads' && (
                  <VideoUploadStats
                    data={data}
                    getDurationInMillis={getDurationInMillis}
                    selectedDuration={selectedDuration}
                  />
                )}
              </div>
            </Box>
          </Grid>
          {(ACM[typeOfUser]?.isDoctor || ACM[typeOfUser]?.isTeacher) && (
            <Grid item xs={12} md={4}>
              <Box className='flexBox flexWrap contentContainer'>
                <Typography
                  variant='h5'
                  sx={{
                    fontFamily: 'Lato',
                    textAlign: 'center',
                    color: 'var(--clr-theme-purple)',
                    marginTop: '10px',
                  }}
                >
                  General Stats
                </Typography>
                <Box className=' Generalstat flexBox flexColumn'>
                  <Typography
                    gutterBottom
                    className='status flexBox flexColumn'
                    sx={{
                      fontFamily: 'Lato',
                      margin: '1px',
                      backgroundColor: 'var(--clr-theme-lightG)',
                      textAlign: 'left',
                    }}
                  >
                    <Box
                      sx={{
                        fontSize: '1.4rem',
                        color: 'var(--clr-theme-gray)',
                      }}
                    >
                      {totalVideos}
                    </Box>
                    {ACM[typeOfUser]?.isDoctor ? (
                      <Box
                        sx={{
                          color: 'var(--clr-theme-purple)',
                          fontSize: '1rem',
                        }}
                      >
                        Total videos uploaded by patients
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          color: 'var(--clr-theme-purple)',
                          fontSize: '1rem',
                        }}
                      >
                        Total videos uploaded by students
                      </Box>
                    )}
                  </Typography>

                  <Typography
                    gutterBottom
                    className='status flexBox flexColumn'
                    sx={{
                      fontFamily: 'Lato',
                      margin: '1px',
                      backgroundColor: 'var(--clr-theme-White),',
                      textAlign: 'left',
                    }}
                  >
                    {ACM[typeOfUser]?.isDoctor ? (
                      <Box
                        sx={{
                          fontSize: '1.4rem',
                          color: 'var(--clr-theme-gray)',
                        }}
                      >
                        {patientData?.length}
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          fontSize: '1.4rem',
                          color: 'var(--clr-theme-gray)',
                        }}
                      >
                        {studentData?.length}
                      </Box>
                    )}
                    {ACM[typeOfUser]?.isDoctor ? (
                      <Box
                        sx={{
                          color: 'var(--clr-theme-purple)',
                          fontSize: '1rem',
                        }}
                      >
                        Total number of patients
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          color: 'var(--clr-theme-purple)',
                          fontSize: '1rem',
                        }}
                      >
                        Total number of students
                      </Box>
                    )}
                  </Typography>

                  <Typography
                    gutterBottom
                    className='status flexBox flexColumn'
                    sx={{
                      fontFamily: 'Lato',
                      margin: '1px',
                      backgroundColor: 'var(--clr-theme-lightG)',
                      textAlign: 'left',
                    }}
                  >
                    <Box
                      sx={{
                        fontSize: '1.4rem',
                        color: 'var(--clr-theme-gray)',
                      }}
                    >
                      {Math.floor(totalVideos / patientData?.length) || 0}
                    </Box>
                    <Box
                      sx={{
                        color: 'var(--clr-theme-purple)',
                        fontSize: '1rem',
                      }}
                    >
                      Average videos uploaded
                    </Box>
                  </Typography>
                </Box>
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
    </MainLayout>
  );
};

export default Statistics;

const RatingLineChart = ({ data, selectedDuration, isLoading }) => {
  const sortedData = data?.slice().sort((a, b) => {
    if (selectedDuration === '1week' || selectedDuration === '1month') {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return dateA - dateB;
    }
    return 0;
  });
  if (isLoading) {
    return <Progress />;
  }
  if (!data || data.length === 0) {
    return (
      <div className='responsive flexBox'>
        <h4 className='noData'>No data available...</h4>
      </div>
    );
  }

  return (
    <ResponsiveContainer width='100%' height={'85%'}>
      <LineChart
        data={sortedData}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <XAxis
          dataKey={selectedDuration === '6months' ? 'month' : 'date'}
          tick={{ fontSize: 15 }}
          stroke='#4913bd'
        >
          <Label value='Time' offset={0} dy={15} />
        </XAxis>
        <YAxis tick={{ fontSize: 15 }} stroke='#4913bd'>
          <Label
            value='Average Rating'
            angle={-90}
            offset={0}
            position='insideLeft'
            style={{ textAnchor: 'middle' }}
          />
        </YAxis>
        <Tooltip
          labelStyle={{ color: 'white' }}
          separator=' : '
          itemStyle={{ color: 'whitesmoke' }}
          wrapperStyle={{ outline: 'none' }}
          contentStyle={{
            borderRadius: 10,
            background: '#4913bd',
          }}
        />{' '}
        <Line
          dataKey={selectedDuration === '6months' ? 'average_rating' : 'rating'}
          type='monotone'
          fill='#8884D8'
          shape={<TriangleBar />}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colors[index % 20]} />
          ))}
        </Line>
      </LineChart>
    </ResponsiveContainer>
  );
};

const SymptomRatingLineChart = ({
  data,
  selectedSymptoms,
  selectedDuration,
}) => {
  if (selectedSymptoms.length == 0) {
    return (
      <div className='responsive flexBox'>
        <h4 className='noData'>
          Please select Symptoms from the dropdowns if applicable.
        </h4>
      </div>
    );
  } else if (!data || data.length === 0) {
    return (
      <div className='responsive flexBox'>
        <h4 className='noData'>No data available...</h4>
      </div>
    );
  }

  const sortedData = data.slice().sort((a, b) => {
    if (selectedDuration === '1week' || selectedDuration === '1month') {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return dateA - dateB;
    }
    return 0;
  });

  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const colors = selectedSymptoms.map(() => getRandomColor());

  return (
    <ResponsiveContainer width='100%' height={'100%'}>
      {selectedSymptoms && selectedSymptoms?.length > 0 ? (
        <LineChart
          data={sortedData}
          margin={{
            top: 20,
            right: 20,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis
            dataKey={selectedDuration === '6months' ? 'month' : 'date'}
            tick={{ fontSize: 15 }}
            stroke='#4913bd'
          >
            <Label value='Time' offset={0} dy={15} />
          </XAxis>
          <YAxis tick={{ fontSize: 15 }} stroke='#4913bd'>
            <Label
              value='Average Rating'
              angle={-90}
              offset={0}
              position='insideLeft'
              style={{ textAnchor: 'middle' }}
            />
          </YAxis>
          <Tooltip />
          <Legend
            wrapperStyle={{
              paddingTop: '20px',
              textAlign: 'center',
              marginLeft: '25px',
            }}
          />
          {selectedSymptoms.map((symptom, index) => (
            <Line
              key={symptom}
              type='monotone'
              dataKey={symptom}
              name={symptom}
              stroke={colors[index]}
              connectNulls
              dot={{
                stroke: `${colors[index]}`,
                strokeWidth: 2,
                fill: `${colors[index]}`,
              }}
              strokeWidth={2}
            />
          ))}
        </LineChart>
      ) : (
        <div className='responsive flexBox'>
          <h4 className='noData'>No data available...</h4>
        </div>
      )}
    </ResponsiveContainer>
  );
};

const BehaviourStats = ({
  selectedPatientId,
  selectedStudentId,
  getDurationInMillis,
  selectedDuration,
  user,
  typeOfUser,
  selectedBehaviors,
  setBehaviorsList,
}) => {
  const { ACM } = useContext(ACMContext);
  const [behaviorData, setBehaviorData] = useState([]);
  const [data, setData] = useState([]);
  const currentMonths = getPastMonthNames(DURATION_MONTHS);

  const { data: patientVideoFeedback, isLoading: patientVideoFeedbackLoading } =
    useGetAllVideoFeedbackData(
      ACM[typeOfUser]?.Teacher || ACM[typeOfUser]?.isDoctor
        ? selectedPatientId || selectedStudentId
        : user?.uid
    );

  const sortedData = data.slice().sort((a, b) => {
    if (selectedDuration === '1week' || selectedDuration === '1month') {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return dateA - dateB;
    }
    return 0;
  });

  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  useEffect(() => {
    const durationInMillis = getDurationInMillis(selectedDuration);

    // if (typeOfUser === "DOCTOR") {
    if (ACM[typeOfUser]?.isDoctor || ACM[typeOfUser]?.isTeacher) {
      if (
        (selectedPatientId || selectedStudentId) &&
        (selectedDuration === '1week' || selectedDuration === '1month')
      ) {
        const filteredData = behaviorData.filter((entry) =>
          selectedBehaviors.includes(entry.behaviour)
        );
        getAverageFrequencyCount(
          selectedPatientId || selectedStudentId,
          durationInMillis,
          filteredData
        );
      }
    }
  }, [typeOfUser, selectedDuration, user, selectedBehaviors]);

  const getAverageFrequencyCount = async (id, duration, filteredData) => {
    try {
      const feedbackData = patientVideoFeedback;
      if (feedbackData) {
        const frequencyCountArray = [];
        const behaviorsArray = [];
        const behaviorsListArr = [];
        const behaviorsMap = new Map();

        feedbackData.forEach((item) => {
          const docId = item.docId;
          const time = item.docData.time?._seconds || 0;
          const date = new Date(time * 1000).toLocaleDateString() || '-';

          if (item.docData.behaviours) {
            item.docData.behaviours.forEach((behavior) => {
              const currentTimeStamp = new Date(time * 1000);
              const timeDifference = Date.now() - currentTimeStamp.getTime();

              if (timeDifference <= duration) {
                behaviorsArray.push({
                  videoID: `${docId} V${behaviorsArray.length}`,
                  date: currentTimeStamp.toLocaleDateString(),
                  behavior: behavior.behaviour,
                  frequencyCount: behavior.behaviour_rating,
                });
                const behaviorName = behavior.behaviour;
                if (selectedBehaviors.includes(behaviorName)) {
                  behaviorsArray.push({
                    videoID: `${docId} V${behaviorsArray.length}`,
                    date: currentTimeStamp.toLocaleDateString(),
                    behavior: behaviorName,
                    frequencyCount: behavior.behaviour_rating,
                  });
                }

                behaviorsListArr.push(behaviorName);
              }
            });
          }
        });

        const result = [];
        frequencyCountArray.reduce(function (res, value) {
          if (!res[value.date]) {
            res[value.date] = {
              date: value.date,
              frequencyCount: value.frequencyCount,
            };
            result.push(res[value.date]);
          }
          res[value.date].frequencyCount +=
            value.frequencyCount /
            frequencyCountArray.filter((x) => x.date === value.date).length;
          return res;
        }, {});

        behaviorsArray.forEach((entry) => {
          const existingBehavior = behaviorsMap.get(entry.date);
          if (existingBehavior) {
            existingBehavior[entry.behavior] = entry.frequencyCount;
          } else {
            behaviorsMap.set(entry.date, {
              [entry.behavior]: entry.frequencyCount,
            });
          }
        });

        const aggregatedBehaviorData = Array.from(behaviorsMap).map(
          ([date, behaviors]) => ({
            date: date,
            ...behaviors,
          })
        );

        setBehaviorData(aggregatedBehaviorData);
        setBehaviorsList([...new Set(behaviorsListArr)]);
        setData(aggregatedBehaviorData);
      }
    } catch (error) {
      console.error(
        'Error fetching behavior Average Behaviors count by date:',
        error
      );
    }
  };

  const colors = useMemo(
    () => selectedBehaviors?.map(() => getRandomColor()),
    [selectedBehaviors]
  );

  if (selectedBehaviors?.length == 0) {
    return (
      <ResponsiveContainer width='100%' height={'100%'}>
        <div className='responsive flexBox'>
          <h4 className='noData'>
            Please select Behaviors from the dropdowns if applicable.
          </h4>
        </div>
      </ResponsiveContainer>
    );
  }

  return (
    <>
      <ResponsiveContainer width='100%' height={'100%'}>
        {selectedBehaviors && selectedBehaviors?.length > 0 ? (
          <LineChart
            data={sortedData}
            margin={{
              top: 30,
              right: 20,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis dataKey='date'>
              <Label value='Time' offset={0} dy={15} position='insideBottom' />
            </XAxis>
            <YAxis type='number' domain={[0, 3]}>
              <Label
                value='Frequency Count'
                angle={-90}
                offset={0}
                position='insideLeft'
                style={{ textAnchor: 'middle' }}
              />
            </YAxis>
            <Tooltip />
            <Legend
              wrapperStyle={{
                paddingTop: '20px',
                textAlign: 'center',
                marginLeft: '25px',
              }}
            />
            {selectedBehaviors?.map((behavior, index) => (
              <Line
                key={behavior}
                type='monotone'
                dataKey={behavior}
                name={behavior}
                stroke={colors[index]}
                // activeDot={{ r: 5 }}
                connectNulls
                dot={{
                  stroke: `${colors[index]}`,
                  strokeWidth: 2,
                  fill: `${colors[index]}`,
                }}
                strokeWidth={2}
              />
            ))}
          </LineChart>
        ) : (
          <div className='responsive flexBox'>
            <h4 className='noData'>No data available...</h4>
          </div>
        )}
      </ResponsiveContainer>
    </>
  );
};

const VideoUploadStats = ({ data, selectedDuration, getDurationInMillis }) => {
  const { ACM } = useContext(ACMContext);
  const [videoList, setVideoList] = useState([]);
  const { user, typeOfUser } = useContext(AuthContext);
  const [monthCount, setMonthCount] = useState();
  const currentMonths = getPastMonthNames(DURATION_MONTHS);

  const getVideoUploadStats = async (id, duration) => {
    try {
      const videos = await getPatientVideos(id, 'all');
      if (videos) {
        const uploadArray = [];

        videos.forEach((video) => {
          const time = video.rawVideoData.time?.seconds || 0;
          const date = new Date(time * 1000).toLocaleDateString() || '-';

          const currentTimeStamp = new Date(time * 1000);
          const timeDifference = Date.now() - currentTimeStamp.getTime();

          if (timeDifference <= duration) {
            uploadArray.push({
              videoID: video?.videoId,
              date: currentTimeStamp.toLocaleDateString(),
              uploadCount: 1,
            });
          }
        });
        const result = [];
        uploadArray.forEach((entry) => {
          const existingDateIndex = result.findIndex(
            (item) => item.date === entry.date
          );
          if (existingDateIndex !== -1) {
            result[existingDateIndex].uploadCount += entry.uploadCount / 2;
          } else {
            result.push({
              date: entry.date,
              uploadCount: Math.round(entry.uploadCount / 2),
            });
          }
        });

        setMonthCount(result);
      }
    } catch (error) {
      console.log('Error fetching statistics for video uploads:', error);
    }
  };

  const get6MonthVideoUploadStatistics = async (id) => {
    try {
      const videos = await getPatientVideos(id, 'all');
      const uploadArray = [];

      videos.forEach((video) => {
        const uploadDate = video.rawVideoData?.time.seconds * 1000;
        if (uploadDate > getDateByPastMonths(6)) {
          uploadArray.push({
            videoID: video?.videoId,
            month: ALL_MONTHS[new Date(uploadDate).getMonth()],
            uploadCount: 1,
          });
        }
      });

      const resultData = currentMonths.map((month) => {
        const totalUploadsForMonth = uploadArray.reduce((sum, video) => {
          return video.month === month ? sum + video.uploadCount : sum;
        }, 0);
        return { month, uploadCount: Math.round(totalUploadsForMonth / 4) };
      });

      const isAllZero = resultData.every((item) => item.average_rating === 0);

      const finalData = isAllZero ? null : resultData;
      setMonthCount(finalData);
    } catch (error) {
      console.error('Error fetching 6-month video upload statistics:', error);
      return null;
    }
  };

  const {
    data: currentPatientVideos,
    isLoading: loadingfetchOnePatientAllVideos,
    error: errorPatient,
  } = useOnePatientVideos(user?.uid);

  useEffect(() => {
    const durationInMillis = getDurationInMillis(selectedDuration);

    if (ACM[typeOfUser]?.isPatient || ACM[typeOfUser]?.isStudent) {
      if (selectedDuration === '1week' || selectedDuration === '1month') {
        const durationInMillis = getDurationInMillis(selectedDuration);

        getVideoUploadStats(user?.uid, durationInMillis);
        LogAnalyticsEvent(ANALYTICS_KEYS.STATISTICS.PATIENTSTATSFORVIDEOS, {
          selectedDuration,
          typeOfUser: typeOfUser,
        });
      } else if (selectedDuration === '6months') {
        get6MonthVideoUploadStatistics(user?.uid, selectedDuration);
        LogAnalyticsEvent(ANALYTICS_KEYS.STATISTICS.PATIENTSTATSFORVIDEOS, {
          selectedDuration,
          typeOfUser: typeOfUser,
        });
      }
    }
  }, [typeOfUser, user?.uid, selectedDuration, user]);

  // Only for patient login
  useEffect(() => {
    if (currentPatientVideos?.data?.length > 0) {
      setVideoList(currentPatientVideos.data);
    }
  }, [currentPatientVideos]);
  return (
    <>
      <ResponsiveContainer width='100%' height='100%'>
        <LineChart
          data={monthCount}
          margin={{ top: 20, right: 20, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis
            dataKey={selectedDuration === '6months' ? 'month' : 'date'}
            tick={{ fontSize: 15 }}
            stroke='#4913bd'
          >
            <Label value='Time' offset={0} dy={15} />
          </XAxis>
          <YAxis tick={{ fontSize: 15 }} stroke='#4913bd'>
            <Label
              value='Average video uploads'
              angle={-90}
              offset={0}
              position='insideLeft'
              style={{ textAnchor: 'middle' }}
            />
          </YAxis>
          <Tooltip
            labelStyle={{ color: 'white' }}
            separator=' : '
            itemStyle={{ color: 'whitesmoke' }}
            wrapperStyle={{ outline: 'none' }}
            contentStyle={{ borderRadius: 10, background: '#4913bd' }}
          />
          <Line
            dataKey={'uploadCount'}
            type='monotone'
            fill='#8884D8'
            shape={<TriangleBar />}
          >
            {data?.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={colors[index % 20]} />
            ))}
          </Line>
        </LineChart>
      </ResponsiveContainer>
    </>
  );
};
