import React, { useContext, useEffect, useRef, useState, useMemo } from 'react';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Avatar,
  Divider,
  Box,
  TextField,
  List,
  ListItemButton,
  ListItemText,
  Button,
  ListItem,
  ListItemAvatar,
  CircularProgress,
  CardHeader,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  AppBar,
  Toolbar,
  Stack,
  tooltipClasses,
  styled,
  Tooltip,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { Send, Close } from '@mui/icons-material';
import AuthContext from '../../contexts/AuthContext';
import Reminders from '../../components/Reminders';
import useDoctorPatientMessages from '../../hooks/useDoctorPatientMessages';
import useGroupMessages from '../../hooks/useGroupMessages';
import { stableSort } from '../../utils/helper';
import {
  Timestamp,
  addDoc,
  collection,
  onSnapshot,
  doc,
  updateDoc,
} from 'firebase/firestore';
import { FirestoreDB } from '../../utils/firebase/firebase';
import { toast } from 'react-toastify';
import { MdEdit } from 'react-icons/md';
import { LogAnalyticsEvent } from '../../utils/firebase/firebase';
import { ANALYTICS_KEYS } from '../../constants/AnalyticKeys';
import { ACMContext } from '../../contexts/ACMProvider';
import PatientCard from '../../components/PatientCard';
import PatientDetails from '../PatientOverview/PatientDetails';
import CustomButton from '../../components/CustomButton';
import { useNavigate } from 'react-router-dom';
import { UIContext } from '../../contexts/UIProvider';

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontWeight: 'bold',
    padding: '10px',
  },
}));

// here chat id will represents the group chat id or slected user id
const Conversation = ({
  chatId,
  chatName = '',
  chatPhotoURL,
  isGroupChat,
  onChatNameUpdate,
  navigateProfile = () => {},
  handleReminder = () => {},
  chatUserInfo,
  unreadCounts,
  unreadCountsForGroup,
}) => {
  const { ACM } = useContext(ACMContext);
  const [messages, setMessages] = useState([]);
  const [open, setOpen] = useState(false);
  const [input, setInput] = useState('');
  const [inputError, setInputError] = useState('');
  const [members, setMembers] = useState([]);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [editedChatName, setEditedChatName] = useState(chatName);
  // const [originalChatName, setOriginalChatName] = useState(chatName);
  const navigate = useNavigate();
  const { selectPatient } = useContext(UIContext);

  const scroll = useRef(null);

  const { user, typeOfUser, setOpenRightPanel, setPanelContent } =
    useContext(AuthContext);

  useEffect(() => {
    setEditedChatName(chatName);
  }, [chatName]);

  const handleOpenEditDialog = () => {
    setEditedChatName(chatName);

    setOpenEditDialog(true);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
  };

  const handleSaveEdit = async () => {
    try {
      const groupChatRef = doc(FirestoreDB, 'groupChats', chatId);
      await updateDoc(groupChatRef, { groupName: editedChatName });

      setEditedChatName(editedChatName);

      setOpenEditDialog(false);

      setOpenRightPanel(false);
      setPanelContent(null);
      if (typeof onChatNameUpdate === 'function') {
        onChatNameUpdate(editedChatName);
      }

      toast.success('Group name updated successfully');
    } catch (error) {
      console.error('Error updating group chat name:', error);
      toast.error('Failed to update group chat name. Please try again later.');
    }
  };
  const handleCancelEdit = () => {
    // Close the edit dialog
    setOpenEditDialog(false);
  };

  const fetchMembers = () => {
    const memeber = collection(FirestoreDB, 'groupChats', chatId, 'members');
    const unsubscribe = onSnapshot(memeber, (groupSnapshot) => {
      const memberList = groupSnapshot.docs.map((doc) => doc.data());
      setMembers(memberList);
    });

    return unsubscribe;
  };
  useEffect(() => {
    const unsubscribe = chatId && fetchMembers();
    return () => chatId && unsubscribe();
  }, [chatId]);

  const capitalizeWords = (name) => {
    return name
      ?.split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  const truncateName = (name, limit = 10) => {
    const capitalized = capitalizeWords(name);
    if (capitalized.length > limit) {
      return `${capitalized.slice(0, limit)}...`;
    }
    return capitalized;
  };

  const viewGroup = () => {
    setOpenRightPanel(true);
    setPanelContent(
      <div
        className='fullWidth fullHeight flexBox flexColumn'
        style={{ gap: 4 }}
      >
        <Stack spacing={2} sx={{ backgroundColor: '#9291f033', p: 5 }}>
          <Avatar
            alt='Remy Sharp'
            src='https://cdn-icons-png.flaticon.com/512/6387/6387947.png'
            sx={{
              width: 156,
              height: 156,
              alignSelf: 'center',
              border: '5px solid white',
            }}
          />
          <Box display='flex' alignItems='center' justifyContent='center'>
            <Typography
              variant='h4'
              sx={{ fontFamily: 'Lato' }}
              textAlign='center'
            >
              {userEmail?.userName
                .split(' ')
                .map(
                  (word) =>
                    word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                )
                .join(' ')}
            </Typography>
            <Button
              onClick={handleOpenEditDialog}
              sx={{ ml: 1, mt: 1, color: 'black' }}
            >
              <MdEdit size={24} />
            </Button>
          </Box>
        </Stack>
        <Typography variant='h6' sx={{ fontFamily: 'Lato' }}>
          Members
        </Typography>
        <Card className='fullWidth fullHeight' sx={{ overflowY: 'auto' }}>
          <List>
            {members?.map((member) => (
              <ListItem
                key={member?.id}
                sx={{ border: '1px solid lightgray', mt: 1 }}
                secondaryAction={
                  <Chip
                    label={member?.typeOfUser}
                    color='success'
                    variant='outlined'
                  />
                }
              >
                <ListItemButton>
                  <ListItemAvatar>
                    <Avatar
                      src={
                        member?.photoURL ||
                        'https://png.pngtree.com/png-clipart/20210915/ourmid/pngtree-user-avatar-placeholder-black-png-image_3918427.jpg'
                      }
                    />
                  </ListItemAvatar>
                  <Box
                    display='flex'
                    alignItems='center'
                    justifyContent='space-between'
                    width='100%'
                  >
                    <BootstrapTooltip
                      title={
                        member?.parentName
                          ? `${member.name} (${member.parentName})`
                          : member?.name
                      }
                    >
                      <ListItemText
                        primary={
                          member?.parentName
                            ? `${truncateName(member.name)} (${truncateName(
                                member.parentName
                              )})`
                            : truncateName(member?.name)
                        }
                        sx={{ cursor: 'pointer' }} // Add pointer cursor for clarity
                      />
                    </BootstrapTooltip>
                  </Box>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Card>
      </div>
    );
  };

  const handleInputChange = (event) => {
    setInput(event.target.value);
    setInputError('');
  };

  const {
    data: doctorPatientMessages,
    isLoading: isLoadingDoctorPatientMessages,
    refetch: refetchDoctorPatientMessages,
  } = useDoctorPatientMessages(user?.uid, chatId);

  const {
    data: groupMessages,
    isLoading: isLoadingGroupMessages,
    refetch: refetchGroupMessages,
  } = useGroupMessages(user?.uid, chatId);

  const loading = !isGroupChat
    ? isLoadingDoctorPatientMessages
    : isLoadingGroupMessages;

  const sortedMessages = useMemo(() => {
    let messages = [];

    if (isGroupChat && groupMessages) {
      messages = stableSort(groupMessages, (a, b) => {
        const dateA = b.createdDate?._seconds || b.createdDate?.seconds;
        const dateB = a.createdDate?._seconds || a.createdDate?.seconds;
        return dateB - dateA;
      });
    } else if (!isGroupChat && doctorPatientMessages) {
      messages = stableSort(doctorPatientMessages, (a, b) => {
        const dateA = b.createdDate?._seconds || b.createdDate?.seconds;
        const dateB = a.createdDate?._seconds || a.createdDate?.seconds;
        return dateB - dateA;
      });
    }

    return messages;
  }, [groupMessages, doctorPatientMessages]);

  useEffect(() => {
    if (sortedMessages.length) {
      setMessages(sortedMessages);
    }
  }, [sortedMessages]);

  useEffect(() => {
    if (scroll.current) {
      scroll.current.scrollTop = scroll.current.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    if (isGroupChat) {
      refetchGroupMessages();
    } else {
      refetchDoctorPatientMessages();
    }
  }, [unreadCounts, unreadCountsForGroup]);

  const sendMessage = async (e) => {
    e.preventDefault();
    if (!input.trim()) {
      setInputError('Message cannot be empty');
      return;
    }

    let notificationSource = '';
    let collectionPath = '';
    let message = {};
    let params = {};
    let groupId = ''; // Track group ID for analytics in group chats

    const sentMessageChat = ACM[typeOfUser]?.isDoctor
      ? ANALYTICS_KEYS.MESSAGE.MESSAGEBYDOCTOR
      : ANALYTICS_KEYS.MESSAGE.MESSAGEBYPATIENT;

    const sentMessageGroup = ACM[typeOfUser]?.isDoctor
      ? ANALYTICS_KEYS.MESSAGE.GROUPMESSAGEBYDOCTOR
      : ANALYTICS_KEYS.MESSAGE.GROUPMESSAGEBYPATIENT;

    // Reset the input field and error state
    setInput('');
    setInputError('');
    try {
      if (!isGroupChat) {
        if (ACM[typeOfUser]?.isDoctor) {
          // Doctor messages
          notificationSource = 'doctorMessage';
          params = {
            title: 'Healthcare Professional has sent you a message',
            description: input,
            notifyTo: chatUserInfo?.patientInfo?.email,
            sentBy: user?.email,
            userName: user?.displayName,
            userPhotoURL: user?.photoURL,
          };
          collectionPath = `notifications/${chatId}/messages`;
        } else if (ACM[typeOfUser]?.isTeacher) {
          // Teacher messages
          notificationSource = 'teacherMessage';
          params = {
            title: 'Teacher has sent you a message',
            description: input,
            notifyTo: chatUserInfo?.email, // Notify the student
            sentBy: user?.email,
            userName: user?.fullname,
            userPhotoURL: user?.photoURL,
          };
          collectionPath = `notifications/${chatId}/messages`;
        } else if (ACM[typeOfUser]?.isPatient) {
          // Patient messages
          notificationSource = 'patientMessage';
          params = {
            title: 'Patient has sent you a message',
            description: input,
            notifyTo: chatUserInfo?.doctorId
              ? chatUserInfo?.doctorInfo?.email // Notify the doctor if available
              : chatUserInfo?.email, // Generic fallback
            sentBy: user?.email,
            userName: user?.displayName,
            userPhotoURL: user?.photoURL,
          };
          collectionPath = `notifications/${
            chatUserInfo?.doctorId || chatId
          }/messages`;
        } else if (ACM[typeOfUser]?.isStudent) {
          // Patient messages
          notificationSource = 'studentMessage';
          params = {
            title: 'Student has sent you a message',
            description: input,
            notifyTo: chatUserInfo?.id
              ? chatUserInfo?.email // Notify the doctor if available
              : chatUserInfo?.email, // Generic fallback
            sentBy: user?.email,
            userName: user?.displayName,
            userPhotoURL: user?.photoURL,
          };
          collectionPath = `notifications/${chatId}/messages`;
        }

        // Create the message object
        message = {
          infoMessage: btoa(JSON.stringify(params)), // Base64 encode the params
          notificationSource,
          userId: user?.uid,
          isEncrypted: false,
          readStatus: false,
          createdDate: Timestamp.now(),
        };
      } else {
        notificationSource = 'groupMessage';
        params = {
          title: ACM[typeOfUser]?.isPatient
            ? 'Patient has sent you a message in group'
            : 'Healthcare Professional has sent you a message in group',
          description: input,
          sentBy: user?.email,
          userName: user?.fullname,
          userPhotoURL: user?.photoURL,
        };

        const readStatus = members
          ?.filter((member) => member.id !== user?.uid)
          .map((member) => ({
            id: member.id,
            status: false,
          }));

        collectionPath = `groupChats/${chatId}/groupMessages`;
        message = {
          infoMessage: btoa(JSON.stringify(params)),
          notificationSource,
          userId: user?.uid,
          isEncrypted: false,
          readStatus,
          userName: user?.displayName,
          createdDate: Timestamp.now(),
        };
        groupId = chatId; // Track group ID for analytics
      }

      // Show success toast optimistically
      toast.success('Message sent successfully', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });

      const res = await addDoc(
        collection(FirestoreDB, collectionPath),
        message
      );

      setMessages((prevMessages) => [
        ...prevMessages,
        { ...message, id: res.id, ...params },
      ]);

      // Log the analytics event for individual chats
      if (typeof LogAnalyticsEvent === 'function') {
        try {
          LogAnalyticsEvent(sentMessageChat, {
            notificationSource,
            isGroupChat,
            groupId: isGroupChat ? groupId : null,
            userId: user?.uid,
          });

          // Log the analytics event for group chats (if applicable)
          if (isGroupChat) {
            LogAnalyticsEvent(sentMessageGroup, {
              groupId,
              userId: user?.uid,
            });
          }
        } catch (err) {
          console.error('Error logging analytics event:', err);
        }
      } else {
        console.warn('LogAnalyticsEvent function is not defined.');
      }
    } catch (error) {
      console.error('Error sending message:', error);
      toast.error('Failed to send message');
    }

    // Reset the input field and error state
    setInput('');
    setInputError('');
  };

  const userEmail = messages.find((msg) => msg.userId === chatId);

  return (
    <Card
      sx={{
        height: 'calc(100vh - 100px)',
        backgroundColor: 'var(--clr-theme-white)',
        boxShadow: 'none',
      }}
    >
      <CardHeader
        sx={{
          height: '48px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '0px',
          marginX: '8px',
          '& .MuiCardHeader-content': {
            padding: '0px',
          },
          '& .MuiCardHeader-action': {
            margin: 0,
          },
          marginRight: '30px',
          paddingTop: '5px',
        }}
        title={
          isGroupChat
            ? chatName
                ?.split(' ')
                .map(
                  (word) =>
                    word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                )
                .join(' ') // Show chatName for group chats
            : ACM[typeOfUser]?.isTeacher || ACM[typeOfUser]?.isDoctor
            ? userEmail?.userName
                ?.split(' ')
                .map(
                  (word) =>
                    word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                )
                .join(' ') : chatName // Show formatted userName for recent chats
        }
        subheader={
          (ACM[typeOfUser]?.isDoctor || ACM[typeOfUser]?.isTeacher) &&
          !isGroupChat && (
            <React.Fragment>
              {/* <Typography
                sx={{
                  display: 'inline',
                  cursor: 'pointer',
                  color: 'text.primary',
                  '&:hover': {
                    color: '#9291f0',
                    textShadow: '0px 0px 2px #9291f0',
                  },
                }}
                component='span'
                variant='body2'
                onClick={() => navigateProfile(chatId)}
              >
                View profile
              </Typography> */}
            </React.Fragment>
          )
        }
        action={
          <>
            {(ACM[typeOfUser]?.isDoctor || ACM[typeOfUser]?.isTeacher) &&
              !isGroupChat && (
                <>
                  <Button
                    sx={{
                      backgroundColor: 'var(--clr-theme-purple)!important',
                      color: 'white',
                      textTransform: 'capitalize',
                      width: '200px',
                      fontWeight: 'bold',
                    }}
                    onClick={() => setOpen(true)}
                  >
                    Set Reminder
                  </Button>
                  <Dialog
                    onClose={() => setOpen(false)}
                    aria-labelledby='customized-dialog-title'
                    open={open}
                    maxWidth='lg'
                    className='mindly-reminders'
                  >
                    <AppBar
                      sx={{
                        position: 'relative',
                        backgroundColor: 'var(--clr-mindly-background)',
                        boxShadow: 'none',
                      }}
                    >
                      <Toolbar>
                        <Typography
                          sx={{
                            flex: 1,
                            color: 'black',
                            fontSize: '1.8rem',
                            fontFamily: 'Lato',
                            textAlign: 'center',
                          }}
                          variant='h6'
                          component='div'
                        >
                          Reminders
                        </Typography>
                        <Button
                          autoFocus
                          color='inherit'
                          onClick={() => setOpen(false)}
                        >
                          <Close sx={{ color: 'black' }} />
                        </Button>
                      </Toolbar>
                    </AppBar>
                    <DialogContent
                      sx={{
                        width: '42vw',
                        backgroundColor: 'var(--clr-mindly-background)',
                      }}
                    >
                      <Reminders currentPatient={chatId} />
                    </DialogContent>
                  </Dialog>
                </>
              )}
          </>
        }
      />
      <Divider sx={{ border: '1px solid grey' }} />
      <Box sx={{ width: '100%' }}>
        {loading ? (
          <CircularProgress
            sx={{
              position: 'absolute',
              left: '50%',
              top: '50%',

              color: 'var(--clr-theme-purple)',
            }}
          />
        ) : (
          <CardContent>
            <Box
              sx={{
                backgroundColor: ' var(--clr-theme-white)',
                display: 'flex',
                flexDirection: {xs:"column",md:'row'},
                gap: 1.5,
                height: 'calc(100vh - 170px)',
                overflowY:'scroll'              
              }}
            >
              <Box
                sx={{
                  height: 'calc(100vh - 175px)',
                  flexGrow: 1,
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Box sx={{ flexGrow: 1, overflow: 'auto' }} ref={scroll}>
                  {messages?.map((message, index) => {
                    // const date = new Date(
                    //   (message?.createdDate?.seconds ||
                    //     message?.createdDate?._seconds) * 1000
                    // );
                    const date =
                      message?.createdDate?.seconds ||
                      message?.createdDate?._seconds
                        ? new Date(
                            (message?.createdDate?.seconds ||
                              message?.createdDate?._seconds) * 1000
                          )
                        : null;

                    function convertSecondsToDate(seconds) {
                      // Create a new Date object from the seconds (timestamp)
                      const dateObject = new Date(seconds * 1000); // Convert seconds to milliseconds
                      // Return the date part (year, month, day) only
                      return dateObject;
                    }

                    const isTwoDatesAreSame = (seconds1, seconds2) => {
                      // Convert the seconds to date objects
                      const date1 = convertSecondsToDate(seconds1);
                      const date2 = convertSecondsToDate(seconds2);

                      // Compare the year, month, and day (ignore time)
                      if (
                        date1.getDay() === date2.getDay() &&
                        date1.getMonth() === date2.getMonth() &&
                        date1.getFullYear() === date2.getFullYear()
                      ) {
                        return true;
                      } else {
                        return false;
                      }
                    };
                    return (
                      <React.Fragment key={message.id}>
                        {index === 0 &&
                        message?.createdDate?._seconds &&
                        Object.keys(message?.createdDate).length > 0 ? (
                          <div className='chatDate'>
                            <p style={{ color: '#999' }}>
                              {new Date(
                                message.createdDate._seconds * 1000
                              ).toLocaleDateString()}
                            </p>
                          </div>
                        ) : message?.createdDate?._seconds &&
                          !isTwoDatesAreSame(
                            messages[index - 1]?.createdDate?._seconds,
                            message?.createdDate?._seconds
                          ) ? (
                          <div className='chatDate'>
                            <p style={{ color: '#999' }}>
                              {new Date(
                                message.createdDate._seconds * 1000
                              ).toLocaleDateString()}
                            </p>
                          </div>
                        ) : null}
                        <Chatconversation
                          key={index}
                          message={message}
                          typeOfUser={typeOfUser}
                          user={user}
                        />
                      </React.Fragment>
                    );
                  })}
                </Box>
                <form onSubmit={sendMessage} className='fullWidth'>
                  <Box className='fullWidth'>
                    <Grid item xs={12}>
                      <Box
                        className='fullWidth'
                        sx={{
                          backgroundColor: '#fff',
                          position: 'relative',
                          width: '100%',
                          borderRadius: '10px',
                          marginTop: '5px',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'end',
                        }}
                      >
                        <TextField
                          size='small'
                          fullWidth
                          placeholder='Type a message...'
                          variant='outlined'
                          value={input}
                          onChange={handleInputChange}
                          error={!!inputError}
                          helperText={inputError}
                          InputProps={{
                            sx: { paddingTop: '10px', pl: '5px' },
                            endAdornment: (
                              <InputAdornment position='end'>
                                <IconButton
                                  aria-label='send button'
                                  onClick={sendMessage}
                                  edge='end'
                                >
                                  <Send />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                    </Grid>
                  </Box>
                </form>
              </Box>
              <Box
                sx={{
                  backgroundColor: 'var(--clr-theme-white)',
                  // maxWidth: '35%',
                }}
              >
                <Card
                  className='profile-details fullWidth'
                  sx={{
                    paddingRight: 0,
                    borderRadius: '20px',
                    backgroundColor: 'var(--clr-theme-white)',
                    boxShadow: '0px 2px 0px rgba(3,3,3,0.1);',
                    border: '1px solid #6e7575',
                  }}
                >
                  <CardContent
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      // paddingBottom: 1,
                    }}
                  >
                    <Box
                      className='fullWidth'
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        textAlign: 'center',
                      }}
                    >
                      <Avatar
                        src={
                          userEmail?.userPhotoURL ||
                          chatPhotoURL ||
                          'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png'
                        }
                        alt='Student'
                        sx={{
                          width: 130,
                          height: 130,
                          // marginBottom: 0.5,
                        }}
                        variant='circular'
                      />

                      <Typography
                        variant='h6'
                        sx={{
                          fontFamily: 'Lato',
                          fontSize: '16px',
                          fontWeight: 600,
                        }}
                      >
                        {
                          isGroupChat
                            ? chatName
                                ?.split(' ')
                                .map(
                                  (word) =>
                                    word.charAt(0).toUpperCase() +
                                    word.slice(1).toLowerCase()
                                )
                                .join(' ')
                            : (userEmail?.userName || chatName)
                                ?.split(' ')
                                .map(
                                  (word) =>
                                    word.charAt(0).toUpperCase() +
                                    word.slice(1).toLowerCase()
                                )
                                .join(' ') // Show formatted userName for recent chats
                        }
                      </Typography>
                      <Typography
                        variant='body2'
                        color='textSecondary'
                        sx={{ textDecoration: 'underline' }}
                      >
                        {userEmail?.sentBy}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 2, // Add gap between the rows                      
                        // padding: { xs: '8px', sm: '12px' }, // Adjust spacing on small screens
                      }}
                    >
                      <Box
                        sx={{
                          padding: '2px 0',
                          minHeight: 'unset',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: 'Lato',
                            fontSize: '0.85rem',
                            lineHeight: '1',
                          }}
                          variant='h6'
                        >
                          Availability Hours:
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          padding: '1px 0',
                          minHeight: 'unset',
                          gap: 1, // Add gap between the columns
                        }}
                      >
                        <Typography
                          variant='body2'
                          color='textSecondary'
                          sx={{
                            fontSize: '0.75rem',
                            margin: 0,
                            lineHeight: '1.1',
                            padding: '4px 0', // Add padding to the text
                          }}
                        >
                          M-W: 9:00 AM to 5:00 PM
                        </Typography>
                        <Typography
                          variant='body2'
                          color='textSecondary'
                          sx={{
                            fontSize: '0.75rem',
                            margin: 0,
                            lineHeight: '1.1',
                            padding: '4px 0', // Add padding to the text
                          }}
                        >
                          Th-F: 10:00 AM to 4:00 PM
                        </Typography>
                      </Box>
                    </Box>

                    {isGroupChat && (
                      <Button
                        variant='contained'
                        onClick={viewGroup}
                        sx={{
                          backgroundColor: 'var(--clr-theme-purple)!important',
                          textTransform: 'capitalize',
                          justifyContent: 'center',
                          fontWeight: 'bold',
                        }}
                      >
                        View Group
                      </Button>
                    )}
                    {!isGroupChat &&
                      (ACM[typeOfUser]?.isDoctor ||
                        ACM[typeOfUser]?.isTeacher) && (
                        <Button
                          variant='contained'
                          sx={{
                            backgroundColor:
                              'var(--clr-theme-purple)!important',
                            marginTop: 'auto',
                            justifyContent: 'center',
                            fontWeight: 'bold',
                            textTransform: 'capitalize',
                            width: { xs: '100%', sm: 'auto' },
                            height:{xs:'', sm:''},
                            minWidth: '150px',
                            fontSize: { xs: '12px', sm: '14px', md: '16px' },
                            padding: { xs: '8px', sm: '10px' },
                          }}
                          onClick={() => navigateProfile(chatId)}
                        >
                          View Details
                        </Button>
                      )}
                  </CardContent>
                </Card>
              </Box>
            </Box>
          </CardContent>
        )}
      </Box>

      {/* Edit Dialog */}
      <Dialog
        open={openEditDialog}
        onClose={handleCloseEditDialog}
        maxWidth='sm' // Change this value as needed, options are 'xs', 'sm', 'md', 'lg', 'xl'
        fullWidth={true}
        sx={{ zIndex: 10000 }}
      >
        <DialogTitle
          sx={{ textAlign: 'center', color: '#9291f0', fontWeight: 'bold' }}
          variant='h5'
        >
          Edit group name
        </DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            sx={{ margin: '5px 0px' }}
            value={editedChatName}
            onChange={(e) => setEditedChatName(e.target.value)}
            label='Group name'
            variant='outlined'
          />
        </DialogContent>
        <DialogActions sx={{ paddingRight: 3 }}>
          <Button
            onClick={handleCancelEdit}
            style={{ color: '#9291f0', width: '20%' }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSaveEdit}
            variant='contained'
            style={{ backgroundColor: '#9291f0', width: '20%' }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default Conversation;

const Chatconversation = ({ message, typeOfUser, user }) => {
  const { ACM } = useContext(ACMContext);
  if (!message.description) {
    return null;
  }

  // Determine if the message is from the doctor or the patient
  const isDoctorMessage = message?.notificationSource === 'doctorMessage';
  const isPatientMessage = message?.notificationSource === 'patientMessage';

  // Determine the alignment of the message content
  // const contentAlignment = isDoctorMessage ? "flex-end" : "flex-start";
  const isCurrentUserMessage =
    (ACM[typeOfUser]?.isDoctor && message.sentBy === user.email) ||
    (ACM[typeOfUser]?.isPatient && message.sentBy === user.email) ||
    (ACM[typeOfUser]?.isTeacher && message.sentBy === user.email) ||
    (ACM[typeOfUser]?.isStudent && message.sentBy === user.email);

  // Determine the alignment of the message content
  // const contentAlignment = isCurrentUserMessage ? 'flex-end' : 'flex-start';

  const formattedDate = new Date(
    (message?.createdDate?.seconds || message?.createdDate?._seconds) * 1000
  ).toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        // padding: '10px',
        marginBottom: 1,
        backgroundColor: 'var(--clr-theme-white)',
      }}
      className='anilmessage'
    >
      <img
        src={
          ACM[typeOfUser]?.isDoctor
            ? message?.userPhotoURL ||
              'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png'
            : message?.userPhotoURL ||
              user?.photoURL ||
              'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png'
        }
        alt='User Avatar'
        // style={{ width: '30px', height: '30px', borderRadius: '50%' }}
        style={{ width: '40px', height: '40px', borderRadius: '50%' }}
      />
      <div
        style={{
          marginLeft: '5px',
          width: '70%',
          // backgroundColor: 'var(--clr-theme-purple-20)',
          backgroundColor: 'transparent',
          padding: '10px',
          borderRadius: isDoctorMessage
            ? '20px 5px 20px 20px'
            : '20px 20px 20px 5px',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', m: 5 }}>
          <p
            style={{
              marginBottom: '0px',
              marginRight: '12px',
              fontSize: '16px',
              fontWeight: 'bold',
              color: '#030303',
            }}
          >
            {message?.userName
              ?.split(/\s+/)
              .map(
                (word) =>
                  word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
              )
              .join(' ') || 'NA'}
          </p>
          <p
            style={{
              fontSize: '16px',
              color: '#999',
              marginBottom: '2px',
            }}
          >
            {formattedDate}
          </p>
        </div>
        <p style={{ color: '#999', margin: '0', fontSize: '20px' }}>
          {message?.description}
        </p>
      </div>
    </div>
  );
};
