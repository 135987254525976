import React, { useEffect, useState, memo, useContext } from 'react';
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  FormControlLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  tooltipClasses,
  Typography,
  Box,
  FormControl,
  InputLabel,
  OutlinedInput,
  Button,
  Tabs,
  Tab,
  ListItemText,
  TextareaAutosize,
  DialogContentText,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  FileUploadSharp,
  CloudUploadRounded,
  CheckCircleOutlineRounded,
  Info,
  Attachment,
  VideoFile,
} from '@mui/icons-material';
import { ref } from 'firebase/storage';
import {
  doc,
  Timestamp,
  collection,
  setDoc,
  onSnapshot,
  addDoc,
} from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';

import {
  FirebaseStorage,
  FirestoreDB,
  LogAnalyticsEvent,
} from '../../utils/firebase/firebase';
import { UploadFileToStorage } from '../../utils/firebase/helper';
import Progress from '../Progress';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { MobileDateTimePicker } from '@mui/x-date-pickers';
import { ToastContainer, toast } from 'react-toastify';
import './VideoUpload.css';
import 'react-toastify/dist/ReactToastify.css';
import BrandLogo from '../BrandLogo';
import AuthContext from '../../contexts/AuthContext';
import useOnePatientData from '../../hooks/useOnePatientData';
import { fetchAllAttachmentsByUser, fetchPatientTreatments } from '../../api';
import behaviorData from './behaviorData.json';
import { ANALYTICS_KEYS } from '../../constants/AnalyticKeys';
import { ACMContext } from '../../contexts/ACMProvider';
import usePatientsForDoctor from '../../hooks/usePatientsForDoctor';
import useAllPatientsVideos from '../../hooks/useAllPatientVideos';
import useOnePatientVideos from '../../hooks/useOnePatientVideos';
import useStudentsForTeacher from '../../hooks/useStudentsForTeacher';
import useTeachersForOneStudent from '../../hooks/useTeachersForOneStudent';
import useStudentsForOneTeacher from '../../hooks/useStudentsForOneTeacher';
import useOneStudentData from '../../hooks/useOneStudentData';
import useFetchAllAttachmentsByUser from '../../hooks/useFetchAllAttachmentsByUser';
import useFetchPreRegisteredUserForTeacher from '../../hooks/useFetchPreRegisteredUserForTeacher';

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontWeight: 'bold',
    padding: '10px',
  },
}));

const documentsType = [
  { label: 'Assesment', value: 'Assesment' },
  { label: 'Summary', value: 'Summary' },
  { label: 'Score', value: 'Score' },
];
const VideoUpload = ({ user, typeOfUser }) => {
  const { ACM } = useContext(ACMContext);
  const { openVideoUpload, setOpenVideoUpload } = useContext(AuthContext);

  const [selectedTab, setSelectedTab] = useState(0);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [patientData, setPatientData] = useState([]);
  const [studentData, setStudentData] = useState([]);
  const [SelectedPatient, SetSelectedPatient] = useState('');
  const [selectedStudent, setSelectedStudent] = useState('');
  const [fileDuration, setFileDuration] = useState(0);
  const [customFileName, setCustomFileName] = useState('');
  const [showTextField, setShowTextField] = useState(false);
  const [isPrivate, setIsPrivate] = useState(false);
  const [therapiesDropdown, setTherapiesDropdown] = useState([]);
  const [diagnosisDropdown, setDiagnosisDropdown] = useState([]);
  const [patientTreatmentsData, setPatientTreatementsData] = useState([]);
  const [isPatientSelected, setIsPatientSelected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [Medications, setMedications] = useState([]);
  const [attachmentsData, setAttachmentsData] = useState([]);
  const [filteredAttachmentsData, setFilteredAttachmentsData] = useState([]);
  const [patientSummaryData, setPatientSummaryData] = useState([]);
  const [selectedBehaviorNames, setSelectedBehaviorNames] = useState([]);
  const [isPrivateAll, setIsPrivateAll] = useState(false);
  const [selectedAntecedents, setSelectedAntecedents] = useState([]);
  const [selectedConsequences, setSelectedConsequences] = useState([]);
  const [showTherapyTextField, setShowTherapyTextField] = useState(false);
  const [showMedicationTextField, setShowMedicationTextField] = useState(false);
  const [isFirstSectionComplete, setIsFirstSectionComplete] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [isResetConfirmed, setIsResetConfirmed] = useState(false);
  const [newPatient, setNewPatient] = useState(null);
  const [newStudent, setNewStudent] = useState(null);

  useEffect(() => {
    if (isResetConfirmed) {
      setSelectedBehaviorNames([]);
      setSelectedAntecedents([]);
      setSelectedConsequences([]);
      setMedications([]);
      setTherapiesDropdown([]);
      setDiagnosisDropdown([]);
      setInformation({ notes: '' });
      setShowTextField(false);
      setIsResetConfirmed(false);
    }
  }, [isResetConfirmed]);

  const handleDialog = () => {
    setShowDialog(false);
  };

  const handleConfirmChange = () => {
    ACM[typeOfUser]?.isDoctor
      ? SetSelectedPatient(newPatient)
      : setSelectedStudent(newStudent);
    setIsResetConfirmed(true);
    handleDialog();
  };

  const [information, setInformation] = useState({
    notes: '',
  });
  const [date, setDate] = useState(null);
  const [error, setError] = useState({
    show: false,
    message: '',
  });
  const [typeOfDocument, setTypeOfDocument] = useState('');
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    SetSelectedPatient(newValue);
  };

  const [medAndTherapiesData, setmedAndTherapiesData] = useState('');

  const { data: allattachments, refetch: refetchAllAttachments } =
    useFetchAllAttachmentsByUser(user?.uid, typeOfUser);

  const {
    data: patients = [],
    isLoading: isPatientDataLoading,
    error: errorPatientData,
  } = usePatientsForDoctor(user?.uid);

  const {
    data: teacherData = [],
    isLoading: isTeacherDataLoading,
    error: errorTeacherData,
  } = useTeachersForOneStudent(user?.uid, typeOfUser);
  const {
    data: students = [],
    isLoading: isStudentDataLoading,
    error: errorStudentData,
  } = useStudentsForOneTeacher(user?.uid, typeOfUser);

  const { data: preRegisteredUsers = [], refetch } =
    useFetchPreRegisteredUserForTeacher(user?.uid);

  const {
    data: allPatientsAllVideos,
    isLoading: loadingfetchAllPatientsAllVideos,
    error: errorDoctor,
    refetch: refetchAllPatientsVideos,
  } = useAllPatientsVideos(user?.uid);

  const {
    data: currentPatientVideos,
    isLoading: loadingfetchOnePatientAllVideos,
    error: errorPatient,
    refetch: refetchOnePatientVideos,
  } = useOnePatientVideos(user?.uid);

  const dateChangeHandler = (newValue) => {
    setDate(newValue._d);
    if (newValue && newValue._d <= new Date()) {
      setDate(newValue._d); // Set the selected date
      setError({
        show: false,
        message: '',
      });
    } else {
      setDate(null);
      setError({
        show: true,
        message:
          'Select Valid Date, Video taken time cannot be greater than current time.',
      });
    }
  };
  const showToastMessage = (status) => {
    if (status === 'success') {
      toast.success('Your upload was successful', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      toast.error('Your upload was Failed', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
  const handleOpen = () => setOpenVideoUpload(true);
  const handleClose = () => {
    setOpenVideoUpload(false);
    setCustomFileName('');
    setDiagnosisDropdown('');
    setSelectedBehaviorNames([]);
    setTherapiesDropdown('');
    setSelectedAntecedents([]);
    setSelectedConsequences([]);
    setShowTextField(false);
    setShowMedicationTextField(false);
    setShowTherapyTextField(false);
    // setSecondDropdownValue("");
    setMedications([]);
    setTypeOfDocument('');
  };
  useEffect(() => {
    // Reset file and custom file name whenever tab changes
    setUploadedFile(null);
    setCustomFileName('');
  }, [selectedTab]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    file && setUploadedFile(file);
    getVideoDuration(file);
  };

  const handleCustomFileNameChange = (e) => {
    setCustomFileName(e.target.value);
  };

  const getVideoDuration = (file) => {
    var vid = document.createElement('video');
    var fileURL = URL.createObjectURL(file);
    vid.src = fileURL;
    vid.ondurationchange = function () {
      setFileDuration(this.duration);
    };
  };
  const validateInputs = () => {
    const errors = {};

    if (!uploadedFile) {
      errors.uploadedFile = 'Please select a file to upload.';
    }

    if (!date) {
      errors.date = 'Please select a valid date.';
    }
    if (date >= new Date()) {
      errors.uploadedFile = 'Please select a valid date.';
    }
    if (ACM[typeOfUser]?.isDoctor && !SelectedPatient) {
      errors.SelectedPatient = 'Please select a patient.';
    }
    if (!Medications || Medications.length === 0) {
      errors.Medications = 'Please enter at least one medication.';
    }

    if (!therapiesDropdown || therapiesDropdown.length === 0) {
      errors.therapiesDropdown = 'Please enter at least one therapy.';
    }

    if (!diagnosisDropdown || diagnosisDropdown.trim() === '') {
      errors.diagnosisDropdown = 'Please select a diagnosis.';
    }

    if (!selectedBehaviorNames || selectedBehaviorNames.length === 0) {
      errors.selectedBehaviorNames = 'Please select at least one behavior.';
    }
    const isValid = Object.keys(errors).length === 0;

    return { isValid, errors };
  };
  const validateInputsForAttchment = () => {
    const errors = {};

    if (!uploadedFile) {
      errors.uploadedFile = 'Please select a file to upload.';
    }

    if (!date) {
      errors.date = 'Please select a valid date.';
    }
    if (date >= new Date()) {
      errors.uploadedFile = 'Please select a valid date.';
    }

    const isValid = Object.keys(errors).length === 0;

    return { isValid, errors };
  };
  const resetStates = () => {
    setError({
      show: false,
      message: '',
    });
    setUploadedFile(null);
    setInformation({
      notes: '',
    });
    setDate(null);
    SetSelectedPatient('');
    // setAllSymptoms([]);
    // setMedTherapies({ meds: [], therapies: [] });
    // setSelectedMedsAndTherapies({ meds: [], therapies: [] });
  };

  //Here getting Meds and Therapies
  const getMedAndTherapy = async (id) => {
    const collectionName = ACM[typeOfUser]?.isDoctor
      ? 'patientsList'
      : 'studentList';
    onSnapshot(
      collection(FirestoreDB, collectionName, id, 'treatments'),
      (querySnapshot) => {
        const meds = [];
        const therapies = [];
        for (let i = 0; i < querySnapshot.docs.length; i++) {
          for (let j = 0; j < querySnapshot.docs[i].data()?.meds?.length; j++) {
            meds.push(querySnapshot.docs[i].data()?.meds[j]);
          }
          for (
            let k = 0;
            k < querySnapshot.docs[i].data()?.therapies?.length;
            k++
          ) {
            therapies.push(querySnapshot.docs[i].data()?.therapies[k]);
          }
        }
        // setMedTherapies({ meds, therapies });
      }
    );
  };

  const addNotification = async (
    videoID,
    selectedPatientId,
    selectedStudentId,
    selectedTeacherId
  ) => {
    try {
      if (ACM[typeOfUser]?.isPatient) {
        const doctors = user?.doctorDetails;
        doctors?.map(async (each) => {
          if (each.doctorId) {
            const params = {
              title: 'Patient uploaded a new video',
              description: '',
              notifyTo: each?.email,
              sentBy: user?.email,
              userName: user?.fullname,
              userPhotoURL: user?.photoURL,
              videoID: videoID,
            };
            await addDoc(
              collection(
                FirestoreDB,
                'notifications',
                each.doctorId,
                'messages'
              ),
              {
                infoMessage: btoa(JSON.stringify(params)),
                notificationSource: 'patientVideoUploaded',
                userId: user?.uid,
                isEncrypted: false,
                readStatus: false,
                createdDate: Timestamp.now(),
              }
            );
          }
        });
      } else if (ACM[typeOfUser]?.isStudent) {
        teacherData?.map(async (each) => {
          if (each.id) {
            const params = {
              title: 'Student uploaded a new video',
              description: '',
              notifyTo: each?.email,
              sentBy: user?.email,
              userName: user?.fullname || user?.name,
              userPhotoURL: user?.photoURL,
              videoID: videoID,
            };
            await addDoc(
              collection(FirestoreDB, 'notifications', each.id, 'messages'),
              {
                infoMessage: btoa(JSON.stringify(params)),
                notificationSource: 'studentVideoUploaded',
                userId: user?.uid,
                isEncrypted: false,
                readStatus: false,
                createdDate: Timestamp.now(),
              }
            );
          }
        });
      } else if (ACM[typeOfUser]?.isDoctor) {
        if (selectedPatientId) {
          const selectedPatientDetails = patients.find(
            (each) => each?.patientId === selectedPatientId
          );
          const params = {
            title: 'Healthcare professional uploaded a new video',
            description: '',
            notifyTo: selectedPatientDetails?.patientInfo?.email,
            sentBy: user?.email,
            userName: user?.fullname || user?.name,
            userPhotoURL: user?.photoURL,
            videoID: videoID,
          };
          await addDoc(
            collection(
              FirestoreDB,
              'notifications',
              selectedPatientDetails.patientId,
              'messages'
            ),
            {
              infoMessage: btoa(JSON.stringify(params)),
              userId: user?.uid,
              notificationSource: 'doctorVideoUploaded',
              isEncrypted: false,
              readStatus: false,
              createdDate: Timestamp.now(),
            }
          );
        }
      } else if (ACM[typeOfUser]?.isTeacher) {
        if (selectedStudent) {
          const selectedStudentDetails = studentData?.find(
            (each) => each?.id === selectedStudent
          );
          const params = {
            title: 'Teacher uploaded a new video',
            description: '',
            notifyTo: selectedStudentDetails?.email,
            sentBy: user?.email,
            userName: user?.fullname || user?.name,
            userPhotoURL: user?.photoURL,
            videoID: videoID,
          };
          await addDoc(
            collection(
              FirestoreDB,
              'notifications',
              selectedStudentDetails.id,
              'messages'
            ),
            {
              infoMessage: btoa(JSON.stringify(params)),
              userId: user?.uid,
              notificationSource: 'teacherVideoUploaded',
              isEncrypted: false,
              readStatus: false,
              createdDate: Timestamp.now(),
            }
          );
        }
      }
    } catch (e) {
      console.log('error while adding notification', e);
    }
  };

  const videoUploadFile = async (id) => {
    try {
      if (validateInputs().isValid) {
        setIsLoading(true);
        setError({
          show: false,
          message: '',
        });

        // const userId = ACM[typeOfUser]?.isDoctor ? id : user?.uid;
        const userId =
          ACM[typeOfUser]?.isDoctor || ACM[typeOfUser]?.isTeacher
            ? id
            : user?.uid;
        if (uploadedFile && uploadedFile.type === 'video/mp4') {
          // Upload video and store its metadata
          const videoID = uuidv4();
          const blob = new Blob([uploadedFile], {
            type: 'video/mp4',
          });
          const videoStorageRef = ref(
            FirebaseStorage,
            `videoLogs/${userId}/${videoID}/rawVideo/${
              customFileName
                ? customFileName.charAt(0).toUpperCase() +
                  customFileName.slice(1) +
                  '.mp4'
                : uploadedFile.name
            }`
          );

          const result = await UploadFileToStorage(videoStorageRef, blob);

          if (result.status) {
            const url = result.data;

            // Metadata for the video
            const params = {
              duration: fileDuration || 0,
              fileName: customFileName
                ? customFileName.charAt(0).toUpperCase() +
                  customFileName.slice(1) +
                  '.mp4'
                : uploadedFile.name,
              fileSize: uploadedFile.size,
              fileType: uploadedFile.type,
              time: Timestamp.now(),
              url: url,
              id: videoID,
              date: date || null,
              userName: user?.fullname,
              userType: typeOfUser,
            };

            const collection =
              ACM[typeOfUser]?.isDoctor || ACM[typeOfUser].isPatient
                ? 'patientsList'
                : 'studentsList';

            // Store video metadata
            // await setDoc(
            //   doc(FirestoreDB, 'videoLogs', id, 'rawVideos', videoID),
            //   {
            //     info: btoa(JSON.stringify(params)),
            //     isEncrypted: false,
            //   }
            // );
            await setDoc(doc(FirestoreDB, 'videoLogs', id), {
              videos: [],
            });

            await setDoc(
              doc(FirestoreDB, 'videoLogs', id, 'rawVideos', videoID),
              {
                info: btoa(JSON.stringify(params)),
                isEncrypted: false,
              }
            );

            // Store patient notes
            // await setDoc(
            //   doc(FirestoreDB, 'videoLogs', id, 'patientNotes', videoID),
            //   {
            //     notes: btoa(information?.notes || ''),
            //     isEncrypted: false,
            //     isDeleted: false,
            //   }
            // );
            await setDoc(
              doc(FirestoreDB, 'videoLogs', id, 'patientNotes', videoID),
              {
                notes: btoa(information?.notes || ''),
                isEncrypted: false,
                isDeleted: false,
              }
            );

            const videoUploadKey = ACM[typeOfUser]?.isDoctor
              ? ANALYTICS_KEYS.DASHBOARD.DOCTORVIDEOUPLOAD
              : ANALYTICS_KEYS.DASHBOARD.PATIENTVIDEOUPLOAD;

            const userType = typeOfUser ? typeOfUser.toLowerCase() : 'unknown';

            // Dynamically include the typeOfUser in the video upload message
            const message = `${userType} successfully uploaded a video`;

            LogAnalyticsEvent(videoUploadKey, {
              videoID: videoID,
              message: message,
            });

            // Prepare treatment data (meds, therapies, behaviors)
            const meds = Array.isArray(Medications)
              ? Medications.map((med) => ({ drug_name: med }))
              : [{ drug_name: Medications }];
            const therapies = Array.isArray(therapiesDropdown)
              ? therapiesDropdown.map((therapy) => ({ therapy_name: therapy }))
              : [{ therapy_name: therapiesDropdown }];

            const selectedBehaviorsData = selectedBehaviorNames.map(
              (behaviorName) => {
                const relatedCrisisPlan = relatedCrisisPlans?.find(
                  (plan) => plan.TargetBehavior === behaviorName
                );

                return {
                  behaviorName: behaviorName,
                  definition: relatedCrisisPlan?.Definition || 'No definition',
                  antecedent: selectedAntecedents || [],
                  consequence: selectedConsequences || [],
                  behaviorId: uuidv4(),
                };
              }
            );

            const treatmentParams = {
              meds: Medications || [],
              therapies: therapiesDropdown || [],
              diagnosis: diagnosisDropdown || '',
              behaviors: selectedBehaviorsData,
              videoID: videoID,
              createdDate: Timestamp.now(),
            };

            //Check if diagnosis exists
            const diagnosisExists =
              // typeOfUser === "DOCTOR"
              ACM[typeOfUser]?.isDoctor || ACM[typeOfUser]?.isTeacher
                ? medAndTherapiesData?.data?.some(
                    (item) => item?.treatment_name === diagnosisDropdown
                  )
                : patientTreatmentsData?.some(
                    (item) => item?.treatment_name === diagnosisDropdown
                  );

            // If diagnosis does not exist, create a new treatment and patientTreatmentInfo document
            if (!diagnosisExists) {
              const patientTreatmentsData = {
                isDoctorApproved: ACM[typeOfUser]?.isDoctorTreatmentApproved
                  ? true
                  : false,
                meds: btoa(JSON.stringify(meds || [])),
                therapies: btoa(JSON.stringify(therapies || [])),
                treatment_name: btoa(diagnosisDropdown || ''),
                userName: btoa(user?.fullname || ''),
                userType: typeOfUser,
              };

              // await setDoc(
              //   doc(FirestoreDB, 'patientsList', id, 'treatments', videoID),
              //   patientTreatmentsData
              // );
              await setDoc(
                doc(FirestoreDB, collection, id, 'treatments', videoID),
                patientTreatmentsData
              );

              await setDoc(
                doc(
                  FirestoreDB,
                  'videoLogs',
                  id,
                  'patientTreatmentInfo',
                  videoID
                ),
                {
                  info: btoa(JSON.stringify(treatmentParams)),
                  isEncrypted: false,
                }
              );
            }

            // Regardless of diagnosis existence, store the treatmentParams in patientTreatmentInfo
            await setDoc(
              doc(
                FirestoreDB,
                'videoLogs',
                id,
                'patientTreatmentInfo',
                videoID
              ),
              {
                info: btoa(JSON.stringify(treatmentParams)),
                isEncrypted: false,
              }
              // treatmentParams
            );

            addNotification(videoID, id);
            showToastMessage('success');
            if (ACM[typeOfUser]?.isDoctor || ACM[typeOfUser]?.isTeacher)
              refetchAllPatientsVideos();
            else refetchOnePatientVideos();
            handleClose();
          } else {
            toast.error('Error uploading video. Please try again.', {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        } else if (date >= new Date()) {
          toast.error('Please select a valid date', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        } else {
          toast.error('Please upload only mp4 videos!', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }

        setIsLoading(false);
      } else {
        setError({
          show: true,
          message: 'Enter all the above values.',
        });
      }
    } catch (error) {
      console.error('Error message: ', error);
      setIsLoading(false);
      showToastMessage('error');
    }
  };

  const attachmentUploadFile = async (id) => {
    try {
      if (validateInputsForAttchment().isValid) {
        setIsLoading(true);
        setError({
          show: false,
          message: '',
        });
        // const userId = typeOfUser === "DOCTOR" ? id : user?.uid;
        // const userId = ACM[typeOfUser]?.isDoctor ? id : user?.uid;
        const userId =
          ACM[typeOfUser]?.isDoctor || ACM[typeOfUser]?.isTeacher
            ? id
            : user?.uid;

        // const userId = user?.uid;
        if (
          uploadedFile &&
          (uploadedFile.type === 'application/pdf' ||
            uploadedFile.type === 'application/vnd.ms-excel' ||
            uploadedFile.type ===
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
            uploadedFile.type === 'text/csv')
        ) {
          const attachmentID = uuidv4();
          const blob = new Blob([uploadedFile], {
            type: uploadedFile.type,
          });

          const videoStorageRef = ref(
            FirebaseStorage,
            `historicalData/${userId}/${attachmentID}/attachments/${uploadedFile.name}`
          );
          const IEPattachmentStorageRef = ref(
            FirebaseStorage,
            `historicalData/${userId}/${attachmentID}/IEPattachments/${uploadedFile.name}`
          );

          const result = await UploadFileToStorage(videoStorageRef, blob);
          const IEPattachmentsResult = await UploadFileToStorage(
            IEPattachmentStorageRef,
            blob
          );

          if (typeOfDocument === 'Summary') {
            if (result.status) {
              const url = result.data;
              const params = {
                // duration: fileDuration || 0,
                fileName: uploadedFile.name,
                fileSize: uploadedFile.size,
                fileType: uploadedFile.type,
                url: url,
                id: attachmentID,
                userName: user?.fullname,
                userType: typeOfUser,
                typeOfDocument: typeOfDocument,
              };

              // added this logic to prevent isPrivate only for doctor
              const docData = {
                info: btoa(JSON.stringify(params)),
                isEncrypted: false,
                notes: btoa(information?.notes || ''),
                isDeleted: false,
                createdAt: Timestamp.now(),
                selectedDate: date || null,
                isPrivate: ACM[typeOfUser]?.isDoctor ? isPrivate : false, // Conditionally add isPrivate if typeOfUser is DOCTOR
                isPrivateAll: isPrivateAll,
                uploadedId: user.uid,
                typeOfDocument: typeOfDocument,
              };

              await setDoc(doc(FirestoreDB, 'historicalData', id), {
                documentsData: [],
              });

              await setDoc(
                doc(
                  FirestoreDB,
                  'historicalData',
                  id,
                  'attachments',
                  attachmentID
                ),
                docData
              );

              const documentUploadKey = ACM[typeOfUser]?.isDoctor
                ? ANALYTICS_KEYS.DASHBOARD.DOCTORDOCUMENTUPLOAD
                : ANALYTICS_KEYS.DASHBOARD.PATIENTDOCUMENTUPLOAD;

              const userType = typeOfUser
                ? typeOfUser.toLowerCase()
                : 'unknown';

              // Dynamically include the typeOfUser in the video upload message
              const message = `${userType} successfully uploaded a document`;

              LogAnalyticsEvent(documentUploadKey, {
                attachmentID: attachmentID,
                message: message,
              });
              refetchAllAttachments();
              showToastMessage('success');
              handleClose();
            } else {
              toast.error('Error uploading file. Please try again.', {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
            }
          } else if (IEPattachmentsResult.status) {
            const url = IEPattachmentsResult.data;
            const params = {
              // duration: fileDuration || 0,
              fileName: uploadedFile.name,
              fileSize: uploadedFile.size,
              fileType: uploadedFile.type,
              url: url,
              id: attachmentID,
              userName: user?.fullname,
              userType: typeOfUser,
              typeOfDocument: typeOfDocument,
            };

            // added this logic to prevent isPrivate only for doctor
            const docData = {
              info: btoa(JSON.stringify(params)),
              isEncrypted: false,
              notes: btoa(information?.notes || ''),
              isDeleted: false,
              createdAt: Timestamp.now(),
              selectedDate: date || null,
              isPrivate: ACM[typeOfUser]?.isDoctor ? isPrivate : false, // Conditionally add isPrivate if typeOfUser is DOCTOR
              isPrivateAll: isPrivateAll,
              uploadedId: user.uid,
              typeOfDocument: typeOfDocument,
            };

            await setDoc(
              doc(
                FirestoreDB,
                'historicalData',
                id,
                'IEPattachments',
                attachmentID
              ),
              docData
            );

            const documentUploadKey = ACM[typeOfUser]?.isDoctor
              ? ANALYTICS_KEYS.DASHBOARD.DOCTORDOCUMENTUPLOAD
              : ANALYTICS_KEYS.DASHBOARD.PATIENTDOCUMENTUPLOAD;

            const userType = typeOfUser ? typeOfUser.toLowerCase() : 'unknown';

            // Dynamically include the typeOfUser in the video upload message
            const message = `${userType} successfully uploaded a document`;

            LogAnalyticsEvent(documentUploadKey, {
              attachmentID: attachmentID,
              message: message,
            });
            showToastMessage('success');
            handleClose();
          } else {
            toast.error('Error uploading file. Please try again.', {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        } else if (date >= new Date()) {
          toast.error('Please select a valid date', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        } else {
          toast.error('Please upload only documents!', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }

        setIsLoading(false);
        // handleClose();
      } else {
        setError({
          show: true,
          message: 'Enter all the above values.',
        });
      }
    } catch (error) {
      console.error('Error message: ', error);
      setIsLoading(false);
      showToastMessage('error');
    }
  };

  const videoSaveHandler = () => {
    if (ACM[typeOfUser]?.isDoctor || ACM[typeOfUser]?.isTeacher) {
      videoUploadFile(
        ACM[typeOfUser]?.isDoctor ? SelectedPatient : selectedStudent
      );
    } else {
      videoUploadFile(user.uid);
    }
  };

  const attachmentSaveHandler = () => {
    // if (ACM[typeOfUser]?.isDoctor) {
    if (ACM[typeOfUser]?.isDoctor || ACM[typeOfUser]?.isTeacher) {
      // attachmentUploadFile(SelectedPatient);
      attachmentUploadFile(
        ACM[typeOfUser]?.isDoctor ? SelectedPatient : selectedStudent
      );
    } else {
      attachmentUploadFile(user.uid);
    }
  };

  useEffect(() => {
    !openVideoUpload && resetStates();
    if (
      (ACM[typeOfUser]?.isPatient || ACM[typeOfUser]?.isStudent) &&
      user?.uid
    ) {
      getMedAndTherapy(user?.uid);
      // getSymptoms(user?.uid);
    }
  }, [openVideoUpload]);

  useEffect(() => {
    if (patients && patients.length > 0) {
      const dataArray = patients.map((each) => ({
        ...each.patientInfo,
        id: each.patientId,
      }));
      setPatientData(dataArray);
    }
  }, [patients]);

  useEffect(() => {
    if (students) {
      const dataArray = students.map((each) => ({
        id: each.id,
        name: each.name,
        email: each.email,
        parentName: each.parentName,
        schoolName: each.schoolName,
        photoURL: each.photoURL,
      }));
      setStudentData(dataArray);
    }
  }, [students]);

  const handlePatientSelection = (e) => {
    const selectedValue = e.target.value;
    setNewPatient(selectedValue);
    if (selectedTab === 0) {
      setShowDialog(true);
      getMedAndTherapy(e.target.value);
    } else {
      handleConfirmChange();
    }
    getMedAndTherapy(e.target.value);
  };

  const handleStudentSelection = (e) => {
    const selectedValue = e.target.value;
    // SetSelectedPatient(selectedValue);
    // setIsPatientSelected(true);
    setNewStudent(selectedValue);
    if (selectedTab === 0) {
      setShowDialog(true);
      getMedAndTherapy(e.target.value);
    } else {
      handleConfirmChange();
    }
  };

  useEffect(() => {
    const fetchTreatments = async () => {
      try {
        if (user) {
          const treatmentsData = await fetchPatientTreatments(user.uid);

          if (treatmentsData) {
            setPatientTreatementsData(treatmentsData);
          } else {
            console.log('No treatments found or an error occurred.');
          }
        } else {
          console.log('No user is logged in.');
        }
      } catch (error) {
        console.log('Failed to fetch treatments.');
      } finally {
        // setLoading(false);
      }
    };

    fetchTreatments();
  }, []);

  const { data: currentPatientData = [], isLoading: iscurrentPatientLoading } =
    useOnePatientData(SelectedPatient);

  const {
    data: currentStudentData = [],
    isStudentLoading: isCurrentStudentLoading,
  } = useOneStudentData(selectedStudent);

  useEffect(() => {
    if (ACM[typeOfUser]?.isDoctor || ACM[typeOfUser]?.isTeacher) {
      if (SelectedPatient || selectedStudent) {
        if (currentPatientData?.id === SelectedPatient || selectedStudent) {
          setmedAndTherapiesData(currentPatientData);
        }
      }
      if (attachmentsData?.length > 0) {
        const filteredAttachments = attachmentsData?.filter(
          (attachment) => attachment?.patientId === SelectedPatient
        );
        const allSummaries = filteredAttachments[0]?.attachmentData?.map(
          (attachment) => attachment?.docData?.summary
        );
        if (
          JSON.stringify(allSummaries) !==
          JSON.stringify(filteredAttachmentsData)
        ) {
          setFilteredAttachmentsData(allSummaries);
        }
      }
    } else if (
      ACM[typeOfUser]?.isPatient &&
      user?.uid &&
      attachmentsData?.length > 0
    ) {
      const filterPatientAttachmentsSummary = attachmentsData?.map(
        (doc) => doc?.docData?.summary
      );
      if (
        JSON.stringify(filterPatientAttachmentsSummary) !==
        JSON.stringify(patientSummaryData)
      ) {
        setPatientSummaryData(filterPatientAttachmentsSummary);
      }
    }
  }, [
    SelectedPatient,
    currentPatientData,
    attachmentsData,
    selectedStudent,
    typeOfUser,
    medAndTherapiesData,
    filteredAttachmentsData,
    patientSummaryData,
  ]);

  const handleBehaviorChange = (event) => {
    setSelectedBehaviorNames(event.target.value);
  };

  const fetchAttachmentData = async () => {
    if (!user) return;
    setIsLoading(true);
    try {
      const fetchedAttachmentData = await fetchAllAttachmentsByUser(
        user?.uid,
        typeOfUser
      );
      setAttachmentsData(fetchedAttachmentData?.data);
    } catch (error) {
      console.log('Error fetching documents:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    user && fetchAttachmentData();
  }, [user]);

  const handleAntecedentChange = (event) => {
    const { value } = event.target;
    setSelectedAntecedents(
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const handleConsequenceChange = (event) => {
    const { value } = event.target;
    setSelectedConsequences(
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const selectedBehaviorIDs =
    behaviorData[0]?.BehavioralGoalsAndBaselines?.filter((b) => {
      return selectedBehaviorNames?.includes(b.BehaviorName);
    }).map((b) => {
      return b.BehaviorID;
    });

  const relatedCrisisPlans = behaviorData[0]?.CrisisPlanSummary?.filter(
    (cp) => {
      return selectedBehaviorIDs.includes(cp.BehaviorID);
    }
  );

  const capitalizeWords = (name) => {
    return name
      ?.split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  const truncateName = (name, limit = 10) => {
    const capitalized = capitalizeWords(name);
    if (capitalized?.length > limit) {
      return `${capitalized.slice(0, limit)}...`;
    }
    return capitalized;
  };

  useEffect(() => {
    setIsFirstSectionComplete(
      Boolean(customFileName && date && (newPatient || newStudent))
    );
  }, [customFileName, SelectedPatient, selectedStudent]);

  const mergedData = ACM[typeOfUser]?.isTeacher
    ? [...students, ...preRegisteredUsers]
    : [...patientData, ...preRegisteredUsers];

  return (
    <>
      <Box>
        <BootstrapTooltip title='Upload Video' placement='left'>
          <Fab
            size='large'
            className='videoUploadButton'
            onClick={handleOpen}
            color='primary'
          >
            <FileUploadSharp />
          </Fab>
        </BootstrapTooltip>

        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            textAlign: 'left',
            gap: 10,
          }}
        >
          <Dialog
            open={openVideoUpload}
            onClose={handleClose}
            className='videoUploadDialog'
          >
            <DialogTitle sx={{ m: 0, pr: '10%', pl: '10%' }}>
              <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                centered
                // textColor="secondary"
                indicatorColor='secondary'
                className='tabs'
              >
                <Tab
                  label='Upload Video'
                  icon={<VideoFile />}
                  iconPosition='end'
                />
                <Tab
                  label='Upload Attachments'
                  iconPosition='end'
                  icon={<Attachment />}
                />
              </Tabs>
              <BrandLogo newline={false} />
            </DialogTitle>
            <DialogContent className='dialogContentBox'>
              {selectedTab === 0 && (
                <>
                  {/* {(allSymptoms?.length > 0 && typeOfUser === "PATIENT") || */}
                  {/* {typeOfUser === "DOCTOR" ? ( */}
                  {ACM[typeOfUser]?.isDoctorUploadVideo ? (
                    <>
                      <Typography
                        sx={{
                          color: '#9291F0',
                          fontFamily: 'Karla',
                          fontWeight: 750,
                          fontSize: '24px',
                          borderBottom: '3px solid #9291F0',
                        }}
                      ></Typography>
                      <Typography
                        gutterBottom
                        sx={{ textAlign: 'center', mt: '0.3rem' }}
                      >
                        Upload video for review
                      </Typography>
                      <Box className='flexBox flexCenter'>
                        <BootstrapTooltip
                          title='choose a file'
                          placement='right'
                        >
                          <Fab
                            variant='extended'
                            component='label'
                            color='secondary'
                            size='small'
                          >
                            <CloudUploadRounded />
                            <input
                              hidden
                              accept='video/*'
                              multiple
                              type='file'
                              onChange={handleFileChange}
                              disabled={isLoading}
                            />
                          </Fab>
                        </BootstrapTooltip>
                      </Box>

                      <Typography
                        className='flexBox flexCenter'
                        color={'GrayText'}
                      >
                        {uploadedFile?.name && (
                          <>
                            {customFileName
                              ? customFileName.charAt(0).toUpperCase() +
                                customFileName.slice(1)
                              : uploadedFile?.name}
                            {uploadedFile?.name && (
                              <CheckCircleOutlineRounded color='success' />
                            )}
                          </>
                        )}
                      </Typography>

                      <Typography
                        gutterBottom
                        className='dateInput'
                        style={{ marginBottom: '8px' }}
                      >
                        * File Name
                      </Typography>
                      <TextField
                        variant='outlined'
                        label='Custom File Name'
                        value={customFileName}
                        onChange={handleCustomFileNameChange}
                        disabled={isLoading}
                        className='fullWidth'
                      />
                      <Typography gutterBottom className='dateInput'>
                        * Date of video taken
                      </Typography>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <MobileDateTimePicker
                          label='Date Of Video Taken'
                          disableFuture
                          className='fullWidth'
                          value={date || null}
                          disabled={isLoading}
                          onChange={dateChangeHandler}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>

                      <Typography gutterBottom>
                        {ACM[typeOfUser]?.isTeacher
                          ? 'Students List'
                          : 'Patients List'}
                      </Typography>
                      <Select
                        value={
                          ACM[typeOfUser]?.isTeacher
                            ? selectedStudent
                            : SelectedPatient
                        }
                        className='fullWidth'
                        onChange={
                          ACM[typeOfUser]?.isTeacher
                            ? handleStudentSelection
                            : handlePatientSelection
                        }
                        disabled={isLoading}
                      >
                        {mergedData?.map((item) => {
                          return (
                            <MenuItem key={item.id} value={item?.id}>
                              <BootstrapTooltip
                                title={
                                  item?.parentName
                                    ? `${item.fullname || item.name}(${
                                        item.parentName
                                      })`
                                    : item?.fullname || item.name
                                }
                              >
                                {item?.parentName
                                  ? `${truncateName(
                                      item.fullname || item.name
                                    )} (${truncateName(item.parentName)})`
                                  : `${truncateName(
                                      item.fullname || item.name
                                    )}`}
                              </BootstrapTooltip>
                            </MenuItem>
                          );
                        })}
                      </Select>

                      <Typography
                        sx={{
                          color: '#9291F0',
                          fontFamily: 'Karla',
                          fontWeight: 750,
                          fontSize: '24px',
                          borderBottom: '3px solid #9291F0',
                          marginTop: '20px',
                        }}
                      ></Typography>
                      <Typography
                        sx={{
                          marginBottom: '8px',
                          color: !isFirstSectionComplete ? 'gray' : '#9291F0',
                        }}
                        gutterBottom={isFirstSectionComplete}
                        // gutterBottom
                        className='dateInput'
                        // style={{ marginBottom: "8px" }}
                      >
                        * Diagnosis
                      </Typography>
                      {medAndTherapiesData?.data?.length > 0 ? (
                        <>
                          <Select
                            value={diagnosisDropdown}
                            className='fullWidth'
                            onChange={(e) => {
                              if (e.target.value === 'addNew') {
                                setShowTextField(true);
                              } else {
                                setDiagnosisDropdown(e.target.value);
                                setShowTextField(false);
                              }
                            }}
                            // disabled={isLoading || !isPatientSelected}
                            disabled={isLoading || !isFirstSectionComplete}
                          >
                            {medAndTherapiesData?.data?.map((item, i) => (
                              <MenuItem
                                key={`${i}`}
                                value={item?.treatment_name}
                              >
                                {item?.treatment_name}
                              </MenuItem>
                            ))}
                            <MenuItem value='addNew'>
                              + Add New Diagnosis
                            </MenuItem>
                          </Select>
                          {showTextField && (
                            <TextField
                              variant='outlined'
                              label='New Diagnosis'
                              value={diagnosisDropdown}
                              onChange={(e) =>
                                setDiagnosisDropdown(e.target.value)
                              }
                              // disabled={isLoading || !isPatientSelected}
                              className='fullWidth'
                              style={{ marginTop: '8px' }}
                            />
                          )}
                        </>
                      ) : (
                        <TextField
                          variant='outlined'
                          label='Diagnosis'
                          value={diagnosisDropdown}
                          onChange={(e) => setDiagnosisDropdown(e.target.value)}
                          // disabled={isLoading || !isPatientSelected}
                          disabled={isLoading || !isFirstSectionComplete}
                          className='fullWidth'
                        />
                      )}

                      {/* <Typography gutterBottom>* Type of therapy</Typography> */}
                      <Typography
                        sx={{
                          marginBottom: '8px',
                          color: !isFirstSectionComplete ? 'gray' : '#9291F0',
                        }}
                        gutterBottom={isFirstSectionComplete}
                      >
                        * Type of therapy
                      </Typography>
                      {medAndTherapiesData?.data?.length > 0 &&
                      diagnosisDropdown ? (
                        <>
                          <Select
                            value={therapiesDropdown}
                            className='fullWidth'
                            onChange={(e) => {
                              if (e.target.value === 'addNewTherapy') {
                                setShowTherapyTextField(true);
                              } else {
                                setTherapiesDropdown(e.target.value);
                                setShowTherapyTextField(false);
                              }
                            }}
                            // disabled={isLoading || !isPatientSelected}
                            disabled={isLoading || !isFirstSectionComplete}
                          >
                            {medAndTherapiesData?.data
                              ?.filter(
                                (item) =>
                                  item?.treatment_name === diagnosisDropdown
                              )
                              ?.map((item, i) =>
                                item?.therapies?.map((therapy, j) => (
                                  <MenuItem
                                    key={`${i}-${j}`}
                                    value={therapy?.therapy_name}
                                  >
                                    {therapy?.therapy_name}
                                  </MenuItem>
                                ))
                              )}
                            <MenuItem value='addNewTherapy'>
                              + Add New Therapy
                            </MenuItem>
                          </Select>
                          {showTherapyTextField && (
                            <TextField
                              variant='outlined'
                              label='New Therapy'
                              value={therapiesDropdown}
                              onChange={(e) =>
                                setTherapiesDropdown(e.target.value)
                              }
                              // disabled={isLoading || !isPatientSelected}
                              disabled={isLoading || !isFirstSectionComplete}
                              className='fullWidth'
                              style={{ marginTop: '8px' }}
                            />
                          )}
                        </>
                      ) : (
                        <TextField
                          variant='outlined'
                          label='Therapies'
                          value={therapiesDropdown}
                          onChange={(e) => setTherapiesDropdown(e.target.value)}
                          // disabled={isLoading || !isPatientSelected}
                          disabled={isLoading || !isFirstSectionComplete}
                          className='fullWidth'
                        />
                      )}
                      <Typography
                        sx={{
                          marginBottom: '8px',
                          color: !isFirstSectionComplete ? 'gray' : '#9291F0',
                        }}
                        gutterBottom={isFirstSectionComplete}
                        // gutterBottom
                        className='dateInput'
                        // style={{ marginBottom: "8px" }}
                        disabled={!isFirstSectionComplete}
                      >
                        * Medication
                      </Typography>
                      {medAndTherapiesData?.data?.length > 0 &&
                      diagnosisDropdown ? (
                        <>
                          <Select
                            value={Medications}
                            className='fullWidth'
                            onChange={(e) => {
                              if (e.target.value === 'addNewMedication') {
                                setShowMedicationTextField(true);
                              } else {
                                setMedications(e.target.value);
                                setShowMedicationTextField(false);
                              }
                            }}
                            // disabled={isLoading || !isPatientSelected}
                            disabled={isLoading || !isFirstSectionComplete}
                          >
                            {medAndTherapiesData?.data
                              ?.filter(
                                (item) =>
                                  item?.treatment_name === diagnosisDropdown
                              )
                              ?.map((item, i) =>
                                item?.meds?.map((med, j) => (
                                  <MenuItem
                                    key={`${i}-${j}`}
                                    value={med?.drug_name}
                                  >
                                    {med?.drug_name}
                                  </MenuItem>
                                ))
                              )}
                            <MenuItem value='addNewMedication'>
                              + Add New Medication
                            </MenuItem>
                          </Select>
                          {showMedicationTextField && (
                            <TextField
                              variant='outlined'
                              label='New Medication'
                              value={Medications}
                              onChange={(e) => setMedications(e.target.value)}
                              // disabled={isLoading || !isPatientSelected}
                              disabled={isLoading || !isFirstSectionComplete}
                              className='fullWidth'
                              style={{ marginTop: '8px' }}
                            />
                          )}
                        </>
                      ) : (
                        <TextField
                          variant='outlined'
                          label='Medications'
                          value={Medications}
                          onChange={(e) => setMedications(e.target.value)}
                          // disabled={isLoading || !isPatientSelected}
                          disabled={isLoading || !isFirstSectionComplete}
                          className='fullWidth'
                        />
                      )}
                      <div>
                        {/* Behavior Selection Dropdown */}
                        <Typography
                          sx={{
                            color: !isFirstSectionComplete ? 'gray' : '#9291F0',
                          }}
                          gutterBottom={isFirstSectionComplete}
                          className='dateInput'
                          disabled={!isFirstSectionComplete}
                        >
                          * Behaviors
                        </Typography>
                        <FormControl
                          fullWidth
                          variant='outlined'
                          margin='normal'
                          disabled={!isFirstSectionComplete}
                        >
                          <InputLabel>Classify Behaviors</InputLabel>
                          <Select
                            multiple
                            value={selectedBehaviorNames}
                            onChange={handleBehaviorChange}
                            input={<OutlinedInput label='Classify Behaviors' />}
                            renderValue={(selected) => selected.join(', ')}
                          >
                            {behaviorData[0]?.BehavioralGoalsAndBaselines?.map(
                              (behavior, index) => (
                                // <MenuItem key={behavior.BehaviorID} value={behavior.BehaviorName}>
                                //   <Checkbox checked={selectedBehaviors.indexOf(behavior.BehaviorName) > -1} />
                                //   <ListItemText primary={behavior.BehaviorName} />
                                // </MenuItem>
                                <MenuItem
                                  key={`behavior-${index}`}
                                  value={behavior.BehaviorName}
                                >
                                  <Checkbox
                                    checked={selectedBehaviorNames.includes(
                                      behavior.BehaviorName
                                    )}
                                  />
                                  <ListItemText
                                    primary={behavior.BehaviorName}
                                  />
                                </MenuItem>
                              )
                            )}
                          </Select>
                        </FormControl>

                        <Box sx={{ paddingTop: 1 }}>
                          {selectedBehaviorNames?.length > 0 ? (
                            relatedCrisisPlans?.length > 0 ? (
                              relatedCrisisPlans?.map((definition, index) => (
                                <Box key={index} sx={{ marginBottom: 1 }}>
                                  <Typography sx={{ fontWeight: 'bold' }}>
                                    Behavior definition:{' '}
                                    {`${definition.TargetBehavior}`}
                                  </Typography>
                                  <TextareaAutosize
                                    minRows={1}
                                    maxRows={3}
                                    readOnly
                                    style={{ width: '100%' }}
                                    value={definition.Definition}
                                  />
                                </Box>
                              ))
                            ) : (
                              <p>
                                No matching behavior found in Crisis Plan
                                Summary.
                              </p>
                            )
                          ) : null}
                        </Box>

                        {/* Antecedent Interventions Multi-Select Checkboxes */}
                        <Typography
                          sx={{
                            color: !isFirstSectionComplete ? 'gray' : '#9291F0',
                          }}
                          gutterBottom={isFirstSectionComplete}
                          className='dateInput'
                          disabled={!isFirstSectionComplete}
                        >
                          * Interventions
                        </Typography>
                        <FormControl
                          fullWidth
                          variant='outlined'
                          margin='normal'
                          disabled={!isFirstSectionComplete}
                        >
                          <InputLabel>Antecedent Interventions</InputLabel>
                          <Select
                            multiple
                            value={selectedAntecedents}
                            onChange={handleAntecedentChange}
                            input={
                              <OutlinedInput label='Antecedent Interventions' />
                            }
                            renderValue={(selected) => selected.join(', ')}
                          >
                            {[
                              ...new Set(
                                relatedCrisisPlans?.flatMap(
                                  (cp) => cp.AntecedentInterventions
                                )
                              ),
                            ]?.map((intervention, index) => (
                              <MenuItem key={index} value={intervention}>
                                <Checkbox
                                  checked={
                                    selectedAntecedents.indexOf(intervention) >
                                    -1
                                  }
                                />
                                <ListItemText primary={intervention} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>

                        {/* Consequence Interventions Multi-Select Checkboxes */}
                        <FormControl
                          fullWidth
                          variant='outlined'
                          margin='normal'
                          disabled={!isFirstSectionComplete}
                        >
                          <InputLabel>Consequence Interventions</InputLabel>
                          <Select
                            multiple
                            value={selectedConsequences}
                            onChange={handleConsequenceChange}
                            input={
                              <OutlinedInput label='Consequence Interventions' />
                            }
                            renderValue={(selected) => selected.join(', ')}
                          >
                            {/* {relatedCrisisPlans?.flatMap((cp) => cp.ConsequenceInterventions) */}
                            {[
                              ...new Set(
                                relatedCrisisPlans?.flatMap(
                                  (cp) => cp.ConsequenceInterventions
                                )
                              ),
                            ]?.map((intervention, index) => (
                              <MenuItem key={index} value={intervention}>
                                <Checkbox
                                  checked={
                                    selectedConsequences.indexOf(intervention) >
                                    -1
                                  }
                                />
                                <ListItemText primary={intervention} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                      <Typography
                        sx={{
                          marginBottom: '8px',
                          color: !isFirstSectionComplete ? 'gray' : '#9291F0',
                        }}
                        gutterBottom={isFirstSectionComplete}
                      >
                        * Description of events leading up to this behavior
                      </Typography>
                      <TextField
                        className='notes fullWidth fullHeight'
                        variant='outlined'
                        size='medium'
                        label='Please input descrption...'
                        sx={{ my: 0.5 }}
                        multiline
                        rows={6}
                        InputProps={{
                          style: {
                            minHeight: '55px', // Adjust the height as needed
                          },
                        }}
                        value={information.notes}
                        onChange={(e) =>
                          setInformation({
                            ...information,
                            notes: e.target.value,
                          })
                        }
                        // disabled={isLoading || !isPatientSelected}
                        disabled={isLoading || !isFirstSectionComplete}
                      />
                      {error.show && (
                        <Typography color={'red'} sx={{ py: 2 }}>
                          {error.message}
                        </Typography>
                      )}
                      <DialogActions
                        className={`fullWidth flexBox flexWrap dialogActionsFixed`}
                        sx={{
                          alignItems: 'center',
                          justifyContent: 'center',
                          paddingRight: '10%',
                          paddingLeft: '10%',
                          gap: 1,
                          mt: 3,
                        }}
                      >
                        <Button
                          variant='outlined'
                          onClick={handleClose}
                          sx={{
                            width: '48%',
                            fontFamily: 'Lato',
                            textTransform: 'capitalize',
                            color: 'var(--clr-theme-purple)',
                            borderColor: 'var(--clr-theme-purple)',
                          }}
                          disabled={isLoading}
                        >
                          Cancel
                        </Button>
                        <Button
                          sx={{
                            alignItems: 'center',
                            backgroundColor:
                              'var(--clr-theme-purple)!important',
                            color: 'white',
                            width: '48%',
                            fontFamily: 'Lato',
                            textTransform: 'capitalize',
                            '&:hover': {
                              backgroundColor: isPatientSelected
                                ? 'var(--clr-theme-purple-50)'
                                : 'disabled',
                            },
                            '&:disabled': {
                              color: 'var(--clr-theme-white) !important',
                              backgroundColor: 'rgb(211, 211, 211) !important',
                            },
                          }}
                          variant='contained'
                          onClick={videoSaveHandler}
                          disabled={isLoading}
                          // disabled={selectedSymptoms?.length <= 0}
                        >
                          Save
                          {/* <SendRounded sx={{ ml: 1 }} fontSize="small" /> */}
                        </Button>

                        {isLoading && <Progress />}
                      </DialogActions>
                    </>
                  ) : (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'start',
                        gap: 1,
                      }}
                    >
                      <Box className='fullWidth flexBox flexColumn flexCenter'>
                        <Typography
                          gutterBottom
                          sx={{
                            textAlign: 'center',
                            mt: '1rem',
                            fontFamily: 'Lato',
                          }}
                        >
                          Upload video for review
                        </Typography>
                        <Box className='flexBox flexCenter'>
                          <BootstrapTooltip
                            title='choose a file'
                            placement='right'
                          >
                            <Fab
                              variant='extended'
                              component='label'
                              color='secondary'
                              size='small'
                            >
                              <CloudUploadRounded />
                              <input
                                hidden
                                accept='video/*'
                                multiple
                                type='file'
                                onChange={handleFileChange}
                                disabled={isLoading}
                              />
                            </Fab>
                          </BootstrapTooltip>
                        </Box>
                        <Typography
                          className='flexBox flexCenter'
                          color={'GrayText'}
                        >
                          {uploadedFile?.name && (
                            <>
                              {customFileName
                                ? customFileName.charAt(0).toUpperCase() +
                                  customFileName.slice(1)
                                : uploadedFile?.name}
                              {uploadedFile?.name && (
                                <CheckCircleOutlineRounded color='success' />
                              )}
                            </>
                          )}
                        </Typography>
                      </Box>
                      <Typography>Section One</Typography>
                      <Typography
                        gutterBottom
                        className='dateInput'
                        style={{ marginBottom: '8px' }}
                      >
                        * File Name
                      </Typography>
                      <TextField
                        variant='outlined'
                        label='Custom File Name'
                        value={customFileName}
                        onChange={handleCustomFileNameChange}
                        disabled={isLoading}
                        className='fullWidth'
                      />

                      <Typography gutterBottom>
                        * Date of video taken
                      </Typography>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <MobileDateTimePicker
                          label='Date Of Video Taken'
                          disableFuture
                          className='fullWidth'
                          value={date || null}
                          onChange={dateChangeHandler}
                          disabled={isLoading}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                      <Typography
                        gutterBottom
                        className='dateInput'
                        style={{ marginBottom: '8px' }}
                      >
                        * Diagnosis
                      </Typography>
                      {patientTreatmentsData?.length > 0 ? (
                        <>
                          <Select
                            value={diagnosisDropdown}
                            className='fullWidth'
                            onChange={(e) => {
                              if (e.target.value === 'addNew') {
                                setShowTextField(true);
                              } else {
                                setDiagnosisDropdown(e.target.value);
                                setShowTextField(false);
                              }
                            }}
                            disabled={isLoading}
                          >
                            {patientTreatmentsData?.map((item, i) => (
                              <MenuItem
                                key={`${i}`}
                                value={item?.treatment_name}
                              >
                                {item?.treatment_name}
                              </MenuItem>
                            ))}
                            <MenuItem value='addNew'>
                              + Add New Diagnosis
                            </MenuItem>
                          </Select>
                          {showTextField && (
                            <TextField
                              variant='outlined'
                              label='New Diagnosis'
                              value={diagnosisDropdown}
                              onChange={(e) =>
                                setDiagnosisDropdown(e.target.value)
                              }
                              className='fullWidth'
                              style={{ marginTop: '8px' }}
                            />
                          )}
                        </>
                      ) : (
                        <TextField
                          variant='outlined'
                          label='Diagnosis'
                          value={diagnosisDropdown}
                          onChange={(e) => setDiagnosisDropdown(e.target.value)}
                          disabled={isLoading}
                          className='fullWidth'
                        />
                      )}
                      <Typography gutterBottom>* Type of therapy</Typography>
                      {patientTreatmentsData?.length > 0 &&
                      diagnosisDropdown ? (
                        <>
                          <Select
                            value={therapiesDropdown}
                            className='fullWidth'
                            onChange={(e) => {
                              if (e.target.value === 'addNewTherapy') {
                                setShowTherapyTextField(true);
                              } else {
                                setTherapiesDropdown(e.target.value);
                                setShowTherapyTextField(false);
                              }
                            }}
                            disabled={isLoading}
                          >
                            {patientTreatmentsData
                              ?.filter(
                                (item) =>
                                  item?.treatment_name === diagnosisDropdown
                              )
                              ?.map((item, i) =>
                                item?.therapies?.map((therapy, j) => (
                                  <MenuItem
                                    key={`${i}-${j}`}
                                    value={therapy?.therapy_name}
                                  >
                                    {therapy?.therapy_name}
                                  </MenuItem>
                                ))
                              )}
                            <MenuItem value='addNewTherapy'>
                              + Add New Therapy
                            </MenuItem>
                          </Select>
                          {showTherapyTextField && (
                            <TextField
                              variant='outlined'
                              label='New Therapy'
                              value={therapiesDropdown}
                              onChange={(e) =>
                                setTherapiesDropdown(e.target.value)
                              }
                              disabled={isLoading}
                              className='fullWidth'
                              style={{ marginTop: '8px' }}
                            />
                          )}
                        </>
                      ) : (
                        <TextField
                          variant='outlined'
                          label='Therapies'
                          value={therapiesDropdown}
                          onChange={(e) => setTherapiesDropdown(e.target.value)}
                          disabled={isLoading}
                          className='fullWidth'
                        />
                      )}
                      <Typography
                        gutterBottom
                        className='dateInput'
                        style={{ marginBottom: '8px' }}
                      >
                        * Medication
                      </Typography>
                      {patientTreatmentsData?.length > 0 &&
                      diagnosisDropdown ? (
                        <>
                          <Select
                            value={Medications}
                            className='fullWidth'
                            onChange={(e) => {
                              if (e.target.value === 'addNewMedication') {
                                setShowMedicationTextField(true);
                              } else {
                                setMedications(e.target.value);
                                setShowMedicationTextField(false);
                              }
                            }}
                            disabled={isLoading}
                          >
                            {patientTreatmentsData
                              ?.filter(
                                (item) =>
                                  item?.treatment_name === diagnosisDropdown
                              )
                              ?.map((item, i) =>
                                item?.meds?.map((med, j) => (
                                  <MenuItem
                                    key={`${i}-${j}`}
                                    value={med?.drug_name}
                                  >
                                    {med?.drug_name}
                                  </MenuItem>
                                ))
                              )}
                            <MenuItem value='addNewMedication'>
                              + Add New Medication
                            </MenuItem>
                          </Select>
                          {showMedicationTextField && (
                            <TextField
                              variant='outlined'
                              label='New Medication'
                              value={Medications}
                              onChange={(e) => setMedications(e.target.value)}
                              disabled={isLoading}
                              className='fullWidth'
                              style={{ marginTop: '8px' }}
                            />
                          )}
                        </>
                      ) : (
                        <TextField
                          variant='outlined'
                          label='Medications'
                          value={Medications}
                          onChange={(e) => setMedications(e.target.value)}
                          disabled={isLoading}
                          className='fullWidth'
                        />
                      )}
                      {/* Behavior Selection Dropdown */}
                      <FormControl fullWidth variant='outlined' margin='normal'>
                        <InputLabel>Classify Behaviors</InputLabel>
                        <Select
                          multiple
                          value={selectedBehaviorNames}
                          onChange={handleBehaviorChange}
                          input={<OutlinedInput label='Classify Behaviors' />}
                          renderValue={(selected) => selected.join(', ')}
                        >
                          {behaviorData[0]?.BehavioralGoalsAndBaselines?.map(
                            (behavior, index) => (
                              // <MenuItem key={behavior.BehaviorID} value={behavior.BehaviorName}>
                              //   <Checkbox checked={selectedBehaviors.indexOf(behavior.BehaviorName) > -1} />
                              //   <ListItemText primary={behavior.BehaviorName} />
                              // </MenuItem>
                              <MenuItem
                                key={`behavior-${index}`}
                                value={behavior.BehaviorName}
                              >
                                <Checkbox
                                  checked={selectedBehaviorNames.includes(
                                    behavior.BehaviorName
                                  )}
                                />
                                <ListItemText primary={behavior.BehaviorName} />
                              </MenuItem>
                            )
                          )}
                        </Select>
                      </FormControl>

                      <Box sx={{ paddingTop: 1, width: '100%' }}>
                        {selectedBehaviorNames?.length > 0 ? (
                          relatedCrisisPlans?.length > 0 ? (
                            relatedCrisisPlans?.map((definition, index) => (
                              <Box key={index} sx={{ marginBottom: 1 }}>
                                <Typography sx={{ fontWeight: 'bold' }}>
                                  Behavior definition:{' '}
                                  {`${definition.TargetBehavior}`}
                                </Typography>
                                <TextareaAutosize
                                  minRows={1}
                                  maxRows={3}
                                  readOnly
                                  style={{ width: '100%' }}
                                  value={definition.Definition}
                                />
                              </Box>
                            ))
                          ) : (
                            <p>
                              No matching behavior found in Crisis Plan Summary.
                            </p>
                          )
                        ) : null}
                      </Box>

                      {/* Antecedent Interventions Multi-Select Checkboxes */}
                      <FormControl fullWidth variant='outlined' margin='normal'>
                        <InputLabel>Antecedent Interventions</InputLabel>
                        <Select
                          multiple
                          value={selectedAntecedents}
                          onChange={handleAntecedentChange}
                          input={
                            <OutlinedInput label='Antecedent Interventions' />
                          }
                          renderValue={(selected) => selected.join(', ')}
                        >
                          {[
                            ...new Set(
                              relatedCrisisPlans?.flatMap(
                                (cp) => cp.AntecedentInterventions
                              )
                            ),
                          ]?.map((intervention, index) => (
                            <MenuItem key={index} value={intervention}>
                              <Checkbox
                                checked={
                                  selectedAntecedents.indexOf(intervention) > -1
                                }
                              />
                              <ListItemText primary={intervention} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      {/* Consequence Interventions Multi-Select Checkboxes */}
                      <FormControl fullWidth variant='outlined' margin='normal'>
                        <InputLabel>Consequence Interventions</InputLabel>
                        <Select
                          multiple
                          value={selectedConsequences}
                          onChange={handleConsequenceChange}
                          input={
                            <OutlinedInput label='Consequence Interventions' />
                          }
                          renderValue={(selected) => selected.join(', ')}
                        >
                          {/* {relatedCrisisPlans?.flatMap((cp) => cp.ConsequenceInterventions) */}
                          {[
                            ...new Set(
                              relatedCrisisPlans?.flatMap(
                                (cp) => cp.ConsequenceInterventions
                              )
                            ),
                          ]?.map((intervention, index) => (
                            <MenuItem key={index} value={intervention}>
                              <Checkbox
                                checked={
                                  selectedConsequences.indexOf(intervention) >
                                  -1
                                }
                              />
                              <ListItemText primary={intervention} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      <Typography gutterBottom>
                        * Description events leading up to this behavior
                      </Typography>
                      <TextField
                        className='notes fullWidth fullHeight'
                        variant='outlined'
                        size='medium'
                        label='Please input descrption...'
                        sx={{ my: 0.5 }}
                        multiline
                        rows={6}
                        InputProps={{
                          style: {
                            minHeight: '55px', // Adjust the height as needed
                          },
                        }}
                        onChange={(e) =>
                          setInformation({
                            ...information,
                            notes: e.target.value,
                          })
                        }
                        disabled={isLoading}
                      />
                      {error.show && (
                        <Typography color={'red'} sx={{ py: 2 }}>
                          {error.message}
                        </Typography>
                      )}
                      <Box className='flexBox flexCenter' gap={1}>
                        <Info sx={{ color: 'var(--clr-icon-lightgray)' }} />
                        <Typography
                          color='var(--clr-icon-lightgray)'
                          sx={{ fontFamily: 'Karla' }}
                        >
                          Treatment information will be updated after doctor
                          approval.
                        </Typography>
                      </Box>
                      <Box
                        className={`fullWidth flexBox flexCenter flexWrap dialogActionsFixed`}
                        gap={2}
                        mt={3}
                      >
                        <Button
                          variant='outlined'
                          onClick={handleClose}
                          sx={{
                            width: '30%',
                            fontFamily: 'Lato',
                            textTransform: 'capitalize',
                            color: 'var(--clr-theme-purple)',
                            borderColor: 'var(--clr-theme-purple)',
                          }}
                          disabled={isLoading}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant='extended'
                          onClick={videoSaveHandler}
                          sx={{
                            width: '30%',
                            // alignSelf: "end",
                            backgroundColor:
                              'var(--clr-theme-purple)!important',
                            color: 'var(--clr-theme-white)',
                            '&:disabled': {
                              color: 'var(--clr-theme-white) !important',
                              backgroundColor: 'rgb(211, 211, 211) !important',
                            },
                          }}
                          disabled={isLoading}
                        >
                          Save
                          {/* <SendRounded sx={{ ml: 1 }} fontSize="small" /> */}
                        </Button>
                        {isLoading && <Progress />}
                      </Box>
                    </Box>
                  )}
                </>
              )}
              {selectedTab === 1 && (
                <>
                  {/* {typeOfUser === "DOCTOR" ? ( */}
                  {ACM[typeOfUser]?.isDoctorUploadAttacments ? (
                    <>
                      <Typography
                        gutterBottom
                        sx={{ textAlign: 'center', mt: '0.3rem' }}
                      >
                        Upload attachment for review
                      </Typography>
                      <Box className='flexBox flexCenter'>
                        <BootstrapTooltip
                          title='choose a file'
                          placement='right'
                        >
                          <Fab
                            variant='extended'
                            component='label'
                            color='secondary'
                            size='small'
                          >
                            <Attachment />
                            <input
                              hidden
                              accept='.pdf,.csv,.xls,.xlsx,.doc,.docx'
                              multiple
                              type='file'
                              onChange={handleFileChange}
                              disabled={isLoading}
                            />
                          </Fab>
                        </BootstrapTooltip>
                      </Box>
                      <Typography
                        className='flexBox flexCenter'
                        color={'GrayText'}
                      >
                        {uploadedFile?.name && (
                          <>
                            {uploadedFile?.name}
                            <CheckCircleOutlineRounded color='success' />
                          </>
                        )}
                      </Typography>
                      <Typography gutterBottom className='dateInput'>
                        * Date of attachment created:
                      </Typography>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <MobileDateTimePicker
                          label='Date of Attachment Created'
                          disableFuture
                          className='fullWidth'
                          value={date || null}
                          disabled={isLoading}
                          onChange={dateChangeHandler}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>

                      <Typography gutterBottom className='dateInput'>
                        * Type of Document
                      </Typography>
                      <Select
                        value={typeOfDocument}
                        className='fullWidth'
                        onChange={(e) => {
                          setTypeOfDocument(e.target.value);
                        }}
                        disabled={isLoading}
                      >
                        {documentsType.map((data, index) => {
                          return (
                            <MenuItem key={data.value} value={data.value}>
                              {data.label}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {/* {ACM[typeOfUser]?.isDoctor ? (
                        <>
                          <Typography gutterBottom>* Patients List:</Typography>
                          <Select
                            value={SelectedPatient}
                            className='fullWidth'
                            onChange={(e) => {
                              SetSelectedPatient(e.target.value);
                              // setMedTherapies([]);
                              // getMedAndTherapy(e.target.value);
                              // getSymptoms(e.target.value);
                            }}
                            disabled={isLoading}
                          >
                            {patientData?.map((patient) => {
                              return (
                                <MenuItem key={patient.id} value={patient?.id}>
                                  <BootstrapTooltip
                                    title={
                                      patient?.parentName
                                        ? `${patient.parentName}(${patient.fullname})`
                                        : patient?.fullname
                                    }
                                  >
                                    {patient?.parentName
                                      ? `${truncateName(
                                          patient.parentName
                                        )} (${truncateName(patient.fullname)})`
                                      : `${truncateName(patient.fullname)}`}
                                  </BootstrapTooltip>
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </>
                      ) : (
                        ''
                      )} */}
                      <Typography gutterBottom>
                        {ACM[typeOfUser]?.isTeacher
                          ? 'Students List'
                          : 'Patients List'}
                      </Typography>
                      <Select
                        value={
                          ACM[typeOfUser]?.isTeacher
                            ? selectedStudent
                            : SelectedPatient
                        }
                        className='fullWidth'
                        onChange={
                          ACM[typeOfUser]?.isTeacher
                            ? handleStudentSelection
                            : handlePatientSelection
                        }
                        disabled={isLoading}
                      >
                        {mergedData?.map((item) => {
                          return (
                            <MenuItem key={item.id} value={item?.id}>
                              <BootstrapTooltip
                                title={
                                  item?.parentName
                                    ? `${item.fullname || item.name}(${
                                        item.parentName
                                      })`
                                    : item?.fullname || item.name
                                }
                              >
                                {item?.parentName
                                  ? `${truncateName(
                                      item.fullname || item.name
                                    )} (${truncateName(item.parentName)})`
                                  : `${truncateName(
                                      item.fullname || item.name
                                    )}`}
                              </BootstrapTooltip>
                            </MenuItem>
                          );
                        })}
                      </Select>

                      <Typography gutterBottom>Notes:</Typography>
                      <TextField
                        className='notes fullWidth fullHeight'
                        variant='outlined'
                        size='medium'
                        label='Please input notes...'
                        sx={{ my: 0.5 }}
                        multiline
                        rows={6}
                        InputProps={{
                          style: {
                            minHeight: '55px', // Adjust the height as needed
                          },
                        }}
                        onChange={(e) =>
                          setInformation({
                            ...information,
                            notes: e.target.value,
                          })
                        }
                        disabled={isLoading}
                      />

                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isPrivate}
                            onChange={(e) => {
                              setIsPrivate(e.target.checked);
                            }}
                          />
                        }
                        label={
                          ACM[typeOfUser]?.isTeacher
                            ? 'Private to Student'
                            : 'Private to Patient'
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isPrivateAll}
                            onChange={(e) => {
                              setIsPrivateAll(e.target.checked);
                            }}
                          />
                        }
                        label={
                          ACM[typeOfUser]?.isTeacher
                            ? 'Private to Teacher'
                            : 'Private to Doctor'
                        }
                      />
                      {error.show && (
                        <Typography color={'red'} sx={{ py: 2 }}>
                          {error.message}
                        </Typography>
                      )}
                      <DialogActions
                        className='fullWidth flexBox flexWrap'
                        sx={{
                          alignItems: 'center',
                          justifyContent: 'center',
                          gap: 1,
                          mt: 3,
                        }}
                      >
                        <Button
                          variant='outlined'
                          onClick={handleClose}
                          sx={{
                            width: '48%',
                            fontFamily: 'Lato',
                            textTransform: 'capitalize',
                            color: 'var(--clr-theme-purple)',
                            borderColor: 'var(--clr-theme-purple)',
                          }}
                          disabled={isLoading}
                        >
                          Cancel
                        </Button>
                        <Button
                          sx={{
                            alignItems: 'center',
                            backgroundColor:
                              'var(--clr-theme-purple)!important',
                            color: 'white',
                            width: '48%',
                            fontFamily: 'Lato',
                            textTransform: 'capitalize',
                          }}
                          variant='contained'
                          onClick={attachmentSaveHandler}
                          disabled={isLoading}
                          // disabled={selectedSymptoms?.length <= 0}
                        >
                          Save
                          {/* <SendRounded sx={{ ml: 1 }} fontSize="small" /> */}
                        </Button>

                        {isLoading && <Progress />}
                      </DialogActions>
                    </>
                  ) : (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'start',
                        gap: 1,
                      }}
                    >
                      <Box className='fullWidth flexBox flexColumn flexCenter'>
                        <Typography
                          gutterBottom
                          sx={{
                            textAlign: 'center',
                            mt: '1rem',
                            fontFamily: 'Lato',
                          }}
                        >
                          Upload attachment for review
                        </Typography>
                        <Box className='flexBox flexCenter'>
                          <BootstrapTooltip
                            title='choose a file'
                            placement='right'
                          >
                            <Fab
                              variant='extended'
                              component='label'
                              color='secondary'
                              size='small'
                            >
                              <CloudUploadRounded />
                              <input
                                hidden
                                accept='.pdf,.csv,.xls,.xlsx,.doc,.docx'
                                multiple
                                type='file'
                                onChange={handleFileChange}
                                disabled={isLoading}
                              />
                            </Fab>
                          </BootstrapTooltip>
                        </Box>
                        <Typography
                          className='flexBox flexCenter'
                          color={'GrayText'}
                        >
                          {uploadedFile?.name && (
                            <>
                              {uploadedFile?.name}
                              <CheckCircleOutlineRounded color='success' />
                            </>
                          )}
                        </Typography>
                      </Box>

                      <Typography gutterBottom>
                        * Date of attachment created:
                      </Typography>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <MobileDateTimePicker
                          label='Date of Attachment Created'
                          disableFuture
                          className='fullWidth'
                          value={date || null}
                          onChange={dateChangeHandler}
                          disabled={isLoading}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>

                      <Typography gutterBottom>*Type of Document</Typography>

                      <Select
                        value={typeOfDocument}
                        className='fullWidth'
                        onChange={(e) => {
                          setTypeOfDocument(e.target.value);
                        }}
                        disabled={isLoading}
                      >
                        {documentsType.map((data, index) => {
                          return (
                            <MenuItem key={data.value} value={data.value}>
                              {data.label}
                            </MenuItem>
                          );
                        })}
                      </Select>

                      <Typography gutterBottom>Notes:</Typography>
                      <TextField
                        className='notes fullWidth fullHeight'
                        variant='outlined'
                        size='medium'
                        label='Please input notes...'
                        sx={{ my: 0.5 }}
                        multiline
                        rows={8}
                        onChange={(e) =>
                          setInformation({
                            ...information,
                            notes: e.target.value,
                          })
                        }
                        disabled={isLoading}
                      />
                      {error.show && (
                        <Typography color={'red'} sx={{ py: 2 }}>
                          {error.message}
                        </Typography>
                      )}

                      <Box
                        className='fullWidth flexBox flexCenter flexWrap'
                        gap={2}
                        mt={3}
                      >
                        <Button
                          variant='outlined'
                          onClick={handleClose}
                          sx={{
                            width: '30%',
                            fontFamily: 'Lato',
                            textTransform: 'capitalize',
                            color: 'var(--clr-theme-purple)',
                            borderColor: 'var(--clr-theme-purple)',
                          }}
                          disabled={isLoading}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant='extended'
                          onClick={attachmentSaveHandler}
                          sx={{
                            width: '30%',
                            // alignSelf: "end",
                            backgroundColor:
                              'var(--clr-theme-purple)!important',
                            color: 'var(--clr-theme-white)',
                            '&:disabled': {
                              color: 'var(--clr-theme-white) !important',
                              backgroundColor: 'rgb(211, 211, 211) !important',
                            },
                          }}
                          disabled={isLoading}
                        >
                          Save
                          {/* <SendRounded sx={{ ml: 1 }} fontSize="small" /> */}
                        </Button>
                        {isLoading && <Progress />}
                      </Box>
                    </Box>
                  )}
                </>
              )}
            </DialogContent>
          </Dialog>
        </Box>

        <ToastContainer />
      </Box>
      <Dialog open={showDialog && selectedTab === 0} onClose={handleDialog}>
        <DialogTitle>Confirm Changing Patient</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to change the patient? Once you click
            'Confirm,' all information in Section Two will be reset.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setIsResetConfirmed(false);
              handleDialog();
            }}
            sx={{
              width: '48%',
              fontFamily: 'Lato',
              textTransform: 'capitalize',
              color: 'var(--clr-theme-purple)',
              borderColor: 'var(--clr-theme-purple)',
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmChange}
            sx={{
              alignItems: 'center',
              backgroundColor: 'var(--clr-theme-purple)!important',
              color: 'white',
              width: '48%',
              fontFamily: 'Lato',
              textTransform: 'capitalize',
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default memo(VideoUpload);
