import { doc, setDoc, getDoc } from 'firebase/firestore';
import { FirestoreDB } from '../../utils/firebase/firebase';
import { ACM } from '../../constants/UserRoles';
import { findPermissionDifferences } from './PermissionsUtil';

export const saveDifferencesToFirestore = async (
  userId,
  role,
  userPermissions
) => {
  const localPermissions = ACM[role];
  const differences = findPermissionDifferences(
    localPermissions,
    userPermissions
  );

  if (Object.keys(differences).length >= 0) {
    try {
      const roleCollection = (() => {
        switch (role) {
          case 'DOCTOR':
            return 'doctorsList';
          case 'PATIENT':
            return 'patientsList';
          case 'STUDENT':
            return 'studentsList';
          case 'TEACHER':
            return 'teachersList';
          case 'SCHOOLADMIN':
            return 'schoolAdminsList';
          case 'ORGANIZATIONADMIN':
            return 'organizationAdminsList';
          case 'CUSTOM_ROLE':
            return 'accessPermission';
          default:
            throw new Error('Invalid role');
        }
      })();

      const docRef = doc(FirestoreDB, roleCollection, userId);
      const getDocObj = await getDoc(docRef);
      // if (!getDocObj.exists()) {
      //   console.error('Document doesnt exists.');
      //   return;
      // }
      // const data = getDocObj.data();

      let data = {};

      if (getDocObj.exists()) {
        data = getDocObj.data();
      } else if (role === 'CUSTOM_ROLE') {
        data = { permissions: {} }; // Initialize document for custom roles
      } else {
        console.error("Document doesn't exist.");
        return;
      }
      const updatedData = {
        ...data,
        permissions: differences,
      };

      await setDoc(docRef, updatedData);
    } catch (error) {
      console.error('Error saving differences:', error);
    }
  }
};

// export const fetchAndMergePermissions = async (userId, role) => {
//   try {
//     const roleCollection = (() => {
//       switch (role) {
//         case 'DOCTOR':
//           return 'doctorsList';
//         case 'PATIENT':
//           return 'patientsList';
//         case 'STUDENT':
//           return 'studentsList';
//         case 'TEACHER':
//           return 'teachersList';
//         case 'SCHOOLADMIN':
//           return 'schoolAdminsList';
//         case 'ORGANIZATIONADMIN':
//           return 'organizationAdminsList';

//         default:
//           throw new Error('Invalid role');
//       }
//     })();
//     const docRef = doc(FirestoreDB, roleCollection, userId);
//     const docSnap = await getDoc(docRef);

//     if (docSnap.exists()) {
//       const remoteDifferences = docSnap.data().permissions || {};
//       return {
//         ...Object.fromEntries(
//           Object.entries(ACM[role]).filter(
//             ([key, value]) => typeof value === 'boolean'
//           )
//         ),
//         ...remoteDifferences,
//       };
//     } else {
//       console.log('No remote permissions found, using local ACM.');
//       return ACM[role];
//     }
//   } catch (error) {
//     console.error('Error fetching permissions:', error);
//     return ACM[role];
//   }
// };

export const fetchAndMergePermissions = async (userId, role) => {
  try {
    const roleCollection = (() => {
      switch (role) {
        case 'DOCTOR':
          return 'doctorsList';
        case 'PATIENT':
          return 'patientsList';
        case 'STUDENT':
          return 'studentsList';
        case 'TEACHER':
          return 'teachersList';
        case 'SCHOOLADMIN':
          return 'schoolAdminsList';
        case 'ORGANIZATIONADMIN':
          return 'organizationAdminsList';
        case 'CUSTOM_ROLE':
          return 'accessPermission';
        default:
          throw new Error('Invalid role');
      }
    })();

    const docRef = doc(FirestoreDB, roleCollection, userId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const remoteDifferences = docSnap.data().permissions || {};
      return {
        ...Object.fromEntries(
          Object.entries(ACM[role] || {}).filter(
            ([key, value]) => typeof value === 'boolean'
          )
        ),
        ...remoteDifferences,
      };
    } else {
      console.log('No remote permissions found, using local ACM.');
      return ACM[role] || {};
    }
  } catch (error) {
    console.error('Error fetching permissions:', error);
    return ACM[role] || {};
  }
};
