import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useContext, useEffect, useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  LeftMenu,
  AdminLeftMenu,
  LeftMenu_BottomSection,
  OrgAdminLeftMenu,
  StudentPatientLeftMenu,
  SchoolAdminLeftMenu,
  TeacherLeftMenu,
} from '../../constants/LeftMenu';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { UIContext } from '../../contexts/UIProvider';
import PatientList from './PatientList';
import newLogo from '../../assets/images/LogoWhite.png';

import './LeftPanel.css';
import { LogAnalyticsEvent } from '../../utils/firebase/firebase';
import { ANALYTICS_KEYS } from '../../constants/AnalyticKeys';
import { ACMContext } from '../../contexts/ACMProvider';

// Extracted MenuItem component
const MenuItem = React.memo(
  ({
    item,
    isSelected,
    onClick,
    isDrawerExpanded,
    isNewMessage,
    isHovering,
  }) => (
    <ListItem
      disablePadding
      onClick={onClick}
      className={isSelected ? 'selected' : ''}
    >
      <ListItemButton>
        <Tooltip title={item.name} placement='right'>
          <ListItemIcon
            className={isSelected ? 'selectedIcon' : 'nonSelectedIcon'}
          >
            {item.icon}
          </ListItemIcon>
        </Tooltip>
        {(isDrawerExpanded || isHovering) && (
          <ListItemText
            className={isSelected ? 'selectedIcon' : 'nonSelectedIcon'}
            primary={item.name}
          />
        )}
        {item.key === 'Messaging' && item.additionalIcon && isNewMessage && (
          <ListItemIcon>
            <div style={{ fontSize: '1.5rem', color: 'green' }}>
              {item.additionalIcon}
            </div>
          </ListItemIcon>
        )}
      </ListItemButton>
    </ListItem>
  )
);

const LeftPanel = React.memo(({ userType }) => {
  const { ACM } = useContext(ACMContext);
  const navigate = useNavigate();
  const {
    selectedMenuKey,
    updateSelectedMenu,
    setIsDrawerOpen,
    isNewMesage,
    isDrawerExpanded,
    setIsDrawerExpanded,
    isHovering,
    setIsHovering,
  } = useContext(UIContext);

  const menuItemsToRender = useMemo(() => {
    switch (userType) {
      case 'ADMIN':
        return AdminLeftMenu;
      case 'ORGANIZATIONADMIN':
        return OrgAdminLeftMenu;
      case 'SCHOOLADMIN':
        return SchoolAdminLeftMenu;
      case 'STUDENT':
        return StudentPatientLeftMenu;
      case 'PATIENT':
        return StudentPatientLeftMenu;
      case 'TEACHER':
        return TeacherLeftMenu;
      default:
        return LeftMenu;
    }
  }, [userType]);

  const handleMenuClick = useCallback(
    (item) => {
      updateSelectedMenu(item.key);
      item.path && navigate(item.path);
      setIsDrawerOpen(false);

      // Log the analytics event for the left menubar
      LogAnalyticsEvent(ANALYTICS_KEYS.LEFTMENU.SIDEBARMENUCLICK, {
        page_name: item.name,
        page_key: item.key,
      });
    },
    [updateSelectedMenu, navigate, setIsDrawerOpen]
  );

  useEffect(() => {
    if (isDrawerExpanded === undefined) {
      setIsDrawerExpanded(false); // Collapse by default
    }
  }, [isDrawerExpanded, setIsDrawerExpanded]);

  useEffect(() => {
    if (isHovering === undefined) {
      setIsDrawerExpanded(false); // Collapse by default
    }
  }, [isHovering, setIsHovering]);

  const renderMenuItems = useCallback(
    (items) =>
      items.map((item, index) => {
        const isSelected =
          (localStorage.getItem('selectedMenuKey') || selectedMenuKey) ===
          (item.key || item.name);

        // Only render PatientList if both conditions are true:
        // 1. User is a DOCTOR
        // 2. Item has collapsible property
        if (
          (userType === 'DOCTOR' || userType === 'TEACHER') &&
          item?.collapsible
        ) {
          return (
            <PatientList
              key={index}
              icon={item.icon}
              text={item.name}
              isDrawerExpanded={isDrawerExpanded}
              isHovering={isHovering}
            />
          );
        }

        // For non-DOCTOR users or non-collapsible items, render regular MenuItem
        // Only render if the item doesn't have collapsible property
        if (!item?.collapsible) {
          return (
            <MenuItem
              key={index}
              item={item}
              isSelected={isSelected}
              onClick={() => handleMenuClick(item)}
              isDrawerExpanded={isDrawerExpanded}
              isNewMessage={isNewMesage}
              isHovering={isHovering}
            />
          );
        }

        return null; // Don't render anything for collapsible items for non-DOCTOR users
      }),
    [
      handleMenuClick,
      isDrawerExpanded,
      isNewMesage,
      selectedMenuKey,
      userType,
      isHovering,
    ]
  );

  const drawerWidth = isHovering || isDrawerExpanded ? 280 : 70;

  const handleMouseEnter = () => setIsHovering(true);
  const handleMouseLeave = () => setIsHovering(false);

  return (
    <>
      <Drawer
        className='left-drawer'
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          position: 'absolute',
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            zIndex: 1200,
            transition: 'width 0.2s ease',
            height: '100vh',
            overflowY: 'auto',
            overflowX: 'hidden',
          },
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        variant='permanent'
        anchor='left'
      >
        <Box
          className='flexBox flexCenter'
          sx={{
            m: isDrawerExpanded || isHovering ? 1 : 1,
            position: 'sticky',
            top: 0,
            zIndex: 1200,
            backgroundColor: 'var(--clr-mindly-background)',
          }}
        >
          <ListItemAvatar
            sx={{
              display: { xs: 'flex', md: 'flex' },
              mx: 1,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                backgroundColor: 'var(--clr-theme-purple)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%',
                width: '3rem',
                height: '3rem',
                overflow: 'hidden',
              }}
            >
              <img
                src={newLogo}
                alt='mindly'
                style={{
                  maxWidth: '72%',
                  maxHeight: '72%',
                  objectFit: 'contain',
                }}
              />
            </div>
          </ListItemAvatar>
          {(isDrawerExpanded || isHovering) && (
            <Typography
              className='brand'
              variant='caption'
              noWrap
              sx={{
                display: { md: 'flex' },
                fontSize: '2rem !important',
                fontFamily: 'System-ui',
                color: 'var(--clr-theme-purple)',
              }}
            >
              mindly
            </Typography>
          )}
        </Box>

        {ACM[userType]?.hasAdminMenu && (
          <List className='topSection'>
            {renderMenuItems(menuItemsToRender)}
          </List>
        )}
        {ACM[userType]?.hasSchoolAdminMenu && (
          <>
            <List className='topSection'>
              {renderMenuItems(menuItemsToRender)}
            </List>
            <List className='bottomSection'>
              {renderMenuItems(LeftMenu_BottomSection)}
            </List>
          </>
        )}
        {ACM[userType]?.hasOrganizationAdminMenu && (
          <>
            <List className='topSection'>
              {renderMenuItems(menuItemsToRender)}
            </List>
            <List className='bottomSection'>
              {renderMenuItems(LeftMenu_BottomSection)}
            </List>
          </>
        )}
        {(ACM[userType]?.hasStudentMenu || ACM[userType]?.hasPatientMenu) && (
          <>
            <List className='topSection'>
              {renderMenuItems(menuItemsToRender)}
            </List>
            <List className='bottomSection'>
              {renderMenuItems(LeftMenu_BottomSection)}
            </List>
          </>
        )}
        {ACM[userType]?.hasUserMenu && (
          <>
            <List className='topSection'>{renderMenuItems(LeftMenu)}</List>

            <List className='bottomSection'>
              {renderMenuItems(LeftMenu_BottomSection)}
            </List>
          </>
        )}
{/* 
        {ACM[userType]?.hasTeacherMenu && (
          <>
            <List className='topSection'>
              {renderMenuItems(menuItemsToRender)}
            </List>

            <List className='bottomSection'>
              {renderMenuItems(LeftMenu_BottomSection)}
            </List>
          </>
        )} */}
      </Drawer>
      <Tooltip title={isDrawerExpanded ? 'Collapse' : 'Expand'}>
        <IconButton
          sx={{
            position: 'absolute',
            left: isDrawerExpanded ? 256 : 48,
            bottom: '5%',
            transform: 'translateY(-50%)',
            zIndex: 1200,
            background: 'white',
            border: '1px solid rgba(0, 0, 0, 0.12)',
            transition: 'left 0.2s ease',
            flexShrink: 0,
            height: '45px',
            padding: '5px 10px',
            display: isHovering && !isDrawerExpanded ? 'none' : 'flex',
          }}
          onClick={() => setIsDrawerExpanded((prev) => !prev)}
        >
          {isDrawerExpanded ? <ArrowBackIcon /> : <ArrowForwardIcon />}
        </IconButton>
      </Tooltip>
    </>
  );
});

export default LeftPanel;
