import {
    Box,
    Typography,
    Card,
    CardContent,
    tooltipClasses,
    styled,
    Tooltip,
    Skeleton,
  } from '@mui/material';
  import React from 'react';
  
  import '../PatientOverview/PatientOverview.css';
  
  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
      fontWeight: 'bold',
      padding: '10px',
    },
  }));
  
  function StudentInformation({ student, currentStudentData, loading }) {
    const validateString = (str) => {
      return str?.toString().trim() || '-';
    };
  
    const truncateName = (name, maxLength = 10) => {
      return name?.length > maxLength ? `${name.slice(0, maxLength)}...` : name;
    };
    return (
      <Card className='patientInformationCard' sx={{ boxShadow: 'none' }}>
        <div className='patientInformation'>
          <CardContent
            className='flexBox flexWrap'
            sx={{
              justifyContent: 'left',
              flexDirection: 'row',
              width: '100%',
              height: '100%',
            }}
          >
            {loading ? (
              <Box sx={{ width: '100%' }}>
                <div style={{ textAlign: 'left', paddingBottom: '8px' }}>
                  <Typography
                    variant='body1'
                    fontWeight='bold'
                    align='left'
                    fontSize={14}
                    color='rgba(0, 0, 0, 0.6)'
                  >
                    Parent Name/Guardian
                  </Typography>
                </div>
                <div style={{ textAlign: 'left', paddingBottom: '8px' }}>
                  <Skeleton width='50%' height={15} animation='pulse' />
                </div>
                <div style={{ textAlign: 'left', paddingBottom: '8px' }}>
                  <Typography
                    variant='body1'
                    fontWeight='bold'
                    align='left'
                    fontSize={14}
                    color='rgba(0, 0, 0, 0.6)'
                  >
                    Email
                  </Typography>
                </div>
                <div style={{ textAlign: 'left', paddingBottom: '8px' }}>
                  <Skeleton width='60%' height={15} animation='pulse' />
                </div>
                <div style={{ textAlign: 'left', paddingBottom: '8px' }}>
                  <Typography
                    variant='body1'
                    fontWeight='bold'
                    align='left'
                    fontSize={14}
                    color='rgba(0, 0, 0, 0.6)'
                  >
                    School Name
                  </Typography>
                </div>
                <div style={{ textAlign: 'left', paddingBottom: '8px' }}>
                  <Skeleton width='60%' height={15} animation='pulse' />
                </div>
                <div style={{ textAlign: 'left' }}>
                  <Typography
                    variant='body1'
                    fontWeight='bold'
                    align='left'
                    fontSize={14}
                    color='rgba(0, 0, 0, 0.6)'
                  >
                    Gender
                  </Typography>
                </div>
                <div style={{ textAlign: 'left', paddingBottom: '8px' }}>
                  <Skeleton width='50%' height={15} animation='pulse' />
                </div>
                <div style={{ textAlign: 'left', paddingBottom: '8px' }}>
                  <Typography
                    variant='body1'
                    fontWeight='bold'
                    align='left'
                    fontSize={14}
                    color='rgba(0, 0, 0, 0.6)'
                  >
                    Contact no
                  </Typography>
                </div>
                <div style={{ textAlign: 'left', paddingBottom: '8px' }}>
                  <Skeleton width='60%' height={15} animation='pulse' />
                </div>
                <div style={{ textAlign: 'left', paddingBottom: '8px' }}>
                  <Typography
                    variant='body1'
                    fontWeight='bold'
                    align='left'
                    fontSize={14}
                    color='rgba(0, 0, 0, 0.6)'
                  >
                    Date of Birth
                  </Typography>
                </div>
                <div style={{ textAlign: 'left', paddingBottom: '8px' }}>
                  <Skeleton width='50%' height={15} animation='pulse' />
                </div>
                <div style={{ textAlign: 'left', paddingBottom: '8px' }}>
                  <Typography
                    variant='body1'
                    fontWeight='bold'
                    align='left'
                    fontSize={14}
                    color='rgba(0, 0, 0, 0.6)'
                  >
                    Age
                  </Typography>
                </div>
                <div style={{ textAlign: 'left', paddingBottom: '8px' }}>
                  <Skeleton width='20%' height={15} animation='pulse' />
                </div>
              </Box>
            ) : (
              <Box className='patientInformationBox'>
                <div style={{ textAlign: 'left' }}>
                  <>
                    <div style={{ paddingBottom: '8px' }}>
                      <Typography
                        variant='body1'
                        fontWeight='bold'
                        align='left'
                        fontSize={14}
                        color='rgba(0, 0, 0, 0.6)'
                      >
                        Parent Name/Guardian
                      </Typography>
                      <Typography variant='body2' align='left' fontSize={16}>
                        <BootstrapTooltip
                          title={currentStudentData?.user?.parentName || student?.parentName|| '-'}
                        >
                          <span>
                            {currentStudentData?.user?.parentName || student?.parentName
                              ? truncateName(currentStudentData?.user?.parentName) || truncateName(student?.parentName)
                              : '-'}
                          </span>
                        </BootstrapTooltip>
                      </Typography>
                    </div>
                    <div style={{ paddingBottom: '8px' }}>
                      <Typography
                        variant='body1'
                        fontWeight='bold'
                        align='left'
                        fontSize={14}
                        color='rgba(0, 0, 0, 0.6)'
                      >
                        Email
                      </Typography>
                      <Typography
                        variant='body2'
                        align='left'
                        fontSize={16}
                        sx={{
                          wordBreak: 'break-word',
                          overflowWrap: 'break-word',
                          whiteSpace: 'normal',
                          textAlign: 'left',
                          lineHeight: '1',
                        }}
                      >
                        {currentStudentData?.user?.email || student?.email || '-'}
                      </Typography>
                    </div>
                    <div style={{ paddingBottom: '8px' }}>
                      <Typography
                        variant='body1'
                        fontWeight='bold'
                        align='left'
                        fontSize={14}
                        color='rgba(0, 0, 0, 0.6)'
                      >
                        School Name
                      </Typography>
                      <Typography
                        variant='body2'
                        align='left'
                        fontSize={16}
                        sx={{
                          wordBreak: 'break-word',
                          overflowWrap: 'break-word',
                          whiteSpace: 'normal',
                          textAlign: 'left',
                          lineHeight: '1',
                        }}
                      >
                        {currentStudentData?.user?.schoolName || student?.schoolName || '-'}
                      </Typography>
                    </div>
                    <div style={{ paddingBottom: '8px' }}>
                      <Typography
                        variant='body1'
                        fontWeight='bold'
                        align='left'
                        fontSize={14}
                        color='rgba(0, 0, 0, 0.6)'
                      >
                        Gender
                      </Typography>
                      <Typography variant='body2' align='left' fontSize={16}>
                        {currentStudentData?.user?.gender || student?.gender || '-'}
                      </Typography>
                    </div>
                    <div style={{ paddingBottom: '8px' }}>
                      <Typography
                        variant='body1'
                        fontWeight='bold'
                        align='left'
                        fontSize={14}
                        color='rgba(0, 0, 0, 0.6)'
                      >
                        Contact no
                      </Typography>
                      <Typography variant='body2' align='left' fontSize={16}>
                        {`+${
                          currentStudentData?.user?.contactNo?.areaCode || '00'
                        }-${
                          currentStudentData?.user?.contactNo?.number ||
                          '00000000'
                        }` || student?.contactNo}
                      </Typography>
                    </div>
                    <div style={{ paddingBottom: '8px' }}>
                      <Typography
                        variant='body1'
                        fontWeight='bold'
                        align='left'
                        fontSize={14}
                        color='rgba(0, 0, 0, 0.6)'
                      >
                        Date of Birth
                      </Typography>
                      <Typography variant='body2' align='left' fontSize={16}>
                        {currentStudentData?.user?.dob &&
                          validateString(
                            new Date(
                              currentStudentData?.user?.dob.seconds * 1000 ||
                                currentStudentData?.user?.dob
                            ).toLocaleDateString()
                          )}
                      </Typography>
                    </div>
                    <div style={{ paddingBottom: '8px' }}>
                      <Typography
                        variant='body1'
                        fontWeight='bold'
                        align='left'
                        fontSize={14}
                        color='rgba(0, 0, 0, 0.6)'
                      >
                        Age
                      </Typography>
                      <Typography variant='body2' align='left' fontSize={16}>
                        {/* {validateString(
                          (() => {
                            const dobInMillis = student?.user?.dob?.seconds
                              ? student.user.dob.seconds * 1000
                              : new Date(student?.dob).getTime();
                            const ageInMillis =
                              new Date() - new Date(dobInMillis);
                            const ageInYears =
                              ageInMillis / (1000 * 3600 * 24 * 365);
                            return ageInYears < 1 ? 0 : Math.floor(ageInYears);
                          })()
                        )} */}
                        {currentStudentData?.user?.age || '-'}
                      </Typography>
                    </div>
                  </>
                </div>
              </Box>
            )}
          </CardContent>
        </div>
      </Card>
    );
  }
  
  export default StudentInformation;
  