import React, { useContext, useState } from 'react';
import MainLayout from '../../hoc/MainLayout';
import {
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  Typography,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Skeleton,
  MenuItem,
  Box,
} from '@mui/material';
import { FirestoreDB } from '../../utils/firebase/firebase';

import { toast, ToastContainer } from 'react-toastify';
import { Edit, Delete } from '@mui/icons-material';
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  updateDoc,
} from 'firebase/firestore';
import AuthContext from '../../contexts/AuthContext';
import { updateOrganizationAdminProfileDetails } from '../../api';
import useSchoolsData from '../../hooks/useSchoolsData';
import useOrganizationData from '../../hooks/useOrganizationData';

const OrganizationPage = () => {
  const { user, typeOfUser, updateGlobalUser } = useContext(AuthContext);
  const {
    data: schools = [],
    isLoading: isSchoolsLoading,
    error: schoolsError,
    refetch: refetchSchools,
  } = useSchoolsData();

  const {
    data: fetchOrgData = [],
    isLoading: isOrgLoading,
    error: orgError,
    refetch: refetchOrgData,
  } = useOrganizationData(user?.uid, typeOfUser);

  const [formData, setFormData] = useState({
    name: '',
    orgName: '',
    email: '',
    schoolName: '',
    contact: {
      areaCode: '',
      number: '',
    },
  });
  const [editIndex, setEditIndex] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [orgIndexDelete, setOrgIndexDelete] = useState(0);
  const specializationOptions = [
    'Autism Spectrum Disorder',
    'Learning Disabilities',
    'Behavioral Support',
    'Speech Therapy',
    'Occupational Therapy',
    'Physical Therapy',
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prev) => {
      if (name.startsWith('contact.')) {
        const fieldName = name.split('.')[1];
        return {
          ...prev,
          contact: { ...prev.contact, [fieldName]: value },
        };
      }

      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleClose = () => {
    setFormData({
      name: '',
      email: '',
      orgName: '',
      email: '',
      contact: {
        areaCode: '',
        number: '',
      },
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (editIndex !== null) {
        const organizationUid = fetchOrgData[editIndex]?.id;
        const params = {
          organizationName: formData.orgName || '',
          schoolName: formData.schoolName || '',
          organizationAdminName: formData.name || '',
          email: formData.email || '',
          contact: formData.contact || { areaCode: '00', number: '000000000' },
        };

        const result = await updateOrganizationAdminProfileDetails(
          btoa(JSON.stringify(params)),
          organizationUid
        );

        if (result) {
          updateGlobalUser({ ...user, ...formData });

          await refetchOrgData();
          //   resetStates();
        } else {
          console.error('API returned an error or no result');
        }
      } else {
        const selectedSchool = schools.find(
          (school) => school.schoolName === formData.schoolName
        );
        const params = {
          fromEmail: user?.email,
          inviteType: 'ORGANIZATIONADMIN',
          message: '',
          organizationName: formData.orgName || '',
          organizationAdminName: formData.name || '',
          schoolName: formData.schoolName,
          schoolId: selectedSchool?.schoolId || selectedSchool?.id || '',
          timestamp: new Date(),
          toEmail: formData.email,
        };

        try {
          const docRef = await addDoc(
            collection(FirestoreDB, 'invitations'),
            params
          );
          await updateDoc(docRef, {
            organizationId: docRef.id,
          });

          await refetchOrgData();
        } catch (e) {
          console.error('Error adding document: ', e);
        }
      }

      setIsModalOpen(false);
      if (editIndex === null)
        toast.success('Organization Admin added successfully.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      else
        toast.success('Organization Admin updated successfully.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      handleClose();
    } catch (error) {
      console.error('Error saving document: ', error);
    }
  };

  const handleEdit = (index) => {
    const organizationToEdit = fetchOrgData[index];

    setFormData({
      orgName: organizationToEdit.organizationName || '',
      name: organizationToEdit.name || '',
      email: organizationToEdit.email || '',
      schoolName: organizationToEdit.schoolName || '',
      contact: organizationToEdit.contact || {
        areaCode: '',
        number: '',
      },
    });

    setEditIndex(index);
    setIsModalOpen(true);
  };

  const handleDelete = async (index) => {
    const orgAdminToDelete = fetchOrgData[index];
    try {
      await deleteDoc(
        doc(FirestoreDB, 'organizationAdminsList', orgAdminToDelete.id)
      );
      await refetchOrgData();
      toast.success('Organization Admin deleted successfully.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } catch (error) {
      console.error('Error deleting document: ', error);
    }
  };

  const isFormValid = formData.name && formData.contact && formData.email;

  const SkeletonRow = () => {
    return (
      <TableRow>
        <TableCell>
          <Skeleton variant='text' width={150} />
        </TableCell>

        <TableCell>
          <Skeleton variant='text' width={200} />
        </TableCell>

        <TableCell>
          <Skeleton variant='text' width={150} />
        </TableCell>

        <TableCell>
          <Skeleton
            variant='rectangular'
            width={50}
            height={25}
            style={{ marginRight: '10px' }}
          />
        </TableCell>
      </TableRow>
    );
  };
  return (
    <MainLayout>
      <Box sx={{ padding: '20px' }}>
        <Typography variant='h4' gutterBottom>
          Organization Admin
        </Typography>

        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: '20px',
          }}
        >
          <Button
            variant='contained'
            color='primary'
            onClick={() => {
              setFormData({
                name: '',
                address: '',
                capacity: 0,
                specializations: [],
              });
              setEditIndex(null);
              setIsModalOpen(true);
            }}
          >
            + Add Organization
          </Button>
        </div>

        <Dialog
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          maxWidth='md'
          fullWidth
        >
          <DialogTitle>
            {editIndex !== null ? 'Edit Organization' : 'Add Organization'}
          </DialogTitle>
          <DialogContent>
            <form onSubmit={handleSubmit}>
              <Typography variant='subtitle1'>
                Organization Details :{' '}
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4} sx={{ mt: 2 }}>
                  <TextField
                    label='Organization Name'
                    name='orgName'
                    value={formData.orgName}
                    onChange={handleInputChange}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={4} sx={{ mt: 2 }}>
                  <TextField
                    label='Organization Admin Name'
                    name='name'
                    value={formData.name}
                    onChange={handleInputChange}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={4} sx={{ mt: 2 }}>
                  <TextField
                    label='Email'
                    name='email'
                    value={formData.email}
                    onChange={handleInputChange}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={4} sx={{ mt: 1 }}>
                  <TextField
                    select
                    label='School Name'
                    fullWidth
                    variant='outlined'
                    margin='normal'
                    value={formData.schoolName || ''}
                    // onChange={handleInputChange}

                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        schoolName: e.target.value,
                      }))
                    }
                  >
                    {schools.map((school) => (
                      <MenuItem key={school.id} value={school.schoolName}>
                        {school.schoolName}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography variant='subtitle1'>Contact : </Typography>
                  <TextField
                    label='Area Code'
                    name='contact.areaCode'
                    value={formData.contact?.areaCode || ''}
                    onChange={handleInputChange}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={4} sx={{ mt: 3.5 }}>
                  <TextField
                    label='Number'
                    name='contact.number'
                    value={formData.contact?.number || ''}
                    onChange={handleInputChange}
                    fullWidth
                    required
                  />
                </Grid>
              </Grid>
            </form>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setFormData({
                  name: '',
                  email: '',
                  orgName: '',
                  contact: {
                    areaCode: '',
                    number: '',
                  },
                });
                setIsModalOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              variant='contained'
              color='primary'
              disabled={!isFormValid}
            >
              {editIndex !== null ? 'Update' : 'Add'}
            </Button>
          </DialogActions>
        </Dialog>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: 'bold' }}>Name</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>Email</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>
                  Organization Name
                </TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fetchOrgData.length > 0 ? (
                fetchOrgData.map((school, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {school.name || school.fullname || school.schoolName}
                    </TableCell>
                    <TableCell>{school.email}</TableCell>
                    <TableCell>{school.organizationName}</TableCell>
                    <TableCell>
                      <Tooltip title='Edit Organization Admin Information'>
                        <Button onClick={() => handleEdit(index)}>
                          <Edit sx={{ color: 'black' }} />
                        </Button>
                      </Tooltip>
                      <Tooltip title='Delete Organization Admin'>
                        <Button
                          onClick={() => {
                            setDeleteModal(true);
                            setOrgIndexDelete(index);
                          }}
                        >
                          <Delete sx={{ color: 'red' }} />
                        </Button>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))
              ) : isSchoolsLoading || isOrgLoading ? (
                [...Array(5)].map((_, index) => <SkeletonRow key={index} />)
              ) : (
                <TableRow>
                  <TableCell colSpan={5} align='center'>
                    No Organization Admins yet. Add your first organization
                    admin above.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Dialog open={deleteModal} onClose={() => setOrgIndexDelete(0)}>
        <DialogContent>
          <Typography
            sx={{
              color: 'var(--clr-theme-gray)',
              fontFamily: 'Karla',
              fontWeight: 700,
              textAlign: 'center',
            }}
          >
            Are you sure you want to delete this organization admin?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant='contained'
            color='success'
            onClick={() => setDeleteModal(false)}
            sx={{
              backgroundColor: '#D3D3D3',
              textTransform: 'capitalize',
              fontFamily: 'Lato',
              fontWeight: 400,
              fontSize: '1.2rem',
              width: '100%',
            }}
          >
            No
          </Button>
          <Button
            variant='contained'
            color='success'
            onClick={() => {
              handleDelete(orgIndexDelete);
              setDeleteModal(false);
            }}
            sx={{
              backgroundColor: '#9291f0',
              textTransform: 'capitalize',
              fontFamily: 'Lato',
              fontWeight: 400,
              fontSize: '1.2rem',
              width: '100%',
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer />
    </MainLayout>
  );
};

export default OrganizationPage;
