import {
  PlayCircle,
  VisibilityOffOutlined,
  VisibilityOutlined,
} from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Typography,
  Skeleton,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';

import { UIContext } from '../../contexts/UIProvider';
import { LogAnalyticsEvent } from '../../utils/firebase/firebase';
import PlayVideo from '../PlayVideo';
import { ANALYTICS_KEYS } from '../../constants/AnalyticKeys';
import useOnePatientVideos from '../../hooks/useOnePatientVideos';

const VideoListPatient = ({ patient, typeOfUser, student }) => {
  const { setBottomDrawer } = useContext(UIContext);

  const {
    data: currentPatientVideos,
    isLoading: loadingfetchOnePatientAllVideos,
  } = useOnePatientVideos(patient?.id || student?.id);

  const [videoList, setVideoList] = useState([]);

  const formatDates = (days) => {
    if (days === 0) return 'Today';
    if (days < 7) return `${days} days ago`;

    const years = Math.floor(days / 365);
    const months = Math.floor((days % 365) / 30);
    const weeks = Math.floor(((days % 365) % 30) / 7);

    if (years > 0) return `${years} year${years > 1 ? 's' : ''} ago`;
    if (months > 0) return `${months} month${months > 1 ? 's' : ''} ago`;

    return `${weeks} week${weeks > 1 ? 's' : ''} ago`;
  };
 
  const handleVideoOpen = (videoDetails, patientID) => {
    setBottomDrawer({
      show: true,
      title: videoDetails?.rawVideoData?.fileName,
      patientName: videoDetails?.user?.fullname || videoDetails?.user?.name,
      patientPhoto: videoDetails?.user?.photoURL,
      content: (
        <PlayVideo
          videoList={videoList}
          handleVideoOpen={handleVideoOpen}
          videoDetails={videoDetails}
          patientID={patientID}
          typeOfUser={typeOfUser}
        />
      ),
    });
    LogAnalyticsEvent(ANALYTICS_KEYS.PATIENTOVERVIEW.VIDEOVIEW, {
      eventName: 'Patient list video Opened',
      videoID: videoDetails?.videoId,
      patientID: patientID || 'unknown_patient',
    });
  };

  useEffect(() => {
    if (currentPatientVideos?.id) {
      setVideoList(
        currentPatientVideos?.data
          ?.sort((a, b) => {
            return b.rawVideoData.time.seconds - a.rawVideoData.time.seconds;
          })
          .map((video) => {
            return {
              ...video,
              user: patient || student,
            };
          }) || []
      );
    }
  }, [currentPatientVideos]);

  const loadingList = Array(3)
    .fill(null)
    .map((_, index) => (
      <ListItem
        key={index}
        className='flexBox flexCenter listItemBox'
        style={{
          width: '97%',
          border: '1px solid white',
          boxShadow: '0 0 5px var(--clr-theme-purple)',
          padding: '16px',
          borderRadius: '5px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          cursor: 'pointer',
        }}
      >
        <ListItemAvatar>
          <Skeleton
            variant='rectangular'
            width={40}
            height={40}
            sx={{
              borderRadius: '10%',
              backgroundColor: '#e0e0e0',
            }}
          />
        </ListItemAvatar>

        <Skeleton
          variant='rectangular'
          width='85%'
          height={15}
          animation='pulse'
          sx={{
            borderRadius: '4px',
          }}
        />
      </ListItem>
    ));

  if (loadingfetchOnePatientAllVideos) return loadingList;
  return loadingfetchOnePatientAllVideos ? (
    <List className='newVideosList'>{loadingList}</List>
  ) : videoList.length > 0 ? (
    <List className='newVideosList'>
      {videoList.map((video, i) => (
        <ListItem
          key={video?.id || i}
          className='flexBox flexCenter listItemBox'
          style={{
            width: '97%',
            border: '1px solid white',
            boxShadow: '0 0 5px var(--clr-theme-purple)',
            padding: '16px',
            borderRadius: '5px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            cursor: 'pointer',
          }}
        >
          <ListItemAvatar>
            <Avatar
              variant='rounded'
              alt={patient?.fullname}
              src={patient?.photoURL}
            />
          </ListItemAvatar>
          <ListItemText
            sx={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              fontSize: '0.7rem',
            }}
            primary={
              <Typography className='username' variant='caption'>
                {video?.rawVideoData?.fileName
                  ? video.rawVideoData.fileName
                      .split(/\s+/)
                      .map(
                        (word) =>
                          word.charAt(0).toUpperCase() +
                          word.slice(1).toLowerCase()
                      )
                      .join(' ')
                  : ''}
              </Typography>
            }
            secondary={video?.patientNotes?.symptom}
          />
          <Box className='flexBox flexCenter'>
            <ListItemIcon>
              {video?.docNotesDocId ? (
                <VisibilityOutlined fontSize='small' color='success' />
              ) : (
                <VisibilityOffOutlined fontSize='small' color='error' />
              )}
            </ListItemIcon>
            <ListItemText
              className='daysAgo'
              primary={
                <Typography
                  variant='caption'
                  sx={{
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    fontSize: '0.7rem',
                  }}
                >
                  {formatDates(
                    Math.floor(
                      (new Date() -
                        new Date(video?.rawVideoData?.time?.seconds * 1000)) /
                        (1000 * 3600 * 24)
                    )
                  )}
                </Typography>
              }
            />
            <Button
              onClick={() => {
                handleVideoOpen(video, patient?.id || student?.id);
              }}
            >
              <PlayCircle sx={{ color: 'black', fontSize: '2.5rem' }} />
            </Button>
          </Box>
        </ListItem>
      ))}
    </List>
  ) : (
    <Typography>No new videos</Typography>
  );
};

export default VideoListPatient;
