import { useQuery } from '@tanstack/react-query';
import { getAllVideosFeedbackData } from '../api';

const useGetAllVideoFeedbackData = (uid) => {
  return useQuery({
    queryKey: ['useGetAllVideoFeedbackData', uid],
    queryFn: () => getAllVideosFeedbackData(uid),
    enabled: !!uid,
    staleTime: 60000,
    cacheTime: 300000,
  });
};

export default useGetAllVideoFeedbackData;
