import { useQuery } from '@tanstack/react-query';
import { fetchPreRegisteredUsers } from '../api';

const useFetchPreRegisteredUsers = (email) => {
  return useQuery({
    queryKey: ['fetchPreRegisteredUsers', email],
    queryFn: async () => {
      const fetchedData = await fetchPreRegisteredUsers(email);

      // Check if the fetched data is an array or a single object
      if (Array.isArray(fetchedData)) {
        // Handle multiple records (if needed)
        return fetchedData.map(student => ({
          id: student.id,
          teacherId: student.teacherID || student.teacherIds, 
          name: student.fullname || '', 
          email: student.email,
          parentName: student.parentName,
          dob: student.dob || '',
          gender: student.gender || '',
          grade: student.grade || '',
          contactNo: student.contactNo || '',
          photoURL: student.photoURL || null, 
          typeofUser: student.typeOfUser || '',
        })) || [];
      } else {
        // Handle the case for a single user object
        return {
          id: fetchedData.id,
          teacherId: fetchedData.teacherID || fetchedData.teacherIds,
          name: fetchedData.fullname || '',
          email: fetchedData.email,
          parentName: fetchedData.parentName,
          dob: fetchedData.dob || '',
          gender: fetchedData.gender || '',
          grade: fetchedData.grade || '',
          contactNo: fetchedData.contactNo || '',
          photoURL: fetchedData.photoURL || null,
          typeofUser: fetchedData.typeOfUser || '',
        };
      }
    },
    staleTime: 60000, // 1 minute
    cacheTime: 300000, // 5 minutes
    onError: (error) => {
      console.error('Error fetching pre-registered users:', error);
    },
  });
};

export default useFetchPreRegisteredUsers;

