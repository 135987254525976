import { useContext, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Card,
  FormControl,
  ListItemIcon,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import './dataEntry.css';
import { ACM } from '../../constants/UserRoles';
import AuthContext from '../../contexts/AuthContext';
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  Timestamp,
} from 'firebase/firestore';
import { FirestoreDB } from '../../utils/firebase/firebase';
import { toast } from 'react-toastify';
import { editStudentGoalEntry } from '../../api';
import useAllStudentGoalsData from '../../hooks/useAllStudentGoalsData';
import dayjs from 'dayjs';
import InfoIcon from '@mui/icons-material/Info';
import TimePicker from 'react-time-picker';

const DataEntryModal = ({
  goalId,
  goalTitle,
  selectedGoalIds,
  selectedStudents,
  goalNumber,
  MultipleDataPointsPerSession,
  measurementDuration,
  measurementUnit,
  dataPointPerSession,
  studentDataPoints,
  selectedDate,
  studentId,
  target,
  goalData,
  goalDate,
  open,
  onClose,
  goalType,
  subGoalIndex,
  promptingType,
  criteria,
  trialsMax,
}) => {
  const { user, typeOfUser } = useContext(AuthContext);
  const [goalNo, setGoalNo] = useState(null);
  const [graphData, setGraphData] = useState([]);
  const [existingEntry, setExistingEntry] = useState(null);
  const [dataPoints, setDataPoints] = useState([
    createInitialDataPoint(subGoalIndex, promptingType, criteria),
  ]);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [existingPromptingType, setExistingPromptingType] =
    useState(promptingType);

  const {
    data: studentGoalsData = [],
    isLoading: isStudentGoalsLoading,
    error: studentGoalsError,
    refetch: refetchStudentGoals,
  } = useAllStudentGoalsData(user?.uid, typeOfUser);

  function createInitialDataPoint(
    subGoalIndex,
    promptingType,
    criteriaItems = []
  ) {
    const initialCriteria = criteriaItems?.map((item) => ({
      MeasurementType: item.MeasurementType,
      Target: item.Target,
      TargetDuration: item.TargetDuration,
      Variable: item.Variable,
      value: 0,
    }));

    return {
      currentGoal: subGoalIndex ? subGoalIndex : 'main',
      value: 0,
      trialsNumber: [0],
      trailsValue: {
        TrailsMin: '',
        TrailsMax: '',
      },
      selectedOptions: [],
      abcData: {
        antecedent: '',
        behavior: '',
        consequence: '',
        setting: '',
        notes: '',
      },
      phaseChange: '',
      eventLine: '',
      timeOfCollection: '',
      notes: '',
      prompting: getPromptingStructure(promptingType),
      criteria: initialCriteria,
    };
  }

  function getPromptingStructure(type) {
    if (type === 'P+ & P-') {
      return {
        pMinus: '',
        pPlus: '',
      };
    } else if (type === 'IVP, DVP, PVP, GP, PP') {
      return {
        I: 0,
        VP: 0,
        IVP: 0,
        DVP: 0,
        PVP: 0,
        GP: 0,
        PP: 0,
      };
    }
    return {};
  }

  const handleOpenDeleteDialog = () => setIsDeleteDialogOpen(true);
  const handleCloseDeleteDialog = () => setIsDeleteDialogOpen(false);

  const handleConfirmDelete = () => {
    handleDelete(goalId || goalCollectionID);
    handleCloseDeleteDialog();
  };

  useEffect(() => {
    const entry = studentDataPoints?.find((dp) => dp.date === selectedDate);
    setExistingEntry(entry);
  }, [selectedDate, studentDataPoints]);

  const handleTrialChange = (dataIndex, trialIndex, value) => {
    setDataPoints((prev) =>
      prev.map((point, i) =>
        i === dataIndex
          ? {
              ...point,
              trialsNumber: point.trialsNumber.map((trial, tIndex) =>
                tIndex === trialIndex ? value : trial
              ),
            }
          : point
      )
    );
  };

  const handleAddTrial = (index) => {
    setDataPoints((prev) =>
      prev.map((point, i) => {
        if (i === index) {
          if (
            trialsMax !== 'N/A' &&
            point.trialsNumber.length >= Number(trialsMax)
          ) {
            return point;
          }
          return {
            ...point,
            trialsNumber: [...point.trialsNumber.map(Number), 0],
          };
        }
        return point;
      })
    );
  };

  const handleCriteriaChange = (dataIndex, criteriaIndex, value) => {
    setDataPoints((prev) =>
      prev.map((point, i) =>
        i === dataIndex
          ? {
              ...point,
              criteria: point.criteria.map((criterion, cIndex) =>
                cIndex === criteriaIndex
                  ? { ...criterion, value: value }
                  : criterion
              ),
            }
          : point
      )
    );
  };

  const goalCollectionID = studentDataPoints?.find(
    (dp) => dp.date === selectedDate
  )?.goalCollectionId;

  const showToastMessage = (status) => {
    if (status === 'success') {
      toast.success('Data entry uploaded successfully!', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else if (status === 'delete') {
      toast.success('Data entry deleted successfully!', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      toast.error('Data entry upload failed!', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  const handleCheckboxChange = (index, event) => {
    const { value, checked } = event.target;
    setDataPoints((prev) =>
      prev.map((dp, i) =>
        i === index
          ? {
              ...dp,
              selectedOptions: checked
                ? [...dp.selectedOptions, value]
                : dp.selectedOptions.filter((option) => option !== value),
            }
          : dp
      )
    );
  };

  const handleTimeChange = (index, newTime) => {
    setDataPoints((prev) =>
      prev.map((dp, i) =>
        i === index
          ? {
              ...dp,
              timeOfCollection: newTime,
            }
          : dp
      )
    );
  };

  const validateValue = (index, newValue) => {
    const parsedValue = Number.parseFloat(newValue);
    if (isNaN(parsedValue)) return;

    setDataPoints((prev) =>
      prev.map((dp, i) => (i === index ? { ...dp, value: newValue } : dp))
    );
  };

  const handleFieldChange = (index, field, newValue) => {
    if (field === 'value') {
      validateValue(index, newValue);
    }

    setDataPoints((prev) =>
      prev.map((dp, i) => (i === index ? { ...dp, [field]: newValue } : dp))
    );
  };

  const handleAbcChange = (index, e) => {
    const { name, value } = e.target;
    setDataPoints((prev) =>
      prev.map((dp, i) =>
        i === index ? { ...dp, abcData: { ...dp.abcData, [name]: value } } : dp
      )
    );
  };

  const handlePromptingChange = (index, field, value) => {
    setDataPoints((prev) =>
      prev.map((dp, i) =>
        i === index
          ? {
              ...dp,
              prompting: { ...dp.prompting, [field]: value },
            }
          : dp
      )
    );
  };

  const handleAddDataPoint = () => {
    setDataPoints([
      ...dataPoints,
      createInitialDataPoint(subGoalIndex, promptingType, criteria),
    ]);
  };

  const handleSubmit = async (goalId) => {
    const studentID = selectedStudents?.id || studentId;
    const collectionName =
      ACM[typeOfUser]?.isDoctor || ACM[typeOfUser]?.isPatient
        ? 'patientsList'
        : 'studentsList';
    const existingEntry = studentDataPoints?.find(
      (dp) => dp.date === selectedDate
    );
    const goal = selectedGoalIds?.find((g) => g.goalId === goalId);
    const firestoreTimestamp = Timestamp.fromDate(dayjs(goalDate).toDate());

    try {
      if (existingEntry) {
        const formData = {
          goal: goalTitle,
          goalNumber: goalNo,

          data_points: dataPoints.map((dataPoint) => ({
            id: doc(collection(FirestoreDB, 'temp')).id,

            goal_value: dataPoint.value,
            trials_value: dataPoint.trailsValue,
            trialsNumber: dataPoint.trialsNumber.map(Number),
            abc_data: dataPoint.selectedOptions.includes('Add ABC Data')
              ? dataPoint.abcData
              : '',
            phase_change: dataPoint.selectedOptions.includes(
              'Add Phase Change Line'
            )
              ? dataPoint.phaseChange
              : '',
            event: dataPoint.selectedOptions.includes('Add Event Line')
              ? dataPoint.eventLine
              : '',
            notes: dataPoint.notes,
            timeOfCollection: dataPoint.selectedOptions.includes(
              'Add Time of Collection'
            )
              ? dataPoint.timeOfCollection
              : '',
            prompting: dataPoint.prompting,
            criteria: dataPoint.criteria,
            currentGoal: dataPoint.currentGoal,
          })),
        };

        await editStudentGoalEntry(
          user?.uid,
          typeOfUser,
          studentID,
          goalId,
          existingEntry.dataPoints.id,
          btoa(JSON.stringify(formData))
        );
        showToastMessage('success');
      } else {
        if (!goal) return;
        const formData = {
          goal: goal.goalTitle,
          goalNumber: goal.goalNumber,
          data_points: dataPoints.map((dataPoint) => ({
            id: doc(collection(FirestoreDB, 'temp')).id,
            goal_value: dataPoint.value,
            trialsNumber: dataPoint.trialsNumber.map(Number),
            trials_range: dataPoint.trailsValue,
            abc_data: dataPoint.selectedOptions.includes('Add ABC Data')
              ? dataPoint.abcData
              : '',
            phase_change: dataPoint.selectedOptions.includes(
              'Add Phase Change Line'
            )
              ? dataPoint.phaseChange
              : '',
            event: dataPoint.selectedOptions.includes('Add Event Line')
              ? dataPoint.eventLine
              : '',
            notes: dataPoint.notes,
            timeOfCollection: dataPoint.selectedOptions.includes(
              'Add Time of Collection'
            )
              ? dataPoint.timeOfCollection
              : '',
            prompting: dataPoint.prompting,
            criteria: dataPoint.criteria,
            currentGoal: dataPoint.currentGoal,
          })),
        };

        await addDoc(
          collection(
            FirestoreDB,
            collectionName,
            studentID,
            'dataCollection',
            goalId,
            'goalData'
          ),
          {
            info: btoa(JSON.stringify(formData)),
            createdAt: firestoreTimestamp,
            isEncrypted: false,
          }
        );
        showToastMessage('success');
      }
    } catch (error) {
      console.error('Error saving data:', error);
      showToastMessage('error');
    }
  };

  const handleDelete = async (goalId) => {
    const studentID = selectedStudents?.id || studentId;
    const collectionName =
      ACM[typeOfUser]?.isDoctor || ACM[typeOfUser]?.isPatient
        ? 'patientsList'
        : 'studentsList';
    const existingEntry = studentDataPoints?.find(
      (dp) => dp.date === selectedDate
    );

    try {
      if (existingEntry) {
        const dataCollectionId = existingEntry.dataPoints.id;
        const goalDocRef = doc(
          FirestoreDB,
          collectionName,
          studentID,
          'dataCollection',
          goalId,
          'goalData',
          dataCollectionId
        );

        await deleteDoc(goalDocRef);
        showToastMessage('delete');
        onClose();
      }
    } catch (error) {
      console.error('Error deleting data:', error);
      showToastMessage('error');
    }
  };

  const handleClose = () => {
    setDataPoints([
      createInitialDataPoint(subGoalIndex, promptingType, criteria),
    ]);
    onClose();
  };

  useEffect(() => {
    if (selectedDate && studentDataPoints?.length) {
      const matchedData = studentDataPoints.find(
        (dp) => dp.date === selectedDate
      );

      if (matchedData) {
        const goalNumber = matchedData?.dataPoints?.info?.goalNumber || null;
        setGoalNo(goalNumber);

        if (matchedData.dataPoints?.info?.data_points?.length) {
          // Determine the prompting type from existing data if available
          const existingDataPoint = matchedData.dataPoints.info.data_points[0];
          let detectedPromptingType = '';

          if (existingDataPoint.prompting) {
            if (
              existingDataPoint.prompting.pMinus !== undefined ||
              existingDataPoint.prompting.pPlus !== undefined
            ) {
              detectedPromptingType = 'P+ & P-';
            } else if (
              existingDataPoint.prompting.I !== undefined ||
              existingDataPoint.prompting.DVP !== undefined ||
              existingDataPoint.prompting.GP !== undefined
            ) {
              detectedPromptingType = 'IVP, DVP, PVP, GP, PP';
            }
          }

          if (detectedPromptingType) {
            setExistingPromptingType(detectedPromptingType);
          }

          setDataPoints(
            matchedData.dataPoints.info.data_points.map((dataPoint) => {
              let criteriaData = [];

              if (dataPoint.criteria && dataPoint.criteria.length > 0) {
                criteriaData = dataPoint.criteria.map((criterion) => ({
                  ...criterion,
                  MeasurementType:
                    criterion.MeasurementType || criterion.measurementType,
                  Target: criterion.Target || criterion.target,
                  TargetDuration:
                    criterion.TargetDuration || criterion.measurement,
                  Variable: criterion.Variable || criterion.variable,
                }));
              } else if (criteria && criteria.length > 0) {
                // Use current criteria structure with default values
                criteriaData = criteria.map((item) => ({
                  MeasurementType: item.MeasurementType,
                  Target: item.Target,
                  TargetDuration: item.TargetDuration,
                  Variable: item.Variable,
                  value: 0,
                }));
              }

              // Handle prompting - preserve existing prompting data
              let promptingData = {};

              if (dataPoint.prompting) {
                // Use existing prompting data
                promptingData = dataPoint.prompting;
              } else {
                // Use default structure based on detected or current prompting type
                promptingData = getPromptingStructure(
                  detectedPromptingType || promptingType
                );
              }

              return {
                value: dataPoint.goal_value || 'N/A',
                trialsNumber: dataPoint.trialsNumber || [0],
                trailsValue: dataPoint.trials_value || {
                  TrailsMin: '',
                  TrailsMax: '',
                },
                selectedOptions: [
                  ...(dataPoint.abc_data ? ['Add ABC Data'] : []),
                  ...(dataPoint.phase_change ? ['Add Phase Change Line'] : []),
                  ...(dataPoint.event ? ['Add Event Line'] : []),
                  ...(dataPoint.timeOfCollection
                    ? ['Add Time of Collection']
                    : []),
                ],
                abcData:
                  typeof dataPoint.abc_data === 'object'
                    ? dataPoint.abc_data
                    : {
                        antecedent: '',
                        behavior: '',
                        consequence: '',
                        setting: '',
                        notes: '',
                      },
                phaseChange: dataPoint.phase_change || '',
                eventLine: dataPoint.event || '',
                timeOfCollection: dataPoint.timeOfCollection || '',
                notes: dataPoint.notes || '',
                prompting: promptingData,
                criteria: criteriaData,
              };
            })
          );
        }
      }
    }
  }, [selectedDate, studentDataPoints, promptingType, subGoalIndex, criteria]);

  const processStudentGoalsData = () => {
    if (!studentGoalsData || studentGoalsData.length === 0) return;

    const goalWiseData = {};

    const getLast7Days = () => {
      const days = [];
      for (let i = 6; i >= 0; i--) {
        const date = new Date();
        date.setDate(date.getDate() - i);
        days.push(date.toLocaleDateString('en-CA'));
      }
      return days;
    };

    if (ACM[typeOfUser]?.isDoctor || ACM[typeOfUser]?.isTeacher) {
      studentGoalsData.forEach((student) => {
        const studentId = student.id;
        if (Array.isArray(student?.dataCollection)) {
          student.dataCollection.forEach((collection) => {
            const goalTitle = collection?.info?.GoalDetails?.GoalTitle;
            const goalType =
              collection?.info?.DataCollection?.measurementType?.Target;
            const goalNumber = collection?.info?.GoalDetails?.GoalNumber;
            const targetDuration = Number.parseFloat(
              collection?.info?.DataCollection?.measurementType
                ?.TargetDuration || 0
            );

            if (!goalTitle || targetDuration <= 0) return;

            const goalKey = `${studentId}-${goalTitle}`;

            if (!goalWiseData[goalKey]) {
              goalWiseData[goalKey] = {
                studentId,
                goalTitle,
                targetDuration,
                goalNumber,
                goalType,
                dates: {},
              };
            }

            if (Array.isArray(collection?.goalData)) {
              collection.goalData.forEach((dataEntry) => {
                const createdAtDate = new Date(
                  (dataEntry.createdAt?._seconds ||
                    dataEntry.createdAt?.seconds) * 1000
                ).toLocaleDateString('en-CA');

                const dataPoints = dataEntry?.info?.data_points || [];

                if (!goalWiseData[goalKey].dates[createdAtDate]) {
                  goalWiseData[goalKey].dates[createdAtDate] = [];
                }

                dataPoints.forEach((point) => {
                  const goalValue = Number.parseFloat(point.goal_value || 0);
                  goalWiseData[goalKey].dates[createdAtDate].push(goalValue);
                });
              });
            }
          });
        }
      });
    } else {
      studentGoalsData.forEach((student) => {
        const studentId = student?.studentData?.StudentID;
        const goalTitle = student?.studentData?.GoalDetails?.GoalTitle;
        const goalType =
          student?.studentData?.DataCollection?.measurementType?.Target;
        const goalNumber = student?.studentData?.GoalDetails?.GoalNumber;
        const targetDuration = Number.parseFloat(
          student?.studentData?.DataCollection?.measurementType
            ?.TargetDuration || 0
        );

        if (!goalTitle || targetDuration <= 0) return;

        const goalKey = `${studentId}-${goalTitle}`;

        if (!goalWiseData[goalKey]) {
          goalWiseData[goalKey] = {
            studentId,
            goalTitle,
            targetDuration,
            goalType,
            goalNumber,
            dates: {},
          };
        }

        if (Array.isArray(student?.studentData?.goalData)) {
          student?.studentData?.goalData.forEach((dataEntry) => {
            const createdAtDate = new Date(
              (dataEntry.createdAt?._seconds || dataEntry.createdAt?.seconds) *
                1000
            ).toLocaleDateString('en-CA');

            const dataPoints = dataEntry?.info?.data_points || [];

            if (!goalWiseData[goalKey].dates[createdAtDate]) {
              goalWiseData[goalKey].dates[createdAtDate] = [];
            }

            dataPoints.forEach((point) => {
              const goalValue = Number.parseFloat(point.goal_value || 0);
              goalWiseData[goalKey].dates[createdAtDate].push(goalValue);
            });
          });
        }
      });
    }

    const allDatesSet = new Set();
    Object.values(goalWiseData).forEach(({ dates }) => {
      Object.keys(dates).forEach((date) => allDatesSet.add(date));
    });

    const allDates = Array.from(allDatesSet).sort();

    const last7Days = getLast7Days();
    const combinedDates = Array.from(
      new Set([...allDates, ...last7Days])
    ).sort();

    const formattedData = Object.entries(goalWiseData).map(
      ([
        goalKey,
        { studentId, goalTitle, goalNumber, targetDuration, goalType, dates },
      ]) => {
        const graphData = combinedDates.map((date) => {
          const goalValues = dates[date] || [];
          let percentage = 0;

          if (goalValues.length > 1) {
            const totalGoalValue = goalValues.reduce(
              (sum, val) => sum + val,
              0
            );
            const avgGoalValue = totalGoalValue / goalValues.length;
            percentage =
              goalType === 'Atleast'
                ? (avgGoalValue / targetDuration) * 100
                : (1 - (avgGoalValue - targetDuration) / avgGoalValue) * 100;
          } else if (goalValues.length === 1) {
            percentage =
              goalType === 'Atleast'
                ? (goalValues[0] / targetDuration) * 100
                : (1 - (goalValues[0] - targetDuration) / goalValues[0]) * 100;
          }

          return { date, percentage: Number(percentage.toFixed(2)) };
        });

        return { studentId, goalTitle, graphData, goalNumber };
      }
    );

    setGraphData(formattedData);
  };

  useEffect(() => {
    processStudentGoalsData();
  }, [studentGoalsData]);

  const filteredGoals = useMemo(() => {
    return studentGoalsData.filter(
      (goal) => (goal.id || goal?.studentData?.StudentID) === studentId
    );
  }, [studentGoalsData, studentId]);

  const renderGoalDetails = () => {
    if (filteredGoals?.length === 0) return <Typography>Goal:</Typography>;

    return filteredGoals.map((goal, goalIndex) => {
      if (ACM[typeOfUser]?.isDoctor || ACM[typeOfUser]?.isTeacher) {
        return goal?.dataCollection?.map((item, itemIndex) => {
          const goalGraphData = graphData?.find(
            ({ goalTitle, studentId, goalNumber }) =>
              goalTitle === item?.info?.GoalDetails?.GoalTitle &&
              studentId === goal.id &&
              goalNumber === item?.info?.GoalDetails?.GoalNumber
          );

          if (goalGraphData?.goalNumber === (goalNo || goalNumber)) {
            return (
              <Typography key={itemIndex}>
                Goal:{' '}
                {[
                  item?.info?.DataCollection?.measurementType?.Target,
                  item?.info?.DataCollection?.measurementType?.TargetDuration,
                  measurementUnit,
                  'in',
                  item?.info?.DataCollection?.MultipleTimesToMeetGoal
                    ?.NumberOfSessions === 'NA'
                    ? '-'
                    : item?.info?.DataCollection?.MultipleTimesToMeetGoal
                        ?.NumberOfSessions || 0,
                  'consecutive sessions',
                  'by',
                  item?.info?.DataCollection?.ByDate,
                ]
                  .filter(Boolean)
                  .join(' ')}
              </Typography>
            );
          }
          return null;
        });
      } else {
        const goalGraphData = graphData?.find(
          ({ goalTitle, studentId, goalNumber }) =>
            goalTitle === goal?.studentData?.GoalDetails?.GoalTitle &&
            studentId === goal?.studentData?.StudentID &&
            goalNumber === goal?.studentData?.GoalDetails?.GoalNumber
        );

        if (goalGraphData?.goalNumber === (goalNo || goalNumber)) {
          return (
            <Typography key={goalIndex}>
              Goal:{' '}
              {[
                goal?.studentData?.DataCollection?.measurementType?.Target,
                goal?.studentData?.DataCollection?.measurementType
                  ?.TargetDuration,
                measurementUnit,
                'in',
                goal?.studentData?.DataCollection?.MultipleTimesToMeetGoal
                  ?.NumberOfSessions === 'NA'
                  ? '-'
                  : goal?.studentData?.DataCollection?.MultipleTimesToMeetGoal
                      ?.NumberOfSessions || 0,
                'consecutive sessions',
                'by',
                goal?.studentData?.DataCollection?.ByDate,
              ]
                .filter(Boolean)
                .join(' ')}
            </Typography>
          );
        }
      }
      return null;
    });
  };

  return (
    <Card
      sx={{
        backgroundColor: '#e9eaff',
        borderRadius: '8px',
        padding: '0 10px',
      }}
    >
      <Box my={2} className='data-entry-model'>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography gutterBottom variant='body1'>
            Goal # {goalNumber || goalNo}{' '}
            {subGoalIndex ? `, Subgoal #${subGoalIndex}` : ''}
          </Typography>
          <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
            <Typography gutterBottom variant='body1'>
              {goalTitle}
            </Typography>
          </Box>
        </Box>

        {renderGoalDetails()}

        {dataPoints?.map((dataPoint, index) => (
          <Box key={index} sx={{ borderBottom: '1px solid gray', pb: 2 }}>
            {dataPoint?.value !== 'N/A' && goalType !== 'Trials' && (
              <Box sx={{ display: 'flex', gap: 2, marginTop: '1rem' }}>
                <Typography variant='body1' sx={{ paddingTop: '15px' }}>
                  Value:
                </Typography>
                <TextField
                  type='text'
                  inputProps={{
                    'aria-label': 'Without label',
                    inputMode: 'numeric',
                  }}
                  margin='dense'
                  size='small'
                  value={dataPoint.value}
                  sx={{ width: '15%' }}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    if (newValue >= 0) {
                      handleFieldChange(index, 'value', newValue);
                    }
                  }}
                />
                <Typography variant='body1' sx={{ paddingTop: '15px' }}>
                  {measurementUnit || ''}
                </Typography>
              </Box>
            )}

            {(dataPoint?.value === 'N/A' || goalType === 'Trials') && (
              <Box className='flexBox flexColumn'>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                  }}
                >
                  {dataPoint?.trialsNumber?.map((trial, trialIndex) => (
                    <Box
                      key={trialIndex}
                      sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: 1,
                        alignItems: 'center',
                      }}
                    >
                      <Typography>{`Trial ${
                        trialIndex + 1
                      } value: `}</Typography>
                      <TextField
                        type='text'
                        inputProps={{
                          'aria-label': 'Without label',
                          inputMode: 'numeric',
                        }}
                        sx={{ width: '20%' }}
                        margin='dense'
                        size='small'
                        value={trial}
                        onChange={(e) =>
                          handleTrialChange(index, trialIndex, e.target.value)
                        }
                      />
                      <Typography variant='body1'>{''}</Typography>
                    </Box>
                  ))}

                  <Button
                    variant='outlined'
                    color='inherit'
                    sx={{
                      color: 'black',
                      textTransform: 'capitalize',
                      alignSelf: 'start',
                    }}
                    onClick={() => handleAddTrial(index)}
                    disabled={
                      trialsMax !== 'N/A' &&
                      dataPoint.trialsNumber.length >= Number(trialsMax)
                    }
                  >
                    + Add Trials{' '}
                    {trialsMax !== 'N/A' &&
                      `(${dataPoint.trialsNumber.length}/${trialsMax})`}
                  </Button>
                </Box>
              </Box>
            )}

            {criteria && criteria.length > 0 && (
              <Box sx={{ mt: 2, mb: 2 }}>
                {dataPoint.criteria.map((criteriaItem, criteriaIndex) => (
                  <Box
                    key={criteriaIndex}
                    sx={{
                      display: 'flex',
                      gap: 2,
                      alignItems: 'center',
                      mb: 1,
                    }}
                  >
                    <Typography variant='body1' sx={{ paddingTop: '15px' }}>
                      Value:
                    </Typography>
                    <TextField
                      type='text'
                      inputProps={{
                        'aria-label': 'Without label',
                        inputMode: 'numeric',
                      }}
                      margin='dense'
                      size='small'
                      value={criteriaItem.value}
                      sx={{ width: '15%' }}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        if (newValue >= 0) {
                          handleCriteriaChange(index, criteriaIndex, newValue);
                        }
                      }}
                    />

                    <Typography variant='body1'>
                      {(criteriaItem.MeasurementType ||
                        criteriaItem.measurementType) === 'Frequency' &&
                        'times of'}
                      {(criteriaItem.MeasurementType ||
                        criteriaItem.measurementType) === 'Duration' &&
                        'mins of'}
                      {(criteriaItem.MeasurementType ||
                        criteriaItem.measurementType) === 'Percentage' &&
                        '% of'}
                    </Typography>
                    <Typography variant='body1'>
                      {criteriaItem.Variable || criteriaItem.variable}
                    </Typography>
                  </Box>
                ))}
              </Box>
            )}

            {(promptingType || existingPromptingType) && (
              <Box sx={{ mt: 1 }}>
                <Typography variant='body1' sx={{ width: '100%', mb: 1 }}>
                  Prompting Level: {existingPromptingType || promptingType}
                </Typography>

                {(promptingType === 'P+ & P-' ||
                  existingPromptingType === 'P+ & P-') && (
                  <>
                    {['P-', 'P+'].map((label) => (
                      <Box
                        key={label}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 2,
                          mb: 1,
                        }}
                      >
                        <Typography
                          variant='body1'
                          sx={{ width: '20%', textAlign: 'right' }}
                        >
                          {label}:
                        </Typography>
                        <TextField
                          fullWidth
                          margin='dense'
                          value={
                            label === 'P-'
                              ? dataPoint.prompting.pMinus || ''
                              : dataPoint.prompting.pPlus || ''
                          }
                          sx={{
                            width: '70px',
                          }}
                          className='input-height'
                          onChange={(e) =>
                            handlePromptingChange(
                              index,
                              label === 'P-' ? 'pMinus' : 'pPlus',
                              e.target.value
                            )
                          }
                        />
                      </Box>
                    ))}
                  </>
                )}

                {(promptingType === 'IVP, DVP, PVP, GP, PP' ||
                  existingPromptingType === 'IVP, DVP, PVP, GP, PP') && (
                  <>
                    {['I', 'VP', 'IVP', 'DVP', 'PVP', 'GP', 'PP'].map(
                      (label) => (
                        <Box
                          key={label}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 2,
                            mb: 1,
                          }}
                        >
                          <Typography
                            variant='body1'
                            sx={{ width: '20%', textAlign: 'right' }}
                          >
                            {label}:
                          </Typography>
                          <TextField
                            fullWidth
                            margin='dense'
                            value={dataPoint.prompting[label] || 0}
                            sx={{
                              width: '70px',
                            }}
                            className='input-height'
                            onChange={(e) =>
                              handlePromptingChange(
                                index,
                                label,
                                e.target.value
                              )
                            }
                          />
                        </Box>
                      )
                    )}
                  </>
                )}
              </Box>
            )}

            <FormControl
              component='fieldset'
              sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={dataPoint.selectedOptions.includes('Add ABC Data')}
                    onChange={(e) => handleCheckboxChange(index, e)}
                    value='Add ABC Data'
                  />
                }
                label='Add ABC Data'
              />
              {dataPoint.selectedOptions.includes('Add ABC Data') && (
                <>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Typography
                      variant='body1'
                      sx={{ width: '20%', textAlign: 'right' }}
                    >
                      Antecedent:
                    </Typography>
                    <TextField
                      fullWidth
                      name='antecedent'
                      margin='dense'
                      value={dataPoint.abcData.antecedent}
                      sx={{ width: '70%', padding: '0' }}
                      className='input-height'
                      onChange={(e) => handleAbcChange(index, e)}
                    />
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Typography
                      variant='body1'
                      sx={{ width: '20%', textAlign: 'right' }}
                    >
                      Behavior:
                    </Typography>
                    <TextField
                      fullWidth
                      name='behavior'
                      margin='dense'
                      value={dataPoint.abcData.behavior}
                      className='input-height'
                      sx={{ width: '70%', padding: '0' }}
                      onChange={(e) => handleAbcChange(index, e)}
                    />
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Typography
                      variant='body1'
                      sx={{ width: '20%', textAlign: 'right' }}
                    >
                      Consequence:
                    </Typography>
                    <TextField
                      fullWidth
                      name='consequence'
                      margin='dense'
                      value={dataPoint.abcData.consequence}
                      className='input-height'
                      sx={{ width: '70%', padding: '0' }}
                      onChange={(e) => handleAbcChange(index, e)}
                    />
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Typography
                      variant='body1'
                      sx={{ width: '20%', textAlign: 'right' }}
                    >
                      Setting:
                    </Typography>
                    <TextField
                      fullWidth
                      name='setting'
                      margin='dense'
                      value={dataPoint.abcData.setting}
                      className='input-height'
                      sx={{ width: '70%', padding: '0' }}
                      onChange={(e) => handleAbcChange(index, e)}
                    />
                  </Box>
                </>
              )}

              <FormControlLabel
                control={
                  <Checkbox
                    checked={dataPoint.selectedOptions.includes(
                      'Add Phase Change Line'
                    )}
                    onChange={(e) => handleCheckboxChange(index, e)}
                    value='Add Phase Change Line'
                  />
                }
                label='Add Phase Change Line'
              />
              {dataPoint.selectedOptions.includes('Add Phase Change Line') && (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <TextField
                    fullWidth
                    margin='dense'
                    value={dataPoint.phaseChange}
                    className='input-height'
                    onChange={(e) =>
                      handleFieldChange(index, 'phaseChange', e.target.value)
                    }
                  />
                </Box>
              )}

              <FormControlLabel
                control={
                  <Checkbox
                    checked={dataPoint.selectedOptions.includes(
                      'Add Event Line'
                    )}
                    onChange={(e) => handleCheckboxChange(index, e)}
                    value='Add Event Line'
                  />
                }
                label='Add Event Line'
              />
              {dataPoint.selectedOptions.includes('Add Event Line') && (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <TextField
                    fullWidth
                    margin='dense'
                    value={dataPoint.eventLine}
                    className='input-height'
                    onChange={(e) =>
                      handleFieldChange(index, 'eventLine', e.target.value)
                    }
                  />
                </Box>
              )}

              <FormControlLabel
                control={
                  <Checkbox
                    checked={dataPoint.selectedOptions.includes(
                      'Add Time of Collection'
                    )}
                    onChange={(e) => handleCheckboxChange(index, e)}
                    value='Add Time of Collection'
                  />
                }
                label='Add Time of Collection'
              />
              {dataPoint.selectedOptions.includes('Add Time of Collection') && (
                <TimePicker
                  onChange={(newTime) => handleTimeChange(index, newTime)}
                  value={dataPoint.timeOfCollection || ''}
                  disableClock={true}
                  format='hh:mm a'
                  style={{
                    maxWidth: '150px',
                    height: '70px',
                    fontSize: '18px',
                    padding: '10px',
                  }}
                />
              )}
            </FormControl>

            <Box sx={{ gap: 2, marginTop: '10px' }}>
              <Typography variant='body1'>Notes:</Typography>
              <TextField
                fullWidth
                label='Notes'
                margin='dense'
                value={dataPoint.notes}
                className='input-height'
                sx={{ width: '70%', padding: '0' }}
                onChange={(e) =>
                  handleFieldChange(index, 'notes', e.target.value)
                }
              />
            </Box>
          </Box>
        ))}

        <Button
          disabled={
            (MultipleDataPointsPerSession || dataPointPerSession) !== 'Yes'
          }
          variant='outlined'
          sx={{ mt: 2 }}
          onClick={handleAddDataPoint}
        >
          + Add Data Point
        </Button>

        {(MultipleDataPointsPerSession || dataPointPerSession) !== 'Yes' && (
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
            <ListItemIcon>
              <InfoIcon
                fontSize='small'
                sx={{ color: 'var(--clr-theme-black)', mr: 0.5 }}
              />
              <Typography variant='body2' color='var(--clr-mindly-black)'>
                Edit the goal to allow add data points.
              </Typography>
            </ListItemIcon>
          </Box>
        )}

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          {existingEntry && (
            <>
              <Button
                variant='outlined'
                color='error'
                sx={{
                  mt: 2,
                  ml: 2,
                  width: '15%',
                  textTransform: 'capitalize',
                }}
                onClick={handleOpenDeleteDialog}
              >
                Delete
              </Button>

              <Dialog
                open={isDeleteDialogOpen}
                onClose={handleCloseDeleteDialog}
              >
                <DialogTitle fontWeight={'bold'} fontFamily={'Lato'}>
                  Confirm Deletion
                </DialogTitle>
                <DialogContent>
                  <DialogContentText fontFamily={'Lato'}>
                    Are you sure you want to delete this goal data-entry
                    permanently?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCloseDeleteDialog} color='primary'>
                    No
                  </Button>
                  <Button onClick={handleConfirmDelete} color='error' autoFocus>
                    Yes
                  </Button>
                </DialogActions>
              </Dialog>
            </>
          )}
          <Button
            variant='contained'
            sx={{
              mt: 2,
              ml: 2,
              width: '15%',
              textTransform: 'capitalize',
              backgroundColor: 'var(--clr-mindly-purple-hover)!important',
            }}
            onClick={() => handleSubmit(goalId || goalCollectionID)}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Card>
  );
};

export default DataEntryModal;
