import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Avatar,
  Button,
  MenuItem,
  Select,
  Grid,
  Divider,
  Box,
  FormControl,
  InputLabel,
  Tooltip,
} from '@mui/material';
import './allGoals.css';
import MainLayout from '../../hoc/MainLayout';
import useStudentsForOneTeacher from '../../hooks/useStudentsForOneTeacher';
import AuthContext from '../../contexts/AuthContext';
import {
  Line,
  LineChart,
  Tooltip as RechartsTooltip,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import useAllStudentGoalsData from '../../hooks/useAllStudentGoalsData';
import { ExpandMore } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import NewGoalDialog from '../../components/NewGoalDialog';
import { ACM } from '../../constants/UserRoles';

const AllGoals = () => {
  const [selectedStudent, setSelectedStudent] = useState('All');
  const [dataEntryDialogOpen, setDataEntryDialogOpen] = useState(false);
  const { user, typeOfUser } = useContext(AuthContext);
  const [graphData, setGraphData] = useState([]);
  const [goalProgress, setGoalProgress] = useState();
  const navigate = useNavigate();

  const {
    data: fetchStudentsForOneTeacher = [],
    isLoading: isTeachersLoading,
    error: teachersError,
    refetch: refetchTeachers,
  } = useStudentsForOneTeacher(user?.uid, typeOfUser);

  const enrolledStudents = useMemo(
    () => fetchStudentsForOneTeacher || [],
    [fetchStudentsForOneTeacher]
  );

  const {
    data: studentGoalsData = [],
    isLoading: isStudentGoalsLoading,
    error: studentGoalsError,
    refetch: refetchStudentGoals,
  } = useAllStudentGoalsData(user?.uid, typeOfUser);

  const handleSelectStudent = (value) => {
    setSelectedStudent(value);
  };

  const truncateName = (name, limit = 10) => {
    const capitalized = capitalizeWords(name);
    if (capitalized?.length > limit) {
      return `${capitalized.slice(0, limit)}...`;
    }
    return capitalized;
  };

  const capitalizeWords = (name) => {
    return name
      ?.split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  // const filteredGoals = useMemo(() => {
  //   return selectedStudent === 'All'
  //     ? studentGoalsData
  //     : studentGoalsData.filter(
  //         (goal) =>
  //           (goal.id || goal?.studentData?.StudentID) === selectedStudent
  //       );
  // }, [selectedStudent, studentGoalsData]);

  const filteredGoals = useMemo(() => {
    return selectedStudent === 'All'
      ? studentGoalsData
          ?.filter((goal) => goal?.isArchived !== true) // Filter at goal level
          .map((goal) => {
            // Ensure the dataCollection within the goal is also filtered
            if (Array.isArray(goal?.dataCollection)) {
              goal.dataCollection = goal?.dataCollection?.filter(
                (g) => g?.isArchived !== true
              ); // Filter at dataCollection level
            }
            return goal;
          })
      : studentGoalsData
          ?.filter(
            (goal) => (goal?.id || goal?.info?.StudentID) === selectedStudent
          )
          .map((goal) => {
            // Ensure the dataCollection within the goal is also filtered
            if (goal?.dataCollection) {
              goal.dataCollection = goal?.dataCollection?.filter(
                (g) => g?.isArchived !== true
              ); // Filter at dataCollection level
            }
            return goal;
          });
  }, [selectedStudent, studentGoalsData]);

  const processStudentGoalsData = () => {
    if (!studentGoalsData || studentGoalsData.length === 0) return;
    const goalWiseData = {};

    const getLast7Days = () => {
      const days = [];
      for (let i = 6; i >= 0; i--) {
        const date = new Date();
        date.setDate(date.getDate() - i);
        days.push(date.toLocaleDateString('en-CA'));
      }
      return days;
    };
    {
      ACM[typeOfUser]?.isDoctor || ACM[typeOfUser]?.isTeacher
        ? studentGoalsData.forEach((student) => {
            const studentId = student.id;
            if (Array.isArray(student?.dataCollection)) {
              student.dataCollection.forEach((collection) => {
                const goalTitle = collection?.info?.GoalDetails?.GoalTitle;
                const targetDuration = parseFloat(
                  collection?.info?.DataCollection?.measurementType
                    ?.TargetDuration || 0
                );
                const typeOfGoal =
                  collection?.info?.DataCollection?.measurementType?.Target ||
                  '';
                const typeOfOperation =
                  collection?.info?.DataCollection?.MultipleDataPointsPerSession
                    ?.DataPointPerSession || '';
                if (!goalTitle || targetDuration <= 0) return;
                const goalKey = `${studentId}-${goalTitle}`;
                if (!goalWiseData[goalKey]) {
                  goalWiseData[goalKey] = {
                    studentId,
                    goalTitle,
                    targetDuration,
                    typeOfGoal,
                    typeOfOperation,
                    dates: {},
                  };
                }
                if (Array.isArray(collection?.goalData)) {
                  collection.goalData.forEach((dataEntry) => {
                    const createdAtDate = new Date(
                      (dataEntry.createdAt?._seconds ||
                        dataEntry.createdAt?.seconds) * 1000
                    ).toLocaleDateString('en-CA');

                    const dataPoints = dataEntry?.info?.data_points || [];

                    if (!goalWiseData[goalKey].dates[createdAtDate]) {
                      goalWiseData[goalKey].dates[createdAtDate] = [];
                    }
                    dataPoints.forEach((point) => {
                      const goalValue = parseFloat(point.goal_value || 0);
                      goalWiseData[goalKey].dates[createdAtDate].push(
                        goalValue
                      );
                    });
                  });
                }
                Object.keys(goalWiseData[goalKey].dates).forEach((date) => {
                  const values = goalWiseData[goalKey].dates[date];
                  if (values.length > 1) {
                    switch (typeOfOperation) {
                      case 'Average':
                        goalWiseData[goalKey].dates[date] = [
                          values.reduce((a, b) => a + b, 0) / values.length,
                        ];
                        break;
                      case 'Add':
                        goalWiseData[goalKey].dates[date] = [
                          values.reduce((a, b) => a + b, 0),
                        ];
                        break;
                      case 'Take Highest':
                        goalWiseData[goalKey].dates[date] = [
                          Math.max(...values),
                        ];
                        break;
                      case 'Take Lowest':
                        goalWiseData[goalKey].dates[date] = [
                          Math.min(...values),
                        ];
                        break;
                    }
                  }
                });
              });
            }
          })
        : studentGoalsData.forEach((student) => {
            const studentId = student?.studentData?.StudentID;
            const goalDetails = student?.studentData?.GoalDetails;
            const measurementType =
              student?.studentData?.DataCollection?.measurementType;
            const typeOfOperation =
              student?.studentData?.DataCollection?.MultipleDataPointsPerSession
                ?.DataPointPerSession || '';

            if (!studentId || !goalDetails || !measurementType) return;

            const goalTitle = goalDetails.GoalTitle;
            const targetDuration = parseFloat(
              measurementType?.TargetDuration || 0
            );
            const typeOfGoal = measurementType?.Target || '';

            if (!goalTitle || targetDuration <= 0) return;

            const goalKey = `${studentId}-${goalTitle}`;
            if (!goalWiseData[goalKey]) {
              goalWiseData[goalKey] = {
                studentId,
                goalTitle,
                targetDuration,
                typeOfGoal,
                typeOfOperation,
                dates: {},
              };
            }

            if (Array.isArray(student?.studentData?.goalData)) {
              student.studentData.goalData.forEach((dataEntry) => {
                const createdAtDate = new Date(
                  (dataEntry.createdAt?._seconds ||
                    dataEntry.createdAt?.seconds) * 1000
                ).toLocaleDateString('en-CA');

                const dataPoints = dataEntry?.info?.data_points || [];

                if (!goalWiseData[goalKey].dates[createdAtDate]) {
                  goalWiseData[goalKey].dates[createdAtDate] = [];
                }

                dataPoints.forEach((point) => {
                  const goalValue = parseFloat(point.goal_value || 0);
                  goalWiseData[goalKey].dates[createdAtDate].push(goalValue);
                });
              });
            }
            Object.keys(goalWiseData[goalKey].dates).forEach((date) => {
              const values = goalWiseData[goalKey].dates[date];
              if (values.length > 1) {
                switch (typeOfOperation) {
                  case 'Average':
                    goalWiseData[goalKey].dates[date] = [
                      values.reduce((a, b) => a + b, 0) / values.length,
                    ];
                    break;
                  case 'Add':
                    goalWiseData[goalKey].dates[date] = [
                      values.reduce((a, b) => a + b, 0),
                    ];
                    break;
                  case 'Take Highest':
                    goalWiseData[goalKey].dates[date] = [Math.max(...values)];
                    break;
                  case 'Take Lowest':
                    goalWiseData[goalKey].dates[date] = [Math.min(...values)];
                    break;
                }
              }
            });
          });
    }

    const allDatesSet = new Set();
    Object.values(goalWiseData).forEach(({ dates }) => {
      Object.keys(dates).forEach((date) => allDatesSet.add(date));
    });
    const allDates = Array.from(allDatesSet).sort();
    const last7Days = getLast7Days();
    const combinedDates = Array.from(
      new Set([...allDates, ...last7Days])
    ).sort();

    const formattedData = Object.entries(goalWiseData).map(
      ([
        goalKey,
        { studentId, goalTitle, targetDuration, typeOfGoal, dates },
      ]) => {
        const graphData = combinedDates.map((date) => {
          const goalValues = dates[date] || [];
          return {
            date,
            goalValues,
          };
        });

        return { studentId, goalTitle, graphData, targetDuration, typeOfGoal };
      }
    );

    setGraphData(formattedData);
  };

  const calculatingProgress = () => {
    if (!studentGoalsData || studentGoalsData.length === 0) return;

    const goalWiseData = {};

    // Get today's date and last 7 days' dates
    const getLast7Days = () => {
      const days = [];
      for (let i = 6; i >= 0; i--) {
        const date = new Date();
        date.setDate(date.getDate() - i);
        days.push(date.toLocaleDateString('en-CA'));
      }
      return days;
    };
    {
      ACM[typeOfUser]?.isDoctor || ACM[typeOfUser]?.isTeacher
        ? studentGoalsData.forEach((student) => {
            const studentId = student.id;
            if (Array.isArray(student?.dataCollection)) {
              student.dataCollection.forEach((collection) => {
                const goalTitle = collection?.info?.GoalDetails?.GoalTitle;
                const goalType =
                  collection?.info?.DataCollection?.measurementType?.Target;
                const targetDuration = parseFloat(
                  collection?.info?.DataCollection?.measurementType
                    ?.TargetDuration || 0
                );

                if (!goalTitle || targetDuration <= 0) return;

                const goalKey = `${studentId}-${goalTitle}`;

                if (!goalWiseData[goalKey]) {
                  goalWiseData[goalKey] = {
                    studentId,
                    goalTitle,
                    targetDuration,
                    goalType,
                    dates: {},
                  };
                }

                if (Array.isArray(collection?.goalData)) {
                  collection.goalData.forEach((dataEntry) => {
                    const createdAtDate = new Date(
                      (dataEntry.createdAt?._seconds ||
                        dataEntry.createdAt?.seconds) * 1000
                    ).toLocaleDateString('en-CA');

                    const dataPoints = dataEntry?.info?.data_points || [];

                    if (!goalWiseData[goalKey].dates[createdAtDate]) {
                      goalWiseData[goalKey].dates[createdAtDate] = [];
                    }

                    dataPoints.forEach((point) => {
                      const goalValue = parseFloat(point.goal_value || 0);
                      goalWiseData[goalKey].dates[createdAtDate].push(
                        goalValue
                      );
                    });
                  });
                }
              });
            }
          })
        : studentGoalsData.forEach((student) => {
            const studentId = student?.studentData?.StudentID;
            // if (Array.isArray(student?.studentData?.dataCollection)) {
            //   student?.studentData?.dataCollection?.map((collection) => {
            const goalTitle = student?.studentData?.GoalDetails?.GoalTitle;
            const goalType =
              student?.studentData?.DataCollection?.measurementType?.Target;
            const targetDuration = parseFloat(
              student?.studentData?.DataCollection?.measurementType
                ?.TargetDuration || 0
            );

            if (!goalTitle || targetDuration <= 0) return;

            const goalKey = `${studentId}-${goalTitle}`;

            if (!goalWiseData[goalKey]) {
              goalWiseData[goalKey] = {
                studentId,
                goalTitle,
                targetDuration,
                goalType,
                dates: {},
              };
            }

            if (Array.isArray(student?.studentData?.goalData)) {
              student?.studentData?.goalData.forEach((dataEntry) => {
                const createdAtDate = new Date(
                  (dataEntry.createdAt?._seconds ||
                    dataEntry.createdAt?.seconds) * 1000
                ).toLocaleDateString('en-CA');

                const dataPoints = dataEntry?.info?.data_points || [];

                if (!goalWiseData[goalKey].dates[createdAtDate]) {
                  goalWiseData[goalKey].dates[createdAtDate] = [];
                }

                dataPoints.forEach((point) => {
                  const goalValue = parseFloat(point.goal_value || 0);
                  goalWiseData[goalKey].dates[createdAtDate].push(goalValue);
                });
              });
            }
          });
    }
    // }) }

    // **Extract all unique dates across all goal data**
    const allDatesSet = new Set();
    Object.values(goalWiseData).forEach(({ dates }) => {
      Object.keys(dates).forEach((date) => allDatesSet.add(date));
    });

    // **Sort dates in ascending order**
    const allDates = Array.from(allDatesSet).sort();

    // **Ensure last 7 days always appear**
    const last7Days = getLast7Days();
    const combinedDates = Array.from(
      new Set([...allDates, ...last7Days])
    ).sort();

    const formattedProgressData = Object.entries(goalWiseData).map(
      ([
        goalKey,
        { studentId, goalTitle, targetDuration, goalType, dates },
      ]) => {
        const graphData = combinedDates.map((date) => {
          const goalValues = dates[date] || [];
          let percentage = 0;

          if (goalValues.length > 1) {
            const totalGoalValue = goalValues.reduce(
              (sum, val) => sum + val,
              0
            );
            const avgGoalValue = totalGoalValue / goalValues.length;
            percentage =
              goalType === 'Atleast'
                ? (avgGoalValue / targetDuration) * 100
                : (1 - (avgGoalValue - targetDuration) / avgGoalValue) * 100;
          } else if (goalValues.length === 1) {
            percentage =
              goalType === 'Atleast'
                ? (goalValues[0] / targetDuration) * 100
                : (1 - (goalValues[0] - targetDuration) / goalValues[0]) * 100;
          }

          return { date, percentage: Number(percentage.toFixed(2)) };
        });

        return { studentId, goalTitle, graphData };
      }
    );

    setGoalProgress(formattedProgressData);
  };

  // const handleGraphClick = (goalTitle, studentId, graphData) => {
  //   // Find the selected student's data
  //   const selectedStudentData = studentGoalsData.find(
  //     (student) => student.id  === studentId
  //   );

  //   if (!selectedStudentData) return;

  //   let selectedGoalDataPoints = [];
  //   let measurementUnit = '';
  //   let measurementType = '';
  //   let targetDuration = '';
  //   const studentDataCollections = selectedStudentData?.studentData?.DataCollection;
  //   selectedStudentData.dataCollection.forEach((collection) => {
  //     if (collection?.info?.GoalDetails?.GoalTitle === goalTitle) {
  //       measurementUnit =
  //         collection?.info?.DataCollection?.measurementType?.measurementUnit ||
  //         '';
  //       measurementType =
  //         collection?.info?.DataCollection?.measurementType?.Type || '';
  //       targetDuration =
  //         collection?.info?.DataCollection?.measurementType?.TargetDuration ||
  //         '';
  //       if (Array.isArray(collection?.goalData)) {
  //         collection.goalData.forEach((dataEntry) => {
  //           const createdAtDate = new Date(
  //             (dataEntry.createdAt?._seconds || dataEntry.createdAt?.seconds) *
  //               1000
  //           ).toLocaleDateString('en-CA');

  //           // Store only the selected goal's data points
  //           selectedGoalDataPoints.push({
  //             date: createdAtDate,
  //             dataPoints: dataEntry,
  //             goalCollectionId: collection.id,
  //           });
  //         });
  //       }
  //     }
  //   })

  //   navigate('/detailed-graph', {
  //     state: {
  //       goalTitle,
  //       studentId,
  //       graphData,
  //       studentDataPoints: selectedGoalDataPoints,
  //       measurementUnit,
  //       measurementType,
  //       targetDuration,
  //     },
  //   });
  // };

  const handleGraphClick = (goalTitle, studentId, graphData) => {
    // Find the selected student's data
    const selectedStudentData = studentGoalsData.find(
      (student) => (student.id || student?.studentData?.StudentID) === studentId
    );

    if (!selectedStudentData) return;

    let selectedGoalDataPoints = [];
    let measurementUnit = '';
    let measurementType = '';
    let targetDuration = '';
    let dataPointPerSession = '';

    // Check if user is a Doctor or Teacher
    if (ACM[typeOfUser]?.isDoctor || ACM[typeOfUser]?.isTeacher) {
      if (Array.isArray(selectedStudentData?.dataCollection)) {
        selectedStudentData.dataCollection.forEach((collection) => {
          if (collection?.info?.GoalDetails?.GoalTitle === goalTitle) {
            measurementUnit =
              collection?.info?.DataCollection?.measurementType
                ?.measurementUnit || '';
            measurementType =
              collection?.info?.DataCollection?.measurementType?.Type || '';
            targetDuration =
              collection?.info?.DataCollection?.measurementType
                ?.TargetDuration || '';
            dataPointPerSession =
              collection?.info?.DataCollection?.MultipleDataPointsPerSession
                ?.MultipleDataPointsPerSession || '';

            if (Array.isArray(collection?.goalData)) {
              collection.goalData.forEach((dataEntry) => {
                const createdAtDate = new Date(
                  (dataEntry.createdAt?._seconds ||
                    dataEntry.createdAt?.seconds) * 1000
                ).toLocaleDateString('en-CA');

                selectedGoalDataPoints.push({
                  date: createdAtDate,
                  dataPoints: dataEntry,
                  goalCollectionId: collection.id,
                });
              });
            }
          }
        });
      }
    } else {
      // if (Array.isArray(studentGoalsData)) {
      studentGoalsData?.map((collection) => {
        if (collection?.studentData?.GoalDetails?.GoalTitle === goalTitle) {
          measurementUnit =
            collection?.studentData?.DataCollection?.measurementType
              ?.measurementUnit || '';
          measurementType =
            collection?.studentData?.DataCollection?.measurementType?.Type ||
            '';
          targetDuration =
            collection?.studentData?.DataCollection?.measurementType
              ?.TargetDuration || '';
          dataPointPerSession =
            collection?.studentData?.DataCollection
              ?.MultipleDataPointsPerSession?.MultipleDataPointsPerSession ||
            '';

          if (Array.isArray(collection?.studentData?.goalData)) {
            collection.studentData.goalData.forEach((dataEntry) => {
              const createdAtDate = new Date(
                (dataEntry.createdAt?._seconds ||
                  dataEntry.createdAt?.seconds) * 1000
              ).toLocaleDateString('en-CA');

              selectedGoalDataPoints.push({
                date: createdAtDate,
                dataPoints: dataEntry,
                goalCollectionId: collection.docId,
              });
            });
          }
        }
      });
      // }
    }

    navigate('/detailed-graph', {
      state: {
        goalTitle,
        studentId,
        graphData,
        studentDataPoints: selectedGoalDataPoints,
        measurementUnit,
        measurementType,
        targetDuration,
        dataPointPerSession,
      },
    });
  };

  const handleDataEntery = () => {
    setDataEntryDialogOpen(true);
  };

  useEffect(() => {
    processStudentGoalsData();
    calculatingProgress();
  }, [studentGoalsData]);

  return (
    <MainLayout>
      <Box padding={2} className='allGoals'>
        <Grid container alignItems='center' justifyContent='space-between'>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant='h5' fontWeight='bold'>
              Goals Overview
            </Typography>
            <Typography variant='subtitle1'>
              View progress towards goals.
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={5}
            md={5}
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              alignItems: { xs: 'center', sm: 'center' },
              gap: 2,
            }}
          >
            {ACM[typeOfUser]?.isDoctor || ACM[typeOfUser]?.isTeacher ? (
              <>
                <Typography variant='subtitle1'>
                  {ACM[typeOfUser]?.isDoctor
                    ? 'Select Patient:'
                    : 'Select Student'}
                </Typography>
                <FormControl sx={{ minWidth: 200 }} className='video-filters'>
                  <InputLabel id='demo-simple-select-label'>
                    {ACM[typeOfUser]?.isDoctor
                      ? 'Select Patient'
                      : 'Select Student'}
                  </InputLabel>
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    value={selectedStudent || 'All'}
                    label='Select Student'
                    onChange={(event) =>
                      handleSelectStudent(event.target.value)
                    }
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          maxHeight: 200, // Adjust this value as needed
                        },
                      },
                    }}
                  >
                    <MenuItem value='All'>
                      {ACM[typeOfUser]?.isDoctor
                        ? 'All Patient'
                        : 'All Student'}
                    </MenuItem>
                    {ACM[typeOfUser]?.isDoctor
                      ? studentGoalsData?.map((student) => (
                          <MenuItem key={student.id} value={student.id}>
                            {student.fullname}
                          </MenuItem>
                        ))
                      : enrolledStudents.map((student) => (
                          <MenuItem key={student.id} value={student.id}>
                            <Tooltip title={student?.name}>
                              <span>{truncateName(student?.name)}</span>
                            </Tooltip>
                          </MenuItem>
                        ))}
                  </Select>
                </FormControl>
              </>
            ) : null}
            <Button
              variant='contained'
              onClick={handleDataEntery}
              sx={{
                backgroundColor: 'var(--clr-theme-purple)',
                '&:hover': { background: 'var(--clr-theme-purple-50)' },
                color: 'var(--clr-theme-white)',
              }}
            >
              Enter Data
            </Button>
            <Button
              variant='contained'
              onClick={() => navigate('/add-goal')}
              sx={{
                backgroundColor: 'var(--clr-theme-purple)',
                '&:hover': { background: 'var(--clr-theme-purple-50)' },
                color: 'var(--clr-theme-white)',
              }}
            >
              Add Goal
            </Button>
          </Grid>
        </Grid>
        <Divider sx={{ my: 2, borderBottomWidth: 2, bgcolor: 'black' }} />
        {filteredGoals?.length > 0 && filteredGoals ? (
          filteredGoals?.map((goal, goalIndex) => (
            <Box
              key={goal.id}
              marginBottom={goalIndex !== filteredGoals.length - 1 ? 2 : 0}
            >
              {ACM[typeOfUser]?.isDoctor || ACM[typeOfUser]?.isTeacher ? (
                Array.isArray(goal?.dataCollection) &&
                goal.dataCollection.map((item, itemIndex) => {
                  const shareWithTeam = item?.info?.ShareWithTeam;
                  if (
                    shareWithTeam?.IsSharingEnabled && 
                    (shareWithTeam?.EditAccess?.includes(user?.subRoleName) || 
                      shareWithTeam?.ViewOnlyAccess?.includes(
                        user?.subRoleName
                      )) 
                  ) {
                    return (
                      <>
                        <CardContent key={item.id || itemIndex}>
                          <Grid container spacing={2} alignItems='flex-start'>
                            <Grid
                              item
                              xs={12}
                              md={6}
                              container
                              spacing={2}
                              alignItems='flex-start'
                            >
                              <Grid
                                item
                                sx={{
                                  width: 100,
                                  display: 'flex',
                                  gap: 1,
                                  flexDirection: 'column',
                                  alignItems: 'center',
                                }}
                              >
                                <Avatar src={goal.avatar} alt={goal.photoURL} />
                                <Typography
                                  variant='subtitle1'
                                  sx={{ textAlign: 'center' }}
                                >
                                  {goal.fullname}
                                </Typography>
                              </Grid>
                              <Grid item xs>
                                <Typography variant='h6' paddingBottom={2}>
                                  # {item?.info?.GoalDetails?.GoalNumber}{' '}
                                  {item?.info?.GoalDetails?.GoalTitle}
                                </Typography>
                                <Typography
                                  variant='body2'
                                  sx={{ fontWeight: 'bold' }}
                                >
                                  {/* If the goal is "Trials" */}
                                  {item?.info?.DataCollection?.measurementType
                                    ?.Type === 'Trials' ? (
                                    <Typography paddingBottom={1}>
                                      {
                                        item?.info?.DataCollection
                                          ?.measurementType?.Target
                                      }{' '}
                                      {
                                        item?.info?.DataCollection
                                          ?.measurementType?.TargetDuration
                                      }{' '}
                                      {
                                        item?.info?.DataCollection
                                          ?.measurementType?.measurementUnit
                                      }{' '}
                                      in{' '}
                                      {
                                        item?.info?.DataCollection
                                          ?.measurementType?.TrailsValue
                                          ?.TrailsMin
                                      }{' '}
                                      out of{' '}
                                      {
                                        item?.info?.DataCollection
                                          ?.measurementType?.TrailsValue
                                          ?.TrailMax
                                      }{' '}
                                      trails in{' '}
                                      {
                                        item?.info?.DataCollection
                                          ?.MultipleTimesToMeetGoal
                                          ?.NumberOfSessions
                                      }{' '}
                                      {item?.info?.DataCollection
                                        ?.MultipleTimesToMeetGoal
                                        ?.ConsecutiveSessions === 'Yes'
                                        ? 'consecutive sessions'
                                        : 'sessions'}{' '}
                                      by {item?.info?.DataCollection?.ByDate}
                                    </Typography>
                                  ) : (
                                    // If the goal is not "Trials", show the default information
                                    <Typography paddingBottom={1}>
                                      {[
                                        item?.info?.DataCollection
                                          ?.measurementType?.Target,
                                        item?.info?.DataCollection
                                          ?.measurementType?.TargetDuration,
                                        item?.info?.DataCollection
                                          ?.measurementType?.measurementUnit,
                                        'in',
                                        item?.info?.DataCollection
                                          ?.MultipleTimesToMeetGoal
                                          ?.NumberOfSessions === 'NA'
                                          ? '-'
                                          : item?.info?.DataCollection
                                              ?.MultipleTimesToMeetGoal
                                              ?.NumberOfSessions || 0,
                                        item?.info?.DataCollection
                                          ?.MultipleTimesToMeetGoal
                                          ?.ConsecutiveSessions === 'Yes'
                                          ? 'consecutive sessions'
                                          : 'sessions',
                                        'by',
                                        item?.info?.DataCollection?.ByDate,
                                      ]
                                        .filter(Boolean)
                                        .join(' ')}
                                    </Typography>
                                  )}

                                  <strong>Baseline: </strong>
                                  <span style={{ fontWeight: 500 }}>
                                    {item?.info?.DataCollection?.Baseline}
                                  </span>
                                </Typography>
                                <Typography variant='body2'>
                                  <strong>Goal Progress:</strong>{' '}
                                  {(() => {
                                    if (!Array.isArray(goalProgress))
                                      return 'N/A';

                                    const goalGraphData = goalProgress.find(
                                      ({ goalTitle, studentId }) =>
                                        goalTitle ===
                                          item?.info?.GoalDetails?.GoalTitle &&
                                        studentId === goal.id
                                    );

                                    if (
                                      goalGraphData &&
                                      Array.isArray(goalGraphData.graphData)
                                    ) {
                                      // Filter out data points where percentage > 0
                                      const validDataPoints =
                                        goalGraphData.graphData.filter(
                                          (data) => data.percentage > 0
                                        );

                                      // Get the last non-zero data point
                                      const lastValidDataPoint =
                                        validDataPoints.length > 0
                                          ? validDataPoints[
                                              validDataPoints.length - 1
                                            ]
                                          : null;

                                      return lastValidDataPoint
                                        ? `${lastValidDataPoint.percentage}`
                                        : 'N/A';
                                    }

                                    return 'N/A';
                                  })()}
                                </Typography>

                                <Typography sx={{ fontSize: '18px' }}>
                                  <strong>Last Data Point:</strong>{' '}
                                  {(() => {
                                    const selectedStudentData =
                                      studentGoalsData.find(
                                        (student) => student.id === goal.id
                                      );

                                    if (!selectedStudentData) return 'N/A';

                                    let lastGoalValue = null;
                                    let measurementUnit = null;

                                    selectedStudentData.dataCollection.forEach(
                                      (collection) => {
                                        if (
                                          collection?.info?.GoalDetails
                                            ?.GoalTitle ===
                                          item?.info?.GoalDetails?.GoalTitle
                                        ) {
                                          if (
                                            Array.isArray(collection?.goalData)
                                          ) {
                                            collection.goalData.forEach(
                                              (dataEntry) => {
                                                const dataPoints =
                                                  dataEntry?.info
                                                    ?.data_points || [];
                                                dataPoints.sort(
                                                  (a, b) =>
                                                    new Date(a.date) -
                                                    new Date(b.date)
                                                );
                                                if (dataPoints.length > 0) {
                                                  lastGoalValue =
                                                    dataPoints[
                                                      dataPoints.length - 1
                                                    ].goal_value;
                                                  measurementUnit =
                                                    item?.info?.DataCollection
                                                      ?.measurementType
                                                      ?.measurementUnit;
                                                }
                                              }
                                            );
                                          }
                                        }
                                      }
                                    );

                                    return lastGoalValue !== null
                                      ? `${lastGoalValue}  ${measurementUnit}`
                                      : 'N/A';
                                  })()}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid item xs={12} md={6} sm={6}>
                              {(() => {
                                const goalGraphData = graphData.filter(
                                  ({ goalTitle, studentId }) =>
                                    goalTitle ===
                                      item?.info?.GoalDetails?.GoalTitle &&
                                    studentId === goal.id
                                );

                                return (
                                  goalGraphData?.length > 0 &&
                                  goalGraphData.map(
                                    ({
                                      goalTitle,
                                      graphData,
                                      targetDuration,
                                      typeOfGoal,
                                      studentId,
                                    }) => {
                                      const today = new Date();
                                      const sevenDaysAgo = new Date();
                                      sevenDaysAgo.setDate(today.getDate() - 7);

                                      // Filter graphData to include only last 7 days
                                      const filteredGraphData =
                                        graphData.filter(({ date }) => {
                                          const dataDate = new Date(date);
                                          return dataDate >= sevenDaysAgo;
                                        });

                                      return (
                                        <>
                                          {item?.info?.Generate?.Graphs.toString() ===
                                          'true' ? (
                                            <Box
                                              key={goalTitle}
                                              style={{
                                                cursor: 'pointer',
                                                minHeight: '220px',
                                                overflow: 'hidden',
                                                display: 'flex',
                                                alignItems: 'flex-end',
                                                gap: 20,
                                              }}
                                            >
                                              <ResponsiveContainer
                                                width='100%'
                                                height={200}
                                              >
                                                <LineChart
                                                  data={filteredGraphData.map(
                                                    ({ date, goalValues }) => ({
                                                      date,
                                                      percentage:
                                                        goalValues.length > 0
                                                          ? goalValues[0]
                                                          : 0,
                                                    })
                                                  )}
                                                >
                                                  <XAxis
                                                    dataKey='date'
                                                    tickFormatter={(date) =>
                                                      date.substring(5)
                                                    }
                                                  />
                                                  <YAxis
                                                    domain={[0, 6]}
                                                    tickCount={7}
                                                    tickFormatter={(value) =>
                                                      `${value} ${
                                                        item?.info
                                                          ?.DataCollection
                                                          ?.measurementType
                                                          ?.measurementUnit ||
                                                        ''
                                                      }`
                                                    }
                                                  />
                                                  <RechartsTooltip
                                                    formatter={(value) => [
                                                      `${value} ${
                                                        item?.info
                                                          ?.DataCollection
                                                          ?.measurementType
                                                          ?.measurementUnit ||
                                                        ''
                                                      }`,
                                                      undefined,
                                                    ]}
                                                    cursor={{
                                                      stroke: '#ccc',
                                                      strokeWidth: 1,
                                                    }}
                                                    labelFormatter={(label) =>
                                                      `Date: ${label}`
                                                    }
                                                  />
                                                  <Line
                                                    type='monotone'
                                                    dataKey='percentage'
                                                    stroke='#8884d8'
                                                    strokeWidth={2}
                                                  />
                                                </LineChart>
                                              </ResponsiveContainer>
                                              <Box
                                                onClick={() =>
                                                  handleGraphClick(
                                                    goalTitle,
                                                    goal.id,
                                                    graphData
                                                  )
                                                }
                                                style={{
                                                  cursor: 'pointer',
                                                  // marginTop: '8px',
                                                  display: 'flex',
                                                  flexDirection: 'row-reverse',
                                                  backgroundColor:
                                                    'var(--clr-mindly-background)',
                                                  borderRadius: '5px',
                                                }}
                                              >
                                                <ExpandMore fontSize='large' />
                                              </Box>
                                            </Box>
                                          ) : (
                                            <Box
                                              style={{
                                                display: 'flex',
                                                alignItems: 'flex-end',
                                                height: '100%',
                                                minHeight: '220px',
                                                justifyContent: 'center',
                                              }}
                                            >
                                              <Typography>
                                                Graph is disabled. Please enable
                                                it to view the graph.
                                              </Typography>
                                            </Box>
                                          )}
                                        </>
                                      );
                                    }
                                  )
                                );
                              })()}
                            </Grid>
                          </Grid>
                        </CardContent>
                        <Divider
                          sx={{ my: 2, borderBottomWidth: 2, bgcolor: 'black' }}
                        />
                      </>
                    );
                  } else {
                    return (
                      <>
                        <CardContent key={item.id || itemIndex}>
                          <Grid container spacing={2} alignItems='flex-start'>
                            <Grid
                              item
                              xs={12}
                              md={6}
                              container
                              spacing={2}
                              alignItems='flex-start'
                            >
                              <Grid
                                item
                                sx={{
                                  width: 100,
                                  display: 'flex',
                                  gap: 1,
                                  flexDirection: 'column',
                                  alignItems: 'center',
                                }}
                              >
                                <Avatar src={goal.avatar} alt={goal.photoURL} />
                                <Typography
                                  variant='subtitle1'
                                  sx={{ textAlign: 'center' }}
                                >
                                  {goal.fullname}
                                </Typography>
                              </Grid>
                              <Grid item xs>
                                <Typography variant='h6' paddingBottom={2}>
                                  # {item?.info?.GoalDetails?.GoalNumber}{' '}
                                  {item?.info?.GoalDetails?.GoalTitle}
                                </Typography>
                                <Typography
                                  variant='body2'
                                  sx={{ fontWeight: 'bold' }}
                                >
                                  {/* If the goal is "Trials" */}
                                  {item?.info?.DataCollection?.measurementType
                                    ?.Type === 'Trials' ? (
                                    <Typography paddingBottom={1}>
                                      {
                                        item?.info?.DataCollection
                                          ?.measurementType?.Target
                                      }{' '}
                                      {
                                        item?.info?.DataCollection
                                          ?.measurementType?.TargetDuration
                                      }{' '}
                                      {
                                        item?.info?.DataCollection
                                          ?.measurementType?.measurementUnit
                                      }{' '}
                                      in{' '}
                                      {
                                        item?.info?.DataCollection
                                          ?.measurementType?.TrailsValue
                                          ?.TrailsMin
                                      }{' '}
                                      out of{' '}
                                      {
                                        item?.info?.DataCollection
                                          ?.measurementType?.TrailsValue
                                          ?.TrailMax
                                      }{' '}
                                      trails in{' '}
                                      {
                                        item?.info?.DataCollection
                                          ?.MultipleTimesToMeetGoal
                                          ?.NumberOfSessions
                                      }{' '}
                                      {item?.info?.DataCollection
                                        ?.MultipleTimesToMeetGoal
                                        ?.ConsecutiveSessions === 'Yes'
                                        ? 'consecutive sessions'
                                        : 'sessions'}{' '}
                                      by {item?.info?.DataCollection?.ByDate}
                                    </Typography>
                                  ) : (
                                    // If the goal is not "Trials", show the default information
                                    <Typography paddingBottom={1}>
                                      {[
                                        item?.info?.DataCollection
                                          ?.measurementType?.Target,
                                        item?.info?.DataCollection
                                          ?.measurementType?.TargetDuration,
                                        item?.info?.DataCollection
                                          ?.measurementType?.measurementUnit,
                                        'in',
                                        item?.info?.DataCollection
                                          ?.MultipleTimesToMeetGoal
                                          ?.NumberOfSessions === 'NA'
                                          ? '-'
                                          : item?.info?.DataCollection
                                              ?.MultipleTimesToMeetGoal
                                              ?.NumberOfSessions || 0,
                                        item?.info?.DataCollection
                                          ?.MultipleTimesToMeetGoal
                                          ?.ConsecutiveSessions === 'Yes'
                                          ? 'consecutive sessions'
                                          : 'sessions',
                                        'by',
                                        item?.info?.DataCollection?.ByDate,
                                      ]
                                        .filter(Boolean)
                                        .join(' ')}
                                    </Typography>
                                  )}

                                  <strong>Baseline: </strong>
                                  <span style={{ fontWeight: 500 }}>
                                    {item?.info?.DataCollection?.Baseline}
                                  </span>
                                </Typography>
                                <Typography variant='body2'>
                                  <strong>Goal Progress:</strong>{' '}
                                  {(() => {
                                    if (!Array.isArray(goalProgress))
                                      return 'N/A';

                                    const goalGraphData = goalProgress.find(
                                      ({ goalTitle, studentId }) =>
                                        goalTitle ===
                                          item?.info?.GoalDetails?.GoalTitle &&
                                        studentId === goal.id
                                    );

                                    if (
                                      goalGraphData &&
                                      Array.isArray(goalGraphData.graphData)
                                    ) {
                                      // Filter out data points where percentage > 0
                                      const validDataPoints =
                                        goalGraphData.graphData.filter(
                                          (data) => data.percentage > 0
                                        );

                                      // Get the last non-zero data point
                                      const lastValidDataPoint =
                                        validDataPoints.length > 0
                                          ? validDataPoints[
                                              validDataPoints.length - 1
                                            ]
                                          : null;

                                      return lastValidDataPoint
                                        ? `${lastValidDataPoint.percentage}`
                                        : 'N/A';
                                    }

                                    return 'N/A';
                                  })()}
                                </Typography>

                                <Typography sx={{ fontSize: '18px' }}>
                                  <strong>Last Data Point:</strong>{' '}
                                  {(() => {
                                    const selectedStudentData =
                                      studentGoalsData.find(
                                        (student) => student.id === goal.id
                                      );

                                    if (!selectedStudentData) return 'N/A';

                                    let lastGoalValue = null;
                                    let measurementUnit = null;

                                    selectedStudentData.dataCollection.forEach(
                                      (collection) => {
                                        if (
                                          collection?.info?.GoalDetails
                                            ?.GoalTitle ===
                                          item?.info?.GoalDetails?.GoalTitle
                                        ) {
                                          if (
                                            Array.isArray(collection?.goalData)
                                          ) {
                                            collection.goalData.forEach(
                                              (dataEntry) => {
                                                const dataPoints =
                                                  dataEntry?.info
                                                    ?.data_points || [];
                                                dataPoints.sort(
                                                  (a, b) =>
                                                    new Date(a.date) -
                                                    new Date(b.date)
                                                );
                                                if (dataPoints.length > 0) {
                                                  lastGoalValue =
                                                    dataPoints[
                                                      dataPoints.length - 1
                                                    ].goal_value;
                                                  measurementUnit =
                                                    item?.info?.DataCollection
                                                      ?.measurementType
                                                      ?.measurementUnit;
                                                }
                                              }
                                            );
                                          }
                                        }
                                      }
                                    );

                                    return lastGoalValue !== null
                                      ? `${lastGoalValue}  ${measurementUnit}`
                                      : 'N/A';
                                  })()}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid item xs={12} md={6} sm={6}>
                              {(() => {
                                const goalGraphData = graphData.filter(
                                  ({ goalTitle, studentId }) =>
                                    goalTitle ===
                                      item?.info?.GoalDetails?.GoalTitle &&
                                    studentId === goal.id
                                );

                                return (
                                  goalGraphData?.length > 0 &&
                                  goalGraphData.map(
                                    ({
                                      goalTitle,
                                      graphData,
                                      targetDuration,
                                      typeOfGoal,
                                      studentId,
                                    }) => {
                                      const today = new Date();
                                      const sevenDaysAgo = new Date();
                                      sevenDaysAgo.setDate(today.getDate() - 7);

                                      // Filter graphData to include only last 7 days
                                      const filteredGraphData =
                                        graphData.filter(({ date }) => {
                                          const dataDate = new Date(date);
                                          return dataDate >= sevenDaysAgo;
                                        });

                                      return (
                                        <>
                                          {item?.info?.Generate?.Graphs.toString() ===
                                          'true' ? (
                                            <Box
                                              key={goalTitle}
                                              style={{
                                                cursor: 'pointer',
                                                minHeight: '220px',
                                                overflow: 'hidden',
                                                display: 'flex',
                                                alignItems: 'flex-end',
                                                gap: 20,
                                              }}
                                            >
                                              <ResponsiveContainer
                                                width='100%'
                                                height={200}
                                              >
                                                <LineChart
                                                  data={filteredGraphData.map(
                                                    ({ date, goalValues }) => ({
                                                      date,
                                                      percentage:
                                                        goalValues.length > 0
                                                          ? goalValues[0]
                                                          : 0,
                                                    })
                                                  )}
                                                >
                                                  <XAxis
                                                    dataKey='date'
                                                    tickFormatter={(date) =>
                                                      date.substring(5)
                                                    }
                                                  />
                                                  <YAxis
                                                    domain={[0, 6]}
                                                    tickCount={7}
                                                    tickFormatter={(value) =>
                                                      `${value} ${
                                                        item?.info
                                                          ?.DataCollection
                                                          ?.measurementType
                                                          ?.measurementUnit ||
                                                        ''
                                                      }`
                                                    }
                                                  />
                                                  <RechartsTooltip
                                                    formatter={(value) => [
                                                      `${value} ${
                                                        item?.info
                                                          ?.DataCollection
                                                          ?.measurementType
                                                          ?.measurementUnit ||
                                                        ''
                                                      }`,
                                                      undefined,
                                                    ]}
                                                    cursor={{
                                                      stroke: '#ccc',
                                                      strokeWidth: 1,
                                                    }}
                                                    labelFormatter={(label) =>
                                                      `Date: ${label}`
                                                    }
                                                  />
                                                  <Line
                                                    type='monotone'
                                                    dataKey='percentage'
                                                    stroke='#8884d8'
                                                    strokeWidth={2}
                                                  />
                                                </LineChart>
                                              </ResponsiveContainer>
                                              <Box
                                                onClick={() =>
                                                  handleGraphClick(
                                                    goalTitle,
                                                    goal.id,
                                                    graphData
                                                  )
                                                }
                                                style={{
                                                  cursor: 'pointer',
                                                  // marginTop: '8px',
                                                  display: 'flex',
                                                  flexDirection: 'row-reverse',
                                                  backgroundColor:
                                                    'var(--clr-mindly-background)',
                                                  borderRadius: '5px',
                                                }}
                                              >
                                                <ExpandMore fontSize='large' />
                                              </Box>
                                            </Box>
                                          ) : (
                                            <Box
                                              style={{
                                                display: 'flex',
                                                alignItems: 'flex-end',
                                                height: '100%',
                                                minHeight: '220px',
                                                justifyContent: 'center',
                                              }}
                                            >
                                              <Typography>
                                                Graph is disabled. Please enable
                                                it to view the graph.
                                              </Typography>
                                            </Box>
                                          )}
                                        </>
                                      );
                                    }
                                  )
                                );
                              })()}
                            </Grid>
                          </Grid>
                        </CardContent>
                        <Divider
                          sx={{ my: 2, borderBottomWidth: 2, bgcolor: 'black' }}
                        />
                      </>
                    );
                  }
                })
              ) : (
                <>
                  <CardContent key={goalIndex}>
                    <Grid container spacing={2} alignItems='flex-start'>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        container
                        spacing={2}
                        alignItems='flex-start'
                      >
                        <Grid
                          item
                          sx={{
                            width: 100,
                            display: 'flex',
                            gap: 1,
                            flexDirection: 'column',
                            alignItems: 'center',
                          }}
                        >
                          <Avatar src={goal.avatar} alt={goal.photoURL} />
                          <Typography
                            variant='subtitle1'
                            sx={{ textAlign: 'center' }}
                          >
                            {goal.fullname}
                          </Typography>
                        </Grid>
                        <Grid item xs>
                          <Typography variant='h6' paddingBottom={2}>
                            # {goal?.studentData?.GoalDetails?.GoalNumber}{' '}
                            {goal?.studentData?.GoalDetails?.GoalTitle}
                          </Typography>
                          <Typography
                            variant='body2'
                            sx={{ fontWeight: 'bold' }}
                          >
                            <Typography paddingBottom={1}>
                              {[
                                goal?.studentData?.DataCollection
                                  ?.measurementType?.Target,
                                goal?.studentData?.DataCollection
                                  ?.measurementType?.TargetDuration,
                                goal?.studentData?.DataCollection
                                  ?.measurementType?.measurementUnit,
                                'in',
                                goal?.studentData?.DataCollection
                                  ?.MultipleTimesToMeetGoal
                                  ?.NumberOfSessions === 'NA'
                                  ? '-'
                                  : goal?.studentData?.DataCollection
                                      ?.MultipleTimesToMeetGoal
                                      ?.NumberOfSessions || 0,
                                goal?.studentData?.DataCollection
                                  ?.MultipleTimesToMeetGoal
                                  ?.ConsecutiveSessions === 'Yes'
                                  ? 'consecutive sessions'
                                  : 'sessions',
                                'by',
                                goal?.studentData?.DataCollection?.ByDate,
                              ]
                                .filter(Boolean)
                                .join(' ')}
                            </Typography>

                            <strong>Baseline: </strong>
                            <span style={{ fontWeight: 500 }}>
                              {goal?.studentData?.DataCollection?.Baseline}
                            </span>
                            {/* {(() => {
                              const baselineValue =
                                goal?.studentData?.DataCollection?.Baseline;
                              const extractedNumber = baselineValue
                                ? parseFloat(
                                    baselineValue.match(/\d+(\.\d+)?/)?.[0]
                                  ) || 0
                                : 0;

                              return <span> {extractedNumber}</span>;
                            })()} */}
                          </Typography>
                          <Typography variant='body2'>
                            <strong>Goal Progress:</strong>{' '}
                            {(() => {
                              if (!Array.isArray(goalProgress)) return 'N/A';

                              const goalGraphData = goalProgress.find(
                                ({ goalTitle, studentId }) =>
                                  goalTitle ===
                                    goal?.studentData?.GoalDetails?.GoalTitle &&
                                  studentId ===
                                    (goal.id || goal?.studentData?.StudentID)
                              );

                              if (
                                goalGraphData &&
                                Array.isArray(goalGraphData.graphData)
                              ) {
                                // Filter out data points where percentage > 0
                                const validDataPoints =
                                  goalGraphData.graphData.filter(
                                    (data) => data.percentage > 0
                                  );

                                // Get the last non-zero data point
                                const lastValidDataPoint =
                                  validDataPoints.length > 0
                                    ? validDataPoints[
                                        validDataPoints.length - 1
                                      ]
                                    : null;

                                return lastValidDataPoint
                                  ? `${lastValidDataPoint.percentage}`
                                  : 'N/A';
                              }

                              return 'N/A';
                            })()}
                          </Typography>

                          <Typography sx={{ fontSize: '18px' }}>
                            <strong>Last Data Point:</strong>{' '}
                            {(() => {
                              if (
                                !Array.isArray(studentGoalsData) ||
                                studentGoalsData.length === 0
                              )
                                return 'N/A';

                              // Find the specific student's goal data based on docId
                              const selectedGoalData = studentGoalsData.find(
                                (student) => student.docId === goal.docId
                              );

                              if (
                                !selectedGoalData ||
                                !selectedGoalData.studentData?.goalData
                              )
                                return 'N/A';

                              let lastGoalValue = null;
                              let measurementUnit =
                                selectedGoalData?.studentData?.DataCollection
                                  ?.measurementType?.measurementUnit || '';

                              // Find the goal data for the matched goal
                              const goalData =
                                selectedGoalData.studentData.goalData;

                              if (
                                Array.isArray(goalData) &&
                                goalData.length > 0
                              ) {
                                goalData.forEach((goalEntry) => {
                                  if (
                                    goalEntry?.info?.goal ===
                                    goal?.studentData?.GoalDetails?.GoalTitle
                                  ) {
                                    if (
                                      Array.isArray(
                                        goalEntry?.info?.data_points
                                      ) &&
                                      goalEntry.info.data_points.length > 0
                                    ) {
                                      // Sort data points by date
                                      const sortedDataPoints = [
                                        ...goalEntry.info.data_points,
                                      ].sort(
                                        (a, b) =>
                                          (a.createdAt?._seconds || 0) -
                                          (b.createdAt?._seconds || 0)
                                      );

                                      // Get the last data point for this specific goal
                                      const lastDataPoint =
                                        sortedDataPoints[
                                          sortedDataPoints.length - 1
                                        ];
                                      lastGoalValue = lastDataPoint.goal_value;
                                    }
                                  }
                                });
                              }

                              return lastGoalValue !== null
                                ? `${lastGoalValue} ${measurementUnit}`
                                : 'N/A';
                            })()}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={6} sm={6}>
                        {(() => {
                          const goalGraphData = graphData.filter(
                            ({ goalTitle, studentId }) =>
                              goalTitle ===
                                goal?.studentData?.GoalDetails?.GoalTitle &&
                              studentId ===
                                (goal.id || goal?.studentData?.StudentID)
                          );

                          return (
                            goalGraphData?.length > 0 &&
                            goalGraphData.map(
                              ({
                                goalTitle,
                                graphData,
                                targetDuration,
                                typeOfGoal,
                                studentId,
                              }) => {
                                const today = new Date();
                                const sevenDaysAgo = new Date();
                                sevenDaysAgo.setDate(today.getDate() - 7);

                                // Filter graphData to include only last 7 days
                                const filteredGraphData = graphData.filter(
                                  ({ date }) => {
                                    const dataDate = new Date(date);
                                    return dataDate >= sevenDaysAgo;
                                  }
                                );

                                return (
                                  <>
                                    {goal?.studentData?.Generate?.Graphs.toString() ===
                                    'true' ? (
                                      <Box
                                        key={goalTitle}
                                        style={{
                                          cursor: 'pointer',
                                          minHeight: '220px',
                                          overflow: 'hidden',
                                          display: 'flex',
                                          alignItems: 'flex-end',
                                          gap: 20,
                                        }}
                                      >
                                        <ResponsiveContainer
                                          width='100%'
                                          height={200}
                                        >
                                          <LineChart
                                            data={filteredGraphData.map(
                                              ({ date, goalValues }) => ({
                                                date,
                                                percentage:
                                                  goalValues.length > 0
                                                    ? goalValues[0]
                                                    : 0,
                                              })
                                            )}
                                          >
                                            <XAxis
                                              dataKey='date'
                                              tickFormatter={(date) =>
                                                date.substring(5)
                                              }
                                            />
                                            <YAxis
                                              domain={[0, 6]}
                                              tickCount={7}
                                              tickFormatter={(value) =>
                                                `${value} ${
                                                  goal?.studentData
                                                    ?.DataCollection
                                                    ?.measurementType
                                                    ?.measurementUnit || ''
                                                }`
                                              }
                                            />
                                            <RechartsTooltip
                                              formatter={(value) => [
                                                `${value} ${
                                                  goal?.studentData
                                                    ?.DataCollection
                                                    ?.measurementType
                                                    ?.measurementUnit || ''
                                                }`,
                                                undefined,
                                              ]}
                                              cursor={{
                                                stroke: '#ccc',
                                                strokeWidth: 1,
                                              }}
                                              labelFormatter={(label) =>
                                                `Date: ${label}`
                                              }
                                            />
                                            <Line
                                              type='monotone'
                                              dataKey='percentage'
                                              stroke='#8884d8'
                                              strokeWidth={2}
                                            />
                                          </LineChart>
                                        </ResponsiveContainer>
                                        <Box
                                          onClick={() =>
                                            handleGraphClick(
                                              goalTitle,
                                              goal.studentData.StudentID,
                                              graphData
                                            )
                                          }
                                          style={{
                                            cursor: 'pointer',
                                            // marginTop: '8px',
                                            display: 'flex',
                                            flexDirection: 'row-reverse',
                                            backgroundColor:
                                              'var(--clr-mindly-background)',
                                            borderRadius: '5px',
                                          }}
                                        >
                                          <ExpandMore fontSize='large' />
                                        </Box>
                                      </Box>
                                    ) : (
                                      <Box
                                        style={{
                                          display: 'flex',
                                          alignItems: 'flex-end',
                                          height: '100%',
                                          minHeight: '220px',
                                          justifyContent: 'center',
                                        }}
                                      >
                                        <Typography>
                                          Graph is disabled. Please enable it to
                                          view the graph.
                                        </Typography>
                                      </Box>
                                    )}
                                  </>
                                );
                              }
                            )
                          );
                        })()}
                      </Grid>
                    </Grid>
                  </CardContent>
                  <Divider
                    sx={{ my: 2, borderBottomWidth: 2, bgcolor: 'black' }}
                  />
                </>
              )}
            </Box>
          ))
        ) : (
          <>
            <Typography>No data to display</Typography>
          </>
        )}
      </Box>
      {dataEntryDialogOpen && (
        <NewGoalDialog
          open={dataEntryDialogOpen}
          onClose={() => setDataEntryDialogOpen(false)}
        />
      )}
    </MainLayout>
  );
};

export default AllGoals;
