import React, { useContext, useEffect, useState } from 'react';
import MainLayout from '../../hoc/MainLayout';
import {
  TextField,
  Checkbox,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  FormControlLabel,
  Typography,
  Grid,
  Dialog,
  Tooltip,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  Skeleton,
  MenuItem,
  Box,
  FormControl,
  InputLabel,
} from '@mui/material';
import { FirestoreDB } from '../../utils/firebase/firebase';
import { Edit, Delete } from '@mui/icons-material';
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  updateDoc,
} from 'firebase/firestore';
import AuthContext from '../../contexts/AuthContext';
import useSchoolsData from '../../hooks/useSchoolsData';
import useOrganizationData from '../../hooks/useOrganizationData';
import useSchoolAdminsData from '../../hooks/useSchoolAdminsData';
import { ToastContainer, toast } from 'react-toastify';
const SchoolPage = () => {
  const { user, typeOfUser } = useContext(AuthContext);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    capacity: 0,
    specializations: [],
    website: '',
    admin: '',
    organization: '',
    address: {
      street: '',
      line1: '',
      line2: '',
      zipCode: '',
    },
    contact: {
      areaCode: '',
      number: '',
    },
  });
  const [editIndex, setEditIndex] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filteredAdmins, setFilteredAdmins] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [schoolIndexDelete, setSchoolIndexDelete] = useState(0);

  const {
    data: schools = [],
    isLoading: isSchoolsLoading,
    error: schoolsError,
    refetch: refetchSchools,
  } = useSchoolsData();

  const {
    data: fetchOrgData = [],
    isLoading: isOrgLoading,
    error: orgError,
    refetch: refetchOrgData,
  } = useOrganizationData(user?.uid, typeOfUser);

  const {
    data: schoolAdmins = [],
    isLoading: isSchoolAdminsLoading,
    error: schoolAdminsError,
    refetch: refetchSchoolAdmins,
  } = useSchoolAdminsData(user?.uid, typeOfUser);

  const specializationOptions = [
    'Autism Spectrum Disorder',
    'Learning Disabilities',
    'Behavioral Support',
    'Speech Therapy',
    'Occupational Therapy',
    'Physical Therapy',
  ];

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (name.startsWith('address.')) {
      const fieldName = name.split('.')[1];
      setFormData((prev) => ({
        ...prev,
        address: { ...prev.address, [fieldName]: value },
      }));
    } else if (name.startsWith('contact.')) {
      const fieldName = name.split('.')[1];
      setFormData((prev) => ({
        ...prev,
        contact: { ...prev.contact, [fieldName]: value },
      }));
    } else if (name === 'specializations') {
      setFormData((prev) => ({
        ...prev,
        specializations: checked
          ? [...prev.specializations, value]
          : prev.specializations.filter((spec) => spec !== value),
      }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleClose = () => {
    setFormData({
      name: '',
      address: {
        street: '',
        line1: '',
        line2: '',
        zipCode: '',
      },
      contact: {
        areaCode: '',
        number: '',
      },
      capacity: 0,
      specializations: [],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const selectedOrganization = fetchOrgData.find(
      (organisation) => organisation.organizationName === formData.organization
    );
    const params = {
      schoolName: formData?.name || '',
      admin: formData?.admin || '',
      capacity: formData?.capacity || '',
      email: formData?.email || '',
      website: formData?.website || '',
      address: formData?.address || '',
      contact: formData?.contact || { areaCode: '00', number: '000000000' },
      specializations: formData?.specializations || '',
      organizationName: formData?.organization || '',
      organizationId: selectedOrganization?.organizationId || '',
    };

    try {
      if (editIndex !== null) {
        const schoolToEdit = schools[editIndex];
        const docRef = doc(FirestoreDB, 'schoolsList', schoolToEdit.id);

        await updateDoc(docRef, params);

        await refetchSchools();
      } else {
        const docRef = await addDoc(
          collection(FirestoreDB, 'schoolsList'),
          params
        );
        await updateDoc(docRef, {
          schoolId: docRef.id,
        });

        await refetchSchools();
      }

      setIsModalOpen(false);
      if (editIndex === null)
        toast.success('School added successfully.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      else
        toast.success('School updated successfully.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });

      handleClose();
      setEditIndex(null);
    } catch (error) {
      console.error('Error saving document: ', error);
    }
  };

  const handleEdit = (index) => {
    const schoolToEdit = schools[index];
    setFormData({
      name:
        schoolToEdit.fullname ||
        schoolToEdit.name ||
        schoolToEdit.schoolName ||
        '',
      email: schoolToEdit.email || '',
      capacity: schoolToEdit.capacity || 0,
      website: schoolToEdit.website || '',
      admin: schoolToEdit.admin || '',
      address: schoolToEdit.address || {
        street: '',
        line1: '',
        line2: '',
        zipCode: '',
      },
      contact: schoolToEdit.contact || {
        areaCode: '',
        number: '',
      },
      specializations: schoolToEdit.specializations || [],
    });

    setEditIndex(index);
    setIsModalOpen(true);
  };

  const handleDelete = async (index) => {
    const schoolToDelete = schools[index];
    try {
      await deleteDoc(doc(FirestoreDB, 'schoolsList', schoolToDelete.id));
      await refetchSchools();
      toast.success('School deleted successfully.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } catch (error) {
      console.error('Error deleting document: ', error);
    }
  };

  useEffect(() => {
    if (formData.organization) {
      const selectedOrganization = fetchOrgData.find(
        (org) => org.organizationName === formData.organization
      );

      if (selectedOrganization) {
        const admins = schoolAdmins.filter(
          (admin) =>
            admin.organizationId === selectedOrganization.organizationId
        );
        setFilteredAdmins(admins);
      }
    } else {
      setFilteredAdmins([]);
    }
  }, [formData.organization, fetchOrgData, schoolAdmins]);

  const isFormValid =
    formData.name &&
    formData.address &&
    formData.capacity > 0 &&
    // formData.admin &&
    formData.contact &&
    formData.email &&
    formData.website;

  const SkeletonRow = () => {
    return (
      <TableRow>
        <TableCell>
          <Skeleton variant='text' width={150} />
        </TableCell>

        <TableCell>
          <Skeleton variant='text' width={200} />
        </TableCell>

        <TableCell>
          <Skeleton variant='text' width={150} />
        </TableCell>

        <TableCell>
          <Skeleton variant='text' width={50} />
        </TableCell>

        <TableCell>
          <Skeleton
            variant='rectangular'
            width={50}
            height={25}
            style={{ marginRight: '10px' }}
          />
        </TableCell>
      </TableRow>
    );
  };

  return (
    <MainLayout>
      <Box sx={{ padding: '20px' }}>
        <Typography variant='h4' gutterBottom>
          Special Education Schools
        </Typography>

        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: '20px',
          }}
        >
          <Button
            variant='contained'
            color='primary'
            onClick={() => {
              setFormData({
                name: '',
                address: '',
                capacity: 0,
                specializations: [],
              });
              setEditIndex(null);
              setIsModalOpen(true);
            }}
          >
            + Add School
          </Button>
        </div>

        <Dialog
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          maxWidth='md'
          fullWidth
        >
          <DialogTitle>
            {editIndex !== null ? 'Edit School' : 'Add School'}
          </DialogTitle>
          <DialogContent>
            <form onSubmit={handleSubmit}>
              <Typography variant='subtitle1'>School Details : </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4} sx={{ mt: 2 }}>
                  <FormControl fullWidth>
                    <InputLabel id='demo-simple-select-label'>
                      Select Organization
                    </InputLabel>
                    <Select
                      name='admin'
                      labelId='demo-simple-select-label'
                      id='demo-simple-select'
                      label='Select Organization'
                      value={formData.organization}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          organization: e.target.value,
                        }))
                      }
                      // onChange={(e) => {
                      //   const selectedOrg = fetchOrgData.find(
                      //     (org) => org.organizationName === e.target.value
                      //   );
                      //   setFormData((prev) => ({
                      //     ...prev,
                      //     organization: selectedOrg.organizationName,
                      //     organizationId: selectedOrg.oraganizationId,
                      //   }));
                      // }}
                      fullWidth
                      required
                    >
                      {fetchOrgData.map((orgadmin) => (
                        <MenuItem
                          key={orgadmin?.organizationId}
                          value={orgadmin.organizationName}
                        >
                          {orgadmin.organizationName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} sx={{ mt: 2 }}>
                  <TextField
                    label='School Name'
                    name='name'
                    value={formData.name}
                    onChange={handleInputChange}
                    fullWidth
                    required
                    // disabled={!formData.organization}
                  />
                </Grid>
                <Grid item xs={12} sm={4} sx={{ mt: 2 }}>
                  <TextField
                    label='Email'
                    name='email'
                    value={formData.email}
                    onChange={handleInputChange}
                    fullWidth
                    required
                    disabled={!formData.name}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label='Capacity'
                    name='capacity'
                    type='number'
                    value={formData.capacity}
                    onChange={handleInputChange}
                    fullWidth
                    required
                    disabled={!formData.name}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label='Website'
                    name='website'
                    value={formData.website}
                    onChange={handleInputChange}
                    fullWidth
                    required
                    disabled={!formData.name}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <InputLabel id='demo-simple-select-label'>
                      Select Admin
                    </InputLabel>
                    <Select
                      name='admin'
                      labelId='demo-simple-select-label'
                      id='demo-simple-select'
                      label='Select Admin'
                      value={formData.admin}
                      onChange={handleInputChange}
                      fullWidth
                      required
                      disabled={!formData.name || filteredAdmins.length == 0}
                    >
                      {filteredAdmins.map((admin) => (
                        <MenuItem key={admin.id} value={admin.name}>
                          {admin.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {/* <FormControl fullWidth required>
                    <InputLabel id='admin-select-label'>
                      Select Admin
                    </InputLabel>
                    <Select
                      labelId='admin-select-label' 
                      id='admin-select'
                      name='admin'
                      value={formData.admin}
                      onChange={handleInputChange}
                    >
                      {schoolAdmins.map((admin) => (
                        <MenuItem key={admin.id} value={admin.name}>
                          {admin.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl> */}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant='subtitle1'>Address : </Typography>
                  <TextField
                    label='Street'
                    name='address.street'
                    value={formData.address.street}
                    onChange={handleInputChange}
                    fullWidth
                    required
                    disabled={!formData.name}
                  />
                </Grid>
                <Grid item xs={12} sm={6} sx={{ mt: 3.5 }}>
                  <TextField
                    label='Line 1'
                    name='address.line1'
                    value={formData.address.line1}
                    onChange={handleInputChange}
                    fullWidth
                    required
                    disabled={!formData.name}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label='Line 2'
                    name='address.line2'
                    value={formData.address.line2}
                    onChange={handleInputChange}
                    fullWidth
                    disabled={!formData.name}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label='Zip Code'
                    name='address.zipCode'
                    value={formData.address.zipCode}
                    onChange={handleInputChange}
                    fullWidth
                    required
                    disabled={!formData.name}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant='subtitle1'>Contact : </Typography>
                  <TextField
                    label='Area Code'
                    name='contact.areaCode'
                    value={formData.contact?.areaCode || ''}
                    onChange={handleInputChange}
                    fullWidth
                    required
                    disabled={!formData.name}
                  />
                </Grid>
                <Grid item xs={12} sm={6} sx={{ mt: 3.5 }}>
                  <TextField
                    label='Number'
                    name='contact.number'
                    value={formData.contact?.number || ''}
                    onChange={handleInputChange}
                    fullWidth
                    required
                    disabled={!formData.name}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='subtitle1'>Specializations</Typography>
                  {specializationOptions.map((spec) => (
                    <FormControlLabel
                      key={spec}
                      control={
                        <Checkbox
                          name='specializations'
                          value={spec}
                          checked={formData.specializations.includes(spec)}
                          onChange={handleInputChange}
                          disabled={!formData.name}
                        />
                      }
                      label={spec}
                    />
                  ))}
                </Grid>
              </Grid>
            </form>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setFormData({
                  name: '',
                  email: '',
                  address: {
                    street: '',
                    line1: '',
                    line2: '',
                    zipCode: '',
                  },
                  contact: {
                    areaCode: '',
                    number: '',
                  },
                  capacity: 0,
                  specializations: [],
                });
                setIsModalOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              variant='contained'
              color='primary'
              disabled={!isFormValid}
            >
              {editIndex !== null ? 'Update' : 'Add'}
            </Button>
          </DialogActions>
        </Dialog>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: 'bold' }}>Name</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>Address</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>
                  Specializations
                </TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>Capacity</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {schools.length > 0 ? (
                schools.map((school, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {school.name || school.fullname || school.schoolName}
                    </TableCell>
                    <TableCell>{`${school.address.street}, ${school.address.line1}, ${school.address.line2}, ${school.address.zipCode}`}</TableCell>
                    <TableCell>{school.specializations.join(', ')}</TableCell>
                    <TableCell>{school.capacity}</TableCell>
                    <TableCell>
                      <Tooltip title='Edit School Information'>
                        <Button onClick={() => handleEdit(index)}>
                          <Edit sx={{ color: 'black' }} />
                        </Button>
                      </Tooltip>
                      <Tooltip title='Delete School'>
                        <Button
                          onClick={() => {
                            setDeleteModal(true);
                            setSchoolIndexDelete(index);
                          }}
                        >
                          <Delete sx={{ color: 'red' }} />
                        </Button>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))
              ) : isSchoolAdminsLoading || isSchoolsLoading || isOrgLoading ? (
                [...Array(5)].map((_, index) => <SkeletonRow key={index} />)
              ) : (
                <TableRow>
                  <TableCell colSpan={5} align='center'>
                    No schools yet. Add your first school above.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Dialog open={deleteModal} onClose={() => setSchoolIndexDelete(0)}>
        <DialogContent>
          <Typography
            sx={{
              color: 'var(--clr-theme-gray)',
              fontFamily: 'Karla',
              fontWeight: 700,
              textAlign: 'center',
            }}
          >
            Are you sure you want to delete this school?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant='contained'
            color='success'
            onClick={() => setDeleteModal(false)}
            sx={{
              backgroundColor: '#D3D3D3',
              textTransform: 'capitalize',
              fontFamily: 'Lato',
              fontWeight: 400,
              fontSize: '1.2rem',
              width: '100%',
            }}
          >
            No
          </Button>
          <Button
            variant='contained'
            color='success'
            onClick={() => {
              handleDelete(schoolIndexDelete);
              setDeleteModal(false);
            }}
            sx={{
              backgroundColor: '#9291f0',
              textTransform: 'capitalize',
              fontFamily: 'Lato',
              fontWeight: 400,
              fontSize: '1.2rem',
              width: '100%',
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer />
    </MainLayout>
  );
};

export default SchoolPage;
