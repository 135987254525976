import { useQuery } from "@tanstack/react-query";
import { fetchAllStudents } from "../api";

const useStudentData = (userId, typeOfUser) => {
  return useQuery({
    queryKey: ["students", userId, typeOfUser],
    queryFn: async () => {
      if (typeOfUser !== "ADMIN") return [];

      const fetchedData = await fetchAllStudents(userId, "ADMIN");

      const students = fetchedData?.students.map((student) => {
        const studentObj = {
          id: student.id,
          name: student.docData?.name || student.docData?.fullname || "",
          email: student.docData?.email || "",
          admissionNumber: student.docData?.inviteId || "",
          parentName: student.docData?.parentName || "",
          photoURL: student.docData?.photoURL || null,
          schoolName: student.docData?.schoolName || "",
          teacherName: student.docData?.teacherName || [],
          status: student.docData?.status,
          permissions: student.docData?.permissions || {},
          teacherIds: student.docData?.teacherIds || [],
        };
        // Debugging each student object
        return studentObj;
      }) || [];

      return students;
    },
    staleTime: 60000, // 1 min
    cacheTime: 300000, // 5 min
  });
};

export default useStudentData;
