import { collection, onSnapshot } from 'firebase/firestore';
import { FirestoreDB } from '../utils/firebase/firebase';
import { useQuery } from '@tanstack/react-query';

const fetchSymptoms = () => {
  return new Promise((resolve, reject) => {
    const symptomsRef = collection(FirestoreDB, 'symptoms');

    const unsubscribe = onSnapshot(
      symptomsRef,
      (querySnapshot) => {
        const symptomNames = querySnapshot.docs.map((doc) => doc.id);
        resolve(symptomNames);
      },
      (error) => reject(error)
    );

    return () => unsubscribe();
  });
};

export const useSymptoms = () => {
  return useQuery({
    queryKey: ['symptoms'],
    queryFn: fetchSymptoms,
    staleTime: 1000 * 60 * 5,
    cacheTime: 1000 * 60 * 10,
  });
};
