import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  List,
  ListItem,
  CircularProgress,
  ListItemText,
} from '@mui/material';
import { useMsal } from '@azure/msal-react';
import axios from 'axios';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import bootstrap5Plugin from '@fullcalendar/bootstrap5';

const MicrosoftCalendar = () => {
  const { instance } = useMsal();
  const [events, setEvents] = useState([]);
  const [accessToken, setAccessToken] = useState('');
  const [loading, setLoading] = useState(false);
  const [todayEvents, setTodayEvents] = useState([]);

  useEffect(() => {
    const getExistingToken = async () => {
      try {
        const accounts = instance.getAllAccounts();
        if (accounts.length > 0) {
          const response = await instance.acquireTokenSilent({
            scopes: ['User.Read', 'Calendars.ReadWrite'],
            account: accounts[0],
          });
          setAccessToken(response.accessToken);
          await fetchEvents(response.accessToken);
        }
      } catch (error) {
        console.error('Error fetching existing session:', error);
      }
    };

    getExistingToken();
  }, [instance]);

  useEffect(() => {
    if (events.length > 0) {
      const today = new Date().toISOString().split('T')[0];

      const filteredTodayEvents = events
        .filter((event) => event.start.startsWith(today))
        .sort((a, b) => new Date(a.start) - new Date(b.start));

      setTodayEvents(filteredTodayEvents);
    }
  }, [events]);

  // const handleLogin = async () => {
  //   try {
  //     setLoading(true);
  //     const response = await instance.loginPopup({
  //       scopes: ['User.Read', 'Calendars.ReadWrite'],
  //     });

  //     setAccessToken(response.accessToken);
  //     await fetchEvents(response.accessToken);
  //   } catch (error) {
  //     console.error('Login Error', error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };


  const handleLogin = async () => {
    try {
      setLoading(true);
      const accounts = instance.getAllAccounts();
  
      if (accounts.length > 0) {
        // Try to get token silently
        const response = await instance.acquireTokenSilent({
          scopes: ['User.Read', 'Calendars.ReadWrite'],
          account: accounts[0],
        });
        setAccessToken(response.accessToken);
        await fetchEvents(response.accessToken);
      } else {
        // If no session, do popup login
        const response = await instance.loginPopup({
          scopes: ['User.Read', 'Calendars.ReadWrite'],
        });
  
        setAccessToken(response.accessToken);
        await fetchEvents(response.accessToken);
      }
    } catch (error) {
      console.error('Login Error', error);
    } finally {
      setLoading(false);
    }
  };
  
  const fetchEvents = async (token) => {
    if (!token) {
      console.warn('No access token found. Please log in first.');
      return;
    }

    try {
      setLoading(true);
      let allEvents = [];
      let nextLink = `https://graph.microsoft.com/v1.0/me/calendar/events?$top=100`;

      while (nextLink) {
        const response = await axios.get(nextLink, {
          headers: { Authorization: `Bearer ${token}` },
        });

        allEvents = [...allEvents, ...response.data.value];
        nextLink = response.data['@odata.nextLink'] || null;
      }

      const formattedEvents = allEvents
        .map((event) => ({
          id: event.id,
          title: event.subject,
          start: event.start.dateTime,
          end: event.end.dateTime,
          url: event.webLink,
          allDay: event.isAllDay || false,
          extendedProps: { ...event },
        }))
        .sort((a, b) => new Date(a.start) - new Date(b.start));

      setEvents(formattedEvents);
    } catch (error) {
      console.error('Error fetching events:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleAddEvent = () => {
    window.open('https://outlook.office.com/calendar/action/compose', '_blank');
  };

  return (
    <Box sx={{ height: '100%' }}>
      {accessToken ? (
        loading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: 200,
            }}
          >
            {/* <CircularProgress /> */}
            <Typography>Loading events...</Typography>
          </Box>
        ) : events?.length > 0 ? (
          <div className='calendar-box'>
            <Box className='edit-events' sx={{ overflowY: 'auto' }}>
              <List
                subheader="Today's Events"
                sx={{
                  fontFamily: 'Lato',
                  fontWeight: 700,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                }}
              >
                <ListItem>
                  <Button
                    fullWidth
                    variant='contained'
                    onClick={handleAddEvent}
                    sx={{ backgroundColor: 'black !important' }}
                  >
                    Add Event
                  </Button>
                </ListItem>

                <div>
                  {todayEvents.length > 0 ? (
                    todayEvents.map((event, i) => (
                      <Box
                        key={event.id}
                        sx={{
                          height: 65,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          backgroundColor:
                            i % 2 !== 0
                              ? 'var(--clr-theme-white)'
                              : 'var(--clr-theme-purple-50)',
                          paddingLeft: '5px',
                        }}
                      >
                        <Box
                          sx={{
                            height: '100%',
                            paddingRight: '8px',
                            width: '24%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <Typography
                            color='secondary'
                            fontSize={25}
                            fontWeight='bold'
                          >
                            {new Date(event.start).getHours() +
                              ':' +
                              (new Date(event.start).getMinutes() < 10
                                ? '0' + new Date(event.start).getMinutes()
                                : new Date(event.start).getMinutes())}
                          </Typography>
                        </Box>
                        <Box className='events-TitleContent'>
                          <h6 className='long-and-truncated'>{event.title}</h6>
                        </Box>
                      </Box>
                    ))
                  ) : (
                    <Typography>No Meetings Available</Typography>
                  )}
                </div>
              </List>
            </Box>

            {/* Full Calendar */}
            <FullCalendar
              plugins={[
                dayGridPlugin,
                timeGridPlugin,
                listPlugin,
                bootstrap5Plugin,
              ]}
              themeSystem='bootstrap5'
              initialView='dayGridMonth'
              headerToolbar={{
                left: 'prev,next today',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
              }}
              navLinks={true}
              dayMaxEvents={true}
              events={events.map((event) => ({
                title: event.title,
                start: new Date(event.start),
                end: new Date(event.end),
                url: event.url,
                extendedProps: { ...event },
              }))}
              eventClick={(e) => {
                if (e.event.url) {
                  e.jsEvent.preventDefault();
                  window.open(e.event.url, '_blank');
                }
              }}
            />
          </div>
        ) : (
          <Typography>No events found for today.</Typography>
        )
      ) : (
        <Button onClick={handleLogin} variant='text'>
          Sign in with Microsoft Account
        </Button>
      )}
    </Box>
  );
};

export default MicrosoftCalendar;
