import {
  Box,
  Button,
  DialogActions,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Typography,
  IconButton,
  tooltipClasses,
  styled,
  Tooltip,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useContext, useEffect, useState } from 'react';
import 'react-calendar/dist/Calendar.css';
import './Reminders.css';
import usePatientsForDoctor from '../../hooks/usePatientsForDoctor';
import useStudentsForOneTeacher from '../../hooks/useStudentsForOneTeacher';
import AuthContext from '../../contexts/AuthContext';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import { FirestoreDB, LogAnalyticsEvent } from '../../utils/firebase/firebase';
import { addDoc, collection, getDocs } from 'firebase/firestore';
import Progress from '../Progress';
import Allreminders from './Allreminders';
import dayjs from 'dayjs';
import { ANALYTICS_KEYS } from '../../constants/AnalyticKeys';
import { PickersDay } from '@mui/x-date-pickers';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import useAllStudentGoalsData from '../../hooks/useAllStudentGoalsData';
import { ACM } from '../../constants/UserRoles';

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontWeight: 'bold',
    padding: '10px',
  },
}));

const ITEM_HEIGHT = 82;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
var currentDate = new Date();

var formattedDateTime = formatTime(currentDate);

function pad(n) {
  return n < 10 ? '0' + n : n;
}

function formatTime(date) {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = hours % 12 || 12; // Convert 0 to 12 for AM/PM format
  return `${pad(formattedHours)}:${pad(minutes)} ${ampm}`;
}

const Reminders = ({ currentPatient, patient }) => {
  // const { ACM } = useContext(ACMContext);
  const [patientData, setPatientData] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(
    patient?.id || currentPatient?.patientId || ''
  );
  const [selectedStudent, setSelectedStudent] = useState('');
  const [priority, setPriority] = useState('');
  const [value, setValue] = useState(new Date());
  const [time, setTime] = useState([formattedDateTime]);
  const [textReminder, setTextReminder] = useState('');
  const [showCreateReminder, setShowCreateReminder] = useState(true); // State to control visibility of Create Reminder section
  const [selectedDates, setSelectedDates] = useState([]);
  const [active, setActive] = useState(true);
  const [isLoading, SetIsLoading] = useState(false);
  const { user, typeOfUser } = useContext(AuthContext);
  const { data: patients = [] } = usePatientsForDoctor(user?.uid);
  const [allReminders, setAllReminders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [successPopUp, setSuccessPopUp] = useState(false);

  const { data: students = [] } = useStudentsForOneTeacher(
    user?.uid,
    typeOfUser
  );

  useEffect(() => {
    if (ACM[typeOfUser]?.getPatientData) {
      if (patients && ACM[typeOfUser]?.isDoctor) {
        const dataArray = patients?.map((each) => ({
          ...each?.patientInfo,
          id: each?.patientId,
          // id: each?.patientInfo?.fullname,
        }));
        setPatientData(dataArray);
      } else if (students && ACM[typeOfUser]?.isTeacher) {
        const dataArray = students?.map((each) => ({
          ...each,
          id: each?.id,
          // id: each?.patientInfo?.fullname,
        }));
        setPatientData(dataArray);
      }
    }
  }, [selectedPatient, patients, students]);

  const {
    data: studentsGoalData = [],
    isLoading: isGoalsLoading,
    error: goalsError,
    refetch: goalsRefetch,
  } = useAllStudentGoalsData(user?.uid, typeOfUser);

  const formatDate = (date) => {
    const year = new Date(date).getFullYear();
    const month = String(new Date(date).getMonth() + 1).padStart(2, '0'); // Adding 1 to month because months are zero-indexed
    const day = String(new Date(date).getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const handleDateChange = (date) => {
    const formattedDate = formatDate(date);
    const isSelected = selectedDates.includes(formattedDate);

    if (isSelected) {
      const updatedDates = selectedDates.filter((d) => d !== formattedDate);
      setSelectedDates(updatedDates);
    } else {
      setSelectedDates([...selectedDates, formattedDate]);
    }
  };

  const handleTextChangeHandler = (e) => {
    setTextReminder(e.target.value);
  };

  const handlePriorityChange = (event) => {
    setPriority(event.target.value);
  };

  const handleViewAllReminders = () => {
    setShowCreateReminder(false); // Hide Create Reminder section when View All Reminders button is clicked
  };

  const addTimePicker = () => {
    setTime([...time, formattedDateTime]); // Add current time to the array
  };

  const deleteTimePicker = (indexToDelete) => {
    const updatedTimes = time.filter((_, index) => index !== indexToDelete);
    setTime(updatedTimes);
  };

  const updateTime = (index, newTime) => {
    try {
      const formattedNewTime = formatTime(new Date(newTime));
      const updatedTimes = [...time];
      updatedTimes[index] = formattedNewTime;
      setTime(updatedTimes);
    } catch (error) {
      console.error('Error updating time:', error);
    }
  };

  const formatTime = (date) => {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12; // Convert 0 to 12 for AM/PM format
    return `${formattedHours}:${pad(minutes)} ${ampm}`;
  };

  function pad(n) {
    return n < 10 ? '0' + n : n;
  }

  const resetForm = () => {
    setSelectedPatient(patient?.id || currentPatient?.patientId || '');
    setSelectedStudent('');
    setValue(new Date());
    setTime([formattedDateTime]);
    setTextReminder('');
    setSelectedDates([]);
    SetIsLoading(false); // Reset loading state to false
  };

  const setReminderHandler = async () => {
    // Set loading to true when reminder is being added
    SetIsLoading(true);

    try {
      if (!user?.uid) {
        return;
      }

      const timeZone = Intl.DateTimeFormat().resolvedOptions()?.timeZone;

      const remindersData = {
        message: textReminder || '',
        dates: selectedDates || [],
        times: time || [],
        active: active,
        priority,
        timeZone,
        ...(ACM?.[typeOfUser]?.isDoctor
          ? { doctorId: user?.uid }
          : { teacherId: user?.uid }),
      };

      if (ACM[typeOfUser]?.isDoctor) {
        await addDoc(
          collection(
            FirestoreDB,
            'notifications',
            selectedPatient,
            'reminders'
          ),
          remindersData
        );
      } else {
        await addDoc(
          collection(
            FirestoreDB,
            'notifications',
            selectedStudent,
            'reminders'
          ),
          remindersData
        );
      }

      LogAnalyticsEvent(ANALYTICS_KEYS.DASHBOARD.REMINDERS, {
        message: textReminder || '',
        dates: selectedDates || [],
        times: time || [],
        active: active,
        priority,
        timeZone,
        ...(ACM?.[typeOfUser]?.isDoctor
          ? { doctorId: user?.uid }
          : { teacherId: user?.uid }),
      });

      setSuccessPopUp(true);
      resetForm();
    } catch (error) {
      console.error('Error adding reminder:', error.message);
      // Handle error here, such as showing a toast message or displaying an error modal
    } finally {
      // Set loading to false when the operation completes (whether success or failure)
      SetIsLoading(false);
    }
  };

  const getRemindersData = async (patientId) => {
    // Set loading state to true when fetching data starts
    setLoading(true);

    try {
      const remindersRef = collection(
        FirestoreDB,
        'notifications',
        patientId,
        'reminders'
      );
      const remindersSnapshot = await getDocs(remindersRef);
      const remindersData = remindersSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      return remindersData;
    } catch (error) {
      console.error('Error fetching reminders data:', error.message);
      // Handle error here
    } finally {
      // Clear loading state when data fetching is complete
      setLoading(false);
    }
  };

  const fetchReminders = async () => {
    if (!patientData && !studentsGoalData) return;

    const allReminders = [];

    if (ACM?.[typeOfUser]?.isDoctor) {
      for (const patient of patientData) {
        const reminderData = await getRemindersData(patient.id);
        allReminders.push({
          id: patient?.id,
          reminders: reminderData,
          name: patient?.fullname,
          photoURL: patient?.photoURL,
        });
      }
    } else {
      for (const student of studentsGoalData) {
        const reminderData = await getRemindersData(student.id);
        allReminders.push({
          id: student?.id,
          reminders: reminderData,
          name: student?.fullname,
          photoURL: student?.photoURL,
        });
      }
    }
    setAllReminders(allReminders);
  };
  const deleteDatePicker = (indexToDelete) => {
    const updatedDates = selectedDates.filter(
      (_, index) => index !== indexToDelete
    );
    setSelectedDates(updatedDates);
  };

  useEffect(() => {
    fetchReminders(); // Call the async function to fetch reminders
  }, [patientData, showCreateReminder]);

  const capitalizeWords = (name) => {
    return name
      ?.split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  const truncateName = (name, limit = 10) => {
    const capitalized = capitalizeWords(name);
    if (capitalized?.length > limit) {
      return `${capitalized.slice(0, limit)}...`;
    }
    return capitalized;
  };

  if (isLoading) return <Progress />;

  return (
    <>
      {showCreateReminder ? (
        <>
          <Box
            className='fullWidth fullHeight flexBox flexColumn Reminders'
            gap={2}
            sx={{
              // border: '1px solid',
              // p: 3,
              backgroundColor: 'var(--clr-mindly-background)',
              borderRadius: 1,
            }}
          >
            <>
              <Box>
                <FormControl fullWidth>
                  <Typography
                    variant='body2'
                    gutterBottom
                    sx={{ color: 'var(--clr-mindly-black)', fontWeight: 600 }}
                  >
                    {ACM[typeOfUser]?.isDoctor
                      ? 'Select Patient'
                      : 'Select Student'}
                  </Typography>
                  {ACM[typeOfUser]?.isDoctor ? (
                    <Select
                      disabled={selectedPatient}
                      key={selectedPatient}
                      labelId='demo-simple-select-label'
                      id='demo-simple-select'
                      value={selectedPatient}
                      onChange={(e) => {
                        setSelectedPatient(e.target.value);
                      }}
                      sx={{
                        height: '46px',
                        borderRadius: '8px',
                        backgroundColor: 'var(--clr-mindly-white)',
                        boxShadow: '0px 0px 10px rgba(0,0,0,0.1);',
                        // border: "1px solid #9192ef;",
                      }}
                      MenuProps={MenuProps}
                    >
                      {patientData?.map((patient) => {
                        return (
                          <MenuItem key={patient.id} value={patient?.id}>
                            {ACM[typeOfUser]?.isDoctor ? (
                              <BootstrapTooltip
                                title={
                                  patient?.parentName
                                    ? `${patient.fullname} (${patient.parentName})`
                                    : patient?.fullname
                                }
                              >
                                {patient?.parentName
                                  ? `${truncateName(
                                      patient.fullname
                                    )} (${truncateName(patient.parentName)})`
                                  : `${truncateName(patient.fullname)}`}
                              </BootstrapTooltip>
                            ) : (
                              <BootstrapTooltip title={patient?.name}>
                                {patient?.name}
                              </BootstrapTooltip>
                            )}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  ) : (
                    <Select
                      disabled={selectedStudent}
                      key={selectedStudent}
                      labelId='demo-simple-select-label'
                      id='demo-simple-select'
                      value={selectedStudent}
                      onChange={(e) => {
                        setSelectedStudent(e.target.value);
                      }}
                      sx={{
                        height: '46px',
                        borderRadius: '8px',
                        backgroundColor: 'var(--clr-mindly-white)',
                        boxShadow: '0px 0px 10px rgba(0,0,0,0.1);',
                        // border: "1px solid #9192ef;",
                      }}
                      MenuProps={MenuProps}
                    >
                      {studentsGoalData?.map((student) => (
                        <MenuItem key={student.id} value={student.id}>
                          {student.fullname}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </FormControl>
              </Box>
              <Box>
                <Typography
                  variant='body2'
                  gutterBottom
                  sx={{ fontWeight: 600 }}
                >
                  Reminder Notes
                </Typography>
                <TextField
                  value={textReminder}
                  onChange={handleTextChangeHandler}
                  fullWidth
                  multiline
                  rows={3}
                  sx={{ backgroundColor: '#fff' }}
                />
              </Box>
              <Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    // justifyContent: 'space-between',
                    width: '100%',
                    flexWrap: 'wrap',
                    gap: 2,
                  }}
                >
                  <Typography
                    variant='body2'
                    gutterBottom
                    sx={{ fontWeight: 600 }}
                  >
                    Select Date :
                  </Typography>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <StaticDatePicker
                      displayStaticWrapperAs='desktop'
                      value={selectedDates}
                      onChange={handleDateChange}
                      shouldDisableDate={(date) =>
                        dayjs(date).isBefore(dayjs(), 'day')
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{
                            backgroundColor: 'white',
                            border: 'none',
                            borderRadius: '50px',
                            '& .MuiPickersDay-root': {
                              borderRadius: '50%',
                            },
                          }}
                        />
                      )}
                      renderDay={(day, _, pickersProps) => {
                        const isSelected = selectedDates.some((date) =>
                          dayjs(date).isSame(day, 'day')
                        );

                        return (
                          <PickersDay
                            {...pickersProps}
                            day={day}
                            selected={isSelected}
                          />
                        );
                      }}
                      className='custom-calendar'
                      sx={{ height: 'auto', minHeight: '300px' }} // Adjust height to prevent vertical scroll
                    />
                  </LocalizationProvider>
                </Box>
                {selectedDates.length > 0 && (
                  <Box>
                    <Typography
                      variant='body2'
                      gutterBottom
                      sx={{ fontWeight: 600 }}
                    >
                      Selected Dates:
                    </Typography>
                    <ul>
                      {selectedDates?.map((date, index) => (
                        <li key={index}>
                          {date}
                          <IconButton onClick={() => deleteDatePicker(index)}>
                            <DeleteIcon color='error' />
                          </IconButton>
                        </li>
                      ))}
                    </ul>
                  </Box>
                )}
              </Box>
              <Box>
                <Typography
                  variant='body2'
                  gutterBottom
                  sx={{ fontWeight: 600 }}
                >
                  Select Time
                </Typography>
                {time?.map((time, index) => (
                  <Box key={index} mb={1}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        value={dayjs(time, 'HH:mm A')}
                        onChange={(val) => updateTime(index, val)}
                        renderInput={(params) => (
                          <TextField
                            value={time}
                            onChange={handleTextChangeHandler}
                            {...params}
                            InputLabelProps={{ style: { color: 'gray' } }}
                            sx={{
                              backgroundColor: 'white', // Change background color to white
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                  borderColor: 'gray', // Change border color to gray
                                },
                                '& .MuiInputLabel-root': {
                                  color: 'gray', // Change label color to gray
                                },
                                '& .MuiOutlinedInput-input': {
                                  color: 'gray', // Change input text color to gray
                                },
                              },
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>

                    <IconButton onClick={() => deleteTimePicker(index)}>
                      <DeleteIcon color='error' />
                    </IconButton>
                  </Box>
                ))}

                <Typography
                  variant='body2'
                  onClick={addTimePicker}
                  sx={{
                    mt: 1,
                    cursor: 'pointer',
                    color: 'var(--clr-mindly-black)',
                    width: 'fit-content',
                    fontWeight: 600,
                  }}
                >
                  <Radio
                    disabled
                    sx={{
                      color: 'var(--clr-mindly-black)',
                      '&.Mui-checked': {
                        color: 'var(--clr-mindly-black)',
                      },
                    }}
                  />
                  Add
                </Typography>
              </Box>
            </>
          </Box>
          <Box>
            <Typography variant='body2' gutterBottom sx={{ fontWeight: 600 }}>
              Priority
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
              <FormControl component='fieldset'>
                <RadioGroup
                  row
                  aria-label='priority'
                  name='priority'
                  value={priority}
                  onChange={handlePriorityChange}
                >
                  <FormControlLabel
                    value='low'
                    control={<Radio />}
                    label='Low'
                  />
                  <FormControlLabel
                    value='medium'
                    control={<Radio />}
                    label='Medium'
                  />
                  <FormControlLabel
                    value='high'
                    control={<Radio />}
                    label='High'
                  />
                </RadioGroup>
              </FormControl>
            </Box>
          </Box>

          <Box
            className='flexBox'
            sx={{
              width: '100%',
              gap: 2,
              mt: 2,
              flexDirection: 'row',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              variant='text'
              sx={{
                backgroundColor: 'var(--clr-mindly-white)',
                color: 'var(--clr-mindly-purple)',
                '&:hover': {
                  color: 'var(--clr-mindly-purple) ',
                  backgroundColor: 'var(--clr-mindly-white)',
                },
                alignSelf: 'end',
                fontWeight: 'bold',
                textTransform: 'capitalize',
                padding: '10px 20px',
              }}
              onClick={handleViewAllReminders} // Handle click event for View All Reminders button
            >
              View All
            </Button>
            <Button
              autoFocus
              onClick={setReminderHandler}
              sx={{
                backgroundColor: 'var(--clr-mindly-purple)',
                color: 'var(--clr-theme-white)',
                '&:hover': {
                  color: 'var(--clr-theme-white) ',
                  backgroundColor: 'var(--clr-mindly-purple-hover)',
                },
                alignSelf: 'end',
                fontWeight: 'bold',
                textTransform: 'capitalize',
                padding: '10px 20px',
              }}
            >
              Save Reminder
            </Button>
          </Box>
        </>
      ) : (
        <Box className='fullWidth fullHeight'>
          <Allreminders
            allReminders={allReminders}
            selectedPatient={selectedPatient}
            patientData={patientData}
            studentsGoalData={studentsGoalData}
            time={time}
            setActive={setActive}
            fetchReminders={fetchReminders}
            loading={loading}
          />
          <Button
            sx={{
              textTransform: 'capitalize',
              color: 'black',
              textDecoration: 'underline',
            }}
            fullWidth
            onClick={() => setShowCreateReminder(true)}
          >
            Switch To Reminder
          </Button>
        </Box>
      )}
      <Dialog open={successPopUp} onClose={() => setSuccessPopUp(false)}>
        <DialogTitle>
          <Typography
            variant='h4'
            align='center'
            style={{ fontFamily: 'Lato, sans-serif', fontWeight: 'bold' }}
          >
            {'Set Reminder'}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography
            sx={{
              color: 'var(--clr-theme-gray)',
              fontFamily: 'Karla',
              fontWeight: 700,
              textAlign: 'center',
            }}
          >
            Your reminder has been sent successfully!
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant='contained'
            color='success'
            onClick={() => setSuccessPopUp(false)}
            sx={{
              backgroundColor: '#9291f0',
              textTransform: 'capitalize',
              fontFamily: 'Lato',
              fontWeight: 400,
              fontSize: '1.2rem',
              width: '100%',
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Reminders;
