import { Close, Google, Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Box,
  Button,
  Divider,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  TextField,
  Typography,
  IconButton,
  Checkbox,
  FormControlLabel,
  Alert,
  Snackbar,
  Modal,
  Chip,
} from '@mui/material';
import NewLogo from '../../../components/BrandLogo';
import React, { useContext, useState, useRef } from 'react';
import AuthContext from '../../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import Progress from '../../../components/Progress';
import { LogAnalyticsEvent } from '../../../utils/firebase/firebase';
import { ANALYTICS_KEYS } from '../../../constants/AnalyticKeys';

const SignIn = ({
  signupInfoHandler,
  goToHome,
  goToAdmin,
  forgotPasswordHandler,
}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState({
    show: false,
    message: '',
  });
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isAdminMode, setIsAdminMode] = useState(false);
  const [open, setOpen] = useState(true);
  const passwordInputRef = useRef(null);

  const {
    loginWithEmailPassword,
    loginWithGoogleProvider,
    getUserFromFirestore,
    getAdminUserFromFirestore,
  } = useContext(AuthContext);

  const navigate = useNavigate();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const googleHandler = async () => {
    try {
      const response = await loginWithGoogleProvider();

      if (response.status) {
        // ! Check if user exists in firebase collection already. i.e., to get previous user / new user
        if (!response.data?.isNewUser) {
          const result = await getUserFromFirestore(response.data.uid);

          const userType = result.userType
            ? result.userType.toLowerCase()
            : 'unknown';

          const googleSigninKey =
            result.userType === 'DOCTOR'
              ? ANALYTICS_KEYS.USERS.DOCTORGOOGLESIGNIN
              : ANALYTICS_KEYS.USERS.PATIENTGOOGLESIGNIN;

          LogAnalyticsEvent(googleSigninKey, {
            userUid: response?.data?.uid,
            message: `${userType} successfully signed in using Google`,
          });
          if (result.status) {
            // console.log('Google sign-in successful');

            navigate('/');
          } else {
            signupInfoHandler({ provider: 'GOOGLE', data: response.data });
          }
        } else {
          // new user -> navigate to info page
          signupInfoHandler({ provider: 'GOOGLE', data: response.data });
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
  const validateInputs = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email validation regex

    if (email.trim().length === 0) {
      setPasswordError({ show: true, message: 'Email is required.' });
      return false;
    }

    if (!emailRegex.test(email.trim())) {
      setPasswordError({
        show: true,
        message: 'Please enter a valid email address.',
      });
      return false;
    }

    if (password.trim().length === 0) {
      setPasswordError({ show: true, message: 'Password is required.' });
      return false;
    }

    // Validation passed
    setPasswordError({ show: false, message: '' });
    return true;
  };

  const emailChangeHandler = (e) => {
    setEmail(e.target.value);
  };

  const passwordChangeHandler = (e) => {
    setPassword(e.target.value);
  };

  const signinToFirebase = async () => {
    if (validateInputs()) {
      setIsLoading(true);
      const result = await loginWithEmailPassword(email, password);

      if (result.status) {
        const getUserFunction = isAdminMode
          ? getAdminUserFromFirestore
          : getUserFromFirestore;
        const userResult = await getUserFunction(result.data.uid);

        if (userResult.status) {
          // Store session details in localStorage
          const expiryTime = new Date().getTime() + 3600000; // Example: 1-hour expiry
          const sessionData = {
            accessToken: result.data.stsTokenManager.accessToken,
            expiry: expiryTime,
          };
          localStorage.setItem('session', JSON.stringify(sessionData));
          // const signinKey =
          //   result.userType === 'DOCTOR'
          //     ? ANALYTICS_KEYS.USERS.DOCTORSIGNIN
          //     : ANALYTICS_KEYS.USERS.PATIENTSIGNIN;

          const userType = result.userType
            ? result.userType.toLowerCase()
            : 'unknown';
          const signinKey = (() => {
            switch (userType) {
              case 'TEACHER':
                return ANALYTICS_KEYS.USERS.TEACHERSIGNIN;
              case 'STUDENT':
                return ANALYTICS_KEYS.USERS.STUDENTSIGNIN;
              case 'PATIENT':
                return ANALYTICS_KEYS.USERS.PATIENTSIGNIN; // Optional if applicable
              default:
                return ANALYTICS_KEYS.USERS.UNKNOWN; // Optional fallback key
            }
          })();
          // const userType = result.userType
          //   ? result.userType.toLowerCase()
          //   : 'unknown';
          LogAnalyticsEvent(signinKey, {
            userUid: result?.data?.uid,
            message: `${userType} successfully signed in`,
          });
          // Navigate to home after successful login
          if (isAdminMode) {
            goToAdmin(); // Redirect to /admin for admin users
          } else {
            goToHome(); // Navigate to home for regular users
          }
        } else {
          setPasswordError({
            show: true,
            message: 'User not found or unauthorized.',
          });
        }
      } else if (result?.data?.code) {
        // Handle errors based on `result.data.code`
        switch (result.data.code) {
          case 'auth/user-not-found':
            setPasswordError({
              show: true,
              message: 'User not found. Please check your email.',
            });
            break;
          case 'auth/wrong-password':
            setPasswordError({
              show: true,
              message: 'Incorrect password. Please try again.',
            });
            break;
          case 'auth/too-many-requests':
            setPasswordError({
              show: true,
              message:
                'Too many failed login attempts. Please try again later.',
            });
            break;
          default:
            setPasswordError({
              show: true,
              message: 'An error occurred during sign-in. Please try again.',
            });
            console.error('Unhandled error during sign-in:', result.data.code);
            break;
        }
      } else {
        setPasswordError({
          show: true,
          message: result.message || 'Invalid email & password',
        });
      }
      setIsLoading(false);
      // } else {
      //   setPasswordError({ show: true, message: 'Enter email & password' });
    }
  };

  const handleKeyDown = async (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (event.target.id === 'username') {
        passwordInputRef.current.focus();
      } else if (event.target.id === 'password') {
        await signinToFirebase();
      }
    }
  };

  const toggleAdminMode = () => {
    setIsAdminMode(true);
  };

  const toggleAdminSignup = () => {
    setIsAdminMode((prev) => !prev);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => setOpen(false)}
      >
        <Close fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div
      style={{
        width: '100%',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        gap: 15,
      }}
    >
      <NewLogo newline={false} />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          gap: 10,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            textAlign: 'left',
          }}
        >
          <Typography variant="h4" sx={{ fontFamily: 'Lato', fontWeight: 400 }}>
            {isAdminMode ? 'Welcome to Admin Portal' : 'Welcome to Mindly.'}
          </Typography>
          <Typography
            gutterBottom
            variant="subtitle1"
            sx={{
              color: 'var(--clr-theme-gray)',
              fontFamily: 'Karla',
              fontWeight: 700,
            }}
          >
            Please enter your email and password to proceed
          </Typography>
        </div>

        <InputLabel
          sx={{
            color: 'var(--clr-theme-gray)',
            fontFamily: 'Karla',
            fontWeight: 700,
          }}
        >
          Email
        </InputLabel>
        <TextField
          id="username"
          type={'email'}
          onChange={emailChangeHandler}
          sx={{ borderRadius: '10px' }}
          variant="outlined"
          onKeyDown={handleKeyDown}
          disabled={isLoading}
          autoComplete='off'
          
        />
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'start',
            justifyContent: 'space-between',
          }}
        >
          <InputLabel
            sx={{
              color: 'var(--clr-theme-gray)',
              fontFamily: 'Karla',
              fontWeight: 700,
            }}
          >
            Password
          </InputLabel>
          <Typography
            sx={{
              color: 'var(--clr-theme-gray)',
              fontFamily: 'Karla',
              fontWeight: 700,
            }}
          >
            <Link onClick={forgotPasswordHandler} style={{ cursor: 'pointer' }}>
              Forgot your Password ?
            </Link>
          </Typography>
        </div>
        <OutlinedInput
          disabled={isLoading}
          inputRef={passwordInputRef}
          id="password"
          fullWidth
          type={showPassword ? 'text' : 'password'}
          variant="outlined"
          onChange={passwordChangeHandler}
          // helperText={passwordError.show && passwordError.message}
          onKeyDown={handleKeyDown}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
          className="fullWidth"
        >
          <FormControlLabel
            control={<Checkbox defaultChecked />}
            label="Remember Me"
          />

          {passwordError.show && passwordError.message && (
            <>
              {/* <Typography color="red" variant="subtitle2" fontFamily={"Lato"}>
                {passwordError.show && passwordError.message}
              </Typography> */}
              <Alert
                variant="standard"
                fullWidth="true"
                severity="error"
                sx={{
                  fontSize: '0.70rem', // Smaller font size
                  p: 0.5,
                }}
              >
                {passwordError.show && passwordError.message}
              </Alert>
              <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={() => setOpen(false)}
                message={passwordError.show && passwordError.message}
                action={action}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              >
                <Alert
                  onClose={() => setOpen(false)}
                  severity="error" // Automatically adds the error icon
                  sx={{ width: '100%' }}
                >
                  {passwordError.show && passwordError.message}
                </Alert>
              </Snackbar>
            </>
          )}
        </Box>
        <Button
          variant="contained"
          color="success"
          onClick={signinToFirebase}
          sx={{
            backgroundColor: '#9291f0',
            textTransform: 'capitalize',
            fontFamily: 'Lato',
            fontWeight: 400,
            fontSize: '1.2rem',
            width: '100%',
          }}
          disabled={isLoading}
        >
          Log in
        </Button>
      </div>

      {isAdminMode ? (
        <Button
          variant="text"
          onClick={toggleAdminSignup}
          sx={{
            textTransform: 'capitalize',
            fontFamily: 'Karla',
            fontWeight: 700,
            color: 'var(--clr-theme-gray)',
            mt: 2,
          }}
        >
          {isAdminMode ? 'Go to User Login' : 'Sign Up as Admin'}
        </Button>
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 10,
            alignItems: 'center',
          }}
        >
          <Divider />
          <Button
            variant="outlined"
            onClick={googleHandler}
            sx={{
              textTransform: 'capitalize',
              color: 'var(--clr-theme-gray)',
              fontFamily: 'Karla',
              fontWeight: '700',
              fontSize: '1rem',
            }}
          >
            <Google sx={{ color: 'red', mr: 1 }} />
            Log in with Google
          </Button>
          <Typography>
            <Link
              onClick={forgotPasswordHandler}
              style={{ cursor: 'pointer', fontFamily: 'Karla' }}
            >
              Forgot Password ?
            </Link>
          </Typography>
          <Typography
            sx={{
              fontFamily: 'Karla',
              fontWeight: 700,
              fontSize: '1rem',
              color: 'var(--clr-theme-gray)',
            }}
          >
            Don't have an account ?
            <Link
              onClick={signupInfoHandler}
              style={{
                marginLeft: '5px',
                cursor: 'pointer',
                fontFamily: 'Karla',
              }}
            >
              Sign Up
            </Link>
          </Typography>
          <Typography
            sx={{
              fontFamily: 'Karla',
              fontWeight: 700,
              fontSize: '1rem',
              color: 'var(--clr-theme-gray)',
            }}
          >
            <Link
              onClick={toggleAdminMode}
              style={{
                marginLeft: '5px',
                cursor: 'pointer',
                fontFamily: 'Karla',
              }}
            >
              Admin Portal
            </Link>
          </Typography>
        </div>
      )}
      {isLoading && <Progress />}
    </div>
  );
};

export default SignIn;
