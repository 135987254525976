export const ACM = {
  ADMIN: {
    component: ['AdminEditInformation'],
    hasAdminMenu: true,
    userType: 'ADMIN',
    canAcceptPolicies: false,
    canViewNotifications: false,
    isAdmin: true,
  },

  DOCTOR: {
    component: ['DoctorEditInformation'],
    isDashboardAccess: true,
    isDashboardAppointments: true,
    teacherDashboard: false,
    isDashboardVideos: true,
    isDashboardStatistics: true,
    dashboardStatsOfUser: true,
    userSelectionForVideos: true,
    dateSelectionForVideos: true,
    isGoalsOverview: true,
    isAddNewGoals: true,
    videosFilter: true,
    viewHisUsers: true,
    userVideos: true,
    viewAllVideos: true,
    usersAvailable: true,
    hasUserMenu: true,
    videoFeedbackAddNewTreatment: true,
    statisticsScreenPatientList: true,
    fetchAllAttachmentsByUser: true,
    fetchAllAttachmentsByUserForDoctor: true,
    historicalDataScreenPatientList: true,
    historicalScreenAssessment: true,
    historicalScreenDocuments: true,
    historicalScreenBehavior: true,
    historicalScreenMetrics: true,
    canAccessProfile: true,
    canChangePassword: true,
    canAddPatient: true,
    canDeleteAccount: true,
    canAddPatTab: true,
    unreadMessageCountsDoc: true,
    canAcceptPolicies: true,
    canViewNotifications: true,
    updatedAcceptPoliciesHandlerForDoctor: true,
    attachmentServicesDoc: true,
    notificationServices: true,
    doctorReview: true,
    videoFeedback: true,
    getPatientData: true,
    getDoctorStatics: true,
    isDoctorTreatmentApproved: true,
    isDoctorUploadVideo: true,
    isDoctorUploadAttacments: true,
    videoListDoc: true,
    docReviewedVideos: true,
    docPendingVideos: true,
    isDoctor: true,
    isPrivate: true,
  },

  PATIENT: {
    component: ['PatientEditInformation'],
    userType: 'PATIENT',
    hisOwnAllVideos: true,
    hisOwnPendingVideos: true,
    isDashboardVideos: true,
    isDashboardStatistics: true,
    teacherDashboard: false,
    isDashboardAppointments: true,
    hisOwndashboardStats: true,
    isGoalsOverview: true,
    isAddNewGoals: true,
    isProfileCompleted: true,
    usersAvailable: false,
    hasPatientMenu: true,
    hisOwnStats: true,
    isPatientUploadAttacments: true,
    fetchAllAttachmentsByUser: true,
    fetchAllAttachmentsByUserForPatient: true,
    historicalScreenAssessment: true,
    historicalScreenDocuments: true,
    historicalScreenBehavior: true,
    historicalScreenMetrics: true,
    addDoctorByPatient: true,
    canAccessProfile: true,
    canChangePassword: true,
    canAddDoctor: true,
    canDeleteAccount: true,
    unreadMessageCountsPat: true,
    canAcceptPolicies: true,
    canViewNotifications: true,
    updatedAcceptPoliciesHandlerForPatient: true,
    attachmentServicesPat: true,
    patientReview: true,
    getPatientStatistics: true,
    patPendingVideos: true,
    isPatient: true,
  },

  TEACHER: {
    component: ['TeacherEditInformation'],
    isTeacher: true,
    isDashboardAccess: true,
    isDashboardVideos: true,
    isDashboardAppointments: true,
    isDashboardStatistics: true,
    dashboardStatsOfUser: true,
    userSelectionForVideos: true,
    dateSelectionForVideos: true,
    videoFeedbackAddNewTreatment: true,
    isGoalsOverview: true,
    isAddNewGoals: true,
    teacherDashboard: true,
    canAddStudent: true,
    videosFilter: true,
    viewHisUsers: true,
    userVideos: true,
    viewAllVideos: true,
    usersAvailable: true,
    hasUserMenu: true,
    hasTeacherMenu: true,
    homePageStatistics: true,
    statisticsScreenPatientList: true,
    fetchAllAttachmentsByUser: true,
    fetchAllAttachmentsByUserForDoctor: true,
    historicalDataScreenPatientList: true,
    historicalScreenAssessment: true,
    historicalScreenDocuments: true,
    historicalScreenBehavior: true,
    historicalScreenMetrics: true,
    canAccessProfile: true,
    canChangePassword: true,
    canAddPatient: false,
    canDeleteAccount: true,
    canAddPatTab: false,
    unreadMessageCountsDoc: true,
    canAcceptPolicies: true,
    canViewNotifications: true,
    updatedAcceptPoliciesHandlerForTeacher: true,
    attachmentServicesDoc: true,
    notificationServices: true,
    doctorReview: true,
    videoFeedback: true,
    getPatientData: true,
    getDoctorStatics: true,
    isDoctorTreatmentApproved: true,
    isDoctorUploadVideo: true,
    isDoctorUploadAttacments: true,
    videoListDoc: true,
    docReviewedVideos: true,
    docPendingVideos: true,
    // isDoctor: true,
    isDoctor: false,
    isPrivate: true,
  },

  STUDENT: {
    component: ['StudentEditInformation'],
    userType: 'STUDENT',
    hisOwnAllVideos: true,
    hisOwnPendingVideos: true,
    isDashboardVideos: true,
    isDashboardStatistics: true,
    isDashboardAppointments: true,
    hisOwndashboardStats: true,
    isProfileCompleted: true,
    isGoalsOverview: true,
    isAddNewGoals: true,
    teacherDashboard: false,
    usersAvailable: false,
    hasStudentMenu: true,
    hisOwnStats: true,
    fetchAllAttachmentsByUser: true,
    fetchAllAttachmentsByUserForPatient: true,
    historicalScreenAssessment: true,
    historicalScreenDocuments: true,
    historicalScreenBehavior: true,
    historicalScreenMetrics: true,
    addDoctorByPatient: false,
    canAccessProfile: true,
    canChangePassword: true,
    canAddDoctor: true,
    canDeleteAccount: true,
    unreadMessageCountsPat: true,
    canAcceptPolicies: true,
    canViewNotifications: true,
    updatedAcceptPoliciesHandlerForStudent: true,
    attachmentServicesPat: false,
    patientReview: true,
    getPatientStatistics: true,
    isPatientUploadVideo: false,
    patPendingVideos: true,
    isStudent: true,
  },

  SUPER_ADMIN: {
    hasAdminMenu: true,
    canAcceptPolicies: false,
    isAdmin: true,
  },

  SCHOOLADMIN: {
    component: ['SchoolAdminEditInformation'],
    hasSchoolAdminMenu: true,
    userType: 'SCHOOLADMIN',
    canAcceptPolicies: true,
    canViewNotifications: false,
    isDashboardAppointments: false,
    canAccessProfile: true,
    isSchoolAdmin: true,
    updatedAcceptPoliciesHandlerForSchoolAdmin: true,
  },
  ORGANIZATIONADMIN: {
    component: ['OrgAdminEditInformation'],
    hasOrganizationAdminMenu: true,
    userType: 'ORGANIZATIONADMIN',
    canAcceptPolicies: true,
    canViewNotifications: false,
    canAccessProfile: true,
    isOrganizationAdmin: true,
    updatedAcceptPoliciesHandlerForOrganizationAdmin: true,
  },
  CUSTOM_ROLE: {
    isOrganizationAdmin: true,
    isDoctor: true,
    isPatient: true,
    isTeacher: true,
    isStudent: true,
  },
};
