import { useState, useMemo, useEffect, useContext } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
} from 'recharts';
import {
  Box,
  Button,
  Modal,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import DataEntryModal from '../../components/GoalData/index';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import AuthContext from '../../contexts/AuthContext';
import DatePicker from 'react-date-picker';
import { FaCalendarAlt } from 'react-icons/fa';

const GoalGraph = ({
  goalTitle,
  studentId,
  graphData,
  studentDataPoints,
  targetDuration,
  measurementType,
  measurementUnit,
  dataPointPerSession,
}) => {
  const { user, typeOfUser } = useContext(AuthContext);
  const [filter, setFilter] = useState('1W');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedDataPoint, setSelectedDataPoint] = useState(null);
  const [open, setOpen] = useState(false);

  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const handlePointClick = (data) => {
    setSelectedDataPoint({ ...data, dataPointPerSession });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedDataPoint(null);
  };

  const filteredData = useMemo(() => {
    if (!graphData || !Array.isArray(graphData)) return [];

    const now = new Date();
    let fromDate, toDate;

    if (startDate && endDate) {
      const tempFromDate = new Date(startDate);
      tempFromDate.setHours(0, 0, 0, 0);
      fromDate = new Date(
        tempFromDate.getTime() - tempFromDate.getTimezoneOffset() * 60000
      );
      const tempToDate = new Date(endDate);
      tempToDate.setHours(23, 59, 59, 999);
      toDate = new Date(
        tempToDate.getTime() - tempToDate.getTimezoneOffset() * 60000
      );
    } else {
      switch (filter) {
        case '1W':
          fromDate = new Date(now.setDate(now.getDate() - 6));
          break;
        case '1M':
          fromDate = new Date();
          fromDate.setDate(fromDate.getDate() - 30);
          break;
        case '3M':
          fromDate = new Date(
            now.getFullYear(),
            now.getMonth() - 3,
            now.getDate()
          );
          break;
        case '6M':
          fromDate = new Date(
            now.getFullYear(),
            now.getMonth() - 6,
            now.getDate()
          );
          break;
        case '1Y':
          fromDate = new Date(
            now.getFullYear() - 1,
            now.getMonth(),
            now.getDate()
          );
          break;
        case 'ALL':
          return graphData;
        default:
          return graphData;
      }
      toDate = new Date();
    }

    const allDates = [];
    const currentDate = new Date(fromDate);
    while (currentDate <= toDate) {
      allDates.push(currentDate.toLocaleDateString('en-CA'));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    const dataMap = new Map(
      graphData.map((item) => [item.date, item.goalValue])
    );

    return allDates.map((date) => ({
      date,
      goalValue: dataMap.has(date) ? dataMap.get(date) : 0,
    }));
  }, [graphData, filter, startDate, endDate]);

  const phaseChangeDates = new Set();
  const eventDates = new Set();

  studentDataPoints?.forEach(({ date, dataPoints }) => {
    if (dataPoints?.info?.data_points) {
      dataPoints?.info?.data_points?.forEach((dp) => {
        if (dp?.phase_change) phaseChangeDates.add(date);
        if (dp?.event) eventDates.add(date);
      });
    }
  });

  const CustomDot = (props) => {
    const { cx, cy, payload } = props;

    const value = payload.goalValue;

    if (value === 'N/A' || value === 0 || value === null) {
      return null;
    }

    return (
      <g>
        <circle
          cx={cx}
          cy={cy}
          r={15}
          fill='transparent'
          style={{ cursor: 'pointer' }}
          onClick={() => handlePointClick(payload)}
        />

        <circle
          cx={cx}
          cy={cy}
          r={5}
          fill='black'
          stroke='white'
          strokeWidth={2}
          style={{ cursor: 'pointer' }}
          onClick={() => handlePointClick(payload)}
        />
      </g>
    );
  };

  useEffect(() => {
    if (!graphData || graphData.length === 0) return;

    let fromDate, toDate;
    const now = new Date();

    switch (filter) {
      case '1W':
        fromDate = new Date();
        fromDate.setDate(fromDate.getDate() - 6);
        toDate = new Date();
        break;
      case '1M':
        fromDate = new Date();
        fromDate.setDate(fromDate.getDate() - 30);
        toDate = new Date();
        break;
      case '3M':
        fromDate = new Date();
        fromDate.setMonth(fromDate.getMonth() - 3);
        toDate = new Date();
        break;
      case '6M':
        fromDate = new Date();
        fromDate.setMonth(fromDate.getMonth() - 6);
        toDate = new Date();
        break;
      case '1Y':
        fromDate = new Date();
        fromDate.setFullYear(fromDate.getFullYear() - 1);
        toDate = new Date();
        break;
      case 'ALL':
        fromDate = new Date(graphData[0].date);
        toDate = new Date(graphData[graphData.length - 1].date);
        break;
      default:
        return;
    }

    setStartDate(fromDate.toISOString().split('T')[0]);
    setEndDate(toDate.toISOString().split('T')[0]);
  }, [filter, graphData]);

  const shiftDateRange = (direction) => {
    if (!startDate || !endDate) return;
    const fromDate = new Date(startDate);
    const toDate = new Date(endDate);
    const shiftAmount =
      {
        '1W': 7,
        '1M': 30,
        '3M': 90,
        '6M': 180,
        '1Y': 365,
      }[filter] || 0;
    if (direction === 'prev') {
      fromDate.setDate(fromDate.getDate() - shiftAmount);
      toDate.setDate(toDate.getDate() - shiftAmount);
    } else {
      fromDate.setDate(fromDate.getDate() + shiftAmount);
      toDate.setDate(toDate.getDate() + shiftAmount);
    }
    setStartDate(fromDate.toISOString().split('T')[0]);
    setEndDate(toDate.toISOString().split('T')[0]);
  };

  return (
    <Box sx={{ p: 2 }}>
      <Box sx={{ textAlign: 'center', mb: 2 }}>
        <h3>Goal Progress</h3>
      </Box>

      <Stack
        direction={isSmallScreen ? 'column' : 'row'}
        spacing={2}
        alignItems={isSmallScreen ? 'stretch' : 'center'}
        justifyContent='space-between'
        sx={{ mb: 2 }}
      >
        <Stack direction='row' spacing={1}>
          {['1W', '1M', '3M', '6M', '1Y', 'ALL'].map((range) => (
            <Button
              key={range}
              variant={filter === range ? 'contained' : 'outlined'}
              onClick={() => {
                setFilter(range);
                setStartDate('');
                setEndDate('');
              }}
              sx={{
                backgroundColor:
                  filter === range ? 'var(--clr-mindly-purple)' : 'transparent',
                borderColor: 'var(--clr-mindly-purple)',
                color: filter === range ? '#fff' : 'var(--clr-mindly-purple)',
                '&:hover': {
                  backgroundColor: 'var(--clr-mindly-purple-hover)',
                  borderColor: 'var(--clr-mindly-purple-hover)',
                },
              }}
            >
              {range}
            </Button>
          ))}
        </Stack>

        <Stack direction='row' spacing={1} alignItems='center'>
          <Button
            variant='outlined'
            onClick={() => shiftDateRange('prev')}
            sx={{
              backgroundColor: 'var(--clr-mindly-purple)',
              color: 'white',
              borderRadius: '8px',
              borderColor: 'var(--clr-mindly-purple)',
              padding: '8px 16px',
              '&:hover': {
                backgroundColor: 'var(--clr-mindly-purple-hover)',
                borderColor: 'var(--clr-mindly-purple-hover)',
              },
            }}
          >
            {'<'} Prev
          </Button>
          <Typography>From</Typography>
          <DatePicker
            value={startDate}
            type='date'
            onChange={(date) => {
              setStartDate(date);
              if (endDate) setFilter('');
            }}
            calendarIcon={<FaCalendarAlt />}
            format='MM/dd/yyyy'
            dayPlaceholder='dd'
            monthPlaceholder='mm'
            yearPlaceholder='yyyy'
          />

          <Typography>To</Typography>
          <DatePicker
            value={endDate}
            type='date'
            onChange={(date) => {
              setEndDate(date);
              if (startDate) setFilter('');
            }}
            calendarIcon={<FaCalendarAlt />}
            format='MM/dd/yyyy'
            dayPlaceholder='dd'
            monthPlaceholder='yyyy'
          />
          <Button
            variant='outlined'
            onClick={() => shiftDateRange('next')}
            sx={{
              backgroundColor: 'var(--clr-mindly-purple)',
              color: 'white',
              borderRadius: '8px',
              padding: '8px 16px',
              '&:hover': {
                backgroundColor: 'var(--clr-mindly-purple-hover)',
              },
            }}
          >
            Next {'>'}
          </Button>
          <MenuRoundedIcon fontSize='medium' />
        </Stack>
      </Stack>

      <Box sx={{ width: '100%', height: 400 }}>
        <ResponsiveContainer width='100%' height='100%'>
          <LineChart data={filteredData} margin={{ bottom: 40 }}>
            <XAxis
              dataKey='date'
              tick={{ fill: '#555' }}
              tickFormatter={(date) => date.substring(5)}
              ticks={filteredData.map((d) => d.date)}
              label={{
                value: 'Date',
                position: 'outsideBottom',
                dy: 25,
                style: { textAnchor: 'middle', fill: '#555' },
              }}
            />

            <YAxis
              domain={[
                0,
                Math.max(
                  10,
                  targetDuration,
                  ...filteredData.map((d) => d.goalValue || 0)
                ),
              ]}
              axisLine={false}
              tick={({ x, y, payload }) => (
                <text x={x} y={y} fill='#555' textAnchor='end'>
                  <tspan x={x}>{payload.value}</tspan>
                  <tspan x={x} dy={12}>
                    {measurementUnit}
                  </tspan>
                </text>
              )}
              label={{
                value: `${
                  measurementType?.charAt(0).toUpperCase() +
                  measurementType?.slice(1)
                } `,
                angle: -90,
                position: 'insideLeft',
                style: { textAnchor: 'middle', fill: '#555' },
              }}
            />
            <Tooltip
              content={({ active, payload }) => {
                if (active && payload && payload.length) {
                  const data = payload[0].payload;

                  return (
                    <Box
                      sx={{
                        bgcolor: 'white',
                        p: 2,
                        borderRadius: 1,
                        boxShadow: 2,
                      }}
                    >
                      <Typography variant='body2'>
                        <strong>Date:</strong> {data.date}
                      </Typography>
                      <Typography variant='body2'>
                        <strong>Value:</strong> {data.goalValue}{' '}
                        {measurementUnit}
                      </Typography>
                    </Box>
                  );
                }
                return null;
              }}
            />

            <Line
              type='linear'
              dataKey='goalValue'
              stroke='black'
              strokeWidth={3}
              dot={<CustomDot />}
              connectNulls={true}
            />

            {[...phaseChangeDates].map((date) => {
              const phaseData = studentDataPoints.find(
                (dp) => dp.date === date
              );
              const phaseChangeText =
                phaseData?.dataPoints?.info?.data_points?.find(
                  (dp) => dp.phase_change
                )?.phase_change || '';
              return (
                <ReferenceLine
                  key={`phase-${date}`}
                  x={date}
                  stroke='black'
                  strokeWidth={2}
                  strokeDasharray='0'
                  label={{
                    value: phaseChangeText,
                    angle: -90,
                    position: 'insideLeft',
                    fill: 'black',
                    fontSize: 12,
                    dy: 36,
                    offset: 10,
                  }}
                />
              );
            })}

            {[...eventDates].map((date) => {
              const eventData = studentDataPoints.find(
                (dp) => dp.date === date
              );
              const eventText =
                eventData?.dataPoints?.info?.data_points?.find((dp) => dp.event)
                  ?.event || '';
              return (
                <ReferenceLine
                  key={`event-${date}`}
                  x={date}
                  stroke='blue'
                  strokeWidth={2}
                  strokeDasharray='5 5'
                  label={{
                    value: eventText,
                    angle: -90,
                    position: 'insideLeft',
                    fill: 'black',
                    fontSize: 12,
                    dy: 36,
                    offset: 10,
                  }}
                />
              );
            })}

            {targetDuration && (
              <ReferenceLine
                y={targetDuration}
                stroke='red'
                strokeWidth={2}
                strokeDasharray='0'
                label={{
                  value: `Goal: ${targetDuration} ${measurementUnit}`,
                  position: 'insideTopRight',
                  fill: 'red',
                  fontSize: 12,
                }}
              />
            )}
          </LineChart>
        </ResponsiveContainer>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: 30,
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginRight: '20px',
            }}
          >
            <span style={{ fontWeight: 'bold', marginRight: '5px' }}>⬤</span>
            <span>Baseline</span>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginRight: '20px',
            }}
          >
            <span
              style={{
                color: 'blue',
                fontWeight: 'bold',
                marginRight: '5px',
              }}
            >
              - -
            </span>
            <span>Event</span>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginRight: '20px',
            }}
          >
            <span
              style={{ fontWeight: 'bold', marginRight: '5px', color: 'red' }}
            >
              ─
            </span>
            <span>Goal</span>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span
              style={{
                fontWeight: 'bold',
                color: 'black',
                marginRight: '5px',
              }}
            >
              ─
            </span>
            <span>Phase Change</span>
          </div>
        </div>
      </Box>

      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 600,
            maxHeight: '80vh',
            bgcolor: 'white',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            overflowY: 'auto',
          }}
        >
          {selectedDataPoint && (
            <DataEntryModal
              open={open}
              onClose={handleClose}
              goalTitle={goalTitle}
              studentDataPoints={studentDataPoints}
              selectedDate={selectedDataPoint.date}
              dataPointPerSession={dataPointPerSession}
              studentId={studentId}
              criteria={
                studentDataPoints?.[0]?.dataPoints?.info?.data_points[0]
                  ?.criteria
              }
            />
          )}
        </Box>
      </Modal>
    </Box>
  );
};

export default GoalGraph;
