import {
  AddAPhoto,
  Email,
  Image,
  Info,
  Lock,
  Person,
} from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Fab,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import Progress from '../../../components/Progress';
import { Timestamp, addDoc, collection } from 'firebase/firestore';
import {
  FirestoreDB,
  LogAnalyticsEvent,
} from '../../../utils/firebase/firebase';
import AuthContext from '../../../contexts/AuthContext';
import { validateAuthCodeAndGetDoctor } from '../../../api';
import PolicyTerms from '../../PolicyTerms';
import PrivacyLandingpage from '../PrivacyLandingpage';
// import SaasAgrement from "../SaasAgreement";
import { useNavigate } from 'react-router-dom';
import { ANALYTICS_KEYS } from '../../../constants/AnalyticKeys';
import PrivacyTerms from '../../PrivacyTerms';

const SignUp = ({ signinHandler, goToLogin, infoParams, emailHandler }) => {
  const [username, setUsername] = useState('');
  const [parentName, setParentName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState({
    show: false,
    message: '',
  });
  const [profilePicFile, setProfilePicFile] = useState(null);
  const [profilePicURL, setProfilePicURL] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [patient, setPatient] = useState(null);
  const [authCode, setAuthCode] = useState();
  const [doctorDetails, setDoctorDetails] = useState(null);
  const [isVerificationDone, setIsVerificationDone] = useState(false);
  const [openPoliciesModal, setOpenPoliciesModal] = useState(false);
  const [isPoliciesAccepted, setIsPoliciesAccepted] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);

  const [customError, setCustomError] = useState({
    show: false,
    message: '',
  });
  const [signupError, setSignupError] = useState({
    show: false,
    message: '',
  });
  const [parentNameError, setParentNameError] = useState({
    show: false,
    message: '',
  });

  const handleAuthCode = (e) => {
    setAuthCode(e.target.value);
  };

  const navigate = useNavigate();

  const { registerWithEmailPassword, registerNewPatient, login } =
    useContext(AuthContext);
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file?.size <= 10048576) {
      // Greater than 10MB
      setProfilePicFile(file);
    }
  };

  const addNotification = async () => {
    const { doctorId = '' } = doctorDetails;
    try {
      if (doctorId) {
        const params = {
          title: 'New Patient Registered',
          description: '',
          notifyTo: doctorDetails?.doctorProfile?.email,
          sentBy: patient?.email,
          userName: username,
          userPhotoURL: patient?.photoURL,
        };
        await addDoc(
          collection(FirestoreDB, 'notifications', doctorId, 'messages'),
          {
            infoMessage: btoa(JSON.stringify(params)),
            notificationSource: 'newPatientReg',
            userId: patient?.uid,
            isEncrypted: false,
            readStatus: false,
            createdDate: Timestamp.now(),
          }
        );
      }
    } catch (e) {
      console.log('error while adding notification', e);
    }
  };

  const registerToFirebase = async () => {
    if (
      validateInputs() &&
      (infoParams.type === 'PATIENT') === validateParentName()
    ) {
      setIsLoading(true);
      try {
        const result = await registerWithEmailPassword(
          email,
          password,
          username,
          parentName,
          profilePicFile,
          infoParams
        );
        if (result?.status) {
          setSignupError({ show: false, message: '' });
          const signupKey =
            infoParams.type === 'DOCTOR'
              ? ANALYTICS_KEYS.USERS.DOCTORSIGNUP
              : ANALYTICS_KEYS.USERS.PATIENTSIGNUP;

          LogAnalyticsEvent(signupKey, {
            message: `${infoParams.type.toLowerCase()} successfully signed up`,
            userType: infoParams.type,
          });

          if (infoParams?.type === 'PATIENT') {
            setPatient(result?.data);
          } else if (infoParams?.type === 'DOCTOR') {
            // goToLogin();
            const user = result.data;
            await login(user);
            navigate('/');
          }
        } else {
          if (result?.error) {
            switch (result.error) {
              case 'Firebase: Error (auth/email-already-in-use).':
                setSignupError({
                  show: true,
                  message:
                    'The email address is already in use by another account.',
                });
                break;
              case 'Firebase: Error (auth/invalid-email).':
                setSignupError({
                  show: true,
                  message:
                    'The email address is not valid. Please enter a valid email.',
                });
                break;
              default:
                setSignupError({
                  show: true,
                  message: 'An error occurred during signup. Please try again.',
                });
                console.error('Error during signup:', result.error);
                break;
            }
          } else {
            setSignupError({
              show: true,
              message: 'An unexpected error occurred. Please try again.',
            });
            console.error('Unexpected error format:', result);
          }
        }
      } catch (error) {
        console.error('Error during signup:', error);
        setSignupError({
          show: true,
          message: 'An unexpected error occurred. Please try again.',
        });
      } finally {
        setIsLoading(false);
      }
    } else {
      handleValidationErrors();
    }
  };

  const handleValidationErrors = () => {
    if (password.length < 7 && password) {
      setPasswordError({
        show: true,
        message: 'Password must be more than 7 characters.',
      });
    } else if (password !== confirmPassword) {
      setPasswordError({
        show: true,
        message: "Password and confirm password didn't match.",
      });
    } else if (!email || !username || !password || !confirmPassword) {
      setPasswordError({
        show: true,
        message: 'Please fill the above details.',
      });
    } else if (!isPoliciesAccepted) {
      setPasswordError({
        show: true,
        message: 'Please agree to the policies',
      });
    }
  };
  const validateParentName = () => {
    const fullnameRegex = /^[A-Za-z ]+$/;
    if (!fullnameRegex.test(parentName.trim())) {
      setParentNameError({
        show: true,
        message: "Parent's name must contain only alphabets.",
      });
      return false;
    } else {
      setParentNameError({ show: false, message: '' });
      return true;
    }
  };

  const textChangeHandler = (setfunction, e) => {
    setfunction(e.target.value);
  };

  const validateInputs = () => {
    const fullnameRegex = /^[A-Za-z ]+$/;
    const isValidFullName = fullnameRegex.test(username.trim());

    if (!isValidFullName) {
      setSignupError({
        show: true,
        message: 'Full name must be only alphabets.',
      });
    } else {
      setSignupError({ show: false, message: '' });
    }
    // Password min 8 chars
    return (
      isValidFullName &&
      username.trim().length > 0 &&
      email.trim().length > 0 &&
      password.trim().length > 7 &&
      confirmPassword.trim().length > 7 &&
      password === confirmPassword &&
      isPoliciesAccepted === true
    );
  };

  const handleCloseTermsModal = () => {
    setOpenPoliciesModal(false);
  };

  const openTerms = () => {
    setOpenPoliciesModal(true);
  };

  useEffect(() => {
    if (infoParams?.provider === 'GOOGLE') {
      setPatient(infoParams);
    }
  }, [infoParams]);

  useEffect(() => {
    let fileReader,
      isCancel = false;
    if (profilePicFile) {
      fileReader = new FileReader();
      fileReader.onload = (e) => {
        const { result } = e.target;
        if (result && !isCancel) {
          setProfilePicURL(result);
        }
      };
      fileReader.readAsDataURL(profilePicFile);
    }
    return () => {
      isCancel = true;
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    };
  }, [profilePicFile]);

  const validateAuthCode = async () => {
    setIsLoading(true);
    if (authCode && patient?.uid) {
      try {
        const doctorInfo = await validateAuthCodeAndGetDoctor(
          authCode,
          patient?.uid
        );
        setDoctorDetails(doctorInfo);
        if (doctorInfo?.doctorId) {
          setIsVerificationDone(true);
          // alert('Email Verification sent! Check your mailbox');
          setCustomError({ show: false, message: '' });
        } else {
          setIsVerificationDone(false);
        }
        if (doctorInfo?.data?.status === false) {
          setCustomError({ show: true, message: doctorInfo.data.message });
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  const registerToDB = async () => {
    const response = await registerNewPatient(patient, doctorDetails?.doctorId);

    if (response.status) {
      // goToLogin();
      await login(patient);
      addNotification();
      navigate('/');
    }
  };

  const acceptPoliciesHandler = () => {
    setIsPoliciesAccepted(true);
    setOpenPoliciesModal(false);
  };

  const nextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const renderContent = () => {
    switch (currentStep) {
      case 1:
        return <PolicyTerms footerRequired={false} />;
      case 2:
        // return <PrivacyLandingpage footerRequired={false} />;
        return <PrivacyTerms footerRequired={false} />;
      default:
        return null;
    }
  };

  const renderButton = () => {
    switch (currentStep) {
      case 1:
        return (
          <Fab
            size="medium"
            onClick={nextStep}
            variant="extended"
            sx={{
              fontFamily: 'Lato',
              fontSize: '1rem',
              background: 'var(--clr-theme-purple)',
              color: 'var(--clr-theme-white)',
              textTransform: 'none',
              ':hover': {
                background: 'var(--clr-theme-purple-50)',
              },
            }}
          >
            Accept Terms & Conditions
          </Fab>
        );
      case 2:
        return (
          <Fab
            size="medium"
            onClick={acceptPoliciesHandler}
            variant="extended"
            sx={{
              fontFamily: 'Lato',
              fontSize: '1rem',
              background: 'var(--clr-theme-purple)',
              color: 'var(--clr-theme-white)',
              textTransform: 'none',
              ':hover': {
                background: 'var(--clr-theme-purple-50)',
              },
            }}
          >
            Accept Privacy & Policy
          </Fab>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
          gap: 5,
          width: '100%',
        }}
      >
        <Typography variant="h4" sx={{ fontFamily: 'Lato', fontWeight: 400 }}>
          Get started
        </Typography>
        <Typography
          variant="h6"
          sx={{
            color: 'var(--clr-theme-gray)',
            fontFamily: 'Karla',
            fontWeight: 700,
          }}
        >
          Register as a new member
        </Typography>
        {!patient && (
          <>
            <div className="profile-picture-container">
              <Avatar
                className="profile-avatar"
                alt="upload picture"
                sx={{ width: 100, height: 100 }}
                src={profilePicURL}
              >
                <Image />
              </Avatar>
              <Tooltip title="Max size of 10MB">
                <IconButton
                  className="profile-upload-icon"
                  color="secondary"
                  aria-label="upload picture"
                  component="label"
                >
                  <input
                    hidden
                    accept="image/*"
                    type="file"
                    onChange={handleFileChange}
                  />
                  <AddAPhoto />
                </IconButton>
              </Tooltip>
            </div>
            <TextField
              label={
                infoParams?.type === 'PATIENT'
                  ? 'Patient Full Name'
                  : 'Full Name'
              }
              type={'text'}
              sx={{ m: 1 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Person />
                  </InputAdornment>
                ),
              }}
              disabled={isLoading}
              variant="outlined"
              onChange={(e) => textChangeHandler(setUsername, e)}
            />
            {infoParams?.type === 'PATIENT' && (
              <TextField
                label="Parent's Name"
                type="text"
                sx={{ m: 1 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Person />
                    </InputAdornment>
                  ),
                }}
                disabled={isLoading}
                variant="outlined"
                onChange={(e) => textChangeHandler(setParentName, e)}
                error={parentNameError.show}
                helperText={parentNameError.show && parentNameError.message}
              />
            )}

            <TextField
              label="Email"
              type={'email'}
              sx={{ m: 1 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Email />
                  </InputAdornment>
                ),
              }}
              disabled={isLoading}
              variant="outlined"
              onChange={(e) => textChangeHandler(setEmail, e)}
              autoComplete="new-email"
              required
            />
            <TextField
              label="Password"
              type={'password'}
              sx={{ m: 1 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Lock />
                  </InputAdornment>
                ),
              }}
              disabled={isLoading}
              variant="outlined"
              onChange={(e) => textChangeHandler(setPassword, e)}
              autoComplete="new-password"
              required
            />
            <TextField
              label="Confirm Password"
              type={'password'}
              sx={{ m: 1 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Lock />
                  </InputAdornment>
                ),
              }}
              disabled={isLoading}
              variant="outlined"
              helperText={passwordError.show && passwordError.message}
              onChange={(e) => textChangeHandler(setConfirmPassword, e)}
            />
            <Box className="fullWidth">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="success"
                      checked={isPoliciesAccepted}
                      onClick={openTerms}
                      disabled={isLoading}
                      // onChange={() =>
                      //   setIsPoliciesAccepted(!isPoliciesAccepted)
                      // }
                    />
                  }
                  label={
                    <Typography
                      sx={{
                        fontFamily: 'Karla',
                        fontSize: '1rem',
                        color: 'var(--clr-theme-gray)',
                      }}
                    >
                      Agree to{' '}
                      <Link href="#" onClick={openTerms}>
                        Policies and Terms
                      </Link>
                    </Typography>
                  }
                />
              </FormGroup>
            </Box>

            {signupError.show && (
              <Typography className="errorMessage" variant="caption">
                {signupError.message}
              </Typography>
            )}
            <Button
              fullWidth
              variant="contained"
              sx={{ background: '#9291f0' }}
              onClick={registerToFirebase}
              disabled={isLoading}
              emailHandler={emailHandler}
            >
              Sign Up
            </Button>
          </>
        )}
        {(infoParams?.provider === 'GOOGLE' ||
          infoParams?.provider === 'PASSWORD') &&
        patient ? (
          <Box
            className="fullWidth fullHeight"
            sx={{
              gap: 2,
              display: 'flex',
              flexDirection: 'column',
              height: '400px',
              mt: 5,
            }}
          >
            <TextField
              label="Authorization code"
              fullWidth
              onChange={handleAuthCode}
              // disabled={isVerificationDone}
            />
            <Box className="flexBox" gap={0.5}>
              <Info color="success" sx={{ fontSize: '20px' }} />
              <Typography
                variant="caption"
                sx={{ textAlign: 'start', color: 'var(--clr-theme-gray)' }}
              >
                To proceed further, kindly obtain the authorization code from
                your healthcare provider or doctor, as it is required for the
                next steps in the registration process.
              </Typography>
            </Box>

            {isVerificationDone && doctorDetails && (
              <Typography
                variant="subtitle1"
                color={'green'}
                sx={{ textAlign: 'start', fontFamily: 'Lato' }}
              >
                Doctor: {doctorDetails?.doctorProfile?.fullname || 'No doctor'}{' '}
                *
              </Typography>
            )}
            {customError.show && (
              <Typography className="errorMessage" variant="caption">
                {customError.message}
              </Typography>
            )}

            <Button
              variant="contained"
              // fullWidth
              sx={{
                backgroundColor: 'var(--clr-theme-purple)!important',
                textTransform: 'initial',
              }}
              onClick={validateAuthCode}
              disabled={isVerificationDone}
            >
              Verify
            </Button>
            <Button
              variant="contained"
              // fullWidth
              sx={{
                backgroundColor: 'var(--clr-theme-purple)',
                textTransform: 'initial',
              }}
              onClick={registerToDB}
              disabled={!isVerificationDone}
            >
              Continue to dashboard
            </Button>
          </Box>
        ) : (
          <>{/* ... (existing code) */}</>
        )}

        <Typography
          sx={{
            mt: 2,
            fontFamily: 'Karla',
            fontWeight: 700,
            fontSize: '1rem',
            color: 'var(--clr-theme-gray)',
          }}
        >
          Already have an account ?
          <Link
            onClick={signinHandler}
            style={{
              marginLeft: '5px',
              cursor: 'pointer',
              fontFamily: 'Karla',
            }}
          >
            Sign In
          </Link>
        </Typography>
      </div>
      <Dialog
        sx={{
          marginTop: '40px',
        }}
        open={openPoliciesModal}
        onClose={handleCloseTermsModal}
      >
        <DialogContent>{renderContent()}</DialogContent>
        <DialogActions>{renderButton()}</DialogActions>
      </Dialog>
      {isLoading && <Progress />}
    </>
  );
};

export default SignUp;
