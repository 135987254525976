import { fetchDoctorAndPatientMessages } from '../api';
import { useQuery } from '@tanstack/react-query';

const useDoctorPatientMessages = (id, ChatId) => {
  return useQuery({
    queryKey: ['doctorPatientMessages', id, ChatId],
    queryFn: () => fetchDoctorAndPatientMessages(id, ChatId, id),
    enabled: !id && !!ChatId,
    staleTime: 60000,
    cacheTime: 300000,
  });
};

export default useDoctorPatientMessages;
