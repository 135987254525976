import { Box, FormControlLabel, TextField, Typography } from '@mui/material';
import {
  EmailAuthProvider,
  getAuth,
  reauthenticateWithCredential,
  updatePassword,
} from 'firebase/auth';
import React, { useState } from 'react';
import HomeCard from '../../../components/HomeCard';
import CustomButton from '../../../components/CustomButton';
import { LogAnalyticsEvent } from '../../../utils/firebase/firebase';
import { ANALYTICS_KEYS } from '../../../constants/AnalyticKeys';

const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [userMessage, setUserMessage] = useState({
    status: true,
    text: '',
  });

  const auth = getAuth();
  const user = auth.currentUser;
  const passwordChange = ANALYTICS_KEYS.SETTINGS.PASSWORDCHANGE;

  const changePasswordHandler = async () => {
    setLoading(true);
    setUserMessage({
      status: true,
      text: '',
    });
    LogAnalyticsEvent(passwordChange, {
      userId: user?.uid,
    });
    const newPasswordValid = () => {
      return (
        newPassword.trim() === confirmPassword.trim() &&
        newPassword.trim().length > 0
      );
    };
    const oldValid = await oldPasswordValid();
    const newValid = newPasswordValid();
    if (!oldValid) {
      setUserMessage({
        status: false,
        text: 'Incorrect old password !!',
      });
      setLoading(false);
      return;
    }

    if (!newValid) {
      setUserMessage({
        status: false,
        text: 'New Password / Confirm Password invalid !!',
      });
      setLoading(false);
      return;
    }

    if (oldValid && newValid) {
      try {
        await updatePassword(user, newPassword);

        setUserMessage({
          status: true,
          text: 'Password updated successfully',
        });

        resetFields();
      } catch (e) {
        setUserMessage({
          status: false,
          text: e.message,
        });
      }
    }

    setLoading(false);
  };

  const resetFields = () => {
    setOldPassword('');
    setNewPassword('');
    setConfirmPassword('');
  };

  const oldPasswordValid = async () => {
    try {
      const credential = EmailAuthProvider.credential(user.email, oldPassword);
      await reauthenticateWithCredential(user, credential);

      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  return (
    <HomeCard
      title='Change Password'
      showAvatar={false}
      customClass='profileCard'
    >
      {user.providerData[0].providerId === 'google.com' ? (
        <>
          <Typography>You are logged in with Google.</Typography>
          <Typography variant='subtitle2' color={'GrayText'}>
            Please update your google password from the provider website
          </Typography>
        </>
      ) : (
        <div className='changePasswordPage'>
          <Box className='changePasswordBox'>
            <FormControlLabel
              control={
                <TextField
                  fullWidth
                  label='Current Password'
                  type={'password'}
                  sx={{ m: 1, width: '32ch' }}
                  value={oldPassword}
                  onChange={(e) => {
                    setOldPassword(e.target.value);
                  }}
                />
              }
              label='Old Password'
              labelPlacement='start'
            />
            <FormControlLabel
              control={
                <TextField
                  fullWidth
                  label='New Password'
                  type={'password'}
                  sx={{ m: 1, width: '32ch' }}
                  value={newPassword}
                  onChange={(e) => {
                    setNewPassword(e.target.value);
                  }}
                />
              }
              label='New Password'
              labelPlacement='start'
            />
            <FormControlLabel
              control={
                <TextField
                  fullWidth
                  label='Confirm Password'
                  type={'password'}
                  sx={{ m: 1, width: '32ch' }}
                  value={confirmPassword}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                  }}
                />
              }
              label='Confirm Password'
              labelPlacement='start'
            />
          </Box>
          <Box sx={{ width: '10%', mt: 2 }}>
            <CustomButton
              variant='extended'
              color='success'
              ChangeHandler={changePasswordHandler}
              disabled={loading}
            >
              Submit
            </CustomButton>
          </Box>
          <Typography
            color={
              userMessage.status ? 'var(--clr-success)' : 'var(--clr-error)'
            }
            sx={{ marginTop: '20px' }}
          >
            {userMessage.text}
          </Typography>
        </div>
      )}
    </HomeCard>
  );
};

export default ChangePassword;
