import React, { useContext, useEffect, useState } from 'react';
import Carousel from 'react-material-ui-carousel';
import {
  Box,
  Button,
  Card,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import ReactPlayer from 'react-player';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import VideoFeedback from './VideoFeedback';
import PatientReview from './PatientReview';
import ProcessVideo from './ProcessVideo';
import FullProcessVideo from './FullProcessVideo';
import { doc, onSnapshot } from 'firebase/firestore';
import { FirestoreDB } from '../../utils/firebase/firebase';
import LineChartComponent from './LineChartComponent';
import VideoActivityLogs from './VideoActivityLogs';
import AuthContext from '../../contexts/AuthContext';

import './PlayVideo.css';
import Progress from '../Progress';
import { ACMContext } from '../../contexts/ACMProvider';
import useFetchVideoDetails from '../../hooks/useFetchVideoDetails';
import useFetchAllAttachmentsByUser from '../../hooks/useFetchAllAttachmentsByUser';
import useProcessedVideoDetails from '../../hooks/useProcessedVideoDetails';

const PlayVideo = ({
  typeOfUser,
  videoDetails,
  patientID,
  handleVideoOpen,
  videoList,
  videoSource,
  studentID,
}) => {
  const { ACM } = useContext(ACMContext);
  const [playingIndex, setPlayingIndex] = useState(0);
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [disableLeft, setDisableLeft] = useState(null);
  const [disableRight, setDisableRight] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [videoInfo, setVideoInfo] = useState(null);
  const [feedbackData, setFeedbackData] = useState({ exist: false, data: '' });
  const [feedbackDataPatient, setFeedbackDataPatient] = useState({
    exist: false,
    data: '',
  });
  const [isLoading, setIsLoading] = useState(true);
  const [processVideos, setProcessVideos] = useState({});
  const [processVideoStatusMessage, setProcessVideoStatusMessage] =
    useState('');
  const [isRawVideoPlaying, setIsRawVideoPlaying] = useState(false);
  const [isProcessedVideoPlaying, setIsProcessedVideoPlaying] = useState(false);
  const [isFullProcessedVideoPlaying, setIsFullProcessedVideoPlaying] =
    useState(false);
  const [summary, setSummary] = useState([]);
  const { setOpenRightPanel, setPanelContent, user } = useContext(AuthContext);

  const { data: videoDetail, isLoading: isFetchVideoLoading } =
    useFetchVideoDetails(
      patientID ? patientID : user?.uid,
      videoDetails?.videoId
    );
  console.log('videoDetails', videoDetail);
  const getVideoDetails = async (videoDetails) => {
    setIsLoading(true);

    setVideoInfo(videoDetails);

    if (
      videoDetails?.docNotes &&
      Object.keys(videoDetails.docNotes).length > 0
    ) {
      setFeedbackData({ exist: true, data: videoDetails.docNotes });
    } else {
      setFeedbackData({ exist: false, data: null });
    }

    if (videoDetails?.patientNotes) {
      setFeedbackDataPatient({ exist: true, data: videoDetails?.patientNotes });
    }
    setIsLoading(false);
  };

  const { data: fetchedDocuments } = useFetchAllAttachmentsByUser(
    user?.uid,
    typeOfUser
  );
  const fetchDocuments = async () => {
    if (!user) return;
    setIsLoading(true);

    try {
      // if (typeOfUser === "DOCTOR") {
      if (ACM[typeOfUser]?.attachmentServicesDoc) {
        // Filter documents that match the patientId
        const matchedDocuments = fetchedDocuments?.data?.filter(
          (doc) => doc.patientId === patientID || doc.studentId === studentID
        );

        if (matchedDocuments.length > 0) {
          // Loop through the matching documents and get all attachments
          const allAttachments = matchedDocuments.flatMap(
            (doc) => doc?.attachmentData
          );

          // Sort attachments by createdAt timestamp and get the latest one
          const latestAttachment = allAttachments
            .filter((attachment) => !attachment.docData.isDeleted) // Exclude deleted attachments
            .sort(
              (a, b) =>
                b.docData.createdAt._seconds - a.docData.createdAt._seconds
            )[0];

          if (latestAttachment) {
            // Handle the latest attachment (e.g., set it to summary state)
            setSummary(latestAttachment || []);
          } else {
            console.log('No valid attachments found.');
          }
        } else {
          console.log('No matching documents found for patientId:', patientID);
        }
      }
      // else if (typeOfUser === "PATIENT") {
      else if (ACM[typeOfUser]?.attachmentServicesPat) {
        setSummary(fetchedDocuments?.data);
        if (fetchedDocuments?.data?.length) {
          // Sort the documents by the createdAt timestamp in descending order
          const sortedDocuments = fetchedDocuments.data.sort((a, b) => {
            return b.docData.createdAt._seconds - a.docData.createdAt._seconds;
          });

          // The first document in the sorted array will be the latest one
          const latestDocument = sortedDocuments[0];

          // Set the summary to the latest document
          setSummary(latestDocument);
        } else {
          console.log('No attachments found for the patient.');
        }
      }
    } catch (error) {
      console.log('Error fetching documents:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (fetchedDocuments) fetchDocuments();
  }, [fetchedDocuments]);

  const {
    data: processedVideoDetails,
    isLoading: isProcessedVideoDetailsLoading,
  } = useProcessedVideoDetails(
    patientID ? patientID : user?.uid,
    videoDetails?.videoId
  );

  const getProcessVideos = async (id, videoId) => {
    try {
      if (videoId) {
        const videoDocRef = doc(
          FirestoreDB,
          'videoLogs',
          id,
          'processedVideos',
          videoId
        );
        onSnapshot(
          videoDocRef,
          async (querySnapshot) => {
            if (querySnapshot.exists()) {
              const docData = querySnapshot.data();
              const status = docData?.status;
              if (status === 'DONE') {
                const result = processedVideoDetails;
                result && setProcessVideos(result);
                setProcessVideoStatusMessage('');
              } else if (status === 'ERROR') {
                setProcessVideoStatusMessage(
                  'Could not process video due to insufficient data'
                );
              } else if (status === 'IN-PROGRESS') {
                setProcessVideoStatusMessage(
                  'Please wait, your processed video will be ready in 10 minutes'
                );
              } else {
                setProcessVideoStatusMessage(
                  'Please wait, your processed video will be ready in 10 minutes'
                );
              }
            } else {
              console.log('Video data not found.');
            }
          },
          (error) => {
            console.error('Error fetching video data:', error);
          }
        );
      } else {
        console.log('videoDetails or videoDetails.videoId is undefined');
      }
    } catch (error) {
      console.error('Error in getProcessVideos:', error);
    }
  };

  useEffect(() => {
    if (isFetchVideoLoading) {
      return;
    }
    if (videoDetail) {
      getVideoDetails(videoDetail);
    }
  }, [patientID, videoDetails, videoDetail, isFetchVideoLoading]);

  useEffect(() => {
    if (isProcessedVideoDetailsLoading || isFetchVideoLoading) {
      return;
    }
    if (videoDetail && processedVideoDetails) {
      getProcessVideos(patientID ? patientID : user?.uid, videoDetail?.videoId);
    }
  }, [
    patientID,
    videoDetails,
    isFetchVideoLoading,
    processedVideoDetails,
    isProcessedVideoDetailsLoading,
  ]);

  const activityLogHandler = () => {
    setPanelContent(
      <VideoActivityLogs
        patientID={patientID}
        user={user}
        typeOfUser={typeOfUser}
        videoDetails={videoInfo}
      />
    );
    setOpenRightPanel(true);
  };

  const selectedVideoIndex = () => {
    if (videoList) {
      videoList.map((res, index) => {
        if (res.videoId === videoDetails?.videoId) {
          index < 1 && setDisableLeft(true);
          index == videoList.length - 1 && setDisableRight(true);
        }
      });
    }
  };
  const nextVideo = () => {
    if (playingIndex < videoList.length - 1) {
      setDisableLeft(false);
      setPlayingIndex((next) => next + 1);
      setCarouselIndex(0);

      return handleVideoOpen(
        videoList[playingIndex + 1],
        videoList[playingIndex + 1]?.user?.uid || videoList[1]?.user?.id,
        // || videoList[playingIndex + 1]?.patient?.id,
        videoSource
      );
    }
  };

  const prevVideo = () => {
    if (playingIndex > 0) {
      setDisableRight(false);
      setPlayingIndex((prev) => prev - 1);
      setCarouselIndex(0);
      return handleVideoOpen(
        videoList[playingIndex - 1],
        videoList[playingIndex - 1]?.user.uid || videoList[1].user.id,
        // ||  videoList[playingIndex - 1]?.patient?.id,
        videoSource
      );
    }
  };

  useEffect(() => {
    selectedVideoIndex();
  }, [videoDetails?.videoId]);

  if (isLoading || isFetchVideoLoading) return <Progress />;

  if (!videoInfo && !isLoading && !isFetchVideoLoading)
    return <div>No video available</div>;

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <Box
            className='flexBox flexColumn'
            sx={{
              gap: 3,
            }}
          >
            <Card
              sx={{
                border: '1px solid var(--clr-theme-purple-50)',
              }}
            >
              <Carousel
                className='carouselBox'
                navButtonsAlwaysVisible
                animation='slide'
                autoPlay={false}
                duration={1000}
                index={carouselIndex}
                onChange={(newIndex) => {
                  setCarouselIndex(newIndex);
                  // setIsPlaying(false);
                  setIsRawVideoPlaying(false); // Pause raw video on carousel change
                  setIsProcessedVideoPlaying(false); // Pause processed video on carousel change
                  setIsFullProcessedVideoPlaying(false);
                }}
              >
                {/* First Slide */}
                <div style={{ height: { lg: '30vh', md: '55vh', sm: '55vh' } }}>
                  <div className='slide-label'>Raw Video</div>
                  <ReactPlayer
                    className='react-player'
                    width={'100%'}
                    url={videoInfo?.rawVideoData?.url}
                    type={videoInfo?.rawVideoData?.fileType}
                    controls={true}
                    playing={isPlaying}
                    // onPlay={() => setIsPlaying(true)}
                    onPlay={() => {
                      setIsRawVideoPlaying(true);
                      setIsProcessedVideoPlaying(false); // Pause processed video when playing raw video
                      setIsFullProcessedVideoPlaying(false); // Pause full processed video when playing raw video
                    }}
                    // onPause={() => setIsPlaying(false)}
                    onPause={() => setIsRawVideoPlaying(false)}
                  />
                </div>
                {/* Second Slide */}
                <div style={{ height: { lg: '30vh', md: '55vh', sm: '55vh' } }}>
                  <div className='slide-label'>Splice Video</div>
                  <ProcessVideo
                    videoDetails={videoInfo}
                    patientID={patientID}
                    typeOfUser={typeOfUser}
                    isPlaying={isPlaying}
                    setIsPlaying={setIsPlaying}
                    setIsRawVideoPlaying={setIsRawVideoPlaying}
                    setIsProcessedVideoPlaying={setIsProcessedVideoPlaying}
                    setIsFullProcessedVideoPlaying={
                      setIsFullProcessedVideoPlaying
                    }
                    processVideoStatusMessage={processVideoStatusMessage}
                    processVideos={processVideos}
                  />
                </div>
                {/* Third Slide */}
                <div style={{ height: { lg: '30vh', md: '55vh', sm: '55vh' } }}>
                  <div className='slide-label'>Processed Video</div>
                  <FullProcessVideo
                    videoDetails={videoInfo}
                    patientID={patientID}
                    typeOfUser={typeOfUser}
                    isPlaying={isPlaying}
                    setIsPlaying={setIsPlaying}
                    processVideoStatusMessage={processVideoStatusMessage}
                    processVideos={processVideos}
                    setIsProcessedVideoPlaying={setIsProcessedVideoPlaying}
                    setIsRawVideoPlaying={setIsRawVideoPlaying}
                    setIsFullProcessedVideoPlaying={
                      setIsFullProcessedVideoPlaying
                    }
                  />
                </div>

                {/* Fourth Slide */}
              </Carousel>
              <Button
                disabled={disableLeft}
                variant='contained'
                sx={{
                  '&.Mui-disabled': {
                    background: 'var(--clr-theme-white)',
                    color: 'var(--clr-theme-gray)',
                  },

                  background: '#9291F0',
                }}
                onClick={prevVideo}
              >
                <ArrowBackIosOutlinedIcon />
                Prev Video
              </Button>
              <Button
                disabled={disableRight}
                variant='contained'
                sx={{
                  '&.Mui-disabled': {
                    background: 'var(--clr-theme-white)',
                    color: 'var(--clr-theme-gray)',
                  },

                  background: '#9291F0',
                }}
                style={{ float: 'right' }}
                onClick={nextVideo}
              >
                Next Video
                <ArrowForwardIosOutlinedIcon />
              </Button>
            </Card>
            <Card
              sx={{
                border: '2px solid lightgray',
                width: '100%',
                height: '100%',
                textAlign: 'center',
                alignSelf: 'center',
                display: 'flex',
                flexDirection: {
                  xs: 'column', // Apply column direction on small screens
                  sm: 'row', // Apply row direction on medium and larger screens
                },
                minWidth: '450px',
                gap: 1,
                p: 1,
              }}
            >
              <Box
                className='fullWidth fullHeight'
                sx={{ p: 2, height: '50vh' }}
                border={'1px solid lightgray'}
              >
                {summary && summary.docData && (
                  <Box
                    className='fullHeight fullWidth flexBox flexColumn'
                    gap={1}
                    mb={3}
                  >
                    {isLoading && <Progress />}
                    <Typography
                      variant='h5'
                      fontFamily={'Lato'}
                      textAlign={'start'}
                      sx={{ color: 'var(--clr-theme-purple)', fontWeight: 700 }}
                    >
                      GOALS
                    </Typography>

                    <Box
                      sx={{
                        backgroundColor: 'lightgray',
                        p: 2,
                        borderRadius: 2,
                        overflowY: 'auto',
                      }}
                    >
                      {summary?.docData?.summary &&
                      summary?.docData?.summary?.BehavioralGoalsAndBaselines ? (
                        <>
                          <Typography
                            variant='h6'
                            fontFamily={'Lato'}
                            textAlign={'start'}
                            gutterBottom
                          >
                            Behavioral Goals and Baselines
                          </Typography>
                          <List>
                            {summary?.docData?.summary
                              ?.BehavioralGoalsAndBaselines?.length > 0 ? (
                              summary?.docData?.summary?.BehavioralGoalsAndBaselines?.map(
                                (goal, index) => (
                                  <ListItem key={index} divider>
                                    <ListItemText
                                      primary={
                                        <Typography
                                          variant='body1'
                                          fontFamily={'Lato'}
                                          fontWeight='bold'
                                          gutterBottom
                                        >
                                          {`Behavior #${index + 1}: ${
                                            goal?.BehaviorName
                                          }`}
                                        </Typography>
                                      }
                                      secondary={
                                        <>
                                          <Typography
                                            variant='body2'
                                            fontFamily={'Lato'}
                                          >
                                            <strong>Goal:</strong> {goal?.Goal}
                                          </Typography>
                                          <Typography
                                            variant='body2'
                                            fontFamily={'Lato'}
                                          >
                                            <strong>Baseline:</strong>{' '}
                                            {goal?.Baseline}
                                          </Typography>
                                        </>
                                      }
                                    />
                                  </ListItem>
                                )
                              )
                            ) : (
                              <Box
                                className='fullWidth fullHeight flexBox flexCenter'
                                height={'30vh'}
                              >
                                <Typography
                                  variant='body1'
                                  fontFamily={'Lato'}
                                  textAlign='center'
                                >
                                  No data available.
                                </Typography>
                              </Box>
                            )}
                          </List>
                        </>
                      ) : (
                        <Box className='fullWidth fullHeight flexBox flexCenter'>
                          <Typography
                            variant='body1'
                            fontFamily={'Lato'}
                            textAlign='center'
                          >
                            No goals or baselines available.
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Box>
                )}
              </Box>
              <Box
                className='fullWidth'
                sx={{ height: '50vh', border: '1px solid lightgray' }}
              >
                {processVideos && processVideos?.processedVideoData ? (
                  <LineChartComponent data={processVideos.processedVideoData} />
                ) : (
                  <Box
                    className='flexBox flexColumn flexCenter fullHeight Loder'
                    sx={{
                      gap: 5,
                    }}
                  >
                    <Typography variant='h5' sx={{ fontFamily: 'Lato' }}>
                      {processVideoStatusMessage}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Card>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            height: '100vh',
            overflowY: 'auto',
          }}
        >
          <Box className='flexBox flexColumn '>
            <Box className='flexBox flexCenter'>
              <Button
                fullWidth
                variant='outlined'
                onClick={activityLogHandler}
                sx={{
                  // backgroundColor: "var(--clr-theme-purple)",
                  mb: 1,
                  width: '97%',
                  ':hover': {
                    backgroundColor: 'var(--clr-theme-purple)', // Change the background color on hover
                    color: 'white',
                    border: 'none',
                  },
                }}
              >
                Activity Log
              </Button>
            </Box>
            {
              //  (typeOfUser === "DOCTOR") &&
              !feedbackData.exist ? (
                <>
                  <VideoFeedback
                    patientID={patientID}
                    videoDetails={videoInfo}
                  />
                  {/* {typeOfUser === "PATIENT" && ( */}
                  {ACM[typeOfUser]?.isPatient && (
                    <PatientReview
                      videoDetails={{ ...videoInfo, patientId: patientID }}
                      feedback={feedbackData}
                      patientNote={feedbackDataPatient}
                      patientID={patientID}
                      activityLogHandler={activityLogHandler}
                    />
                  )}
                </>
              ) : (
                <>
                  <PatientReview
                    videoDetails={{ ...videoInfo, patientId: patientID }}
                    feedback={feedbackData}
                    patientNote={feedbackDataPatient}
                    patientID={patientID}
                    activityLogHandler={activityLogHandler}
                  />
                </>
              )
            }
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PlayVideo;
