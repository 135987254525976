import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Typography,
  FormControl,
  Select,
  MenuItem,
  ListSubheader,
} from '@mui/material';
import {
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
} from 'recharts';
import AuthContext from '../../contexts/AuthContext';

const PerformanceMetricsChart = ({
  goalProgress,
  handleGoalProgress,
  filteredGoals,
  formattedChartData,
  chartDatas,
}) => {
  const [chartData, setChartData] = useState(formattedChartData);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    setChartData(formattedChartData);
  }, [formattedChartData]);

  // const filteredGoalsByAccess = filteredGoals.filter((goal) => {
  //   const isSharingEnabled =
  //     goal?.dataCollection?.ShareWithTeam?.IsSharingEnabled;
  //   const hasAccess =
  //     goal.dataCollection.ShareWithTeam?.EditAccess?.includes(
  //       user?.subRoleName
  //     ) ||
  //     goal.dataCollection.ShareWithTeam?.ViewOnlyAccess?.includes(
  //       user?.subRoleName
  //     );
  //   return isSharingEnabled && hasAccess;
  // });

  // const activeGoals = filteredGoalsByAccess?.filter((goal) => !goal.isArchived);
  // const archivedGoals = filteredGoalsByAccess?.filter((goal) => goal.isArchived);

  // Filter based on access first (ShareWithTeam)
  const filteredGoalsByAccess = filteredGoals.filter((goal) => {
    const isSharingEnabled =
      goal?.dataCollection?.ShareWithTeam?.IsSharingEnabled;
    const hasAccess =
      goal.dataCollection?.ShareWithTeam?.EditAccess?.includes(
        user?.subRoleName
      ) ||
      goal.dataCollection?.ShareWithTeam?.ViewOnlyAccess?.includes(
        user?.subRoleName
      );

    return isSharingEnabled && hasAccess;
  });

  // Filter goals into active and archived based on the `isArchived` flag
  const activeGoalsWithAccess = filteredGoalsByAccess.filter(
    (goal) => !goal.isArchived
  );
  const archivedGoalsWithAccess = filteredGoalsByAccess.filter(
    (goal) => goal.isArchived
  );

  // Now filter the goals that don't have access
  const activeGoalsWithoutAccess = filteredGoals
    .filter((goal) => {
      const isSharingEnabled =
        goal?.dataCollection?.ShareWithTeam?.IsSharingEnabled;
      const hasAccess =
        goal.dataCollection?.ShareWithTeam?.EditAccess?.includes(
          user?.subRoleName
        ) ||
        goal.dataCollection?.ShareWithTeam?.ViewOnlyAccess?.includes(
          user?.subRoleName
        );

      return !isSharingEnabled || !hasAccess;
    })
    .filter((goal) => !goal.isArchived);

  const archivedGoalsWithoutAccess = filteredGoals
    .filter((goal) => {
      const isSharingEnabled =
        goal?.dataCollection?.ShareWithTeam?.IsSharingEnabled;
      const hasAccess =
        goal.dataCollection?.ShareWithTeam?.EditAccess?.includes(
          user?.subRoleName
        ) ||
        goal.dataCollection?.ShareWithTeam?.ViewOnlyAccess?.includes(
          user?.subRoleName
        );

      return !isSharingEnabled || !hasAccess;
    })
    .filter((goal) => goal.isArchived);

  const activeGoals = [...activeGoalsWithAccess, ...activeGoalsWithoutAccess];
  const archivedGoals = [
    ...archivedGoalsWithAccess,
    ...archivedGoalsWithoutAccess,
  ];

  return (
    <>
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        }}
        minHeight={300}
      >
        <Box sx={{ flexShrink: 0 }}>
          <Typography fontFamily={'Lato'} fontWeight={'bold'} variant='h6'>
            Performance Metrics
          </Typography>
          <Box className='flexBox' alignItems={'center'}>
            <Typography fontFamily={'Lato'}>Select Metric</Typography>
            <FormControl sx={{ minWidth: 230, m: 1 }}>
              <Select
                value={goalProgress}
                onChange={handleGoalProgress}
                sx={{
                  border: '1px solid var(--clr-mindly-purple)',
                  height: '40px',
                  borderRadius: '10px',
                  boxShadow: '0px 0px 0px 0px var(--clr-mindly-purple)',
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      maxHeight: '250px',
                      overflowY: 'auto',
                      '& .MuiMenuItem-root': {
                        '&:hover': {
                          backgroundColor: 'var(--clr-mindly-purple)',
                          color: '#fff',
                        },
                      },
                    },
                  },
                }}
              >
                <MenuItem value='All Progress'>
                  <span>All Progress</span>
                </MenuItem>

                {/* Group Active Goals */}
                {activeGoals.length > 0 && (
                  <ListSubheader sx={{ fontWeight: 'bold' }}>
                    Active Goals
                  </ListSubheader>
                )}
                {activeGoals.length > 0 ? (
                  activeGoals.map((goal, index) => (
                    <MenuItem
                      key={index}
                      value={`${goal.GoalTitle}__${goal.studentName}`}
                    >
                      {goal.GoalTitle} - ({goal.studentName})
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No active goals available</MenuItem>
                )}

                {/* Group Archived Goals */}
                {archivedGoals.length > 0 && (
                  <ListSubheader sx={{ fontWeight: 'bold' }}>
                    Archived Goals
                  </ListSubheader>
                )}
                {archivedGoals.length > 0 ? (
                  archivedGoals.map((goal, index) => (
                    <MenuItem
                      key={index}
                      value={`${goal.GoalTitle}__${goal.studentName}`}
                    >
                      {goal.GoalTitle} - ({goal.studentName})
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No archived goals available</MenuItem>
                )}
              </Select>
            </FormControl>
          </Box>
        </Box>

        <Box
          sx={{
            flexGrow: 1,
            overflow: 'hidden',
            bgcolor: 'var(--clr-mindly-background)',
          }}
        >
          <ResponsiveContainer width='100%' height='100%'>
            <BarChart data={chartData} barCategoryGap={'20%'}>
              <CartesianGrid strokeDasharray='4 4' />
              <XAxis
                dataKey='goalTitle'
                tickFormatter={(tick) => {
                  const [day, month, year] = tick
                    ? tick.split('/').map(Number)
                    : [0, 0, 0];
                  return `${month.toString().padStart(2, '0')}/${day
                    .toString()
                    .padStart(2, '0')}/20${year}`;
                }}
              />
              <YAxis tickFormatter={(tick) => `${tick}%`} />
              {chartDatas.length > 0 && (
                <Tooltip
                  content={({ active, payload, label }) => {
                    if (active && payload && payload.length) {
                      const data = payload[0].payload;
                      const [day, month, year] = label
                        ? label.split('/').map(Number)
                        : [0, 0, 0];
                      const formattedDate = `${month
                        .toString()
                        .padStart(2, '0')}/${day
                        .toString()
                        .padStart(2, '0')}/20${year}`;
                      return (
                        <div
                          style={{
                            backgroundColor: 'var(--clr-theme-purple-50)',
                            padding: '10px',
                          }}
                        >
                          <Typography variant='h6' fontFamily={'Lato'}>
                            {data?.goalName}
                          </Typography>
                          <Typography fontFamily={'Lato'}>
                            Date: {formattedDate}
                          </Typography>
                          <Typography fontFamily={'Lato'}>
                            Progress: {data?.progress}%
                          </Typography>
                          <Typography variant='subtitle1' fontFamily={'Lato'}>
                            {data?.studentName}
                          </Typography>
                        </div>
                      );
                    }
                    return null;
                  }}
                />
              )}
              <Legend
                wrapperStyle={{
                  backgroundColor: 'transparent',
                  borderRadius: 3,
                  lineHeight: '20px',
                }}
              />
              <Bar
                dataKey='progress'
                fill='var(--clr-mindly-purple)'
                name={
                  goalProgress === 'All Progress'
                    ? 'Total Goal Progress Over Time'
                    : goalProgress
                }
                barSize={20}
                isAnimationActive={true}
                radius={[20, 20, 20, 20]}
              />
              {chartDatas.length === 0 && (
                <text
                  x='47%'
                  y='50%'
                  textAnchor='middle'
                  fontSize='18'
                  fill='gray'
                  fontFamily='Lato'
                >
                  No Data Available
                </text>
              )}
            </BarChart>
          </ResponsiveContainer>
        </Box>
      </Box>
    </>
  );
};

export default PerformanceMetricsChart;
