import { useQuery } from '@tanstack/react-query';
import { fetchAllAttachmentsByUser } from '../api';

const useFetchAllAttachmentsByUser = (userId, typeOfUser) => {
  return useQuery({
    queryKey: ['fetchAllAttachmentsByUser', userId, typeOfUser],
    queryFn: () => fetchAllAttachmentsByUser(userId, typeOfUser),
    enabled: !!userId || !!typeOfUser,
    staleTime: 60000,
    cacheTime: 300000,
  });
};

export default useFetchAllAttachmentsByUser;
