import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  Typography,
  IconButton,
  Chip,
  Divider,
  TextField,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useContext, useMemo, useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import dayjs from 'dayjs';
// import { ACMContext } from '../../contexts/ACMProvider';
import AuthContext from '../../contexts/AuthContext';
import useStudentsForOneTeacher from '../../hooks/useStudentsForOneTeacher';
import useAllStudentGoalsData from '../../hooks/useAllStudentGoalsData';
import DataEntryModal from '../../components/GoalData/index';
import './NewGoalDialog.css';
import { PickersDay } from '@mui/x-date-pickers';
import usePatientsForDoctor from '../../hooks/usePatientsForDoctor';
import { ACM } from '../../constants/UserRoles';

var currentDate = new Date();

var formattedDateTime = formatTime(currentDate);

function pad(n) {
  return n < 10 ? '0' + n : n;
}
function formatTime(date) {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = hours % 12 || 12;
  return `${pad(formattedHours)}:${pad(minutes)} ${ampm}`;
}

const NewGoalDialog = ({ open, onClose }) => {
  // const { ACM } = useContext(ACMContext);
  const { user, typeOfUser } = useContext(AuthContext);
  const [textReminder, setTextReminder] = useState('');
  const [selectedStudents, setSelectedStudents] = useState('');
  const [selectedPatient, setSelectedPatient] = useState('');
  const [time, setTime] = useState([formattedDateTime]);
  const [selectedDates, setSelectedDates] = useState([]);
  const [dialogPage, setDialogPage] = useState(1);
  const [selectedGoalIds, setSelectedGoalIds] = useState([]);
  const [errors, setErrors] = useState({});

  const { data: studentData = [] } = useStudentsForOneTeacher(
    user?.uid,
    typeOfUser
  );

  const { data: patientData = [] } = usePatientsForDoctor(user?.uid);

  const { data: studentGoalsData = [] } = useAllStudentGoalsData(
    user?.uid,
    typeOfUser
  );

  const selectedStudentGoals = useMemo(() => {
    if (!selectedStudents && !selectedPatient) return [];

    return studentGoalsData.filter(
      (goal) =>
        goal.id === selectedStudents?.id ||
        goal.id === selectedPatient.patientId
    );
  }, [selectedStudents, selectedPatient, studentGoalsData]);

  const goalTitles = useMemo(() => {
    if (selectedStudentGoals?.length) {
      return selectedStudentGoals.flatMap((goalData) => {
        if (Array.isArray(goalData?.dataCollection)) {
          return goalData?.dataCollection?.map((goal) => ({
            dataCollection: goal.info,
            goalTitle: goal?.info?.GoalDetails?.GoalTitle,
            goalNumber: goal?.info?.GoalDetails?.GoalNumber,
            goalId: goal?.id,
            MultipleDataPointsPerSession:
              goal?.info?.DataCollection?.MultipleDataPointsPerSession
                ?.MultipleDataPointsPerSession,
            measurementDuration:
              goal?.info?.DataCollection?.measurementType?.TargetDuration ||
              'N/A',
            measurementUnit:
              goal?.info?.DataCollection?.measurementType?.measurementUnit ||
              '',
            Target:
              goal?.info?.DataCollection?.measurementType?.Target || 'N/A',
            goalData: goal?.goalData,
            goalDate: goal?.info?.DataCollection?.ByDate,
            type: goal?.info?.DataCollection?.measurementType?.Type || 'N/A',
            trialsMax:
              goal?.info?.DataCollection?.measurementType?.TrailsValue
                ?.TrailsMax || 'N/A',
            criteria:
              goal?.info?.DataCollection?.measurementType?.Criteria || 'N/A',
          }));
        }
        return [];
      });
    } else if (studentGoalsData) {
      // Extracting from studentData when selectedStudentGoals is empty or undefined
      return studentGoalsData.map((goalData) => ({
        goalTitle: goalData?.studentData?.GoalDetails?.GoalTitle,
        goalNumber: goalData?.studentData?.GoalDetails?.GoalNumber,
        goalId: goalData?.docId,
        MultipleDataPointsPerSession:
          goalData?.studentData?.DataCollection?.MultipleDataPointsPerSession
            ?.MultipleDataPointsPerSession,
        measurementDuration:
          goalData?.studentData?.DataCollection?.measurementType
            ?.TargetDuration || 'N/A',
        measurementUnit:
          goalData?.studentData?.DataCollection?.measurementType
            ?.measurementUnit || '',
        Target:
          goalData?.studentData?.DataCollection?.measurementType?.Target ||
          'N/A',
        goalData: goalData?.studentData?.goalData,
        goalDate: goalData?.studentData?.DataCollection?.ByDate,
        type: goalData?.info?.DataCollection?.measurementType?.Type || 'N/A',
      }));
      return [];
    }
    return [];
  }, [selectedStudentGoals, studentData]);

  const handleGoalSelections = (event) => {
    const selectedValues = event.target.value;

    const updatedGoals = (goalTitles || [])
      .filter((goal) => selectedValues.includes(goal.goalId))
      .map((goal) => {
        if (goal?.dataCollection?.HasSubgoal?.HasSubgoal === 'No') {
          return {
            goalId: goal.goalId || '',
            goalTitle: goal.goalTitle || '',
            goalNumber: goal.goalNumber || '',
            MultipleDataPointsPerSession:
              goal.MultipleDataPointsPerSession || false,
            measurementDuration: goal.measurementDuration || 'N/A',
            measurementUnit:
              goal.type === 'Frequency'
                ? 'times'
                : goal.type === 'Duration'
                ? 'mins'
                : goal.type === 'Percentage'
                ? '%'
                : goal.measurementUnit || '',
            Target: goal.Target || 'N/A',
            goalData: goal.goalData || [],
            goalDate: goal.goalDate || '',
            goalType: goal.type || 'N/A',
            prompting:
              goal?.dataCollection?.DataCollection?.PromptingApplicable
                ?.PromptingType || null,
            criteria:
              goal?.dataCollection?.DataCollection?.measurementType?.Criteria ||
              null,
            trialsMax: goal.trialsMax || 'N/A',
          };
        } else {
          let index = 1;
          const subgoals = goal?.dataCollection?.HasSubgoal?.Subgoals || [];
          for (const item of subgoals) {
            if (item?.CurrentGoal === 'Yes') {
              return {
                subGoalIndex: index,
                goalId: goal.goalId || '',
                goalTitle: goal.goalTitle || '',
                goalNumber: goal.goalNumber || '',
                MultipleDataPointsPerSession:
                  goal.MultipleDataPointsPerSession || false,
                measurementDuration: goal.measurementDuration || 'N/A',
                measurementUnit:
                  goal.type === 'Frequency'
                    ? 'times'
                    : goal.type === 'Duration'
                    ? 'mins'
                    : goal.type === 'Percentage'
                    ? '%'
                    : goal.measurementUnit || '',
                Target: goal.Target || 'N/A',
                goalData: goal.goalData || [],
                goalDate: goal.goalDate || '',
                goalType: goal.type || 'N/A',
                prompting:
                  goal?.dataCollection?.DataCollection?.PromptingApplicable
                    ?.PromptingType || null,
                criteria:
                  goal?.dataCollection?.DataCollection?.measurementType
                    ?.Criteria || null,
                trialsMax: goal.trialsMax || 'N/A',
              };
            }
            index++;
          }
          return {
            goalId: goal.goalId || '',
            goalTitle: goal.goalTitle || '',
            goalNumber: goal.goalNumber || '',
            MultipleDataPointsPerSession:
              goal.MultipleDataPointsPerSession || false,
            measurementDuration: goal.measurementDuration || 'N/A',
            measurementUnit:
              goal.type === 'Frequency'
                ? 'times'
                : goal.type === 'Duration'
                ? 'mins'
                : goal.type === 'Percentage'
                ? '%'
                : goal.measurementUnit || '',
            Target: goal.Target || 'N/A',
            goalData: goal.goalData || [],
            goalDate: goal.goalDate || '',
            goalType: goal.type || 'N/A',
            prompting:
              goal?.dataCollection?.DataCollection?.PromptingApplicable
                ?.PromptingType || null,
            criteria:
              goal?.dataCollection?.DataCollection?.measurementType?.Criteria ||
              null,
            trialsMax: goal.trialsMax || 'N/A',
          };
        }
      });

    setSelectedGoalIds(updatedGoals);
  };

  const truncateName = (name, limit = 10) => {
    const capitalized = capitalizeWords(name);
    if (capitalized?.length > limit) {
      return `${capitalized.slice(0, limit)}...`;
    }
    return capitalized;
  };

  const capitalizeWords = (name) => {
    return name
      ?.split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  const formatDate = (date) => {
    return dayjs(date).format('YYYY-MM-DD');
  };

  const handleStudentChange = (e) => {
    setSelectedStudents(e.target.value);
  };

  const handlePatientChange = (e) => {
    setSelectedPatient(e.target.value);
  };

  const handleDateChange = (date) => {
    const formattedDate = formatDate(date);
    const isSelected = selectedDates.includes(formattedDate);

    if (isSelected) {
      const updatedDates = selectedDates.filter((d) => d !== formattedDate);
      setSelectedDates(updatedDates);
    } else {
      setSelectedDates([...selectedDates, formattedDate]);
    }
  };

  const handleTextChangeHandler = (e) => {
    setTextReminder(e.target.value);
  };
  const deleteDatePicker = (indexToDelete) => {
    const updatedDates = selectedDates.filter(
      (_, index) => index !== indexToDelete
    );
    setSelectedDates(updatedDates);
  };

  const updateTime = (index, newTime) => {
    try {
      const formattedNewTime = formatTime(new Date(newTime));
      const updatedTimes = [...time];
      updatedTimes[index] = formattedNewTime;
      setTime(updatedTimes);
    } catch (error) {
      console.error('Error updating time:', error);
    }
  };

  const deleteTimePicker = (indexToDelete) => {
    const updatedTimes = time.filter((_, index) => index !== indexToDelete);
    setTime(updatedTimes);
  };

  const addTimePicker = () => {
    setTime([...time, formattedDateTime]);
  };

  const validateForm = () => {
    const validationErrors = {};

    const isDoctor = ACM[typeOfUser]?.isDoctor;

    setErrors(validationErrors);

    return Object.keys(validationErrors).length === 0;
  };

  const handleNext = () => {
    if (validateForm()) {
      setDialogPage(2);
    }
  };

  return (
    <>
      {ACM[typeOfUser]?.isAddNewGoals && (
        <>
          <Dialog
            open={open}
            onClose={onClose}
            className='fullWidth fullHeight flexBox flexColumn'
            sx={{
              '& .MuiDialog-paper': {
                width: '50vw',
                borderRadius: '24px',
                backgroundColor: '#f6f7fc',
              },
            }}
          >
            <DialogContent style={{ padding: '0', borderRadius: '10px' }}>
              {dialogPage === 1 ? (
                <>
                  <Box
                    className='fullWidth fullHeight flexBox flexColumn Reminders'
                    gap={2}
                    sx={{ p: 3 }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                      }}
                    >
                      <Typography
                        variant='h4'
                        gutterBottom
                        sx={{ fontFamily: 'Lato', fontWeight: '700' }}
                      >
                        DATA ENTRY
                      </Typography>
                    </Box>

                    <Box
                      display='flex'
                      alignItems='center'
                      gap={1}
                      sx={{ flexWrap: 'wrap' }}
                    >
                      {ACM[typeOfUser]?.isDoctor && (
                        <>
                          <Typography
                            id='demo-select-small-label'
                            variant='p'
                            gutterBottom
                            sx={{ color: 'var(--clr-mindly-black)' }}
                          >
                            Select Patient
                          </Typography>
                          <FormControl
                            sx={{
                              minWidth: 250,
                              padding: 0,
                              backgroundColor: '#fff',
                              borderRadius: '10px',
                            }}
                          >
                            <Select
                              labelId='demo-select-small-label'
                              id='demo-select-small'
                              value={selectedPatient || null} // ✅ Store complete patient object
                              onChange={handlePatientChange}
                              inputProps={{ 'aria-label': 'Without label' }}
                              MenuProps={{
                                PaperProps: {
                                  sx: {
                                    maxHeight: 200, // Adjust this value as needed
                                  },
                                },
                              }}
                              renderValue={(selectedPatientObj) =>
                                selectedPatientObj ? (
                                  <Chip
                                    sx={{
                                      backgroundColor: '#9291f0',
                                      color: 'white',
                                    }}
                                    label={truncateName(
                                      selectedPatientObj.patientInfo.fullname
                                    )}
                                  />
                                ) : (
                                  'Select a patient'
                                )
                              }
                              sx={{ height: 40, borderRadius: '10px' }}
                            >
                              {patientData?.map((patient) => (
                                <MenuItem
                                  key={patient.patientId}
                                  value={patient}
                                >
                                  {patient?.patientInfo?.parentName
                                    ? `${truncateName(
                                        patient.patientInfo.fullname
                                      )} (${truncateName(
                                        patient.patientInfo.parentName
                                      )})`
                                    : `${truncateName(
                                        patient.patientInfo.fullname
                                      )}`}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </>
                      )}
                      {ACM[typeOfUser]?.isTeacher && (
                        <>
                          <Typography
                            id='demo-select-small-label'
                            variant='p'
                            gutterBottom
                            sx={{ color: 'var(--clr-mindly-black)' }}
                          >
                            Select Student
                          </Typography>
                          <FormControl
                            sx={{
                              minWidth: 250,
                              padding: 0,
                              backgroundColor: '#fff',
                              borderRadius: '10px',
                            }}
                          >
                            <Select
                              labelId='demo-select-small-label'
                              id='demo-select-small'
                              value={selectedStudents}
                              onChange={handleStudentChange}
                              inputProps={{ 'aria-label': 'Without label' }}
                              renderValue={(selected) =>
                                selected ? (
                                  <Chip
                                    sx={{
                                      backgroundColor: '#9291f0',
                                      color: 'white',
                                    }}
                                    label={selected.name}
                                  />
                                ) : (
                                  'Select a student'
                                )
                              }
                              sx={{ height: 40, borderRadius: '10px' }}
                            >
                              {studentData.map((student) => (
                                <MenuItem key={student.id} value={student}>
                                  {student.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </>
                      )}
                    </Box>

                    {/* {selectedStudents && ( */}
                    <Box
                      className='flexBox'
                      alignItems={'center'}
                      gap={2.7}
                      flexWrap={'wrap'}
                    >
                      <Typography variant='body1' gutterBottom>
                        Select Goals
                      </Typography>
                      <FormControl
                        sx={{
                          minWidth: 250,
                          padding: 0,
                          backgroundColor: '#fff',
                          borderRadius: '10px',
                        }}
                      >
                        <Select
                          multiple
                          value={selectedGoalIds.map((goal) => goal.goalId)}
                          onChange={handleGoalSelections}
                          displayEmpty
                          renderValue={(selected) =>
                            selected.length ? (
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexWrap: 'wrap',
                                  gap: 0.5,
                                }}
                              >
                                {selected.map((goalId) => {
                                  const goal = goalTitles.find(
                                    (g) => g.goalId === goalId
                                  );
                                  return goal ? (
                                    <Chip
                                      key={goalId}
                                      label={goal.goalTitle}
                                      sx={{
                                        backgroundColor: '#9291f0',
                                        color: 'white',
                                      }}
                                    />
                                  ) : null;
                                })}
                              </Box>
                            ) : (
                              ''
                            )
                          }
                          sx={{ height: 40, borderRadius: '10px' }}
                        >
                          {goalTitles.length > 0 ? (
                            goalTitles.map((goal) => {
                              // Filter logic to check access and sharing
                              const isSharingEnabled =
                                goal?.dataCollection?.ShareWithTeam
                                  ?.IsSharingEnabled;
                              const hasAccess =
                                goal?.dataCollection?.ShareWithTeam?.EditAccess?.includes(
                                  user?.subRoleName
                                ) ||
                                goal?.dataCollection?.ShareWithTeam?.ViewOnlyAccess?.includes(
                                  user?.subRoleName
                                );

                              // Conditional check for user types
                              if (
                                ACM[typeOfUser]?.isTeacher ||
                                ACM[typeOfUser]?.isDoctor
                              ) {
                                // Teachers and Doctors can see goals if sharing is enabled or they have access
                                if (isSharingEnabled || hasAccess) {
                                  return (
                                    <MenuItem
                                      key={goal.goalId}
                                      value={goal.goalId}
                                    >
                                      {goal.goalTitle}
                                    </MenuItem>
                                  );
                                }
                              } else {
                                // For other users, show all available goals
                                return (
                                  <MenuItem
                                    key={goal.goalId}
                                    value={goal.goalId}
                                  >
                                    {goal.goalTitle}
                                  </MenuItem>
                                );
                              }
                              return null;
                            })
                          ) : (
                            <MenuItem disabled>No goals available</MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </Box>

                    <Divider
                      sx={{ my: 1, borderBottomWidth: 2, bgcolor: 'black' }}
                    />

                    <Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'flex-start',
                          justifyContent: 'space-between',
                          width: '100%',
                          flexWrap: 'wrap',
                          gap: 2,
                        }}
                      >
                        <Typography gutterBottom>Select Date :</Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <StaticDatePicker
                            displayStaticWrapperAs='desktop'
                            value={selectedDates}
                            onChange={handleDateChange}
                            shouldDisableDate={(date) =>
                              dayjs(date).isAfter(dayjs(), 'day')
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                sx={{
                                  backgroundColor: 'white',
                                  border: 'none',
                                  borderRadius: '5px',
                                  '& .MuiPickersDay-root': {
                                    borderRadius: '50%',
                                  },
                                }}
                              />
                            )}
                            renderDay={(day, _, pickersProps) => {
                              const isSelected = selectedDates.some((date) =>
                                dayjs(date).isSame(day, 'day')
                              );

                              return (
                                <PickersDay
                                  {...pickersProps}
                                  day={day}
                                  selected={isSelected}
                                />
                              );
                            }}
                            className='custom-calendar'
                          />
                        </LocalizationProvider>
                      </Box>
                      {selectedDates?.length > 0 && (
                        <div>
                          Selected Dates:
                          <ul>
                            {selectedDates?.map((date, index) => (
                              <li key={index}>
                                {date}
                                <IconButton
                                  onClick={() => deleteDatePicker(index)}
                                >
                                  <DeleteIcon color='error' />
                                </IconButton>
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </Box>

                    {errors.selectedDates && (
                      <Typography color='error'>
                        {errors.selectedDates}
                      </Typography>
                    )}
                  </Box>
                  <Box
                    className='flexBox flexColumn'
                    sx={{ width: '100%', gap: 2, mt: 2 }}
                  ></Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '100%',
                    }}
                  >
                    <Button
                      disabled={
                        selectedDates.length === 0 ||
                        (!ACM[typeOfUser]?.isDoctor &&
                          !selectedStudents?.length === 0) ||
                        (ACM[typeOfUser]?.isDoctor &&
                          !selectedPatient.length === 0) ||
                        time.length === 0
                      }
                      sx={{
                        backgroundColor:
                          selectedDates.length === 0 ||
                          (!ACM[typeOfUser]?.isDoctor &&
                            !selectedStudents?.length === 0) ||
                          (ACM[typeOfUser]?.isDoctor &&
                            !selectedPatient.length === 0) ||
                          time.length === 0
                            ? 'lightgray'
                            : 'var(--clr-theme-purple)',
                        '&:hover': {
                          background:
                            selectedDates.length === 0 ||
                            (!ACM[typeOfUser]?.isDoctor &&
                              !selectedStudents?.length === 0) ||
                            (ACM[typeOfUser]?.isDoctor &&
                              !selectedPatient.length === 0) ||
                            time.length === 0
                              ? 'lightgray'
                              : 'var(--clr-theme-purple-50)',
                        },
                        color: 'var(--clr-theme-white)',
                        fontFamily: 'Lato',
                        width: '20%',
                        marginBottom: '20px',
                        textTransform: 'capitalize',
                        fontWeight: '700',
                      }}
                      onClick={handleNext}
                    >
                      Next
                    </Button>
                  </Box>
                </>
              ) : (
                <Box sx={{ padding: '20px' }}>
                  <Box
                    sx={{
                      position: 'sticky',
                      zIndex: 100,
                      width: '100%',
                      margin: 0,
                      padding: '10px 0px',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                      }}
                    >
                      <Typography
                        variant='h4'
                        gutterBottom
                        sx={{ fontFamily: 'Lato', marginBottom: 3 }}
                      >
                        DATA ENTRY
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ flex: 1, overflowY: 'auto' }}>
                    {selectedGoalIds?.map((goal) => {
                      const goalData = goal?.goalData;
                      const validGoalData = Array.isArray(goalData)
                        ? goalData
                        : [];

                      const currentTime = Math.floor(Date.now() / 1000);
                      const tenDaysAgo = currentTime - 10 * 24 * 60 * 60;
                      const countLast10Days = validGoalData.filter(
                        (entry) => entry.createdAt._seconds >= tenDaysAgo
                      ).length;

                      return selectedDates.map((date, index) => (
                        <Box key={`${goal.goalId}-${index}`} mb={2}>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Typography>
                              Student Name:{' '}
                              {ACM[typeOfUser]?.isDoctor ||
                              ACM[typeOfUser]?.isTeacher
                                ? selectedStudents?.name ||
                                  selectedPatient?.patientInfo?.fullname
                                : user?.fullname}
                            </Typography>
                            <Typography>DATE: {date}</Typography>
                          </Box>
                          <Box mb={2}>
                            <Divider
                              sx={{ borderBottomWidth: 2, bgcolor: 'black' }}
                            />
                          </Box>

                          <DataEntryModal
                            open={open}
                            onClose={onClose}
                            goalId={goal.goalId}
                            goalTitle={goal.goalTitle}
                            goalNumber={goal.goalNumber}
                            goalData={validGoalData}
                            goalDate={date} // Pass selected date
                            countLast10Days={countLast10Days}
                            MultipleDataPointsPerSession={
                              goal.MultipleDataPointsPerSession
                            }
                            measurementDuration={goal.measurementDuration}
                            measurementUnit={goal.measurementUnit}
                            target={goal.Target}
                            studentId={
                              selectedStudents?.id ||
                              selectedPatient?.patientId ||
                              user?.uid
                            }
                            studentName={
                              selectedStudents?.name ||
                              selectedPatient?.patientInfo?.fullname ||
                              user?.fullname
                            }
                            selectedGoalIds={selectedGoalIds}
                            selectedStudents={
                              selectedStudents || selectedPatient
                            }
                            selectedDates={selectedDates}
                            time={time}
                            goalType={goal.goalType || 'N/A'}
                            subGoalIndex={goal.subGoalIndex || null}
                            promptingType={goal.prompting || null}
                            criteria={goal.criteria || null}
                            trialsMax={goal.trialsMax || 'N/A'}
                          />
                        </Box>
                      ));
                    })}
                  </Box>
                </Box>
              )}
            </DialogContent>
          </Dialog>
        </>
      )}
    </>
  );
};

export default NewGoalDialog;
