import { useQuery } from '@tanstack/react-query';
import { getPreRegisteredUserForTeacher } from '../api';

const useFetchPreRegisteredUserForTeacher = (userId) => {
  return useQuery({
    queryKey: ['getPreRegisteredUserForTeacher', userId],
    queryFn: async () => {
      // Fetching data using the API function
      const fetchedData = await getPreRegisteredUserForTeacher(userId);

      return (
        fetchedData?.map(student => ({
          id: student.id,
          teacherId: student.teacherId || student.teacherID || '',
          name: student.info.name || student.info.fullname || '',
          email: student.info.email,
          parentName: student.info.parentName,
          dob: student.info.dob || '',
          gender: student.info.gender || '',
          grade: student.info.grade || '',
          contactNo: student.info.contactNo || '',
          photoURL: student.info?.photoURL || null,
        })) || []
      );
    },
    staleTime: 5000, // 5 seconds, data will be considered stale quickly
    cacheTime: 60000, // Cache data for 1 minute
    onError: (error) => {
      console.error("Error fetching pre-registered users:", error);
    },
  });
};

export default useFetchPreRegisteredUserForTeacher;
