import { Button } from "@mui/material";
import React from "react";

import "./CustomButton.css";

const CustomButton = ({
  variant,
  children,
  color,
  ChangeHandler,
  disabled,
  size,
  customClass = "",
}) => {
  return (
    <Button
      fullWidth
      className={`${customClass || "customButton"}`}
      sx={{
        width: "100%",
        color: "var(--clr-theme-white)!important",
        textTransform: "initial",
        height: "3rem",
        fontSize: "1rem",
        lineHeight: "20px",
      }}
      variant={variant}
      color={color}
      onClick={ChangeHandler}
      disabled={disabled}
      size={size}
    >
      {children}
    </Button>
  );
};

export default CustomButton;
