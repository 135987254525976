import { useContext, useEffect, useState, useMemo, useRef } from 'react';
import { Card, Typography, Grid, Box } from '@mui/material';
import { useLocation } from 'react-router-dom';

import GoalData from './GoalData';
import DataPoints from './DataPoints';
import GoalGraph from './GoalGraph';
import MainLayout from '../../hoc/MainLayout';
import useAllStudentGoalsData from '../../hooks/useAllStudentGoalsData';
import AuthContext from '../../contexts/AuthContext';
import { ACMContext } from '../../contexts/ACMProvider';

const ViewGoal = () => {
  const location = useLocation();
  const { goalData, goals, setviewGoalEdit } = location.state || {};
  const { ACM } = useContext(ACMContext);
  const { user, typeOfUser } = useContext(AuthContext);

  const goalDataCardRef = useRef(null);
  const dataPointsCardRef = useRef(null);
  const [cardHeight, setCardHeight] = useState(null);

  console.log('goalData', goalData);

  const {
    data: studentsGoalData = [],
    isLoading: isGoalsLoading,
    error: goalsError,
    refetch: goalsRefetch,
  } = useAllStudentGoalsData(user?.uid, typeOfUser);

  const goalId = goals?.goalId;

  const specificGoalData = useMemo(() => {
    if (!studentsGoalData || studentsGoalData.length === 0) return null;

    const goalCollection = studentsGoalData.flatMap((student) =>
      Array.isArray(student.dataCollection)
        ? student.dataCollection.filter(
            (collection) => collection?.id === goalId
          )
        : []
    );

    return goalCollection[0]?.goalData || [];
  }, [studentsGoalData, goalId]);

  console.log('specificGoalData', specificGoalData);

  const selectedGoalDataPoints = useMemo(() => {
    if (!specificGoalData || !Array.isArray(specificGoalData)) return [];

    return specificGoalData.map((dataEntry) => {
      const createdAtDate = new Date(
        (dataEntry.createdAt?._seconds || dataEntry.createdAt?.seconds) * 1000
      ).toLocaleDateString('en-CA');
      return {
        date: createdAtDate,
        dataPoints: dataEntry,
        goalCollectionId: goals?.goalId,
      };
    });
  }, [specificGoalData, goals]);

  const getLast7Days = () => {
    return Array.from({ length: 7 }, (_, i) => {
      const date = new Date();
      date.setDate(date.getDate() - (6 - i));
      return date.toLocaleDateString('en-CA');
    });
  };

  const goalTitle = goals?.GoalTitle;
  const studentId = goals?.studentId;
  const measurementType = goalData?.DataCollection?.measurementType?.Type;
  const measurementUnit =
    goalData?.DataCollection?.measurementType?.measurementUnit;
  const dataPointPerSession =
    goals?.dataCollection?.DataCollection?.MultipleDataPointsPerSession
      ?.MultipleDataPointsPerSession;
  const targetDuration =
    goalData?.DataCollection?.measurementType?.TargetDuration;
  const target = goalData?.DataCollection?.measurementType?.Target;

  const [graphData, setGraphData] = useState([]);
  const [lastGoalValue, setLastGoalValue] = useState(null);

  const isTrials = goalData?.DataCollection?.measurementType?.Type === 'Trials';
  const typeOfOperation =
    goals?.dataCollection?.DataCollection?.MultipleDataPointsPerSession
      ?.DataPointsPerSession;
  const goalWiseData = useMemo(
    () => ({
      studentId,
      goalTitle,
      targetDuration,
      target,
      typeOfOperation,
      dates: {},
    }),
    [studentId, goalTitle, targetDuration, target, typeOfOperation]
  );

  const processGoalData = () => {
    if (!Array.isArray(specificGoalData) || specificGoalData.length === 0)
      return;

    goalWiseData.dates = {};

    specificGoalData.forEach((dataEntry) => {
      let entryDate = null;

      try {
        const createdAt = dataEntry.createdAt;
        if (createdAt) {
          entryDate = createdAt._seconds
            ? new Date(createdAt._seconds * 1000).toLocaleDateString('en-CA')
            : new Date(createdAt).toLocaleDateString('en-CA');
        }
      } catch (error) {
        console.log('Error parsing date:', error);
      }

      if (!entryDate) return;

      if (!goalWiseData.dates[entryDate]) {
        goalWiseData.dates[entryDate] = [];
      }

      const average = (numbers) =>
        numbers.length && numbers.every((num) => !isNaN(Number(num)))
          ? numbers.reduce((sum, num) => sum + Number(num), 0) / numbers.length
          : null;

      const dataPoints = dataEntry?.info?.data_points || [];

      dataPoints.forEach((point) => {
        if (!point) return;

        let goalValue = null;

        if (measurementType !== 'Trials') {
          goalValue = !isNaN(Number.parseFloat(point.goal_value || 0))
            ? Number.parseFloat(point.goal_value)
            : null;
        } else {
          if (
            Array.isArray(point.trialsNumber) &&
            point.trialsNumber.length > 0
          ) {
            goalValue = average(point.trialsNumber);
          } else if (
            Array.isArray(point.trials_number) &&
            point.trials_number.length > 0
          ) {
            goalValue = average(point.trials_number);
          }

          if (
            goalValue === null &&
            !isNaN(Number.parseFloat(point.goal_value || 0))
          ) {
            goalValue = Number.parseFloat(point.goal_value);
          }
        }

        if (goalValue !== null && !isNaN(goalValue)) {
          goalWiseData.dates[entryDate].push(goalValue);
        }
      });
    });

    Object.keys(goalWiseData.dates).forEach((date) => {
      const values = goalWiseData.dates[date];
      if (values.length > 1) {
        switch (typeOfOperation) {
          case 'Average':
            goalWiseData.dates[date] = [
              values.reduce((a, b) => a + b, 0) / values.length,
            ];
            break;
          case 'Add':
            goalWiseData.dates[date] = [values.reduce((a, b) => a + b, 0)];
            break;
          case 'Take Highest':
            goalWiseData.dates[date] = [Math.max(...values)];
            break;
          case 'Take Lowest':
            goalWiseData.dates[date] = [Math.min(...values)];
            break;
          default:
            break;
        }
      }
    });

    const last7Days = getLast7Days();
    const allDates = Object.keys(goalWiseData.dates).sort();
    const sortedDates = Object.keys(goalWiseData.dates).sort(
      (a, b) => new Date(b) - new Date(a)
    );
    const mostRecentDate = sortedDates[0];
    const mostRecentValue = goalWiseData.dates[mostRecentDate]?.[0] || null;
    setLastGoalValue(mostRecentValue);

    const combinedDates = Array.from(
      new Set([...allDates, ...last7Days])
    ).sort();
    const formattedData = combinedDates.map((date) => ({
      date,
      goalValue: goalWiseData.dates[date]?.[0] || 0,
    }));

    const today = new Date();
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(today.getDate() - 30);

    const filteredData = formattedData.filter(({ date }) => {
      const dataDate = new Date(date);
      return dataDate >= thirtyDaysAgo;
    });

    setGraphData(filteredData);
  };

  useEffect(() => {
    processGoalData();
  }, [specificGoalData, goals, goalData]);

  const adjustCardHeights = () => {
    if (goalDataCardRef.current && dataPointsCardRef.current) {
      goalDataCardRef.current.style.height = 'auto';
      dataPointsCardRef.current.style.height = 'auto';

      const height1 = goalDataCardRef.current.scrollHeight;
      const height2 = dataPointsCardRef.current.scrollHeight;

      const maxHeight = Math.min(Math.max(height1, height2), 450);
      setCardHeight(maxHeight);
    }
  };

  useEffect(() => {
    adjustCardHeights();

    const handleResize = () => {
      adjustCardHeights();
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      adjustCardHeights();
    }, 100);

    return () => clearTimeout(timer);
  }, [specificGoalData, goalData, goals]);

  return (
    <MainLayout>
      <Box sx={{ margin: '0 auto' }}>
        <Typography variant='h5' sx={{ marginBottom: 2, fontWeight: 500 }}>
          {goals?.GoalTitle}
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} md={7}>
            <Card
              ref={goalDataCardRef}
              sx={{
                padding: 2,
                backgroundColor: '#e9eaff',
                height: cardHeight ? `${cardHeight}px` : 'auto',
                display: 'flex',
                flexDirection: 'column',
                maxHeight: '450px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                  overflowY: 'auto',
                  maxHeight: '100%',
                }}
              >
                <GoalData goalData={goalData} goals={goals} />
              </Box>
            </Card>
          </Grid>

          <Grid item xs={12} md={5}>
            <Card
              ref={dataPointsCardRef}
              sx={{
                padding: 2,
                backgroundColor: '#e9eaff',
                height: cardHeight ? `${cardHeight}px` : 'auto',
                display: 'flex',
                flexDirection: 'column',
                maxHeight: '450px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                  overflowY: 'auto',
                  maxHeight: '100%',
                }}
              >
                <DataPoints
                  data={specificGoalData}
                  measurementType={measurementType}
                  goals={goals}
                  studentDataPoints={selectedGoalDataPoints}
                  measurementUnit={measurementUnit}
                  setviewGoalEdit={setviewGoalEdit}
                />
              </Box>
            </Card>
          </Grid>
        </Grid>

        <Box sx={{ marginTop: 2 }}>
          <Card sx={{ padding: 2 }}>
            <GoalGraph
              goalTitle={goalTitle}
              studentId={studentId}
              graphData={graphData}
              targetDuration={targetDuration}
              studentDataPoints={selectedGoalDataPoints}
              measurementType={measurementType}
              measurementUnit={measurementUnit}
              dataPointPerSession={dataPointPerSession}
              setviewGoalEdit={setviewGoalEdit}
            />
          </Card>
        </Box>
      </Box>
    </MainLayout>
  );
};

export default ViewGoal;
