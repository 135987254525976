import { useContext, useState, useEffect } from 'react';
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  FormControlLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  tooltipClasses,
  Typography,
  Box,
  Button,
} from '@mui/material';
import { CheckCircleOutlineRounded, Attachment } from '@mui/icons-material';
import { doc, Timestamp, setDoc, addDoc, collection } from 'firebase/firestore';
import BrandLogo from '../../components/BrandLogo';
import { ref } from 'firebase/storage';
import AuthContext from '../../contexts/AuthContext';
import { styled } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import Progress from '../../components/Progress';
import { MobileDateTimePicker } from '@mui/x-date-pickers';
import { v4 as uuidv4 } from 'uuid';
import { FirebaseStorage, FirestoreDB } from '../../utils/firebase/firebase';
import { UploadFileToStorage } from '../../utils/firebase/helper';
import usePatientsForDoctor from '../../hooks/usePatientsForDoctor';
import { ACMContext } from '../../contexts/ACMProvider';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useStudentsForOneTeacher from '../../hooks/useStudentsForOneTeacher';
import useFetchPreRegisteredUserForTeacher from '../../hooks/useFetchPreRegisteredUserForTeacher';
import useFetchAllAttachmentsByUser from '../../hooks/useFetchAllAttachmentsByUser';

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontWeight: 'bold',
    padding: '10px',
  },
}));

const documentsType = [
  { value: 'Assessment', label: 'Assessment' },
  { value: 'Summary', label: 'Summary' },
  { value: 'Score', label: 'Score' },
];

const UploadAttachment = ({
  open: isOpen,
  handleClose: externalHandleClose,
}) => {
  const { ACM } = useContext(ACMContext);
  const { user, typeOfUser } = useContext(AuthContext);

  const [uploadedFile, setUploadedFile] = useState(null);
  const [patientData, setPatientData] = useState([]);
  const [studentData, setStudentData] = useState([]);
  const [SelectedPatient, SetSelectedPatient] = useState('');
  const [selectedStudent, setSelectedStudent] = useState('');
  const [customFileName, setCustomFileName] = useState('');
  const [isPrivate, setIsPrivate] = useState(false);
  const [isPrivateAll, setIsPrivateAll] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [attachmentsData, setAttachmentsData] = useState([]);
  const [information, setInformation] = useState({ notes: '' });
  const [date, setDate] = useState(null);
  const [error, setError] = useState({ show: false, message: '' });
  const [typeOfDocument, setTypeOfDocument] = useState('');
  const [showDialog, setShowDialog] = useState(false);
  const [newPatient, setNewPatient] = useState(null);
  const [newStudent, setNewStudent] = useState(null);

  const { data: patients = [], isLoading: isPatientDataLoading } =
    usePatientsForDoctor(user?.uid);

  const { data: students = [], isLoading: isStudentDataLoading } =
    useStudentsForOneTeacher(user?.uid, typeOfUser);

  const { data: preRegisteredUsers = [] } = useFetchPreRegisteredUserForTeacher(
    user?.uid
  );

  const { refetch: refetchAllAttachments } = useFetchAllAttachmentsByUser(
    user?.uid,
    typeOfUser
  );

  const dateChangeHandler = (newValue) => {
    if (newValue && newValue._d <= new Date()) {
      setDate(newValue._d);
      setError({
        show: false,
        message: '',
      });
    } else {
      setDate(null);
      setError({
        show: true,
        message:
          'Select Valid Date, Document date cannot be greater than current time.',
      });
    }
  };

  const showToastMessage = (status) => {
    if (status === 'success') {
      toast.success('Your upload was successful', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      toast.error('Your upload was Failed', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  const handleClose = () => {
    externalHandleClose();
    setCustomFileName('');
    setTypeOfDocument('');
    setUploadedFile(null);
    setDate(null);
    SetSelectedPatient('');
    setSelectedStudent('');
    setInformation({ notes: '' });
    setIsPrivate(false);
    setIsPrivateAll(false);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    file && setUploadedFile(file);
  };

  const validateInputsForAttachment = () => {
    const errors = {};

    if (!uploadedFile) {
      errors.uploadedFile = 'Please select a file to upload.';
    }

    if (!date) {
      errors.date = 'Please select a valid date.';
    }

    if (date >= new Date()) {
      errors.uploadedFile = 'Please select a valid date.';
    }

    if (!typeOfDocument) {
      errors.typeOfDocument = 'Please select a document type.';
    }

    if (
      (ACM[typeOfUser]?.isDoctor && !SelectedPatient) ||
      (ACM[typeOfUser]?.isTeacher && !selectedStudent)
    ) {
      errors.selectedPerson = 'Please select a patient or student.';
    }

    const isValid = Object.keys(errors).length === 0;
    return { isValid, errors };
  };

  // Upload attachment file
  const attachmentUploadFile = async (id) => {
    try {
      const validation = validateInputsForAttachment();
      if (validation.isValid) {
        setIsLoading(true);
        setError({
          show: false,
          message: '',
        });

        const userId =
          ACM[typeOfUser]?.isDoctor || ACM[typeOfUser]?.isTeacher
            ? id
            : user?.uid;

        const acceptedFileTypes = [
          'application/pdf',
          'application/vnd.ms-excel',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          'text/csv',
        ];

        if (uploadedFile && acceptedFileTypes.includes(uploadedFile.type)) {
          const attachmentID = uuidv4();
          const blob = new Blob([uploadedFile], {
            type: uploadedFile.type,
          });

          const storageRef =
            typeOfDocument === 'Summary'
              ? ref(
                  FirebaseStorage,
                  `historicalData/${userId}/${attachmentID}/attachments/${uploadedFile.name}`
                )
              : ref(
                  FirebaseStorage,
                  `historicalData/${userId}/${attachmentID}/IEPattachments/${uploadedFile.name}`
                );

          const result = await UploadFileToStorage(storageRef, blob);

          if (result.status) {
            const url = result.data;
            const params = {
              fileName: uploadedFile.name,
              fileSize: uploadedFile.size,
              fileType: uploadedFile.type,
              url: url,
              id: attachmentID,
              userName: user?.fullname,
              userType: typeOfUser,
              typeOfDocument: typeOfDocument,
            };

            const docData = {
              info: btoa(JSON.stringify(params)),
              isEncrypted: false,
              notes: btoa(information?.notes || ''),
              isDeleted: false,
              createdAt: Timestamp.now(),
              selectedDate: date || null,
              isPrivate: ACM[typeOfUser]?.isDoctor ? isPrivate : false,
              isPrivateAll: isPrivateAll,
              uploadedId: user.uid,
              typeOfDocument: typeOfDocument,
            };

            await setDoc(doc(FirestoreDB, 'historicalData', id), {
              documentsData: [],
            });

            const collectionPath =
              typeOfDocument === 'Summary' ? 'attachments' : 'IEPattachments';

            await setDoc(
              doc(
                FirestoreDB,
                'historicalData',
                id,
                collectionPath,
                attachmentID
              ),
              docData
            );

            // Add notification if needed
            await addNotification(attachmentID, id);

            // Refresh data and show success message
            refetchAllAttachments && refetchAllAttachments();
            showToastMessage('success');
            handleClose();
          } else {
            toast.error('Error uploading file. Please try again.', {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        } else {
          toast.error(
            'Please upload only supported document formats (.pdf, .csv, .xls, .xlsx, .doc, .docx)',
            {
              position: toast.POSITION.BOTTOM_RIGHT,
            }
          );
        }

        setIsLoading(false);
      } else {
        setError({
          show: true,
          message: 'Please fill in all required fields.',
        });
      }
    } catch (error) {
      console.error('Error message: ', error);
      setIsLoading(false);
      showToastMessage('error');
    }
  };

  const addNotification = async (attachmentID, selectedId) => {
    try {
      if (ACM[typeOfUser]?.isPatient) {
        const doctors = user?.doctorDetails;
        doctors?.map(async (each) => {
          if (each.doctorId) {
            const params = {
              title: 'Patient uploaded a new document',
              description: '',
              notifyTo: each?.email,
              sentBy: user?.email,
              userName: user?.fullname,
              userPhotoURL: user?.photoURL,
              attachmentID: attachmentID,
            };
            await addDoc(
              collection(
                FirestoreDB,
                'notifications',
                each.doctorId,
                'messages'
              ),
              {
                infoMessage: btoa(JSON.stringify(params)),
                notificationSource: 'patientDocumentUploaded',
                userId: user?.uid,
                isEncrypted: false,
                readStatus: false,
                createdDate: Timestamp.now(),
              }
            );
          }
        });
      } else if (ACM[typeOfUser]?.isStudent) {
        const teacherData = user?.teacherDetails || [];
        teacherData?.map(async (each) => {
          if (each.id) {
            const params = {
              title: 'Student uploaded a new document',
              description: '',
              notifyTo: each?.email,
              sentBy: user?.email,
              userName: user?.fullname || user?.name,
              userPhotoURL: user?.photoURL,
              attachmentID: attachmentID,
            };
            await addDoc(
              collection(FirestoreDB, 'notifications', each.id, 'messages'),
              {
                infoMessage: btoa(JSON.stringify(params)),
                notificationSource: 'studentDocumentUploaded',
                userId: user?.uid,
                isEncrypted: false,
                readStatus: false,
                createdDate: Timestamp.now(),
              }
            );
          }
        });
      } else if (ACM[typeOfUser]?.isDoctor) {
        if (selectedId) {
          const selectedPatientDetails = patients.find(
            (each) => each?.patientId === selectedId
          );
          if (selectedPatientDetails) {
            const params = {
              title: 'Healthcare professional uploaded a new document',
              description: '',
              notifyTo: selectedPatientDetails?.patientInfo?.email,
              sentBy: user?.email,
              userName: user?.fullname || user?.name,
              userPhotoURL: user?.photoURL,
              attachmentID: attachmentID,
            };
            await addDoc(
              collection(
                FirestoreDB,
                'notifications',
                selectedPatientDetails.patientId,
                'messages'
              ),
              {
                infoMessage: btoa(JSON.stringify(params)),
                userId: user?.uid,
                notificationSource: 'doctorDocumentUploaded',
                isEncrypted: false,
                readStatus: false,
                createdDate: Timestamp.now(),
              }
            );
          }
        }
      } else if (ACM[typeOfUser]?.isTeacher) {
        if (selectedId) {
          const selectedStudentDetails = studentData?.find(
            (each) => each?.id === selectedId
          );
          if (selectedStudentDetails) {
            const params = {
              title: 'Teacher uploaded a new document',
              description: '',
              notifyTo: selectedStudentDetails?.email,
              sentBy: user?.email,
              userName: user?.fullname || user?.name,
              userPhotoURL: user?.photoURL,
              attachmentID: attachmentID,
            };
            await addDoc(
              collection(
                FirestoreDB,
                'notifications',
                selectedStudentDetails.id,
                'messages'
              ),
              {
                infoMessage: btoa(JSON.stringify(params)),
                userId: user?.uid,
                notificationSource: 'teacherDocumentUploaded',
                isEncrypted: false,
                readStatus: false,
                createdDate: Timestamp.now(),
              }
            );
          }
        }
      }
    } catch (e) {
      console.log('error while adding notification', e);
    }
  };

  const attachmentSaveHandler = () => {
    if (ACM[typeOfUser]?.isDoctor || ACM[typeOfUser]?.isTeacher) {
      attachmentUploadFile(
        ACM[typeOfUser]?.isDoctor ? SelectedPatient : selectedStudent
      );
    } else {
      attachmentUploadFile(user.uid);
    }
  };

  const handleDialog = () => {
    setShowDialog(false);
  };

  const handleConfirmChange = () => {
    ACM[typeOfUser]?.isDoctor
      ? SetSelectedPatient(newPatient)
      : setSelectedStudent(newStudent);
    handleDialog();
  };

  const handlePatientSelection = (e) => {
    const selectedValue = e.target.value;
    setNewPatient(selectedValue);
    SetSelectedPatient(selectedValue);
  };

  const handleStudentSelection = (e) => {
    const selectedValue = e.target.value;
    setNewStudent(selectedValue);
    setSelectedStudent(selectedValue);
  };

  useEffect(() => {
    if (patients && patients.length > 0) {
      const dataArray = patients.map((each) => ({
        ...each.patientInfo,
        id: each.patientId,
      }));
      setPatientData(dataArray);
    }
  }, [patients]);

  useEffect(() => {
    if (students) {
      const dataArray = students.map((each) => ({
        id: each.id,
        name: each.name,
        email: each.email,
        parentName: each.parentName,
        schoolName: each.schoolName,
        photoURL: each.photoURL,
      }));
      setStudentData(dataArray);
    }
  }, [students]);

  const capitalizeWords = (name) => {
    return name
      ?.split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  const truncateName = (name, limit = 10) => {
    const capitalized = capitalizeWords(name);
    if (capitalized?.length > limit) {
      return `${capitalized.slice(0, limit)}...`;
    }
    return capitalized;
  };

  const mergedData = ACM[typeOfUser]?.isTeacher
    ? [...studentData, ...(preRegisteredUsers || [])]
    : [...patientData, ...(preRegisteredUsers || [])];

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        className='documentUploadDialog'
        maxWidth='md'
        fullWidth // Make dialog use the maxWidth value
      >
        <DialogTitle>
          <BrandLogo newline={false} />
        </DialogTitle>
        <DialogContent sx={{ minWidth: '500px' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
              gap: 2,
              width: '100%',
            }}
          >
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Typography
                gutterBottom
                sx={{
                  textAlign: 'center',
                  mt: '1rem',
                  fontFamily: 'Lato',
                  fontSize: '1.2rem',
                  fontWeight: 'bold',
                }}
              >
                Upload document for review
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
                <BootstrapTooltip title='choose a file' placement='right'>
                  <Fab
                    variant='extended'
                    component='label'
                    color='secondary'
                    size='medium'
                  >
                    <Attachment sx={{ mr: 1 }} />
                    Choose File
                    <input
                      hidden
                      accept='.pdf,.csv,.xls,.xlsx,.doc,.docx'
                      multiple
                      type='file'
                      onChange={handleFileChange}
                      disabled={isLoading}
                    />
                  </Fab>
                </BootstrapTooltip>
              </Box>
              <Typography
                sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                color={'GrayText'}
              >
                {uploadedFile?.name && (
                  <>
                    {uploadedFile?.name}
                    <CheckCircleOutlineRounded color='success' />
                  </>
                )}
              </Typography>
            </Box>

            <Typography variant='subtitle1' fontWeight='medium' gutterBottom>
              * Date of document created:
            </Typography>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <MobileDateTimePicker
                label='Date of Document Created'
                disableFuture
                sx={{ width: '100%' }}
                value={date || null}
                onChange={dateChangeHandler}
                disabled={isLoading}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>

            <Typography variant='subtitle1' fontWeight='medium' gutterBottom>
              * Type of Document
            </Typography>
            <Select
              value={typeOfDocument}
              fullWidth
              onChange={(e) => {
                setTypeOfDocument(e.target.value);
              }}
              disabled={isLoading}
            >
              {documentsType.map((data) => (
                <MenuItem key={data.value} value={data.value}>
                  {data.label}
                </MenuItem>
              ))}
            </Select>

            {(ACM[typeOfUser]?.isDoctor || ACM[typeOfUser]?.isTeacher) && (
              <>
                <Typography
                  variant='subtitle1'
                  fontWeight='medium'
                  gutterBottom
                >
                  * {ACM[typeOfUser]?.isTeacher ? 'Student' : 'Patient'}:
                </Typography>
                <Select
                  value={
                    ACM[typeOfUser]?.isTeacher
                      ? selectedStudent
                      : SelectedPatient
                  }
                  fullWidth
                  onChange={
                    ACM[typeOfUser]?.isTeacher
                      ? handleStudentSelection
                      : handlePatientSelection
                  }
                  disabled={isLoading}
                >
                  {mergedData?.map((item) => (
                    <MenuItem key={item.id} value={item?.id}>
                      <BootstrapTooltip
                        title={
                          item?.parentName
                            ? `${item.fullname || item.name}(${
                                item.parentName
                              })`
                            : item?.fullname || item.name
                        }
                      >
                        {item?.parentName
                          ? `${truncateName(
                              item.fullname || item.name,
                              20
                            )} (${truncateName(item.parentName, 15)})`
                          : `${truncateName(item.fullname || item.name, 30)}`}
                      </BootstrapTooltip>
                    </MenuItem>
                  ))}
                </Select>
              </>
            )}

            <Typography variant='subtitle1' fontWeight='medium' gutterBottom>
              Notes:
            </Typography>
            <TextField
              variant='outlined'
              size='medium'
              label='Please input notes...'
              fullWidth
              multiline
              rows={6}
              onChange={(e) =>
                setInformation({
                  ...information,
                  notes: e.target.value,
                })
              }
              disabled={isLoading}
            />

            {(ACM[typeOfUser]?.isDoctor || ACM[typeOfUser]?.isTeacher) && (
              <Box sx={{ width: '100%', mt: 1 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isPrivate}
                      onChange={(e) => {
                        setIsPrivate(e.target.checked);
                      }}
                    />
                  }
                  label={
                    ACM[typeOfUser]?.isTeacher
                      ? 'Private to Student'
                      : 'Private to Patient'
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isPrivateAll}
                      onChange={(e) => {
                        setIsPrivateAll(e.target.checked);
                      }}
                    />
                  }
                  label={
                    ACM[typeOfUser]?.isTeacher
                      ? 'Private to Teacher'
                      : 'Private to Doctor'
                  }
                />
              </Box>
            )}

            {error.show && (
              <Typography color={'error'} sx={{ py: 1, width: '100%' }}>
                {error.message}
              </Typography>
            )}
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            px: 3,
            pb: 3,
            display: 'flex',
            justifyContent: 'center',
            gap: 2,
          }}
        >
          <Button
            variant='outlined'
            onClick={handleClose}
            sx={{
              width: '48%',
              fontFamily: 'Lato',
              textTransform: 'capitalize',
              color: 'var(--clr-theme-purple)',
              borderColor: 'var(--clr-theme-purple)',
              py: 1,
            }}
            disabled={isLoading}
          >
            Cancel
          </Button>
          <Button
            sx={{
              alignItems: 'center',
              backgroundColor: 'var(--clr-theme-purple)!important',
              color: 'white',
              width: '48%',
              fontFamily: 'Lato',
              textTransform: 'capitalize',
              py: 1,
            }}
            variant='contained'
            onClick={attachmentSaveHandler}
            disabled={isLoading}
          >
            Save
          </Button>

          {isLoading && <Progress />}
        </DialogActions>
      </Dialog>

      <Dialog open={showDialog} onClose={handleDialog}>
        <DialogTitle>Confirm Selection</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to select this{' '}
            {ACM[typeOfUser]?.isTeacher ? 'student' : 'patient'}?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDialog}
            sx={{
              width: '48%',
              fontFamily: 'Lato',
              textTransform: 'capitalize',
              color: 'var(--clr-theme-purple)',
              borderColor: 'var(--clr-theme-purple)',
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmChange}
            sx={{
              alignItems: 'center',
              backgroundColor: 'var(--clr-theme-purple)!important',
              color: 'white',
              width: '48%',
              fontFamily: 'Lato',
              textTransform: 'capitalize',
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <ToastContainer />
    </>
  );
};

export default UploadAttachment;
