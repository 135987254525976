import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Modal,
  TextField,
  Tabs,
  Tab,
  Box,
  Typography,
  Table,
  Paper,
  TableHead,
  TableRow,
  Tooltip,
  TableCell,
  TableBody,
  IconButton,
  Skeleton,
  TableContainer,
  MenuItem,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  Switch,
  Select,
  Checkbox,
  InputLabel,
  FormControl,
  Chip,
  Avatar,
  CircularProgress,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import MainLayout from '../../hoc/MainLayout';
import { Edit, Delete } from '@mui/icons-material';
import { FirestoreDB } from '../../utils/firebase/firebase';
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  updateDoc,
} from 'firebase/firestore';
import AuthContext from '../../contexts/AuthContext';
import { toast, ToastContainer } from 'react-toastify';
import {
  updatePreRegisterUserProfile,
  updateStudentProfileDetails,
} from '../../api';
import useSchoolsData from '../../hooks/useSchoolsData';
import useStudentData from '../../hooks/useStudentsData';
import useTeachersData from '../../hooks/useTeachersData';
import useFetchAllPreRegisterUser from '../../hooks/useFetchAllPreRegisterUser';

const StudentsPage = () => {
  const { user, typeOfUser, updateGlobalUser } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [students, setStudents] = useState([]);
  const [editIndex, setEditIndex] = useState(null);
  const [grade, setGrade] = useState('');
  const [schoolType, setSchoolType] = useState('');
  const [deleteModal, setDeleteModal] = useState(false);
  const [studentIndexDelete, setStudentIndexDelete] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const {
    data: fetchStudent = [],
    isLoading: isStudentsLoading,
    error: studentsError,
    refetch: refetchStudents,
  } = useStudentData(user?.uid, typeOfUser);

  const {
    data: schools = [],
    isLoading: isSchoolsLoading,
    error: schoolsError,
    refetch: refetchSchools,
  } = useSchoolsData();

  const {
    data: fetchedTeachers = [],
    isLoading: isTeachersLoading,
    error: teachersError,
    refetch: refetchTeachers,
  } = useTeachersData(user?.uid, typeOfUser);

  const {
    data: fetchPreRegisterUsers = [],
    // isLoading: isLoading,
    // error: errorStudentData,
    refetch: refetchPreRegisterStudents,
  } = useFetchAllPreRegisterUser(user?.uid, typeOfUser);

  const initialStudentData = {
    name: '',
    grade: '',
    class: '',
    email: '',
    admissionNumber: '',
    schoolId: '',
    schoolName: '',
    teacherId: [],
    teacherName: [],
    parentName: '',
  };

  const [studentData, setStudentData] = useState(initialStudentData);
  const [filteredTeachers, setFilteredTeachers] = useState([]);

  const grades = [
    { id: '1', name: 'Pre-School' },
    { id: '2', name: 'Kinder garden' },
    { id: '3', name: '1-12 grade' },
    { id: '4', name: 'k-2' },
  ];
  const schoolTypes = [
    { id: '1', name: 'Elementary School' },
    { id: '2', name: 'Middle School' },
    { id: '3', name: 'High School' },
  ];

  const combinedData = [...fetchPreRegisterUsers, ...students];

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setStudentData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
    if (name === 'schoolName') {
      const selectedSchool = schools.find(
        (school) => school.schoolName === value
      );
      const relatedTeachers = fetchedTeachers.filter(
        (teacher) => teacher.schoolName === selectedSchool?.schoolName
      );
      setFilteredTeachers(relatedTeachers);
    }
  };

  const handleOpen = (studentId = null) => {
    let studentToEdit = null;

    // Check if the student is in the pre-registered list
    if (studentId !== null) {
      // Check in fetchPreRegisterUsers (pre-registered students)
      studentToEdit = fetchPreRegisterUsers.find(
        (student) => student.id === studentId
      );

      // If not found in pre-registered users, check in normal users (fetchStudent)
      if (!studentToEdit) {
        studentToEdit = fetchStudent.find(
          (student) => student.id === studentId
        );
      }

      if (studentToEdit) {
        setEditIndex(studentId);

        const selectedSchool = schools.find(
          (school) => school.schoolName === studentToEdit.schoolName
        );

        const relatedTeachers = fetchedTeachers.filter(
          (teacher) => teacher.schoolName === selectedSchool?.schoolName
        );
        setFilteredTeachers(relatedTeachers);

        // const selectedTeacher = relatedTeachers.find(
        //   (teacher) => teacher.id === studentToEdit.teacherIds
        // );

        const selectedTeacher = relatedTeachers.filter(
          (teacher) =>
            studentToEdit.teacherIds?.includes(teacher?.id) ||
            studentToEdit.teacherId?.includes(teacher?.id)
        );

        setStudentData({
          name: studentToEdit.name || '',
          email: studentToEdit.email || '',
          admissionNumber:
            studentToEdit.admissionNumber || studentToEdit.id || '',
          parentName: studentToEdit.parentName || '',
          schoolId:
            schools.find(
              (school) => school.schoolName === studentToEdit.schoolName
            )?.id || '',
          schoolName: studentToEdit.schoolName || '',
          teacherId: selectedTeacher?.map((teacher) => teacher.id) || [],
          teacherName: selectedTeacher?.map((teacher) => teacher.name) || [],
          grade:
            grades.find((grade) => grade.name === studentToEdit.grade)?.id ||
            '',
          schoolType:
            schoolTypes.find((type) => type.name === studentToEdit.schoolType)
              ?.id || '',
        });

        setGrade(
          grades.find((grade) => grade.name === studentToEdit.grade)?.id || ''
        );
        setSchoolType(
          schoolTypes.find((type) => type.name === studentToEdit.schoolType)
            ?.id || ''
        );
      }
    } else {
      setEditIndex(null);
      setStudentData(initialStudentData);
      setGrade('');
      setSchoolType('');
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setActiveTab(0);
    setStudentData(initialStudentData);
    setSchoolType('');
    setGrade('');
  };

  const handleTabChange = (event, newValue) => setActiveTab(newValue);

  const addPreRegisterStudent = async () => {
    setIsLoading(true);
    const selectedSchool = schools.find(
      (school) => school.schoolName === studentData.schoolName
    );
    if (editIndex !== null) {
      try {
        const updatedStudent = {
          fullname: studentData.name,
          admissionNumber: studentData.admissionNumber,
          parentName: studentData.parentName,
          schoolName: studentData.schoolName,
          schoolId: selectedSchool?.schoolId || selectedSchool?.id || '',
          teacherName: studentData.teacherName || [],
          email: studentData.email,
          timestamp: new Date(),
        };

        // Check if the student is a pre-registered user or a normal user based on the editIndex
        const isPreRegisteredUser = fetchPreRegisterUsers.some(
          (user) => user.id === editIndex
        );

        if (isPreRegisteredUser) {
          // If it's a pre-registered user, update using updatePreRegisterUserProfile
          const result = await updatePreRegisterUserProfile(
            btoa(JSON.stringify(updatedStudent)),
            editIndex
          );

          const preRegisterUserListRef = doc(
            FirestoreDB,
            'preRegisteredUsers',
            editIndex
          );

          await updateDoc(preRegisterUserListRef, {
            teacherID: studentData.teacherId || [],
          });

          if (result) {
            console.log(
              'Pre-registered student profile updated successfully via API'
            );
            await refetchPreRegisterStudents();
          } else {
            console.error('Error updating pre-registered student');
          }
        } else {
          // normal user, update using updateStudentProfileDetails
          const result = await updateStudentProfileDetails(
            btoa(JSON.stringify(updatedStudent)),
            editIndex
          );

          const studentsListRef = doc(FirestoreDB, 'studentsList', editIndex);

          await updateDoc(studentsListRef, {
            teacherIds: studentData.teacherId || [],
          });

          if (result) {
            updateGlobalUser({ ...user, ...studentData });
            console.log('Student profile updated successfully via API');
            await refetchStudents();
          } else {
            console.error('Error updating normal student profile');
          }
        }
        handleClose();
      } catch (error) {
        console.error('Error updating student profile:', error);
      }
    } else {
      const params = {
        email: studentData.email,
        fullname: studentData.name || '',
        parentName: studentData.parentName || '',
        schoolName: studentData.schoolName,
        schoolId: selectedSchool?.schoolId || selectedSchool?.id || '',
        teacherName: studentData.teacherName || [],
        // teacherIds: studentData.teacherId || [],
        studentId: '',
        gender: '',
        dob: null,
        grade: studentData.grade || '',
        timestamp: new Date(),
        toEmail: studentData.email,
      };

      console.log('Adding new pre-registered student, params', params);

      try {
        const docRef = await addDoc(
          collection(FirestoreDB, 'preRegisteredUsers'),
          {
            profile: btoa(JSON.stringify(params)),
            isEncrypted: false,
            teacherID: studentData.teacherId,
            typeOfUser: 'STUDENT',
            timestamp: new Date(),
          }
        );
        console.log(
          'New pre-registered student document written with ID:',
          docRef.id
        );
        handleClose();
      } catch (e) {
        console.error('Error adding new pre-registered student:', e);
      }
    }
    handleClose();
    setIsLoading(false);
  };

  const handleSendInvitation = async (studentId) => {
    const selectedStudent = fetchPreRegisterUsers.find(
      (preRegUser) => preRegUser.id === studentId
    );

    if (!selectedStudent) {
      console.error('Selected student not found');
      return;
    }

    const params = {
      fromEmail: user?.email,
      inviteType: 'STUDENT',
      message: '',
      fullname: selectedStudent.name,
      timestamp: new Date(),
      toEmail: selectedStudent.email,
      parentName: selectedStudent.parentName || '',
      schoolId: selectedStudent.schoolId,
      schoolName: selectedStudent.schoolName,
      teacherName: selectedStudent.teacherName,
      teacherIds: selectedStudent.teacherId,
    };

    try {
      const docRef = await addDoc(
        collection(FirestoreDB, 'invitations'),
        params
      );
      console.log(' Invite Doc Id ', docRef.id);

      toast.success('Your invitation was successfully sent', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });

    } catch (e) {
      console.error('Error sending invitation: ', e);

      toast.error('Error in sending invitation. Please try again.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  const handleStatusChange = async (studentId, isActive) => {
    try {
      const studentDocRef = doc(FirestoreDB, 'studentsList', studentId);
      await updateDoc(studentDocRef, {
        status: isActive ? 'active' : 'inactive',
      });

      console.log(`Status updated for student ID: ${studentId}`);

      setStudents((prevStudents) =>
        prevStudents.map((student) =>
          student.id === studentId
            ? { ...student, status: isActive ? 'active' : 'inactive' }
            : student
        )
      );
      await refetchStudents();
      console.log(`Status updated for student ID: ${studentId}`);
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  const handleDeleteStudent = async (studentId) => {
    try {
      // First, check if the student exists in the preRegisteredUsers collection
      const preRegisteredUserRef = doc(
        FirestoreDB,
        'preRegisteredUsers',
        studentId
      );
      const preRegisteredUserDoc = await getDoc(preRegisteredUserRef);

      if (preRegisteredUserDoc.exists()) {
        // If the student is found in preRegisteredUsers, delete from there
        await deleteDoc(preRegisteredUserRef);
        console.log(
          `Pre-registered student with ID ${studentId} deleted successfully.`
        );
        toast.success('Pre-registered student deleted successfully.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } else {
        // If the student is not found in preRegisteredUsers, delete from studentsList
        const studentDocRef = doc(FirestoreDB, 'studentsList', studentId);
        await deleteDoc(studentDocRef);
        console.log(`Student with ID ${studentId} deleted successfully.`);
        toast.success('Student deleted successfully.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }

      await refetchStudents();
      await refetchPreRegisterStudents();
    } catch (error) {
      console.error(`Error deleting student with ID ${studentId}:`, error);
      toast.error('Error deleting student.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  // const handleDeleteStudent = async (studentId) => {
  //   try {
  //     const studentDocRef = doc(FirestoreDB, 'studentsList', studentId);

  //     await deleteDoc(studentDocRef);

  //     console.log(`Student with ID ${studentId} deleted successfully.`);

  //     await refetchStudents();
  //     toast.success('Student deleted successfully.', {
  //       position: toast.POSITION.BOTTOM_RIGHT,
  //     });
  //   } catch (error) {
  //     console.error(`Error deleting student with ID ${studentId}:`, error);
  //   }
  // };

  useEffect(() => {
    if (fetchStudent?.length > 0) {
      setStudents(fetchStudent);
    }
  }, [fetchStudent]);

  const SkeletonRow = () => {
    return (
      <TableRow>
        <TableCell>
          <Skeleton variant='text' width={200} />
        </TableCell>

        <TableCell>
          <Skeleton variant='text' width={75} />
        </TableCell>

        <TableCell>
          <Skeleton variant='text' width={75} />
        </TableCell>

        <TableCell>
          <Skeleton variant='text' width={75} />
        </TableCell>

        <TableCell>
          <Skeleton variant='text' width={75} />
        </TableCell>

        <TableCell>
          <Skeleton
            variant='rectangular'
            width={50}
            height={25}
            style={{ marginRight: '10px' }}
          />
        </TableCell>
        <TableCell>
          <Skeleton
            variant='rectangular'
            width={50}
            height={25}
            style={{ marginRight: '10px' }}
          />
        </TableCell>
      </TableRow>
    );
  };
  return (
    <MainLayout>
      <Box sx={{ padding: '20px' }}>
        <Typography variant='h4' gutterBottom>
          Students
        </Typography>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: '20px',
          }}
        >
          <Button
            variant='contained'
            // color='primary'
            onClick={() => handleOpen()}
            sx={{
              backgroundColor: 'var(--clr-theme-purple) !important',
              textTransform: 'capitalize',
              color: 'white',
              marginBottom: '20px',
            }}
          >
            + Add Student
          </Button>
        </div>

        <TableContainer component={Paper} style={{ marginTop: '20px' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: 'bold' }}>
                  Admission Number
                </TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>
                  Student Name
                </TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>
                  Parent Name
                </TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>
                  School Name
                </TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>
                  Teacher Name
                </TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>Status</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {combinedData.length > 0 ? (
                combinedData.map((student) => (
                  <TableRow key={student.id}>
                    <TableCell>
                      {student.admissionNumber || student.id}
                    </TableCell>
                    <TableCell>{student.name}</TableCell>
                    <TableCell>{student.parentName}</TableCell>
                    <TableCell>{student.schoolName}</TableCell>
                    <TableCell>
                      {Array.isArray(student.teacherName)
                        ? student.teacherName.join(', ')
                        : student.teacherName || 'No Teachers Assigned'}
                    </TableCell>

                    <TableCell>
                      {fetchPreRegisterUsers.some(
                        (preRegUser) => preRegUser.id === student.id
                      ) ? (
                        <Switch
                          checked={student.status === 'active'}
                          onChange={(e) =>
                            handleStatusChange(student.id, e.target.checked)
                          }
                          name='status'
                          color='primary'
                          disabled // Disabling the switch for pre-registered users
                        />
                      ) : (
                        <Switch
                          checked={student.status === 'active'}
                          onChange={(e) =>
                            handleStatusChange(student.id, e.target.checked)
                          }
                          name='status'
                          color='primary'
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      <Tooltip title='Edit Student Information'>
                        <Button onClick={() => handleOpen(student.id)}>
                          <Edit sx={{ color: 'black' }} />
                        </Button>
                      </Tooltip>
                      <Tooltip title='Delete Student'>
                        <Button
                          onClick={() => {
                            setDeleteModal(true);
                            setStudentIndexDelete(student.id);
                          }}
                        >
                          <Delete sx={{ color: 'red' }} />
                        </Button>
                      </Tooltip>
                      {/* <Tooltip title='Delete Student'> */}
                      {fetchPreRegisterUsers.some(
                        (preRegUser) => preRegUser.id === student.id
                      ) && (
                        <Button
                          sx={{
                            backgroundColor:
                              'var(--clr-theme-purple) !important',
                            width: '30%',
                            textTransform: 'capitalize',
                            color: 'white',
                          }}
                          onClick={() => handleSendInvitation(student.id)}
                        >
                          Invite
                        </Button>
                      )}
                      {/* </Tooltip> */}
                    </TableCell>
                  </TableRow>
                ))
              ) : isStudentsLoading || isSchoolsLoading ? (
                [...Array(5)].map((_, index) => <SkeletonRow key={index} />)
              ) : (
                <TableRow>
                  <TableCell colSpan={4} align='center'>
                    No students yet. Add your first student above.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <Modal open={open} onClose={handleClose}>
          <Box
            sx={{
              width: '600px',
              backgroundColor: 'white',
              padding: '20px',
              margin: '50px auto',
              borderRadius: '8px',
            }}
          >
            <Typography variant='h6' gutterBottom>
              {editIndex !== null ? 'Edit Student' : 'Add New Student'}
            </Typography>
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              variant='fullWidth'
            >
              <Tab label='Basic Information' />
            </Tabs>
            <Box sx={{ marginTop: '20px', overflow: 'auto', pt: 1 }}>
              {activeTab === 0 && (
                <Box className='flexBox flexColumn' gap={2}>
                  <TextField
                    fullWidth
                    label='Name'
                    name='name'
                    value={studentData.name}
                    onChange={handleInputChange}
                    variant='outlined'
                    disabled={isLoading}
                  />
                  <TextField
                    fullWidth
                    label='Email'
                    name='email'
                    value={studentData.email}
                    onChange={handleInputChange}
                    variant='outlined'
                    disabled={isLoading}
                  />
                  <TextField
                    fullWidth
                    label='Parent Name'
                    name='parentName'
                    value={studentData.parentName}
                    onChange={handleInputChange}
                    variant='outlined'
                    disabled={isLoading}
                  />
                  <TextField
                    fullWidth
                    label='Admission Number'
                    name='admissionNumber'
                    value={studentData.admissionNumber}
                    onChange={handleInputChange}
                    variant='outlined'
                    disabled={isLoading}
                  />
                  <TextField
                    select
                    label='School'
                    name='schoolName'
                    value={studentData.schoolName}
                    onChange={handleInputChange}
                    fullWidth
                    required
                    disabled={isLoading}
                  >
                    {schools.map((school) => (
                      <MenuItem key={school.id} value={school.schoolName}>
                        {school.schoolName}
                      </MenuItem>
                    ))}
                  </TextField>

                  <Select
                    multiple
                    displayEmpty
                    fullWidth
                    variant='outlined'
                    name='teacherId'
                    // label='Select Teachers'
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    value={studentData.teacherId || []} // Ensure it's always an array
                    onChange={(e) => {
                      const selectedValues = e.target.value || []; // Ensure this is always an array
                      const selectedTeachers = filteredTeachers.filter(
                        (teacher) => selectedValues.includes(teacher.id)
                      );

                      setStudentData((prevData) => ({
                        ...prevData,
                        teacherId: selectedValues,
                        teacherName: selectedTeachers.map((t) => t.name),
                      }));
                    }}
                    disabled={
                      !studentData.schoolName ||
                      filteredTeachers.length === 0 ||
                      isLoading
                    }
                    renderValue={(selected) =>
                      selected.length > 0 ? (
                        <div
                          style={{ display: 'flex', flexWrap: 'wrap', gap: 5 }}
                        >
                          {filteredTeachers
                            .filter((teacher) => selected.includes(teacher.id))
                            .map((teacher) => (
                              <Chip
                                avatar={
                                  <Avatar src={teacher.photoURL}>
                                    {teacher.name[0]}
                                  </Avatar>
                                }
                                key={teacher.id}
                                label={teacher.name}
                                onDelete={(event) => {
                                  event.stopPropagation(); // Prevent dropdown from opening

                                  // Remove the selected teacher from the list
                                  const updatedTeacherIds =
                                    studentData.teacherId.filter(
                                      (id) => id !== teacher.id
                                    );
                                  setStudentData((prevData) => ({
                                    ...prevData,
                                    teacherId: updatedTeacherIds,
                                    teacherName: filteredTeachers
                                      .filter((t) =>
                                        updatedTeacherIds.includes(t.id)
                                      )
                                      .map((t) => t.name),
                                  }));
                                }}
                              />
                            ))}
                        </div>
                      ) : (
                        'Select Teachers'
                      )
                    }
                  >
                    {filteredTeachers.map((teacher) => (
                      <MenuItem key={teacher.id} value={teacher.id}>
                        <Checkbox
                          checked={
                            studentData?.teacherId?.includes(teacher.id) ||
                            false
                          }
                        />
                        {teacher.name}
                      </MenuItem>
                    ))}
                  </Select>

                  {/* <TextField
                    select
                    label='Teacher Name'
                    fullWidth
                    variant='outlined'
                    margin='normal'
                    name='teacherId'
                    value={studentData.teacherId}
                    onChange={(e) => {
                      const selectedTeacher = filteredTeachers.find(
                        (teacher) => teacher.id === e.target.value
                      );
                      setStudentData((prevData) => ({
                        ...prevData,
                        teacherId: selectedTeacher?.id || '',
                        teacherName: selectedTeacher?.name || '',
                      }));
                    }}
                    disabled={
                      !studentData.schoolName || filteredTeachers.length === 0
                    }
                  >
                    {filteredTeachers.map((teacher) => (
                      <MenuItem key={teacher.id} value={teacher.id}>
                        {teacher.name}
                      </MenuItem>
                    ))}
                  </TextField> */}
                  {/* <TextField
                    select
                    label='Teacher Name'
                    fullWidth
                    variant='outlined'
                    margin='normal'
                    name='teacherId'
                    value={studentData.teacherId}
                    onChange={(e) => {
                      const selectedTeacherIds = e.target.value;
                      const selectedTeachers = filteredTeachers.filter(
                        (teacher) => selectedTeacherIds.includes(teacher.id)
                      );

                      setStudentData((prevData) => ({
                        ...prevData,
                        teacherId: selectedTeacherIds,
                        teacherName: selectedTeachers
                          .map((teacher) => teacher.name)
                          .join(', '),
                      }));
                    }}
                    disabled={
                      !studentData.schoolName || filteredTeachers.length === 0
                    }
                    multiple // Enable multi-select
                  >
                    {filteredTeachers.map((teacher) => (
                      <MenuItem key={teacher.id} value={teacher.id}>
                        {teacher.name}
                      </MenuItem>
                    ))}
                  </TextField> */}
                  <TextField
                    select
                    label='Grade Level'
                    fullWidth
                    variant='outlined'
                    value={grade}
                    onChange={(e) => setGrade(e.target.value)}
                    disabled={isLoading}
                  >
                    {grades.map((grade) => (
                      <MenuItem key={grade.id} value={grade.id}>
                        {grade.name}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    select
                    label='School Type'
                    fullWidth
                    variant='outlined'
                    value={schoolType}
                    onChange={(e) => setSchoolType(e.target.value)}
                    disabled={isLoading}
                  >
                    {schoolTypes.map((type) => (
                      <MenuItem key={type.id} value={type.id}>
                        {type.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
              )}
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '20px',
              }}
            >
              <Button
                onClick={handleClose}
                sx={{
                  backgroundColor: 'var(--clr-theme-purple) !important',
                  textTransform: 'capitalize',
                  color: 'white',
                  marginRight: '10px',
                  width: '30%'
                }}
              >
                Cancel
              </Button>
              <Button
                variant='contained'
                color='primary'
                onClick={addPreRegisterStudent}
                // sx={{ textTransform: 'capitalize' }}
                sx={{
                  backgroundColor: 'var(--clr-theme-purple) !important',
                  textTransform: 'capitalize',
                  color: 'white',
                }}
                disabled={isLoading}
              >
                {isLoading ? (
                  <CircularProgress size={24} color='inherit' />
                ) : editIndex !== null ? (
                  'Update Changes'
                ) : (
                  'Add Student'
                )}
              </Button>
            </Box>
          </Box>
        </Modal>
      </Box>
      <Dialog open={deleteModal} onClose={() => setStudentIndexDelete(0)}>
        <DialogContent>
          <Typography
            sx={{
              color: 'var(--clr-theme-gray)',
              fontFamily: 'Karla',
              fontWeight: 700,
              textAlign: 'center',
            }}
          >
            Are you sure you want to delete this student?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant='contained'
            color='success'
            onClick={() => setDeleteModal(false)}
            sx={{
              backgroundColor: '#D3D3D3',
              textTransform: 'capitalize',
              fontFamily: 'Lato',
              fontWeight: 400,
              fontSize: '1.2rem',
              width: '100%',
            }}
          >
            No
          </Button>
          <Button
            variant='contained'
            color='success'
            onClick={() => {
              handleDeleteStudent(studentIndexDelete);
              setDeleteModal(false);
            }}
            sx={{
              backgroundColor: '#9291f0',
              textTransform: 'capitalize',
              fontFamily: 'Lato',
              fontWeight: 400,
              fontSize: '1.2rem',
              width: '100%',
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer />
    </MainLayout>
  );
};

export default StudentsPage;
