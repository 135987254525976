import { useQuery } from '@tanstack/react-query';
import { fetchAllTeachers } from '../api';

const useTeachersData = (userId, typeOfUser) => {
  return useQuery({
    queryKey: ['teachers', userId, typeOfUser],
    queryFn: async () => {
      if (typeOfUser !== 'ADMIN') return [];

      const fetchedData = await fetchAllTeachers(userId, 'ADMIN');
      return (
        fetchedData?.teachers.map((teacher) => ({
          id: teacher.id,
          typeOfUser: typeOfUser,
          name: teacher.docData?.name || teacher.docData?.fullname || '',
          email: teacher.docData?.email || '',
          photoURL: teacher.docData?.photoURL || null,
          schoolName: teacher.docData?.schoolName || '',
          addStudent: teacher.docData?.addStudent,
          grade: teacher.docData?.grade || '',
          schoolType: teacher.docData?.schoolType || '',
          qualifications: teacher.docData?.qualifications || [],
          status: teacher.docData?.status,
          permissions: teacher.docData?.permissions || {},
          subRole: teacher.docData?.subRoleName || '',
        })) || []
      );
    },
    staleTime: 60000, // 1 min
    cacheTime: 300000, // 5 min
  });
};

export default useTeachersData;
