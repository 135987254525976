const goalsFromDocuments = [
  {
    StudentName: 'Sophia',
    StudentID: '2ABCxYZ12345',
    GoalDetails: {
      GoalTitle: 'Following Instructions',
      GoalNumber: 5,
      GoalCategory: ['ABA'],
      TypeOfGoal: {
        Type: 'Behavior',
        BehaviorType: 'Skill Acquisition',
        BehaviorName: 'Following Instructions',
        Definition:
          'Responding to verbal instructions given by an adult within 5 seconds',
        Onset: 'Instruction given',
        Offset: 'Completion of task',
      },
    },
    DataCollection: {
      measurementType: {
        Type: 'Percentage',
        Target: 'At least',
        TargetDuration: '80',
        measurementUnit: '% correct responses',
      },
      MultipleDataPointsPerSession: {
        MultipleDataPointsPerSession: 'Yes',
        DataPointPerSession: 'Each Trial',
      },
      MultipleTimesToMeetGoal: {
        MultipleTimesToMeetGoal: 'Yes',
        NumberOfSessions: '4',
        ConsecutiveSessions: 'Yes',
      },
      ByDate: '2025-06-15',
      Baseline: 'Currently following instructions 40% of the time',
      Notes: 'Increase response time efficiency',
    },
    Subgoal: {
      IsSubgoal: 'Yes',
      LinkedGoal: 'Improving Attention',
    },
    Generate: {
      Graphs: true,
      Reports: 'true',
    },
    ShareWithTeam: {
      IsSharingEnabled: true,
      EditAccess: ['Teacher', 'Therapist'],
      ViewOnlyAccess: ['Parent/Guardian'],
    },
  },
  {
    StudentName: 'Daniel',
    StudentID: '3XYZ98765LMN',
    GoalDetails: {
      GoalTitle: 'Social Interaction',
      GoalNumber: 10,
      GoalCategory: ['Social Skills'],
      TypeOfGoal: {
        Type: 'Behavior',
        BehaviorType: 'Skill Acquisition',
        BehaviorName: 'Initiating Conversations',
        Definition:
          'Starting a conversation with a peer at least once per social opportunity',
        Onset: 'Student begins speaking to a peer',
        Offset: 'Peer responds or conversation ends',
      },
    },
    DataCollection: {
      measurementType: {
        Type: 'Frequency',
        Target: 'At least',
        TargetDuration: '3',
        measurementUnit: 'times per session',
      },
      MultipleDataPointsPerSession: {
        MultipleDataPointsPerSession: 'Yes',
        DataPointPerSession: 'Average',
      },
      MultipleTimesToMeetGoal: {
        MultipleTimesToMeetGoal: 'No',
        NumberOfSessions: 'NA',
        ConsecutiveSessions: 'NA',
      },
      ByDate: '2025-07-01',
      Baseline: 'Currently initiating conversations 1 time per session',
      Notes: 'Encourage natural conversations',
    },
    Subgoal: {
      IsSubgoal: 'No',
      LinkedGoal: 'NA',
    },
    Generate: {
      Graphs: false,
      Reports: 'true',
    },
    ShareWithTeam: {
      IsSharingEnabled: false,
      EditAccess: [],
      ViewOnlyAccess: [],
    },
  },
  {
    StudentName: 'Emma',
    StudentID: '4LMN09876XYZ',
    GoalDetails: {
      GoalTitle: 'Handwriting Skills',
      GoalNumber: 12,
      GoalCategory: ['Academic'],
      TypeOfGoal: {
        Type: 'Skill Development',
        BehaviorType: 'Fine Motor Skills',
        BehaviorName: 'Writing Letters Clearly',
        Definition:
          'Student will write legible letters with proper formation and spacing',
        Onset: 'Student begins writing',
        Offset: 'Completion of writing task',
      },
    },
    DataCollection: {
      measurementType: {
        Type: 'Accuracy',
        Target: 'At least',
        TargetDuration: '90',
        measurementUnit: '% correctly formed letters',
      },
      MultipleDataPointsPerSession: {
        MultipleDataPointsPerSession: 'No',
        DataPointPerSession: 'Final Score',
      },
      MultipleTimesToMeetGoal: {
        MultipleTimesToMeetGoal: 'Yes',
        NumberOfSessions: '6',
        ConsecutiveSessions: 'Yes',
      },
      ByDate: '2025-09-10',
      Baseline: 'Currently forming letters correctly 60% of the time',
      Notes: 'Improve consistency in writing letters',
    },
    Subgoal: {
      IsSubgoal: 'Yes',
      LinkedGoal: 'Fine Motor Strength',
    },
    Generate: {
      Graphs: true,
      Reports: 'true',
    },
    ShareWithTeam: {
      IsSharingEnabled: true,
      EditAccess: ['Occupational Therapist'],
      ViewOnlyAccess: ['Parent/Guardian'],
    },
  },
];

export default goalsFromDocuments;
