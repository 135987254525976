import React, { useContext, useState } from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import HomeCard from '../../../components/HomeCard';
import {
  Backdrop,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  Radio,
  RadioGroup,
} from '@mui/material';
import { Info } from '@mui/icons-material';
import AuthContext from '../../../contexts/AuthContext';
import {
  FirestoreDB,
  LogAnalyticsEvent,
} from '../../../utils/firebase/firebase';
import { addDoc, collection } from 'firebase/firestore';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
 
const AddStudent = () => {
   const [isDateSelected, setIsDateSelected] = useState(false);
  const [formValues, setFormValues] = useState({
    name: '',
    parentName: '',
    studentId: '',
    gender: '',
    dob: null,
    email: '',
    grade: '',
    contactNo: { areaCode: '', number: '' },
  });
  const [studentDetails, setStudentDeatils] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [customError, setCustomError] = useState({ show: false, message: '' });
  const [customMsg, setCustomMsg] = useState({ show: false, message: '' });
  const [isStudentAdded, setIsStudentAdded] = useState(false);
  const { user } = useContext(AuthContext);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setCustomError({ show: false, message: '' });
  };

  const resetValues = () => {
    setFormValues({
      name: '',
      parentName: '',
      studentId: '',
      gender: '',
      dob: null,
      email: '',
      grade: '',
      contactNo: { areaCode: '', number: '' },
    });
    setStudentDeatils(null);
    setCustomError({ show: false, message: '' });
    setCustomMsg({ show: false, message: '' });
    setIsStudentAdded(false);
  };

  const addStudent = async () => {
    if (
      !formValues.email ||
      !formValues.name ||
      !formValues.contactNo ||
      !formValues.parentName ||
      !formValues.gender ||
      !formValues.dob ||
      !formValues.grade
    ) {
      setCustomError({ show: true, message: 'All fields are required.' });
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    const params = {
      email: formValues.email,
      fullname: formValues.name || '',
      parentName: formValues.parentName || '',
      studentId: formValues.studentId || '',
      teacherName: [user.fullname]|| [],
      gender: formValues.gender || '',
      dob: formValues.dob || null,
      grade: formValues.grade || '',
      contactNo: formValues?.contactNo || {
        areaCode: '00',
        number: '000000000',
      },
    };
    
    try {
      const docRef = await addDoc(
        collection(FirestoreDB, 'preRegisteredUsers'),
        {
          profile: btoa(JSON.stringify(params)),
          isEncrypted: false,
          teacherID: [user?.uid],
          typeOfUser: 'STUDENT',
          timestamp: new Date(),
        }
      );
      console.log('Document written with ID: ', docRef.id);
      setIsLoading(false);
      resetValues();
    } catch (e) {
      console.error('Error adding document: ', e);
    }
    resetValues();
  };

  return (
    <HomeCard title='Add Student' showAvatar={false} customClass='AddDoctor'>
      <Container maxWidth='xl' sx={{ px: 4, mt: 4 }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={12}>
            <Paper
              className='fullHeight'
              sx={{
                p: 5,
                mb: 4,
                border: '1px solid lightgray',
                maxHeight: '70vh',
                overflowY: 'auto',
              }}
            >
              <Typography
                variant='subtitle1'
                component='div'
                sx={{ fontFamily: 'Lato' }}
              >
                I would like guidance on adding a student to the system and
                obtaining the necessary email id for the process.
              </Typography>
              <Box className='flexBox flexColumn' sx={{ gap: 2, mt: 3 }}>
                {/* Form Fields */}
                <TextField
                  label='Student Name'
                  fullWidth
                  name='name'
                  value={formValues.name}
                  onChange={handleInputChange}
                  color='secondary'
                  error={customError.show}
                  helperText={customError.show ? customError.message : ''}
                />
                <TextField
                  label='Parent Name'
                  fullWidth
                  name='parentName'
                  value={formValues.parentName}
                  onChange={handleInputChange}
                  color='secondary'
                  error={customError.show}
                  helperText={customError.show ? customError.message : ''}
                />
                <TextField
                  label='Student ID'
                  fullWidth
                  name='studentId'
                  value={formValues.studentId}
                  onChange={handleInputChange}
                  color='secondary'
                  error={customError.show}
                  helperText={customError.show ? customError.message : ''}
                />
                <FormControl fullWidth error={customError.show} sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
                    <Typography sx={{marginTop: '8px'}}>Gender : </Typography>
                  <RadioGroup
                    name="gender"
                    value={formValues.gender}
                    onChange={handleInputChange}
                    row
                    
                  >
                    <FormControlLabel value="Female" control={<Radio />} label="Female" />
                    <FormControlLabel value="Male" control={<Radio />} label="Male" />
                  </RadioGroup>
                  {customError.show && (
                    <Typography variant="caption" color="error">
                      {customError.message}
                    </Typography>
                  )}
                </FormControl>
                {/* <Typography
                  variant='subtitle1'
                  gutterBottom
                  sx={{ fontFamily: 'Lato' }}
                >
                  Date of birth*
                </Typography> */}
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DesktopDatePicker
                    disableFuture
                    className='fullWidth'
                    value={formValues?.dob?.seconds * 1000 || formValues?.dob}
                    onChange={(newValue) => {
                      setFormValues({
                        ...formValues,
                        dob: newValue._d,
                      });
                      setIsDateSelected(true);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
                <TextField
                  label='Email Address'
                  fullWidth
                  name='email'
                  value={formValues.email}
                  onChange={handleInputChange}
                  color='secondary'
                  error={customError.show}
                  helperText={customError.show ? customError.message : ''}
                />
                <TextField
                  label='Grade'
                  fullWidth
                  name='grade'
                  value={formValues.grade}
                  onChange={handleInputChange}
                  color='secondary'
                  error={customError.show}
                  helperText={customError.show ? customError.message : ''}
                />
                 <Box className='flexBox fullWidth' sx={{ gap: 1, flex: 1 }}>
                 <Typography sx={{marginTop: '15px'}}>Contact : </Typography>
                  <TextField
                    fullWidth
                    label='Area code'
                    // type="tel"
                    sx={{ flex: 0.3 }}
                    value={formValues?.contactNo?.areaCode}
                    onChange={(e) => {
                      setFormValues({
                        ...formValues,
                        contactNo: {
                          ...formValues.contactNo,
                          areaCode: e.target.value.trim().slice(0, 3),
                        },
                      });
                    }}
                  />
                  <TextField
                    fullWidth
                    label='Number'
                    // type={"tel"}
                    sx={{ flex: 0.7 }}
                    value={formValues?.contactNo?.number}
                    onChange={(e) => {
                      setFormValues({
                        ...formValues,
                        contactNo: {
                          ...formValues.contactNo,
                          number: e.target.value.trim(),
                        },
                      });
                    }}
                  />
                </Box>
                <Box className='flexBox' gap={0.5}>
                  <Info color='success' sx={{ fontSize: '20px' }} />
                  <Typography
                    variant='caption'
                    sx={{ textAlign: 'start', color: 'var(--clr-theme-gray)' }}
                  >
                    To proceed further, please kindly provide the email address
                    of either the student or their healthcare professional, as
                    it is necessary for the subsequent steps in the registration
                    process.
                  </Typography>
                </Box>

                {customMsg.show && (
                  <Typography className='successMessage' variant='caption'>
                    {customMsg.message}
                  </Typography>
                )}

                <Button
                  sx={{
                    textTransform: 'capitalize',
                    backgroundColor: 'var(--clr-theme-purple) !important',
                    '&:disabled': {
                      color: 'var(--clr-theme-white) !important',
                      backgroundColor: 'rgb(211, 211, 211) !important',
                    },
                  }}
                  variant='contained'
                  color='primary'
                  type='button'
                  fullWidth
                  onClick={addStudent}
                  disabled={isStudentAdded}
                >
                  Add Student
                </Button>
                <Button
                  sx={{
                    textTransform: 'capitalize',
                    backgroundColor: 'var(--clr-theme-purple) !important',
                    '&:disabled': {
                      color: 'var(--clr-theme-white) !important',
                      backgroundColor: 'rgb(211, 211, 211) !important',
                    },
                  }}
                  variant='contained'
                  color='primary'
                  type='button'
                  fullWidth
                  onClick={resetValues}
                >
                  Reset
                </Button>
              </Box>
            </Paper>
          </Grid>

          {/* <Grid item xs={12} md={4}>
            <Card sx={{ border: '1px solid lightgray' }}>
              <CardHeader
                title='Student Details'
                sx={{
                  textAlign: 'center',
                  backgroundColor: 'var(--clr-theme-purple)',
                  color: '#fff',
                }}
              />
              <CardContent>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'center',
                  }}
                >
                  <Avatar
                    src={studentDetails?.photoURL}
                    alt='Student'
                    sx={{
                      width: 150,
                      height: 150,
                      marginBottom: 2,
                    }}
                    variant='circular'
                  />
                  <div style={{ marginTop: 3 }}>
                    <Typography
                      variant='h5'
                      gutterBottom
                      sx={{ fontFamily: 'Lato' }}
                    >
                      {studentDetails?.name || formValues.name || 'NA'}
                    </Typography>
                  </div>
                </div>
                <Divider my={4} />
                <List>
                  <ListItem alignItems='flex-start'>
                    <ListItemText
                      primary={
                        <Typography sx={{ fontFamily: 'Lato' }} variant='h6'>
                          Email
                        </Typography>
                      }
                      secondary={
                        <Typography variant='body2' color='textSecondary'>
                          {studentDetails?.email || formValues.email || 'NA'}
                        </Typography>
                      }
                    />
                  </ListItem>
                  <ListItem alignItems='flex-start'>
                    <ListItemText
                      primary={
                        <Typography sx={{ fontFamily: 'Lato' }} variant='h6'>
                          Contact number
                        </Typography>
                      }
                      secondary={
                        <Typography variant='body2' color='textSecondary'>
                          {formValues.contactNo ||
                            studentDetails?.contactNo ||
                            'NA'}
                        </Typography>
                      }
                    />
                  </ListItem>
                </List>
              </CardContent>
            </Card>
          </Grid> */}
        </Grid>
        {isLoading && (
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
          >
            <CircularProgress color='inherit' />
          </Backdrop>
        )}
      </Container>
    </HomeCard>
  );
};

export default AddStudent;
