import React from 'react';
import { Box, CardContent } from '@mui/material';
import Footer from '../Updated_UI/Footer';
import './Terms.css';
import { usePolicy } from '../../hooks/usePolicy';

const PolicyTerms = ({ footerRequired }) => {
  const { data: termsText = '' } = usePolicy();
  return (
    <Box className='terms'>
      <Box
        sx={{
          maxWidth: '90%',
          margin: '20px auto',
          padding: 2,
          overflow: 'auto',
        }}
      >
        <CardContent>
          <div dangerouslySetInnerHTML={{ __html: termsText }} />
        </CardContent>
      </Box>
      {footerRequired && <Footer />}
    </Box>
  );
};

export default PolicyTerms;
