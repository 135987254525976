import { useQuery } from '@tanstack/react-query';
import { fetchVideoDetails } from '../api';

const useFetchVideoDetails = (patientUid, videoId) => {
  return useQuery({
    queryKey: ['fetchVideoDetails', patientUid, videoId],
    queryFn: () => fetchVideoDetails(patientUid, videoId),
    enabled: !!patientUid || !!videoId,
    staleTime: 60000,
    cacheTime: 300000,
  });
};

export default useFetchVideoDetails;
