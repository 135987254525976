import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { DataContext } from '../contexts/DataProvider';

const useOneStudentVideos = (studentUid) => {
  const { fetchOneStudentAllVideos } = useContext(DataContext);
  return useQuery({
    queryKey: ['oneStudentsVideos', studentUid],
    queryFn: () => fetchOneStudentAllVideos(studentUid),
    enabled: !!studentUid,
    staleTime: 60000, // 1 min 
    cacheTime: 300000, // 5 min
  });
};

export default useOneStudentVideos;
