import { PublicClientApplication } from "@azure/msal-browser";

const msalConfig = {
  auth: {
    clientId: "f841fc79-d3e7-4238-9540-afaa3bf290ab",
    authority: "https://login.microsoftonline.com/46ecd9db-ea42-4ed1-96e8-0b4e403dd2f9", //Tenant ID
    redirectUri: "https://mindly.care", // Ensure this matches Azure
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);