'use client';

import { useContext, useEffect, useState, useMemo } from 'react';
import {
  Box,
  Button,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Grid,
  Stack,
  Paper,
  Snackbar,
  Alert,
} from '@mui/material';
import { ACMContext } from '../../contexts/ACMProvider';
import AuthContext from '../../contexts/AuthContext';
import Reports from './Reports';
import MainLayout from '../../hoc/MainLayout';
import usePatientsForDoctor from '../../hooks/usePatientsForDoctor';
import useStudentsForOneTeacher from '../../hooks/useStudentsForOneTeacher';
import useFetchAllAttachmentsByUser from '../../hooks/useFetchAllAttachmentsByUser';
import useFetchPreRegisteredUserForTeacher from '../../hooks/useFetchPreRegisteredUserForTeacher';
import { useNavigate } from 'react-router-dom';
import { LogAnalyticsEvent } from '../../utils/firebase/firebase';
import { ANALYTICS_KEYS } from '../../constants/AnalyticKeys';
import UploadAttachment from './UploadAttachment';

const Documents = () => {
  const { ACM } = useContext(ACMContext);
  const { user, typeOfUser } = useContext(AuthContext);
  const navigate = useNavigate();

  const [openAttachmentUploadDocument, setOpenAttachmentUploadDocument] =
    useState(false);
  const [selectedStudent, setSelectedStudent] = useState('__all_students__');
  const [selectedPatient, setSelectedPatient] = useState('all_patients__');
  const [documentType, setDocumentType] = useState('All Documents');
  const [documents, setDocuments] = useState([]);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'info',
  });

  const { data: preRegisteredUsers = [] } = useFetchPreRegisteredUserForTeacher(
    user?.uid
  );
  const { data: patientData = [] } = usePatientsForDoctor(user?.uid);
  const { data: fetchStudentsForOneTeacher = [] } = useStudentsForOneTeacher(
    user?.uid,
    typeOfUser
  );
  const { data: allAttachments } = useFetchAllAttachmentsByUser(
    user?.uid,
    typeOfUser
  );

  const enrolledStudents = useMemo(
    () => fetchStudentsForOneTeacher || [],
    [fetchStudentsForOneTeacher]
  );

  const combinedStudents = useMemo(() => {
    const enrolledStudentNames = enrolledStudents.map((student) => ({
      id: student.id,
      name: student.name,
    }));

    const preRegisteredUserNames = preRegisteredUsers.map((user) => ({
      id: user.id,
      name: user.name,
    }));

    return [...enrolledStudentNames, ...preRegisteredUserNames];
  }, [enrolledStudents, preRegisteredUsers]);

  const fetchDocuments = async () => {
    if (!user) return;
    try {
      if (ACM[typeOfUser]?.fetchAllAttachmentsByUser) {
        const fetchedDocuments = allAttachments?.data?.map((patient) => ({
          ...patient,
          attachmentData: patient?.attachmentData
            ?.filter((attachment) => !attachment.docData.isDeleted)
            ?.sort(
              (a, b) =>
                b.docData.createdAt._seconds - a.docData.createdAt._seconds
            ),
        }));

        setDocuments(fetchedDocuments || []);
      }
    } catch (error) {
      console.error('Error fetching documents:', error);
    }
  };

  useEffect(() => {
    fetchDocuments();
  }, [user, allAttachments]);

  useEffect(() => {
    let docName = 'All Documents';
    if (selectedPatient !== 'all_patients__') {
      const patient = patientData.find((p) => p.patientId === selectedPatient);
      docName = patient ? `Patient_${patient.patientInfo.fullname}` : docName;
    }
    if (selectedStudent !== '__all_students__') {
      const student = combinedStudents.find((s) => s.id === selectedStudent);
      docName = student ? `Student_${student.name}` : docName;
    }
    setDocumentType(docName);
  }, [selectedPatient, selectedStudent, patientData, combinedStudents]);

  const truncateName = (name, limit = 10) => {
    const capitalized = name
      ?.split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
    return capitalized?.length > limit
      ? `${capitalized.slice(0, limit)}...`
      : capitalized;
  };

  const openDocumentHandler = (
    attachmentId,
    documentUrl,
    documentName,
    patientId
  ) => {
    navigate('/smartsearch', {
      state: { attachmentId, documentUrl, documentName, patientId },
    });
    LogAnalyticsEvent(ANALYTICS_KEYS.HISTORICALDATA.DOCUMENTVIEW, {
      eventName: 'Document View',
      message: 'Document Viewed',
      patientID: patientId,
      documentID: attachmentId,
    });
  };

  const downloadFile = async (url, fileName) => {
    try {
      setSnackbar({
        open: true,
        message: 'Starting download...',
        severity: 'info',
      });

      const response = await fetch(url);

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = downloadUrl;
      a.download = fileName || 'document';
      document.body.appendChild(a);
      a.click();

      window.URL.revokeObjectURL(downloadUrl);
      document.body.removeChild(a);

      setSnackbar({
        open: true,
        message: 'File downloaded successfully!',
        severity: 'success',
      });

      LogAnalyticsEvent(ANALYTICS_KEYS.HISTORICALDATA.DOCUMENTDOWNLOAD, {
        eventName: 'Document Download',
        message: 'Document Downloaded',
        fileName: fileName,
      });
    } catch (error) {
      console.error('Error downloading file:', error);
      setSnackbar({
        open: true,
        message: 'Error downloading file. Please try again.',
        severity: 'error',
      });
    }
  };

  const formattedDocuments = useMemo(() => {
    if (!documents || documents.length === 0) return [];

    let filteredDocs = [...documents];

    if (selectedPatient !== 'all_patients__') {
      filteredDocs = filteredDocs.filter(
        (doc) => doc.patientId === selectedPatient
      );
    }

    if (selectedStudent !== '__all_students__') {
      filteredDocs = filteredDocs.filter(
        (doc) => doc.patientId === selectedStudent
      );
    }

    if (
      documentType &&
      !documentType.startsWith('Patient_') &&
      !documentType.startsWith('Student_') &&
      documentType !== 'All Documents'
    ) {
      filteredDocs = filteredDocs
        .map((patient) => ({
          ...patient,
          attachmentData: patient.attachmentData.filter(
            (attachment) =>
              attachment.docData.typeOfDocument &&
              attachment.docData.typeOfDocument.toLowerCase() ===
                documentType.toLowerCase()
          ),
        }))
        .filter(
          (patient) =>
            patient.attachmentData && patient.attachmentData.length > 0
        );
    }

    const result = [];
    filteredDocs.forEach((patient) => {
      if (patient.attachmentData) {
        patient.attachmentData.forEach((attachment) => {
          const doc = attachment.docData;
          if (doc && doc.info) {
            result.push({
              id: attachment.docId,
              title: doc.info.fileName || 'Unnamed Document',
              uploadedBy: doc.uploadedId || 'Unknown',
              date: doc.createdAt
                ? new Date(doc.createdAt._seconds * 1000).toLocaleDateString()
                : 'Unknown date',
              patientId: patient.patientId,
              url: doc.info.url,
              fileName: doc.info.fileName,
              typeOfDocument: doc.typeOfDocument,
            });
          }
        });
      }
    });

    return result;
  }, [documents, selectedPatient, selectedStudent, documentType]);

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleOpenUpload = () => {
    setOpenAttachmentUploadDocument(true);
  };

  return (
    <MainLayout>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 4,
        }}
      >
        <Typography variant='h4' fontWeight='bold'>
          Document Dashboard
        </Typography>
        <Button
          variant='contained'
          sx={{
            bgcolor: '#9291ef',
            '&:hover': { bgcolor: '#6366f1' },
            color: 'white',
            px: 3,
          }}
          onClick={handleOpenUpload}
        >
          Upload Document
        </Button>
      </Box>

      <Box sx={{ display: 'flex', gap: 2, mb: 4 }}>
        {ACM[typeOfUser]?.isDoctor && (
          <FormControl size='small' sx={{ minWidth: 180 }}>
            <Select
              value={selectedPatient}
              onChange={(e) => setSelectedPatient(e.target.value)}
              displayEmpty
              sx={{ bgcolor: 'white' }}
            >
              <MenuItem value='all_patients__'>All Patients</MenuItem>
              {patientData?.map((patient) => (
                <MenuItem key={patient.patientId} value={patient.patientId}>
                  {truncateName(patient?.patientInfo?.fullname)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {ACM[typeOfUser]?.isTeacher && (
          <FormControl size='small' sx={{ minWidth: 180 }}>
            <Select
              value={selectedStudent}
              onChange={(e) => setSelectedStudent(e.target.value)}
              displayEmpty
              sx={{ bgcolor: 'white' }}
            >
              <MenuItem value='__all_students__'>All Students</MenuItem>
              {combinedStudents.map((student) => (
                <MenuItem key={student.id} value={student.id}>
                  {truncateName(student.name)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        <FormControl size='small' sx={{ minWidth: 180 }}>
          <Select
            value={documentType}
            onChange={(e) => setDocumentType(e.target.value)}
            displayEmpty
            sx={{ bgcolor: 'white' }}
          >
            <MenuItem value='All Documents'>All Documents</MenuItem>
            <MenuItem value='Summary'>Summaries</MenuItem>
            <MenuItem value='Assessment'>Assessments</MenuItem>
            <MenuItem value='Score'>Scores</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <Grid container spacing={4}>
        <Grid item xs={12} lg={7}>
          <Box>
            <Typography variant='h6' fontWeight='bold' gutterBottom>
              Uploaded Documents
            </Typography>
            <Stack spacing={2}>
              {formattedDocuments.length > 0 ? (
                formattedDocuments.map((doc) => (
                  <Paper
                    key={doc.id}
                    elevation={0}
                    sx={{
                      p: 2,
                      bgcolor: '#e9eaff',
                      borderRadius: 2,
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Box>
                      <Typography variant='subtitle1' fontWeight='medium'>
                        {doc.title}
                      </Typography>
                      <Typography variant='body2' color='text.secondary'>
                        Uploaded on {doc.date}
                      </Typography>
                      <Typography variant='body2' color='text.secondary'>
                        Type: {doc.typeOfDocument}
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <Button
                        variant='contained'
                        size='small'
                        sx={{
                          bgcolor: '#9291ef',
                          '&:hover': { bgcolor: '#6366f1' },
                          color: 'white',
                        }}
                        onClick={() =>
                          openDocumentHandler(
                            doc.id,
                            doc.url,
                            doc.title,
                            doc.patientId
                          )
                        }
                      >
                        View
                      </Button>
                      <Button
                        variant='contained'
                        size='small'
                        sx={{
                          bgcolor: '#9291ef',
                          '&:hover': { bgcolor: '#6366f1' },
                          color: 'white',
                        }}
                        onClick={() => downloadFile(doc.url, doc.fileName)}
                      >
                        Download
                      </Button>
                    </Box>
                  </Paper>
                ))
              ) : (
                <Paper
                  elevation={0}
                  sx={{
                    p: 4,
                    bgcolor: '#eef2ff',
                    borderRadius: 2,
                    textAlign: 'center',
                  }}
                >
                  <Typography variant='body1'>
                    No documents found with the selected filters.
                  </Typography>
                </Paper>
              )}
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={12} lg={5}>
          <Reports />
        </Grid>
      </Grid>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>

      {openAttachmentUploadDocument && (
        <UploadAttachment
          open={openAttachmentUploadDocument}
          handleClose={() => setOpenAttachmentUploadDocument(false)}
        />
      )}
    </MainLayout>
  );
};

export default Documents;
