import React from 'react';
import { Box, CardContent } from '@mui/material';

import { usePrivacy } from '../../hooks/usePrivacy';

const PrivacyTerms = () => {
  const { data: privacyText = '' } = usePrivacy();

  return (
    <Box>
      <Box
        sx={{
          maxWidth: '90%',
          margin: '20px auto',
          padding: 2,
          overflow: 'auto',
        }}
      >
        <CardContent>
          <div dangerouslySetInnerHTML={{ __html: privacyText }} />
        </CardContent>
      </Box>
    </Box>
  );
};
export default PrivacyTerms;
