import { AddAPhoto, Email, Image, Lock, Person } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Fab,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Progress from '../../../components/Progress';
import PolicyTerms from '../../PolicyTerms';
import PrivacyLandingpage from '../PrivacyLandingpage';
import {
  FirebaseStorage,
  FirestoreDB,
  LogAnalyticsEvent,
} from '../../../utils/firebase/firebase';
import useGetInvitation from '../../../hooks/useGetInvitation';
import { ANALYTICS_KEYS } from '../../../constants/AnalyticKeys';
import AuthContext from '../../../contexts/AuthContext';
import { useContext } from 'react';
import PrivacyTerms from '../../PrivacyTerms';
import useFetchPreRegisteredUsers from '../../../hooks/useFetchPreRegisteredUsers';
import { deleteDoc, doc, getDoc } from 'firebase/firestore';
import {
  fetchPreRegisteredUsers,
  movePreRegisteredUserToRealUser,
} from '../../../api';

const Invite = () => {
  const { id } = useParams();
  const { data, isLoading, isError } = useGetInvitation(id);

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState({
    show: false,
    message: '',
  });
  const [openPoliciesModal, setOpenPoliciesModal] = useState(false);
  const [isPoliciesAccepted, setIsPoliciesAccepted] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [profilePicURL, setProfilePicURL] = useState(null);
  const [profilePicFile, setProfilePicFile] = useState(null);
  const [signupError, setSignupError] = useState({
    show: false,
    message: '',
  });
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [privacyAccepted, setPrivacyAccepted] = useState(false);
  const [setIsLoading] = useState(false);
  const [student, setStudent] = useState();

  // const [infoParams,setInfoParams] = useState(inviteType);

  const { registerWithEmailPasswordForTeacherAndStudent, login } =
    useContext(AuthContext);

  const navigate = useNavigate();

  const handleCloseTermsModal = () => {
    setOpenPoliciesModal(false);
  };

  const nextStep = () => {
    if (currentStep === 1) {
      setTermsAccepted(true);
      setCurrentStep(2);
    } else if (currentStep === 2) {
      setPrivacyAccepted(true);
      setOpenPoliciesModal(false);
      setIsPoliciesAccepted(true);
    }
  };
  const acceptPoliciesHandler = () => {
    setIsPoliciesAccepted(true);
    setOpenPoliciesModal(false);
  };

  const renderContent = () => {
    switch (currentStep) {
      case 1:
        return <PolicyTerms footerRequired={false} />;
      case 2:
        // return <PrivacyLandingpage footerRequired={false} />;
        return <PrivacyTerms footerRequired={false} />;
      default:
        return null;
    }
  };

  const renderButton = () => {
    switch (currentStep) {
      case 1:
        return (
          <Fab
            size='medium'
            onClick={nextStep}
            variant='extended'
            sx={{
              fontFamily: 'Lato',
              fontSize: '1rem',
              background: 'var(--clr-theme-purple)',
              color: 'var(--clr-theme-white)',
              textTransform: 'none',
              ':hover': {
                background: 'var(--clr-theme-purple-50)',
              },
            }}
          >
            Accept Terms & Conditions
          </Fab>
        );
      case 2:
        return (
          <Fab
            size='medium'
            onClick={acceptPoliciesHandler}
            variant='extended'
            sx={{
              fontFamily: 'Lato',
              fontSize: '1rem',
              background: 'var(--clr-theme-purple)',
              color: 'var(--clr-theme-white)',
              textTransform: 'none',
              ':hover': {
                background: 'var(--clr-theme-purple-50)',
              },
            }}
          >
            Accept Privacy & Policy
          </Fab>
        );
      default:
        return null;
    }
  };

  const openTerms = () => {
    setOpenPoliciesModal(true);
    // setCurrentStep(1);
  };

  useEffect(() => {
    let fileReader,
      isCancel = false;
    if (profilePicFile) {
      fileReader = new FileReader();
      fileReader.onload = (e) => {
        const { result } = e.target;
        if (result && !isCancel) {
          setProfilePicURL(result);
        }
      };
      fileReader.readAsDataURL(profilePicFile);
    }
    return () => {
      isCancel = true;
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    };
  }, [profilePicFile]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file?.size <= 10048576) {
      // Greater than 10MB
      setProfilePicFile(file);
    }
  };

  const registerToFirebase = async () => {
    try {
      const { fromEmail, message, timestamp, toEmail: email, ...rest } = data;
      const preRegUserData = await fetchPreRegisteredUsers(email);
      const result = await registerWithEmailPasswordForTeacherAndStudent({
        ...rest,
        email,
        password,
        profilePictureFile: profilePicFile,
      });
      if (result?.status) {
        setSignupError({ show: false, message: '' });
        let signupKey;
        switch (data.inviteType) {
          case 'TEACHER':
            signupKey = ANALYTICS_KEYS.USERS.TEACHERSIGNUP;
            break;
          case 'STUDENT':
            signupKey = ANALYTICS_KEYS.USERS.STUDENTSIGNUP;
            break;
          case 'ADMIN':
            signupKey = ANALYTICS_KEYS.USERS.SCHOOLADMINSIGNUP;
            break;
          case 'ORGANIZATIONADMIN':
            signupKey = ANALYTICS_KEYS.USERS.ORGANIZATIONADMINSIGNUP;
            break;
          default:
            signupKey = null;
            break;
        }
        LogAnalyticsEvent(signupKey, {
          message: `${data.inviteType.toLowerCase()} successfully signed up`,
          userType: data.inviteType,
        });
        if (data.inviteType === 'STUDENT') {
          // setStudent(result?.data);
          const user = result.data;
          await login(user);
          navigate('/');
          await movePreRegisteredUserToRealUser(preRegUserData?.id, user?.uid);
        } else if (data.inviteType === 'TEACHER') {
          // goToLogin();
          const user = result.data;
          await login(user);
          navigate('/');
        } else if (data.inviteType === 'SCHOOLADMIN') {
          const user = result.data;
          await login(user);
          navigate('/');
        }
      } else {
        if (result?.error) {
          switch (result.error) {
            case 'Firebase: Error (auth/email-already-in-use).':
              setSignupError({
                show: true,
                message:
                  'The email address is already in use by another account.',
              });
              break;
            case 'Firebase: Error (auth/invalid-email).':
              setSignupError({
                show: true,
                message:
                  'The email address is not valid. Please enter a valid email.',
              });
              break;
            default:
              setSignupError({
                show: true,
                message: 'An error occurred during signup. Please try again.',
              });
              console.error('Error during signup:', result.error);
              break;
          }
        } else {
          setSignupError({
            show: true,
            message: 'An unexpected error occurred. Please try again.',
          });
          console.error('Unexpected error format:', result);
        }
      }
    } catch (error) {
      console.error('Error during signup:', error);
      setSignupError({
        show: true,
        message: 'An unexpected error occurred. Please try again.',
      });
    }
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
          backgroundColor: '#f0f2f5',
        }}
      >
        <Box
          sx={{
            width: '100%',
            maxWidth: '450px',
            padding: '2rem',
            backgroundColor: 'white',
            borderRadius: '12px',
            boxShadow:
              '0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)',
            border: '1px solid rgba(0, 0, 0, 0.1)',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              textAlign: 'center',
              gap: 15,
              width: '100%',
            }}
          >
            <Typography
              variant='h4'
              sx={{ fontFamily: 'Lato', fontWeight: 400 }}
            >
              Get started
            </Typography>
            <Typography
              variant='h6'
              sx={{
                color: 'var(--clr-theme-gray)',
                fontFamily: 'Karla',
                fontWeight: 700,
                marginBottom: '1rem',
              }}
            >
              Register as {data?.inviteType}
            </Typography>

            <div className='profile-picture-container'>
              <Avatar
                className='profile-avatar'
                alt='upload picture'
                sx={{ width: 100, height: 100 }}
                src={profilePicURL}
              >
                <Image />
              </Avatar>
              <Tooltip title='Max size of 10MB'>
                <IconButton
                  className='profile-upload-icon'
                  color='secondary'
                  aria-label='upload picture'
                  component='label'
                >
                  <input
                    hidden
                    accept='image/*'
                    type='file'
                    onChange={handleFileChange}
                  />
                  <AddAPhoto />
                </IconButton>
              </Tooltip>
            </div>

            {(data?.inviteType === 'SCHOOLADMIN' ||
              data?.inviteType === 'STUDENT' ||
              data?.inviteType === 'TEACHER') && (
              <TextField
                label='Full Name'
                value={data?.fullname}
                sx={{ m: 1, width: '100%' }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <Person />
                    </InputAdornment>
                  ),
                  readOnly: true,
                }}
                disabled={isLoading}
                variant='outlined'
              />
            )}
            {data?.inviteType === 'ORGANIZATIONADMIN' && (
              <TextField
                label='organization Name'
                type='text'
                value={data?.organizationName}
                sx={{ m: 1, width: '100%' }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <Person />
                    </InputAdornment>
                  ),
                  readOnly: true,
                }}
                disabled={isLoading}
                variant='outlined'
              />
            )}

            {data?.inviteType === 'STUDENT' && (
              <TextField
                label="Parent's Name"
                type='text'
                value={data?.parentName}
                sx={{ m: 1, width: '100%' }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <Person />
                    </InputAdornment>
                  ),
                  readOnly: true,
                }}
                disabled={isLoading}
                variant='outlined'
              />
            )}

            {data?.inviteType === 'STUDENT' && (
              <TextField
                label="Teacher's Name"
                type='text'
                value={data?.teacherName}
                sx={{ m: 1, width: '100%' }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <Person />
                    </InputAdornment>
                  ),
                  readOnly: true,
                }}
                disabled={isLoading}
                variant='outlined'
              />
            )}

            {(data?.inviteType === 'SCHOOLADMIN' ||
              data?.inviteType === 'STUDENT' ||
              data?.inviteType === 'TEACHER') && (
              <TextField
                label='School Name'
                type='text'
                value={data?.schoolName}
                sx={{ m: 1, width: '100%' }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <Person />
                    </InputAdornment>
                  ),
                  readOnly: true,
                }}
                disabled={isLoading}
                variant='outlined'
              />
            )}

            <TextField
              label='Email'
              type='email'
              value={data?.toEmail}
              sx={{ m: 1, width: '100%' }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <Email />
                  </InputAdornment>
                ),
                readOnly: true,
              }}
              disabled={isLoading}
              variant='outlined'
              autoComplete='new-email'
              required
            />

            <TextField
              label='Password'
              type='password'
              sx={{ m: 1, width: '100%' }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <Lock />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setPassword(e.target.value)}
              disabled={isLoading}
              variant='outlined'
              autoComplete='new-password'
              required
            />

            <TextField
              label='Confirm Password'
              type='password'
              sx={{ m: 1, width: '100%' }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <Lock />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setConfirmPassword(e.target.value)}
              disabled={isLoading}
              variant='outlined'
              helperText={passwordError.show && passwordError.message}
            />
            <Box className='flexBox'>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      color='success'
                      checked={isPoliciesAccepted}
                      disabled={isLoading}
                      onClick={openTerms}
                    />
                  }
                  label={
                    <Typography
                      sx={{
                        fontFamily: 'Karla',
                        fontSize: '1rem',
                        color: 'var(--clr-theme-gray)',
                      }}
                    >
                      Agree to{' '}
                      <Link href='#' onClick={openTerms}>
                        Policies and Terms
                      </Link>
                    </Typography>
                  }
                />
              </FormGroup>
            </Box>

            {signupError.show && (
              <Typography className='errorMessage' variant='caption'>
                {signupError.message}
              </Typography>
            )}

            <Button
              variant='contained'
              disabled={!isPoliciesAccepted}
              sx={{
                width: '100%',
                marginTop: '1rem',
                background: 'var(--clr-theme-purple)',
                '&:hover': {
                  background: 'var(--clr-theme-purple-50)',
                },
              }}
              onClick={registerToFirebase}
              // emailHandler={emailHandler}
            >
              Sign Up
            </Button>
          </div>
        </Box>
      </div>

      <Dialog
        sx={{
          marginTop: '40px',
        }}
        open={openPoliciesModal}
        onClose={handleCloseTermsModal}
      >
        <DialogContent>{renderContent()}</DialogContent>
        <DialogActions>{renderButton()}</DialogActions>
      </Dialog>

      {isLoading && <Progress />}
    </>
  );
};

export default Invite;
