import {
  collection,
  limit,
  orderBy,
  query,
  onSnapshot,
} from 'firebase/firestore';
import { FirestoreDB } from '../utils/firebase/firebase';
import { useQuery } from '@tanstack/react-query';

const fetchPolicy = () => {
  return new Promise((resolve, reject) => {
    const termsQuery = query(
      collection(FirestoreDB, 'terms_conditions'),
      orderBy('modified_date', 'desc'),
      limit(1)
    );

    const unsubscribe = onSnapshot(
      termsQuery,
      (querySnapshot) => {
        const latestTermsDoc = querySnapshot.docs[0]?.data().terms || '';
        const decodedTermsText = atob(latestTermsDoc);
        resolve(decodedTermsText);
      },
      (error) => reject(error)
    );

    return () => unsubscribe();
  });
};

export const usePolicy = () => {
  return useQuery({
    queryKey: ['policy'],
    queryFn: fetchPolicy,
    staleTime: 1000 * 60 * 30,
    cacheTime: 1000 * 60 * 60,
  });
};
