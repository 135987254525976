import { Cancel, Delete, Medication, Psychology } from '@mui/icons-material';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import SymptomRating from './Rating';

const Treatment = ({
  treatment,
  updateTreatments,
  symptoms,
  index,
  deleteTreatment,
  medAndTherapies,
  isLoading,
  behaviours,
}) => {
  const [currentValue, setCurrentValue] = useState('');
  const [isCustomSymptom, setIsCustomSymptom] = useState(false);

  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    setShowMessage(
      medAndTherapies.meds.length === 0 &&
        medAndTherapies.therapies.length === 0 &&
        medAndTherapies.treatment_name.length === 0
    );
  }, [medAndTherapies]);

  const handleChange = (e) => {
    setCurrentValue(e.target.value);
    // updateTreatments(currentValue, "Diagnosis", treatment);
  };

  const symptomHandler = (e) => {
    if (e.target.value !== 'other') {
      updateTreatments(e.target.value, 'Symptom', treatment.id);
      setIsCustomSymptom(false);
    } else {
      setIsCustomSymptom(true);
    }
  };

  const RatingHandler = (value, type, id, funCall) => {
    console.log('ID 62', id);
    updateTreatments(value, type, id);
  };

  if (isLoading)
    return (
      <CircularProgress
        sx={{ position: 'absolute', left: '81%', top: '30%' }}
      />
    );

  return (
    <Card className='treatmentBox' sx={{ border: '1px solid lightgray', p: 1 }}>
      <CardHeader
        action={
          <IconButton onClick={() => deleteTreatment(treatment.id)}>
            <Cancel />
          </IconButton>
        }
        subheader={'Treatment information'}
      />

      <CardContent>
        <div
          className='cardContent'
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: 5,
          }}
        >
          <Typography gutterBottom variant='subtitle2'>
            Diagnosis
          </Typography>
          <FormControl fullWidth>
            <InputLabel id='demo-simple-select-label'>
              Select diagnosis
            </InputLabel>
            <Select
              label='Select diagnosis'
              value={treatment.diagnosis}
              onChange={(e) => {
                updateTreatments(e.target.value, 'Diagnosis', treatment.id);
              }}
              disabled={medAndTherapies.treatment_name.length === 0}
              className={
                medAndTherapies.treatment_name.length === 0
                  ? 'disabled-dropdown'
                  : ''
              }
            >
              {medAndTherapies?.treatment_name?.length > 0 &&
                medAndTherapies?.treatment_name?.map((item, i) => {
                  return (
                    <MenuItem key={i} value={item}>
                      {item}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
          <Typography gutterBottom variant='subtitle2'>
            Symptom
          </Typography>
          <FormControl fullWidth sx={{ mb: 1 }}>
            <InputLabel id='demo-simple-select-label'>
              Select Symptom
            </InputLabel>
            <Select
              label='Select Symptom'
              value={isCustomSymptom ? 'other' : treatment.symptom}
              onChange={symptomHandler}
              disabled={medAndTherapies.treatment_name.length === 0}
              className={
                medAndTherapies.treatment_name.length === 0
                  ? 'disabled-dropdown'
                  : ''
              }
            >
              {symptoms?.length > 0 &&
                symptoms?.map((item, i) => {
                  return (
                    <MenuItem key={i} value={item}>
                      {item}
                    </MenuItem>
                  );
                })}
              <MenuItem value='other'>Other</MenuItem>
            </Select>
            {isCustomSymptom ? (
              <TextField
                sx={{ mt: 2 }}
                fullWidth
                // label={`Add Symptom type *${index + 1}`}
                label={`Add symptom `}
                // value={treatment.symptom}
                onChange={(e) => {
                  updateTreatments(e.target.value, 'Symptom', treatment.id);
                }}
              />
            ) : null}
          </FormControl>

          {/* <TextField
            fullWidth
            label={`Symptom type #${index + 1}`}
            value={treatment.symptom}
            onChange={(e) => {
              updateTreatments(e.target.value, "Symptom", treatment.id);
            }}
          /> */}
        </div>
        <DynamicList
          label={'Therapy'}
          list={
            Array.isArray(treatment.therapies) ? [...treatment.therapies] : []
          }
          treatmentID={treatment.id}
          icon={<Psychology color='success' />}
          updateTreatments={updateTreatments}
          medAndTherapies={medAndTherapies}
        />
        <DynamicList
          label={'Meds'}
          list={[...treatment.meds]}
          treatmentID={treatment.id}
          icon={<Medication color='success' />}
          updateTreatments={updateTreatments}
          medAndTherapies={medAndTherapies}
        />
        <SymptomRating
          id={treatment.id}
          RatingHandler={RatingHandler}
          funCall='treatment'
        />
        {showMessage ? (
          <Typography
            variant='subtitle1'
            color={'red'}
            sx={{ fontFamily: 'Lato' }}
          >
            Update treatment information in patient profile.
          </Typography>
        ) : null}
      </CardContent>
    </Card>
  );
};

const DynamicList = ({
  label,
  list,
  icon,
  updateTreatments,
  treatmentID,
  medAndTherapies,
}) => {
  const [currentValue, setCurrentValue] = useState('');
  const [myList, setMyList] = useState(list);

  const changeHandler = (e) => {
    setCurrentValue(e.target.value);
    setMyList([...myList, e.target.value]);
    setCurrentValue('');
    updateTreatments([...myList, e.target.value], label, treatmentID);
  };

  const addListItem = () => {
    setMyList([...myList, currentValue]);
    setCurrentValue('');
    updateTreatments([...myList, currentValue], label, treatmentID);
  };

  const removeItem = (item) => {
    setMyList([...myList.filter((x) => x !== item)]);
    updateTreatments([...myList.filter((x) => x !== item)], label, treatmentID);
  };

  return (
    <>
      <List subheader={label}>
        {myList.map((item, idx) => {
          return (
            <ListItem
              key={idx}
              secondaryAction={
                <IconButton
                  edge='end'
                  aria-label='delete'
                  onClick={() => removeItem(item)}
                >
                  <Delete color='error' />
                </IconButton>
              }
            >
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText>
                {label === 'Therapy' ? item.therapy_name : item.drug_name}
              </ListItemText>
            </ListItem>
          );
        })}
      </List>
      <Box className='flexBox'>
        <FormControl variant='outlined' sx={{ width: '100%', minWidth: 120 }}>
          {label === 'Therapy' ? (
            <>
              <InputLabel id='demo-simple-select-label'>
                Select Therapy
              </InputLabel>
              <Select
                onChange={changeHandler}
                value={currentValue}
                label='Select Therapy'
                disabled={medAndTherapies.therapies.length === 0}
                className={
                  medAndTherapies.therapies.length === 0
                    ? 'disabled-dropdown'
                    : ''
                }
              >
                {medAndTherapies?.therapies?.length > 0 &&
                  medAndTherapies?.therapies?.map((item, i) => {
                    return (
                      <MenuItem key={i} value={item}>
                        {item?.therapy_name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </>
          ) : (
            <>
              <InputLabel id='demo-simple-select-label'>
                Select Medication
              </InputLabel>
              <Select
                onChange={changeHandler}
                value={currentValue}
                label='Select Medication'
                disabled={medAndTherapies.meds.length === 0}
                className={
                  medAndTherapies.meds.length === 0 ? 'disabled-dropdown' : ''
                }
              >
                {medAndTherapies?.meds?.length > 0 &&
                  medAndTherapies?.meds?.map((item, i) => {
                    return (
                      <MenuItem key={i} value={item}>
                        {item.drug_name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </>
          )}
        </FormControl>
      </Box>
    </>
  );
};

export default Treatment;
