import React, { useEffect, useState } from 'react';
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  GoogleAuthProvider,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signInWithPopup,
  updateProfile,
  getAdditionalUserInfo,
} from 'firebase/auth';
import { ref } from 'firebase/storage';
import AuthContext from './AuthContext';
import {
  FirebaseAuth,
  FirebaseStorage,
  FirestoreDB,
} from '../utils/firebase/firebase';
import { UploadFileToStorage } from '../utils/firebase/helper';
import { doc, getDoc, setDoc, onSnapshot } from 'firebase/firestore';
import {
  FIRESTORE_DOCTOR_TEMPLATE,
  FIRESTORE_ORGANIZATIONADMIN_TEMPLATE,
  FIRESTORE_PATIENT_TEMPLATE,
  FIRESTORE_SCHOOLADMIN_TEMPLATE,
  FIRESTORE_STUDENT_TEMPLATE,
  FIRESTORE_TEACHER_TEMPLATE,
} from '../constants/DataTemplates';
import {
  patientLogin,
  doctorLogin,
  adminLogin,
  teacherLogin,
  studentLogin,
  schoolAdminLogin,
  organizationAdminLogin,
  fetchPreRegisteredUsers,
} from '../api';
import { APP_URL } from '../constants/AppUrl';
import { ACM } from '../constants/UserRoles';

const AuthProvider = ({ children }) => {
  const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);
  const [isAdminUserAuthenticted, setIsAdminUserAuthenticated] =
    useState(false);
  const [user, setUser] = useState(null);
  const [typeOfUser, setTypeOfUser] = useState(null);
  const [openRightPanel, setOpenRightPanel] = useState(false);
  const [openVideoUpload, setOpenVideoUpload] = useState(false);
  const [panelContent, setPanelContent] = useState();
  const [isProfileCompleted, setIsProfileCompleted] = useState(false);
  const [isNotificationPanel, setIsNotificationPanel] = useState(false);
  const typeOfUsers = {
    TEACHER: 'teachersList',
    STUDENT: 'studentsList',
    SCHOOLADMIN: 'schoolAdminsList',
    ORGANIZATIONADMIN: 'organizationAdminsList',
  };
  const registerWithGoogle = async (data, infoParams) => {
    try {
      if (infoParams.type === 'PATIENT') {
        // Only patients have this documents mapped to their ID. Doctors will only read based on patient id.
        await setDoc(doc(FirestoreDB, 'videoLogs', data.uid), {
          videos: [],
        });
        await setDoc(doc(FirestoreDB, 'patientHistory', data.uid), {
          history: [],
        });
      }

      // Add document to firestore collection
      const collectionName =
        infoParams.type === 'DOCTOR' ? 'doctorsList' : 'patientsList';

      const documentParams =
        infoParams.type === 'DOCTOR'
          ? {
              registrationID: infoParams.regID,
              fullname: data.displayName || '',
              email: data.email,
              photoURL: data.photoURL || '',
              ...FIRESTORE_DOCTOR_TEMPLATE,
            }
          : {
              doctorRegistrationID: infoParams.regID,
              fullname: data.displayName || '',
              email: data.email,
              photoURL: data.photoURL || '',
              ...FIRESTORE_PATIENT_TEMPLATE,
            };

      if (infoParams.type === 'DOCTOR') {
        await setDoc(doc(FirestoreDB, collectionName, data.uid), {
          profile: btoa(JSON.stringify(documentParams)),
          isEncrypted: false,
        });
      }

      if (infoParams.type === 'PATIENT') {
        return {
          status: true,
          data: {
            uid: data.uid,
            fullname: data.displayName,
            email: data.email,
            photoURL: data.photoURL || '',
          },
        };
      }
      login({ ...data });
      return { status: true, data: { uid: data?.uid, userEmail: data?.email } };
    } catch (err) {
      return { status: false, data: err };
    }
  };

  const registerWithEmailPassword = async (
    email,
    password,
    fullname,
    parentName,
    profilePictureFile,
    infoParams
  ) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(
        FirebaseAuth,
        email,
        password
      );
      const user = userCredential.user;
      const actionCodeSettings = {
        url:
          window.location.hostname === 'localhost'
            ? APP_URL.LOCAL
            : window.location.hostname === 'https://mindly.care'
            ? APP_URL.DEV
            : APP_URL.PROD,
      };
      await sendEmailVerification(user, actionCodeSettings);
      let profilePictureUrl = '';
      if (profilePictureFile) {
        try {
          const blob = new Blob([profilePictureFile], {
            type: profilePictureFile.type,
          });
          const profileStorageRef = ref(
            FirebaseStorage,
            `profiles/${user?.uid}/${profilePictureFile.name}`
          );
          const uploadResult = await UploadFileToStorage(
            profileStorageRef,
            blob
          );
          if (uploadResult?.status) {
            profilePictureUrl = uploadResult?.data;
          }
        } catch (uploadError) {
          console.error('Error uploading profile picture:', uploadError);
        }
      }
      await updateProfile(user, {
        displayName: fullname,
        photoURL: profilePictureUrl,
      });

      if (infoParams.type === 'PATIENT') {
        try {
          await Promise.all([
            setDoc(doc(FirestoreDB, 'videoLogs', user.uid), {
              videos: [],
            }),
            setDoc(doc(FirestoreDB, 'patientHistory', user.uid), {
              history: [],
            }),
            setDoc(doc(FirestoreDB, 'historicalData', user.uid), {
              documents: [],
            }),
          ]);
        } catch (patientDocError) {
          console.error('Error creating patient documents:', patientDocError);
          throw new Error('Failed to create patient documents');
        }
      }
      const collectionName =
        infoParams.type === 'DOCTOR' ? 'doctorsList' : 'patientsList';
      const documentParams =
        infoParams.type === 'DOCTOR'
          ? {
              registrationID: infoParams.regID,
              fullname: fullname,
              email: user?.email,
              photoURL: profilePictureUrl || '',
              ...FIRESTORE_DOCTOR_TEMPLATE,
            }
          : {
              doctorRegistrationID: infoParams.regID,
              fullname: fullname,
              email: user?.email,
              parentName: parentName,
              photoURL: profilePictureUrl || '',
              ...FIRESTORE_PATIENT_TEMPLATE,
            };

      try {
        await setDoc(doc(FirestoreDB, collectionName, user.uid), {
          profile: btoa(JSON.stringify(documentParams)),
          isEncrypted: infoParams.type === 'DOCTOR' ? false : true,
          isPoliciesAccepted: true,
        });
      } catch (firestoreError) {
        console.error(
          'Error adding user document to Firestore:',
          firestoreError
        );
        throw new Error('Failed to save user document to Firestore');
      }

      if (infoParams.type === 'PATIENT') {
        return {
          status: true,
          data: {
            uid: user?.uid,
            fullname: fullname,
            parentName: parentName,
            email: user?.email,
            photoURL: profilePictureUrl || '',
          },
        };
      }
      return {
        status: true,
        data: { uid: user.uid, userEmail: user?.email },
      };
    } catch (error) {
      console.error('Error during registration:', error);
      return { status: false, error: error.message || error };
    }
  };

  const uploadProfilePic = async (user, file) => {
    try {
      const blob = new Blob([file], {
        type: file.type,
      });
      const profileStorageRef = ref(
        FirebaseStorage,
        `profiles/${user?.uid}/${file.name}`
      );
      const uploadResult = await UploadFileToStorage(profileStorageRef, blob);
      if (!uploadResult?.status) {
        throw new Error('Error Uploading profile picture');
      }
      return uploadResult?.data;
    } catch (error) {
      console.error('Profile picture upload error:', error);
      throw error;
    }
  };

  const registerWithEmailPasswordForTeacherAndStudent = async (data) => {
    try {
      const {
        email,
        password,
        profilePictureFile,
        status = 'active',
        inviteType,
        ...rest
      } = data;
      const preRegisteredUser = await fetchPreRegisteredUsers(email);
      console.log(
        'preRegisteredUser at auth:========================',
        preRegisteredUser
      );
      let additionalData = {}; // Will store pre-registered user details
      if (preRegisteredUser) {
        additionalData = preRegisteredUser || {}; // Store decrypted profile data
        console.log('additional Data:', additionalData);
      } else {
        console.log(
          ':small_blue_diamond: No pre-registered user found. Proceeding with regular registration.'
        );
      }

      const userCredential = await createUserWithEmailAndPassword(
        FirebaseAuth,
        email,
        password
      );
      console.log('uerCredential at auth:', userCredential);
      const user = userCredential?.user;
      console.log('user at auth after create:', user);

      const actionCodeSettings = {
        url:
          window.location.hostname === 'localhost'
            ? APP_URL.LOCAL
            : window.location.hostname === 'https://mindly.care'
            ? APP_URL.DEV
            : APP_URL.PROD,
      };
      console.log('actionCodeSettings:', actionCodeSettings);
      await sendEmailVerification(user, actionCodeSettings);

      let photoURL = null;
      if (profilePictureFile) {
        photoURL = await uploadProfilePic(user, profilePictureFile);
      }
      // await updateProfile(user, {
      //   displayName: rest.fullname,
      //   photoURL,
      // });
      const finalFullName = additionalData.fullname || rest.fullname || '';
      const finalEmail = additionalData.email || email;
      await updateProfile(user, {
        displayName: finalFullName,
        photoURL,
      });

      if (inviteType === 'STUDENT') {
        try {
          await Promise.all([
            setDoc(doc(FirestoreDB, 'videoLogs', user.uid), {
              videos: [],
            }),
            setDoc(doc(FirestoreDB, 'patientHistory', user.uid), {
              history: [],
            }),
            setDoc(doc(FirestoreDB, 'historicalData', user.uid), {
              documents: [],
            }),
          ]);
        } catch (patientDocError) {
          console.error('Error creating patient documents:', patientDocError);
          throw new Error('Failed to create patient documents');
        }
      }

      const documentParams =
        data.inviteType === 'TEACHER'
          ? {
              ...FIRESTORE_TEACHER_TEMPLATE,
              fullname: data.fullname,
              email: user?.email,
              photoURL: photoURL || '',
            }
          : data.inviteType === 'STUDENT'
          ? {
              ...FIRESTORE_STUDENT_TEMPLATE,
              fullname: finalFullName,
              email: finalEmail,
              parentName: data.parentName,
              dob: additionalData?.dob,
              contactNo: {
                areaCode: additionalData?.contactNo?.areaCode,
                number: additionalData?.contactNo?.number,
              },
              gender: additionalData?.gender,
              photoURL: photoURL || '',
              id: user.uid,
            }
          : data.inviteType === 'ORGANIZATIONADMIN'
          ? {
              ...FIRESTORE_ORGANIZATIONADMIN_TEMPLATE,
              fullname: data.fullname,
              email: user?.email,
              photoURL: photoURL || '',
            }
          : {
              ...FIRESTORE_SCHOOLADMIN_TEMPLATE,
              fullname: data.fullname,
              email: user?.email,
              photoURL: photoURL || '',
            };

      await setDoc(doc(FirestoreDB, typeOfUsers[inviteType], user.uid), {
        profile: btoa(
          JSON.stringify({
            // email,
            // photoURL,
            // ...rest,
            // ...documentParams,
            email: finalEmail,
            photoURL,
            fullname: finalFullName,
            ...additionalData, // Merge pre-registered user data (if exists)
            ...rest,
            ...documentParams,
          })
        ),
        schoolId: rest.schoolId || additionalData.schoolId || '',
        teacherIds: rest.teacherIds || additionalData.teacherIds || [],
        organizationId:
          rest.organizationId || additionalData.organizationId || [],
        isEncrypted: false,
        isPoliciesAccepted: true,
        status,
      });

      return { status: true, data: { uid: user?.uid, email: user.email } };
    } catch (error) {
      console.error('Error during registration:', error);
      return { status: false, error: error.message };
    }
  };

  const registerNewPatient = async (patientInfo, doctorId) => {
    try {
      // Only patients have this documents mapped to their ID. Doctors will only read based on patient id.
      await setDoc(doc(FirestoreDB, 'videoLogs', patientInfo.uid), {
        videos: [],
      });
      await setDoc(doc(FirestoreDB, 'patientHistory', patientInfo.uid), {
        history: [],
      });

      // Add document to firestore collection
      const collectionName = 'patientsList';
      const documentParams = {
        fullname: patientInfo?.fullname,
        email: patientInfo.email,
        photoURL: patientInfo.photoURL || '',
        parentName: patientInfo.parentName || '',
        ...FIRESTORE_PATIENT_TEMPLATE,
      };
      await setDoc(doc(FirestoreDB, collectionName, patientInfo.uid), {
        profile: btoa(JSON.stringify(documentParams)),
        isEncrypted: false,
        isPoliciesAccepted: true,
        doctorIDs: [doctorId],
      });

      login(user); // Relogin and set the state after signup
      return { status: true, data: null };
    } catch (err) {
      return { status: false, data: err };
    }
  };

  const loginWithEmailPassword = async (email, password) => {
    try {
      const userCredential = await signInWithEmailAndPassword(
        FirebaseAuth,
        email,
        password
      );
      const user = userCredential.user;
      const loginResult = await login(user);

      // Check if the user is an admin in Firestore
      const adminDocRef = await getDoc(
        doc(FirestoreDB, 'adminsList', user.uid)
      );
      if (adminDocRef.exists()) {
        // Perform admin login if the user is an admin
        await adminsLogin(user);
        return { status: true, data: user, userType: 'ADMIN' };
      } else if (loginResult.status) {
        // Return user data along with userType

        return { status: true, data: user, userType: loginResult.userType };
      } else {
        return {
          status: false,
          data: null,
          userType: null,
          message: loginResult.message,
        };
      }
    } catch (e) {
      return { status: false, data: e };
    }
  };

  const loginWithGoogleProvider = async () => {
    try {
      const provider = new GoogleAuthProvider();
      provider.addScope('https://www.googleapis.com/auth/calendar.events');

      const result = await signInWithPopup(FirebaseAuth, provider);
      const credential = GoogleAuthProvider.credentialFromResult(result);
      // const token = credential.accessToken;
      const user = result.user;

      const moreInfo = getAdditionalUserInfo(result);

      //checking if user is exits or not by authentication
      const isNewUser = moreInfo.isNewUser;

      return { status: true, data: { ...user, isNewUser } };
    } catch (e) {
      if (typeof console !== 'undefined') {
        console.error('Google Sign-In error:', e);
      }
      console.log(e);
      return { status: false, data: e };
    }
  };

  const getUserFromFirestore = async (uid) => {
    try {
      if (!FirestoreDB) {
        throw new Error('FirestoreDB is not initialized');
      }
      if (!uid) {
        throw new Error('UID is undefined or null');
      }

      // Check patientsList
      const patientDocRef = await getDoc(doc(FirestoreDB, 'patientsList', uid));
      if (patientDocRef.exists()) {
        setTypeOfUser('PATIENT');
        const response = await patientLogin(uid);
        return {
          status: true,
          data:
            {
              ...response?.myDetails,
              doctorDetails: response?.doctorDetails,
            } || {},
          userType: 'PATIENT',
        };
      }

      // Check doctorsList
      const doctorDocRef = await getDoc(doc(FirestoreDB, 'doctorsList', uid));
      if (doctorDocRef.exists()) {
        setTypeOfUser('DOCTOR');
        const response = await doctorLogin(uid);
        return {
          status: true,
          data: response?.myDetails || {},
          userType: 'DOCTOR',
        };
      }

      // Check teachersList
      const teacherDocRef = await getDoc(doc(FirestoreDB, 'teachersList', uid));
      console.log('teacherDocREF:', teacherDocRef);
      if (teacherDocRef.exists()) {
        const teacherData = teacherDocRef.data();
        console.log('teacherData at auth:', teacherData);

        if (teacherData.status !== 'active') {
          return {
            status: false,
            message:
              'Your account is inactive. Please contact your School Admin.',
            userType: 'TEACHER',
          };
        }

        setTypeOfUser('TEACHER');
        const response = await teacherLogin(uid);

        // console.log("response at getfromFirestore for teacher:", response);

        return {
          status: true,
          data: response?.myDetails || {},
          userType: 'TEACHER',
        };
      }

      // Check studentsList
      const studentDocRef = await getDoc(doc(FirestoreDB, 'studentsList', uid));
      if (studentDocRef.exists()) {
        const studentData = studentDocRef.data();
        console.log('studentData', studentData);

        if (studentData.status !== 'active') {
          return {
            status: false,
            message:
              'Your account is inactive. Please contact your School Admin.',
            userType: 'STUDENT',
          };
        }

        setTypeOfUser('STUDENT');
        const response = await studentLogin(uid);
        console.log('response at getfromFirestore for student:', response);
        return {
          status: true,
          data: response?.myDetails || {},
          userType: 'STUDENT',
        };
      }
      // // Check schoolAdminsList
      const schoolAdminsDocRef = await getDoc(
        doc(FirestoreDB, 'schoolAdminsList', uid)
      );
      if (schoolAdminsDocRef.exists()) {
        setTypeOfUser('SCHOOLADMIN');
        const response = await schoolAdminLogin(uid);

        // return { status: true, data: response?.myDetails || {}, userType: 'SCHOOLADMIN' };
        return {
          status: true,
          data: response.myDetails || {},
          userType: 'SCHOOLADMIN',
        };
      }
      const organizationAdminsDocRef = await getDoc(
        doc(FirestoreDB, 'organizationAdminsList', uid)
      );
      if (organizationAdminsDocRef.exists()) {
        setTypeOfUser('ORGANIZATIONADMIN');
        const response = await organizationAdminLogin(uid);
        console.log('resp for OrgAdmin---------------:', response);

        return {
          status: true,
          data: response.myDetails || {},
          userType: 'ORGANIZATIONADMIN',
        };
      }

      // If none match
      return { status: false, message: 'Invalid user type or not authorized.' };
    } catch (error) {
      console.error('Error fetching user from Firestore:', error);
      return { status: false, data: error.message };
    }
  };

  const getAdminUserFromFirestore = async (uid) => {
    try {
      const documentRef = await getDoc(doc(FirestoreDB, 'adminsList', uid));

      if (!documentRef.exists()) {
        return { status: false, message: 'Admin user not found' };
      }

      setTypeOfUser('ADMIN');
      const response = await adminLogin(uid);

      if (!response) {
        return { status: false, message: 'Admin login failed' };
      }

      return { status: true, data: response.myDetails || {} };
    } catch (error) {
      console.error('Error fetching admin user:', error);
      return { status: false, message: 'Error fetching admin user' };
    }
  };

  const login = async (user) => {
    const response = await getUserFromFirestore(user?.uid);
    console.log('at login for response:', response);
    if (response?.status) {
      setIsUserAuthenticated(true);
      setUser({ ...user, ...response?.data });

      const item = {
        value: user?.uid,
        expiry: new Date().getTime() + 3600000, // 1hr
      };
      localStorage.setItem('session', JSON.stringify(item));
      return { status: true, data: user, userType: response.userType };
    } else {
      setIsUserAuthenticated(false);
      setUser(null);
      localStorage.removeItem('session');
      const errorMessage =
        response?.message ||
        'An error occurred during login. Please try again.';
      console.error(errorMessage);
      // alert(errorMessage);
      //ToDo: add toast to the Login button check if authenticate false

      return {
        status: false,
        data: null,
        userType: null,
        message: errorMessage,
      }; //line added
    }
  };

  const adminsLogin = async (user) => {
    const response = await getAdminUserFromFirestore(user.uid);
    if (response.status) {
      setIsAdminUserAuthenticated(true);
      setUser({ ...user, ...response.data });
      const item = {
        value: user?.uid,
        expiry: new Date().getTime() + 3600000, // 1hr
      };
      localStorage.setItem('session', JSON.stringify(item));
      return { status: true, data: user, userType: response.userType };
    } else {
      setIsAdminUserAuthenticated(false);
      setUser(null);
      localStorage.removeItem('session');
      //ToDo: add toast to the Login button check if authenticate false
      return { status: false, data: null, userType: null }; //line added
    }
  };

  const logout = () => {
    if (FirebaseAuth.currentUser) {
      FirebaseAuth.signOut().then(() => {
        // if (typeOfUser === "ADMIN") {
        if (ACM[typeOfUser]?.isAdmin) {
          setIsAdminUserAuthenticated(false);
        } else {
          setIsUserAuthenticated(false);
        }
        setUser(null);
        localStorage.removeItem('session');
      });
    }
  };

  const updateGlobalUser = (data) => {
    setUser(data);
  };

  const validateContactNo = (contactNo) => {
    if (!contactNo || typeof contactNo !== 'object') {
      return false;
    }
    const { areaCode, number } = contactNo;

    if ((areaCode > 0 && number > 0) || (areaCode !== '' && number !== '')) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    if (user?.uid) {
      // if (typeOfUser === "PATIENT") {
      if (ACM[typeOfUser]?.isPatient || ACM[typeOfUser]?.isStudent) {
        if (
          (user?.age < 0 || user?.age !== '') &&
          user?.gender !== '' &&
          // user.birthWeight !== 0 &&
          user?.dob !== '' &&
          // (user.currentWeight !== 0 || user.currentWeight !== "") &&
          // (user.height !== 0 || user.height !== "") &&
          validateContactNo(user?.contactNo)
        ) {
          setIsProfileCompleted(true);
        } else {
          setIsProfileCompleted(false);
        }
        // } else if (typeOfUser === "DOCTOR") {
      } else if (ACM[typeOfUser]?.isDoctor || ACM[typeOfUser]?.isTeacher) {
        setIsProfileCompleted(true);
      }
    }
  }, [user, typeOfUser]);

  useEffect(() => {
    let isMounted = true;
    const unsubscribe = onAuthStateChanged(FirebaseAuth, async (user) => {
      if (!isMounted) return;

      try {
        if (user) {
          const storedRole = localStorage.getItem('userRole');

          if (storedRole === 'admin') {
            if (isMounted) {
              await adminsLogin(user);
              setIsAdminUserAuthenticated(true);
            }
          } else if (storedRole === 'user') {
            if (isMounted) {
              await login(user);
              setIsUserAuthenticated(true);
            }
          } else {
            const adminDocRef = await getDoc(
              doc(FirestoreDB, 'adminsList', user.uid)
            );

            if (!isMounted) return;

            if (adminDocRef.exists()) {
              localStorage.setItem('userRole', 'admin');
              if (isMounted) {
                await adminsLogin(user);
                setIsAdminUserAuthenticated(true);
              }
            } else {
              localStorage.setItem('userRole', 'user');
              if (isMounted) {
                await login(user);
                setIsUserAuthenticated(true);
              }
            }
          }
        } else {
          if (isMounted) {
            setIsUserAuthenticated(false);
            setIsAdminUserAuthenticated(false);
            setUser(null);
            localStorage.removeItem('session');
            localStorage.removeItem('userRole');
          }
        }
      } catch (error) {
        console.error('Error during authentication:', error);
        if (isMounted) {
          setIsUserAuthenticated(false);
          setIsAdminUserAuthenticated(false);
          setUser(null);
          localStorage.removeItem('userRole');
        }
      }
    });

    return () => {
      isMounted = false;
      unsubscribe(); // Unsubscribe from Firebase listener when the component unmounts
    };
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isUserAuthenticated,
        isAdminUserAuthenticted,
        user,
        setUser,
        registerWithGoogle,
        registerWithEmailPassword,
        loginWithEmailPassword,
        loginWithGoogleProvider,
        login,
        logout,
        typeOfUser,
        setTypeOfUser,
        openRightPanel,
        setOpenRightPanel,
        openVideoUpload,
        setOpenVideoUpload,
        panelContent,
        setPanelContent,
        isProfileCompleted,
        setIsProfileCompleted,
        isNotificationPanel,
        setIsNotificationPanel,
        registerNewPatient,
        updateGlobalUser,
        getUserFromFirestore,
        getAdminUserFromFirestore,
        registerWithEmailPasswordForTeacherAndStudent,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
